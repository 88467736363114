// Copyright © 2021 Move Closer

/**
 * Uppercases the first letter of the input string.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export default function (value: string|null, onlyFirst: boolean = true): string {
  if (!value) {
    return ''
  }

  value = value.toString()

  if (!onlyFirst) {
    const splited = value.split(' ')
    const length = splited.length

    for (let i = 0; i < length; i++) {
      splited[i] = splited[i].charAt(0).toUpperCase() + splited[i].slice(1)
    }

    return splited.join(' ')
  }

  return value.charAt(0).toUpperCase() + value.slice(1)
}
