// Copyright © 2021 Move Closer

import { FiltersConfig, FilterType } from '@component/Filters/Filters.contracts'
import { defaultIgnoredQueryParams } from '@component/Filters/Filters.hook'

import { loadRoles } from '@/shared/helpers/filters'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const usersFiltersConfig: FiltersConfig = {
  groups: {
    firstName: { label: 'Imię', key: 'firstName', type: FilterType.String },
    lastName: { label: 'Nazwisko', key: 'lastName', type: FilterType.String },
    nickname: { label: 'Pseudonim', key: 'nickname', type: FilterType.String },
    role: { label: 'Dostęp', key: 'role', type: FilterType.Dictionary, dictionaryLoader: loadRoles },
    email: { label: 'Email', key: 'email', type: FilterType.String },
    registerDate: { label: 'Data rejestracji', key: 'registerDate', type: FilterType.Date },
    lastLogin: { label: 'Ostatnie logowanie', key: 'lastLogin', type: FilterType.Date }
  },
  ignore: [...defaultIgnoredQueryParams],
  override: { page: '1' },
  leave: ['perPage']
}
