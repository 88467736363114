








import { Component, Prop, Vue } from 'vue-property-decorator'

import { Identifier } from '@/shared/contracts/data'
import { IRelatedService } from '@service/related'

import { UserData } from '@module/auth/contracts/models'

import { OnVariantChangeCallback, VariantErrors, VariantModel } from '../contracts'
import { Addon, addonsDrivers } from '../maps/variant'

import { AddonLabel } from './addons/AddonLabel.vue'

@Component({
  name: 'VariantAddons'
})
export class VariantAddons extends Vue {
  @Prop({ type: Object, required: true })
  protected active!: VariantModel

  @Prop({ type: Object, required: false, default: null })
  protected authUser!: UserData|null

  @Prop({ type: Boolean, required: false })
  public isDisabled!: boolean

  @Prop({ type: Object, required: false })
  public errors!: VariantErrors

  @Prop({ type: Function, required: true })
  protected onChange!: OnVariantChangeCallback

  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  public addonsTypes = Addon

  public get accordionItems () {
    return Object.values(this.addonsTypes)
      .filter(i => this.active.addons.includes(i))
      .map(i => {
        return {
          label: {
            component: AddonLabel,
            props: {
              label: this.$t(`content.addonsLabels.${i}`),
              hasErrors: this.checkForErrors(i)
            }
          },
          id: i,
          component: addonsDrivers[i],
          props: {
            disabled: !this.isEditable,
            errors: this.getErrors(i),
            formName: `addons-${i}`,
            onChange: this.onChange,
            relatedService: this.relatedService,
            variant: this.active
          }
        }
      })
  }

  public get isEditable (): boolean {
    return this.active.isEditable(this.authUser?.id as unknown as Identifier)
  }

  protected checkForErrors (addonName: Addon): boolean {
    if (!this.errors) {
      return false
    }

    for (const errorKey of Object.keys(this.errors)) {
      if (errorKey.startsWith(`properties.${addonName}.`)) {
        return true
      }
    }

    return false
  }

  protected getErrors (addonName: Addon) {
    if (!this.errors) {
      return
    }

    const addonError: string[] = []

    for (const errorKey of Object.keys(this.errors)) {
      if (errorKey.startsWith(`properties.${addonName}.`)) {
        addonError.push(...this.errors[errorKey])
      }
    }

    return addonError
  }
}

export default VariantAddons
