













































import { AnyObject } from '@movecloser/front-core'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName, DashmixTheme, Site } from '@d24/modules'

import { FormTypeahead } from '@component/FromTypeahead/FormTypeahead'
import { Hint } from '@component/Typeahead'
import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@plugin/inversify'

import { DictionaryRepositoryType, IDictionaryRepository } from '@module/root/contracts/repositories'

import { Roles, SimpleRole } from '../contracts/models'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'RolesSideBar',
  components: { FormTypeahead }
})
export class RolesSideBar extends Vue {
  @Prop({ type: String, required: true })
  public form!: string

  @Prop({ type: Boolean, required: false, default: false })
  public isEditMode!: boolean

  @Prop({ type: Boolean, required: true })
  public isSaving!: boolean

  @Prop({ type: Object, required: true })
  public roles!: Roles

  @Prop({ type: Object, required: true })
  public sitesLogos!: Record<Site, AnyObject>

  @Inject(DictionaryRepositoryType)
  private dictionaryRepository!: IDictionaryRepository

  public icons = DashmixIconName
  public isLoading: boolean = false
  public options: Hint[] = []
  public useForAll: boolean = false
  public themes = DashmixTheme

  created () {
    this.loadOptions()
  }

  public getTypeaheadOption (site: Site): Hint[] {
    if (this.roles && this.roles[site]) {
      return this.roles[site].map(role => {
        return {
          value: role.id,
          label: role.name
        }
      })
    }
    return []
  }

  public loadOptions (searchParams?: string) {
    this.isLoading = true
    this.dictionaryRepository.loadRolesDictionary({ q: searchParams || '' })
      .then(collection => {
        this.options = [...collection].map(option => {
          return {
            value: option.id,
            label: option.name
          }
        })
      }
      ).finally(() => {
        this.isLoading = false
      })
  }

  public onClear () {
    this.loadOptions()
  }

  public onDelete (site: Site, selectedHint: Hint) {
    if (this.roles && this.roles[site]) {
      this.roles[site] = this.roles[site].filter(role => {
        return role.id !== selectedHint.value
      })

      if (this.useForAll) {
        this.propagateRoles(this.roles[site])
      }
    }
  }

  public onSelect (site: Site, selectedHint: Hint) {
    if (this.roles && this.roles[site]) {
      this.roles[site].push({ id: selectedHint.value as Identifier, name: selectedHint.label })

      if (this.useForAll) {
        this.propagateRoles(this.roles[site])
      }
    }
  }

  public changeSetForAll (value: true) {
    this.useForAll = value

    if (value) {
      this.propagateRoles(this.roles['defence24.pl'])
    }
  }

  public onSearch (searchedParams: string) {
    this.loadOptions(searchedParams)
  }

  protected propagateRoles (selected: SimpleRole[]) {
    if (!this.roles) {
      return
    }

    this.$emit('propagateForAll', selected)
  }
}

export default RolesSideBar
