// Copyright © 2021 Move Closer

import { AnyObject } from '@movecloser/front-core'
import { ExtendedMDE } from 'simplemde'
import { HasDriver, ModuleDriver } from '@d24/modules'

import { DeMarked } from '@/shared/helpers/demarked'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
interface FoundResult {
  collection: AnyObject[]
  element: AnyObject
  index: number
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
function findTokenAtLine (line: string, tokenType: ModuleDriver): FoundResult | null {
  const objects: AnyObject[] = DeMarked.convertMDToObjects(line)

  for (let i = 0; i < objects.length; i++) {
    if (objects[i].driver === tokenType) {
      return {
        collection: objects,
        element: objects[i],
        index: i
      }
    }
  }

  return null
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
function readLines (editor: ExtendedMDE): string[] {
  return [
    editor.codemirror.getSelection(),
    editor.codemirror.getLine(
      editor.codemirror.getCursor().line
    )
  ]
}

/**
 * Analyze the current line against given token.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function findTokenAtCurrentLine (editor: ExtendedMDE, tokenType: ModuleDriver): AnyObject | null {
  const [currentSelection, currentLine] = readLines(editor)

  let object: FoundResult | null = findTokenAtLine(currentSelection, tokenType)

  if (!object) {
    object = findTokenAtLine(currentLine, tokenType)
  }

  return object?.element.content
}

/**
 * Replace found token with given content.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function replaceTokenAtCurrentLine (editor: ExtendedMDE, tokenType: ModuleDriver, content: AnyObject, version?: string): void {
  let foundAtSelection: boolean = true
  const [currentSelection, currentLine] = readLines(editor)

  let result = findTokenAtLine(currentSelection, tokenType)
  if (!result) {
    result = findTokenAtLine(currentLine, tokenType)
    foundAtSelection = false
  }

  if (!result) {
    // There's no token to replace. Let's insert a new one.
    editor.codemirror.replaceSelection(
      DeMarked.convertObjectsToMD([
        { driver: tokenType, content, version } as HasDriver<ModuleDriver>
      ])
    )
    return
  }

  const { collection, element, index } = result
  element.content = content

  if (foundAtSelection) {
    editor.codemirror.replaceSelection(
      DeMarked.convertObjectsToMD([element as HasDriver<ModuleDriver>])
    )
    return
  }

  const lineNumber: number = editor.codemirror.getCursor().line
  const lineLength: number = currentLine.length
  collection[index] = element

  editor.codemirror.replaceRange(
    DeMarked.convertObjectsToMD(collection as HasDriver<ModuleDriver>[]),
    { line: lineNumber, ch: 0 },
    { line: lineNumber, ch: lineLength }
  )
}
