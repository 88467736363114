// Copyright © 2021 Move Closer

import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { AdBannerContent } from '@d24/modules'

import { TargetingOptionsKeys } from '../../ads/ads.contracts'
import { TargetingKeys } from '../../ads/ads.config'

@Component<AdBannerWTGForm>({
  name: 'AdBannerWTGForm',

  template: `
    <div>
      <label class="mt-3">{{ $t('adBanner.targeting.label') }}</label>
      <div class="form-group">
        <label for="targeting.section">{{ $t('adBanner.targeting.section') }}</label>
        <D24Select :model="getTargeting(targetingKeys.Section)" name="targeting.section"
                   @update:model="(model) => setTargeting(targetingKeys.Section, model)"
                   :options="targetingOptions[targetingKeys.Section]" multiple/>
      </div>

      <div class="form-group">
        <label for="targeting.type">{{ $t('adBanner.targeting.type') }}</label>
        <D24Select :model="getTargeting(targetingKeys.Type)" name="targeting.type"
                   @update:model="(model) => setTargeting(targetingKeys.Type, model)"
                   :options="targetingOptions[targetingKeys.Type]" :searchable="false" multiple/>
      </div>
    </div>
  `
})
export class AdBannerWTGForm extends Vue {
  @PropSync('content', { type: Object, required: true })
  public _content!: AdBannerContent

  @Prop({ type: Boolean, required: false, default: false })
  public readonly disabled!: boolean

  @Prop({ type: Object, required: true })
  public readonly targetingOptions!: Partial<TargetingOptionsKeys>

  @Prop({ type: Function, required: true })
  public readonly getTargeting!: (key: TargetingKeys) => string[]

  @Prop({ type: Function, required: true })
  public readonly setTargeting!: (key: TargetingKeys, value: string[]) => void

  public readonly targetingKeys = TargetingKeys

  public loading = false
}
