
































import { Authentication, AuthServiceType, IModal, ModalType } from '@movecloser/front-core'
import { BreadcrumbsProps, DashmixTheme, TableHead, TableRowElement, TableSearchBarAction } from '@d24/modules'
import { Component, Mixins, Watch } from 'vue-property-decorator'

import { DropdownActions } from '@/shared/contracts/content'
import { HeaderInterface } from '@component/InteractiveTable/InteractiveTable.contracts'
import { HasIdentifier, Identifier } from '@/shared/contracts/data'
import { Inject } from '@/shared/plugins/inversify'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { Modals } from '@/config/modals'
import { ModelListHeader } from '@component/ModelListHeader/ModelListHeader'
import { Query } from '@/shared/contracts/query'

import { AuthRepositoryType, IAuthRepository } from '@module/auth/contracts/repositories'
import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'
import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { ISubscriptionRepository, SubscriptionRepositoryType } from '../contracts/repositories'
import { SubscriptionData, UserModel } from '../contracts/models'
import {
  SubscriptionListsActions,
  subscriptionRowActionsFactory,
  subscriptionTableHead
} from '../maps/subscriptions'
import { SubscriptionTableRow } from '../components/SubscriptionTableRow.vue'
import { subscriptionsFiltersConfig } from '@module/users/models/subscriptions.filters'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'SubscriptionList',
  components: { InteractiveTable, ModelListHeader }
})
export class SubscriptionList extends Mixins<IUserAware>(UserAware) {
  @Inject(AuthRepositoryType)
  protected authRepository!: IAuthRepository

  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(SubscriptionRepositoryType)
  protected subscriptionRepository!: ISubscriptionRepository

  public filtersConfig = subscriptionsFiltersConfig

  public actions: DropdownActions = {
    [SubscriptionListsActions.Edit]: {
      callback: (data: unknown) => {
        this.onEdit((data as SubscriptionData).id)
      }
    },
    [SubscriptionListsActions.Cancel]: {
      callback: (data: unknown) => {
        return this.onCancel((data as HasIdentifier).id)
      },
      confirmation: {
        header: 'subscription.cancel.header',
        contentText: 'subscription.cancel.text',
        theme: DashmixTheme.Warning,
        buttonLabel: 'subscription.cancel.confirm'
      }
    }
  }

  public isLoading = false

  public rowActions: TableSearchBarAction[] = []
  public rowComponent = SubscriptionTableRow
  public tableData: TableRowElement[] =[]
  public tableHead: TableHead = subscriptionTableHead.map(item => {
    return {
      ...item,
      label: `${this.$t(item.label)}`
    }
  })

  public totalPages: number = 0
  public itemsTotal: number = 0

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('users.listTitle')}`,
          target: { name: 'users.list' }
        },
        {
          label: `${this.$t('subscription.listTitle')}`,
          target: { name: 'subscription.list' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get header (): HeaderInterface {
    const header: Partial<HeaderInterface> = {
      title: 'subscription.listTitle'
    }

    if (this.canCreateUsers) {
      header.buttonLabel = 'subscription.add'
      header.linkTarget = { name: 'subscription.create' }
    }

    return header as HeaderInterface
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  mounted () {
    this.loadList(this.queryParams)

    this.rowActions = subscriptionRowActionsFactory(this.domain, this.user)
  }

  public onCancel (id: Identifier) {
    this.subscriptionRepository.cancel(id).then(data => {
      this.tableData = this.tableData.map(rowElement => {
        if (`${rowElement.id}` === `${id}` && rowElement.data) {
          const rowData = rowElement.data as SubscriptionData
          rowData.endedAt = data
        }
        return rowElement
      })
    })
  }

  public onCreate () {
    this.modalConnector.open(Modals.SelectUserModal, {
      onClose: () => this.modalConnector.close(),
      onCreate: (selectedUser: Identifier) => this.createSubscription(selectedUser),
      onEdit: (subscriptionId: Identifier) => this.onEdit(subscriptionId)
    })
  }

  public onEdit (subscriptionId: Identifier) {
    this.modalConnector.close()
    this.$router.push({ name: 'users.subscriptions.edit', params: { id: subscriptionId.toString() } })
  }

  protected createSubscription (selectedUser: Identifier) {
    if (!selectedUser) {
      return
    }
    this.modalConnector.close()
    this.$router.push({ name: 'users.subscriptions.create', params: { id: selectedUser.toString() } })
  }

  protected loadList (query: Query): void {
    this.isLoading = true
    this.subscriptionRepository.loadCollection(query).then(collection => {
      this.isLoading = false

      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: true,
          data: model
        }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.itemsTotal = collection.meta.total
    }).catch(error => {
      console.debug(error)
      this.isLoading = false
    })
  }

  @Watch('queryParams', { deep: true })
  protected onQueryChange (query: Query): void {
    this.loadList(query)
  }
}

export default SubscriptionList
