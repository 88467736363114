// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'

import { userAdapterMap } from './user.adapter'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const tokenAdapterMap: MappingConfig = {
  accessToken: 'accessToken',
  expiresAt: {
    type: MappingTypes.Function,
    value: item => {
      const now = new Date()
      now.setSeconds(now.getSeconds() + item.expiresIn)
      return now
    }
  },
  tokenType: 'tokenType',
  me: {
    type: MappingTypes.Adapter,
    map: userAdapterMap,
    value: 'me'
  }
}
