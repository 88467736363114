// Copyright © 2021 Move Closer

import { ImageRatio } from '@d24/modules'

import { FileSize } from '@module/media/contracts'

/**
 * @author Jan Dobrowoslki <jan.dobrowolski@movecloser.pl>
 */
export function fileSize (bites: number) {
  const sufix = ['B', 'KB', 'MB', 'GB']
  let i = 0
  let size = bites
  while (size >= 1024) {
    size = Math.ceil(size / 1024)
    i++
  }
  return `${size} ${sufix[i]}`
}

export const AspectRatioSizesMap: Record<ImageRatio, FileSize | null> = {
  [ImageRatio.Standard43]: { width: 1920, height: 1440 },
  [ImageRatio.Standard169]: { width: 1920, height: 1080 },
  [ImageRatio.HeroDesktop]: { width: 1440, height: 520 },
  [ImageRatio.HeroMobile]: { width: 375, height: 540 },
  [ImageRatio.Original]: null,
  [ImageRatio.Portrait]: { width: 1280, height: 1920 },
  [ImageRatio.Square]: { width: 1100, height: 1100 },
  [ImageRatio.Tile]: { width: 580, height: 460 },
  [ImageRatio.Wide]: { width: 1920, height: 768 }
}
