



































import { Authentication, AuthServiceType, EventbusType, EventPayload, IEventbus, IModal, ModalType } from '@movecloser/front-core'
import { DashmixTheme, SizeMap, TableHead, TableRowElement, TableSearchBarAction } from '@d24/modules'
import { Component, Mixins, Watch } from 'vue-property-decorator'

import { DropdownActions } from '@/shared/contracts/content'
import { HasIdentifier, Identifier } from '@/shared/contracts/data'
import { Inject } from '@/shared/plugins/inversify'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { Modals } from '@/config/modals'
import { Query } from '@/shared/contracts/query'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { ISubscriptionRepository, SubscriptionRepositoryType } from '../contracts/repositories'
import { PeriodData, UserModel } from '../contracts/models'
import { PeriodsTableRow } from '../components/PeriodsTableRow.vue'
import { PeriodsListsActions, periodsRowActionsFactory, periodsTableHead } from '../maps/periods'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'PeriodsTable',
  components: { InteractiveTable }
})
export class PeriodsTable extends Mixins<IUserAware>(UserAware) {
  @Inject(EventbusType)
  private eventBus!: IEventbus

  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(SubscriptionRepositoryType)
  protected subscriptionRepository!: ISubscriptionRepository

  public isLoading = false

  public rowComponent = PeriodsTableRow
  public tableHead: TableHead = periodsTableHead.map(item => {
    return {
      ...item,
      label: `${this.$t(item.label)}`
    }
  })

  public tableData: TableRowElement[] = []

  public totalPages: number = 0
  public itemsTotal: number = 0

  public rowActions: TableSearchBarAction[] = []

  public actions: DropdownActions = {
    [PeriodsListsActions.ViewTransaction]: {
      callback: (data: unknown) => {
        this.onShowTransaction((data as PeriodData).id)
      }
    },
    [PeriodsListsActions.GetInvoice]: {
      callback: (data: unknown) => {
        this.onGetInvoice(data as PeriodData)
      }
    },
    [PeriodsListsActions.Paid]: {
      callback: (data: unknown) => {
        return this.onPaid((data as HasIdentifier).id)
      },
      confirmation: {
        header: 'subscription.paid.header',
        contentText: 'subscription.paid.text',
        theme: DashmixTheme.Success,
        buttonLabel: 'subscription.paid.confirm'
      }
    },
    [PeriodsListsActions.SendPaymentLink]: {
      callback: (data: unknown) => {
        return this.onPaymentLinkSend((data as HasIdentifier).id)
      },
      confirmation: {
        header: 'subscription.paidLink.header',
        contentText: 'subscription.paidLink.text',
        theme: DashmixTheme.Success,
        buttonLabel: 'subscription.paidLink.confirm'
      }
    }
  }

  public query: Query = {}

  public get subscriptionId () {
    return Number(this.$route.params.id)
  }

  mounted () {
    this.loadList(this.query)

    this.rowActions = periodsRowActionsFactory(this.domain, this.user)
    this.eventBus.handle('ui:edit-sub.refresh', (event: EventPayload<Identifier>) => {
      this.refresh()
    })
  }

  public setQuery (query: Query) {
    if (JSON.stringify(this.query) === JSON.stringify(query)) {
      return
    }
    this.query = query
  }

  protected loadList (query: Query): void {
    this.isLoading = true
    this.subscriptionRepository.loadPeriods(this.subscriptionId, query).then(collection => {
      this.isLoading = false

      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: true,
          data: model
        }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.itemsTotal = collection.meta.total
    }).catch(error => {
      console.debug(error)
      this.isLoading = false
    })
  }

  protected onGetInvoice (data: PeriodData) {
    if (!data.invoice) {
      return
    }

    const invoiceUrl = data.invoice
    window.open(invoiceUrl, '_blank')
  }

  protected onPaid (id: PeriodData['id']) {
    this.subscriptionRepository.markAsPaid(this.subscriptionId, id).then(() => {
      this.tableData = this.tableData.map(rowData => {
        if (`${rowData.id}` === `${id}`) {
          (rowData.data as PeriodData).isPaid = true
        }
        return rowData
      })
    }).catch((e) => {
      console.debug(e)
    })
  }

  protected onPaymentLinkSend (id: PeriodData['id']) {
    this.subscriptionRepository.sendPaymentEmail(this.subscriptionId, id)
  }

  protected onShowTransaction (id: PeriodData['id']) {
    this.modalConnector.open(Modals.TransactionModal, {
      periodId: id,
      subscriptionId: this.subscriptionId
    }, { size: SizeMap.XLarge })
  }

  @Watch('query', { deep: true })
  protected onQueryChange (query: Query): void {
    this.loadList(query)
  }

  public refresh () {
    this.loadList(this.query)
  }
}

export default PeriodsTable
