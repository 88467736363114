// Copyright © 2021 Move Closer

import { ConnectorMiddleware, FoundResource, Headers, Injectable, Payload } from '@movecloser/front-core'

import { ISiteResolver } from './contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class SiteMiddleware implements ConnectorMiddleware {
  private resolver: ISiteResolver

  public constructor (resolver: ISiteResolver) {
    this.resolver = resolver
  }

  public afterCall (): void {
    //
  }

  public beforeCall (resource: FoundResource, headers: Headers, body: Payload): { headers: Headers; body: Payload } {
    if (resource.meta?.omitXsite) {
      return { body, headers }
    }

    return { body, headers: { ...headers, ...this.resolver.getSiteHeader() } }
  }
}

export const SiteMiddlewareType = Symbol.for('SiteMiddleware')
