// Copyright © 2021 Move Closer

import {
  AbstractRelatedService,
  DriverConfig,
  Identifier,
  PossibleTypeDriver,
  Related,
  RelatedRecord,
  RelatedType,
  RelatedTypeDriverRegistry
} from '@d24/modules'
import { AnyObject, Injectable, ResourceActionFailed } from '@movecloser/front-core'

import {
  CalledRelatedServiceMethod,
  IRelatedRepository,
  IRelatedService,
  RelatedQuery,
  RelatedToLoad
} from './related.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class RelatedService extends AbstractRelatedService implements IRelatedService {
  /**
   * Repository to load all related.
   */
  protected repository: IRelatedRepository

  /**
   * Timeout that loads related.
   */
  protected timeout: number | undefined

  /**
   * Related to load next call.
   */
  protected toLoad: RelatedToLoad[] = []

  public constructor (drivers: RelatedTypeDriverRegistry, relatedRepository: IRelatedRepository, record?: RelatedRecord) {
    super(drivers, record)
    this.repository = relatedRepository
  }

  /**
   * @inheritDoc
   */
  public async describe (related: Related): Promise<AnyObject> {
    try {
      return await super.describe(related)
    } catch (e) {
      return new Promise((resolve, reject) => {
        this.pushToQueue({
          method: CalledRelatedServiceMethod.Describe,
          related,
          resolve,
          reject
        })
      })
    }
  }

  /**
   * @inheritDoc
   */
  public merge (type: RelatedType, key: Identifier, value: AnyObject): void {
    this.storeRelated({ [type]: { [key]: value } })
  }

  /**
   * @inheritDoc
   */
  public async resolve (related: Related, config: DriverConfig = {}): Promise<AnyObject | AnyObject[]> {
    try {
      return await super.resolve(related, config)
    } catch (e) {
      return new Promise((resolve, reject) => {
        this.pushToQueue({
          method: CalledRelatedServiceMethod.Resolve,
          related,
          resolve,
          reject,
          config
        })
      })
    }
  }

  /**
   * Loads missing related in a bulk call.
   */
  protected loadMissingRelated (): void {
    const toLoad = [...this.toLoad]
    this.toLoad = []

    const query: RelatedQuery = {}
    for (const l of toLoad) {
      if (typeof query[l.related.type] === 'undefined') {
        query[l.related.type] = []
      }

      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
      query[l.related.type]!.push(l.related.value)
    }

    this.repository.load(query).then((record: RelatedRecord) => {
      this.storeRelated(record)
      this.notifyOnSuccess(toLoad)
    }).catch((error: ResourceActionFailed) => {
      this.notifyOnFail(toLoad, error)
    })
  }

  /**
   * Push next related to queue stack.
   */
  protected pushToQueue (toPush: RelatedToLoad): void {
    this.toLoad.push(toPush)
    this.triggerLoading()
  }

  /**
   * Queue control.
   */
  protected triggerLoading (): void {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.loadMissingRelated())
  }

  /**
   * Marks all Promises from stack as 'rejected'.
   */
  private notifyOnFail (toNotify: RelatedToLoad[], error: Error): void {
    for (const relatedToLoad of toNotify) {
      relatedToLoad.reject(error)
    }
  }

  /**
   * Marks all Promises from stack as 'resolved'.
   */
  private notifyOnSuccess (toNotify: RelatedToLoad[]): void {
    for (const receiver of toNotify) {
      const driver = this.resolveDriver(receiver.related.type)

      try {
        const result = this.recall(driver, receiver)
        receiver.resolve(result)
      } catch (e) {
        this.notifyOnFail([receiver], e)
      }
    }
  }

  /**
   * Recalls correct method for Promise resolving.
   */
  private recall (driver: PossibleTypeDriver, toRecall: RelatedToLoad): AnyObject {
    switch (toRecall.method) {
      case CalledRelatedServiceMethod.Describe:
        return driver.describe(`${toRecall.related.value}`, this.record)
      case CalledRelatedServiceMethod.Resolve:
        return driver.resolve(
          `${toRecall.related.value}`,
          this.record,
          toRecall.config || {},
          this.resolveDriver.bind(this)
        )
    }
  }
}
