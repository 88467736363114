// Copyright © 2021 Move Closer

import { DashmixIconName, TableHead, TableSearchBarAction } from '@d24/modules'

import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'

import { canViewSubscription } from '../guards'

export enum TransactionsListsActions {
  GetDetails = 'getDetails'
}

export const transactionsTableHead: TableHead = [
  {
    column: 'driver',
    label: 'transactions.table.driver',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'id',
    label: 'transactions.table.id',
    sortable: false,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'price',
    label: 'transactions.table.price',
    sortable: false,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'status',
    label: 'transactions.table.status',
    sortable: false,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'startedAt',
    label: 'subscription.table.startedAt',
    sortable: false,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'endedAt',
    label: 'subscription.table.endedAt',
    sortable: false,
    isSorted: false,
    width: '0.75fr'
  }
]

export const transactionsRowActionsFactory = (domain: Domain, user: UserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canViewSubscription(domain, user)) {
    actions.push(
      {
        label: 'Pokaż szczegóły',
        icon: DashmixIconName.SearchPlusSolid,
        isBulkAction: false,
        action: TransactionsListsActions.GetDetails
      }
    )
  }

  return actions
}
