// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const subscriptionAdapterMap: MappingConfig = {
  id: 'id',
  user: {
    type: MappingTypes.Function,
    value: item => {
      if (!item.user) {
        return item.user
      } else if (!item.user.avatar) {
        return item.user
      }
      return {
        ...item.user,
        avatar: {
          url: item.user.avatar
        }
      }
    }
  },
  type: 'type',
  isActive: 'isActive',
  startedAt: 'startedAt',
  period: 'period',
  price: 'price',
  status: 'status',
  history: 'history',
  deactivatedBy: 'deactivated_by',
  deactivatedAt: 'deactivated_at',
  endedAt: 'endedAt',
  nextRenewalAt: 'nextRenewalAt'
}
