// Copyright © 2021 Move Closer

import { DashmixIconName, TableHead, TableSearchBarAction } from '@d24/modules'
import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'
import { canDeleteTag, canEditTag } from '@module/settings/guards'

export const tagContentTableHead: TableHead = [
  {
    column: 'id',
    label: 'ID',
    sortable: true,
    isSorted: false,
    width: '0.2fr'
  },
  {
    column: 'name',
    label: 'Name',
    sortable: true,
    isSorted: false,
    width: '2.25fr'
  },
  {
    column: 'count',
    label: 'Wystąpień',
    sortable: true,
    isSorted: true,
    width: '0.75fr'
  },
  {
    column: 'use_as_tag_page',
    label: 'Strona tagu?',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'deletedAt',
    label: 'Czy usunięty?',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'preview',
    label: 'Podgląd',
    sortable: false,
    isSorted: false,
    width: '0.7fr'
  }
]

export enum TagsActions {
  Delete = 'delete',
  Edit = 'edit'
}

export const tagsRowActionsFactory = (domain: Domain, user: UserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canEditTag(domain, user)) {
    actions.push(
      {
        label: 'Edytuj',
        icon: DashmixIconName.PenSolid,
        isBulkAction: false,
        action: TagsActions.Edit
      }
    )
  }

  if (canDeleteTag(domain, user)) {
    actions.push(
      {
        label: 'Usuń',
        icon: DashmixIconName.TrashAltSolid,
        isBulkAction: true,
        action: TagsActions.Delete
      }
    )
  }

  return actions
}
