









import { Component } from 'vue-property-decorator'

import { Hint, Typeahead } from '@component/Typeahead'

import { AbstractSelector } from './AbstractSelector'
import { generateSlug } from '../../helpers/slugs'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'TagSelector',
  components: { Typeahead }
})
export class TagSelector extends AbstractSelector {
  protected applySelection (selected: Hint[]) {
    this.onSelection(selected.map(v => {
      if (!v.value) {
        return { label: v.label, value: generateSlug(v.label) }
      }

      return v
    }))
  }

  protected loadDictionary (searchParams?: string) {
    this.loading = true

    this.dictRepository.loadTagsDictionary({ q: searchParams || '' }).then(dict => {
      this.dictionary = [...dict].map(model => {
        return {
          value: model.id,
          label: model.name
        }
      })
    }).finally(() => {
      this.loading = false
    })
  }
}

export default TagSelector
