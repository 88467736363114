






































import { Component, Prop } from 'vue-property-decorator'
import { DashmixIconName, DashmixSelectItem, Related, RelatedType } from '@d24/modules'
import { FilterOperator, IModal, ModalType } from '@movecloser/front-core'

import { AbstractModal } from '@component/Modal'
import { FormTypeahead } from '@component/FromTypeahead/FormTypeahead'
import { Hint } from '@component/Typeahead'
import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@/shared/plugins/inversify'
import { IRelatedService, RelatedServiceType } from '@service/related'

import { ContentModel, ContentRepositoryType, ContentStatus, ContentType, IContentRepository, PickerPayload, SimpleContentModel } from '../contracts'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ArticlePickerModal>({
  name: 'ArticlePickerModal',
  components: {
    FormTypeahead
  },
  created () {
    let selectedIds: Identifier[] = []

    this.showThumbnail = this.payload.params?.showThumbnail

    this.site = this.siteResolver.getSite()?.id ?? null
    this.sites = [...this.siteResolver.getDictionary()]
      .map(site => {
        return {
          label: site.domain,
          value: site.id
        }
      })

    if (this.payload.selected) {
      if (this.payload.multiple) {
        selectedIds = (this.payload.selected as Related<RelatedType.Content, Identifier>[]).map(s => s.value)
      } else {
        selectedIds = [(this.payload.selected as Related<RelatedType.Content, Identifier>).value]
      }

      this.selectedIds = selectedIds
    }

    this.loadSelectedArticles(selectedIds).then(() => {
      this.loadArticles()
    })
  },

  mounted () {
    // TODO fix - see src/modules/content/components/AddSetItemModal.vue:117
    (document.querySelector('.modal-content') as HTMLDivElement).style.overflow = 'visible'
  }
})
export class ArticlePickerModal extends AbstractModal {
  @Prop({
    type: Object,
    required: true
  })
  public payload!: PickerPayload<ContentModel, RelatedType.Content, Identifier>

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  @Inject(ContentRepositoryType)
  private contentRepository!: IContentRepository

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  public icons = DashmixIconName

  public isLoading: boolean = true
  public showThumbnail: boolean = false

  public site: Identifier | null = null
  public sites: DashmixSelectItem[] = []

  public selected: ContentModel[] = []
  public selectedIds: Identifier[] = []
  public articles: ContentModel[] = []

  public get considerThumbnailCheck (): boolean {
    return !!this.payload.params && 'showThumbnail' in this.payload.params
  }

  public get isMultiple (): boolean {
    return this.payload.multiple ?? false
  }

  public get isReady (): boolean {
    return !!this.selected
  }

  public get typeaheadOption (): Hint[] {
    return this.selected.map(item => {
      return {
        value: item.id,
        label: item.title || `${item.id}`
      }
    })
  }

  public apply (): void {
    if (this.payload.onSelection && typeof this.payload.onSelection === 'function') {
      const result = this.selected.map(item => {
        this.relatedService.merge(RelatedType.Content, item.id, {
          ...item
        })

        return {
          type: RelatedType.Content,
          value: item.id
        } as Related<RelatedType.Content, Identifier>
      })

      if (this.considerThumbnailCheck) {
        this.payload.params!.showThumbnail = this.showThumbnail
      }

      this.payload.onSelection(this.isMultiple ? result : result[0])
    }
    this.modalConnector.close()
  }

  public clear () {
    this.loadArticles()
  }

  public close (): void {
    if (this.payload.onClose && typeof this.payload.onClose === 'function') {
      this.payload.onClose()
    }
    this.modalConnector.close()
  }

  protected changeSite (site: Identifier): void {
    this.site = site

    this.loadArticles()
  }

  public getArticlesHints (): DashmixSelectItem[] {
    return ArticlePickerModal.articlesToSelectOptions(this.articles)
  }

  private async loadSelectedArticles (selectedId: Identifier[]) {
    this.isLoading = true

    if (!selectedId || selectedId.length === 0) {
      return
    }

    const q = selectedId.map(selectedId => {
      return `${FilterOperator.Equal}:${selectedId}`
    })

    try {
      this.selected = await this.contentRepository.loadCollection(
        ContentType.Article,
        {
          id: q.join(',or:'),
          status: ContentStatus.Published
        })
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }

  private async loadArticles (searchParams?: string) {
    this.isLoading = true

    this.contentRepository.loadCollection(
      [ContentType.Article, ContentType.LiveArticle], {
        q: searchParams || '',
        status: ContentStatus.Published,
        siteId: `${this.site}`
      }).then((collection) => {
      this.articles = collection
    }).catch(error => {
      console.error(error)
    }).finally(() => {
      this.isLoading = false
    })
  }

  public remove (selectedHint: Hint) {
    const selectedArticleIndex: number = this.selected.findIndex(item => item.id === selectedHint.value)
    if (selectedArticleIndex != null) {
      this.selected.splice(selectedArticleIndex, 1)
    }
  }

  public search (searchedParams: string) {
    this.loadArticles(searchedParams)
  }

  public select (selectedHint: Hint) {
    const selectedArticle = this.articles.find(item => item.id === selectedHint.value)
    if (selectedArticle) {
      this.selected.push(selectedArticle)
    }
  }

  private static articlesToSelectOptions (collection: SimpleContentModel[]): DashmixSelectItem[] {
    return [...collection].map(model => {
      const payload = model.toObject()

      return {
        value: payload.id,
        label: payload.title
      }
    })
  }
}

export default ArticlePickerModal
