// Copyright © 2021 Move Closer

import { Container, RoutesFactory } from '@movecloser/front-core'

import { Permission } from '@module/auth/contracts/permissions'

import RolesList from './views/RolesList.vue'
import CreateRole from './views/CreateRole.vue'
import EditRole from './views/EditRole.vue'

import AuthorsList from './views/AuthorsList.vue'
import CreateAuthor from './views/CreateAuthor.vue'
import CreateSubscription from '@module/users/views/CreateSubscription.vue'
import CreateUser from './views/CreateUser.vue'
import EditAuthor from './views/EditAuthor.vue'
import EditSubscription from '@module/users/views/EditSubscription.vue'
import EditUser from './views/EditUser.vue'
import SubscriptionList from '@module/users/views/SubscriptionList.vue'
import UsersList from './views/UsersList.vue'
import UsersLogsList from './views/UsersLogsList.vue'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const routesFactory: RoutesFactory = (container: Container) => {
  return [
    {
      path: '/users/:id/edit',
      name: 'edit',
      component: EditUser,
      meta: {
        permissions: [
          Permission.EditUsers
        ]
      }
    },
    {
      path: '/users/create',
      name: 'create',
      component: CreateUser,
      meta: {
        permissions: [
          Permission.CreateUsers
        ]
      }
    },
    {
      path: '/users',
      name: 'list',
      component: UsersList,
      meta: {
        permissions: [
          Permission.ViewUsers
        ]
      }
    },
    {
      path: '/users/logs',
      name: 'logs',
      component: UsersLogsList
    },
    {
      path: '/authors',
      name: 'authors.list',
      component: AuthorsList,
      meta: {
        permissions: [
          Permission.ViewAuthors
        ]
      }
    },
    {
      path: '/authors/create',
      name: 'authors.create',
      component: CreateAuthor,
      meta: {
        permissions: [
          Permission.CreateAuthors
        ]
      }
    },
    {
      path: '/authors/:id/edit',
      name: 'authors.edit',
      component: EditAuthor,
      meta: {
        permissions: [
          Permission.EditAuthors
        ]
      }
    },
    {
      path: '/roles/:id/edit',
      name: 'roles.edit',
      component: EditRole,
      meta: {
        permissions: [
          Permission.EditRoles
        ]
      }
    },
    {
      path: '/roles/create',
      name: 'roles.create',
      component: CreateRole,
      meta: {
        permissions: [
          Permission.CreateRoles
        ]
      }
    },
    {
      path: '/roles',
      name: 'roles.list',
      component: RolesList,
      meta: {
        permissions: [
          Permission.ViewRoles
        ]
      }
    },
    {
      path: '/subscriptions',
      name: 'subscriptions.list',
      component: SubscriptionList,
      meta: {
        permissions: [
          // Permission.ViewSubscription
        ]
      }
    },
    {
      path: '/users/:id/subscriptions/create',
      name: 'subscriptions.create',
      component: CreateSubscription,
      meta: {
        permissions: [
          // Permission.CreateSubscription
        ]
      }
    },
    {
      path: '/subscriptions/:id',
      name: 'subscriptions.edit',
      component: EditSubscription,
      meta: {
        permissions: [
          // Permission.EditSubscription
        ]
      }
    }
  ]
}
