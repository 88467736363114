





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixSelectItem } from '@d24/modules'
import { DateTimeType, ICollection, IDateTime } from '@movecloser/front-core'
import { Inject } from '@plugin/inversify'

import { Identifier } from '@/shared/contracts/data'
import { IRelatedService, RelatedServiceType } from '@service/related'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'

import {
  AddSetItemModalPayload,
  ContentModel,
  ContentRepositoryType,
  ContentStatus,
  ContentType,
  IContentRepository
} from '../contracts'
import { Hint, Typeahead } from '@component/Typeahead'

@Component<AddSetItemModal>({
  name: 'AddSetItemModal',
  components: { Typeahead },
  created (): void {
    this.site = this.siteResolver.getSite()?.id
    this.sites = [...this.siteResolver.getDictionary()].map(site => {
      return {
        label: site.domain,
        value: site.id
      }
    })
  },

  mounted () {
    // TODO fix - see below
    (document.querySelector('.modal-content') as HTMLDivElement).style.overflow = 'visible'

    this.loadContents()
  }
})
class AddSetItemModal extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  public payload!: AddSetItemModalPayload

  @Inject(ContentRepositoryType)
  private contentRepository!: IContentRepository

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  private contentsCollection!: ICollection<ContentModel>
  public isLoading: boolean = false
  public itemOptions: Hint[] = []
  public selectedOption: Hint[] = []
  public searchParam: string = ''
  public site: Identifier | undefined
  public sites: DashmixSelectItem[] = []

  public onClear () {
    this.searchParam = ''
  }

  public onDelete () {
    this.selectedOption = []
  }

  public onSearch (value: string) {
    this.searchParam = value
  }

  public onSelect (selectedHint: Hint) {
    this.selectedOption = [selectedHint]
  }

  public loadContents (searchParams?: string) {
    if (typeof this.site === 'undefined') return

    this.isLoading = true
    this.contentRepository.loadCollection([ContentType.Article, ContentType.LiveArticle], {
      status: ContentStatus.Published,
      q: searchParams || '',
      siteId: `${this.site}`,
      sort: '-publishedDate',
      perPage: '100'
    }
    ).then((contents: ICollection<ContentModel>) => {
      this.contentsCollection = contents
      this.itemOptions = this.contentsToHints(contents)
    }).finally(() => {
      this.isLoading = false
    })
  }

  /**
   * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
   * @protected
   */
  protected changeSite (): void {
    this.itemOptions = []

    this.$nextTick(() => {
      this.loadContents()
    })
  }

  protected close () {
    this.payload.onClose()
  }

  @Watch('searchParam', { deep: false })
  protected onSearchParamsChange (searchParams: string): void {
    this.loadContents(searchParams)
  }

  protected submit () {
    if (!this.selectedOption[0]) {
      return
    }
    this.payload.onConfirm(Number(this.selectedOption[0].value))
  }

  public contentsToHints (contents: ICollection<ContentModel>): Hint[] {
    return [...contents].map(
      (content: ContentModel) => {
        return {
          value: content.id,
          label: this.dateTime.parseToFormat(content.publishedDate, 'DD.MM.YYYY (HH:mm)') + ': ' + content.title
        }
      }
    )
  }
}

export default AddSetItemModal
