
















import { BreadcrumbsProps } from '@d24/modules'
import { Component, Vue } from 'vue-property-decorator'

import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@plugin/inversify'
import { Loader } from '@component/Loader'
import { Query } from '@/shared/contracts/query'

import { CommentModel, CommentRepositoryType, ICommentRepository } from '@module/content/contracts'

import { Comment } from '../components/Comment.vue'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component<CommentsAcceptanceList>({
  name: 'CommentsAcceptanceList',
  components: { Comment, Loader },

  mounted () {
    this.loadList()
  }
})
export class CommentsAcceptanceList extends Vue {
  @Inject(CommentRepositoryType)
  protected commentRepository!: ICommentRepository

  public comments: CommentModel[] = []
  public isLoading: boolean = false
  public totalItems: number = 0

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('content.article.listTitle')}`,
          target: { name: 'content.articles.list' }
        },
        {
          label: `${this.$t('content.article.comments.title')}`,
          target: { name: 'content.comments' }
        }
      ],
      root: {
        label: `${this.$t('root.views.root')}`,
        target: { name: 'root.dashboard' }
      }
    }
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  /**
   * Accept comment on Comment Moderation
   * @param id
   * @protected
   */
  protected onCommentAcceptance (id: Identifier): void {
    this.commentRepository.accept(id)
      .then(() => {
        for (let i = 0; i < this.comments.length; i++) {
          if (this.comments[i].id === id) {
            this.comments.splice(i, 1)
            this.totalItems--
            break
          }
        }
      }).catch(error => console.warn(error))
  }

  protected onCommentRejection (id: Identifier): void {
    this.commentRepository.reject(id)
      .then(() => {
        for (let i = 0; i < this.comments.length; i++) {
          if (this.comments[i].id === id) {
            this.comments.splice(i, 1)
            this.totalItems--
            break
          }
        }
      }).catch(error => console.warn(error))
  }

  protected loadList (): void {
    this.isLoading = true

    this.commentRepository.loadCollection({ status: 'in-acceptance', perPage: '999' }).then(collection => {
      this.isLoading = false
      this.comments = collection
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.totalItems = collection._meta.total
    }).catch(error => console.warn(error)
    ).finally(() => { this.isLoading = false })
  }
}

export default CommentsAcceptanceList
