// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'

import { SubscriptionData } from '../contracts/models'
import { Identifiable, Identifier } from '@/shared/contracts/data'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export class Subscription extends Model<SubscriptionData> implements Identifiable {
  protected boot (): void {
    this.initialValues = { price: null }
    this.modelProperties = [
      'id',
      'user',
      'type',
      'status',
      'isActive',
      'startedAt',
      'period',
      'price',
      'endedAt',
      'deactivatedAt',
      'deactivatedBy',
      'history',
      'nextRenewalAt'
    ]
  }

  public displayName (): string {
    return this._data.user ? `${this._data.user.firstName} ${this._data.user.lastName}` : 'Brak użytkownika'
  }

  public identifier (): Identifier {
    return this._data.id
  }
}
