










import { Component, PropSync, Vue } from 'vue-property-decorator'

import PermissionsListItem from '../components/PermissionsListItem.vue'
import { PermissionsListItemProps } from '../contracts/components'

@Component({
  name: 'PermissionsList',
  components: { PermissionsListItem }
})
class PermissionsList extends Vue {
  @PropSync('permissions', { type: Array, required: true })
  public listItems!: PermissionsListItemProps[]
}

export default PermissionsList
