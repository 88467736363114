




























import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { DashmixIconName, DashmixTheme, DropdownItem, ImageRatio } from '@d24/modules'

import { Modals } from '@/config/modals'
import { ModalSize } from '@component/Modal'

import { AspectRatioSizesMap } from '../helpers'
import { FileSource, FileThumbnail } from '../contracts'
import { VideoPlayer } from '@d24/modules/src/modules/_components/VideoPlayer'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<FilePreview>({
  name: 'FilePreview',
  components: { VideoPlayer }
})
export class FilePreview extends Vue {
  @Prop({
    type: Object,
    required: false
  })
  protected sourceSet!: FileSource | null

  @Ref('image')
  protected imageRef!: Vue

  public selectedVariant: ImageRatio | null = null
  public readonly icons = DashmixIconName

  public get canCrop (): boolean {
    return this.hasVariants && this.selectedVariant !== null && this.selectedVariant !== ImageRatio.Original
  }

  public get hasVariants (): boolean {
    return !!this.sourceSet && Array.isArray(this.sourceSet.variants)
  }

  public get isImage (): boolean {
    return !!this.sourceSet && ['jpg', 'jpeg', 'png', 'svg'].includes(this.sourceSet.mime)
  }

  public get isVideo (): boolean {
    return !!this.sourceSet && ['mp4', 'mov'].includes(this.sourceSet.mime)
  }

  public get video () {
    if (!this.sourceSet) {
      return
    }
    return {
      file: {
        url: this.sourceSet.original,
        m3u8: this.sourceSet.m3u8,
        mime: this.sourceSet.mime
      }
    }
  }

  public get selectedVariantFile (): FileThumbnail | null {
    if (!this.sourceSet) {
      return null
    }

    if (!this.hasVariants || this.selectedVariant === null || this.selectedVariant === ImageRatio.Original) {
      return {
        thumbnail: this.sourceSet.original,
        type: this.sourceSet.mime
      }
    }

    return {
      thumbnail: this.sourceSet.variants!.find(v => {
        return this.selectedVariant &&
            v.ratio === this.selectedVariant
      })!.url,
      type: this.sourceSet.mime
    }
  }

  public get selectedVariantLabel (): string {
    switch (this.selectedVariant) {
      case null:
      case ImageRatio.Original:
        return 'Wariant: Oryginał'
      default:
        return `Wariant: ${this.$t('image.ratio.' + this.selectedVariant)}`
    }
  }

  public get processingState (): string {
    if (this.sourceSet?.variantsParams && this.selectedVariant &&
        typeof this.selectedVariant === 'string' &&
        this.sourceSet.variantsParams[this.selectedVariant]
    ) {
      return this.sourceSet.variantsParams[this.selectedVariant].state ?? ''
    }
    return 'processed'
  }

  public get variantOptions (): DropdownItem[] {
    if (!this.sourceSet || !this.sourceSet.variants) {
      return []
    }
    const availableRatios = new Set(this.sourceSet.variants.map(v => {
      return v.ratio
    }))
    const options: DropdownItem[] = []

    for (const ratio of Object.values(ImageRatio)) {
      if (ratio === ImageRatio.Original) {
        continue
      } else if (!availableRatios.has(ratio)) {
        options.push({
          type: 'item',
          theme: DashmixTheme.Success,
          icon: DashmixIconName.PlusSolid,
          label: this.$t(`image.ratio.${ratio}`).toString(),
          onClick: () => this.addRationVariant(ratio)
        })
      } else {
        options.push({
          type: 'item',
          label: this.$t(`image.ratio.${ratio}`).toString(),
          onClick: () => this.setSelected(ratio)
        })
      }
    }

    options.sort(option => {
      if (option.theme === DashmixTheme.Success) {
        return 1
      }
      return -1
    })

    options.unshift({
      type: 'item',
      label: this.$t(`image.ratio.${ImageRatio.Original}`).toString(),
      onClick: () => this.addRationVariant(ImageRatio.Original)
    }, {
      type: 'divider'
    })

    return options
  }

  public addRationVariant (ratio: ImageRatio) {
    this.cropFile(ratio)
  }

  public cropFile (ratio: ImageRatio | null): void {
    if (!this.sourceSet || ratio === null) {
      return
    }

    this.$emit('swap', {
      toOpen: Modals.CropImage,
      payload: {
        aspectRatio: this.getVariantAspectRatio(ratio),
        id: this.sourceSet.id,
        original: this.sourceSet.original,
        ratio: ratio
      },
      config: { size: ModalSize.sm }
    })
  }

  public getVariantAspectRatio (ratio: ImageRatio): number {
    const selectedSize = AspectRatioSizesMap[ratio]

    if (typeof selectedSize === 'undefined') {
      throw new Error(`Not supported [ImageRation]. Selected image ratio: ${ratio}`)
    }

    if (selectedSize === null || selectedSize.height === null) {
      return this.imageRef.$el.clientWidth / this.imageRef.$el.clientHeight
    }

    return selectedSize.width / selectedSize.height
  }

  public refreshModal (): void {
    this.$emit('onRefreshModal')
  }

  public openFile (): void {
    if (!this.selectedVariantFile) {
      return
    }

    window.open(this.selectedVariantFile.thumbnail)
  }

  protected setSelected (selectedRatio: ImageRatio): void {
    this.selectedVariant = selectedRatio

    this.$nextTick(() => {
      if (!this.selectedVariantFile) {
        return
      }

      this.$emit('onUrlChanged', this.selectedVariantFile.thumbnail)
    })
  }
}

export default FilePreview
