// Copyright © 2021 Move Closer

import { defineComponent } from '@vue/composition-api'
import { clockProps, useClock } from '@component/Clock/Clock.hooks'
import { ClockProps } from '@component/Clock/Clock.contracts'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
export const Clock = defineComponent({
  name: 'Clock',
  props: clockProps,
  setup: (props: ClockProps) => {
    return useClock(props)
  },
  template: `
    <div>
      <slot :date="date" />
    </div>
  `
})
