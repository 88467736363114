// Copyright © 2021 Move Closer

import {
  Authentication,
  AuthServiceType,
  Container,
  EventbusType,
  EventPayload,
  IConfiguration,
  IEventbus
} from '@movecloser/front-core'
import { ErrorHandler, RawLocation } from 'vue-router/types/router'
import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'

import { DefaultRoute } from '@/config/navigation'
import { readClientWidth } from '@support/client-width'

import { Domain } from '@module/root/contracts/models'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'
import { Permission } from '@module/auth/contracts/permissions'
import { UserModel } from '@module/auth/contracts/models'

Vue.use(VueRouter)

export const prefixRoutes = (routes: RouteConfig[]): RouteConfig[] => {
  return routes.map(route => (
    route.meta?.noSiteId
      ? route
      : { ...route, path: '/:siteId?' + route.path }
  ))
}

/**
 * Creates router instance with all add-ons.
 */
export const createRouter = (
  routes: RouteConfig[],
  configuration: IConfiguration,
  container: Container
): VueRouter => {
  let isWaiting: boolean = true

  const authService: Authentication<UserModel> = container.get(AuthServiceType)
  const siteResolver: ISiteResolver = container.get(SiteResolverType)
  const eventBus: IEventbus = container.get(EventbusType)

  eventBus.handle('app:authentication', (event: EventPayload<string>) => {
    switch (event.payload) {
      case 'booted':
      case 'refreshed':
        isWaiting = false
        break
      case 'refreshing':
        isWaiting = true
        break
    }
  })

  const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: 'history',
    routes: prefixRoutes(routes)
  })

  /*
   * Override original push method - current siteId is appended to params automatically
   */
  const routerPush = router.push.bind(router)

  function customRouterPush (location: RawLocation, onComplete?: Function, onAbort?: ErrorHandler): Promise<Route> {
    if (typeof location !== 'string' && !location.params?.siteId) {
      location = { ...location, params: { ...location.params, siteId: siteResolver.getSiteId(true) } }
    }

    return routerPush(location, onComplete, onAbort) as any
  }
  router.push = customRouterPush

  /*
   * Override original resolve method - current siteId is appended to params automatically
   */
  const routerResolve = router.resolve.bind(router)

  function customRouterResolve (to: RawLocation, current?: Route, append?: boolean) {
    if (typeof to !== 'string' && !to.params?.siteId) {
      to = { ...to, params: { ...to.params, siteId: siteResolver.getSiteId(true) } }
    }

    return routerResolve(to, current, append) as any
  }
  router.resolve = customRouterResolve

  /**
   * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
   * Prefix route with siteId.
   */
  router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
    let siteId: string

    if (to.meta?.noSiteId) {
      next()
      return
    }

    if (to.path !== '/') {
      const potentialSiteId = to.path.split('/')[1]

      const site = siteResolver.findBySiteId(potentialSiteId)
      if (site) {
        siteId = potentialSiteId
        siteResolver.setSiteFromParam(potentialSiteId)
      }
    }

    siteId = siteResolver.getSiteId(true)

    if ((new RegExp(`^/${siteId}$`)).test(to.path) || (new RegExp(`^/${siteId}/`)).test(to.path)) {
      next()
    } else {
      next({ path: `/${siteId}${to.path}` })
    }
  })

  /**
   * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
   * Validate if use is logged in when required.
   */
  router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
    const redirectToLogin: boolean = typeof to.meta !== 'undefined' && 'requiresAuth' in to.meta
      ? to.meta.requiresAuth : true

    if (!redirectToLogin) {
      next()
      return
    }

    new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!isWaiting) {
          clearInterval(interval)
          resolve()
        }
      }, 200)
    }).then(() => {
      authService.check() ? next() : next({ name: 'auth.login' })
    })
  })

  /**
   * @author Łukasz Sitnicki <luksz.sitnicki@movecloser.pl>
   * Detect if authenticated user is trying to access login page.
   */
  router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
    if (to.name !== 'auth.login') {
      next()
      return
    }

    new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!isWaiting) {
          clearInterval(interval)
          resolve()
        }
      }, 200)
    }).then(() => {
      authService.check() ? next({ name: 'root.dashboard' }) : next()
    })
  })

  /**
   * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
   * Block access to route if user doesn't have necessary permissions.
   */
  router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
    const domain = siteResolver.getSite()?.domain || Domain.Defence24PL
    const user = authService.user

    if (!to.meta || !to.meta.permissions || !to.meta.permissions.length) return next()

    const canAccess = to.meta.permissions.every((permission: Permission) => user?.canPerform(domain, permission))

    const defaultRoutesConfig: DefaultRoute[] | null = configuration.byKey('defaultRoutesConfig')

    if (!defaultRoutesConfig) {
      throw new Error('Missing default routes config')
    }

    const redirectTo = defaultRoutesConfig.find(
      (route: DefaultRoute) => {
        return user?.canPerform(domain, route.meta.permissions)
      }
    )

    if (canAccess) {
      next()
    } else {
      next(redirectTo?.path)
    }
  })

  /**
   * Emit event on router change.
   */
  router.afterEach((to: Route, from: Route) => {
    eventBus.emit('ui:router.changed', {
      route: to.name,
      params: to.params,
      query: to.query,
      meta: to.meta,
      from
    })
  })

  /**
   * Update the `--client-width` CSS variable on every route change.
   */
  router.afterEach(readClientWidth)

  return router
}
