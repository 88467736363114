// Copyright © 2021 Move Closer

import {
  ConjunctionOperator,
  Container,
  Filter, FilterOperator,
  FilterParams,
  FiltersConfig as Config,
  QueryParams
} from '@movecloser/front-core'
import { ComputedRef, Ref } from '@vue/composition-api'
import { DashmixButtonVariant, DashmixSelectItem, DashmixTheme, DropdownItem } from '@d24/modules'

import { Query } from '@/shared/contracts/query'
import { Hint } from '@component/Typeahead'

export type DictionaryLoader = (container: Container, query?: Query) => Promise<GroupDictionary>

export interface DictionaryValue {
  label: string
  value: string
}

export interface Group {
  dictionary?: GroupDictionary
  dictionaryLoader?: DictionaryLoader
  key: string
  label: string
  predefined?: FilterPredefinedValue[]
  type: FilterType
}

export type GroupDictionary = DictionaryValue[]

export interface GroupsConfig {
  [key: string]: Group
}

export interface GroupsDictionaries {
  [key: string]: DictionaryValue[]
}

export interface FilterBadgeProps {
  config: Group
  container: Container
  dictionaries: GroupsDictionaries
  isNew: boolean
  field: string
  loading: boolean
  value: Filter
}

export interface FilterEditPopupDefinitionProps {
  config: Group
  definition: FilterParams
  dictionaries: GroupsDictionaries
  hints: Hint[]
  index: number
  loading: boolean
}

export interface FilterEditPopupProps {
  config: Group
  dictionaries: GroupsDictionaries
  hints: Hint[]
  loading: boolean
  value: Filter
}

export interface FilterPredefinedValue {
  display: string
  value: Filter
}

export enum FilterType {
  Boolean = 'boolean',
  Date = 'date',
  Dictionary = 'dictionary',
  Number = 'number',
  String = 'string',
  Typeahead = 'typeahead'
}

export interface FiltersConfig {
  groups: GroupsConfig
  ignore: string[]
  override: QueryParams
  leave?: string[]
}

export interface FiltersProps {
  config: FiltersConfig
  container: Container
  label: string | undefined
  triggerTheme: DashmixTheme | undefined
  triggerVariant: DashmixButtonVariant | undefined
  query: QueryParams
}

export interface UseFiltersProvides {
  canRemoveAll: ComputedRef<boolean>
  clearFilters: () => void
  dictionaries: Ref<GroupsDictionaries>
  isFromQuery: (filterKey: string) => boolean
  filters: Ref<Config>
  filtersItems: () => DropdownItem[]
  loading: Ref<boolean>
  loadDict: (groupKey: string, query: Query) => void
  onEdit: (field: string, value: any) => void
  onFilterApply: (field: string) => void
  onRemove: (field: string) => void
  shouldRender: ComputedRef<boolean>
}

export interface UseFilterBadge {
  copiedValue: Ref<Filter>
  onBadgeClick: (close: () => void, isOpen: boolean) => void
  resolvedParams: Ref<FilterParams[]>
  toggleEditMode: (open: () => void, close: () => void, isOpen: boolean) => void
  setValue: (newValue: Filter) => void
  toPrint: (filter: FilterParams) => ComputedRef<string>
  hints: Ref<Hint[]>
  deleteHint: (hint: Hint) => void
  setHint: (hint: Hint) => void
}

export interface UseFilterEditPopup {
  addClearDefinition: () => void
  conjunction: Ref<ConjunctionOperator>
  conjunctionOptions: DashmixSelectItem[]
  definitions: Ref<FilterParams[]>
  onDefinitionChange: (changed: FilterParams, index: number) => void
  onDefinitionRemove: (index: number) => void
  setNewConjunction: (newConjunction: ConjunctionOperator) => void
}

export interface UseFilterEditPopupDefinition {
  dictionariesOptions: () => DashmixSelectItem[]
  definitionOperator: Ref<FilterOperator | undefined>
  isDate: ComputedRef<boolean>
  isEnumerable: ComputedRef<boolean>
  isSearchable: ComputedRef<boolean>
  haveSelectOperator: ComputedRef<boolean>
  operatorsOptions: ComputedRef<DashmixSelectItem[]>
  onRemove: () => void
  onSearch: (searchParams: string) => void
  onSelect: (selected: Hint) => void
  onDelete: (selected: Hint) => void
  onClear: () => void
  value: Ref<string | number | boolean>
  selected: () => Hint[]
}
