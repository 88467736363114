// Copyright © 2021 Move Closer

import SimpleMDE from 'simplemde'

import { Shortcut } from '../MarkdownEditor.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const adBannerShortcut: Shortcut = {
  action: ({ codemirror }: SimpleMDE) => {
    codemirror.replaceSelection('\n\n' + '$$$' + '\n\n')
  },
  className: 'fas fa-dollar-sign',
  name: 'ad_banner',
  title: 'Wstaw baner reklamowy'
}
