








































import { Authentication, AuthServiceType, ResourceActionFailed } from '@movecloser/front-core'
import { Component, Vue } from 'vue-property-decorator'

import { Alert, AlertTheme } from '@component/Alert'
import { AuthRepositoryType, IAuthRepository } from '@module/auth/contracts/repositories'
import { enterKey, IEnterEvent } from '@/shared/extensions/events'
import { FormInput } from '@/shared/components/form'
import { Inject } from '@/shared/plugins/inversify'
import { UserModel } from '@module/auth/contracts/models'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'ResetPasswordForm',
  components: { Alert, FormInput }
})
export class ResetPassword extends Vue implements IEnterEvent {
  @Inject(AuthRepositoryType)
  protected authRepository!: IAuthRepository

  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  protected listener = enterKey.bind(this)
  protected token!: string

  public alertTheme: AlertTheme = AlertTheme.Warning
  public email = ''
  public errors: string = ''
  public formName: string = 'resetPasswordForm'
  public hasErrors: boolean = false
  public password: string = ''
  public passwordConfirmation: string = ''
  public sending: boolean = false
  public sent: boolean = false

  enterPressed () {
    this.resetPassword()
  }

  destroyed () {
    window.removeEventListener('keyup', this.listener)
  }

  mounted () {
    window.addEventListener('keyup', this.listener)

    if (!('token' in this.$route.params)) {
      this.$router.back()
    }

    this.token = String(this.$route.params.token)
  }

  resetPassword () {
    this.sending = true
    this.authRepository.resetPassword(this.token, this.email, this.password, this.passwordConfirmation)
      .then(() => {
        this.sending = false
        this.sent = true
      })
      .catch((e: ResourceActionFailed) => {
        this.errors = e.payload && e.message ? e.message : ''
        this.sending = false
        this.hasErrors = true
      })
  }

  public onAlertClose (): void {
    this.hasErrors = false
  }
}

export default ResetPassword

