









































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { DashmixIconName, DashmixTheme } from '@d24/modules'
import { Fragment } from 'vue-fragment'

import { Inject } from '@/shared/plugins/inversify'
import { Popover } from '@component/Popovers/Popover'
import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { RolesPopover } from '../components/RolesPopover.vue'
import { UserModel } from '../contracts/models'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({
  name: 'UsersTableRow',
  components: { Fragment, Popover, RolesPopover }
})
export class UsersTableRowShortened extends Mixins<IUserAware>(UserAware) {
  @Prop({ type: Object, required: true })
  public data!: UserModel

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public readonly icons = DashmixIconName
  public readonly themes = DashmixTheme

  public getStatusClass (): string {
    if (this.data.isBlocked) {
      return 'bg-danger'
    }

    if (this.data.isShadowbanned) {
      return 'bg-danger'
    }

    return this.data.isActive ? 'bg-success' : 'bg-warning'
  }
}

export default UsersTableRowShortened

