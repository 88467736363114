// Copyright © 2021 Move Closer

import { Permission } from '@module/auth/contracts/permissions'
import { NavigationItem } from '@component/Navigation/Navigation.contracts'

export interface DefaultRoute {
  path?: string
  meta: { permissions: Permission[] }
  name: string
}

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
export const defaultRoutesConfig: DefaultRoute[] = [
  // TODO dashboard in this place
  {
    path: '/content/articles',
    name: 'articles.list',
    meta: { permissions: [Permission.ViewContents] }
  },
  {
    path: '/media/list',
    name: 'media.list',
    meta: { permissions: [Permission.ViewMedia] }
  },
  {
    path: '/content/sets',
    name: 'sets.list',
    meta: {
      permissions: [Permission.ViewSets]
    }
  },
  {
    path: '/authors',
    name: 'users.authors.list',
    meta: {
      permissions: [Permission.ViewAuthors]
    }
  },
  {
    path: '/users',
    name: 'users.list',
    meta: {
      permissions: [Permission.ViewUsers]
    }
  },
  {
    path: '/users/roles',
    name: 'users.roles.list',
    meta: {
      permissions: [Permission.ViewRoles]
    }
  },
  {
    path: '/content/comments',
    name: 'comments.list',
    meta: {
      permissions: [Permission.ViewContentsComments]
    }
  },
  {
    path: '/forms/formsList',
    name: 'forms.formsList',
    meta: {
      // TODO: change permission
      permissions: [Permission.ViewContents]
    }
  },
  {
    path: '/settings/navigation',
    name: 'settings.navigation',
    meta: {
      permissions: [Permission.ViewNavigation]
    }
  }
]

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const navigation: NavigationItem[] = [
  {
    icon: 'fa fa-fw fa-tachometer-alt',
    label: 'Dashboard',
    route: { name: 'root.dashboard' },
    permissions: [
      Permission.ViewContents
    ]
  },
  {
    icon: 'far fa-2x fa-newspaper',
    label: 'Artykuły',
    children: [
      {
        label: 'Dodaj nowy',
        route: { name: 'content.articles.create' },
        permissions: [
          Permission.CreateContents
        ]
      },
      {
        label: 'Przeglądaj artykuły',
        route: { name: 'content.articles.list' },
        permissions: [
          Permission.ViewContents
        ]
      },
      {
        label: 'Przeglądaj feedy',
        route: { name: 'content.feeds.list' },
        permissions: []
      },
      {
        label: 'Do akceptacji',
        route: {
          name: 'content.acceptance.list',
          params: { contentType: 'article' }
        },
        permissions: [
          Permission.AcceptContents
        ]
      },
      {
        label: 'Moderacja komentarzy',
        route: { name: 'content.comments.acceptance.list' },
        permissions: [
          Permission.ViewContentsComments
        ]
      },
      {
        label: 'Lista komentarzy',
        route: { name: 'content.comments.list' },
        permissions: [
          Permission.ViewContentsComments
        ]
      },
      {
        label: 'Tester wyszukiwarki',
        route: { name: 'content.search.list' },
        permissions: [
          Permission.ViewContents
        ]
      }
    ]
  },
  {
    icon: 'fa fa-fw fa-project-diagram',
    label: 'Strony',
    children: [
      {
        label: 'Dodaj nową',
        route: { name: 'content.pages.create' },
        permissions: [
          Permission.CreateContents
        ]
      },
      {
        label: 'Przeglądaj strony',
        route: { name: 'content.pages.list' },
        permissions: [
          Permission.ViewContents
        ]
      },
      {
        label: 'Do akceptacji',
        route: {
          name: 'content.acceptance.list',
          params: { contentType: 'page' }
        },
        permissions: [
          Permission.AcceptContents
        ]
      }
    ]
  },
  {
    icon: 'fa fa-2x fa-folder-open',
    label: 'Listy',
    children: [
      {
        label: 'Dodaj nową',
        route: { name: 'content.sets.create' },
        permissions: [
          Permission.CreateSets
        ]
      },
      {
        label: 'Przeglądaj listy',
        route: { name: 'content.sets.list' },
        permissions: [
          Permission.ViewSets
        ]
      }
    ]
  },
  {
    icon: 'fa fa-camera',
    label: 'Media',
    children: [
      {
        label: 'Katalog Plików',
        route: { name: 'media.list' },
        permissions: [
          Permission.ViewMedia
        ]
      },
      {
        label: 'Pliki oczekujące',
        route: { name: 'media.waiting-list' },
        permissions: [
          Permission.ViewMedia
        ]
      },
      {
        label: 'Galerie',
        route: { name: 'media.gallery.list' },
        permissions: [
          Permission.ViewMedia
        ]
      }
    ]
  },
  {
    icon: 'fa fa-fw fa-users',
    label: 'Użytkownicy',
    children: [
      {
        label: 'Lista użytkowników',
        route: { name: 'users.list' },
        permissions: [
          Permission.ViewUsers
        ]
      },
      {
        label: 'Zarządzanie rolami',
        route: { name: 'users.roles.list' },
        permissions: [
          Permission.ViewRoles
        ]
      },
      {
        label: 'Lista autorów',
        route: { name: 'users.authors.list' },
        permissions: [
          Permission.ViewAuthors
        ]
      },
      {
        label: 'Lista subskrypcji',
        route: { name: 'users.subscriptions.list' },
        permissions: [
          // Permission.ViewSubscription
        ]
      },
      {
        label: 'Aktywności użytkowników',
        route: { name: 'users.logs' },
        permissions: [
          // Permission.ViewUsersLogs
        ]
      }
    ]
  },
  {
    icon: 'fa fa-2x fa-calendar-day',
    label: 'Formularze i Wydarzenia',
    children: [
      {
        label: 'Dodaj formularz',
        route: { name: 'forms.forms.create' },
        permissions: [
          Permission.CreateForms
        ]
      },
      {
        label: 'Formularze',
        route: {
          name: 'forms.forms.list',
          query: { type: 'form' }
        },
        permissions: [
          Permission.ViewForms
        ]
      },
      {
        label: 'Wydarzenia',
        route: {
          name: 'forms.forms.list',
          query: { type: 'event' }
        },
        permissions: [
          Permission.ViewForms
        ]
      }
    ]
  },
  {
    icon: 'fa fa-fw fa-cogs',
    label: 'ADS',
    children: [
      {
        label: 'AdX Link resolver',
        route: { name: 'settings.ads.adx' },
        permissions: [
          Permission.ViewAdsSettings
        ]
      },
      {
        label: 'Ustawienia reklam ustawienia',
        route: {
          name: 'settings.config.edit',
          params: { type: 'adsConfig' }
        },
        permissions: [
          Permission.ViewAdsSettings
        ]
      }
    ]
  },
  {
    icon: 'fa fa-fw fa-cogs',
    label: 'Ustawienia',
    children: [
      {
        label: 'Nawigacja',
        route: { name: 'settings.navigation' },
        permissions: [
          Permission.ViewNavigation
        ]
      },
      {
        label: 'Layouty',
        route: { name: 'settings.layouts.list' },
        permissions: [
          Permission.ViewLayout
        ]
      },
      {
        label: 'Tagi',
        route: { name: 'settings.tags.list' },
        permissions: [
          // Permission.ManageTags
        ]
      },
      {
        label: 'Pasek informacyjny',
        route: { name: 'settings.infoBar' },
        permissions: [
          // Permission.MenageInfoBar
        ]
      },
      {
        label: 'Kody rabatowe',
        route: { name: 'settings.promotions.list' },
        permissions: [
          Permission.ViewPromotion
        ]
      },
      {
        label: 'Wszystkie ustawienia',
        route: { name: 'settings.config.list' },
        permissions: [
          Permission.ViewSetting
        ]
      }
    ]
  }
]
