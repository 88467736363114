// Copyright © 2021 Move Closer

import { VueConstructor } from 'vue'

import { ContentStatus, ContentVariantStatusGroups } from '../contracts'
import AdsAddon from '@module/content/components/addons/AdsAddon.vue'
import AuthorAddon from '../components/addons/AuthorAddon.vue'
import CoverAddon from '../components/addons/CoverAddon.vue'
import CustomSetsAddon from '../components/addons/CustomSetsAddon.vue'
import DelayedAddon from '../components/addons/DelayedAddon.vue'
import LabelsAddon from '../components/addons/LabelsAddon.vue'
import LayoutAddon from '../components/addons/LayoutAddon.vue'
import LeadAddon from '../components/addons/LeadAddon.vue'
import LiveFeedAddon from '../components/addons/LiveFeedAddon.vue'
import MetaAddon from '../components/addons/MetaAddon.vue'
import OverridePublishDateAddon from '../components/addons/OverridePublishDateAddon.vue'
import PremiumAddon from '../components/addons/PremiumAddon.vue'
import RelatedArticlesAddon from '../components/addons/RelatedArticlesAddon.vue'
import SmartRelated from '../components/addons/SmartRelated.vue'
import TagsAddon from '../components/addons/TagsAddon.vue'
import TitlesAddon from '../components/addons/TitlesAddon.vue'

// !Note: Order of this Enum corresponds to sidebar's order.
export enum Addon {
  LiveFeed = 'liveFeed',
  Cover = 'cover',
  Lead = 'lead',
  Delayed = 'delayed',
  SmartRelated = 'smartRelated',
  CustomSets = 'customList',
  RelatedArticles = 'relatedArticles',
  Tags = 'tags',
  Labels = 'labels',
  AdditionalTitles = 'additionalTitles',
  Author = 'author',
  Meta = 'meta',
  OverridePublishDate = 'overridePublishDate',
  Premium = 'premium',
  Layout = 'layout',
  Ads = 'ads'
}

export const addonsDrivers: Record<Addon, VueConstructor> = {
  [Addon.LiveFeed]: LiveFeedAddon,
  [Addon.AdditionalTitles]: TitlesAddon,
  [Addon.Ads]: AdsAddon,
  [Addon.Author]: AuthorAddon,
  [Addon.Cover]: CoverAddon,
  [Addon.CustomSets]: CustomSetsAddon,
  [Addon.Delayed]: DelayedAddon,
  [Addon.Labels]: LabelsAddon,
  [Addon.Layout]: LayoutAddon,
  [Addon.Lead]: LeadAddon,
  [Addon.Meta]: MetaAddon,
  [Addon.OverridePublishDate]: OverridePublishDateAddon,
  [Addon.Premium]: PremiumAddon,
  [Addon.SmartRelated]: SmartRelated,
  [Addon.Tags]: TagsAddon,
  [Addon.RelatedArticles]: RelatedArticlesAddon
}

export enum AlternativeTitle {
  Long = 'long',
  Short = 'short',
  Clickbait = 'clickBait',
  TeaserBox = 'teaserBox'
}

export enum BadgesColor {
  Yellow = 'yellow',
  Black = 'black',
  Blue = 'blue',
  Green = 'green',
  Red = 'red',
  White = 'white',
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const variantStatusGroups: ContentVariantStatusGroups = {
  [ContentStatus.Draft]: {
    group: ContentStatus.Draft,
    includes: [ContentStatus.Draft]
  },
  [ContentStatus.InAcceptance]: {
    group: ContentStatus.InAcceptance,
    includes: [ContentStatus.InAcceptance]
  },
  [ContentStatus.Accepted]: {
    group: ContentStatus.Accepted,
    includes: [ContentStatus.Accepted]
  },
  [ContentStatus.Published]: {
    group: ContentStatus.Published,
    includes: [ContentStatus.Published]
  },
  [ContentStatus.Archived]: {
    group: ContentStatus.Archived,
    includes: [ContentStatus.Archived, ContentStatus.Rejected]
  }
}

export enum VariantLabel {
  Urgent = 'urgent',
  Live = 'live',
  Interview = 'interview',
  Comment = 'comment',
  Nonstandard = 'nonstandard'
}

export enum Sources {
  PAP = 'PAP',
  PAPnD24 = 'PAPnD24',
  MSPO = 'MSPO'
}
