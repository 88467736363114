







































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { DashmixIconName, DashmixTheme } from '@d24/modules'
import { Fragment } from 'vue-fragment'

import { Inject } from '@/shared/plugins/inversify'
import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { AuthorModel } from '../contracts/models'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'AuthorTableRow',
  components: { Fragment }
})
export class AuthorTableRow extends Mixins<IUserAware>(UserAware) {
  @Prop({ type: Object, required: true })
  public data!: AuthorModel

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public readonly icons = DashmixIconName
  public readonly themes = DashmixTheme

  public getStatusClass (): string {
    return this.data.isActive ? 'bg-success' : 'bg-warning'
  }
}

export default AuthorTableRow
