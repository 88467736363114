// Copyright © 2021 Move Closer

import {
  ICollection,
  Injectable,
  IResponse,
  MappingConfig,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'
import { CommentData, CommentModel, ICommentRepository } from '@module/content/contracts'
import { commentAdapterMap } from '@module/content/models/comment.adapter'
import { Query } from '@/shared/contracts/query'
import { resolveFromStatus } from '@/shared/exceptions/connector-errors'
import { Comment } from '../models/comment'

@Injectable()
export class CommentRepository extends Repository<CommentData> implements ICommentRepository {
  protected map: MappingConfig = commentAdapterMap
  protected useAdapter = true

  public async accept (id: CommentData['id']): Promise<void> {
    return this.toggleComment(id, 'accept')
  }

  public async delete (id: CommentData['id']): Promise<void> {
    const response: IResponse = await this.connector.call(
      'content',
      'delete',
      { id: id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async loadCollection (query: Query = {}): Promise<ICollection<CommentModel>> {
    const response: IResponse = await this.connector.call('contentComments', 'list', {}, { ...query })

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        response.data.data
      )
    }

    return this.composeCollection(
      response.data.data,
      Comment,
      response.data.meta
    )
  }

  public async reject (id: CommentData['id']): Promise<void> {
    return this.toggleComment(id, 'reject')
  }

  protected async toggleComment (id: CommentData['id'], action: string): Promise<void> {
    const response: IResponse = await this.connector.call(
      'contentComments',
      'accept',
      { id },
      { action }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        response.data.data
      )
    }
  }
}
