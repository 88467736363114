

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { GalleryModel } from '../contracts'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({
  name: 'GalleryPickerItem',
  components: {}
})
export class GalleryPickerItem extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  public item!: GalleryModel

  @Prop({
    type: Boolean,
    required: true
  })
  public isActive!: boolean

  @Prop({
    type: Boolean,
    required: true
  })
  public isMultiple!: boolean

  @Prop({
    type: Boolean,
    required: true
  })
  public isSelected!: boolean

  public selected: boolean = this.isSelected

  @Watch('selected')
  public select () {
    this.$emit('select', this.item)
  }
}

export default GalleryPickerItem
