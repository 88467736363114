<!-- Copyright © 2021 Move Closer -->

<template>
  <BaseSection>
    <template slot="heading">
      {{ $t('components.MarkdownEditorGuideModal.sections.blockquote.heading') }}
    </template>

    <pre>> Non est ad astra mollis e terris via @ Seneca</pre>
  </BaseSection>
</template>

<script>
import BaseSection from './_base'
export default { components: { BaseSection } }
</script>
