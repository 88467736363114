// Copyright © 2021 Move Closer

import { AbstractModuleForm } from '@d24/modules'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'EmptyModuleForm',
  template: `
    <div>
      <p>Formularz niedostępny</p>
    </div>
  `
})
export class EmptyModuleForm extends AbstractModuleForm<never> {}
