// Copyright © 2021 Move Closer

import { Model, ModelPayload } from '@movecloser/front-core'

import { SneakPeekData, ISneakPeek } from '../contracts'
import { Identifiable } from '@/shared/contracts/data'
import { Author } from '@module/content/models/author'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
export class SneakPeek extends Model<SneakPeekData> implements ISneakPeek, Identifiable {
  protected boot () {
    this.modelProperties = [
      'author',
      'fullUrl',
      'id',
      'parent',
      'position',
      'publicationDate',
      'properties',
      'slug',
      'title',
      'travelDistance'
    ]
  }

  public displayName () {
    return this._data.title
  }

  public identifier () {
    return this._data.id
  }

  public toSetItem (): any {
    return {
      author: this._data.author,
      id: this._data.id,
      position: this._data.position,
      publicationDate: this._data.publicationDate,
      title: this._data.title
    }
  }

  protected relatesToAuthor (value: ModelPayload) {
    return this.hasOne(Author, value)
  }
}
