// Copyright © 2023 Move Closer

import Vue from 'vue'
import { ValidatorsMap, ValidatorsMapOutput } from '@module/media/contracts/validators'

/**
 * Validates form payload
 *
 * TODO: Finish. Now it only operates with 'required' property validation
 *
 * @param payload
 * @param validatorsMap
 */
export function validateFormData<T> (
  this: Vue,
  payload: T,
  validatorsMap: ValidatorsMap<T>
): Array<ValidatorsMapOutput<T>> {
  const errors: Array<ValidatorsMapOutput<T>> = []

  const _validateRequiredField = (key: string, value: string) => {
    if (!value || typeof value === 'undefined') {
      errors.push({
        [key]: [this.$t('forms.validation.errors.required', { key: this.$t(`forms.validation.fields.${key}`).toString() })]
      } as ValidatorsMapOutput<T>)
    }
  }

  for (const [key, value] of Object.entries(payload)) {
    const rules = validatorsMap[key as keyof T].split('|')

    if (rules.includes('required')) {
      _validateRequiredField(key, value)
    }
  }

  return errors
}
