
















import { Component } from 'vue-property-decorator'
import { TableHead } from '@d24/modules'

import { Inject } from '@plugin/inversify'

import { HeaderInterface } from '@component/InteractiveTable/InteractiveTable.contracts'
import { ModelListHeader } from '@component/ModelListHeader'

import { AbstractWidget } from '@module/root/shared/AbstractWidget'
import {
  AcceptanceRepositoryType,
  AcceptanceStatus,
  AcceptanceTableRowElement,
  ContentType,
  IAcceptanceRepository
} from '../contracts'
import { acceptancePendingTableHeadShortened } from '../maps/acceptance'
import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'

import { AcceptanceTableRowShortened } from '../components/AcceptanceTableRowShortened.vue'
import { canPublishContents } from '../guards'

/**
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<ArticlesToAcceptWidget>({
  name: 'ArticlesToAcceptWidget',
  components: {
    ModelListHeader
  },

  created (): void {
    if (this.isWidgetVisable) {
      this.loadList()
    }
  }
})
export class ArticlesToAcceptWidget extends AbstractWidget {
  @Inject(AcceptanceRepositoryType)
  protected acceptanceRepository!: IAcceptanceRepository

  public isLoading: boolean = false
  public tableHead: TableHead = acceptancePendingTableHeadShortened
  public tableData: AcceptanceTableRowElement[] = []
  public perPage: number = 10
  public rowComponent = AcceptanceTableRowShortened

  public get header (): HeaderInterface {
    const payload: Partial<HeaderInterface> = {
      title: `${this.$t('content.article.listTitle')}`
    }

    return payload as HeaderInterface
  }

  protected checkPermission (domain: Domain, user: UserModel | null): boolean {
    return canPublishContents(domain, user)
  }

  private loadList (): void {
    this.isLoading = true

    this.acceptanceRepository.loadCollection({ types: [ContentType.Article, ContentType.Page], status: AcceptanceStatus.Pending, perPage: `${this.perPage}` })
      .then(collection => {
        this.tableData = [...collection].map(model => {
          return { id: `${model.id}`, selectable: true, data: model }
        })
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        this.isLoading = false
      })
  }
}
export default ArticlesToAcceptWidget
