// Copyright © 2021 Move Closer

import {
  AnyModule,
  CanHaveVersion,
  ContentType,
  DashmixIconName,
  HasDriver,
  HasGridSize,
  ModuleContent,
  ModuleDriver
} from '@d24/modules'
import { AnyObject } from '@movecloser/front-core'
import { ComputedRef, Ref, WritableComputedRef } from '@vue/composition-api'
import { ElementProps } from '@d24/page-builder'
import { IRelatedService } from '@service/related'
import { Subscription } from 'rxjs'
import { TranslateResult } from 'vue-i18n'
import { VueConstructor } from 'vue'

import { ModuleBlock } from '@component/ModuleWrapper/ModuleWrapper.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ModulesCopyPayload {
  appVersion: string
  copiedAt: number
  modules: AnyModule[]
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface IPageBuilderService {
  copyToStorage (modules: AnyModule[]): void
  hasCopiedModules (): boolean
  listen (callback: (event: PageBuilderEvent) => void): Subscription
  pasteFromStorage (): AnyModule[]
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ModeSwitcherItem {
  icon: DashmixIconName
  label: TranslateResult|string
  mode: PageBuilderOperationMode
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ModeSwitcherProps {
  mode: PageBuilderOperationMode
  modeList: PageBuilderOperationMode[]
  relatedService: IRelatedService
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ModuleConstructor extends VueConstructor {
  getInitialConfig (): HasGridSize & HasDriver<ModuleDriver> & CanHaveVersion
  getInitialContent (): ModuleContent
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type ModulesRegistry = Record<ModuleDriver, ModulesRegistryEntry>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ModulesRegistryEntry {
  form?: ModuleConstructor
  image: string
  name: ModuleDriver
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface PageBuilderEvent {
  type: PageBuilderEventType
  payload?: AnyObject
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum PageBuilderEventType {
  Copied = 'copied',
  Cleared = 'cleared',
  Init = 'init'
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export enum PageBuilderOperationMode {
  Render = 'render',
  EditModules = 'editModules'
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type PageBuilderOperationModeIconRegistry = Record<PageBuilderOperationMode, DashmixIconName>

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface PageBuilderProps {
  columns: number
  contentType: ContentType
  elementWrapper: VueConstructor
  mode: PageBuilderOperationMode
  modules: AnyModule[]
  modulesRegistry: ModulesRegistry|null
  relatedService: IRelatedService
  showControls: boolean
  showPreview: boolean
  targetTag: string
}

export const PageBuilderServiceType = Symbol.for('IPageBuilderService')

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface SelectModuleModalPayload {
  modulesRegistry: ModulesRegistry
  onSelection: (module: AnyModule) => void
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface SelectModuleModalProps {
  payload: SelectModuleModalPayload
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface UsePageBuilderProvides {
  addModule: () => void
  builderClass: ComputedRef<string[]>
  builderMode: ComputedRef<PageBuilderOperationMode>
  elementProps: ComputedRef<ElementProps>
  elements: WritableComputedRef<ModuleBlock[]>
  hasAnythingToPaste: Ref<boolean>
  isGridEditable: ComputedRef<boolean>
  isSticky: Ref<boolean>
  modeList: PageBuilderOperationMode[]
  onModuleDelete: (module: ModuleBlock) => void
  onModulesCopy: () => void
  onModulesPaste: () => void
  onUpdate: (changed: ModuleBlock[]) => void
}
