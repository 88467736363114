// Copyright © 2021 Move Closer

import { defineComponent } from '@vue/composition-api'

import { listGroupProps } from './ListGroup.hooks'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const ListGroup = defineComponent({
  name: 'ListGroup',
  props: listGroupProps,
  template: `
    <ul class="list-group push">
      <template v-for="(item, index) in items">
        <D24Link v-if="hasTarget" class="list-group-item" :target="item.target" :key="'item-' + index">
          <slot :item="item.data">
            {{ typeof item.data === 'string' ? item.data : 'Complex items require item component' }}
          </slot>
        </D24Link>
        <li v-else class="list-group-item" :key="'item-' + index">
          <slot v-bind:item="item.data">
            {{ typeof item.data === 'string' ? item.data : 'Complex items require item component' }}
          </slot>
        </li>
      </template>
    </ul>
  `
})
