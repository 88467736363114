// Copyright © 2021 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'

export const resources: ResourcesRegistry = {
  forms: {
    prefix: 'api/management',
    methods: {
      create: {
        url: 'forms',
        method: Methods.Post,
        auth: true,
        meta: { toast: true }
      },
      delete: {
        url: 'forms/{id}',
        method: Methods.Delete,
        params: ['id'],
        auth: true,
        meta: { toast: true }
      },
      download: {
        url: 'forms/{id}/entries/download',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      item: {
        url: 'forms/{id}',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      list: {
        url: 'forms',
        method: Methods.Get,
        auth: true
      },
      update: {
        url: 'forms/{id}',
        method: Methods.Put,
        params: ['id'],
        auth: true,
        meta: { toast: true }
      }
    }
  }
}
