





























import { Component, Prop, Watch } from 'vue-property-decorator'
import { ICollection, IModal, ModalType } from '@movecloser/front-core'
import { Identifier, Related, RelatedType } from '@d24/modules'
import { Inject } from '@plugin/inversify'

import { AbstractModal } from '@component/Modal'
import { Hint, Typeahead } from '@component/Typeahead'

import { AuthorModel } from '../contracts/models'
import { AuthorRepositoryType, IAuthorRepository } from '../contracts/repositories'
import { ContentModel, PickerPayload } from '../../content/contracts'

@Component<AuthorPicker>({
  name: 'AuthorPicker',
  components: { Typeahead },
  mounted () {
    // TODO fix - see below
    (document.querySelector('.modal-content') as HTMLDivElement).style.overflow = 'visible'

    if (this.payload.selected) {
      let idToLoad: Identifier[] = []
      if (Array.isArray(this.payload.selected)) {
        idToLoad = this.payload.selected.map(item => item.value)
      } else {
        idToLoad.push(this.payload.selected.value)
      }

      this.isLoading = true
      this.authorsRepository.loadCollection({ id: 'in:' + idToLoad.join('|') }).then(response => {
        this.selectedOption = response.filter(author => idToLoad.includes(author.id)).map(author => {
          return {
            value: author.id,
            label: author.fullName
          }
        })
      }).catch(e => console.debug(e))
        .finally(() => {
          this.isLoading = false
        })
    }

    this.loadOptions()
  }
})
class AuthorPicker extends AbstractModal {
  @Prop({ type: Object, required: true })
  public payload!: PickerPayload<ContentModel, RelatedType.Author, Identifier>

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(AuthorRepositoryType)
  protected authorsRepository!: IAuthorRepository

  public error: string = ''
  public hasErrors: boolean = false
  public isLoading: boolean = false
  public searchParam: string = ''
  public selectedOption: Hint[] = []
  public authorsOptions: Hint[] = []

  public close () {
    if (this.payload.onClose && typeof this.payload.onClose === 'function') {
      this.payload.onClose()
    }
    this.modalConnector.close()
  }

  public onClear () {
    this.searchParam = ''
  }

  public onDelete () {
    this.selectedOption = []
  }

  public onSearch (value: string) {
    this.searchParam = value
  }

  public onSelect (selectedHint: Hint) {
    this.hasErrors = false
    this.selectedOption = [selectedHint]
  }

  protected loadOptions (searchParams: string = '') {
    this.isLoading = true
    this.authorsRepository.loadCollection({ q: searchParams })
      .then((contents: ICollection<AuthorModel>) => {
        this.authorsOptions = AuthorPicker.contentsToHints(contents)
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  @Watch('searchParam', { deep: false })
  protected onSearchParamsChange (searchParams: string): void {
    this.loadOptions(searchParams)
  }

  protected submit () {
    if (this.selectedOption.length !== 0 && this.payload.onSelection && typeof this.payload.onSelection === 'function') {
      if (this.payload.multiple) {
        const result = this.selectedOption.map(selected => {
          return {
            type: RelatedType.Author,
            value: selected.value as Identifier
          }
        }) as Related<RelatedType.Author>[]

        this.payload.onSelection(result)
      } else {
        const result = {
          type: RelatedType.Author,
          value: this.selectedOption[0].value
        } as Related<RelatedType.Author>

        this.payload.onSelection(result)
      }
    }

    this.modalConnector.close()
  }

  public static contentsToHints (contents: ICollection<AuthorModel>): Hint[] {
    return [...contents].map(
      (content: AuthorModel) => {
        return {
          value: content.id,
          label: content.fullName
        }
      }
    )
  }
}

export default AuthorPicker
