
































import { Component, Prop, Vue } from 'vue-property-decorator'
import { IModal, ModalType } from '@movecloser/front-core'

import { Inject } from '@plugin/inversify'

import AdBanner from './sections/AdBanner.vue'
import Blockquote from './sections/Blockquote.vue'
import Embed from './sections/Embed.vue'
import Emphasis from './sections/Emphasis.vue'
import Gallery from './sections/Gallery.vue'
import Heading from './sections/Heading.vue'
import Image from './sections/Image.vue'
import Link from './sections/Link.vue'
import List from './sections/List.vue'
import ReadMore from './sections/ReadMore.vue'
import Table from './sections/Table.vue'
import { MarkdownEditorGuideModalPayload } from './MarkdownEditorGuideModal.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component({
  name: 'MarkdownEditorGuideModal',
  components: {
    AdBanner,
    Blockquote,
    Embed,
    Emphasis,
    Gallery,
    Heading,
    ImageSection: Image,
    Link,
    List,
    ReadMore,
    TableSection: Table
  }
})
export class MarkdownEditorGuideModal extends Vue {
  @Inject(ModalType)
  public readonly modalConnector!: IModal

  @Prop({ type: Object, required: false })
  public readonly payload?: MarkdownEditorGuideModalPayload

  /**
   * Determines whether there are any `TokenizerRules` to display.
   */
  public get hasTokenizerRules (): boolean {
    return typeof this.payload !== 'undefined' &&
      Array.isArray(this.payload.rules) &&
      this.payload.rules.length > 0
  }

  /**
   * `TokenizerRules` with some decorations applied.
   */
  public get rules (): string[]|undefined {
    if (!this.hasTokenizerRules) {
      return
    }

    return this.payload?.rules?.reduce<string[]>((acc, curr) => {
      return [
        ...acc,
        curr
          // Escape all HTML elements
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
          // Wrap all param names with the `<kbd>` element.
          .replace(/\[(\w+)]/g, '<small><kbd>$1</kbd></small>')
      ]
    }, [])
  }
}

export default MarkdownEditorGuideModal
