// Copyright © 2021 Move Closer

import { ExtendedMDE } from 'simplemde'

import { Shortcut } from '../MarkdownEditor.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const superscriptShortcut: Shortcut = {
  action: (editor: ExtendedMDE) => {
    editor.codemirror.replaceSelection('<sup>' + editor.codemirror.getSelection() + '</sup>')
  },
  className: 'fas fa-superscript',
  name: 'superscript',
  title: 'Wstaw indeks górny'
}
