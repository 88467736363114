// Copyright © 2021 Move Closer

import { Addon, AddonData, ContentSneakPeakData } from '@d24/modules/front'
import { Model } from '@movecloser/front-core'
import { ISneakPeak } from '@module/content/contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class SneakPeak extends Model<ContentSneakPeakData> implements ISneakPeak {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'author', 'contentId', 'parent', 'properties', 'publicationDate', 'site', 'title', 'type', 'urlPath', 'searchFields', 'explanation', 'lead'
    ]
  }

  /**
   * Return Addon properties.
   */
  public getProperty (addon: Addon): AddonData {
    if (!this.hasProperty(addon)) {
      return {}
    }

    return this._data.properties[addon]
  }

  /**
   * Check if model has given Addon set.
   */
  public hasProperty (addon: Addon): boolean {
    return typeof this._data.properties[addon] !== 'undefined' && this._data.properties[addon] !== null
  }
}
