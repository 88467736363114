





























































































































































import { Component, Prop } from 'vue-property-decorator'
import { DashmixButtonVariantMap, DashmixIconName, DashmixTheme, isRelated, Picker, Related, RelatedType, SizeMap } from '@d24/modules'
import { AnyObject } from '@movecloser/front-core'
import { isEmpty } from 'lodash'

import { AbstractModal } from '@component/Modal'
import { Identifier } from '@/shared/contracts/data'
import { Figure } from '@component/Figure/Figure'

import { AddonErrors } from './AddonErrors.vue'
import { Meta, MetaAddonModalPayload, MetaKeys } from '../../contracts'
import { MetaRobots } from './MetaRobots.vue'
import { getImageTypeFromUrl } from '../../helpers/addons'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<MetaAddonModal>({
  name: 'MetaAddonModal',
  components: { AddonErrors, Figure, MetaRobots },
  created () {
    this.meta = { ...this.payload.meta }

    if (typeof this.payload.intersectedData === 'object' && !!this.payload.intersectedData) {
      this.saveNewPhoto(
        this.payload.intersectedData.params.key,
        Array.isArray(this.payload.intersectedData.selected)
          ? this.payload.intersectedData.selected[0] : this.payload.intersectedData.selected
      )
    }

    if (this.payload.meta.ogImage && isRelated(this.payload.meta.ogImage)) {
      this.resolveFile(MetaKeys.OgImage, this.payload.meta.ogImage as Related<RelatedType.File, Identifier>)
    }

    if (this.payload.meta.twitterImage && isRelated(this.payload.meta.twitterImage)) {
      this.resolveFile(MetaKeys.TwitterImage, this.payload.meta.twitterImage as Related<RelatedType.File, Identifier>)
    }
  }
})
export class MetaAddonModal extends AbstractModal {
  @Prop({ type: Object, required: true })
public payload!: MetaAddonModalPayload

  public meta: Meta = {} as Meta
  public metaKeys = MetaKeys

  // Helpers
  public buttonTheme = DashmixTheme
  public buttonVariant = DashmixButtonVariantMap
  public icons = DashmixIconName
  public isEmpty: (value?: any) => boolean = isEmpty
  public ogFile: AnyObject | null = null
  public twFile: AnyObject | null = null

  public get hasOgImage (): boolean {
    return !isEmpty(this.meta.ogImage) && this.ogFile !== null
  }

  public get hasTwitterImage (): boolean {
    return !isEmpty(this.meta.twitterImage) && this.twFile !== null
  }

  public applyChanges (): void {
    this.payload.onChange(this.meta)
    this.close()
  }

  public deleteImage (key: MetaKeys) {
    if (Object.keys(this.meta).includes(key)) {
      const newMeta = { ...this.meta }
      delete newMeta[key]

      this.meta = newMeta
    }
  }

  public getImageType (url: string) {
    return getImageTypeFromUrl(url)
  }

  public openPicker (key: MetaKeys) {
    let selected
    if (this.meta[key]) {
      selected = {
        value: this.meta[key].value,
        type: RelatedType.File
      }
    }

    this.$emit('swap', {
      toOpen: Picker.Media,
      payload: {
        params: { key },
        selected: selected,
        config: { type: 'photo' }
      },
      override: { meta: this.meta },
      config: { size: SizeMap.XLarge }
    })
  }

  protected async resolveFile (key: MetaKeys, selected: Related<RelatedType.File, Identifier>): Promise<void> {
    try {
      if (key === MetaKeys.OgImage) {
        this.ogFile = await this.payload.relatedService.resolve({ type: RelatedType.File, value: selected.value })
      }

      if (key === MetaKeys.TwitterImage) {
        this.twFile = await this.payload.relatedService.resolve({ type: RelatedType.File, value: selected.value })
      }
    } catch (e) {
      console.warn(e)
    }
  }

  protected saveNewPhoto (key: MetaKeys, selected: Related<RelatedType.File, Identifier>) {
    const meta = { ...this.meta }
    this.resolveFile(key, selected)

    meta[key] = selected

    this.meta = meta
  }
}

export default MetaAddonModal
