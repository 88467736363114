// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'

import { INavigation, NavigationData } from '../contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export class Navigation extends Model<NavigationData> implements INavigation {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'driver',
      'from',
      'id',
      'isActive',
      'links',
      'name',
      'slot'
    ]
  }

  public displayName (): string {
    return this._data.name
  }
}
