// Copyright © 2021 Move Closer

import { AbstractIntention } from '@movecloser/front-core'

import { IEditDirectoryIntention } from '../contracts/intentions'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export class EditDirectoryIntention extends AbstractIntention<IEditDirectoryIntention> {
  protected map = {
    name: 'name',
    updatedAt: 'updatedAt'
  }
}
