// Copyright © 2021 Move Closer

import { MappingConfig } from '@movecloser/front-core'

export const adUnitAdapterMap: MappingConfig = {
  fullPath: 'full_path',
  id: 'id',
  name: 'name',
  sizes: 'sizes'
}
