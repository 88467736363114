


























import { Component } from 'vue-property-decorator'

import { Inject } from '@plugin/inversify'
import { Identifier } from '@/shared/contracts/data'
import { Hint } from '@component/Typeahead'

import { DictionaryRepositoryType, IDictionaryRepository } from '@module/root/contracts/repositories'

import { AuthorAddonData } from '../../contracts'
import { Addon, Sources } from '../../maps/variant'

import { AbstractAddon } from './AbstractAddon'
import AuthorSelector from './AuthorSelector.vue'
import { DashmixSelectItem } from '@d24/modules'
import { AddonErrors } from './AddonErrors.vue'

/**
 * @author  Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'AuthorAddon',
  components: { AddonErrors, AuthorSelector }
})
export class AuthorAddon extends AbstractAddon {
  @Inject(DictionaryRepositoryType)
  protected dictRepository!: IDictionaryRepository

  public value: Hint[] = []
  public source: Sources | '' = ''

  public get sourceDictionary (): DashmixSelectItem[] {
    return [
      { label: this.$t('sources.none') as string, value: '' },
      ...Object.values(Sources).map(c => {
        return {
          label: this.$t(`sources.${c}`) as string,
          value: c
        }
      })]
  }

  public onSelect (selectedHint: Hint[]) {
    this.value = selectedHint
    this.onAuthorSelected(this.value)
  }

  public onSourceSelected (selected: Sources) {
    this.source = selected

    const addonPayload = {
      source: selected
    } as unknown as AuthorAddonData

    if (this.value[0]) {
      addonPayload.id = this.value[0].value as unknown as Identifier
      addonPayload.fullName = this.value[0].label
    }

    this.variant.setProperty<AuthorAddonData>(Addon.Author, addonPayload)
    this.onChange(this.variant)
  }

  protected getVariantProperty (): void {
    const authorFromModel = this.variant.getProperty<AuthorAddonData>(Addon.Author)

    if (!authorFromModel) {
      this.value = []
      this.source = ''
      return
    }

    if (authorFromModel.id) {
      this.value = [{
        value: authorFromModel.id,
        label: authorFromModel.fullName
      }]
    }

    this.source = authorFromModel.source || ''
  }

  protected onAuthorSelected (value: Hint[]): void {
    if (value.length === 0) {
      this.variant.setProperty<AuthorAddonData>(Addon.Author, {} as AuthorAddonData)
      return
    }
    if (!value[0].value) {
      this.variant.setProperty<AuthorAddonData>(Addon.Author, {} as AuthorAddonData)
      return
    }

    const addonPayload: AuthorAddonData = {
      id: value[0].value as unknown as Identifier,
      fullName: value[0].label
    }

    if (this.source) {
      addonPayload.source = this.source
    }

    this.variant.setProperty<AuthorAddonData>(Addon.Author, addonPayload)

    this.onChange(this.variant)
  }
}

export default AuthorAddon
