














































import { AnyModule, AnyObject } from '@d24/modules'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { Identifier } from '@/shared/contracts/data'
import { IRelatedService } from '@service/related'
import { ModulesRegistry, PageBuilder, PageBuilderOperationMode } from '@component/PageBuilder'
import { Property } from '@component/Property'

import { UserData } from '@module/auth/contracts/models'

import { ContentType, OnDirtyCallback, OnVariantChangeCallback } from '../contracts'
import { decorateModules } from '../helpers/modules-decorators'
import { pageModules } from '../maps/modules'
import { VariantModel } from '../contracts/models'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'PageVariant',
  components: { PageBuilder, Property }
})
export class PageVariant extends Vue {
  @Prop({ type: Object, required: false, default: null })
  protected authUser!: UserData|null

  @Prop({ type: String, required: true })
  public contentType!: ContentType

  @Prop({ type: Object, required: false, default: null })
  public errors!: AnyObject

  @Prop({ type: Function, required: true })
  protected onDirty!: OnDirtyCallback

  @Prop({ type: Function, required: true })
  protected onVariantChange!: OnVariantChangeCallback

  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  @Prop({ type: Object, required: true })
  public variant!: VariantModel

  public builderMode: PageBuilderOperationMode = PageBuilderOperationMode.EditModules

  public modules: AnyModule[] = []
  public modulesRegistry: ModulesRegistry = pageModules
  public variantErrors: AnyObject = {}

  public get isSmart (): boolean {
    return this.variant.isSmart()
  }

  public get titleError (): string | null {
    return this.variantErrors && this.variantErrors.title && this.variantErrors.title.length > 0
      ? this.variantErrors.title[0] : null
  }

  public get title (): string {
    return this.variant.title
  }

  public set title (value: string) {
    this.variant.set('title', value)
    this.onVariantChange(this.variant)
  }

  created () {
    this.modules = this.variant.modules
  }

  public isEditable (): boolean {
    return this.variant.isEditable(this.authUser ? (this.authUser.id as Identifier) : null)
  }

  public onModulesChange (changed: AnyModule[]): void {
    this.modules = decorateModules(changed)

    this.variant.set('modules', this.modules)
    this.onVariantChange(this.variant)
  }

  public pageBuilderOperationMode (): PageBuilderOperationMode {
    return this.isEditable() ? this.builderMode : PageBuilderOperationMode.Render
  }

  public setPageBuilderOperationMode (value: PageBuilderOperationMode) {
    this.builderMode = value
  }

  @Watch('errors', { deep: true })
  public onErrorsChange (err: AnyObject): void {
    if (err && err.variant && err.variant[this.variant.id]) {
      this.variantErrors = err.variant[this.variant.id]
    } else {
      this.variantErrors = {}
    }
  }
}

export default PageVariant
