// Copyright © 2021 Move Closer

import { Injectable, IResponse, Payload, Repository, ResourceActionFailed } from '@movecloser/front-core'
import { RelatedRecord } from '@d24/modules'

import { IRelatedRepository, RelatedQuery } from './related.contracts'
import { resolveFromStatus } from '@/shared/exceptions/connector-errors'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class RelatedRepository extends Repository<RelatedRecord> implements IRelatedRepository {
  public async load (query: RelatedQuery): Promise<RelatedRecord> {
    const response: IResponse = await this.connector.call('related', 'list', {}, { ...this.composeQuery(query) })

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return response.data.data as RelatedRecord
  }

  /**
   * Compose correct format of query params.
   */
  protected composeQuery (query: RelatedQuery): Payload {
    const composed: Payload = {}

    for (const [key, values] of Object.entries(query)) {
      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
      composed[key] = values!.filter(
        (value, index, self) => self.indexOf(value) === index && !!value
      ).join(',')
    }

    return composed
  }
}
