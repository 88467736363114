// Copyright © 2021 Move Closer

import {
  Collection,
  ICollection,
  Injectable,
  IResponse,
  MappingConfig,
  Repository,
  ResourceActionFailed,
  ResponseType
} from '@movecloser/front-core'

import { Query } from '@/shared/contracts/query'
import { resolveFromStatus } from '@/shared/exceptions/connector-errors'

import { ISubscriptionRepository } from '../contracts/repositories'
import { Subscription } from '../models/subscription'
import { subscriptionAdapterMap } from '../models/subscription.adapter'
import {
  PeriodData,
  Prices,
  SimpleUser,
  SubscriptionData,
  SubscriptionModel,
  TransactionData
} from '../contracts/models'
import { SubscriptionPayload } from '../contracts/data'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Injectable()
export class SubscriptionRepository extends Repository<SubscriptionData> implements ISubscriptionRepository {
  protected useAdapter = true
  protected map: MappingConfig = subscriptionAdapterMap

  public async cancel (id: SubscriptionModel['id']): Promise<string> {
    const response: IResponse = await this.connector.call(
      'subscription',
      'cancel',
      { id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return response.data.data
  }

  public async forceCancel (id: SubscriptionModel['id']): Promise<string> {
    const response: IResponse = await this.connector.call(
      'subscription',
      'forceCancel',
      { id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return response.data.data
  }

  public async create (id: SimpleUser['id'], payload: SubscriptionPayload): Promise<void> {
    const response: IResponse = await this.connector.call(
      'subscription',
      'create',
      { id },
      payload
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async downloadInvoice (slug: string): Promise<Blob> {
    const response: IResponse = await this.connector.call(
      'subscription',
      'downloadInvoice',
      { slug },
      {},
      {},
      ResponseType.Blob
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        response.errors?.errors
      )
    }

    return response.data as Blob
  }

  public async getPrices (): Promise<Prices> {
    const response: IResponse = await this.connector.call(
      'subscription',
      'getPrices'
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return response.data.data as unknown as Prices
  }

  public async getTransactionsDetails (id: TransactionData['id']): Promise<object> {
    const response: IResponse = await this.connector.call(
      'subscription',
      'downloadTransactionDetails',
      { id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        response.errors?.errors
      )
    }

    return response.data.data
  }

  public async load (id: SubscriptionModel['id']): Promise<SubscriptionModel> {
    const response: IResponse = await this.connector.call(
      'subscription',
      'get',
      { id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeModel(
      response.data.data,
      Subscription
    )
  }

  public async loadCollection (query: Query): Promise<ICollection<SubscriptionModel>> {
    const response: IResponse = await this.connector.call(
      'subscription',
      'list',
      {},
      {
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Subscription,
      response.data.meta
    )
  }

  public async loadPeriods (id: SubscriptionModel['id'], query: Query): Promise<ICollection<PeriodData>> {
    const response: IResponse = await this.connector.call(
      'subscription',
      'periods',
      { id },
      {
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return new Collection(response.data.data as PeriodData[], response.data.meta)
  }

  public async loadTransactions (id: SubscriptionModel['id'], query: Query): Promise<ICollection<TransactionData>> {
    const response: IResponse = await this.connector.call(
      'subscription',
      'transactions',
      { id },
      {
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return new Collection(response.data.data as TransactionData[], response.data.meta)
  }

  public async markAsPaid (subscriptionId: SubscriptionModel['id'], id: PeriodData['id']): Promise<void> {
    const response: IResponse = await this.connector.call(
      'subscription',
      'markAsPaid',
      {
        subscriptionId,
        id
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async sendPaymentEmail (subscriptionId: SubscriptionModel['id'], id: PeriodData['id']): Promise<void> {
    const response: IResponse = await this.connector.call(
      'subscription',
      'sendPaymentEmail',
      {
        subscriptionId,
        id
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async update (id: SimpleUser['id'], payload: SubscriptionPayload): Promise<void> {
    const response: IResponse = await this.connector.call(
      'subscription',
      'update',
      { id },
      payload
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }
}
