// Copyright © 2021 Move Closer

import {
  ApiConnectorType,
  AppModule,
  Container,
  IConfiguration,
  IConnector,
  Interfaces,
  Module
} from '@movecloser/front-core'

import {
  DirectoryRepositoryType,
  FileRepositoryType,
  GalleryRepositoryType,
  IFileRepository,
  IDirectoryRepository,
  IGalleryRepository
} from '@module/media/contracts'
import { resources } from './resources'
import { routesFactory } from './routes'
import { FileRepository } from '@module/media/repositories/file'
import { DirectoryRepository } from '@module/media/repositories/directory'
import { GalleryRepository } from '@module/media/repositories/gallery'

/**
 * Loosely based on KSW media module
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@AppModule({
  name: 'media',
  providers: (config: IConfiguration) => {
    return (bind: Interfaces.Bind) => {
      bind<IDirectoryRepository>(DirectoryRepositoryType).to(DirectoryRepository)
      bind<IFileRepository>(FileRepositoryType).to(FileRepository)
      bind<IGalleryRepository>(GalleryRepositoryType).to(GalleryRepository)
    }
  },
  boot (container: Container) {
    const connector: IConnector = container.get(ApiConnectorType)
    connector.useResources(resources)
  },
  routes: routesFactory
})
export class MediaModule extends Module {}
