












































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { Fragment } from 'vue-fragment'
import { RawLocation } from 'vue-router'

import { Inject } from '@/shared/plugins/inversify'
import { Query } from '@/shared/contracts/query'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { ContentModel, ContentStatus, ContentType } from '../contracts'
import { ContentStatusBadge } from '../contracts/models'
import { EditorsStack } from './EditorsStack.vue'

@Component({
  name: 'PagesTableRow',
  components: { EditorsStack, Fragment }
})
export class PagesTableRow extends Mixins<IUserAware>(UserAware) {
  @Prop({ type: Object, required: true })
  public data!: ContentModel

  @Prop({ type: Boolean, required: false, default: false })
  public isParent!: boolean

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public ContentStatus = ContentStatus
  public ContentStatusBadge = ContentStatusBadge
  public ContentType = ContentType

  public get isClickable (): boolean {
    return (this.isParent && this.data.parent !== null) || (!this.isParent && !!this.data.childrenCount)
  }

  public get nodeTarget (): RawLocation {
    return this.isClickable
      ? {
        name: 'content.pages.list',
        query: { parent: this.isParent ? this.data.parent.id.toString() : this.data.id.toString() }
      }
      : ''
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  public get isSearching (): boolean {
    return typeof this.queryParams.q === 'string' && this.queryParams.q.length > 0
  }
}

export default PagesTableRow
