// Copyright © 2022 Move Closer

import { FormStatus, FormType } from '@module/forms/contracts'
import { Identifier } from '@/shared/contracts/data'
import { AbstractIntention } from '@movecloser/front-core'

export interface EditFormIntentionPayload {
  id: Identifier
  title: string
  type: FormType
  status?: FormStatus
  maEventName?: string
}

export class EditFormIntention extends AbstractIntention<EditFormIntentionPayload> {
  protected map = {
    id: 'id',
    maEventName: 'maEventName',
    status: 'status',
    title: 'title',
    type: 'type'
  }
}
