// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'

/**
 * @author Lukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const processAdapterMap: MappingConfig = {
  id: 'processId',
  code: 'resultCode',
  errors: {
    type: MappingTypes.Function,
    value: item => (item.errors || [])
  },
  feedback: 'feedback',
  result: 'result',
  status: 'status',
  type: 'processType'
}
