
































import {
  AnyModule,
  AnyObject,
  DashmixIconName,
  ModuleMode,
  Picker,
  PickerSelectionCallback,
  Related,
  SizeMap,
  Space
} from '@d24/modules'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { IModal, ModalType } from '@movecloser/front-core'
import { VueConstructor } from 'vue'

import { escapeKey } from '@/shared/extensions/events'
import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@plugin/inversify'
import { IRelatedService, RelatedServiceType } from '@service/related'

import { ContentType } from '@module/content/contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<FormDrawer>({
  name: 'FormDrawer',

  created (): void {
    this.setInitialName()
  }

}
)
export class FormDrawer extends Vue {
  @Prop({ type: String, required: true })
  public readonly contentType!: ContentType

  @Prop({ type: Function, required: true })
  public readonly form!: VueConstructor

  @Prop({ type: Object, required: true })
  public readonly module!: AnyModule

  @Prop({ type: Boolean, required: false, default: false })
  public readonly open!: boolean

  @Inject(ModalType)
  private readonly modalConnector!: IModal

  @Inject(RelatedServiceType)
  public readonly relatedService!: IRelatedService

  public readonly DashmixIconName = DashmixIconName

  public data: AnyModule = {} as AnyModule

  public get formMode (): string {
    switch (this.contentType) {
      case ContentType.SmartPage:
        return ModuleMode.Smart
      case ContentType.Article:
      case ContentType.Page:
      default:
        return ModuleMode.Default
    }
  }

  public applyChanges (): void {
    this.$emit('update:module', this.data)
    this.$emit('close')
  }

  public showPicker (picker: Picker, whenSelected: PickerSelectionCallback, selected: Related, config: AnyObject): void {
    this.modalConnector.open(picker, {
      config,
      onSelection: (data: Related<Picker, Identifier>) => {
        whenSelected({
          type: data.type,
          value: data.value
        } as never)
      }
    }, { size: SizeMap.Large })
  }

  public resize (newSize: Space) {
    this.$emit('resize', newSize)
  }

  protected escapePressed () {
    this.$emit('close')
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  protected escapeHandler = escapeKey.bind(this)

  @Watch('open')
  protected onOpenChange (value: boolean): void {
    if (value) {
      this.data = JSON.parse(
        JSON.stringify({ ...this.module })
      )

      document.body.classList.add('locked')
      document.addEventListener('keyup', this.escapeHandler)
    } else {
      document.body.classList.remove('locked')
      document.removeEventListener('keyup', this.escapeHandler)
    }
  }

  protected setInitialName (): void {
    this.data.content = {
      ...this.data.content,
      name: ''
    } as unknown as AnyModule
  }
}

export default FormDrawer
