// Copyright © 2021 Move Closer

import {
  ICollection,
  Injectable,
  IResponse,
  MappingConfig,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'
import { ContentSneakPeakData } from '@d24/modules/front'

import {
  ISearchRepository,
  SneakPeakModel
} from '../contracts'
import { Query } from '@/shared/contracts/query'
import { SneakPeak } from '@module/content/models/sneak-peak'
import { resolveFromStatus } from '@/shared/exceptions/connector-errors'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Injectable()
export class SearchRepository extends Repository<ContentSneakPeakData, SneakPeak> implements ISearchRepository {
  protected map: MappingConfig = {}
  protected useAdapter = false

  public async load (query: Query): Promise<ICollection<SneakPeakModel>> {
    const response: IResponse = await this.connector.call(
      'search', 'load',
      {},
      query
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        response.data.errors
      )
    }
    return this.composeCollection(
      response.data.data,
      SneakPeak,
      response.data.meta
    )
  }
}
