// Copyright © 2021 Move Closer

import { DashmixIconName } from '@d24/modules'

export interface AlertProps {
  autoTimeout: number|null
  dismissible: boolean
  icon: DashmixIconName|null
  showIcon: boolean
  theme: AlertTheme
  useDefaultIcon: boolean
}

export enum AlertTheme {
  Danger= 'danger',
  Info = 'info',
  Primary = 'primary',
  Success = 'success',
  Warning = 'warning'
}
