// Copyright © 2022 Move Closer

import { PreLoader } from '@/shared/contracts/preloaders'

import { preloadTypo } from '@component/MarkdownEditor'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const preloaders: PreLoader[] = [
  preloadTypo
]
