<!-- Copyright © 2021 Move Closer -->

<template>
  <BaseSection>
    <template slot="heading">
      {{ $t('components.MarkdownEditorGuideModal.sections.table.heading') }}
    </template>

    <pre>
| Column 1 | Column 2 | Column 3 |
| -------- | -------- | -------- |
| John     | Doe      | Male     |
| Mary     | Smith    | Female   |

<small>{{ $t('components.MarkdownEditorGuideModal.sections.table.or-without-columns-alignment') }}</small>
| Column 1 | Column 2 | Column 3 |
| -------- | -------- | -------- |
| John | Doe | Male |
| Mary | Smith | Female |</pre>
  </BaseSection>
</template>

<script>
import BaseSection from './_base'
export default { components: { BaseSection } }
</script>
