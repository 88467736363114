// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'
import { authorAdapterMap } from '@module/content/models/author.adapter'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export const galleryAdapterMap: MappingConfig = {
  editor: {
    type: MappingTypes.Adapter,
    map: authorAdapterMap,
    value: 'editor'
  },
  childrenCount: 'childrenCount',
  createdAt: 'createdAt',
  description: 'description',
  id: 'id',
  title: 'title',
  updatedAt: 'updatedAt'
}
