// Copyright © 2021 Move Closer

import {
  ICollection,
  Injectable,
  IResponse,
  MappingConfig,
  Repository,
  ResourceActionFailed,
  ResponseType
} from '@movecloser/front-core'

import { resolveFromStatus } from '@/shared/exceptions/connector-errors'
import { Identifier } from '@/shared/contracts/data'
import { Query } from '@/shared/contracts/query'

import { CouponData, CouponModel, ICouponRepository, PromotionCouponConfig } from '../contracts'
import { Coupon } from '../models/coupon'
import { couponAdapterMap } from '../models/coupon.adapter'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Injectable()
export class CouponRepository extends Repository<CouponData, Coupon> implements ICouponRepository {
  protected useAdapter: boolean = true
  protected map: MappingConfig = couponAdapterMap

  public async generate (promotionId: Identifier, payload: PromotionCouponConfig): Promise<void> {
    const response: IResponse = await this.connector.call(
      'promotions',
      'generate',
      { promotionId },
      { ...payload }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async list (promotionId: Identifier, query?: Query): Promise<ICollection<CouponModel>> {
    const response: IResponse = await this.connector.call(
      'promotions',
      'codes',
      { promotionId },
      { ...query }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Coupon,
      response.data.meta
    )
  }

  public async download (promotionId: Identifier, query?: Query): Promise<{ filename: string; file: Blob }> {
    console.log('Download')
    const response: IResponse = await this.connector.call(
      'promotions',
      'codesDownload',
      { promotionId },
      {},
      {},
      ResponseType.Blob
    )
    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
    return {
      filename: response.headers['x-content-filename'],
      file: response.data as Blob
    }
  }
}
