

































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Authentication, AuthServiceType, IModal, ModalType } from '@movecloser/front-core'

import { BreadcrumbsProps, DashmixTheme, TableHead, TableRowElement, TableSearchBarAction } from '@d24/modules'

import { DropdownActions } from '@/shared/contracts/content'
import { HeaderInterface } from '@component/InteractiveTable/InteractiveTable.contracts'
import { HasIdentifier, Identifier } from '@/shared/contracts/data'
import { Inject } from '@/shared/plugins/inversify'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { ModelListHeader } from '@component/ModelListHeader/ModelListHeader'
import { Query } from '@/shared/contracts/query'

import { AuthRepositoryType, IAuthRepository } from '@module/auth/contracts/repositories'
import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'
import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { IUserRepository, UserRepositoryType } from '../contracts/repositories'
import { UsersTableRow } from '../components/UsersTableRow.vue'
import { UserData, UserModel } from '../contracts/models'
import { usersFiltersConfig } from '../models/user.filters'
import { UserListsActions, userRowActionsFactory, usersTableHead } from '../maps/users'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'UsersList',
  components: { InteractiveTable, ModelListHeader }
})
export class UsersList extends Mixins<IUserAware>(UserAware) {
  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(AuthRepositoryType)
  protected authRepository!: IAuthRepository

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(UserRepositoryType)
  protected userRepository!: IUserRepository

  public isLoading = false

  public get header (): HeaderInterface {
    const header: Partial<HeaderInterface> = {
      title: 'users.listTitle'
    }

    if (this.canCreateUsers) {
      header.buttonLabel = 'users.addUser'
      header.linkTarget = { name: 'users.create' }
    }

    return header as HeaderInterface
  }

  public readonly filtersConfig = usersFiltersConfig
  public rowComponent = UsersTableRow
  public tableHead: TableHead = usersTableHead.map(item => {
    return {
      ...item,
      label: `${this.$t(item.label)}`
    }
  })

  public tableData: TableRowElement[] =[]

  public readonly defaultNumberOfRows: number = 100
  public itemsTotal: number = 0
  public totalPages: number = 0

  public rowActions: TableSearchBarAction[] = []

  public actions: DropdownActions = {
    [UserListsActions.Unlock]: {
      callback: (data: unknown) => {
        return this.switchBlock(data as UserModel)
      },
      confirmation: {
        header: 'users.unlock.header',
        contentText: 'users.unlock.text',
        theme: DashmixTheme.Success,
        buttonLabel: 'users.unlock.button'
      }
    },
    [UserListsActions.UnlockShadowban]: {
      callback: (data: unknown) => {
        return this.switchShadowban(data as UserModel)
      },
      confirmation: {
        header: 'users.unlock.header',
        contentText: 'users.unlock.text',
        theme: DashmixTheme.Success,
        buttonLabel: 'users.unlock.button'
      }
    },
    [UserListsActions.Edit]: {
      callback: (data: unknown) => {
        this.$router.push({ name: 'users.edit', params: { id: (data as UserData).id.toString() } })
      }
    },
    [UserListsActions.Delete]: {
      callback: (data: unknown) => {
        return this.onDelete((data as HasIdentifier).id)
      },
      confirmation: {
        header: 'users.delete.header',
        contentText: 'actions.delete.contentText',
        theme: DashmixTheme.Danger,
        buttonLabel: 'atoms.delete'
      }
    },
    [UserListsActions.ResetPassword]: {
      callback: (data: unknown) => {
        return this.onReset(data as UserData)
      },
      confirmation: {
        header: 'users.reset.header',
        contentText: 'users.reset.text',
        theme: DashmixTheme.Info,
        buttonLabel: 'users.reset.button'
      }
    },
    [UserListsActions.Block]: {
      callback: (data: unknown) => {
        return this.switchBlock(data as UserModel)
      },
      confirmation: {
        header: 'users.block.header',
        contentText: 'users.block.text',
        theme: DashmixTheme.Danger,
        buttonLabel: 'users.block.button'
      }
    },
    [UserListsActions.Shadowban]: {
      callback: (data: unknown) => {
        return this.switchShadowban(data as UserModel)
      },
      confirmation: {
        header: 'users.shadowban.header',
        contentText: 'users.shadowban.text',
        theme: DashmixTheme.Danger,
        buttonLabel: 'users.shadowban.button'
      }
    }
  }

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('users.listTitle')}`,
          target: { name: 'users.list' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  mounted () {
    this.loadList(this.queryParams)

    this.rowActions = userRowActionsFactory(this.domain, this.user).map(a => {
      const guard = a.guard?.bind(null)
      const action = a.action as UserListsActions
      const actionsRequiringCustomGuard = [
        UserListsActions.Unlock,
        UserListsActions.UnlockShadowban,
        UserListsActions.Block,
        UserListsActions.Shadowban
      ]

      if (actionsRequiringCustomGuard.includes(action)) {
        a.guard = (data: unknown) => {
          const model = data as UserModel
          if (this.authService.user && model.id === this.authService.user.id) {
            return false
          }
          return guard ? guard(data) : true
        }
      }

      return a
    })
  }

  protected loadList (query: Query): void {
    this.isLoading = true
    this.userRepository.loadCollection({ perPage: `${this.defaultNumberOfRows}`, ...query }).then(collection => {
      this.isLoading = false

      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: this.canDeleteUsers || this.canEditUsers,
          data: model
        }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.itemsTotal = collection.meta.total
    }).catch(error => {
      console.log(error)
      this.isLoading = false
    })
  }

  public switchBlock (model: UserModel) {
    this.userRepository.switchActive(model.id as Identifier, !model.isBlocked, model)
      .then(newModel => {
        this.tableData = this.tableData.map(rowElement => {
          if (`${rowElement.id}` === `${model.id}`) {
            return {
              ...rowElement,
              data: newModel
            }
          }
          return rowElement
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  public switchShadowban (model: UserModel) {
    this.userRepository.switchShadowban(model.id as Identifier, !model.isShadowbanned, model)
      .then(newModel => {
        this.tableData = this.tableData.map(rowElement => {
          if (`${rowElement.id}` === `${model.id}`) {
            return {
              ...rowElement,
              data: newModel
            }
          }
          return rowElement
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  public onDelete (id: Identifier) {
    this.isLoading = true
    this.userRepository.delete(id)
      .then(() => {
        this.tableData = this.tableData.filter(rowElement => `${rowElement.id}` !== `${id}`)
      })
      .catch(error => {
        console.debug(error)
      }).finally(() => {
        this.isLoading = false
      })
  }

  public onReset (data: UserData) {
    this.isLoading = true
    this.authRepository.forgotPassword(data.email)
      .catch(error => {
        console.debug(error)
      }).finally(() => {
        this.isLoading = false
      })
  }

  @Watch('queryParams', { deep: true })
  protected onQueryChange (query: Query): void {
    this.loadList(query)
  }
}

export default UsersList
