// Copyright © 2021 Move Closer

import { EventbusType, IEventbus, IModal, ModalType, ResourceActionFailed } from '@movecloser/front-core'
import { Component, Mixins, Watch } from 'vue-property-decorator'

import { ConnectorErrors } from '@/shared/exceptions/connector-errors'
import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@/shared/plugins/inversify'
import { IRelatedService, RelatedServiceType } from '@service/related'
import { Modals } from '@/config/modals'

import {
  AuthorRepositoryType,
  IAuthorRepository,
  IUserRepository,
  UserRepositoryType
} from '@module/users/contracts/repositories'
import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { Addon } from '../../maps/variant'
import {
  AuthorAddonData,
  ContentRepositoryType,
  ContentStatus,
  ContentType,
  IContentRepository,
  IVariantsRepository,
  SaveVariantEvent,
  SaveVariantMode,
  SimpleVariantModel,
  VariantErrors,
  VariantModel,
  VariantsCounts,
  VariantsErrors,
  VariantsRepositoryType,
  VariantsServices
} from '../../contracts'
import {
  AdditionalVariantStatus,
  IVariantStatusService,
  VariantStatus,
  VariantStatusServiceType
} from '../../services/variantStatus'
import {
  createBreadcrumbsFromContent,
  initBreadcrumbs,
  resolveVariantsToLoad,
  resolveVariantToFocus
} from '../../helpers'
import { Variant } from '../../models/variant'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component
export class AbstractEditContent extends Mixins<IUserAware>(UserAware) {
  @Inject(AuthorRepositoryType)
  protected authorsRepository!: IAuthorRepository

  @Inject(UserRepositoryType)
  protected usersRepository!: IUserRepository

  @Inject(ContentRepositoryType)
  protected contentsRepository!: IContentRepository

  @Inject(EventbusType)
  protected eventBus!: IEventbus

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(VariantsRepositoryType)
  protected variantsRepository!: IVariantsRepository

  @Inject(VariantStatusServiceType)
  protected variantsStatusService!: IVariantStatusService

  public activeVariantTab: string = ''
  public breadcrumbs = initBreadcrumbs
  public baseType: ContentType = '' as ContentType
  public contentType: ContentType = '' as ContentType
  protected errors: VariantsErrors = {
    code: null,
    global: null,
    variant: {}
  }

  protected isDirty: boolean = false
  public isDisabled: boolean = false
  public isGeneratingPreview: boolean = false
  public isLoading: boolean = true
  public selectedVariants: VariantModel[] = []
  public variants: SimpleVariantModel[] = []
  public variantsCount: VariantsCounts = {}
  protected variantsServices: VariantsServices = {}

  public get activeVariant (): VariantModel {
    return this.selectedVariants.find(v => `${v.id}` === this.activeVariantTab) as VariantModel
  }

  public set activeVariant (toChange: VariantModel) {
    this.onVariantChange(toChange)
  }

  public get contentId (): Identifier {
    return this.$route.params.id as unknown as Identifier
  }

  created () {
    this.loadContentWithBaseVariants(this.$route.params.id as unknown as Identifier)
  }

  beforeDestroy (): void {
    this.variantsStatusService.unsubscribe()
  }

  /**
   * Clear global errors.
   */
  public clearGlobalError (): void {
    this.errors.global = null
  }

  public onStatusChange (status: VariantStatus, editorId?: Identifier): void {
    if (status === AdditionalVariantStatus.Deleted) {
      this.$router.push({ name: `content.${this.baseType}s.list` })
      return
    } else if (status === AdditionalVariantStatus.Locked) {
      this.lockVariant()
      return
    } else if (status === AdditionalVariantStatus.EditorChange) {
      if (!editorId) {
        throw new Error('[AbstractEditContent], editorId must be provided when editorChange')
      }
      this.changeVariantEditor(editorId)
      return
    }
    switch (status) {
      case ContentStatus.InAcceptance:
        this.$router.push({
          name: 'content.acceptance.show',
          params: {
            contentType: this.baseType,
            id: String(this.activeVariant.id)
          }
        })
        return
      case ContentStatus.Archived:
      case ContentStatus.Accepted:
      case ContentStatus.Published:
      case ContentStatus.Rejected:
      case ContentStatus.Draft:
      case ContentStatus.Unpublished:
        this.loadContentWithBaseVariants(this.$route.params.id as unknown as Identifier)
    }
  }

  public getActiveVariantErrors (): VariantErrors {
    return this.errors.variant[`${this.activeVariant.id}`] || {}
  }

  /**
   * Generate variant's preview with optional save.
   */
  public previewVariant (): void {
    this.isGeneratingPreview = true

    if (this.isDirty) {
      this.modalConnector.open(Modals.VariantPreviewDecision, {
        cancel: () => (this.isGeneratingPreview = false),
        generate: () => this.generatePreview(),
        saveAndGenerate: () => this.generatePreview(true)
      }, { closableWithOutsideClick: false })
    } else {
      this.generatePreview()
    }
  }

  public resolveVariantServices (variant: Identifier): IRelatedService {
    if (!(variant in this.variantsServices)) {
      this.variantsServices[variant] = this.$container!.get<IRelatedService>(RelatedServiceType)
    }

    return this.variantsServices[variant]
  }

  /**
   * Save current changes on variant.
   */
  public sendWebPush (): void {
    this.isDisabled = true

    this.contentsRepository.sendPush(this.$route.params.id as unknown as Identifier).then(() => {
      this.isDirty = false
    }).catch((error: ResourceActionFailed) => {
      this.errors.global = error.message
    }).finally(() => {
      this.isDisabled = false
    })
  }

  /**
   * Save current changes on variant.
   */
  public saveVariant (event: SaveVariantEvent): void {
    this.isDisabled = true

    this.variantsRepository.update(
      this.$route.params.id as unknown as Identifier,
      this.activeVariant.id,
      this.activeVariant.toUpdatePayload(),
      event.postSave
    ).then(() => {
      this.isDirty = false

      if (this.hasAuthorChange()) {
        this.loadNewAuthor()
      }

      if (event.postSave !== SaveVariantMode.None) {
        this.$router.push({ name: `content.${this.baseType}s.list` })
      }
    }).catch((error: ResourceActionFailed) => {
      if ([ConnectorErrors.Conflict, ConnectorErrors.Validation].includes(error.status as ConnectorErrors)) {
        this.errors.global = error.message

        if (error.payload) {
          this.errors.variant[`${this.activeVariant.id}`] = error.payload
        }
      }
    }).finally(() => {
      this.isDisabled = false
    })
  }

  public unpublishVariant (): void {
    this.isDisabled = true

    this.variantsRepository.unpublish(
      this.$route.params.id as unknown as Identifier,
      this.activeVariant.id
    ).then(() => {
      this.isDirty = false

      this.$router.push({ name: `content.${this.baseType}s.list` })
    }).catch((error: ResourceActionFailed) => {
      if ([ConnectorErrors.Conflict, ConnectorErrors.Validation].includes(error.status as ConnectorErrors)) {
        this.errors.global = error.message

        if (error.payload) {
          this.errors.variant[`${this.activeVariant.id}`] = error.payload
        }
      }
    }).finally(() => {
      this.isDisabled = false
    })
  }

  /**
   * Checking then author in addon has change
   * @protected
   */
  private hasAuthorChange (): boolean {
    const addonAuthor = this.activeVariant.getProperty<AuthorAddonData>(Addon.Author)

    if (!addonAuthor || typeof addonAuthor.id === 'undefined') {
      return false
    }

    return addonAuthor.id !== this.activeVariant.author.id
  }

  /**
   * Change variant editor when variant is take over by an other editor
   * @protected
   */
  protected changeVariantEditor (editorId: Identifier): void {
    this.isLoading = true
    this.variantsStatusService.unsubscribe()

    this.usersRepository.load(editorId).then(editor => {
      const variant = this.activeVariant
      variant.set('editor', editor)
      this.activeVariant = Variant.hydrate(variant.toObject())
      this.modalConnector.close()
    }).catch(e => {
      console.warn(e)
    }).finally(() => {
      this.isLoading = false
    })
  }

  /**
   * Generate preview & open as tab.
   * @protected
   */
  protected generatePreview (withSave: boolean = false): void {
    const promise: Promise<string> = withSave
      ? this.variantsRepository.updateAndPreview(
        this.$route.params.id as unknown as Identifier,
        this.activeVariant.id,
        this.activeVariant.toUpdatePayload()
      )
      : this.variantsRepository.preview(
        this.$route.params.id as unknown as Identifier,
        this.activeVariant.id
      )

    promise.then(url => {
      if (this.hasAuthorChange() && withSave) {
        this.loadNewAuthor()
      }

      window.open(url)
    }).catch((error: ResourceActionFailed) => {
      this.errors.code = error.status as string
      this.errors.global = error.message
    }).finally(() => (this.isGeneratingPreview = false))
  }

  /**
   * Loads new author data from API
   * @protected
   */
  protected loadNewAuthor (): void {
    const addonAuthor = this.activeVariant.getProperty<AuthorAddonData>(Addon.Author)

    if (!addonAuthor || typeof addonAuthor.id === 'undefined') {
      return
    }

    this.authorsRepository.load(addonAuthor.id).then(model => {
      this.activeVariant.changeAuthor(model.toOwner())
      this.onVariantChange(this.activeVariant)
    }).catch(e => console.debug(e))
  }

  /**
   * Resolve & load variants due to a strategy.
   * @protected
   */
  protected loadContentWithBaseVariants (id: Identifier): void {
    this.contentsRepository.load(id).then(model => {
      model.set('id', this.$route.params.id)
      this.contentType = model.type
      this.breadcrumbs.items = [
        {
          label: `${this.$t(`content.${this.baseType}.listTitle`)}`,
          target: { name: `content.${this.baseType}s.list` }
        },
        ...createBreadcrumbsFromContent(model, `content.${this.baseType}s.list`, 'parent')
      ]
      if (model.variants) {
        this.variants = model.variants
        this.variantsCount = model.variantsCount
      }

      // TODO: Switch to one call when API is ready to handle 'variants?in:id1|id2|id3'.
      const promises: Promise<number | void>[] = []
      resolveVariantsToLoad(this.variants, this.user).map(v => {
        promises.push(
          this.variantsRepository.load(model.id, v.id)
            .then(variant => this.selectedVariants.push(variant))
            .catch(error => console.error(error))
        )
      })

      Promise.all(promises).finally(() => {
        this.activeVariantTab = resolveVariantToFocus(this.selectedVariants, this.user)
        this.isLoading = false
      })
    })
  }

  /**
   * Lock variant - when variant is take over by other editor.
   * @protected
   */
  protected lockVariant () {
    this.isLoading = true
    this.variantsRepository.lock(this.contentId, this.activeVariant.id).then(() => {
      this.modalConnector.close()
    }).catch((e) => {
      console.warn(e)
    }).finally(() => {
      this.isLoading = false
    })
  }

  /**
   * Force tab switch when user decides to lose changes.
   * @protected
   */
  protected onLosingAccept (id: Identifier): void {
    this.eventBus.emit('ui:edit-mode.force', id)
    this.modalConnector.close()
  }

  /**
   * Watch selectedVariants and update selected Variants list when its empty.
   * @protected
   */
  @Watch('selectedVariants', { deep: true })
  protected onSelectedVariantsEmpty (selectedVariants: VariantModel[]) {
    if (selectedVariants.length === 0) {
      this.isLoading = true
      this.loadContentWithBaseVariants(this.$route.params.id as unknown as Identifier)
    }
  }

  @Watch('activeVariant', { deep: true })
  protected onActiveVariantChange (activeVariant: VariantModel, oldVariant: VariantModel): void {
    // Checking that active variant and user exist
    if (!activeVariant || !this.user) {
      return
    } else if (!oldVariant || activeVariant.id === oldVariant.id) {
      const { id } = this.activeVariant
      if (
        !this.variantsStatusService.hasActiveSubscription(
          id, this.activeVariant.editor?.id as unknown as Identifier) &&
        activeVariant.isEditable(this.user.id as unknown as Identifier)) {
        // If active variant exist and interval is not set - set interval and return
        this.variantsStatusService.subscribeStatus(
          id,
          this.onStatusChange,
          this.activeVariant.editor?.id as unknown as Identifier)
        return
      }
      return
    }
    this.variantsStatusService.unsubscribe()

    if (activeVariant.isEditable(this.user.id as unknown as Identifier)) {
      this.variantsStatusService.subscribeStatus(
        this.activeVariant.id,
        this.onStatusChange,
        this.activeVariant.editor?.id as unknown as Identifier)
    }
  }

  /**
   * Update Vue state due to model's inner changes.
   * @protected
   */
  protected onVariantChange (changed: VariantModel): void {
    // let hasChanged: boolean = false TODO: Remove reference to use this check.
    const list: VariantModel[] = [...this.selectedVariants]
    for (const index in this.selectedVariants) {
      if (list[index].id === changed.id) {
        // TODO: Remove reference to use this check.
        // hasChanged = JSON.stringify(changed.toObject()) !== JSON.stringify(list[index].toObject())
        list[index] = changed
        this.selectedVariants = list
        break
      }
    }

    this.toggleDirty(true)
  }

  /**
   * Fire modal to ask user if it's sure to switch main tabs & lose it's changes.
   * @protected
   */
  protected preventLosingData (id: Identifier): boolean {
    if (!this.isDirty) {
      return true
    }

    this.modalConnector.open(Modals.PreventLoosingData, {
      onConfirm: () => this.onLosingAccept(id),
      onClose: () => this.modalConnector.close()
    })

    return false
  }

  /**
   * Mark form as dirty to be able to trigger modal.
   * @protected
   */
  protected toggleDirty (isDirty: boolean): void {
    this.isDirty = isDirty
  }
}
