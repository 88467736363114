<!-- Copyright © 2021 Move Closer -->

<template>
  <BaseSection>
    <template slot="heading">
      {{ $t('components.MarkdownEditorGuideModal.sections.image.heading') }}
    </template>

    <pre>
#[ id: 123 ]

<small>{{ $t('components.MarkdownEditorGuideModal.sections.image.its-also-possible') }}</small>
#[ src: https://images.unsplash.com/photo-1625153460308-cfda1992d644?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1412&q=80 ]

<small>{{ $t('components.MarkdownEditorGuideModal.sections.image.to-add-alt') }}</small>
#[ id: 123 | <strong>alt: Lorem ipsum</strong> ]

<small>{{ $t('components.MarkdownEditorGuideModal.sections.image.to-add-author') }}</small>
#[ id: 123 | <strong>author: John Doe</strong> ]

<small>{{ $t('components.MarkdownEditorGuideModal.sections.image.to-add-caption') }}</small>
#[ id: 123 | <strong>caption: Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.</strong> ]</pre>

  </BaseSection>
</template>

<script>
import BaseSection from './_base'
export default { components: { BaseSection } }
</script>
