// Copyright © 2021 Move Closer

import { TableHead } from '@d24/modules'

export const feedsTableHead: TableHead = [
  {
    column: 'name',
    label: 'Nazwa',
    sortable: true,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'entriesCount',
    label: 'Liczba wpisów',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'status',
    label: 'Status',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  }
]
