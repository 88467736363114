// Copyright © 2021 Move Closer

import { defineComponent } from '@vue/composition-api'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export const Sidebar = defineComponent({
  name: 'Sidebar',
  template: `
     <nav id="sidebar" aria-label="Main Navigation">
      <!-- Side Header -->
      <div class="content-header">
        <slot name="header" />
      </div>
      <!-- END Side Header -->

      <!-- Sidebar Scrolling -->
      <div class="js-sidebar-scroll">
        <div class="content-side"><slot /></div>
      </div>
      <!-- END Sidebar Scrolling -->
    </nav>
  `
})
