

























import { Component, PropSync, Ref, Vue } from 'vue-property-decorator'
import { DashmixIconName, SizeMap } from '@d24/modules'
import { IModal, ModalType } from '@movecloser/front-core'

import { Inject } from '@plugin/inversify'
import { IRelatedService, RelatedServiceType } from '@service/related'

import { MePayload } from '@module/auth/contracts/data'
import { IMeRepository, MeRepositoryType } from '@module/auth/contracts/repositories'

@Component({
  name: 'ChangeAvatarForm'
})
export class ChangeAvatarForm extends Vue {
  @Ref('fileInput')
  private readonly fileInput!: HTMLInputElement

  @PropSync('avatarFormData', { type: Object, required: true })
  public payload!: MePayload

  @Inject(MeRepositoryType)
  protected meRepository!: IMeRepository

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  public avatarSize: SizeMap = SizeMap.XLarge
  public avatar: File | null = null
  public readonly Icons = DashmixIconName
  public image: string = ''
  public isSaving: boolean = false

  public get filename (): string {
    if (this.avatar === null) {
      return `${this.$t('auth.me.avatarForm.none')}`
    }

    return this.avatar.name
  }

  public get fullName () {
    return this.payload.firstName + this.payload.lastName
  }

  public onBrowseBtnClick (): void {
    this.fileInput.click()
  }

  public onFileInputChange (): void {
    if (this.fileInput.files === null) {
      return
    }

    this.avatar = this.fileInput.files[0]
    this.image = URL.createObjectURL(this.fileInput.files[0])
  }

  public onAvatarSave (): void {
    this.isSaving = true
    if (!this.avatar) {
      return
    }

    this.meRepository.editAvatar(this.avatar)
      .then(() => { this.isSaving = false }
      ).catch(error => console.warn(error)
      ).finally(() => { this.isSaving = false })
  }
}

export default ChangeAvatarForm
