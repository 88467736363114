// Copyright © 2021 Move Closer

import { FilePondOptions } from 'filepond'

/**
 * This config allows to setup fileUploader server settings
 *
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const uploader: FilePondOptions = {
  server: {
    url: process.env.VUE_APP_HTTP_D24_API_BASEURL.replace(/\/\s*$/, ''),
    process: {
      url: '/api/management/storage/files/process/',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-Language': process.env.VUE_APP_HTTP_D24_API_LOCALE || 'en-GB',
        'X-Client': process.env.VUE_APP_HTTP_D24_API_CLIENT
      }
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    patch: {
      url: '/api/management/storage/files/chunk/',
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Accept-Language': process.env.VUE_APP_HTTP_D24_API_LOCALE || 'en-GB',
        'X-Client': process.env.VUE_APP_HTTP_D24_API_CLIENT
      }
    },
    // chunk: '/api/management/storage/files/chunk',
    // chunkServer: {
    //   url: '/api/management/storage/files/chunk/',
    //   method: 'PATCH',
    //   headers: {
    //     Accept: 'application/json',
    //     'Accept-Language': process.env.VUE_APP_HTTP_D24_API_LOCALE || 'en-GB',
    //     'X-Client': process.env.VUE_APP_HTTP_D24_API_CLIENT
    //   }
    // },
    // server: {
    //   url: '/upload',
    //   patch: "/",
    //   headers: {
    //     'X-CSRF-TOKEN': '{{ csrf_token() }}'
    //   },
    // },
    fetch: null,
    revert: null
  },
  chunkUploads: true,
  chunkForce: true,
  chunkSize: 10 * 1024 * 1024,
  maxFileSize: process.env.VUE_APP_UPLOAD_MAX_FILESIZE || '50MB'
}
