// Copyright © 2021 Move Closer

import { MappingConfig } from '@movecloser/front-core'

export const couponAdapterMap: MappingConfig = {
  id: 'id',
  code: 'code',
  isUsed: 'is_used',
  transactionId: 'transaction_id',
  userId: 'user_id',
  promotionId: 'promotion_id',
  usedAt: 'used_at'
}
