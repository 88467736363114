// Copyright © 2021 Move Closer

import { AbstractIntention, MappingTypes } from '@movecloser/front-core'
import { UserPayload } from '../contracts/data'
import { mapRolesToIntention } from '../heplers/mapRoles'

export class EditUserIntention extends AbstractIntention<UserPayload> {
  protected map = {
    avatar: {
      type: MappingTypes.Function,
      value: (model: UserPayload) => {
        if (!model.avatar) {
          return
        }
        return model.avatar.id
      },
      target: 'avatar'
    },
    companyData: 'companyData',
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    isEditor: 'isEditor',
    isActive: 'isActive',
    isBlocked: 'isBlocked',
    isShadowbanned: 'isShadowbanned',
    nickname: 'nickname',
    roles: {
      type: MappingTypes.Function,
      value: (model: UserPayload) => {
        if (!model.roles) {
          return
        }
        return mapRolesToIntention(model.roles)
      },
      target: 'roles'
    },
    password: 'password',
    passwordConfirmation: 'password_confirmation'
  }
}
