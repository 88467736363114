// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'
import { authorAdapterMap } from '@module/content/models/author.adapter'

export const setAdapterMap: MappingConfig = {
  editor: {
    type: MappingTypes.Adapter,
    map: authorAdapterMap,
    value: 'editor'
  },
  childrenCount: 'childrenCount',
  createdAt: 'createdAt',
  id: 'id',
  strategy: 'strategy',
  title: 'title',
  updatedAt: 'updatedAt'
}
