// Copyright © 2021 Move Closer

import { Modals } from '@/config/modals'
import { Identifier } from '@/shared/contracts/data'
import { ConfirmModalPayload } from '@component/ConfirmModal'
import { IModal, Injectable } from '@movecloser/front-core'
import { interval, Subject, Subscription } from 'rxjs'

import { ContentStatus, TakeOverModalPayload } from '../../contracts'
import {
  AdditionalVariantStatus,
  CanDetectStatus,
  IVariantStatusService,
  onStatusChange,
  VariantStatus,
  VariantStatusPayload
} from './variantStatus.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Injectable()
export class VariantStatusService implements IVariantStatusService {
  /**
   * Subscription of API calls
   */
  protected callsInterval: Subscription | null = null

  /**
   *  Variant editor ID
   */
  protected currentEditor: Identifier | undefined

  /**
   *  Variant ID
   */
  protected currentId: Identifier | undefined

  /**
   * Intervals for api calls.
   */
  protected intervals: number | undefined

  /**
   * Modal connector form opening modals
   */
  protected modalConnector: IModal

  /**
   * Variant repository for API calls.
   */
  protected repository: CanDetectStatus

  /**
   * RxJS Subject
   * @see https://rxjs.dev/guide/subject
   */
  protected subject: Subject<VariantStatus>

  /**
   * RxJS Subscription of subject changes
   * @see https://rxjs.dev/guide/subject
   */
  protected subscription: Subscription | null = null

  public constructor (repository: CanDetectStatus, modalConnector: IModal, intervals: number = 10000) {
    this.repository = repository
    this.intervals = intervals
    this.modalConnector = modalConnector
    this.subject = new Subject<VariantStatus>()
  }

  /**
   * @inheritDoc
   */
  public hasActiveSubscription (id: Identifier, currentEditor: Identifier): boolean {
    if (typeof this.currentId !== 'undefined' && id !== this.currentId) {
      throw new Error(`[VariantStatusService] subscription exist for different variant. Variant id: ${this.currentId}`)
    } else if (typeof this.currentEditor !== 'undefined' && currentEditor !== this.currentEditor) {
      throw new Error(`[VariantStatusService] subscription exist for different variant. Variant id: ${this.currentEditor}`)
    }
    return !!this.subscription && !!this.currentId && !!this.currentEditor
  }

  /**
   * @inheritDoc
   */
  public subscribeStatus (id: Identifier, callback: onStatusChange, currentEditor: Identifier): void {
    this.subscription = this.subject.subscribe((status: VariantStatus) => {
      callback(status, this.currentEditor)
    })

    this.currentEditor = currentEditor
    this.currentId = id

    this.callsInterval = interval(this.intervals).subscribe(() => {
      this.callForStatus(id).then(status => {
        this.resolveStatus(status)
      }).catch(e => console.warn(e))
    })
  }

  /**
   * @inheritDoc
   */
  public unsubscribe () {
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.subscription = null
    }

    if (this.callsInterval) {
      this.callsInterval.unsubscribe()
      this.callsInterval = null
    }
  }

  /**
   * Method for single API call
   */
  private async callForStatus (id: Identifier): Promise<VariantStatusPayload> {
    return await this.repository.getStatus(id)
  }

  /**
   * Method when variant is take over
   */
  private onVariantTakeOver (status: VariantStatusPayload, id: Identifier) {
    const payload: TakeOverModalPayload = {
      newStatus: status,
      onAccept: () => {
        // Check if status doesn't change in the meantime
        this.callForStatus(id).then(status => {
          if (status.status === ContentStatus.Draft && !status.deletedAt) {
            this.currentEditor = status.editorId
            this.subject.next(AdditionalVariantStatus.EditorChange)
          } else {
            this.resolveStatus(status)
          }
        })
      },
      onRefuse: () => {
        // Check if status doesn't change in the meantime
        this.callForStatus(id).then(status => {
          if (status.status === ContentStatus.Draft && !status.deletedAt) {
            // If it doesn't call onStatusChange method for variant
            this.subject.next(AdditionalVariantStatus.Locked)
          } else {
            // If it does resolve status
            this.resolveStatus(status)
          }
        })
      }
    }
    this.modalConnector.open(Modals.ContentTakeOver, payload)
  }

  /**
   * Method call when variant status was change
   */
  private onVariantStatusChange (payload: VariantStatusPayload): void {
    const status = payload.deletedAt ? AdditionalVariantStatus.Deleted : payload.status

    const onConfirm = () => {
      this.modalConnector.close()
      this.subject.next(status)
    }

    const modalPayload: ConfirmModalPayload = {
      content: {
        header: `variant.${status}.header`,
        contentText: `variant.${status}.contentText`,
        contentTitle: payload.editorFullname,
        buttonLabel: `variant.${status}.confirm`
      },
      noControls: true,
      onConfirm: onConfirm
    }
    this.modalConnector.open(Modals.Confirm, modalPayload)
  }

  /**
   * Method resolve status and take proper action
   */
  private resolveStatus (status: VariantStatusPayload): void {
    if (typeof this.currentEditor === 'undefined') {
      throw new Error('Can not resolveStatus because variant currentEditor not set in [VariantStatusService]')
    }

    if (!this.currentId) {
      throw new Error('Can not resolveStatus because variant id not set in [VariantStatusService]')
    }

    if (status.deletedAt || status.status !== ContentStatus.Draft) {
      this.onVariantStatusChange(status)

      if (this.callsInterval) {
        this.callsInterval.unsubscribe()
      }

      return
    }

    if (status.editorId !== this.currentEditor) {
      this.onVariantTakeOver(status, this.currentId)

      if (this.callsInterval) {
        this.callsInterval.unsubscribe()
      }
    }
  }
}
