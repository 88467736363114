




































import { Component, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@d24/modules'

import { Inject } from '@plugin/inversify'
import { Loader } from '@component/Loader'

import { IUserRepository, UserRepositoryType } from '../contracts/repositories'
import { UsersInfoData } from '../contracts/models'

/**
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<UsersOverviewWidget>({
  name: 'UsersOverviewWidget',
  components: { Loader },

  created (): void {
    this.loadUsersInfo()
  }
})
export class UsersOverviewWidget extends Vue {
  @Inject(UserRepositoryType)
  protected userRepository!: IUserRepository

  public data: UsersInfoData | null = null
  public icon = DashmixIconName
  public isLoading: boolean = false

  public get latestCount (): string {
    return (this.data?.latestUserCount && typeof this.data?.latestUserCount === 'number') ? String(this.data?.latestUserCount) : 'b/d'
  }

  protected loadUsersInfo (): void {
    this.isLoading = true
    this.userRepository.loadUsersInfo().then((res: UsersInfoData) => {
      this.isLoading = false
      this.data = res
    })
  }
}

export default UsersOverviewWidget
