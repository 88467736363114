

































import { AnyObject, BreadcrumbsProps, RelatedType } from '@d24/modules'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { IModal, ModalType } from '@movecloser/front-core'

import { EditModeLayout } from '@component/EditModeLayout/EditModeLayout'
import { FullScreenLoader } from '@/shared/layouts/FullScreenLoader'
import { Identifier } from '@/shared/contracts/data'
import { initBreadcrumbs } from '@module/content/helpers'
import { Inject } from '@plugin/inversify'
import { Loader } from '@component/Loader'
import { Sidebar } from '@/shared/layouts/partials/Sidebar'

import { DictionaryRepositoryType, IDictionaryRepository } from '@module/root/contracts/repositories'
import { IRelatedService, RelatedServiceType } from '@service/related'

import { EditSetIntention, EditSetIntentionPayload } from '../intentions/EditSetIntention'
import {
  ISetItemsRepository,
  ISetsRepository,
  SetItemsDate,
  SetItemsRepositoryType,
  SetModel,
  SetsRepositoryType,
  SetStrategy
} from '../contracts'
import { SetForm } from '../components/SetForm.vue'
import { SetSidebar } from '../components/SetSidebar.vue'
import { LockedArticlesList } from '../components/LockedArticlesList.vue'
import { MetaInfo } from 'vue-meta'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<EditSet>({
  name: 'EditSet',
  components: { EditModeLayout, SetSidebar, FullScreenLoader, Sidebar, SetForm, Loader, LockedArticlesList },

  created () {
    this.loadSet()
  },

  metaInfo (this: EditSet): MetaInfo {
    return {
      title: this.setName
    }
  }
})
export class EditSet extends Vue {
  @Inject(DictionaryRepositoryType)
  protected dictRepository!: IDictionaryRepository

  @Inject(ModalType)
  private modalConnector!: IModal

  @Inject(RelatedServiceType)
  public readonly relatedService!: IRelatedService

  @Inject(SetItemsRepositoryType)
  private setItemsRepository!: ISetItemsRepository

  @Inject(SetsRepositoryType)
  private setsRepository!: ISetsRepository

  public formName: string = 'editSet'
  public payload: EditSetIntentionPayload | null = null
  public isLoading: boolean = false
  public isSaving: boolean = false
  public reset: boolean = false
  public resort: boolean = false
  public resetWithUnlock: boolean = false
  private set: SetModel | null = null
  public setDates: SetItemsDate = { setItemsFrom: '', setItemsTo: '' }
  public setName: string = this.set?.title ?? ''

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('content.sets.listTitle')}`,
          target: { name: 'content.sets.list' }
        },
        {
          label: this.setName,
          target: { name: 'content.sets.create' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get canEdit () {
    if (!this.payload) {
      return false
    }

    return this.payload.strategy === SetStrategy.Manual || this.payload.strategy === SetStrategy.Automatic
  }

  public get setId () {
    return Number(this.$route.params.id)
  }

  public async editSet () {
    if (!this.payload) {
      return
    }

    this.isSaving = true

    try {
      await this.setsRepository.update(this.setId, new EditSetIntention(this.payload))
      this.setName = this.payload.title ?? ''

      if (this.resort) {
        await this.setsRepository.refresh(this.setId)
        this.loadSet()
        this.resort = false
      }
    } catch {} finally {
      this.isSaving = false
    }
  }

  public loadSet () {
    this.isLoading = true

    this.setsRepository.load(this.setId)
      .then(async (set: SetModel) => {
        this.set = set
        const setObject = set.toObject()

        this.payload = {
          ...setObject,
          author: setObject.editor.id
        }

        this.setDates = this.payload!.properties.setItemsDate

        this.setName = this.set.title

        if (!this.payload || !this.payload.properties || !Array.isArray(this.payload.properties.src)) {
          return
        }

        for (const s of this.payload.properties.src) {
          const sources = await this.relatedService.resolve({
            type: s.type as RelatedType,
            value: Number(s.value)
          }) as AnyObject

          switch (s.type) {
            case 'author':
              s.label = `${sources.firstName} ${sources.lastName}`
              break
            case 'tag':
              s.label = sources.name
              break
            default:
              s.label = sources.label
              break
          }
        }
      })
      .catch(error => {
        console.warn(error)
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  public handleSourcesUpdate (newPayload: EditSetIntentionPayload) {
    this.payload = newPayload
  }

  public handleTitleUpdate (newTitle: string) {
    if (!this.payload) {
      return
    }

    this.payload.title = newTitle
  }

  public handleShowOlderThanUpdate (newShowOlderThan: number | null) {
    if (!this.payload) {
      return
    }

    this.payload.properties.showOlderThanHours = newShowOlderThan
  }

  public handleReset (value: {reset: boolean; resetLockedItems: boolean}) {
    this.reset = value.reset
    this.resetWithUnlock = value.resetLockedItems
  }

  public handleResort (value: boolean) {
    this.resort = value
  }

  public lock (itemId: Identifier) {
    this.setItemsRepository.lock(this.setId, +itemId).then(() => {
      this.loadSet()
    })
  }

  public unlock (itemId: Identifier): void{
    this.setItemsRepository.unlock(this.setId, +itemId).then(() => {
      this.loadSet()
    })
  }

  @Watch('reset')
  private async onReset (value: boolean): Promise<void> {
    if (value) {
      this.isLoading = true
      await this.setsRepository.reset(this.setId, this.resetWithUnlock).then(() => {
        this.loadSet()
        this.reset = false
      }).catch(e => console.warn(e)).finally(() => { this.isLoading = false })
    }
  }
}

export default EditSet

