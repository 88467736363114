// Copyright © 2021 Move Closer

import { ComponentObjectPropsOptions } from '@d24/modules'
import { PropType } from '@vue/composition-api'

import { LoaderProps, LoaderVariant } from './Loader.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const loaderProps: ComponentObjectPropsOptions<LoaderProps> = {
  variant: {
    type: String as PropType<LoaderVariant>,
    required: false,
    default: LoaderVariant.Dark
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const useLoader = (props: LoaderProps) => {
  const classStack: string[] = []

  switch (props.variant) {
    case LoaderVariant.White:
      classStack.push('color-white')
      break
    case LoaderVariant.Dark:
    default:
      break
  }

  return { classStack }
}
