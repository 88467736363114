












































import { Component } from 'vue-property-decorator'
import { DashmixIconName } from '@d24/modules'
import { MetaInfo } from 'vue-meta'

import { Alert, AlertTheme } from '@component/Alert'
import { ConnectorErrors } from '@/shared/exceptions/connector-errors'
import { EditModeLayout, EditModeTabsProps } from '@component/EditModeLayout'
import { Identifier } from '@/shared/contracts/data'

import { AbstractEditContent } from './abstracts/AbstractEditContent'
import { ArticleVariant } from '../components/ArticleVariant.vue'
import { ContentType, SaveVariantMode } from '@module/content/contracts'
import { VariantActions } from '../components/VariantActions.vue'
import { VariantAddons } from '../components/VariantAddons.vue'
import { VariantTab } from '../components/VariantTab.vue'
import { VariantTabActions } from '../components/VariantTabActions.vue'
import { VariantsSelection } from '../components/VariantsSelection.vue'
import { debounce } from 'lodash'

/**
 * This view uses an abstract that unifies all actions available for Content management.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @parent AbstractEditContent
 *
 * @property {VariantModel} activeVariant
 * @property {string} activeVariantTab
 * @property {object: DashmixBreadcrumbsProps} breadcrumbs
 * @property {boolean} isDisabled
 * @property {boolean} isLoading
 * @property {VariantModel[]} selectedVariants
 * @property {SimpleVariantModel[]} variants
 */
@Component({
  name: 'EditArticleContent',
  components: {
    Alert,
    ArticleVariant,
    EditModeLayout,
    VariantActions,
    VariantAddons,
    VariantsSelection,
    VariantTab,
    VariantTabActions
  },
  metaInfo (this: EditArticleContent): MetaInfo {
    return {
      title: `${this.$t('content.editTabs.content')}`
    }
  }
})
export class EditArticleContent extends AbstractEditContent {
  /**
   * Available inner-methods:
   * @method void loadContentWithBaseVariants (id: Identifier)
   * @method void onLosingAccept (id: Identifier)
   * @method void onVariantChange (changed: VariantModel)
   * @method boolean preventLosingData (id: Identifier)
   * @method void toggleDirty (isDirty: boolean)
   */
  public baseType: ContentType = ContentType.Article
  public icons = DashmixIconName
  public modeTabs: EditModeTabsProps = {
    items: [
      {
        label: 'content.editTabs.content',
        route: { name: 'content.articles.content', params: { id: this.$route.params.id } }
      },
      {
        label: 'content.editTabs.meta',
        route: { name: 'content.articles.basics', params: { id: this.$route.params.id } },
        guard: (id: Identifier): boolean => {
          return this.preventLosingData(id)
        }
      }
    ],
    initTab: 0
  }

  public isAutosave = false
  public isMarkdown = false

  public saveVariantDebounce = debounce(this.saveVariant, 3000)

  public get alertTheme (): AlertTheme {
    return this.errors.code === `${ConnectorErrors.ServerError}` ? AlertTheme.Danger : AlertTheme.Warning
  }

  /**
   * @inheritDoc
   */
  protected toggleDirty (isDirty: boolean) {
    if (!this.isMarkdown) {
      this.isAutosave = false
    }
    if (this.isAutosave) {
      this.saveVariantDebounce({ postSave: SaveVariantMode.None })
    }
    if (isDirty && this.isMarkdown) {
      this.isAutosave = true
    }
    super.toggleDirty(isDirty)
  }

  protected onMarkdownChange (state: boolean) {
    this.isMarkdown = state
  }

  public get tabs () {
    return this.selectedVariants.map(v => {
      return {
        component: ArticleVariant,
        props: {
          authUser: this.user,
          contentType: this.contentType,
          errors: this.errors,
          onDirty: this.toggleDirty,
          onVariantChange: this.onVariantChange,
          onMarkdown: this.onMarkdownChange,
          relatedService: this.resolveVariantServices(v.id),
          variant: v
        },
        tab: {
          id: `${v.id}`,
          label: VariantTab,
          props: {
            active: this.activeVariant.id,
            authUser: this.user,
            onVariantChange: this.onVariantChange,
            variant: v
          }
        }
      }
    })
  }
}

export default EditArticleContent
