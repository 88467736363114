// Copyright © 2021 Move Closer

import {
  ICollection,
  Injectable,
  MappingConfig,
  ModelPayload,
  QueryParams,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { resolveFromStatus } from '@/shared/exceptions/connector-errors'

import { AddSetItemIntention } from '../intentions/AddSetItemIntention'
import { ChangeItemsOrderIntention } from '../intentions/ChangeItemsOrderIntention'
import { SneakPeek } from '../models/sneak-peek'
import { sneakPeakAdapterMap } from '../models/sneak-peak.adapter'
import { SneakPeekData, SneakPeekModel, ISetItemsRepository, SetData } from '../contracts'
import { Identifier } from '@/shared/contracts/data'
import { AnyObject } from '@d24/modules'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Injectable()
export class SetItemsRepository extends Repository<SneakPeekData, SneakPeek> implements ISetItemsRepository {
  protected map: MappingConfig = sneakPeakAdapterMap
  protected useAdapter = true

  public async add (setId: SetData['id'], intention: AddSetItemIntention): Promise<void> {
    const response = await this.connector.call(
      'setItems',
      'push',
      { setId },
      intention.toRequest()
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async changeOrder (setId: SetData['id'], intention: ChangeItemsOrderIntention) {
    const response = await this.connector.call(
      'setItems',
      'reorder',
      { setId },
      intention.toRequest()
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async loadCollection (setId: SetData['id'], query?: QueryParams, siteId?: Identifier): Promise<ICollection<SneakPeekModel>> {
    let payload: AnyObject = {}

    if (query) {
      payload = { ...query }
    }
    if (siteId) {
      payload.siteId = siteId
    }

    const response = await this.connector.call(
      'setItems',
      'list',
      { setId },
      payload
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data.filter((i: ModelPayload) => i !== null),
      SneakPeek,
      response.data.meta
    )
  }

  public async remove (setId: SetData['id'], itemId: Identifier): Promise<void> {
    const response = await this.connector.call(
      'setItems',
      'remove',
      { setId, itemId }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async lock (setId: SetData['id'], itemId: Identifier): Promise<void> {
    const response = await this.connector.call(
      'setItems',
      'lock',
      { setId, itemId }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async unlock (setId: SetData['id'], itemId: Identifier): Promise<void> {
    const response = await this.connector.call(
      'setItems',
      'unlock',
      { setId, itemId }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }
}
