// Copyright © 2021 Move Closer

import { AnyObject } from '@movecloser/front-core'
import { EmbedContent, EmbedModuleForm, EmbedVersion, ModuleDriver, SizeMap } from '@d24/modules'
import { ExtendedMDE } from 'simplemde'

import { findTokenAtCurrentLine, replaceTokenAtCurrentLine } from '@component/MarkdownEditor/helpers/line-parser'
import { Modals } from '@/config/modals'

import { getErrors, hasErrors } from '../helpers/embed-validation'
import { Shortcut } from '../MarkdownEditor.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const embedShortcut: Shortcut = {
  action: (editor: ExtendedMDE) => {
    const onSelection = (embed: EmbedContent, version: EmbedVersion) => {
      if (hasErrors(embed, version)) {
        setTimeout(() => {
          editor.options.modalConnector.open(Modals.EmbedModal, {
            content: embed,
            version: version,
            errors: getErrors(embed, version),
            onSelection: onSelection
          }, { size: SizeMap.XLarge })
        }, 0)
        return
      }

      if
      (Object.prototype.hasOwnProperty.call(embed, 'thumbnail') &&
        (!Object.prototype.hasOwnProperty.call(embed.thumbnail, 'value') ||
        !Object.prototype.hasOwnProperty.call(embed.thumbnail, 'type'))
      ) {
        delete embed.thumbnail
      }

      if ((Object.prototype.hasOwnProperty.call(embed, 'pdf') || Object.prototype.hasOwnProperty.call(embed, 'pdfAsLink')) && Object.prototype.hasOwnProperty.call(embed, 'videoId')) {
        delete embed.pdf
        delete embed.pdfAsLink
      }

      if ((Object.prototype.hasOwnProperty.call(embed, 'video')) && Object.prototype.hasOwnProperty.call(embed, 'videoId')) {
        delete embed.video
      }

      replaceTokenAtCurrentLine(editor, ModuleDriver.Embed, embed, version)
    }

    let token: AnyObject | null = findTokenAtCurrentLine(editor, ModuleDriver.Embed)
    const isEditing = !!token // Identify typeof token before assign to it initialContent of module
    let version: EmbedVersion = EmbedVersion.Iframe

    if (!token) {
      token = EmbedModuleForm.getInitialContent()
    } else if (Object.prototype.hasOwnProperty.call(token, 'type')) {
      version = token.type
    } else if (Object.prototype.hasOwnProperty.call(token, 'pdf')) {
      version = EmbedVersion.PDF
    } else if (Object.prototype.hasOwnProperty.call(token, 'videoId')) {
      version = EmbedVersion.YouTube
    } else if (Object.prototype.hasOwnProperty.call(token, 'twitter')) {
      version = EmbedVersion.Twitter
    } else if (Object.prototype.hasOwnProperty.call(token, 'video')) {
      version = EmbedVersion.Video
    }
    /*
      Here I add version inside `_content` to identify which Embed Version is editing and compare in @d24/modules
      In  case of any misunderstanding please refer to @d24/modules/*\/Embed.forms.ts
     */
    token.version = version
    editor.options.modalConnector.open(Modals.EmbedModal, {
      content: token,
      isEditMode: isEditing,
      version: version,
      onClose: () => editor.options.modalConnector.close(),
      onSelection: onSelection
    }, { size: SizeMap.XLarge })
  },
  className: 'fas fa-play',
  name: 'embed',
  title: 'Wstaw embed'
}
