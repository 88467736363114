// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export const tagAdapterMap: MappingConfig = {
  id: 'id',
  name: 'name',
  slug: 'slug',
  color: 'color'
}
