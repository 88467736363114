













import { Component, Prop, Vue } from 'vue-property-decorator'
import { Fragment } from 'vue-fragment'

import { AuthorModel } from '../contracts'

@Component({
  name: 'EditorsStack',
  components: { Fragment }
})
export class EditorsStack extends Vue {
  @Prop({ type: Array, required: true })
  public editors!: AuthorModel[]

  public getAvatar (editor: AuthorModel): string | null {
    if (typeof editor.avatar !== 'string') {
      return null
    } else {
      return editor.avatar
    }
  }
}

export default EditorsStack
