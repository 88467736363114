// Copyright © 2021 Move Closer

import { loadAuthors, loadCommentStatusDict } from '@/shared/helpers/filters'
import { defaultIgnoredQueryParams, FiltersConfig, FilterType } from '@component/Filters'

/**
 * @author Javon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const commentsFiltersConfig: FiltersConfig = {
  groups: {
    author: { label: 'Autor', key: 'author', type: FilterType.Typeahead, dictionaryLoader: loadAuthors },
    // content: { label: 'Tytuł', key: 'content', type: FilterType.Typeahead, dictionaryLoader: loadContents },
    status: { label: 'Status', key: 'status', type: FilterType.Dictionary, dictionary: loadCommentStatusDict() },
    legacyName: { label: 'Legacy', key: 'legacyName', type: FilterType.String }
  },
  ignore: [...defaultIgnoredQueryParams],
  override: { page: '1' },
  leave: ['perPage']
}
