






















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ICollection } from '@movecloser/front-core'
import { Inject } from '@/shared/plugins/inversify'

import { Identifier } from '@/shared/contracts/data'
import { Item, Node, TreeViewer } from '@component/TreeViewer'
import { Loader } from '@component/Loader'

import { DirectoryNodeData, DirectoryParentData } from '../contracts/models'
import { DirectoryRepositoryType, IDirectoryRepository } from '../contracts'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component({
  name: 'DirectorySelect',
  components: { Loader, TreeViewer }
})
export class DirectorySelect extends Vue {
  @Prop({ type: String, required: true })
  public form!: string

  @Prop({ type: Number, required: false })
  public hiddenDirectory!: Identifier

  @Prop({ type: String, required: false })
  public label!: string

  @Prop({ type: Object, required: false })
  public model!: DirectoryParentData

  @Prop({ type: String, required: true })
  public name!: string

  @Inject(DirectoryRepositoryType)
  protected directoryRepository!: IDirectoryRepository

  public directoriesList: Node[] = []
  public isLoading = false

  public get selectedDirectoriesList (): Item[] {
    return this.mapDirectoryToItemList(this.model)
  }

  mounted () {
    this.loadDirectories()
  }

  public selectDirectory (selectedItemsList: Item[]) {
    this.$emit('update:model', this.mapItemListToDirectory(selectedItemsList))
  }

  public mapItemListToDirectory (selectedItemsList: Item[]): DirectoryParentData | null {
    let tmp: DirectoryParentData | null = null
    for (const item of selectedItemsList) {
      tmp = {
        name: item.label,
        id: item.value as number,
        parent: tmp
      }
    }
    return tmp
  }

  public loadDirectories (): void {
    this.isLoading = true

    this.directoryRepository.loadDirectoryTree()
      .then(model => {
        this.directoriesList = this.mapDirectoriesToTree(model)
      })
      .catch(error => {
        // TODO - handling errors
        throw new Error(error)
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  protected mapDirectoriesToTree (directories: ICollection<DirectoryNodeData>): Node[] {
    if (!directories) {
      return []
    }

    return [...directories]
      .filter(directory => {
        return directory.id !== this.hiddenDirectory
      })
      .map(directory => {
        return {
          label: directory.name,
          value: directory.id,
          children: this.mapDirectoriesToTree(directory.nodes)
        }
      })
  }

  protected mapDirectoryToItemList (directory: DirectoryParentData): Item[] {
    if (!directory) {
      return []
    }
    const dirArray = []
    let tmp: DirectoryParentData | null = directory

    while (tmp !== null) {
      dirArray.unshift({
        label: tmp.name,
        value: tmp.id,
        parent: tmp.parent
      })
      tmp = tmp.parent
    }
    return dirArray
  }
}

export default DirectorySelect
