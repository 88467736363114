

























import { AnyObject, IModal, ModalType } from '@movecloser/front-core'
import { Component } from 'vue-property-decorator'
import {
  Addon,
  DashmixButtonVariantMap,
  DashmixIconName,
  DashmixTheme,
  Related,
  RelatedType,
  SizeMap
} from '@d24/modules'

import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@plugin/inversify'
import { Modals } from '@/config/modals'

import { AbstractAddon } from './AbstractAddon'
import { AddonErrors } from './AddonErrors.vue'
import { RelatedAddonData } from '../../contracts'

/**
 * @author  Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'RelatedArticlesAddon',
  components: { AddonErrors }
})
export class RelatedArticlesAddon extends AbstractAddon {
  @Inject(ModalType)
  protected modalConnector!: IModal

  public relatedArticles: RelatedAddonData[] = []

  // Helpers
  public buttonTheme = DashmixTheme
  public buttonVariant = DashmixButtonVariantMap
  public icons = DashmixIconName

  public deleteArticle (index: number) {
    const newList: RelatedAddonData[] = [...this.relatedArticles]

    if (!newList[index]) {
      return
    }

    newList.splice(index, 1)
    this.relatedArticles = newList

    this.variant.setProperty<Related[]>(Addon.RelatedArticles, this.relatedArticles.map(article => {
      return {
        value: article.id,
        type: RelatedType.Content
      }
    }))

    this.onChange(this.variant)
  }

  public openPicker () {
    const selected = this.relatedArticles.length > 0 ? this.relatedArticles.map(s => {
      return { value: s.id, type: RelatedType.Content }
    }) : []

    this.modalConnector.open(Modals.PickArticle, {
      config: {},
      multiple: true,
      selected: selected,
      onClose: () => this.modalConnector.close(),
      onSelection: this.setArticles
    }, { size: SizeMap.Large })
  }

  protected async describe (lists: Identifier[]): Promise<void> {
    this.relatedArticles = []

    for (const s of lists) {
      try {
        const article: AnyObject = await this.relatedService.describe({ type: RelatedType.Content, value: s })

        this.relatedArticles.push({
          id: s,
          name: article.title
        })
      } catch (e) {
        console.warn(e)
      }
    }
  }

  protected getVariantProperty (): void {
    if (this.variant) {
      this.relatedArticles = []
      const dataFromModel = this.variant.getProperty<Related[]>(Addon.RelatedArticles)

      if (!dataFromModel) {
        return
      }

      this.describe(dataFromModel.map(s => s.value))
    }
  }

  protected setArticles (selected: Related<RelatedType.Content, Identifier>[] | Related<RelatedType.Content, Identifier>) {
    const toLoad = this.relatedArticles.map(a => a.id)

    if (Array.isArray(selected)) {
      toLoad.push(...selected.map(set => set.value))
    } else {
      toLoad.push(selected.value)
    }

    this.describe(toLoad).then(() => {
      this.variant.setProperty<Related[]>(Addon.RelatedArticles, this.relatedArticles.map(article => {
        return {
          value: article.id,
          type: RelatedType.Content
        }
      }))

      this.onChange(this.variant)
    })
  }
}

export default RelatedArticlesAddon
