// Copyright © 2021 Move Closer

import { defineComponent, SetupContext } from '@vue/composition-api'

import { modeSwitcherProps, useModeSwitcher } from './ModeSwitcher.hooks'
import { ModeSwitcherProps } from '../PageBuilder.contracts'

export const ModeSwitcher = defineComponent({
  name: 'ModeSwitcher',
  props: modeSwitcherProps,

  setup (props: ModeSwitcherProps, ctx: SetupContext) {
    return useModeSwitcher(props, ctx)
  },

  template: `
    <div class="mode-switcher btn-group mode-switcher__fixed">
    <D24Button v-for="{ icon, label, mode } in availableModes" :key="mode"
               :theme="isModeEnabled(mode) ? 'primary' : 'light'" variant="alt"
               :title="label"
               @click="enableMode(mode)"
               class="mode-switcher__button">
      <D24Icon v-bind="{ icon }" class="mode-switcher__button__icon" />
      <span class="mode-switcher__label">{{ label }}</span>
    </D24Button>
    </div>
  `
})
