// Copyright © 2021 Move Closer

import { BreadcrumbsItem } from '@d24/modules'
import { SetModel } from '@module/content/contracts'

import { DirectoryModel, DirectoryParentData } from '../contracts/models'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * Creates Link[] for breadcrumbs component.
 */
export function createBreadcrumbsFromDirectory (directory: DirectoryModel, searchMode: boolean = false): BreadcrumbsItem[] {
  const items: BreadcrumbsItem[] = []

  let loopDir: DirectoryParentData|null = directory
  while (loopDir && loopDir.id) {
    items.unshift({
      label: loopDir.name,
      target: { name: 'media.showDirectory', params: { id: loopDir.id.toString() } }
    })

    loopDir = loopDir.parent
  }

  if (searchMode) {
    items.push({
      label: 'Wyszukiwanie',
      target: { name: 'media.showDirectory' }
    })
  }

  return items
}

/**
 * Creates Link[] for breadcrumbs component.
 *
 * @param rootLabel - root gallery list label
 * @param gallery - gallery object to create breadcrumb for
 */
export function createBreadcrumbsFromGallery (rootLabel: string, gallery?: SetModel): BreadcrumbsItem[] {
  const items = []
  items.push({
    label: rootLabel,
    target: { name: 'media.gallery.list' }
  })

  if (gallery) {
    items.push({
      label: gallery.displayName(),
      target: { name: 'media.showGallery', params: { id: gallery.id.toString() } }
    })
  }

  return items
}

/**
 * Creates Link[] for breadcrumbs component.
 *
 * @param rootLabel - root gallery list label
 * @param actionLabel - gallery action label
 * @param action - create or edit
 */
export function createBreadcrumbsFromAction (rootLabel: string, actionLabel: string, action: string): BreadcrumbsItem[] {
  const items = []
  items.push({
    label: rootLabel,
    target: { name: 'media.gallery.list' }
  })

  items.push({
    label: actionLabel,
    target: { name: 'media.gallery.' + action }
  })

  return items
}
