// Copyright © 2021 Move Closer

import { AnyObject, IModal, ModalState, ModalType } from '@movecloser/front-core'
import { Data, onMounted, ref } from '@vue/composition-api'
import { SizeMap } from '@d24/modules'
import { VueConstructor } from 'vue'

import { resolve } from '@plugin/inversify'

import { ModalConfiguration, SwapModalPayload } from './Modal.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function useModal () {
  const modalConnector = resolve<IModal>(ModalType)
  const component = ref<VueConstructor | null>(null)
  const config = ref<ModalConfiguration>({})
  const isOpen = ref<boolean>(false)
  const payload = ref<Data | null>(null)
  const previousModal = ref<SwapModalPayload | null>(null)

  const modalSize = ref<string>(SizeMap.Medium)

  function closeModal (): void {
    modalConnector.close('')

    component.value = null
    config.value = {}
    isOpen.value = false
    payload.value = null
  }

  function clickedClose (): void {
    closeModal()
    previousModal.value = null
  }

  function clickedOutside (): void {
    if (config.value.closableWithOutsideClick ?? true) {
      clickedClose()
    }
  }

  function swapModal (toOpen: SwapModalPayload | null | undefined, forget: boolean = false): void {
    if (!toOpen || typeof toOpen !== 'object' || Object.keys(toOpen).length < 1 || !toOpen.toOpen.length) {
      clickedClose()
      return
    }

    if (isOpen.value && !forget) {
      previousModal.value = {
        toOpen: `${modalConnector.name}`,
        payload: !payload.value ? null : { ...payload.value, ...(toOpen.override ?? {}) },
        config: !config.value ? undefined : { ...config.value }
      }
    }

    closeModal()
    setTimeout(() => modalConnector.open(toOpen.toOpen, toOpen.payload, toOpen.config), 200)
  }

  function openPrevious (intersectedData: AnyObject | undefined = undefined): void {
    if (!previousModal.value) {
      clickedClose()
      return
    }

    const toOpen: SwapModalPayload = { ...previousModal.value }
    if (intersectedData) {
      toOpen.payload = {
        ...(toOpen.payload ?? {}),
        intersectedData
      }
    }

    swapModal(toOpen, true)
  }

  onMounted(() => {
    modalConnector.subscribe((modalState: ModalState) => {
      if (modalConnector.isOpened) {
        config.value = modalState.config
        component.value = modalConnector.getComponent<VueConstructor>(String(modalConnector.name))
        modalSize.value = modalState.config.size ?? SizeMap.Medium
        payload.value = modalState.payload
      }

      isOpen.value = modalState.opened
    })
  })

  return {
    clickedOutside,
    closeModal: clickedClose,
    component,
    config,
    isOpen,
    payload,
    modalSize,
    openPrevious,
    swapModal
  }
}
