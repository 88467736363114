// Copyright © 2021 Move Closer

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { IRelatedService } from '@service/related'

import { mockedDirtyCallback, mockedVariantChangeCallback } from '../../helpers/components'
import { OnVariantChangeCallback, VariantModel } from '../../contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component
export class AbstractAddon extends Vue {
  @Prop({ type: Boolean, required: false, default: true })
  public disabled!: boolean

  @Prop({ type: Function, required: false, default: mockedDirtyCallback })
  protected onChange!: OnVariantChangeCallback

  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  @Prop({ type: Object, required: false, default: mockedVariantChangeCallback })
  protected variant!: VariantModel

  @Prop({ type: Array, required: false })
  public errors!: string[]

  public get hasErrors (): boolean {
    return this.errors && this.errors.length > 0
  }

  mounted () {
    this.getVariantProperty()
  }

  protected getVariantProperty () {
    // To implement at parent.
  }

  @Watch('variant', { deep: true })
  protected onVariantChange (newVariant: VariantModel, oldVariant: VariantModel) {
    if (JSON.stringify(newVariant.toObject()) !== JSON.stringify(oldVariant.toObject())) {
      this.getVariantProperty()
    }
  }
}
