// Copyright © 2021 Move Closer

import { DashmixIconName, DashmixTheme, TableHead, TableSearchBarAction } from '@d24/modules'

import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'

import { canDeleteMedia, canEditMedia, canViewMedia } from '../guards'
import { DirectoryModel, FileModel } from '../contracts'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export const mediaTableHead: TableHead = [
  {
    column: 'thumbnail',
    label: '',
    isSorted: false,
    width: '2.5rem'
  },
  {
    column: 'title',
    label: 'Nazwa pliku',
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'addedBy',
    label: 'Dodał',
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'type',
    label: 'Typ',
    isSorted: false,
    width: '0.25fr'
  },
  {
    column: 'size',
    label: 'Rozmiar',
    isSorted: false,
    width: '0.5fr'
  },
  {
    column: 'createdAt',
    label: 'Data utworzenia',
    isSorted: false,
    width: '0.5fr'
  }
]

export enum MediaActions {
  DeleteDirectory = 'deleteDirectory',
  DeleteFile = 'deleteFile',
  EditDirectory = 'editDirectory',
  EditFile = 'editFile',
  MoveDirectory = 'moveDirectory',
  MoveFiles = 'moveFiles',
  OpenDirectory = 'openDirectory'
}

export const mediaRowActionsFactory = (domain: Domain, user: UserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canViewMedia(domain, user)) {
    actions.push(
      ...[
        {
          label: 'Otwórz',
          icon: DashmixIconName.Folder,
          isBulkAction: false,
          action: MediaActions.OpenDirectory,
          guard: (data: unknown): boolean => {
            const model = data as DirectoryModel
            return !!model.parent
          }
        },
        {
          label: 'Zobacz',
          icon: DashmixIconName.Eye,
          isBulkAction: false,
          action: MediaActions.EditFile,
          guard: (data: unknown): boolean => {
            const model = data as FileModel
            return !!model.directory && !model.pending
          }
        }
      ]
    )
  }

  if (canEditMedia(domain, user)) {
    actions.push(
      ...[
        {
          label: 'Edytuj',
          icon: DashmixIconName.PenSolid,
          isBulkAction: false,
          action: MediaActions.EditDirectory,
          guard: (data: unknown): boolean => {
            const model = data as DirectoryModel
            return !!model.parent
          }
        },
        {
          label: 'Przenieś',
          icon: DashmixIconName.AngleDoubleRightSolid,
          isBulkAction: false,
          action: MediaActions.MoveDirectory,
          guard: (data: unknown): boolean => {
            const model = data as DirectoryModel
            return !!model.parent
          }
        }
      ]
    )
  }

  if (canDeleteMedia(domain, user)) {
    actions.push(
      ...[
        {
          label: 'Usuń',
          icon: DashmixIconName.TrashAltSolid,
          isBulkAction: false,
          action: MediaActions.DeleteDirectory,
          theme: DashmixTheme.Danger,
          guard: (data: unknown): boolean => {
            const model = data as DirectoryModel
            return !!model.parent
          }
        }
      ]
    )
  }

  if (canEditMedia(domain, user)) {
    actions.push(
      ...[
        {
          label: 'Przenieś',
          icon: DashmixIconName.AngleDoubleRightSolid,
          isBulkAction: true,
          action: MediaActions.MoveFiles,
          guard: (data: unknown): boolean => {
            const model = data as FileModel
            return !!model.directory && !model.pending
          }
        },
        {
          label: 'Usuń',
          icon: DashmixIconName.TrashAltSolid,
          theme: DashmixTheme.Danger,
          isBulkAction: true,
          action: MediaActions.DeleteFile,
          guard: (data: unknown): boolean => {
            const model = data as FileModel
            return !!model.directory && !model.pending
          }
        }
      ]
    )
  }

  return actions
}

export const mediaWaitingListRowActionsFactory = (domain: Domain, user: UserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canViewMedia(domain, user)) {
    actions.push(
      ...[
        {
          label: 'Zapisz',
          icon: DashmixIconName.Eye,
          isBulkAction: false,
          action: MediaActions.EditFile,
          guard: (data: unknown): boolean => {
            const model = data as FileModel
            return !!model.directory && !model.pending
          }
        }
      ]
    )
  }

  if (canEditMedia(domain, user)) {
    actions.push(
      ...[
        {
          label: 'Usuń',
          icon: DashmixIconName.TrashAltSolid,
          theme: DashmixTheme.Danger,
          isBulkAction: true,
          action: MediaActions.DeleteFile,
          guard: (data: unknown): boolean => {
            const model = data as FileModel
            return !!model.directory && !model.pending
          }
        }
      ]
    )
  }

  return actions
}
