




















import { AnyObject, IModal, ModalType } from '@movecloser/front-core'
import { Component } from 'vue-property-decorator'
import { DashmixButtonVariantMap, DashmixIconName, DashmixTheme, MediaType, Picker, Related, RelatedType, SizeMap } from '@d24/modules'

import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@plugin/inversify'

import { Addon } from '../../maps/variant'
import { PremiumAttachmentsData } from '../../contracts'

import { AbstractAddon } from './AbstractAddon'
import { AddonErrors } from './AddonErrors.vue'

/**
 * @author  Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'PremiumAddon',
  components: { AddonErrors }
})
export class PremiumAddon extends AbstractAddon {
  @Inject(ModalType)
  protected modalConnector!: IModal

  public premiumAttachments: PremiumAttachmentsData[] = []

  // Helpers
  public buttonTheme = DashmixTheme
  public buttonVariant = DashmixButtonVariantMap
  public icons = DashmixIconName

  public deleteAttachment (index: number): void {
    const newAttachments = [...this.premiumAttachments]

    if (!newAttachments[index]) {
      return
    }

    newAttachments.splice(index, 1)
    this.premiumAttachments = newAttachments

    this.variant.setProperty<PremiumAttachmentsData[]>(Addon.Premium, newAttachments)

    this.onChange(this.variant)
  }

  public openPicker () {
    this.modalConnector.open(Picker.Media, {
      multiple: true,
      onSelection: this.setNewAttachments,
      selected: this.premiumAttachments.map(file => {
        return { value: file.id, type: RelatedType.File }
      }),
      onClose: () => this.modalConnector.close(),
      config: { type: 'premium' }
    }, { size: SizeMap.XLarge })
  }

  protected getVariantProperty (): void {
    if (this.variant) {
      const dataFromModel = this.variant.getProperty<PremiumAttachmentsData[]>(Addon.Premium)

      if (!dataFromModel) {
        this.premiumAttachments = []
        return
      }

      this.premiumAttachments = dataFromModel
    }
  }

  protected setNewAttachments (newAttachments: Related<RelatedType.File, Identifier>[]) {
    const premiumAttachments: PremiumAttachmentsData[] = []

    newAttachments.forEach(async attachment => {
      const file: AnyObject = await this.relatedService.describe({ type: RelatedType.File, value: attachment.value })

      premiumAttachments.push({
        id: file.id,
        icon: file.mime as MediaType,
        filename: file.name
      })
    })

    this.premiumAttachments = premiumAttachments
    this.variant.setProperty<PremiumAttachmentsData[]>(Addon.Premium, this.premiumAttachments)
    this.onChange(this.variant)
  }
}

export default PremiumAddon
