// Copyright © 2021 Move Closer

import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { CaptureConsole } from '@sentry/integrations'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const initSentry = () => {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN_FRONT,
    environment: process.env.VUE_APP_ENV,
    release: process.env.VUE_APP_VERSION,
    integrations: [
      new CaptureConsole({
        levels: ['error', 'warn']
      })
    ],
    attachProps: true,
    logErrors: true,
    Vue
  })
}
