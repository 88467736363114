// Copyright © 2021 Move Closer

import { DashmixIconName, DashmixTheme, TableHead, TableSearchBarAction } from '@d24/modules'

import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'

import { canEditSubscription } from '../guards'
import { SubscriptionData } from '../contracts/models'

export const subscriptionTableHead: TableHead = [
  {
    column: 'user',
    label: 'subscription.table.user',
    sortable: true,
    isSorted: false,
    width: '1.5fr'
  },
  {
    column: 'type',
    label: 'subscription.table.type',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'isActive',
    label: 'subscription.table.isActive',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'status',
    label: 'subscription.table.status',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'period',
    label: 'subscription.table.period',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'nextRenewalAt',
    label: 'subscription.table.nextRenewalAt',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'startedAt',
    label: 'subscription.table.startedAt',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'endedAt',
    label: 'subscription.table.endedAt',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'deactivatedAt',
    label: 'subscription.table.deactivatedAt',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  }
]

export const subscriptionTableHeadShortened: TableHead = [
  {
    column: 'user',
    label: 'subscription.table.user',
    sortable: false,
    isSorted: false,
    width: '1.5fr'
  },
  {
    column: 'type',
    label: 'subscription.table.type',
    sortable: false,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'period',
    label: 'subscription.table.period',
    sortable: false,
    isSorted: false,
    width: '.75fr'
  }
]

export enum SubscriptionListsActions {
  Edit = 'edit',
  Cancel = 'cancel',
  ForceCancel = 'forceCancel',
}

export const subscriptionRowActionsFactory = (domain: Domain, user: UserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canEditSubscription(domain, user)) {
    actions.push(
      {
        label: 'Edytuj',
        icon: DashmixIconName.PenSolid,
        isBulkAction: false,
        action: SubscriptionListsActions.Edit
      }
    )
  }

  if (canEditSubscription(domain, user)) {
    actions.push(
      {
        label: 'Anuluj subskrypcje',
        icon: DashmixIconName.BellSlashSolid,
        needsDoubleClick: false,
        isBulkAction: true,
        action: SubscriptionListsActions.Cancel,
        theme: DashmixTheme.Warning,
        guard: (data: unknown) => {
          return !(data as SubscriptionData).endedAt && !!(data as SubscriptionData).nextRenewalAt
        }
      }
    )
  }
  // if (canEditSubscription(domain, user)) {
  //   actions.push(
  //     {
  //       label: 'Zakończ subskrypcje',
  //       icon: DashmixIconName.BellSlashSolid,
  //       needsDoubleClick: false,
  //       isBulkAction: false,
  //       action: SubscriptionListsActions.ForceCancel,
  //       theme: DashmixTheme.Danger,
  //       guard: () => {
  //         return true
  //       }
  //     }
  //   )
  // }

  return actions
}
