// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export const nodeAdapterMap: MappingConfig = {
  id: {
    type: MappingTypes.Function,
    value: item => parseInt(item.id)
  },
  name: 'name',
  nodes: 'nodes',
  slug: 'slug',
  title: 'title'
}
