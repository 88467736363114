






























import { Authentication, AuthServiceType } from '@movecloser/front-core'
import { Component, Vue } from 'vue-property-decorator'

import { AuthRepositoryType, IAuthRepository } from '@module/auth/contracts/repositories'
import { enterKey, IEnterEvent } from '@/shared/extensions/events'
import { FormInput } from '@/shared/components/form'
import { Inject } from '@/shared/plugins/inversify'
import { UserModel } from '@module/auth/contracts/models'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'ForgotPassword',
  components: { FormInput }
})
export class ForgotPassword extends Vue implements IEnterEvent {
  @Inject(AuthRepositoryType)
  protected authRepository!: IAuthRepository

  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  protected listener = enterKey.bind(this)

  public formName: string = 'forgotPasswordForm'
  public email = ''

  public sending: boolean = false

  public sent = false

  destroyed () {
    window.removeEventListener('keyup', this.listener)
  }

  enterPressed () {
    this.SendForgotForm()
  }

  SendForgotForm () {
    if (this.email) {
      this.sending = true

      this.authRepository.forgotPassword(this.email)
        .then(() => {
          this.sending = false
          this.sent = true
        })
        .catch(() => {
          this.sending = false
        })
    }
  }

  mounted () {
    window.addEventListener('keyup', this.listener)
  }
}
export default ForgotPassword

