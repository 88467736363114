// Copyright © 2021 Move Closer

import { onBeforeMount, onMounted, onUpdated, PropType, SetupContext } from '@vue/composition-api'

import { ComponentObjectPropsOptions } from '@d24/modules'

import { Item, Node, TreeViewProps } from './TreeViewer.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const treeViewProps: ComponentObjectPropsOptions<TreeViewProps> = {
  /**
   * Has selected parent
   */
  skippedRoot: {
    type: Boolean,
    required: false,
    default: false
  },

  /**
   * List of items or nodes
   */
  items: {
    type: Array as PropType<Node[]>,
    required: true
  },

  /**
   * Selected item
   */
  selectedItemsList: {
    type: Array as PropType<Item[]>,
    required: false,
    default: () => {
      return []
    }
  },

  /**
   * Label text
   */
  label: {
    type: String,
    required: false,
    default: ''
  },

  /**
   *  Is select required
   */
  isRequired: {
    type: Boolean,
    required: false,
    default: false
  }
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @param props
 * @param ctx
 */
export const useTreeView = (props: TreeViewProps, ctx: SetupContext) => {
  const { emit } = ctx
  const nodeChildren: Node[][] = []

  const treeContainerHTMLId = 'tree-container-' + props.label
  const treeViewerHTMLId = 'tree-viewer-' + props.label

  let treeContainerHTLMElement: HTMLElement | null
  let treeViewerHTMLElement: HTMLElement | null

  /**
   * Function to handle adding / removing children after selecting node
   * @param node
   * @param nodeLevel
   */
  const handleChildrenList = (node: Node, nodeLevel: number) => {
    const checkChildren = node.children && node.children.length > 0

    // Check if selectedItem exist on current nodeLevel
    // if exist removes previous Node and its children
    if (props.selectedItemsList[nodeLevel]) {
      nodeChildren.splice(nodeLevel)

      // Check if newSelected node is different from previously selected
      // If is add its children to nodeChildren.
      if (props.selectedItemsList[nodeLevel].value !== node.value && checkChildren) {
        nodeChildren.push(node.children)
        // eslint-disable-next-line brace-style
      }
      // FIXME: to delete when adapter can be recursive
      else if (Number(props.selectedItemsList[nodeLevel].value) !== Number(node.value) && checkChildren) {
        nodeChildren.push(node.children)
      }

      // If selectedNode doesn't exist on current nodeLevel
      // Add selectedNode children to nodeChildren.
    } else if (node.children.length > 0) {
      nodeChildren.push(node.children)
    }
  }

  /**
   * Function to handle selecting node
   * @param node
   * @param nodeLevel
   */
  const selectItem = (node: Node, nodeLevel: number) => {
    const selectedItems = [...props.selectedItemsList]
    const itemWithOutChildren = { ...node }

    if (Object.prototype.hasOwnProperty.call(itemWithOutChildren, 'children')) {
      delete itemWithOutChildren.children
    }

    handleChildrenList(node, nodeLevel)

    if (props.skippedRoot) {
      nodeLevel += 1
    }

    // Check if selectedItem exist on current nodeLevel
    // if exist removes previous Node and its children from selectedItems list.
    if (selectedItems[nodeLevel]) {
      selectedItems.splice(nodeLevel)
    }

    // Check if newSelected node is different from previously selected
    //  If it is add selected node without children to selectedNode list.
    if (!props.selectedItemsList[nodeLevel] || props.selectedItemsList[nodeLevel].value !== node.value) {
      selectedItems.push(itemWithOutChildren)
      // eslint-disable-next-line brace-style
    }

    // FIXME: to delete when adapter can be recursive
    else if (!props.selectedItemsList[nodeLevel] || Number(props.selectedItemsList[nodeLevel].value) !== Number(node.value)) {
      selectedItems.push(itemWithOutChildren)
    }

    return emit('selectItems', selectedItems)
  }

  onBeforeMount(() => {
    let nodesToSearch: Node[] = [...props.items]
    const selectedItemsListLength = props.selectedItemsList.length

    // Looks for selectedItems in given data to add its children to nodeChildren.
    // Loop over selectedItemsList and add its children to Nodes to render.
    for (let i = 0; i < selectedItemsListLength; i++) {
      const item = { ...props.selectedItemsList[i] }

      for (const node of nodesToSearch) {
        if (item.value === node.value && node.children.length > 0) {
          nodeChildren.push(node.children)
          nodesToSearch = node.children
          break
        }
        // FIXME: to delete when adapter can be recursive
        if (typeof node.value === 'string' && item.value === parseInt(node.value) && node.children.length > 0) {
          nodeChildren.push(node.children)
          nodesToSearch = node.children
          break
        }
      }
    }
  })

  onMounted(() => {
    // Get DOM elements to allow to manipulate scroll.
    treeContainerHTLMElement = document.getElementById(treeContainerHTMLId)
    treeViewerHTMLElement = document.getElementById(treeViewerHTMLId)
  })

  onUpdated(() => {
    // Handle scroll behavior.
    if (treeContainerHTLMElement && treeViewerHTMLElement) {
      if (treeContainerHTLMElement.clientWidth < treeViewerHTMLElement.clientWidth) {
        treeContainerHTLMElement.scroll({
          top: 0,
          left: treeViewerHTMLElement.clientWidth,
          behavior: 'smooth'
        })
      }
    }
  })

  return {
    selectItem,
    nodeChildren,
    treeContainerHTMLId,
    treeViewerHTMLId
  }
}
