// Copyright © 2021 Move Closer

export enum Permission {
  CreateAuthors = 'authors.create',
  DeleteAuthors = 'authors.delete',
  EditAuthors = 'authors.edit',
  ViewAuthors = 'authors.view',

  AcceptContents = 'contents.accept',
  CreateContents = 'contents.create',
  DeleteContents = 'contents.delete',
  EditContents = 'contents.edit',
  PublishContents = 'contents.publish',
  SendWebPushContents = 'contents.sendWebPush',
  ViewContents = 'contents.view',

  AcceptForms = 'forms.accept',
  CreateForms = 'forms.create',
  DeleteForms = 'forms.delete',
  DownloadForms = 'forms.entries.download',
  EditForms = 'forms.edit',
  ViewForms = 'forms.view',

  ManageTags = 'tags.manage',

  ManageContentsComments = 'comments.manage',
  ViewContentsComments = 'comments.view',

  UploadMedia = 'media.upload',
  DeleteMedia = 'media.delete',
  EditMedia = 'media.edit',
  ViewMedia = 'media.view',

  CreateSets = 'sets.create',
  DeleteSets = 'sets.delete',
  EditSets = 'sets.edit',
  ViewSets = 'sets.view',

  CreateUsers = 'users.create',
  EditUsers = 'users.edit',
  DeleteUsers = 'users.delete',
  ViewUsers = 'users.view',
  ViewUsersLogs = 'logs.view',

  CreateRoles = 'roles.create',
  EditRoles = 'roles.edit',
  DeleteRoles = 'roles.delete',
  ViewRoles = 'roles.view',

  CreateNavigation = 'navigation.create',
  EditNavigation = 'navigation.edit',
  DeleteNavigation = 'navigation.delete',
  ViewNavigation = 'navigation.view',

  CreateLayout = 'layout.create',
  EditLayout = 'layout.edit',
  DeleteLayout = 'layout.delete',
  ViewLayout = 'layout.view',

  ViewSubscription = 'subscriptions.view',
  EditSubscription = 'subscriptions.edit',
  CreateSubscription = 'subscriptions.create',

  MenageInfoBar = 'infoBar.edit',

  EditSettings = 'setting.edit',
  ViewSetting = 'setting.view',

  EditAdsSettings = 'ads.edit',
  ViewAdsSettings = 'ads.view',

  // todo: promotions
  CreatePromotion = 'promo.create',
  EditPromotion = 'promo.edit',
  DeletePromotion = 'promo.delete',
  ViewPromotion = 'promo.view',

  CreateCoupon = 'coupon.create',
  EditCoupon = 'coupon.edit',
  DeleteCoupon = 'coupon.delete',
  ViewCoupon = 'coupon.view',
}

export interface SplitPermission {
  resource: string
  action: string
}

export const splitPermission = (permission: Permission): SplitPermission => {
  const [resource, action] = permission.split('.')
  return {
    resource,
    action
  }
}
