// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'

import { SettingData } from '../contracts/models'

/**
 * @author Olga Milczek <michal.rossian@movecloser.pl>
 */
export class Setting extends Model<SettingData> {
  protected boot (): void {
    this.initialValues = {}
    this.modelProperties = ['type', 'value']
  }
}
