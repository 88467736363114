// Copyright © 2021 Move Closer

import { Model, ModelPayload } from '@movecloser/front-core'

import { Identifiable, Identifier } from '@/shared/contracts/data'

import { Author } from './author'
import {
  ContentData,
  ContentInfoData,
  IContent,
  ISimpleContent,
  VariantModel,
  VariantsCounts
} from '../contracts/models'
import { ContentEditPayload } from '../contracts/data'
import { mapParentToSlug } from '../helpers'
import { SimpleVariant } from '../models/variant'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class Content extends Model<ContentData> implements IContent, Identifiable {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'id',
      'title',
      'slug',
      'type',
      'parent',
      'author',
      'variants',
      'variantsCount',
      'status',
      'properties',
      'publishedDate',
      'createdAt',
      'updatedAt',
      'site',
      'editors',
      'children',
      'childrenCount',
      'url'
    ]
  }

  public authorName (): string {
    return this._data.author.fullName()
  }

  public displayName (): string {
    return this._data.title
  }

  public fullSlug (): string {
    const parts: string[] = [this._data.site.address]

    if (parts[0][parts[0].length - 1] === '/') {
      parts[0] = parts[0].slice(0, -1)
    }

    if (this._data.parent) {
      parts.push(mapParentToSlug(this._data.parent), this._data.slug
      )
    } else {
      if (this._data.slug !== '/') {
        parts.push(this._data.slug)
      }
    }

    return parts.filter(p => p !== '').join('/')
  }

  public identifier (): Identifier {
    return this._data.id
  }

  public isLive (): boolean {
    return this._data.variants.some((variant: VariantModel) => variant.addons.includes('liveFeed'))
  }

  public propertyByKey (key: string): any {
    return key in this._data.properties ? this._data.properties[key] : null
  }

  public toEditPayload (): ContentEditPayload {
    return {
      properties: this._data.properties,
      parent: this._data.parent,
      title: this._data.title,
      type: this._data.type,
      slug: this._data.slug
    }
  }

  public variantsInTotal (): number {
    return Object.values(this._data.variantsCount as VariantsCounts)
      .reduce((acc: number, value: number) => {
        return acc + value
      }, 0)
  }

  protected relatesToAuthor (value: ModelPayload) {
    return this.hasOne(Author, value)
  }

  protected relatesToChildren (values: ModelPayload[]) {
    return this.hasMany(Content, values)
  }

  protected relatesToEditors (values: ModelPayload[]) {
    return this.hasMany(Author, values)
  }

  protected relatesToVariants (values: ModelPayload[]) {
    return this.hasMany(SimpleVariant, values)
  }
}

/**
 * @author Piotr Niewczas <piotr.niewczas@movecloser.pl>
 */
export class SimpleContent extends Model<ContentData> implements ISimpleContent, Identifiable {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = ['id', 'title', 'slug']
  }

  public identifier (): Identifier {
    return this._data.id
  }

  public displayName (): string {
    return this._data.title
  }
}

export class ContentInfo extends Model<ContentInfoData> {
  protected boot (): void {
    this.initialValues = {}
    this.modelProperties = ['articleCount', 'articleDraftCount', 'latestArticleCount']
  }
}
