// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'

import { TagData } from '../contracts/models'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export class Tag extends Model<TagData> {
  protected boot (): void {
    this.initialValues = {}
    this.modelProperties = ['id', 'name', 'slug', 'pluralName', 'count', 'useAsTagPage', 'type', 'deletedAt']
  }
}
