// Copyright © 2021 Move Closer

import { BreadcrumbsItem, BreadcrumbsProps } from '@d24/modules'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const rootBreadcrumb: BreadcrumbsItem = {
  label: 'Home',
  target: { name: 'root.dashboard' }
}

export const initBreadcrumbs: BreadcrumbsProps = {
  items: [],
  root: rootBreadcrumb
}
