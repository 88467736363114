















import { Component, Prop } from 'vue-property-decorator'
import { DashmixButtonVariantMap, DashmixTheme } from '@d24/modules'

import { AbstractAddon } from './AbstractAddon'
import { Addon } from '../../maps/variant'
import { LiveFeedAddonData, VariantModel } from '../../contracts'

@Component<LiveFeedAddon>({
  name: 'LiveFeedAddon'
})
export class LiveFeedAddon extends AbstractAddon {
  @Prop({ type: Object, required: true })
  public variant!: VariantModel

  public buttonTheme = DashmixTheme
  public buttonVariant = DashmixButtonVariantMap
  public liveFeedData: LiveFeedAddonData = { feedId: '', reverseOrder: true }

  protected getVariantProperty () {
    if (this.variant) {
      const liveFeedFromModel = this.variant.getProperty<LiveFeedAddonData>(Addon.LiveFeed)

      if (!liveFeedFromModel) {
        this.liveFeedData = { feedId: '', reverseOrder: true }
        return
      }
      this.liveFeedData = { ...this.liveFeedData, ...liveFeedFromModel }
      this.variant.setProperty<LiveFeedAddonData>(Addon.LiveFeed, this.liveFeedData)
    }
  }

  protected setLiveFeedModel (reverse: boolean) {
    this.liveFeedData.reverseOrder = reverse
    this.variant.setProperty<LiveFeedAddonData>(Addon.LiveFeed, this.liveFeedData)

    this.onChange(this.variant)
  }
}

export default LiveFeedAddon
