// Copyright © 2021 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'

export const resources: ResourcesRegistry = {
  auth: {
    connection: 'd24',
    prefix: 'api',
    methods: {
      account: {
        url: 'access/account',
        method: Methods.Post,
        shorthand: 'registerUser',
        meta: { extendsSession: false }
      },
      forgotPassword: {
        url: 'access/password/send',
        method: Methods.Post,
        shorthand: 'forgotPasswordForm',
        meta: { extendsSession: false, omitXsite: true }
      },
      resetPassword: {
        url: 'access/password/reset',
        method: Methods.Post,
        shorthand: 'resetPasswordForm',
        meta: { extendsSession: false, omitXsite: true }
      },
      refresh: {
        url: 'access/token/refresh',
        method: Methods.Post,
        auth: true,
        shorthand: 'refreshToken',
        meta: { extendsSession: false }
      },
      removeToken: {
        url: 'access/token',
        method: Methods.Delete,
        auth: true,
        shorthand: 'logoutUser',
        meta: { extendsSession: false }
      },
      token: {
        url: 'access/token',
        method: Methods.Post,
        shorthand: 'loginUser',
        meta: { extendsSession: false }
      },
      me: {
        url: 'me',
        method: Methods.Get
      }
    }
  },
  me: {
    prefix: 'api',
    methods: {
      editBasicInformation: {
        url: 'me/profile',
        method: Methods.Put,
        shorthand: 'changeBasicInformationsForm',
        auth: true,
        meta: { toast: true }
      },
      editPassword: {
        url: 'me/password',
        method: Methods.Put,
        auth: true,
        shorthand: 'changePasswordForm',
        meta: { toast: true }
      },
      updateAvatar: {
        url: 'me/avatar',
        method: Methods.Post,
        auth: true,
        meta: { toast: true }
      },
      load: {
        url: 'me',
        method: Methods.Get,
        auth: true
      }
    }
  }
}
