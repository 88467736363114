// Copyright © 2021 Move Closer

import { defineComponent } from '@vue/composition-api'

import { propertyProps, useProperty } from './Property.hooks'
import { PropertyProps } from './Property.contracts'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const Property = defineComponent({
  name: 'Property',
  props: propertyProps,
  setup: (props: PropertyProps) => {
    return useProperty(props)
  },
  template: `
    <div class="property" :class="alignmentClass" :style="{'grid-template-columns': gridStyle}" >
    <slot name="left">
      <span>{{ label }}</span>
    </slot>

    <slot name="right">
      <span>{{ valueToDisplay }}</span>
    </slot>
    </div>
  `
})
