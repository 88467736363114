// Copyright © 2021 Move Closer

import { Injectable } from '@movecloser/front-core'

import { IToastService, ToastType } from './toaster.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class ToastService implements IToastService<string, ToastrOptions> {
  private readonly config: ToastrOptions
  private readonly toastr: Toastr

  constructor (config: ToastrOptions) {
    this.config = config
    this.toastr = require('toastr')
  }

  /**
   * Display error toast.
   */
  public error (content: string, title: string = '', options?: ToastrOptions): void {
    this.toastr.error(content, title, this.composeOptions(options))
  }

  /**
   * Display info toast.
   */
  public info (content: string, title: string = '', options?: ToastrOptions): void {
    this.toastr.info(content, title, this.composeOptions(options))
  }

  /**
   * Display any toast type.
   */
  public push (content: string, type: ToastType, title: string = '', options?: ToastrOptions): void {
    this[type](content, title, options)
  }

  /**
   * Display success toast.
   */
  public success (content: string, title: string = '', options?: ToastrOptions): void {
    this.toastr.success(content, title, this.composeOptions(options))
  }

  /**
   * Display warning toast.
   */
  public warning (content: string, title: string = '', options?: ToastrOptions): void {
    this.toastr.warning(content, title, this.composeOptions(options))
  }

  /**
   * Compose options by merging given with default.
   */
  protected composeOptions (options?: ToastrOptions): ToastrOptions {
    if (!options) {
      return this.config
    }

    return { ...this.config, ...options }
  }
}
