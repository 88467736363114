// Copyright © 2021 Move Closer

import { AnyObject } from '@movecloser/front-core'
import { ExtendedMDE } from 'simplemde'
import { HasDriver, ModuleDriver } from '@d24/modules'

import { Modals } from '@/config/modals'
import { DeMarked } from '@/shared/helpers/demarked'

import { FootnoteModalData, FootnoteModalPayload, FootnotePosition } from '@module/content/components/FootnoteModal'

import { findTokenAtCurrentLine, replaceTokenAtCurrentLine } from '../helpers/line-parser'
import { Shortcut } from '../MarkdownEditor.contracts'

/**
 * The prefix that'll be prepended to the values of the `[href]` & `[id]` attributes.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
const ID_PREFIX: string = 'footnote-'

/**
 * Validates the passed-in `FootnoteModalData`.
 *
 * @param data - The data to validate.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
const validateData = (data: FootnoteModalData): void => {
  const { identifier, text } = data

  if (typeof identifier !== 'string') {
    throw new Error(`footnoteShortcut -> onSelection(): Expected [identifier] to be a type of [string], but got [${typeof identifier}]!`)
  }

  if (identifier.length === 0) {
    throw new Error('footnoteShortcut -> onSelection(): [identifier] can\'t be empty!')
  }

  if (typeof text !== 'string') {
    throw new Error(`footnoteShortcut -> onSelection(): Expected [text] to be a type of [string], but got [${typeof text}]!`)
  }

  if (text.length === 0) {
    throw new Error('footnoteShortcut -> onSelection(): [text] can\'t be empty!')
  }
}

const insertFootnote = (editor: ExtendedMDE, anchor: string, footnote: string): void => {
  const initialCursorPosition = editor.codemirror.getCursor()

  // Inject the 1st part (into the body).
  editor.codemirror.replaceSelection(anchor)

  const lastLineNumber: number = editor.codemirror.lastLine()
  const lastLineLength: number = editor.codemirror.getLine(lastLineNumber).length

  // Stiltedly set the cursor at the very end of the document.
  editor.codemirror.setCursor({
    line: lastLineNumber,
    ch: lastLineLength
  })

  // Inject the 2nd part (on the bottom of the document).
  //
  // For the unknown reasons, the `editor.codemirror.setValue()` method
  // fails to update the property value in the `Vue` component.
  // There's some weird mention about the origin of the change made to the CodeMirror's value,
  // but disabling this prevention didn't help. Happily it guided me in the right direction
  // of doing the same operation using the `editor.codemirror.replaceSelection` method
  // and the following code DOES work!
  //
  // @see - node_modules/vue-simplemde/dist/VueSimpleMDE.common.js:1298
  // @see - node_modules/vue-simplemde/dist/VueSimpleMDE.umd.js:1307
  // @see - https://github.com/F-loat/vue-simplemde/blob/336d6e92f75176ef93862c78b28dccf6b0394cbf/src/index.vue#L108
  editor.codemirror.replaceSelection(`\n\n${footnote}`)

  // Reset the cursor to its original position.
  editor.codemirror.setCursor({
    ...initialCursorPosition,
    ch: initialCursorPosition.ch + anchor.length
  })

  editor.codemirror.focus()
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const footnoteShortcut: Shortcut = {
  action: (editor: ExtendedMDE) => {
    const onSelection: FootnoteModalPayload['onSelection'] = (data: FootnoteModalData, position: FootnotePosition, isEditMode: boolean) => {
      // Validate the data entered by the User.
      validateData(data)

      let { identifier, text } = data

      // Replace spaces with dashes and make the whole string lowercase.
      identifier = identifier.replace(/\s/g, '-').toLocaleLowerCase()

      /**
       * The content to inject on the current cursor position.
       */
      const anchor: string = `<sup>[[${identifier}]](#${ID_PREFIX}${identifier})</sup>`

      /**
       * The content to inject on the bottom of the document.
       */
      const footnote: string = `<sup id="${ID_PREFIX}${identifier}" class="anchor-target">[${identifier}]</sup> ${text}`

      if (!isEditMode) {
        insertFootnote(editor, anchor, footnote)
        return
      }

      if (position === FootnotePosition.Bottom) {
        replaceTokenAtCurrentLine(editor, ModuleDriver.Paragraph, { text: footnote })
        // return
      }

      // Find bottom part and replace its text
      editor.codemirror.eachLine((line: AnyObject) => {
        if (line.text.includes(`<sup id="${ID_PREFIX}${identifier}" class="anchor-target">[${identifier}]</sup>`)) {
          const lineNumber = editor.codemirror.getLineNumber(line)

          editor.codemirror.replaceRange(
            DeMarked.convertObjectsToMD([
              { driver: ModuleDriver.Paragraph, content: { text: footnote } } as HasDriver<ModuleDriver>
            ]),
            { line: lineNumber, ch: 0 },
            { line: lineNumber, ch: line.text.length }
          )
        }
      })
    }

    const token: AnyObject | null = findTokenAtCurrentLine(editor, ModuleDriver.Paragraph)

    let selected: FootnoteModalData | undefined
    // Default set cursor position to top
    let position: FootnotePosition = FootnotePosition.Top
    let isEditMode: boolean = false

    // Method to get footnote
    if (token && Object.prototype.hasOwnProperty.call(token, 'text')) {
      const footnoteStart = token.text.indexOf('<sup')
      const footnoteEnd = token.text.indexOf('</sup>')

      if (footnoteStart !== footnoteEnd) {
        isEditMode = true
        const marker: string = token.text.slice(footnoteStart, footnoteEnd)

        if (marker.includes(`id="${ID_PREFIX}`)) {
          // User point out footnote bottom part
          position = FootnotePosition.Bottom

          const text = token.text.slice(token.text.lastIndexOf('>') + 1).trim()
          const identifier = token.text.slice(token.text.lastIndexOf('[') + 1, token.text.lastIndexOf(']'))

          selected = {
            text,
            identifier
          }
        } else {
          // User point out footnote top part
          position = FootnotePosition.Top
          // So we need to find id of footnote and then finds it bottom part of footnote
          const identifier = marker.slice(marker.lastIndexOf('[[') + 2, marker.lastIndexOf(']]'))

          const editorContent = editor.value()
          const footnoteContent = `<sup id="${ID_PREFIX}${identifier}" class="anchor-target">[${identifier}]</sup>`
          const text = editorContent.slice(editorContent.lastIndexOf(footnoteContent)).replace(footnoteContent, '')

          selected = {
            text,
            identifier
          }
        }
      }
    }

    editor.options.modalConnector.open(Modals.Footnote, {
      onSelection,
      selected,
      position,
      isEditMode
    })
  },
  className: 'fas fa-anchor',
  name: 'footnote',
  title: 'Wstaw przypis'
}
