







































import { BreadcrumbsProps, DashmixIconName, DashmixTheme } from '@d24/modules'
import { Component, Vue } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'
import { ResourceActionFailed } from '@movecloser/front-core'

import { EditModeLayout } from '@component/EditModeLayout/EditModeLayout'
import { FormInput, FormText } from '@/shared/components/form'
import { Loader } from '@component/Loader'
import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'

import { CreateGalleryIntention, EmptyGalleryCreatePayload } from '../intentions/CreateGalleryIntention'
import { GalleryForm } from '../components/GalleryForm.vue'
import { GalleryRepositoryType, IGalleryRepository } from '../contracts'
import { createBreadcrumbsFromAction } from '../helpers'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component({
  name: 'CreateGallery',
  components: { EditModeLayout, GalleryForm, Loader, FormInput, FormText }
})
export class CreateGallery extends Vue {
  @Inject(GalleryRepositoryType)
  private galleryRepository!: IGalleryRepository

  public buttonTheme = DashmixTheme
  public formName: string = 'createGallery'
  public icons = DashmixIconName
  public isLoading: boolean = false
  public payload = { ...EmptyGalleryCreatePayload }

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: createBreadcrumbsFromAction(
        this.$t('media.gallery.listTitle') as string,
        this.$t('media.gallery.listButton') as string,
        'create'
      ),
      root: initBreadcrumbs.root
    }
  }

  public createGallery () {
    this.isLoading = true

    this.galleryRepository.create(new CreateGalleryIntention(this.payload)).then(id => {
      this.$router.push({
        name: 'media.gallery.edit',
        params: { id: id.toString() }
      })
    }).catch((error: ResourceActionFailed) => {
      console.log(error)
      this.isLoading = false
    })
  }

  public goToList () {
    this.$router.push({ name: 'media.gallery.list' })
  }
}

export default CreateGallery

