// Copyright © 2021 Move Closer

import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'
import { Permission } from '@module/auth/contracts/permissions'

/**
 * Contents
 */
export const canAcceptContents = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.AcceptContents)
}

export const canCreateContents = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateContents)
}

export const canDeleteContents = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteContents)
}

export const canEditContents = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditContents)
}

export const canPublishContents = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.PublishContents)
}

export const canSendWebPushContents = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.SendWebPushContents)
}

export const canViewContents = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewContents)
}

export const canManageContentsComments = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ManageContentsComments)
}

export const canViewContentsComments = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewContentsComments)
}

/**
 * Sets
 */
export const canCreateSets = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateSets)
}

export const canViewSets = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewSets)
}

export const canDeleteSets = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteSets)
}

export const canEditSets = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditSets)
}
