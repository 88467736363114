


















































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DashmixIconName, DashmixTheme } from '@d24/modules'

import { Property } from '@component/Property'

import { SimpleRole, SubscriptionModel, SubscriptionsPeriods, SubscriptionsStatuses, UserModel } from '../contracts/models'
import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'
import { Inject } from '@plugin/inversify'
import { DateTimeType, IDateTime } from '@movecloser/front-core'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'UserSiteBar',
  components: { Property }
})
export class UserSiteBar extends Mixins<IUserAware>(UserAware) {
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public canCancel!: boolean

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public isEditMode!: boolean

  @Prop({
    type: Boolean,
    required: true
  })
  public isSaving!: boolean

  @Prop({
    type: Object,
    required: false,
    default: null
  })
  public subscription!: SubscriptionModel

  @Prop({
    type: Object,
    required: true
  })
  public selectedUser!: UserModel

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public icons = DashmixIconName
  public themes = DashmixTheme

  public getRole (role: SimpleRole[]): string {
    return role.map(r => r.name).join(', ')
  }

  public getStatusClass (): string {
    if (this.selectedUser.isBlocked) {
      return 'bg-danger'
    }

    if (this.selectedUser.isShadowbanned) {
      return 'bg-danger'
    }

    return this.selectedUser.isActive ? 'bg-success' : 'bg-warning'
  }

  public get isCancelled (): boolean {
    return this.subscription.period === SubscriptionsPeriods.Monthly && this.subscription.nextRenewalAt === null
  }

  public get subscriptionStatusTheme (): DashmixTheme {
    switch (this.subscription.status) {
      case SubscriptionsStatuses.DaysLeft:
        return DashmixTheme.Success
      case SubscriptionsStatuses.Cancelled:
      case SubscriptionsStatuses.Expiring:
        return DashmixTheme.Warning
      case SubscriptionsStatuses.Expired:
        return DashmixTheme.Danger
      case SubscriptionsStatuses.Paid:
        return DashmixTheme.Success
      case SubscriptionsStatuses.NotStarted:
        return DashmixTheme.Dark
    }
    return DashmixTheme.Default
  }

  public get subscriptionActiveTheme (): DashmixTheme {
    return (this.subscription.isActive ? DashmixTheme.Success : DashmixTheme.Danger)
  }

  public get subscriptionActiveTitle (): string {
    return (this.subscription.isActive ? 'active' : 'inactive')
  }
}

export default UserSiteBar
