





































































import {
  AnyObject,
  DashmixIconName,
  DashmixTheme,
  EmbedModuleForm, EmbedVersion,
  Identifier, isRelated,
  Picker,
  Related,
  RelatedType
} from '@d24/modules'
import { Component, InjectReactive, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import { IModal, ModalType } from '@movecloser/front-core'
import { Inject } from '@plugin/inversify'

import { AlertTheme } from '@component/Alert'
import { ConfirmModalPayload } from '@component/ConfirmModal'
import { Figure } from '@component/Figure/Figure'
import { MINI_SIMPLEMDE_CONFIG } from '@component/MarkdownEditor/MarkdownEditor.config'
import { Modals } from '@/config/modals'
import { ModalSize } from '@component/Modal'
import { IRelatedService, RelatedServiceType } from '@service/related'

import { FileModel, GalleryModel } from '@module/media/contracts'

import { PickerPayload } from '../contracts'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Component({
  name: 'FeedEntryRelatedPicker',
  components: { EmbedModuleForm, Figure }
})
export class FeedEntryRelatedPicker extends Vue {
  @PropSync('image', { type: Object, required: false })
  public imageSync?: Related<RelatedType.File>

  @PropSync('gallery', { type: Object, required: false })
  public gallerySync?: Related<RelatedType.Gallery>

  @PropSync('embed', { type: Object, required: false, default: () => ({}) })
  public embedSync!: AnyObject

  @Prop({ type: Boolean, required: true })
  public hasBeenDeleted!: boolean

  @Prop({ type: Boolean, required: true })
  public hasEmbedContent!: boolean

  @Prop({ type: Boolean, required: true })
  public hasEntryBeenDeleted!: boolean

  @Prop({ type: Boolean, required: true })
  public isEditMode!: boolean

  @Prop({ type: Boolean, required: true })
  public isViewMode!: boolean

  @InjectReactive('isFeedEditable')
  public isFeedEditable!: boolean

  @Inject(ModalType)
  private modalConnector!: IModal

  @Inject(RelatedServiceType)
  private relatedService!: IRelatedService

  /**
   * Helpers
   */
  public alertTheme: AlertTheme = AlertTheme.Danger
  public Icon = DashmixIconName
  public mdeConfig = MINI_SIMPLEMDE_CONFIG

  /**
   * State
   */
  public resolvedGallery: AnyObject | null = null
  public resolvedImage: AnyObject | null = null
  public selectedRelated: RelatedType | 'embed' | null = null

  /**
   * Getters
   */
  public get hasPickedEmbed (): boolean {
    return this.selectedRelated === 'embed' || this.hasEmbedContent
  }

  public get hasPickedGallery (): boolean {
    return this.selectedRelated === RelatedType.Gallery && !!this.gallerySync && !!this.resolvedGallery
  }

  public get hasPickedImage (): boolean {
    return this.selectedRelated === RelatedType.File && !!this.imageSync && !!this.resolvedImage
  }

  public get shouldDisableEmbedForm (): boolean {
    return !this.isFeedEditable || this.hasBeenDeleted || this.isViewMode
  }

  public get shouldShowDeleteEmbedButton (): boolean {
    return this.hasPickedEmbed && !this.isViewMode
  }

  /**
   * Related
   */
  private async addFile (data: Related<RelatedType.File>) {
    this.resolvedImage = await this.relatedService.resolve(data, {})
    this.imageSync = data
    this.selectedRelated = RelatedType.File
  }

  private async addGallery (data: Related<RelatedType.Gallery>) {
    this.resolvedGallery = await this.relatedService.describe(data)
    this.selectedRelated = RelatedType.Gallery
    this.gallerySync = data
  }

  @Watch('selectedRelated')
  private onRelatedSelection () {
    switch (this.selectedRelated) {
      case RelatedType.Gallery:
        this.imageSync = undefined
        this.embedSync = {}
        break
      case RelatedType.File:
        this.gallerySync = undefined
        this.embedSync = {}
        break
      case 'embed':
        this.gallerySync = undefined
        this.imageSync = undefined
        break
    }
  }

  public pickEmbed () {
    this.selectedRelated = 'embed'
  }

  public pickGallery () {
    const payload: PickerPayload<GalleryModel, RelatedType.Gallery, Identifier> = {
      onSelection: (data) => {
        this.addGallery(data as Related<RelatedType.Gallery>)
      }
    }

    this.modalConnector.open(
      Modals.PickGallery,
      payload,
      { size: ModalSize.lg }
    )
  }

  public pickImage () {
    const payload: PickerPayload<FileModel, RelatedType.File, Identifier> = {
      onSelection: (data) => this.addFile(data as Related<RelatedType.File>)
    }

    this.modalConnector.open(
      Modals.PickFile,
      payload,
      { size: ModalSize.lg }
    )
  }

  public pickEmbedRelated (
    picker: Picker.Media,
    pick: (source: Related<RelatedType.File>) => void,
    selected: Related<RelatedType.File, Identifier>,
    config: AnyObject
  ) {
    const payload: PickerPayload<FileModel, RelatedType.File, Identifier> = {
      onSelection: (data) => pick(Array.isArray(data) ? data[0] : data),
      config
    }

    if (selected && isRelated(selected)) {
      payload.selected = selected
    }

    this.modalConnector.open(
      Modals.PickFile,
      payload,
      { size: ModalSize.lg }
    )
  }

  public resetRelated () {
    const modalPayload: ConfirmModalPayload = {
      content: {
        buttonLabel: String(this.$t('atoms.delete')),
        contentText: String(this.$t('content.feeds.items.delete_related_modal.text')),
        header: String(this.$t('content.feeds.items.delete_related_modal.header')),
        theme: DashmixTheme.Danger
      },
      onConfirm: () => this.continueRelatedReset()
    }

    this.modalConnector.open(Modals.Confirm, modalPayload)
  }

  public continueRelatedReset () {
    this.imageSync = undefined
    this.gallerySync = undefined
    this.embedSync = {}
    this.selectedRelated = null
    this.modalConnector.close()
  }

  mounted () {
    this.resolveInitRelated()
  }

  private async resolveInitRelated () {
    if (this.imageSync) {
      await this.addFile(this.imageSync)
    } else if (this.gallerySync) {
      await this.addGallery(this.gallerySync)
    } else if (this.hasPickedEmbed) {
      this.selectedRelated = 'embed'
    }
  }

  @Watch('embedSync')
  onEmbedChanged (embedSync: AnyObject) {
    if (!embedSync.type) {
      this.embedSync = {
        ...embedSync,
        type: EmbedVersion.Iframe
      }
    }
  }
}
export default FeedEntryRelatedPicker
