// Copyright © 2021 Move Closer

import { ICollection, IResponse, QueryParams } from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'
import { Query } from '@/shared/contracts/query'

import {
  AcceptanceData,
  AcceptanceModel,
  AdUnitModel,
  CommentData,
  CommentModel,
  ContentData,
  ContentInfoData,
  ContentModel,
  ContentType,
  FeedData,
  FeedEntryData,
  FeedEntryModel,
  FeedModel,
  NodeModel,
  Properties,
  RegistryData,
  RegistryModel,
  RegistryPayload,
  SetData,
  SetItem,
  SetModel,
  SetType,
  SimpleContentModel, SneakPeakModel,
  SneakPeekModel,
  VariantData,
  VariantModel
} from './models'

import { AddSetItemIntention } from '../intentions/AddSetItemIntention'
import { ChangeItemsOrderIntention } from '../intentions/ChangeItemsOrderIntention'
import {
  ContentCreatePayload,
  IContentOptionsPayload,
  IPublishContentPayload,
  VariantUpdatePayload
} from '../contracts/data'
import { CreateSetIntention } from '../intentions/CreateSetIntention'
import { CreateFeedEntryIntention } from '../intentions/CreateFeedEntryIntention'
import { CreateFeedIntention } from '../intentions/CreateFeedIntention'
import { EditSetIntention } from '../intentions/EditSetIntention'
import { IEditContentIntention } from './intentions'
import { CanDetectStatus } from '../services/variantStatus'
import { UpdateFeedIntention } from '../intentions/UpdateFeedIntention'
import { UpdateFeedEntryIntention } from '../intentions/UpdateFeedEntryIntention'
import { SaveVariantMode } from '@module/content/contracts/components'
import { TargetingKeys } from '@service/ads/ads.config'
import { TargetingOption } from '@service/ads'

export const AcceptanceRepositoryType = Symbol.for('IAcceptanceRepository')

export const AdsRepositoryType = Symbol.for('IAdsRepository')

export const CommentRepositoryType = Symbol.for('ICommentRepository')

export const ContentRepositoryType = Symbol.for('IContentRepository')

export const SearchRepositoryType = Symbol.for('ISearchRepository')

export const RegistryRepositoryType = Symbol.for('IRegistryRepository')

export interface IAcceptanceRepository {
  accept (id: AcceptanceData['id']): Promise<void>

  load (id: AcceptanceData['id']): Promise<AcceptanceModel>

  loadCollection (query: Query): Promise<ICollection<AcceptanceModel>>

  reject (id: AcceptanceData['id'], message: string): Promise<void>

  return (id: AcceptanceData['id'], message: string): Promise<void>
}

export interface IAdsRepository {
  loadCollection (query: Query): Promise<AdUnitModel[]>

  load (id: Identifier): Promise<AdUnitModel>

  loadAdsKeys (key?: TargetingKeys): Promise<TargetingOption[]>
}

export interface ICommentRepository {
  accept (id: CommentData['id']): Promise<void>

  delete (id: CommentData['id']): Promise<void>

  loadCollection (query: Query): Promise<ICollection<CommentModel>>

  reject (id: CommentData['id']): Promise<void>
}

export interface ISearchRepository {
  load (query: Query): Promise<ICollection<SneakPeakModel>>
}

export interface IContentRepository {
  create (payload: ContentCreatePayload): Promise<Identifier>

  changeOrder (parent: ContentData['id'], children: Identifier[]): Promise<ContentModel>

  delete (id: ContentData['id']): Promise<void>

  load (id: ContentData['id']): Promise<ContentModel>

  loadCollection (types: ContentType | ContentType[], query: Query): Promise<ICollection<ContentModel>>

  loadContentInfo (): Promise<ContentInfoData>

  loadNode (types: ContentType | ContentType[], id: ContentData['id'], query: Query): Promise<ContentModel>

  loadTree (query: Query): Promise<ICollection<SimpleContentModel>>

  options (id: ContentData['id'], payload: IContentOptionsPayload): Promise<void>

  properties (id: ContentData['id'], payload: Properties): Promise<void>

  publish (id: ContentData['id'], payload: IPublishContentPayload): Promise<void>

  registry (slug: string): Promise<RegistryPayload>

  update (id: ContentData['id'], payload: IEditContentIntention): Promise<IResponse>

  sendPush (id: ContentData['id']): Promise<IResponse>
}

export interface IRegistryRepository {
  load (id: RegistryData['id']): Promise<RegistryModel>

  loadCollection (query: Query): Promise<ICollection<RegistryModel>>
}

export interface INodesRepository {
  loadTree (query: Query): Promise<ICollection<NodeModel>>
}

export interface ISetsRepository {
  // TODO fix this any type when task manager is ready
  create (intention: CreateSetIntention): Promise<any>

  delete (id: SetData['id']): Promise<void>

  load (id: SetData['id'], siteId?: Identifier): Promise<SetModel>

  loadCollection (contentType: SetType, query: Query, siteId?: Identifier): Promise<ICollection<SetModel>>

  update (setId: SetData['id'], intention: EditSetIntention): Promise<void>

  updateItem (setId: SetData['id'], itemId: SetItem['id'], meta: { title: string; description: string }): Promise<void>

  reset (id: SetData['id'], resetLockedItems: boolean): Promise<void>

  refresh (id: SetData['id']): Promise<void>
}

export interface ISetItemsRepository {
  add (setId: SetData['id'], intention: AddSetItemIntention): Promise<void>

  changeOrder (setId: SetData['id'], intention: ChangeItemsOrderIntention): Promise<void>

  loadCollection (setId: SetData['id'], query?: QueryParams, sitetId?: Identifier): Promise<ICollection<SneakPeekModel>>

  remove (setId: SetData['id'], itemId: Identifier): Promise<void>

  lock (setId: SetData['id'], itemId: Identifier): Promise<void>

  unlock (setId: SetData['id'], itemId: Identifier): Promise<void>
}

export interface IFeedsRepository {
  create (intention: CreateFeedIntention): Promise<string>

  delete (feedId: FeedData['id']): Promise<void>

  load (feedId: FeedData['id']): Promise<FeedModel>

  loadCollection (query?: QueryParams): Promise<ICollection<FeedModel>>

  update (feedId: FeedData['id'], intention: UpdateFeedIntention): Promise<void>
}

export interface IFeedEntriesRepository {
  create (feedId: FeedData['id'], intention: CreateFeedEntryIntention): Promise<void>

  delete (feedId: FeedData['id'], entryId: FeedEntryData['id']): Promise<void>

  load (feedId: FeedData['id'], entryId: FeedEntryData['id']): Promise<FeedEntryModel>

  loadCollection (feedId: FeedData['id'], query?: QueryParams): Promise<ICollection<FeedEntryModel>>

  update (feedId: FeedData['id'], entryId: FeedEntryData['id'], intention: UpdateFeedEntryIntention): Promise<void>
}

export interface IVariantsRepository extends CanDetectStatus {
  create (content: ContentData['id']): Promise<VariantModel>

  clone (content: ContentData['id'], from: VariantData['id']): Promise<VariantModel>

  delete (content: ContentData['id'], id: VariantData['id']): Promise<void>

  load (content: ContentData['id'], id: VariantData['id']): Promise<VariantModel>

  lock (content: ContentData['id'], id: VariantData['id']): Promise<void>

  preview (content: ContentData['id'], id: VariantData['id']): Promise<string>

  unpublish (content: ContentData['id'], id: VariantData['id']): Promise<void>

  update (
    content: ContentData['id'],
    id: VariantData['id'],
    payload: VariantUpdatePayload,
    postSave?: SaveVariantMode
  ): Promise<void>

  updateAndPreview (content: ContentData['id'], id: VariantData['id'], payload: VariantUpdatePayload): Promise<string>
}

export const NodesRepositoryType = Symbol.for('INodesRepository')
export const SetItemsRepositoryType = Symbol.for('ISetItemsRepository')
export const SetsRepositoryType = Symbol.for('ISetsRepository')
export const FeedsRepositoryType = Symbol.for('IFeedsRepository')
export const FeedEntriesRepositoryType = Symbol.for('IFeedEntriesRepository')
export const VariantsRepositoryType = Symbol.for('IVariantsRepository')
