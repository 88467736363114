// Copyright © 2023 Move Closer

import { FileCreatePayload } from '@module/media/contracts'
import { ValidatorsMap } from '@module/media/contracts/validators'

export const fileCreateFormValidatorsMap: ValidatorsMap<FileCreatePayload> = {
  alt: '',
  author: 'required',
  caption: '',
  directory: '',
  title: 'required'
}
