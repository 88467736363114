// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'
import { NodeData } from '../contracts/models'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export class Node extends Model<NodeData> {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'name',
      'id',
      'hideInNav',
      'nodes',
      'slug',
      'title'
    ]
  }

  // TODO: Missing relation.
}
