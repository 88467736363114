// Copyright © 2021 Move Closer

import { ComponentObjectPropsOptions, ModuleDriver } from '@d24/modules'
import { computed, PropType, ref, SetupContext } from '@vue/composition-api'
import { v4 as uuid } from 'uuid'

import { SelectModuleModalPayload, SelectModuleModalProps } from '../PageBuilder.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const selectModuleModalProps: ComponentObjectPropsOptions<SelectModuleModalProps> = {
  payload: {
    type: Object as PropType<SelectModuleModalPayload>,
    required: true
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function useSelectionModuleModal (props: SelectModuleModalProps, ctx: SetupContext) {
  const chosenModule = ref<string>('')
  const query = ref<string>('')

  const isSelected = computed<boolean>(() => chosenModule.value.length > 0)
  const modules = computed(() => {
    return Object.values(props.payload.modulesRegistry).filter(m => {
      const name = `${ctx.root.$i18n.t('builder.modulesName.' + m.name)}`
      return query.value.length < 3 ||
        name.indexOf(query.value) !== -1 ||
        name.toLowerCase().indexOf(query.value) !== -1
    }).map(m => {
      return {
        image: m.image,
        name: m.name
      }
    })
  })

  function addSelected () {
    const moduleConstructor = props.payload.modulesRegistry[chosenModule.value as ModuleDriver]?.form

    if (!moduleConstructor) {
      return
    }

    props.payload.onSelection({
      content: moduleConstructor.getInitialContent(),
      ...moduleConstructor.getInitialConfig(),
      isVisible: true,
      isLazyLoaded: true,
      isMobileVisible: true,
      isDesktopVisible: true,
      id: uuid(),
      title: ''
    })

    ctx.emit('close')
  }

  return {
    addSelected,
    chosenModule,
    isSelected,
    modules,
    query
  }
}
