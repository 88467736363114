// Copyright © 2021 Move Closer

import { Coordinates, ImageTransforms } from 'vue-advanced-cropper'

import { HasIdentifier, Identifier } from '@/shared/contracts/data'

import { FileVariant, FileVariantParams } from './models'
import { ImageRatio } from '@d24/modules'

export interface DirectoryCreatePayload {
  parent: Identifier | null
  name: string
}

export interface DirectoryEditPayload {
  name: string
}

export interface FileCreatePayload extends FileEditPayload {
  directory: Identifier
}

export interface FileCropPayload {
  coordinates: Coordinates
  transformations?: ImageTransforms
  ratio: ImageRatio
}

export interface FileEditPayload {
  title: string
  author: string
  alt: string | null
  caption: string | null
}

export interface FilePondErrorDescription {
  type: string
  code: number
  body: object | string
}

export interface FileThumbnail {
  thumbnail: string
  type: string
}

export interface FileSize {
  width: number
  height: number | null
}

export interface FileSource extends HasIdentifier {
  mime: string
  original: string
  variantsParams?: { [key: string]: FileVariantParams }
  variants?: FileVariant[]
  m3u8?: string
}

export interface LinksData {
  [key: string]: string
}

export interface MediaMovePayload {
  directory: Identifier | null
}

export const EmptyFileEditPayload: FileEditPayload = {
  title: '',
  author: '',
  alt: null,
  caption: null
}

export const EmptyFileCreatePayload: FileCreatePayload = {
  ...EmptyFileEditPayload,
  directory: 1
}

export const EmptyDirectoryCreatePayload: DirectoryCreatePayload = {
  name: '',
  parent: null
}

export const EmptyDirectoryEditPayload: DirectoryEditPayload = {
  name: ''
}

export const EmptyDirectoryMovePayload: MediaMovePayload = {
  directory: null
}

export const EmptyFileMovePayload: MediaMovePayload = {
  directory: null
}

export const AllowedAudioTypes = [
  'audio/mpeg'
]

export const AllowedDocumentTypes = [
  'application/msword',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/csv'
]

export const AllowedImageTypes = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/svg+xml'
]

export const AllowedVideoTypes = [
  'video/mp4',
  'video/quicktime',
  'video/mpeg'
]

export const AllowedFileTypes = [
  ...AllowedAudioTypes,
  ...AllowedDocumentTypes,
  ...AllowedImageTypes,
  ...AllowedVideoTypes
]

export enum mediaTypes {
  Audio = 'audio',
  Document = 'document',
  Image = 'image',
  Video = 'video',
  File = 'file'
}

export const allowedTypes: Record<mediaTypes, Array<string>> = {
  [mediaTypes.Audio]: AllowedAudioTypes,
  [mediaTypes.Document]: AllowedDocumentTypes,
  [mediaTypes.Image]: AllowedImageTypes,
  [mediaTypes.Video]: AllowedVideoTypes,
  [mediaTypes.File]: AllowedFileTypes
}
