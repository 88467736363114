



























































import { AnyObject } from '@d24/modules'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { VideoSettings } from '@module/settings/contracts'

@Component({
  name: 'VideoSettingsForm'
})
export class VideoSettingsForm extends Vue {
  @Prop({
    type: String,
    required: true
  })
  public group!: string

  @Prop({
    type: String,
    required: true
  })
  public attr!: string

  @Prop({
    type: String,
    required: true
  })
  public form!: string

  @Prop({
    type: Object,
    required: true
  })
  public model!: VideoSettings

  mounted () {
    const tmp = { ...this.model }
    this.rangeValues = {
      youtube: tmp.youtube * 100,
      hls: tmp.hls * 100,
      on: tmp.on * 100
    }
  }

  public rangeValues: VideoSettings = {
    youtube: 100,
    hls: 0,
    on: 0
  }

  public onPropValue (prop: 'youtube' | 'hls' | 'on', model: number) {
    console.log('PROP:', model)
    this.rangeValues[prop] = model
    const sum: number = Number(this.rangeValues.youtube) + Number(this.rangeValues.hls) + Number(this.rangeValues.on)
    console.log('SUM:', sum)

    const returnValues = { ...this.rangeValues }

    returnValues.hls = Math.round((this.rangeValues.hls / sum) * 100) / 100
    returnValues.on = Math.round((this.rangeValues.on / sum) * 100) / 100
    returnValues.youtube = Math.round((this.rangeValues.youtube / sum) * 100) / 100

    this.$emit('update:model', returnValues)
  }

  /**
   * Default payload value for settings
   */
  static defaultPayload (): AnyObject {
    return {}
  }
}

export default VideoSettingsForm
