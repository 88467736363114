

















































import { Component, Vue } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'
import { Inject } from '@plugin/inversify'

import { DashmixIconName } from '@d24/modules'

import { IToastService, ToastServiceType } from '@service/toaster'

import { ISettingsRepository, SettingRepositoryType } from '@module/settings/contracts'
import { FreeSubscriptionRulesDomain } from '@module/settings/contracts/components'

@Component<FreeSubscriptionRulesForm>({
  name: 'FreeSubscriptionRules',
  mounted () {
    this.setDomainsOnMount()
  }
})
export class FreeSubscriptionRulesForm extends Vue {
  @Inject(SettingRepositoryType)
  protected settingsRepository!: ISettingsRepository

  @Inject(ToastServiceType)
  protected toastService!: IToastService<string, string>

  public readonly icons = DashmixIconName

  public readonly FREE_SUBSCRIPTION_RULES_KEY: string = 'freeSubscriptionRules'

  public isFormShowed: boolean = false

  public isLoading: boolean = false

  public elements: FreeSubscriptionRulesDomain[] = []

  public get domains (): FreeSubscriptionRulesDomain[] {
    return this.elements
  }

  public addDomain (): void {
    this.elements.push({ id: `domain-${this.elements.length}`, value: null })
    this.isFormShowed = true
  }

  public static defaultPayload () {
    return []
  }

  public async onSubmit (): Promise<void> {
    this.isLoading = true
    try {
      const payload = this.domains.filter(({ value }) => !!value).map(({ value }) => value)
      await this.settingsRepository.updateSetting(this.FREE_SUBSCRIPTION_RULES_KEY, payload)
      this.toastService.success(String(this.$t('freeSubscriptionRules.successSaved')))
      this.isFormShowed = !!this.domains.length
    } catch (error) {
      this.toastService.error(error)
    } finally {
      this.isLoading = false
    }
  }

  public removeDomain (id: string): void {
    const filteredElements = this.elements.filter((element: AnyObject) => element.id !== id).map(({ value }, index) => ({ id: `domain-${index}`, value }))
    this.setDomains(filteredElements)
  }

  public setDomains (domains: FreeSubscriptionRulesDomain[]): void {
    this.elements = domains
  }

  public async setDomainsOnMount (): Promise<void> {
    this.isLoading = true
    try {
      const domains = await this.settingsRepository.getSetting(this.FREE_SUBSCRIPTION_RULES_KEY)
      if (typeof domains !== 'undefined' && Array.isArray(domains) && domains.length > 0) {
        const mappedDomains = domains.map((value, index) => ({ id: `domain-${index}`, value }))
        this.setDomains(mappedDomains)
        this.isFormShowed = !!this.domains.length
      }
    } catch (error) {
      this.toastService.error(error)
    } finally {
      this.isLoading = false
    }
  }
}

export default FreeSubscriptionRulesForm
