





































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@d24/modules'

import { FormInput, FormSelect } from '@component/form'

import { SelectItem } from '@/shared/contracts/form'
import { FormStatus, FormType } from '@module/forms/contracts/models'
import { CreateFormIntentionPayload } from '@module/forms/intensions/CreateFormIntention'
import { EditFormIntentionPayload } from '@module/forms/intensions/EditFormIntention'

@Component<EventsForm>({
  name: 'CreateFormView',
  components: {
    FormSelect,
    FormInput
  }
})
export class EventsForm extends Vue {
  @Prop({ type: Boolean, required: true })
  public isEditMode!: boolean

  @Prop({ type: String, required: true })
  public form!: string

  @Prop({ type: Boolean, required: false })
  public isLoading!: boolean

  @Prop({ type: Boolean, required: true })
  public isSaving!: boolean

  @PropSync('payload', { type: Object, required: true })
  public _payload!: CreateFormIntentionPayload | EditFormIntentionPayload

  public Icons = DashmixIconName
  public title: string = ''
  public maEventName: string = ''
  public type: FormType = FormType.Event
  public status: FormStatus = FormStatus.New

  public get formOptions (): SelectItem[] {
    return Object.entries(FormType).map(([label, value]) => {
      return {
        label: this.$t(`content.forms.list.type.${label.toLowerCase()}`).toString(),
        value
      }
    })
  }

  public get formStatus (): SelectItem[] {
    return Object.entries(FormStatus).map(([label, value]) => {
      return {
        label: this.$t(`content.forms.list.status.${label.toLowerCase()}`).toString(),
        value
      }
    })
  }
}

export default EventsForm
