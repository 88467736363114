// Copyright © 2022 Move Closer

import { loadFormStatusDict, loadFormTypesDict } from '@/shared/helpers/filters'

import { defaultIgnoredQueryParams, FiltersConfig, FilterType } from '@component/Filters'

export const formsFiltersConfig: FiltersConfig = {
  groups: {
    status: { label: 'Status', key: 'status', type: FilterType.Dictionary, dictionary: loadFormStatusDict() },
    type: { label: 'Typ', key: 'type', type: FilterType.Dictionary, dictionary: loadFormTypesDict() }
  },
  ignore: [...defaultIgnoredQueryParams],
  override: { page: '1' },
  leave: ['perPage']
}
