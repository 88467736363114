// Copyright © 2021 Move Closer

import { DashmixIconName, TableHead, TableSearchBarAction } from '@d24/modules'
import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'

import { canDeleteCoupon, canEditCoupon } from '../guards'

export const couponsTableHead: TableHead = [
  {
    column: 'code',
    label: 'Kod',
    sortable: true,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'isUsed',
    label: 'Użyty',
    sortable: false,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'transactionId',
    label: 'Transakcja',
    sortable: true,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'userId',
    label: 'Użytkownik',
    sortable: true,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'usedAt',
    label: 'Data użycia',
    sortable: true,
    isSorted: false,
    width: '1fr'
  }
]

export enum CouponsActions {
  Delete = 'delete',
  Edit = 'edit'
}

export const couponsRowActionsFactory = (domain: Domain, user: UserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canEditCoupon(domain, user)) {
    actions.push(
      {
        label: 'Edytuj',
        icon: DashmixIconName.PenSolid,
        isBulkAction: false,
        action: CouponsActions.Edit
      }
    )
  }

  if (canDeleteCoupon(domain, user)) {
    actions.push(
      {
        label: 'Usuń',
        icon: DashmixIconName.TrashAltSolid,
        isBulkAction: true,
        action: CouponsActions.Delete
      }
    )
  }

  return actions
}
