// Copyright © 2021 Move Closer

import { PropertyItem } from '@/shared/contracts/content'

import { SiteModel } from '@module/root/contracts/models'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const pageOptions: (site: SiteModel) => PropertyItem[] = (site: SiteModel) => {
  const options: PropertyItem[] = [
    {
      key: 'ads',
      translationKey: 'content.page.propertiesList.ads'
    },
    {
      key: 'adsAdX',
      translationKey: 'content.page.propertiesList.adsAdX'
    },
    {
      key: 'adsAdXAdSense',
      translationKey: 'content.page.propertiesList.adsAdXAdSense'
    },
    {
      key: 'hideInNav',
      translationKey: 'content.page.propertiesList.nav'
    }
  ]

  if (site.getProperty('requireAuth', false)) {
    options.push({
      key: 'skipAuth',
      translationKey: 'content.page.propertiesList.skipAuth'
    })
  }

  return options
}
