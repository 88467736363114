






















import { Component, Prop, Vue } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { DashmixTheme } from '@d24/modules'
import { Fragment } from 'vue-fragment'

import { Inject } from '@plugin/inversify'
import { defaultDateFormat } from '@module/content/helpers/formatting'

import { CouponData } from '../contracts/models'

@Component({
  name: 'CouponsTableRow',
  components: { Fragment }
})
export class CouponsTableRow extends Vue {
  @Prop({ type: Object, required: true })
  public data!: CouponData

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public badgeTheme = DashmixTheme
  public dateFormat = defaultDateFormat
}

export default CouponsTableRow
