// Copyright © 2021 Move Closer

import { Injectable } from '@movecloser/front-core'

import { ISiteResolver, ISiteService } from './contracts'
import { ISitesRepository } from '../../contracts/repositories'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class SiteService implements ISiteService {
  private sitesRepository: ISitesRepository
  private siteResolver: ISiteResolver

  public constructor (sitesRepository: ISitesRepository, siteResolver: ISiteResolver) {
    this.sitesRepository = sitesRepository
    this.siteResolver = siteResolver
  }

  public boot (): Promise<void> {
    return this.sitesRepository.loadCollection().then(dictionary => {
      this.siteResolver.setupDictionary(dictionary)
      this.siteResolver.boot()
    })
  }
}
