


















import { BreadcrumbsProps } from '@d24/modules'
import { Component, Vue } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

import { EditModeLayout } from '@component/EditModeLayout'
import { Inject } from '@/shared/plugins/inversify'
import { Loader } from '@component/Loader'
import { FormCheckbox } from '@component/form'

import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'

import { AddContentIntention } from '../intentions/AddContentIntention'
import { articleOptions } from '../maps/article'
import { ContentCreatePayload } from '../contracts/data'
import { ContentType, Properties } from '../contracts/models'
import { ContentBasicsForm } from '../components/ContentBasicsForm.vue'
import { ContentRepositoryType, IContentRepository } from '../contracts/repositories'
import { initBreadcrumbs, mapOptionsToSelected } from './../helpers'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'CreateArticle',
  components: {
    ContentBasicsForm,
    Loader,
    EditModeLayout,
    FormCheckbox
  },
  metaInfo (this: CreateArticle): MetaInfo {
    return {
      title: `${this.$t('content.addArticle')}`
    }
  }
})
export class CreateArticle extends Vue {
  @Inject(ContentRepositoryType)
  protected contentRepository!: IContentRepository

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public contentOptions = articleOptions
  public contentType = ContentType.Article
  public formName: string = 'createContent'
  public isSaving: boolean = false
  public payload: ContentCreatePayload = {
    parent: null,
    slug: '',
    title: '',
    type: ContentType.Article,
    properties: {
      ads: true,
      adsAdX: true,
      adsAdXAdSense: true,
      comments: true
    }
  }

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('content.article.listTitle')}`,
          target: { name: 'content.articles.list' }
        },
        {
          label: `${this.$t('content.addArticle')}`,
          target: { name: 'content.articles.create' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get optionsDictionary () {
    if (!this.contentOptions || !this.payload) {
      return []
    }

    return this.contentOptions.map(option => {
      if (option.key === 'hideAuthor') {
        return {
          label: this.$t(option.translationKey, { author: 'Brak wybranego autora' }).toString(),
          key: option.key
        }
      }

      return {
        label: this.$t(option.translationKey).toString(),
        key: option.key
      }
    })
  }

  public get properties (): string[] {
    return mapOptionsToSelected(this.payload.properties, this.contentOptions.map(o => o.key))
  }

  public createNewArticle (): void {
    this.isSaving = true

    const intention = new AddContentIntention(this.payload)

    this.contentRepository.create(intention.toRequest()).then(id => {
      this.$router.push({
        name: 'content.articles.content',
        params: { id: `${id}` }
      })
    }).catch(error => {
      console.warn(error)
    }).finally(() => {
      this.isSaving = false
    })
  }

  public setProperties (options: string[]) {
    this.payload.properties = this.contentOptions.map(o => o.key).reduce((agr: Properties, o: string) => {
      agr[o] = options.includes(o)
      return agr
    }, {} as Properties)
  }
}

export default CreateArticle

