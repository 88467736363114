// Copyright © 2021 Move Closer

import { defineComponent } from '@vue/composition-api'

import { Loader, LoaderVariant } from '../components/Loader'
import { FullScreenLayout } from './FullScreenLayout'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const FullScreenLoader = defineComponent({
  name: 'FullScreenLoader',
  components: { Loader, FullScreenLayout },
  setup () {
    return { white: LoaderVariant.White }
  },
  template: `
    <FullScreenLayout>
      <Loader :variant="white" />
    </FullScreenLayout>
  `
})
