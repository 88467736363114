

























import { Component, Prop, Vue } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'

import { Inject } from '@plugin/inversify'

import { RemoveConflictModalPayload } from '../contracts'
import { defaultDateFormat } from '../helpers/formatting'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({
  name: 'RemoveConflictModal'
})
export class RemoveConflictModal extends Vue {
  @Prop({ type: Object, required: true })
  protected payload!: RemoveConflictModalPayload

  @Inject(DateTimeType)
  protected readonly dateTime!: IDateTime

  public get lockedAt (): string {
    return this.dateTime.parseToFormat(this.payload.lockedAt, defaultDateFormat)
  }

  public close (): void {
    this.$emit('close')
    this.payload.onClose()
  }
}

export default RemoveConflictModal
