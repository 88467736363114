// Copyright © 2021 Move Closer

import { SelectItem } from '@/shared/contracts/form'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const contentTemplates: SelectItem[] = [
  {
    label: 'Brak szablonu',
    value: ''
  },
  {
    label: 'Szablon A',
    value: 'A'
  },
  {
    label: 'Szablon B',
    value: 'B'
  }
]
