// Copyright © 2021 Move Closer

import { AnyObject } from '@movecloser/front-core'
import { LayoutContainer, LayoutContainerType } from '@d24/modules'

import { LayoutContainers } from '../contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function composeContainersMap (payload: AnyObject): LayoutContainers {
  // @ts-expect-error
  const containers: LayoutContainers = {}

  for (const slot of Object.values(LayoutContainer)) {
    containers[slot] = !!payload && typeof payload[slot] === 'string' && Object.values(LayoutContainerType).includes(payload[slot])
      ? payload[slot] : LayoutContainerType.Boxed
  }

  return containers
}
