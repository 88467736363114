// Copyright © 2021 Move Closer

import { defineComponent } from '@vue/composition-api'

import { loaderProps, useLoader } from './Loader.hooks'
import { LoaderProps } from './Loader.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const Loader = defineComponent({
  name: 'Loader',
  props: loaderProps,
  setup (props: LoaderProps) {
    return useLoader(props)
  },
  template: `
    <div class="d-flex align-items-center justify-content-center w-100 h-100">
      <div class="spinner-border" :class="classStack" role="status">
        <span class="d-none">Loading...</span>
      </div>
    </div>
  `
})
