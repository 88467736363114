



































import { Component, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@d24/modules'

import { Inject } from '@plugin/inversify'

import { ContentInfoData, ContentRepositoryType, IContentRepository } from '../contracts'
import { Loader } from '@component/Loader'

/**
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<ArticlesOverviewWidget>({
  name: 'ArticlesOverviewWidget',
  components: { Loader },

  created (): void {
    this.loadContentInfo()
  }
})
export class ArticlesOverviewWidget extends Vue {
  @Inject(ContentRepositoryType)
  protected contentRepository!: IContentRepository

  public data: ContentInfoData | null = null
  public icon = DashmixIconName
  public isLoading: boolean = false

  public get latestCount (): string {
    return (this.data?.latestArticleCount && typeof this.data?.latestArticleCount === 'number') ? String(this.data.latestArticleCount) : 'b/d'
  }

  protected loadContentInfo (): void {
    this.isLoading = true
    this.contentRepository.loadContentInfo().then((res: ContentInfoData) => {
      this.isLoading = false
      this.data = res
    })
  }
}

export default ArticlesOverviewWidget
