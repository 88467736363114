// Copyright © 2022 Move Closer

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Typo from 'typo-js'
import Vue from 'vue'

import { HttpConnectorType, IHttpConnector } from '@movecloser/front-core'

import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function isSpellCheckingOn (): boolean {
  return localStorage.getItem('useSpellCheck') === 'true'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const SPELLCHECK_KEY = 'spell-check'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export async function preloadTypo (vue: Vue): Promise<void> {
  const useSpellCheck: boolean = isSpellCheckingOn()
  if (!vue.$container || !useSpellCheck) {
    return
  }

  const http = vue.$container.get<IHttpConnector>(HttpConnectorType)
  const currentLocale = (vue.$container.get<ISiteResolver>(SiteResolverType).getSite()?.locale || 'pl').toLocaleLowerCase()

  const affResponse = await http.destination('cdn')
    .get(`${process.env.VUE_APP_DICTIONARIES_PATH}/${currentLocale}.aff`)
  const dicResponse = await http.destination('cdn')
    .get(`${process.env.VUE_APP_DICTIONARIES_PATH}/${currentLocale}.dic`)

  let localLabel: string
  switch (currentLocale) {
    case 'en':
      localLabel = 'en_US'
      break
    default:
      localLabel = 'pl'
  }

  const typoInstance = new Typo(
    localLabel,
    affResponse.data,
    dicResponse.data
  )

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const preloaded = vue.preloaded
  preloaded[SPELLCHECK_KEY] = typoInstance
  vue.$set(vue, 'preloaded', preloaded)
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function toggleSpellChecking (value: boolean): void {
  localStorage.setItem('useSpellCheck', `${value}`)
}
