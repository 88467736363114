// Copyright © 2021 Move Closer

import { MappingConfig } from '@movecloser/front-core'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export const meAdapterMap: MappingConfig = {
  avatar: 'avatar',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  nickname: 'nickname'
}
