// Copyright © 2021 Move Closer

import { IResponse, mapModel, ModelPayload, ResourceActionFailed } from '@movecloser/front-core'

import { resolveFromStatus } from '@/shared/exceptions/connector-errors'

import { Process } from '../models/process'
import { processAdapterMap } from '../models/process.adapter'
import { ProcessModel } from '../contracts/models'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function considerFailedProcess (process: ProcessModel, message: string): void {
  if (process.isFailed()) {
    throw new ResourceActionFailed(
      message,
      resolveFromStatus(process.code),
      process.errors
    )
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function considerUnfinishedProcess (process: ProcessModel): void {
  if (process.isPending()) {
    throw new ResourceActionFailed(
      'Process pending',
      resolveFromStatus(202)
    )
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function mapToProcess (data: ModelPayload): ProcessModel {
  return Process.hydrate(
    mapModel(data, processAdapterMap)
  )
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function retrieveResult (response: IResponse): ModelPayload {
  if (response.status !== 202) {
    return response.data.data
  }

  const process = mapToProcess(response.data.data)
  considerUnfinishedProcess(process)
  considerFailedProcess(process, response.data.message)

  return process.result
}
