// Copyright © 2021 Move Closer

import { DashmixIconName, TableHead, TableSearchBarAction } from '@d24/modules'

import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'

import { canEditSubscription, canViewSubscription } from '../guards'
import { PeriodData } from '../contracts/models'

export const periodsTableHead: TableHead = [
  {
    column: 'type',
    label: 'subscription.table.type',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'period',
    label: 'subscription.table.period',
    sortable: false,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'startedAt',
    label: 'subscription.table.startedAt',
    sortable: false,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'endedAt',
    label: 'subscription.table.endedAt',
    sortable: false,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'isPaid',
    label: 'subscription.table.isPaid',
    sortable: false,
    isSorted: false,
    width: '0.75fr'
  }
]

export enum PeriodsListsActions {
  ViewTransaction = 'viewTransactions',
  Paid = 'paid',
  SendPaymentLink = 'sendPaymentLink',
  GetInvoice = 'getInvoice'
}

export const periodsRowActionsFactory = (domain: Domain, user: UserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canViewSubscription(domain, user)) {
    actions.push(
      {
        label: 'Podgląd transakcji',
        icon: DashmixIconName.EyeSolid,
        isBulkAction: false,
        action: PeriodsListsActions.ViewTransaction
      }
    )
  }

  if (canViewSubscription(domain, user)) {
    actions.push(
      {
        label: 'Pobierz fakturę',
        icon: DashmixIconName.FileInvoiceSolid,
        needsDoubleClick: false,
        isBulkAction: false,
        action: PeriodsListsActions.GetInvoice,
        guard: (data: unknown): boolean => {
          return !!(data as PeriodData).invoice
        }
      }
    )
  }

  if (canEditSubscription(domain, user)) {
    actions.push(
      {
        label: 'Zaznacz jako opłaconą',
        icon: DashmixIconName.DollarSignSolid,
        needsDoubleClick: false,
        isBulkAction: true,
        action: PeriodsListsActions.Paid,
        guard: (data: unknown): boolean => {
          return !(data as PeriodData).isPaid
        }
      }
    )
  }

  if (canEditSubscription(domain, user)) {
    actions.push(
      {
        label: 'Wyślij link do płatności',
        icon: DashmixIconName.CommentDollarSolid,
        needsDoubleClick: false,
        isBulkAction: true,
        action: PeriodsListsActions.SendPaymentLink,
        guard: (data: unknown): boolean => {
          return !(data as PeriodData).isPaid
        }
      }
    )
  }

  return actions
}
