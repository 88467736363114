<!-- Copyright © 2021 Move Closer -->

<template>
  <BaseSection type="inline">
    <template slot="heading">
      {{ $t('components.MarkdownEditorGuideModal.sections.emphasis.heading') }}
    </template>

    <pre>
**<strong>{{ $t('components.MarkdownEditorGuideModal.sections.emphasis.examples.bold') }}</strong>**
*<em>{{ $t('components.MarkdownEditorGuideModal.sections.emphasis.examples.italics') }}</em>*
~~<del>{{ $t('components.MarkdownEditorGuideModal.sections.emphasis.examples.strikethrough') }}</del>~~</pre>
  </BaseSection>
</template>

<script>
import BaseSection from './_base'
export default { components: { BaseSection } }
</script>
