















import { Component } from 'vue-property-decorator'
import { TableHead } from '@d24/modules'

import { HeaderInterface } from '@component/InteractiveTable/InteractiveTable.contracts'
import { ModelListHeader } from '@component/ModelListHeader'
import { Inject } from '@plugin/inversify'

import { AbstractWidget } from '@module/root/shared/AbstractWidget'
import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'

import { canViewSets } from '../guards'
import { ContentRepositoryType, ContentTableRowElement, ContentType, IContentRepository } from '../contracts'
import { pagesTableHeadShortened } from '../maps/pages'
import { PagesTableRowShortened } from '../components/PagesTableRowShortened.vue'

/**
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<LatestPagesWidget>({
  name: 'LatestPagesWidget',
  components: {
    ModelListHeader
  },

  created (): void {
    if (this.isWidgetVisable) {
      this.loadList()
    }
  }
})
export class LatestPagesWidget extends AbstractWidget {
  @Inject(ContentRepositoryType)
  protected contentRepository!: IContentRepository

  public isLoading: boolean = false
  public tableHead: TableHead = pagesTableHeadShortened
  public perPage: number = 3
  public rowComponent = PagesTableRowShortened
  public tableData: ContentTableRowElement[] = []

  public get header (): HeaderInterface {
    const payload: Partial<HeaderInterface> = {
      title: `${this.$t('content.article.listTitle')}`
    }

    return payload as HeaderInterface
  }

  protected checkPermission (domain: Domain, user: UserModel | null): boolean {
    return canViewSets(domain, user)
  }

  private loadList (): void {
    this.isLoading = true
    this.contentRepository.loadCollection([ContentType.Page, ContentType.SmartPage], { perPage: `${this.perPage}` }).then((collection) => {
      this.isLoading = false
      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: true,
          data: model
        }
      })
    })
  }
}
export default LatestPagesWidget
