





















































































import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import { DashmixIconName } from '@d24/modules'
import { FormCheckbox, FormInput } from '@component/form'

import { FormSelect } from '@component/form/Select/Select'
import { Item } from '@component/TreeViewer'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'
import { Inject } from '@/shared/plugins/inversify'
import { SelectItem } from '@/shared/contracts/form'

import { ContentCreatePayload, ContentEditPayload } from '../contracts/data'
import { contentTemplates } from '../maps/templates'
import { ContentType, FormMode, OnDirtyCallback } from '../contracts'
import { generateSlug } from '../helpers/slugs'
import { mapNodeToParent, mapParentToNode, mapParentToSlug } from '../helpers'
import { NodeSelect } from './NodeSelect.vue'
import { ParentData } from '../contracts/models'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'ContentBasicsForm',
  components: { FormSelect, FormCheckbox, FormInput, NodeSelect }
})
export class ContentBasicsForm extends Vue {
  @Prop({ type: String, required: true })
  public contentType!: ContentType

  @Prop({ type: String, required: true })
  public form!: string

  @Prop({ type: Boolean, required: false, default: false })
  public isEditMode!: boolean

  @Prop({ type: Boolean, required: false })
  public isSaving!: boolean

  @Prop({ type: Function, required: false, default: () => { return () => false } })
  protected onDirty!: OnDirtyCallback

  @PropSync('formData', { type: Object, required: true })
  public payload!: ContentCreatePayload | ContentEditPayload

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public nodes: Item[] = []
  public templateOptions: SelectItem[] = contentTemplates
  public urlPrefix: string = ''
  private useDefaultSlug: boolean = true

  public Icons = DashmixIconName
  public PossibleModes = FormMode

  public get canUseTemplates (): boolean {
    return this.contentType === ContentType.Page
  }

  public get contentTypeOptions (): SelectItem[] {
    const toMap: ContentType[] = this.contentType === ContentType.Page
      ? [ContentType.Page, ContentType.SmartPage] : [ContentType.Article, ContentType.LiveArticle]

    return toMap.map(t => ({ label: `${this.$t('content.types.' + t)}`, value: t }))
  }

  public get slug () {
    return this.payload.slug
  }

  public set slug (value: string) {
    this.payload.slug = value
  }

  public get slugToDisplay (): string {
    const toDisplay: string[] = [
      this.urlPrefix,
      this.useDefaultSlug ? this.payload.slug ? this.payload.slug : '' : ''
    ]

    return toDisplay.filter(s => s !== '/' && s !== '').join('/')
  }

  mounted () {
    this.nodes = mapParentToNode(this.payload.parent)
    this.urlPrefix = this.buildUrl(this.payload.parent)
  }

  public routeToList () {
    this.$router.push({ name: `content.${this.contentType}s.list` })
  }

  public selectNodes (selectedItemsList: Item[]): void {
    this.nodes = selectedItemsList
    this.onDirty(true)
  }

  protected buildUrl (p: ParentData | null): string {
    let domain = this.siteResolver.getSiteAddress()

    if (domain[domain.length - 1] === '/') {
      domain = domain.slice(0, -1)
    }

    return [
      domain,
      mapParentToSlug(p)
    ].filter(s => s !== '').join('/')
  }

  @Watch('nodes', { deep: true })
  protected onNodesChange (nodes: Item[]) {
    this.payload.parent = mapNodeToParent(nodes)
    this.urlPrefix = this.buildUrl(this.payload.parent)
  }

  @Watch('payload.title', { deep: false })
  protected onTitleChange (title: string): void {
    this.updateGeneratedSlug(title)
    this.onDirty(true)
  }

  protected toggleUseDefaultSlug () {
    this.useDefaultSlug = !this.useDefaultSlug
    this.updateGeneratedSlug(this.payload.title)
    this.onDirty(true)
  }

  protected blurUserSlug () {
    this.payload.slug = generateSlug(this.payload.slug)
  }

  protected updateGeneratedSlug (title: string) {
    if (this.useDefaultSlug) {
      this.payload.slug = generateSlug(title)
    }
  }
}

export default ContentBasicsForm
