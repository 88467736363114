// Copyright © 2021 Move Closer

import { defaultIgnoredQueryParams, FiltersConfig, FilterType } from '@component/Filters'

import { loadEditors, loadStrategyDict } from '@/shared/helpers/filters'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const setsFiltersConfig: FiltersConfig = {
  groups: {
    editor: { label: 'Autor', key: 'editor', type: FilterType.Dictionary, dictionaryLoader: loadEditors },
    strategy: { label: 'Strategia', key: 'strategy', type: FilterType.Dictionary, dictionary: loadStrategyDict() },
    updatedAt: { label: 'Data modyfikacji', key: 'updatedAt', type: FilterType.Date },
    createdAt: { label: 'Data utworzenia', key: 'createdAt', type: FilterType.Date }
  },
  ignore: [...defaultIgnoredQueryParams],
  override: { page: '1' },
  leave: ['perPage']
}
