















import { Component } from 'vue-property-decorator'
import { TableHead, TableRowElement } from '@d24/modules'

import { Inject } from '@plugin/inversify'
import { ModelListHeader } from '@component/ModelListHeader'

import { AbstractWidget } from '@module/root/shared/AbstractWidget'
import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'

import { canViewSubscription } from '../guards'
import { ISubscriptionRepository, SubscriptionRepositoryType } from '../contracts/repositories'
import { subscriptionTableHeadShortened } from '../maps/subscriptions'
import SubscriptionsTableRowShortened from '../components/SubscriptionsTableRowShortened.vue'

/**
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<SubscriptionsWidget>({
  name: 'SubscriptionsWidget',
  components: {
    ModelListHeader
  },
  created (): void {
    if (this.isWidgetVisable) {
      this.loadSubscriptions()
    }
  }
})

export class SubscriptionsWidget extends AbstractWidget {
  @Inject(SubscriptionRepositoryType)
  protected subscriptionRepository!: ISubscriptionRepository

  public isLoading: boolean = false
  public tableData: TableRowElement[] = []
  public rowComponent = SubscriptionsTableRowShortened
  public perPage: number = 5

  public tableHead: TableHead = subscriptionTableHeadShortened.map(item => {
    return {
      ...item,
      label: `${this.$t(item.label)}`
    }
  })

  protected checkPermission (domain: Domain, user: UserModel | null): boolean {
    return canViewSubscription(domain, user)
  }

  private loadSubscriptions (): void {
    this.subscriptionRepository.loadCollection({ perPage: `${this.perPage}` }).then(collection => {
      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: true,
          data: model
        }
      })
    })
  }
}
export default SubscriptionsWidget
