





























import { Component } from 'vue-property-decorator'

import { Hint } from '@component/Typeahead'
import { Property } from '@component/Property/Property'

import { AbstractAddon } from './AbstractAddon'
import { AuthorSelector } from './AuthorSelector.vue'
import { smartMarkers, smartRelatedStrategyOptions, smartRelatedTypeOptions, SmartStrategy, SmartType } from '../../maps/smart'
import { TagSelector } from './TagSelector.vue'
import { GallerySelector } from './GallerySelector.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'SmartRelated',
  components: { AuthorSelector, Property, TagSelector, GallerySelector }
})
export class SmartRelated extends AbstractAddon {
  public selected: Hint[] = []
  public strategy: SmartStrategy = SmartStrategy.All
  public type: SmartType = SmartType.Tag
  public typeOptions = smartRelatedTypeOptions

  public SmartType = SmartType

  public get isNotAll (): boolean {
    return this.strategy !== SmartStrategy.All
  }

  public get markers (): string[] {
    return smartMarkers[this.type] || []
  }

  public get strategyOptions () {
    return smartRelatedStrategyOptions.filter(s => {
      return this.type !== SmartType.Category || s.value === SmartStrategy.All
    })
  }

  mounted () {
    this.getVariantProperty()
  }

  public changeStrategy (strategy: SmartStrategy): void {
    if (strategy === SmartStrategy.All) {
      this.selected = []
    }

    this.strategy = strategy
    this.setVariantProperty()
  }

  public changeType (type: SmartType): void {
    if (this.type !== type) {
      this.strategy = SmartStrategy.All
      this.selected = []
    }

    this.type = type
    this.setVariantProperty()
  }

  public onSelection (value: Hint[]): void {
    this.selected = value
    this.setVariantProperty()
  }

  protected getVariantProperty (): void {
    const initValue: any = this.variant.getProperty('smartRelated')

    if (typeof initValue !== 'object' || initValue === null) {
      this.setVariantProperty()
      return
    }

    this.type = initValue.type
    this.strategy = initValue.strategy
    this.selected = initValue.selected
  }

  protected setVariantProperty (): void {
    this.variant.setProperty('smartRelated', {
      type: this.type,
      strategy: this.strategy,
      selected: this.selected,
      items: this.selected.map(s => s.value)
    })

    this.onChange(this.variant)
  }
}

export default SmartRelated
