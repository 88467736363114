// Copyright © 2021 Move Closer

import { AbstractIntention } from '@movecloser/front-core'

export interface EditGalleryIntentionPayload {
  title?: string
  description?: string
}

export class EditGalleryIntention extends AbstractIntention<EditGalleryIntentionPayload> {
  protected map = {
    title: 'title',
    description: 'description'
  }
}
