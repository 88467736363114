
















import { AnyModule } from '@d24/modules'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IRelatedService } from '@service/related'
import { ModulesRegistry, PageBuilder, PageBuilderOperationMode } from '@component/PageBuilder'

import { ContentType } from '@module/content/contracts'
import { pageModules } from '@module/content/maps/modules'

import { LayoutSlot, LayoutSlotData } from '../contracts'
import { commentsModules, sidebarModules, topModules } from '../maps/modules'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({
  name: 'LayoutSlotForm',
  components: { PageBuilder }
})
export class LayoutSlotForm extends Vue {
  @Prop({ type: Number, required: false })
  public readonly columns?: number

  @Prop({ type: String, required: true })
  public readonly layoutSlot!: LayoutSlot

  @Prop({ type: Array, required: false })
  public readonly modules!: AnyModule[]

  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  @Prop({ type: String, required: true })
  public readonly title!: string

  public builderMode: PageBuilderOperationMode = PageBuilderOperationMode.EditModules
  public slotModules: AnyModule[] = this.modules ?? []

  public contentType: ContentType = ContentType.Page

  public get modulesRegistry (): ModulesRegistry {
    switch (this.layoutSlot) {
      case LayoutSlot.Top:
        return topModules
      case LayoutSlot.Left:
      case LayoutSlot.Right:
        return sidebarModules
      case LayoutSlot.Comments:
        return commentsModules
      case LayoutSlot.Bottom:
      default:
        return pageModules
    }
  }

  public onModulesChange (changed: AnyModule[]): void {
    this.slotModules = changed

    this.$emit('update', { [this.layoutSlot]: this.slotModules } as LayoutSlotData)
  }

  public setPageBuilderOperationMode (value: PageBuilderOperationMode) {
    this.builderMode = value
  }
}

export default LayoutSlotForm
