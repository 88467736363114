// Copyright © 2021 Move Closer

import 'reflect-metadata'
import { registerDashmixDSL, registerSanitize } from '@d24/modules'
import Vue from 'vue'
import VTooltip from 'v-tooltip'
import VueCompositionApi from '@vue/composition-api'
import VueMeta from 'vue-meta'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import JsonViewer from 'vue-json-viewer'

import { createApp } from '@/bootstrap/app'
import { i18n } from '@/shared/support/i18n'
import { initSentry } from '@/shared/support/sentry'
import { installContainer } from '@/shared/plugins/inversify'
import { installFetchRelated } from '@plugin/fetch-related.hook'
import { registerExtensions } from '@/shared/extentions'

import './assets/scss/main.scss'

import App from './App.vue'

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  adapter: {
    loaded ({ el }: { el: HTMLElement }) {
      const srcset = el.getAttribute('data-srcset')
      if (srcset) {
        el.setAttribute('srcset', srcset)
      }
    }
  },
  attempt: 1,
  preLoad: 2.5
})

Vue.use(VueCompositionApi)
Vue.use(VueMeta)
Vue.use(VTooltip)
Vue.use(JsonViewer)

installContainer(Vue)
registerExtensions(Vue)
installFetchRelated(Vue)
registerSanitize(Vue)

registerDashmixDSL('D24')

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
createApp().then(core => {
  const { configuration, container, router } = core

  if (process.env.VUE_APP_ENV !== 'local') {
    initSentry()
  }

  new Vue({
    router,
    container,
    configuration,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})
