// Copyright © 2021 Move Closer

import { ExtendedMDE } from 'simplemde'

import { Modals } from '@/config/modals'

import { Shortcut } from '../MarkdownEditor.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const guideShortcut: Shortcut = {
  action: (editor: ExtendedMDE) => {
    editor.options.modalConnector.open(Modals.MarkdownEditorGuide)
  },
  className: 'fa fa-question-circle',
  name: 'help',
  title: 'Pokaż pomoc'
}
