









import { AnyObject, DashmixIconName, DashmixTheme } from '@d24/modules'
import { Component, PropSync, Vue } from 'vue-property-decorator'

import { defaultIgnoredQueryParams, Filters, FiltersConfig, FilterType } from '@component/Filters'
import { PromotionData } from '@module/settings/contracts'
import { Container } from '@movecloser/front-core'

@Component<PromotionConditionsForm>({
  name: 'PromotionConditionsForm',
  components: { Filters }
})
export class PromotionConditionsForm extends Vue {
  @PropSync('conditions', {
    type: Object,
    required: true
  })
  public payload!: PromotionData['conditions']

  public readonly Icons = DashmixIconName
  public readonly Theme = DashmixTheme

  public filtersConfig: FiltersConfig = {
    groups: {
      email: {
        label: 'Email',
        key: 'email',
        type: FilterType.String
      },
      hasSubscription: {
        label: 'Czy użytkownik wykupił subskrypcję',
        key: 'hasSubscription',
        type: FilterType.Dictionary,
        dictionaryLoader: async (container: Container) => [
          {
            label: 'Tak',
            value: 'true'
          },
          {
            label: 'Nie',
            value: 'false'
          }
        ]
      }
    },
    ignore: [...defaultIgnoredQueryParams],
    override: {},
    leave: ['perPage']
  }

  public updateConditions (conditions: AnyObject) {
    this.payload = conditions
  }
}

export default PromotionConditionsForm
