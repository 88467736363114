























import { Component, Vue, Prop } from 'vue-property-decorator'
import { ConfirmAcceptanceModalContent, ConfirmAcceptanceModalPayload } from '../contracts/components'
import { Inject } from '@plugin/inversify'
import { IModal, ModalType } from '@movecloser/front-core'
import { DashmixIconName } from '@d24/modules'

@Component({
  name: 'ConfirmAcceptanceModal'
})
export class ConfirmAcceptanceModal extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  public payload!: ConfirmAcceptanceModalPayload

  @Inject(ModalType)
  protected modalConnector!: IModal

  public content: ConfirmAcceptanceModalContent = this.payload.content
  public icons = DashmixIconName

  public note: string = ''

  public confirm () {
    this.payload.onConfirm(this.note)
  }
}

export default ConfirmAcceptanceModal
