// Copyright © 2021 Move Closer

import { DashmixIconName } from '@d24/modules'
import { defineComponent, SetupContext } from '@vue/composition-api'

import { CheckTile } from '@component/CheckTile'

import { selectModuleModalProps, useSelectionModuleModal } from './SelectModuleModal.hooks'
import { SelectModuleModalProps } from '../PageBuilder.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const SelectModuleModal = defineComponent({
  name: 'SelectModuleModal',
  components: { CheckTile },
  props: selectModuleModalProps,

  setup (props: SelectModuleModalProps, ctx: SetupContext) {
    return { ...useSelectionModuleModal(props, ctx), DashmixIconName }
  },

  template: `
    <D24ModalInner @close="$emit('close')">
      <template v-slot:header>
        <h5 class="modal-title" v-t="'builder.addModuleTitle'" />
      </template>

      <D24Input name="search" :model.sync="query" :placeholder="$t('builder.findModule')" />

      <div class="row no-gutters mt-3">
        <div class="col-3" v-for="(m, i) in modules" :key="'m-' + m.name">
          <CheckTile class="m-1" :id="m.name + '-' + i" name="module" :selected.sync="chosenModule" :value="m.name">
            <div class="d-flex align-items-center flex-column">
              <img :src="m.image" :alt="m.name" />
              <span>{{ $t('builder.modulesName.' + m.name) }}</span>
            </div>
          </CheckTile>
        </div>
        <div v-if="!modules.length" class="col-12 py-5">
          <h5 class="text-center text-black-50" v-t="'builder.noModulesToDisplay'" />
        </div>
      </div>

      <template v-slot:footer>
        <D24Button :icon="DashmixIconName.PlusSolid" :label="$t('builder.addModule')"
                   :disabled="!isSelected" @click="addSelected" />
      </template>
    </D24ModalInner>
  `
})
