



























import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DashmixIconName } from '@d24/modules'
import { Fragment } from 'vue-fragment'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { ContentModel, ContentStatusBadge, ContentType, Label } from '../contracts'
import { EditorsStack } from './EditorsStack.vue'

@Component({
  name: 'ArticlesTableRowShortened',
  components: {
    EditorsStack,
    Fragment
  }
})
export class ArticlesTableRowShortened extends Mixins<IUserAware>(UserAware) {
  @Prop({ type: Object, required: true })
  public data!: ContentModel

  public ContentStatusBadge = ContentStatusBadge
  public ContentType = ContentType
  public Icons = DashmixIconName
}

export default ArticlesTableRowShortened
