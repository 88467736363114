
























































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { DashmixIconName, DashmixTheme } from '@d24/modules'
import { Fragment } from 'vue-fragment'

import { Inject } from '@/shared/plugins/inversify'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { SubscriptionData, SubscriptionsPeriods, SubscriptionsStatuses } from '../contracts/models'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'SubscriptionTableRow',
  components: { Fragment }
})
export class SubscriptionTableRow extends Mixins<IUserAware>(UserAware) {
  @Prop({
    type: Object,
    required: true
  })
  public data!: SubscriptionData

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public readonly icons = DashmixIconName
  public readonly themes = DashmixTheme

  public get fullName (): string {
    if (!this.data.user) {
      return ''
    }
    return `${this.data.user.firstName} ${this.data.user.lastName}`
  }

  public get isCancelled (): boolean {
    return this.data.period === SubscriptionsPeriods.Monthly && this.data.nextRenewalAt === null
  }

  public get endDateInfo (): string | undefined {
    if (!this.data.endedAt) {
      return
    }

    if (this.leftDays <= 0) {
      return this.$t('subscription.endDate.ended').toString()
    }

    return this.$t('subscription.endDate.left', { days: this.leftDays }).toString()
  }

  public get leftDays (): number {
    let duration: number = 0

    if (this.data.endedAt) {
      duration = this.dateTime.difference(this.data.endedAt)
    }

    if (!duration) {
      return duration
    }

    return Math.floor(duration / 60 / 60 / 24)
  }

  public get activeTheme (): DashmixTheme {
    return (this.data.isActive ? DashmixTheme.Success : DashmixTheme.Danger)
  }

  public get statusTheme (): DashmixTheme {
    switch (this.data.status) {
      case SubscriptionsStatuses.DaysLeft:
        return DashmixTheme.Success
      case SubscriptionsStatuses.Cancelled:
      case SubscriptionsStatuses.Expiring:
        return DashmixTheme.Warning
      case SubscriptionsStatuses.Expired:
        return DashmixTheme.Danger
      case SubscriptionsStatuses.Paid:
        return DashmixTheme.Success
      case SubscriptionsStatuses.NotStarted:
        return DashmixTheme.Dark
    }
    return DashmixTheme.Default
  }

  public get statusClass (): string {
    return (this.data.isActive ? 'bg-success' : 'bg-danger')
  }

  public get statusTitle (): string {
    return (this.data.isActive ? 'active' : 'inactive')
  }
}

export default SubscriptionTableRow
