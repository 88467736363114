// Copyright © 2021 Move Closer

import { AuthorTypes, Avatar, CompanyData, Roles, SimpleUser, SubscriptionsPeriods, SubscriptionsTypes } from './models'
import { Identifier } from '@d24/modules'

export interface AuthorPayload {
  avatar: Avatar|null
  description: string
  firstName: string
  lastName: string
  links: Partial<Record<SocialSite, string>>
  isActive: boolean
  isLinkActive: boolean
  isPageActive: boolean
  nickname: string
  user: SimpleUser | null
  position: string
  title: string
  type: AuthorTypes | null
}

export enum SocialSite {
  Facebook = 'facebook',
  Twitter = 'twitter',
  Google = 'google',
  Linkedin = 'linkedin',
  PersonalWebsite = 'site'
}

export interface SubscriptionPayload {
  userId: Identifier
  isActive?: boolean
  type: SubscriptionsTypes
  period: SubscriptionsPeriods
  paid?: boolean
  startedAt: string
  price?: number
  renewable: boolean
  nextRenewalAt?: string | null
  endedAt?: string
}

export interface UserPayload {
  avatar: Avatar|null
  companyData?: CompanyData
  email: string
  firstName: string
  lastName: string
  nickname: string
  isEditor: boolean
  isActive?: boolean
  isBlocked: boolean
  isShadowbanned: boolean
  roles?: Roles
  password?: string
  passwordConfirmation?: string
}
