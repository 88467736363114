// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'
import { CommentData } from '@module/content/contracts'

export class Comment extends Model<CommentData> {
  protected boot (): void {
    this.modelProperties = [
      'avatar', 'content', 'createdAt', 'creator', 'firstName', 'id', 'lastName', 'legacyName', 'owner', 'parentId', 'status', 'title'
    ]
  }
}
