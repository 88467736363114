// Copyright © 2021 Move Closer

import { AnyObject } from '@movecloser/front-core'

import { DeMarked } from '@/shared/helpers/demarked'

import { SIMPLEMDE_CONFIG } from '../MarkdownEditor.config'
import { Shortcut } from '../MarkdownEditor.contracts'

/**
 * Find element in DOM from toolbar and attach appropriate class to highlight
 *
 * @author Javlon Khalimjonov <javlon.khalimjanov@movecloser.pl>
 */
export const toggleActiveClassOnToolbarElement = (className: string, forcedValue?: boolean): void => {
  let _el: Element | null = null

  const _toolbarElements = [...document.querySelector('.editor-toolbar')!.children] as Element[]

  _el = _toolbarElements.filter((element) => { return element.className.includes(className) })[0]
  if (!_el) {
    return
  }

  if (_el.className.includes(' active') && (typeof forcedValue === 'undefined' || !forcedValue)) {
    _el.className = _el.className.replace(/\s*active\s*/g, '')
  } else if (!_el.className.includes(' active') && (typeof forcedValue === 'undefined' || forcedValue)) {
    _el.className += ' active'
  }
}

/**
 * Check if line contains D24 Module
 * @param line
 *
 * @author Javlon Khalimjonov <javlon.khalimjanov@movecloser.pl>
 */
export function convertLineToToken (line: any): string {
  const objects: AnyObject[] = DeMarked.convertMDToObjects(line)

  let foundToken: string = ''

  for (let i = 0; i < objects.length; i++) {
    foundToken = objects[i].driver
  }

  return foundToken
}

/**
 *
 * Function to identify custom toolbar items and highlight them.
 *
 * @author Javlon Khalimjonov <javlon.khalimjanov@movecloser.pl>
 */
export function highlightCustomActions (codeMirror: any): void {
  /**
   * Initial position of cursor
   */
  const _module = convertLineToToken(codeMirror.getLine(codeMirror.getCursor().line))

  // Filter elements from '|' that are not containing classes and defined as `String`
  const ToolbarItemsCopy = SIMPLEMDE_CONFIG.toolbar
    .filter(item => Object.prototype.hasOwnProperty.call(item, 'className')) as Shortcut[]

  if (!_module) {
    return
  }

  for (let i = 0; i < ToolbarItemsCopy.length; i++) {
    if (ToolbarItemsCopy[i].name === _module) {
      toggleActiveClassOnToolbarElement(ToolbarItemsCopy[i].className)
    }
  }
}
