// Copyright © 2022 Move Closer

import Vue from 'vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface PreLoadedResults {
  [key: string]: unknown
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const preLoadedResultsKey = 'preloaded-results'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type PreLoader = (vue: Vue) => Promise<void>
