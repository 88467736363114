
































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { BreadcrumbsProps, DashmixTheme, TableHead, TableRowAction, TableRowElement } from '@d24/modules'
import { IModal, ModalType } from '@movecloser/front-core'
import { MetaInfo } from 'vue-meta'

import { DropdownActions } from '@/shared/contracts/content'
import { HasIdentifier, Identifier } from '@/shared/contracts/data'
import { Inject } from '@/shared/plugins/inversify'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { ModelListHeader } from '@component/ModelListHeader/ModelListHeader'
import { ModelListHeaderProps } from '@component/ModelListHeader/ModelListHeader.contracts'
import { HeaderInterface } from '@component/InteractiveTable/InteractiveTable.contracts'
import { Query } from '@/shared/contracts/query'
import { Modals } from '@/config/modals'
import { ModalSize } from '@component/Modal'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { initBreadcrumbs } from '../helpers'
import { ISetsRepository, SetsRepositoryType } from '../contracts/repositories'
import { SetsListsActions, setsRowActionsFactory, setsTableHead } from '../maps/sets'
import { GeneratedNewsletterModalPayload, SetData, SetType } from '../contracts'
import { SetTableRow } from '../components/SetTableRow.vue'
import { setsFiltersConfig } from '../models/sets.filters'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'SetsList',
  components: { InteractiveTable, ModelListHeader },
  metaInfo (this: SetsList): MetaInfo {
    return {
      title: `${this.$t('content.sets.listTitle')}`
    }
  }
})
export class SetsList extends Mixins<IUserAware>(UserAware) {
  @Inject(SetsRepositoryType)
  protected setsRepository!: ISetsRepository

  @Inject(ModalType)
  private modalConnector!: IModal

  public isLoading = false
  public filtersConfig = setsFiltersConfig

  public get header (): HeaderInterface {
    const payload: Partial<HeaderInterface> = {
      title: 'Listy'
    }

    if (this.canCreateSets) {
      payload.buttonLabel = 'Dodaj listę'
      payload.linkTarget = { name: 'content.sets.lists.create' }
    }

    return payload as HeaderInterface
  }

  public get modelListHeader (): ModelListHeaderProps {
    const props: Partial<ModelListHeaderProps> = {
      title: String(this.$t('content.sets.listTitle'))
    }

    if (this.canCreateSets) {
      props.addLabel = String(this.$t('content.sets.listButton'))
      props.addTarget = { name: 'content.sets.create' }
    }

    return props as ModelListHeaderProps
  }

  public tableHead: TableHead = setsTableHead
  public rowComponent = SetTableRow
  public tableData: TableRowElement[] = []

  public totalPages: number = 0
  public itemsTotal: number = 0

  public get rowActions (): TableRowAction[] {
    return setsRowActionsFactory(this.domain, this.user)
  }

  public actions: DropdownActions = {
    [SetsListsActions.Preview]: {
      callback: (data: unknown) => {
        this.$router.push({ name: 'content.sets.edit', params: { id: (data as SetData).id.toString() } })
      }
    },
    [SetsListsActions.Generate]: {
      callback: (data: unknown) => {
        return this.onGeneratedNewsletter(data)
      }
    },
    [SetsListsActions.Delete]: {
      callback: (data: unknown) => {
        return this.onDelete((data as HasIdentifier).id)
      },
      confirmation: {
        header: 'Usuń listę',
        contentText: 'Czy na pewno chcesz usunąć:',
        theme: DashmixTheme.Danger,
        buttonLabel: 'atoms.delete'
      }
    }
  }

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('content.sets.listTitle')}`,
          target: { name: 'content.sets.list' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  protected get queryParams (): Query {
    return this.$route.query
  }

  mounted () {
    this.loadList(this.queryParams)
  }

  private onGeneratedNewsletter (data: unknown): void {
    const payload: GeneratedNewsletterModalPayload = {
      setId: (data as SetData).id.toString(),
      onClose: () => this.modalConnector.close(),
      onConfirm: () => this.modalConnector.close()
    }
    this.modalConnector.open(Modals.GeneratedNewsletter, payload, { closableWithOutsideClick: true, size: ModalSize.xl })
  }

  @Watch('queryParams', { deep: true })
  protected onPageChange (query: Query): void {
    this.loadList(query)
  }

  protected loadList (query: Query): void {
    this.isLoading = true
    this.setsRepository.loadCollection(SetType.Articles, query).then(collection => {
      this.isLoading = false

      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: this.canEditSets || this.canDeleteSets,
          selected: false,
          data: model
        }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.itemsTotal = collection.meta.total
    }).catch(error => {
      console.log(error)
      this.isLoading = false
    })
  }

  protected onDelete (id: Identifier) {
    return this.setsRepository.delete(id).then(() => {
      this.tableData = this.tableData.filter(rowData => rowData.id !== id.toString())
    })
  }
}

export default SetsList
