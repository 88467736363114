





























import { BreadcrumbsProps, DashmixTheme, TableHead, TableRowAction } from '@d24/modules'
import { Component, Mixins, Watch } from 'vue-property-decorator'

import { DropdownActions } from '@/shared/contracts/content'
import { HeaderInterface } from '@component/InteractiveTable/InteractiveTable.contracts'
import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@plugin/inversify'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { ModelListHeader } from '@component/ModelListHeader'
import { Query } from '@/shared/contracts/query'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import {
  CommentModel,
  CommentRepositoryType, CommentTableRowElement,
  ICommentRepository
} from '../contracts'

import {
  CommentsActions,
  commentsRowActionsFactory,
  commentTableHead
} from '../maps/comments'

import { CommentsTableRow } from '../components/CommentsTableRow.vue'
import { Comment } from '../components/Comment.vue'
import { commentsFiltersConfig } from '../models/comments.filters'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component<CommentsList>({
  name: 'CommentsList',
  components: { Comment, InteractiveTable, ModelListHeader },

  mounted () {
    this.loadList(this.queryParams)
  }
})
export class CommentsList extends Mixins<IUserAware>(UserAware) {
  @Inject(CommentRepositoryType)
  protected commentRepository!: ICommentRepository

  public isLoading: boolean = false
  public totalItems: number = 0

  public tableHead: TableHead = commentTableHead
  public tableData: CommentTableRowElement[] = []
  public rowComponent = CommentsTableRow
  public filtersConfig = commentsFiltersConfig

  public totalPages: number = 0
  public itemsTotal: number = 0

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('content.article.listTitle')}`,
          target: { name: 'content.articles.list' }
        },
        {
          label: `${this.$t('content.article.commentsList')}`,
          target: { name: 'content.comments' }
        }
      ],
      root: {
        label: `${this.$t('root.views.root')}`,
        target: { name: 'root.dashboard' }
      }
    }
  }

  public get header (): HeaderInterface {
    const payload: Partial<HeaderInterface> = {
      title: `${this.$t('content.article.listTitle')}`
    }

    if (this.canCreateContents) {
      payload.buttonLabel = 'content.addArticle'
      payload.linkTarget = { name: 'content.articles.create' }
    }

    return payload as HeaderInterface
  }

  public get hasStatus (): boolean {
    return Object.prototype.hasOwnProperty.call(this.queryParams, 'status') && !Object.prototype.hasOwnProperty.call(this.queryParams, 'page')
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  public get rowActions (): TableRowAction[] {
    return commentsRowActionsFactory(this.domain, this.user)
  }

  public actions: DropdownActions = {
    [CommentsActions.Accept]: {
      callback: (data: unknown) => {
        const model = data as CommentModel
        return this.onAccept(model.id)
      },
      confirmation: {
        header: 'actions.accept.header',
        contentText: 'actions.accept.contentText',
        theme: DashmixTheme.Info,
        buttonLabel: 'atoms.accept'
      }
    },
    [CommentsActions.Delete]: {
      callback: (data: unknown) => {
        const model = data as CommentModel
        return this.onReject(model.id)
      },
      confirmation: {
        header: 'actions.reject.header',
        contentText: 'actions.reject.contentText',
        theme: DashmixTheme.Danger,
        buttonLabel: 'atoms.reject'
      }
    }
  }

  /**
   * Accept comment on CommentList
   * @param id
   * @protected
   */
  protected onAccept (id: Identifier) {
    return this.commentRepository.accept(id).then(async () => {
      await this.loadList(this.queryParams)
    })
  }

  /**
   * Reject comment on CommentList
   * @param id
   * @protected
   */
  protected onReject (id: Identifier) {
    return this.commentRepository.reject(id).then(async () => {
      await this.loadList(this.queryParams)
    })
  }

  protected loadList (query: Query = {}): void {
    this.isLoading = true

    this.commentRepository.loadCollection({ sort: '-createdAt', ...query }).then(collection => {
      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: this.canEditContents || this.canDeleteContents,
          data: model
        }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)

      this.itemsTotal = collection.meta.total
      this.totalItems = collection.meta.total
      this.isLoading = false
    }).catch(error => console.warn(error)
    ).finally(() => { this.isLoading = false })
  }

  @Watch('queryParams', { deep: true })
  protected onQueryChange (query: Query): void {
    this.loadList(query)
  }
}

export default CommentsList
