














































import { BreadcrumbsItem, BreadcrumbsProps, DashmixTheme, TableRowAction, TableRowElement } from '@d24/modules'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { ICollection, IModal, ModalType, ResourceActionFailed } from '@movecloser/front-core'
import { MetaInfo } from 'vue-meta'

import { DropdownActions } from '@/shared/contracts/content'
import { HeaderInterface } from '@component/InteractiveTable/InteractiveTable.contracts'
import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@/shared/plugins/inversify'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { Loader } from '@component/Loader/Loader'
import { ModelListHeader } from '@component/ModelListHeader/ModelListHeader'
import { Modals } from '@/config/modals'
import { ModelListHeaderProps } from '@component/ModelListHeader/ModelListHeader.contracts'
import { Query } from '@/shared/contracts/query'

import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'
import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { ContentModel, ContentRepositoryType, ContentStatus, ContentType, IContentRepository, IPublishContentPayload, IVariantsRepository, VariantsRepositoryType } from '../contracts'
import { pageFiltersConfig } from '../models/content.filters'
import { createBreadcrumbsFromContent, initBreadcrumbs } from '../helpers'
import { PagesActions, pagesRowActionsFactory, pagesTableHead, searchingPagesTableHead } from '../maps/pages'
import { PagesParentRow } from '../components/PagesParentRow.vue'
import { PagesTableRow } from '../components/PagesTableRow.vue'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<PagesList>({
  name: 'PagesList',
  components: {
    Loader,
    InteractiveTable,
    ModelListHeader,
    PagesParentRow,
    PagesTableRow
  },

  mounted () {
    this.loadList(this.queryParams)
  },

  metaInfo (this: PagesList): MetaInfo {
    return {
      title: `${this.$t('content.pages.listTitle')}`
    }
  }
})
export class PagesList extends Mixins<IUserAware>(UserAware) {
  @Inject(ContentRepositoryType)
  protected contentRepository!: IContentRepository

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(SiteResolverType)
  private siteResolver!: ISiteResolver

  @Inject(VariantsRepositoryType)
  protected variantsRepository!: IVariantsRepository

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: this.BreadcrumbsItems.splice(1),
      root: this.BreadcrumbsItems[0]
    }
  }

  public get header (): HeaderInterface {
    const payload: Partial<HeaderInterface> = {
      title: 'Strony'
    }

    if (this.canCreateContents) {
      payload.buttonLabel = 'content.pages.add'
      payload.linkTarget = { name: 'content.pages.create' }
    }

    return payload as HeaderInterface
  }

  public get isSearching (): boolean {
    return typeof this.queryParams.q === 'string' && this.queryParams.q.length > 0
  }

  public get modelListHeader () {
    const payload: Partial<ModelListHeaderProps> = {
      title: String(this.$t('content.pages.listTitle'))
    }

    if (this.canCreateContents) {
      payload.addLabel = String(this.$t('content.pages.listButton'))
      payload.addTarget = { name: 'content.pages.create' }
    }

    return payload as ModelListHeaderProps
  }

  public get mainBreadcrumbs (): BreadcrumbsProps {
    return {
      items: [{
        label: `${this.$t('content.pages.listTitle')}`,
        target: { name: 'content.pages.list' }
      }],
      root: initBreadcrumbs.root
    }
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  public get rowActions (): TableRowAction[] {
    return pagesRowActionsFactory(this.domain, this.user)
  }

  public get tableHead () {
    return !this.isSearching ? pagesTableHead : searchingPagesTableHead
  }

  public BreadcrumbsItems: BreadcrumbsItem[] = []
  public filtersConfig = pageFiltersConfig
  public isLoading = false
  public isPreviewLoading: boolean = false
  public itemsTotal: number = 0
  public parentRow: TableRowElement = {} as TableRowElement
  public rowComponent = PagesTableRow
  public tableData: TableRowElement[] = []

  public actions: DropdownActions = {
    [PagesActions.Preview]: {
      callback: (data: unknown) => {
        this.onPreview(data as ContentModel)
      }
    },

    [PagesActions.Edit]: {
      callback: (data: unknown) => {
        const model = data as ContentModel
        this.$router.push({
          name: 'content.pages.content',
          params: { id: `${model.id}` }
        })
      }
    },

    [PagesActions.Publish]: {
      callback: (data: unknown, payload: IPublishContentPayload) => {
        const model = data as ContentModel
        return this.onPublish(model.id, payload)
      },
      confirmation: {
        header: 'actions.publish.header',
        contentText: 'actions.publish.contentText',
        buttonLabel: 'atoms.publish'
      }
    },

    [PagesActions.Delete]: {
      callback: (data: unknown) => {
        const model = data as ContentModel
        return this.onDelete(model.id)
      },
      confirmation: {
        header: 'actions.delete.header',
        contentText: 'actions.delete.contentText',
        theme: DashmixTheme.Danger,
        buttonLabel: 'atoms.delete'
      }
    },

    [PagesActions.Sort]: {
      callback: () => {
        return this.onSortContent()
      }
    }
  }

  public onSortContent (): void {
    this.modalConnector.open(Modals.SortContent, {
      collection: this.tableData.map(r => r.data),
      parent: this.parentRow.data,
      onConfirm: (children: Identifier[]) => {
        this.sort(children, () => { this.modalConnector.close() })
      },
      onClose: () => this.modalConnector.close()
    }, { className: 'sort-content-modal__modal' })
  }

  private sort (children: Identifier[], callback: () => void): void {
    let parentId: Identifier = 0

    if (this.$route.query.parent) {
      parentId = this.$route.query.parent as unknown as Identifier
    } else {
      const siteModel = this.siteResolver.getSite()

      if (siteModel) {
        parentId = siteModel.rootContent
      }
    }
    this.contentRepository.changeOrder(
      parentId,
      children
    ).then(() => {
      this.loadList(this.queryParams)
      if (callback) {
        callback()
      }
    }).catch(error => {
      console.log(error)
    })
  }

  protected loadList (query: Query): void {
    this.isLoading = true
    let parentId: Identifier = 0

    if (this.$route.query.parent) {
      parentId = this.$route.query.parent as unknown as Identifier
    } else {
      const siteModel = this.siteResolver.getSite()

      if (siteModel) {
        parentId = siteModel.rootContent
      }
    }

    const promise: Promise<ICollection<ContentModel>|ContentModel> = this.isSearching
      ? this.contentRepository.loadCollection([ContentType.Page, ContentType.SmartPage], { q: this.queryParams.q })
      : this.contentRepository.loadNode([ContentType.Page, ContentType.SmartPage], parentId, query)

    promise.then((data: ICollection<ContentModel>|ContentModel) => {
      this.isLoading = false

      this.tableData = [
        ...(Array.isArray(data) ? data : (data.children || []))
      ].map(model => {
        return {
          id: `${model.id}`,
          selectable: this.canEditContents || this.canDeleteContents,
          data: model
        }
      })

      this.itemsTotal = Array.isArray(data)
        ? (data.meta.total ? data.meta.total : 0)
        : (data.childrenCount ? data.childrenCount : 0)

      this.BreadcrumbsItems = []
      if (!Array.isArray(data)) {
        this.BreadcrumbsItems = createBreadcrumbsFromContent(data, 'content.pages.list', 'parent')

        this.parentRow = { id: `${data.id}`, selectable: false, data: data }
      }
    }).catch((error: ResourceActionFailed) => {
      console.log(error)
      this.isLoading = false
    })
  }

  @Watch('queryParams', { deep: true })
  protected onPageChange (query: Query): void {
    this.loadList(query)
  }

  protected onDelete (id: Identifier) {
    return this.contentRepository.delete(id).then(() => {
      this.tableData = this.tableData.filter(rowData => rowData.id !== id.toString())
      --this.itemsTotal
    })
  }

  protected onPublish (id: Identifier, payload: IPublishContentPayload) {
    return this.contentRepository.publish(id, payload)
  }

  protected onPreview (data: ContentModel) {
    if (!data || !data.url || !data.variants) {
      return
    }
    this.isPreviewLoading = true

    const activeVariants = data.variants.filter((variant) => variant.status === ContentStatus.Published)

    if (!activeVariants.length || activeVariants.length < 0) {
      return
    }

    this.variantsRepository.preview(
        data.id as unknown as Identifier,
        activeVariants[0].id as unknown as number
    ).then(url => {
      window.open(url)
    }).finally(() => {
      this.isPreviewLoading = false
    })
  }
}

export default PagesList
