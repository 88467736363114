// Copyright © 2021 Move Closer

import { Container, RoutesFactory } from '@movecloser/front-core'

import CreateFormView from '@module/forms/views/CreateFormView.vue'
import FormsList from '@module/forms/views/FormsList.vue'
import EditFormView from '@module/forms/views/EditFormView.vue'
import { Permission } from '@module/auth/contracts/permissions'

export const routesFactory: RoutesFactory = (container: Container) => {
  return [
    {
      path: '/forms',
      name: 'forms.list',
      component: FormsList,
      meta: {
        permissions: [
          Permission.ViewForms
        ]
      }
    },
    {
      path: '/forms/create/',
      name: 'forms.create',
      component: CreateFormView,
      meta: {
        permissions: [
          Permission.CreateForms
        ]
      }
    },
    {
      path: '/forms/edit/:id',
      name: 'forms.edit',
      component: EditFormView,
      meta: {
        permissions: [
          Permission.EditForms
        ]
      }
    }
  ]
}
