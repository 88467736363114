// Copyright © 2021 Move Closer

import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { SlickItem, SlickList } from 'vue-slicksort'
import { VueConstructor } from 'vue'

@Component({
  name: 'SortableCollection',
  components: { SlickList, SlickItem },
  template: `
    <div class="sortable__list__container">
    <SlickList v-if="collection.length"
               class="sortable__list" :class="{disabled: isDisabled}"
               appendTo=".sortable__list" helperClass="sortable__list_helper"
               :value="collection" v-model="collection"
               :useDragHandle="true" axis="y" lockAxis="y" tag="ol" :autoScroll="true"
               @sort-end="payload => $emit('reordered', payload)">

      <SlickItem class="sortable__list__item" v-for="(item, index) in collection" :index="index" :key="index" tag="li">
        <component :is="itemComponent" :item="collection[index]" :index="index" :position="index + 1" />
      </SlickItem>
    </SlickList>
      <p class="sortable__no_items" v-else>
        {{ $t('components.sortable.no_elements') }}
      </p>
    </div>
  `
})
export class SortableCollection extends Vue {
  @PropSync('items', { type: Array, required: true, default: () => [] })
  public collection!: unknown[]

  @Prop({ type: Boolean, required: false, default: false })
  public isDisabled?: boolean

  @Prop({ type: Function, required: true })
  public itemComponent!: VueConstructor
}
