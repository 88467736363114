























import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName, DashmixTheme } from '@d24/modules'

import { FormInput } from '@component/form'

import { SocialSite } from '../contracts/data'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'PagesSideBar',
  components: { FormInput }
})
export class PagesSideBar extends Vue {
  @Prop({ type: String, required: true })
  public form!: string

  @Prop({ type: Boolean, required: false, default: false })
  public isEditMode!: boolean

  @Prop({ type: Boolean, required: true })
  public isSaving!: boolean

  @Prop({ type: Object, required: true })
  public pages!: Partial<Record<SocialSite, string>>

  public icons = DashmixIconName
  public isLoading: boolean = false
  public themes = DashmixTheme
  public socialSites = SocialSite
}

export default PagesSideBar
