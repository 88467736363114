// Copyright © 2021 Move Closer

import { defaultIgnoredQueryParams, FiltersConfig, FilterType } from '@component/Filters'
import { loadEditors } from '@/shared/helpers/filters'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const fileFiltersConfig: FiltersConfig = {
  groups: {
    createdAt: {
      label: 'Data utworzenia',
      key: 'createdAt',
      type: FilterType.Date
    },
    addedBy: {
      label: 'Kto dodał',
      key: 'addedBy',
      type: FilterType.Typeahead,
      dictionaryLoader: loadEditors
    },
    author: {
      label: 'Autor',
      key: 'author',
      type: FilterType.String
    },
    title: {
      label: 'Title',
      key: 'title',
      type: FilterType.String
    },
    alt: {
      label: 'Alt',
      key: 'alt',
      type: FilterType.String
    },
    description: {
      label: 'Description',
      key: 'description',
      type: FilterType.String
    },
    id: {
      label: 'Id',
      key: 'id',
      type: FilterType.Number
    }
    // Wyłączone z powodu braku obsługi w API
    // type: { label: 'Typ', key: 'type', type: FilterType.Dictionary, dictionary: loadFileTypesDict() }
  },
  ignore: [...defaultIgnoredQueryParams],
  override: { page: '1' },
  leave: ['perPage']
}
