// Copyright © 2021 Move Closer

import { DashmixIconName, TableHead, TableSearchBarAction } from '@d24/modules'

import { UserModel } from '@module/auth/contracts/models'
import { Domain } from '@module/root/contracts/models'

import { canDeleteMedia, canEditMedia } from '../guards'

export const galleryTableHead: TableHead = [
  {
    column: 'name',
    label: 'Nazwa',
    sortable: true,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'author',
    label: 'Autor',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'fileNumber',
    label: 'Ilość zdjęć',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'createdDate',
    label: 'Data utworzenia',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'updatedAt',
    label: 'Data modyfikacji',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  }
]

export enum GalleryItemListActions {
  Remove = 'remove',
  Edit = 'edit'
}

export enum GalleryListActions {
  Delete = 'delete',
  Edit = 'edit'
}

export const galleryItemsRowActions: TableSearchBarAction[] = [
  {
    label: 'Edytuj',
    icon: DashmixIconName.PenSolid,
    isBulkAction: false,
    action: GalleryItemListActions.Edit
  },
  {
    label: 'Usuń z galerii',
    icon: DashmixIconName.TrashAltSolid,
    isBulkAction: true,
    action: GalleryItemListActions.Remove
  }
]

export const galleryRowActionsFactory = (domain: Domain, user: UserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canEditMedia(domain, user)) {
    actions.push(
      {
        label: 'Edytuj',
        icon: DashmixIconName.PenSolid,
        isBulkAction: false,
        action: GalleryListActions.Edit
      }
    )
  }

  if (canDeleteMedia(domain, user)) {
    actions.push(
      {
        label: 'Usuń',
        icon: DashmixIconName.TrashAltSolid,
        isBulkAction: true,
        action: GalleryListActions.Delete
      }
    )
  }

  return actions
}
