























import { Component } from 'vue-property-decorator'
import { debounce, DebouncedFunc } from 'lodash'

import { DateTimePickerType } from '@d24/modules'

import { OverridePublishDate } from '@module/content/contracts'

import { Addon } from '../../maps/variant'

import { AbstractAddon } from './AbstractAddon'
import { AddonErrors } from './AddonErrors.vue'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component({
  name: 'OverridePublishDateAddon',
  components: { AddonErrors }
})
export class OverridePublishDateAddon extends AbstractAddon {
  public dateTimePickerType = DateTimePickerType
  public overriddenPublishDate: string = ''

  public get publishDate (): string {
    return this.overriddenPublishDate
  }

  public set publishDate (date) {
    this.overriddenPublishDate = date
    this.setNewDate(date)
  }

  public disabledAfterCurrentDate (date: Date): boolean {
    const today = new Date()

    return date > today
  }

  public publicationDate (date: Date): boolean {
    return this.disabledAfterCurrentDate(date)
  }

  protected getVariantProperty (): void {
    if (this.variant) {
      const overridePublishDate = this.variant.getProperty<OverridePublishDate>(Addon.OverridePublishDate)

      this.overriddenPublishDate = overridePublishDate?.date ?? ''
    }
  }

  protected setNewDate: DebouncedFunc<(date: string) => void> =
      debounce((date: string) => {
        this.setNewDate.cancel()

        this.variant.setProperty<OverridePublishDate>(Addon.OverridePublishDate, { date })

        this.onChange(this.variant)
      }, 1000)
}

export default OverridePublishDateAddon
