// Copyright © 2022 Move Closer
import { ICollection } from '@movecloser/front-core'

import { Query } from '@/shared/contracts/query'

import { CreateFormIntention } from '../intensions/CreateFormIntention'
import { EditFormIntention } from '../intensions/EditFormIntention'
import { FormData, FormModel } from './models'

export const FormsRepositoryType = Symbol.for('IFormRepository')

export interface IFormsRepository {
  create (intention: CreateFormIntention): Promise<void>
  delete (id: FormData['id']): Promise<void>
  download (id: FormData['id']): Promise<{ filename: string; file: Blob }>
  load (id: FormData['id']): Promise<FormModel>
  loadCollection (query?: Query): Promise<ICollection<FormModel>>
  update (formId: FormData['id'], intention: EditFormIntention): Promise<void>

}
