// Copyright © 2021 Move Closer

import { ICollection } from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'
import { Query } from '@/shared/contracts/query'

import { CreateUserIntention } from '../intentions/CreateUserIntention'
import { EditUserIntention } from '../intentions/EditUserIntention'
import { CreateAuthorIntention } from '../intentions/CreateAuthorIntention'
import { EditAuthorIntention } from '../intentions/EditAuthorIntention'

import { CreateRoleIntention } from '../intentions/createRole'
import {
  AuthorModel, LogModel,
  PeriodData,
  Prices,
  RoleData,
  RoleModel,
  SimpleUser,
  SubscriptionModel,
  TransactionData,
  UserModel, UsersInfoData
} from './models'
import { UpdateRoleIntention } from '../intentions/updateRole'
import { SubscriptionPayload } from '@module/users/contracts/data'

export interface IAuthorRepository {
  create (intention: CreateAuthorIntention): Promise<void>
  delete (id: Identifier): Promise<void>
  edit (id: Identifier, intention: EditAuthorIntention): Promise<void>
  load (id: Identifier): Promise<AuthorModel>
  loadCollection (query: Query): Promise<ICollection<AuthorModel>>
}

export interface ILogsRepository {
  loadCollection (query: Query): Promise<ICollection<LogModel>>
}

export interface IRolesRepository {
  create (intention: CreateRoleIntention): Promise<string>
  delete (id: Identifier): Promise<void>
  update (id: Identifier, intention: UpdateRoleIntention): Promise<void>
  load (id: RoleData['id']): Promise<RoleModel>
  loadCollection (query?: Query): Promise<ICollection<RoleModel>>
}

export interface ISubscriptionRepository {
  cancel (id: SubscriptionModel['id']): Promise<string>
  forceCancel (id: SubscriptionModel['id']): Promise<string>
  create (id: SimpleUser['id'], payload: SubscriptionPayload): Promise<void>
  downloadInvoice (slug: string): Promise<Blob>
  getPrices (): Promise<Prices>
  getTransactionsDetails (id: TransactionData['id']): Promise<object>
  load (id: SubscriptionModel['id']): Promise<SubscriptionModel>
  loadCollection (query: Query): Promise<ICollection<SubscriptionModel>>
  loadPeriods (id: SubscriptionModel['id'], query: Query): Promise<ICollection<PeriodData>>
  loadTransactions (id: SubscriptionModel['id'], query: Query): Promise<ICollection<TransactionData>>
  markAsPaid (subscriptionId: SubscriptionModel['id'], id: PeriodData['id']): Promise<void>
  sendPaymentEmail (subscriptionId: SubscriptionModel['id'], id: PeriodData['id']): Promise<void>
  update (id: SubscriptionModel['id'], payload: SubscriptionPayload): Promise<void>
}

export interface IUserRepository {
  create (intention: CreateUserIntention): Promise<void>
  delete (id: Identifier): Promise<void>
  edit (id: Identifier, payload: EditUserIntention): Promise<void>
  load (id: Identifier): Promise<UserModel>
  loadCollection (query: Query): Promise<ICollection<UserModel>>
  loadUsersInfo (): Promise<UsersInfoData>
  switchActive(id: Identifier, isActive: boolean, model: UserModel): Promise<UserModel>
  switchShadowban(id: Identifier, isShadowbanned: boolean, model: UserModel): Promise<UserModel>
}

export const AuthorRepositoryType = Symbol.for('IAuthorRepository')
export const LogRepositoryType = Symbol.for('ILogRepository')
export const RolesRepositoryType = Symbol.for('IRoleRepository')
export const SubscriptionRepositoryType = Symbol.for('ISubscriptionRepository')
export const UserRepositoryType = Symbol.for('IUserRepository')
