// Copyright © 2021 Move Closer

import { AnyObject, ModalConfig, ModalPayload } from '@movecloser/front-core'

export interface ModalConfiguration extends ModalConfig {
  className?: string
  closableWithOutsideClick?: boolean
  sizable?: boolean
  width?: ModalSize
  height?: ModalSize
  borderFooter?: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

export enum ModalSize {
  xs = 'xs',
  sm = 's',
  md = 'm',
  lg = 'l',
  xl = 'xl',
  full = 'full-width',
  auto = 'auto'
}

export interface SwapModalPayload {
  config?: ModalConfig
  payload?: ModalPayload|null
  override?: ModalPayload
  toOpen: string
}

export interface SwapPayload extends ModalPayload {
  intersectedData?: AnyObject
}
