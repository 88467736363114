// Copyright © 2021 Move Closer

import { AuthHeader, Headers } from '@movecloser/front-core'

import { FilePondOptions } from 'filepond'

export function composeUploaderConfig (config: any, tokenHeader: AuthHeader, siteHeader: Headers, isPrivate: boolean = true): FilePondOptions {
  const configCopy = JSON.parse(JSON.stringify(config))
  configCopy.server.process.url += isPrivate ? 'private' : 'public'
  configCopy.server.process.headers = {
    ...configCopy.server.process.headers,
    ...tokenHeader,
    ...siteHeader
  }
  configCopy.server.patch.headers = {
    ...configCopy.server.patch.headers,
    ...tokenHeader,
    ...siteHeader
  }

  // configCopy.server.process.onload = (res: string) => {
  //   // return JSON.parse(res).data.id
  // }
  // configCopy.server.process.onerror = (res: string) => {
  //   // return JSON.parse(res)?.errors?.file[0] ?? JSON.parse(res).message ?? JSON.parse(res)
  // }

  return configCopy
}
