// Copyright © 2021 Move Closer

import { AnyObject, ICollection, Injectable, Repository, ResourceActionFailed } from '@movecloser/front-core'

import { DictData, DictModel } from '../contracts/models'
import { Dict } from '../models/dict'
import { IDictionaryRepository } from '../contracts/repositories'
import { resolveFromStatus } from '@/shared/exceptions/connector-errors'
import { Query } from '@/shared/contracts/query'
import { Identifier } from '@/shared/contracts/data'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */

@Injectable()
export class DictRepository extends Repository<DictData, Dict> implements IDictionaryRepository {
  public async loadAuthor (id: Identifier): Promise<DictModel> {
    const response = await this.connector.call(
      'dict',
      'author',
      { id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeModel(
      response.data.data,
      Dict
    )
  }

  public async loadAuthorsDictionary (query: Query): Promise<ICollection<DictModel>> {
    const response = await this.connector.call(
      'dict',
      'authors',
      {},
      {
        scope: 'typeahead',
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Dict,
      response.data.meta
    )
  }

  public async loadGalleryDictionary (query: Query): Promise<ICollection<DictModel>> {
    const response = await this.connector.call(
      'dict',
      'galleries',
      {},
      {
        type: 'gallery',
        scope: 'typeahead',
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data.map((d: AnyObject) => {
        return {
          name: d.title,
          id: d.id
        }
      }),
      Dict,
      response.data.meta
    )
  }

  public async loadLabelsDictionary (): Promise<ICollection<DictModel>> {
    const response = await this.connector.call(
      'dict',
      'labels'
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Dict,
      response.data.meta
    )
  }

  public async loadLayoutsDictionary (query: Query): Promise<ICollection<DictModel>> {
    const response = await this.connector.call(
      'dict',
      'layouts',
      {},
      {
        scope: 'typeahead',
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Dict,
      response.data.meta
    )
  }

  public async loadTag (id: Identifier): Promise<DictModel> {
    const response = await this.connector.call(
      'dict',
      'tag',
      { id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeModel(
      response.data,
      Dict
    )
  }

  public async loadTagsDictionary (query: Query): Promise<ICollection<DictModel>> {
    const response = await this.connector.call(
      'dict',
      'tags',
      {},
      {
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Dict,
      response.data.meta
    )
  }

  public async loadRolesDictionary (query: Query): Promise<ICollection<DictModel>> {
    const response = await this.connector.call(
      'dict',
      'roles',
      {},
      {
        scope: 'typeahead',
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Dict,
      response.data.meta
    )
  }

  public async loadUsersDictionary (query: Query): Promise<ICollection<DictModel>> {
    const response = await this.connector.call(
      'dict',
      'users',
      {},
      {
        scope: 'typeahead',
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Dict,
      response.data.meta
    )
  }

  public async loadUser (id: Identifier): Promise<DictModel> {
    const response = await this.connector.call(
      'dict',
      'user',
      { id },
      {
        scope: 'typeahead'
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeModel(
      response.data.data,
      Dict
    )
  }
}
