

















import { BreadcrumbsProps } from '@d24/modules'
import { Component, Vue } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

import { EditModeLayout } from '@component/EditModeLayout'
import { FormCheckbox } from '@component/form'
import { Inject } from '@/shared/plugins/inversify'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'
import { PropertyItem } from '@/shared/contracts/content'

import { AddContentIntention } from '../intentions/AddContentIntention'
import { ContentCreatePayload } from '../contracts/data'
import { ContentType, Properties } from '../contracts/models'
import { ContentBasicsForm } from '../components/ContentBasicsForm.vue'
import { initBreadcrumbs } from '../helpers'
import { IContentRepository, ContentRepositoryType } from '../contracts/repositories'
import { pageOptions } from '../maps/page'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'CreatePage',
  components: { ContentBasicsForm, EditModeLayout, FormCheckbox },
  metaInfo (this: CreatePage): MetaInfo {
    return {
      title: `${this.$t('content.pages.add')}`
    }
  }
})
export class CreatePage extends Vue {
  @Inject(ContentRepositoryType)
  protected contentRepository!: IContentRepository

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public contentType = ContentType.Page
  public formName: string = 'createContent'
  public isSaving: boolean = false
  public payload: ContentCreatePayload = { parent: null, slug: '', template: '', title: '', type: ContentType.Page, properties: {} }
  public modelProperties: string[] = []

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('content.pages.listTitle')}`,
          target: { name: 'content.pages.list' }
        },
        {
          label: `${this.$t('content.pages.add')}`,
          target: { name: 'content.pages.create' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get contentOptions (): PropertyItem[] {
    const site = this.siteResolver.getSite()
    if (!site) {
      return []
    }

    return pageOptions(site)
  }

  public get optionsDictionary () {
    if (!this.contentOptions.length) {
      return []
    }

    return this.contentOptions.map(option => {
      return {
        label: this.$t(option.translationKey).toString(),
        key: option.key
      }
    })
  }

  public get properties (): string[] {
    return this.modelProperties
  }

  public createNewArticle (): void {
    this.isSaving = true

    this.contentRepository.create((new AddContentIntention(this.payload)).toRequest())
      .then(id => {
        this.$router.push({ name: 'content.pages.content', params: { id: `${id}` } })
      }).catch(error => {
        console.warn(error)
      }).finally(() => {
        this.isSaving = false
      })
  }

  protected onPropertiesChange (options: string[]) {
    this.setProperties(options)
  }

  protected setProperties (options: string[]): void {
    this.modelProperties = options
    this.payload.properties = this.contentOptions.map(o => o.key)
      .reduce((agr: Properties, o: string) => {
        agr[o] = options.includes(o)
        return agr
      }, {} as Properties)
  }
}

export default CreatePage

