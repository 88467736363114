// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'
import { FeedEntryData } from '@module/content/contracts'

export class FeedEntry extends Model<FeedEntryData> {
  protected boot () {
    this.modelProperties = [
      'id',
      'gallery',
      'image',
      'embed',
      'content',
      'createdAt',
      'deletedAt'
    ]
  }
}
