// Copyright © 2021 Move Closer

import { defineComponent } from '@vue/composition-api'
import { VPopover } from 'v-tooltip'

import { popoverProps } from './Popover.hooks'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const Popover = defineComponent({
  name: 'Popover',
  props: popoverProps,
  components: { VPopover },
  template: `
    <VPopover :trigger="triggerMode" :placement="position" >
      <!-- This will be the popover target (for the events and position) -->
      <template>
        <slot name="trigger" />
      </template>
      <!-- This will be the content of the popover -->
      <template slot="popover" >
        <slot name="content" />
      </template>
    </VPopover>
  `
})
