














import { Authentication, AuthServiceType } from '@movecloser/front-core'
import { Component, Vue } from 'vue-property-decorator'
import { DashmixIconName, DropdownItem } from '@d24/modules'

import { Inject } from '@/shared/plugins/inversify'

import { AuthRepositoryType, IAuthRepository } from '@module/auth/contracts/repositories'
import { UserModel } from '@module/auth/contracts/models'

@Component({
  name: 'UserMenu'
})
export class UserMenu extends Vue {
  @Inject(AuthRepositoryType)
  protected authRepository!: IAuthRepository

  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  public get items (): DropdownItem[] {
    return [
      {
        type: 'item',
        label: `${this.$t('atoms.welcome')} ${this.user.firstName}`
      }, {
        type: 'item',
        icon: DashmixIconName.User,
        label: `${this.$t('userDropdown.editProfile')}`,
        onClick: () => {
          this.$router.push({ name: 'auth.myProfileView' })
        }
      }, {
        type: 'divider'
      }, {
        type: 'item',
        icon: DashmixIconName.DoorClosedSolid,
        label: `${this.$t('userDropdown.logOut')}`,
        onClick: () => {
          this.logOut()
        },
        theme: 'danger'
      }
    ]
  }

  public get user (): UserModel {
    return this.authService.user as UserModel
  }

  public logOut (): void {
    this.authRepository.logout().then(() => {
      this.authService.deleteToken()
      this.$router.push({ name: 'auth.login' })
    }).catch(error => console.warn(error))
  }
}

export default UserMenu
