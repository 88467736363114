




























import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { DashmixIconName, DashmixTheme } from '@d24/modules'
import { Fragment } from 'vue-fragment'

import { Inject } from '@/shared/plugins/inversify'
import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { SubscriptionData } from '../contracts/models'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({
  name: 'SubscriptionTableRow',
  components: { Fragment }
})
export class SubscriptionTableRow extends Mixins<IUserAware>(UserAware) {
  @Prop({ type: Object, required: true })
  public data!: SubscriptionData

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public readonly icons = DashmixIconName
  public readonly themes = DashmixTheme

  public getStatusClass (): string {
    return this.data.isActive ? 'bg-success' : 'bg-warning'
  }

  public get fullName (): string {
    if (!this.data.user) {
      return ''
    }
    return `${this.data.user.firstName} ${this.data.user.lastName}`
  }
}

export default SubscriptionTableRow
