// Copyright © 2021 Move Closer

import { MappingConfig } from '@movecloser/front-core'

export const promotionAdapterMap: MappingConfig = {
  conditions: 'conditions',
  config: 'config',
  coupon: 'coupon',
  couponType: 'coupon_type',
  endAt: 'end_at',
  id: 'id',
  isActive: 'is_active',
  name: 'name',
  productTarget: 'product_target',
  productType: 'product_type',
  startAt: 'start_at',
  type: 'type'
}
