








































































































































import { BreadcrumbsProps, DashmixIconName, DashmixSelectItem, DateTimePickerType, Related, RelatedType, SizeMap } from '@d24/modules'
import { Component, Vue } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import { FormDateTimePicker, FormInput, FormSelect } from '@component/form'
import { Identifier } from '@/shared/contracts/data'
import { IRelatedService, RelatedServiceType } from '@service/related'
import { Loader } from '@component/Loader'
import { EditModeLayout } from '@component/EditModeLayout'
import { ModelListHeader } from '@component/ModelListHeader/ModelListHeader'
import { Property } from '@component/Property'

import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'

import { Promotion } from '../models/promotion'
import { IPromotion, IPromotionRepository, PromotionCouponType, PromotionData, PromotionModel, PromotionProductType, PromotionRepositoryType, PromotionType } from '../contracts'
import { Modals } from '@/config/modals'
import { AnyObject, IModal, ModalType } from '@movecloser/front-core'
import { EditPromotionIntention } from '@module/settings/intentions/EditPromotionIntention'
import { PromotionCouponsForm } from '@module/settings/components/PromotionCouponsForm.vue'
import { PromotionConfigForm } from '@module/settings/components/PromotionConfigForm.vue'
import { PromotionConditionsForm } from '@module/settings/components/PromotionConditionsForm.vue'

@Component<PromotionEdit>({
  name: 'PromotionEdit',
  components: {
    PromotionConditionsForm,
    PromotionConfigForm,
    PromotionCouponsForm,
    FormDateTimePicker,
    FormSelect,
    EditModeLayout,
    FormInput,
    Loader,
    ModelListHeader,
    Property
  },
  async mounted () {
    if (this.isEdit) {
      this.isLoading = true

      try {
        const data = await this.promotionRepository.get(this.id)
        // if (data.conditions.length === 0) {
        //   data.conditions = {}
        // }
        await this.updatePromotion(data, this.id)
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    }
  }
})
export class PromotionEdit extends Vue {
  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(PromotionRepositoryType)
  private promotionRepository!: IPromotionRepository

  @Inject(RelatedServiceType)
  public readonly relatedService!: IRelatedService

  public readonly form = 'editPromotion'
  public readonly Icons = DashmixIconName
  public readonly DateTimePickerType = DateTimePickerType
  public readonly PromotionType = PromotionType

  public articles: { id: Identifier; name: string }[] = []
  public promotionConfig: AnyObject = {}
  public subscriptionPeriod: number | null = null
  public isLoading: boolean = false
  public isSaving: boolean = false
  public navName: string = ''
  public payload: Omit<PromotionData, 'id'> = {
    conditions: {},
    config: {},
    couponType: PromotionCouponType.OneTime,
    coupon: '',
    endAt: '',
    productTarget: undefined,
    productType: PromotionProductType.Article,
    startAt: '',
    type: PromotionType.Amount,
    isActive: true,
    name: ''
  }

  private id: Identifier = Number(this.$route.params.id)
  private isEdit: boolean = !!this.id
  private promotion: PromotionModel | null = null

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('settings.promotions.title')}`,
          target: { name: 'settings.promotions.list' }
        },
        {
          label: this.pageTitle,
          target: { name: 'settings.promotions.edit' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get isArticle (): boolean {
    return this.payload.productType === PromotionProductType.Article
  }

  public get isSubscription (): boolean {
    return this.payload.productType === PromotionProductType.SubscriptionMonthly ||
        this.payload.productType === PromotionProductType.SubscriptionAnnual ||
        this.payload.productType === PromotionProductType.Subscription
  }

  public get isCouponSingle (): boolean {
    return this.payload.couponType === PromotionCouponType.Single
  }

  public get hasArticles (): boolean {
    return !!this.articles.length
  }

  public get pageTitle (): string {
    return this.isEdit ? `${this.$t('settings.promotions.editTitle', { name: this.navName })}` : `${this.$t('settings.promotions.createTitle')}`
  }

  public get typeOptions (): DashmixSelectItem[] {
    return [...new Set(Object.values(PromotionType))].map(value => {
      return {
        label: this.$t('settings.promotions.form.type.options.' + value).toString(),
        value: value
      }
    })
  }

  public get productTypeOptions (): DashmixSelectItem[] {
    return [...new Set(Object.values(PromotionProductType))].map(value => {
      return {
        label: this.$t('settings.promotions.form.productType.options.' + value).toString(),
        value: value
      }
    })
  }

  public get couponTypeOptions (): DashmixSelectItem[] {
    return [...new Set(Object.values(PromotionCouponType))].map(value => {
      return {
        label: this.$t('settings.promotions.form.couponType.options.' + value).toString(),
        value: value
      }
    })
  }

  public disabledBeforeToday (date: Date) {
    const today = new Date()
    today.setDate(today.getDate() - 1)

    return date < today
  }

  public disabledBeforeStart (date: Date) {
    if (!this.payload.startAt) {
      return this.disabledBeforeToday(date)
    }

    const startDate = new Date(this.payload.startAt)

    return date < startDate
  }

  public openArticlePicker () {
    const selected = this.articles.length > 0 ? this.articles.map(s => {
      return {
        value: s.id,
        type: RelatedType.Content
      }
    }) : []

    this.modalConnector.open(Modals.PickArticle, {
      config: {},
      multiple: true,
      selected: selected,
      onClose: () => this.modalConnector.close(),
      onSelection: this.setArticles
    }, { size: SizeMap.Large })
  }

  protected setArticles (selected: Related<RelatedType.Content, Identifier>[] | Related<RelatedType.Content, Identifier>) {
    let toLoad: Identifier[]

    if (Array.isArray(selected)) {
      toLoad = selected.map(set => set.value)
    } else {
      toLoad = [selected.value]
    }

    this.describe(toLoad).then(() => {
      this.payload = {
        ...this.payload,
        productTarget: toLoad
      }
    })
  }

  protected async describe (lists: Identifier[]): Promise<void> {
    this.articles = []

    for (const s of lists) {
      try {
        const article: AnyObject = await this.relatedService.describe({
          type: RelatedType.Content,
          value: s
        })

        this.articles.push({
          id: s,
          name: article.title
        })
      } catch (e) {
        console.warn(e)
      }
    }
  }

  public async submit () {
    this.isSaving = true

    const payload = this.buildPayload()
    const requestData = (new EditPromotionIntention(payload)).toRequest()

    try {
      const data = this.isEdit ? await this.promotionRepository.update(this.id, requestData) : await this.promotionRepository.create(requestData)
      if (!this.isEdit && data.id) {
        this.id = data.id

        await this.$router.push({
          name: 'settings.promotions.edit',
          params: { id: `${data.id}` }
        })
      }

      await this.updatePromotion(payload)
    } catch (error) {
      console.info(error)
    } finally {
      this.isSaving = false
    }
  }

  public async updatePromotion (payload: Omit<PromotionData, 'id'>, id?: Identifier) {
    this.promotion = Promotion.hydrate<PromotionData, IPromotion>({
      ...this.promotion,
      ...payload,
      id: id ?? this.id
    })
    this.isEdit = !!this.id

    this.parsePayload(this.promotion)

    if (this.payload.productTarget) {
      await this.describe(this.payload.productTarget)
    }
  }

  public onCouponTypeUpdate (): void {
    this.payload.coupon = ''
  }

  protected buildPayload (): Omit<PromotionData, 'id'> {
    return {
      ...this.payload,
      config: {
        ...(this.subscriptionPeriod ? { periods: this.subscriptionPeriod } : {}),
        [this.payload.type]: this.promotionConfig
      }
    }
  }

  protected parsePayload (promotion: PromotionModel): void {
    this.payload = promotion.toObject()
    this.navName = promotion.name
    this.promotionConfig = promotion.type && promotion.config ? { ...promotion.config[promotion.type] } : {}
    this.subscriptionPeriod = promotion.config ? promotion.config.periods : null
  }
}

export default PromotionEdit
