// Copyright © 2021 Move Closer

/**
 * This config allows to specify Toastr service options.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const toasts: ToastrOptions = {
  positionClass: 'toast-bottom-right',
  preventDuplicates: true,
  progressBar: true
}
