














import { Component } from 'vue-property-decorator'
import { Identifier } from '@d24/modules'

import { Inject } from '@/shared/plugins/inversify'

import { DictionaryRepositoryType, IDictionaryRepository } from '@module/root/contracts/repositories'
import { Hint } from '@component/Typeahead/Typeahead.contracts'

import { Badge } from '../../contracts'

import { AbstractAddon } from './AbstractAddon'
import { AddonErrors } from './AddonErrors.vue'
import { TagSelector } from './TagSelector.vue'

/**
 * @author  Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'TagsAddon',
  components: { AddonErrors, TagSelector }
})
export class TagsAddon extends AbstractAddon {
  @Inject(DictionaryRepositoryType)
  protected dictRepository!: IDictionaryRepository

  public value: Hint[] = []

  protected getVariantProperty (): void {
    const tagsFromModel = this.variant.getProperty<Badge[]>('tags')

    if (!tagsFromModel) {
      this.value = []
      return
    }

    this.value = tagsFromModel.map(t => {
      return {
        value: t.id,
        label: t.name
      }
    })
  }

  protected onTagSelected (value: Hint[]): void {
    this.variant.setProperty<Badge[]>('tags', value.map(v => {
      if (v.value) {
        return { id: v.value as Identifier, name: v.label }
      } else {
        return { name: v.label }
      }
    }))

    this.onChange(this.variant)
  }
}

export default TagsAddon
