// Copyright © 2021 Move Closer

import { defineComponent, SetupContext } from '@vue/composition-api'

import { navigationItemProps, useNavigationItem } from './NavigationItem.hooks'
import { NavigationItemProps } from '@component/Navigation'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const NavigationItem = defineComponent({
  name: 'NavigationItem',
  props: navigationItemProps,
  setup (props: NavigationItemProps, ctx: SetupContext) {
    return useNavigationItem(props, ctx)
  },
  template: `
    <li class="nav-main-item  custom-sidebar" :class="{'active': isSelected, 'open': allOpen || isOpened }" @click="$emit('click')">
      <template v-if="item.route">
        <D24Link class="nav-main-link" :class="{'nav-main-link-submenu': item.children}" :target="item.route">
          <i v-if="item.icon" class="nav-main-link-icon" :class="item.icon"></i>
          <span class="nav-main-link-name">{{ item.label }}</span>
        </D24Link>
      </template>

      <template v-else>
        <div class="nav-main-link" :class="{'nav-main-link-submenu': item.children}">
          <i v-if="item.icon" class="nav-main-link-icon" :class="item.icon"></i>
          <span class="nav-main-link-name">{{ item.label }}</span>
        </div>
      </template>

      <ul v-if="item.children" class="nav-main-submenu">
        <NavigationItem v-for="(childItem, childIndex) in item.children" :key="'nav-child-item-'+childIndex"
                        :item="childItem" />
      </ul>
    </li>
  `
})
