// Copyright © 2021 Move Closer

import { Model, ModelPayload } from '@movecloser/front-core'

import { Author } from '@module/content/models/author'
import { Identifier } from '@/shared/contracts/data'

import { ILayout, LayoutData } from '../contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export class Layout extends Model<LayoutData> implements ILayout {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'containers',
      'createdAt',
      'editor',
      'id',
      'isActive',
      'name',
      'slots',
      'updatedAt'
    ]
  }

  public displayName (): string {
    return this._data.name
  }

  public identifier (): Identifier {
    return this._data.id
  }

  protected relatesToEditor (value: ModelPayload) {
    return this.hasOne(Author, value)
  }
}
