
































import { BreadcrumbsProps, DashmixTheme, TableHead, TableRowAction, TableRowElement } from '@d24/modules'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import { DropdownActions } from '@/shared/contracts/content'
import { HasIdentifier, Identifier } from '@/shared/contracts/data'
import { HeaderInterface } from '@component/InteractiveTable/InteractiveTable.contracts'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'
import { ModelListHeader } from '@component/ModelListHeader/ModelListHeader'
import { Query } from '@/shared/contracts/query'

import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'

import { IPromotionRepository, PromotionData, PromotionRepositoryType } from '../contracts'
import { PromotionsTableRow } from '../components/PromotionsTableRow.vue'
import { promotionsRowActionsFactory, PromotionsActions, promotionsTableHead } from '../maps/promotions'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({
  name: 'PromotionsList',
  components: {
    InteractiveTable,
    ModelListHeader
  }
})
export class PromotionsList extends Mixins<IUserAware>(UserAware) {
  @Inject(PromotionRepositoryType)
  private promotionsRepository!: IPromotionRepository

  public isLoading = false
  public itemsTotal: number = 0
  public rowComponent = PromotionsTableRow
  public tableData: TableRowElement[] = []
  public tableHead: TableHead = promotionsTableHead
  public totalPages: number = 0

  public actions: DropdownActions = {
    [PromotionsActions.Edit]: {
      callback: (data: unknown) => {
        this.$router.push({ name: 'settings.promotions.edit', params: { id: (data as PromotionData).id.toString() } })
      }
    },
    [PromotionsActions.Delete]: {
      callback: (data: unknown) => {
        return this.onDelete((data as HasIdentifier).id)
      },
      confirmation: {
        header: this.$t('settings.promotions.delete.confirmation.header').toString(),
        contentText: this.$t('actions.delete.contentText').toString(),
        theme: DashmixTheme.Danger,
        buttonLabel: 'atoms.delete'
      }
    }
  }

  public header: HeaderInterface = {
    title: this.$t('settings.promotions.title').toString(),
    buttonLabel: this.$t('settings.promotions.add').toString(),
    linkTarget: { name: 'settings.promotions.create' }
  }

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('settings.promotions.title')}`,
          target: { name: 'settings.promotions.list' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  protected get queryParams (): Query {
    return this.$route.query
  }

  public get rowActions (): TableRowAction[] {
    return promotionsRowActionsFactory(this.domain, this.user)
  }

  mounted () {
    this.loadList(this.queryParams)
  }

  @Watch('queryParams', { deep: true })
  protected onPageChange (query: Query): void {
    this.loadList(query)
  }

  protected loadList (query?: Query): void {
    this.isLoading = true
    this.promotionsRepository.list(query).then(collection => {
      this.isLoading = false

      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: true,
          selected: false,
          data: model
        }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.itemsTotal = collection.meta.total
    }).catch(error => {
      console.log(error)
      this.isLoading = false
    })
  }

  protected onDelete (id: Identifier) {
    return this.promotionsRepository.delete(id).then(() => {
      this.tableData = this.tableData.filter(rowData => rowData.id !== id.toString())
    })
  }
}

export default PromotionsList
