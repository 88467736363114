






















import { Component, Prop } from 'vue-property-decorator'
import { DashmixIconName, Picker, PickerSelectionCallback, RequestAccessContent } from '@d24/modules'

import { AbstractModal } from '@component/Modal'
import { Inject } from '@plugin/inversify'
import { IRelatedService, RelatedServiceType } from '@service/related'

import { AddFormModalPayload } from '../contracts'
import { RequestAccessModuleForms } from '../shared/RequestAccess'

@Component<AddFormModal>({
  name: 'AddFormModal',
  components: { RequestAccessModuleForms },

  created () {
    if (this.payload.initialData) {
      this.content = this.payload.initialData
    }
  },
  mounted () {
    (document.querySelector('.modal-content') as HTMLDivElement).style.overflow = 'visible'
  }
})
class AddFormModal extends AbstractModal {
  @Prop({ type: Object, required: true })
  protected payload!: AddFormModalPayload

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  public content: RequestAccessContent = RequestAccessModuleForms.getInitialContent()
  protected readonly DashmixIconName = DashmixIconName

  public close (): void {
    if (typeof this.payload.onClose === 'function') {
      this.payload.onClose()
    }

    this.$emit('close')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public pickRelated (picker: Picker, pick: PickerSelectionCallback): void {
    //
  }

  public submit (): void {
    /**
     * sanitize object values
     */
    Object.entries(this.content).map(([key, value]) => {
      if (value && typeof value === 'string') {
        // FIXME: types
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        value = this.$sanitize(value)
        this.content[key] = value
      }
    })

    this.payload.onConfirm(this.content)
    this.close()
  }
}

export default AddFormModal
