// Copyright © 2021 Move Closer

import { Container } from '@movecloser/front-core'
import { AudioType, DocumentType, ImageType, VideoType } from '@d24/modules'

import { Query } from '@/shared/contracts/query'

import {
  CommentStatus,
  ContentRepositoryType,
  ContentStatus,
  ContentType,
  IContentRepository,
  INodesRepository,
  NodesRepositoryType,
  SetStrategy
} from '@module/content/contracts'
import { DictionaryRepositoryType, IDictionaryRepository } from '@module/root/contracts/repositories'
import {
  LogType,
  SubscriptionsPeriods,
  SubscriptionsStatuses,
  SubscriptionsTypes
} from '@module/users/contracts/models'
import { FormStatus, FormType } from '@module/forms/contracts'

export async function loadAuthors (container: Container, query?: Query) {
  const repo: IDictionaryRepository = container.get(DictionaryRepositoryType)

  let q = query
  if (!q) {
    q = {}
  }

  try {
    const authors = await repo.loadAuthorsDictionary({ perPage: '10', ...q })

    return [...authors].map(author => {
      return {
        label: author.fullName,
        value: author.id
      }
    })
  } catch (e) {
    console.debug(e)
    return []
  }
}

export async function loadContents (container: Container, query?: Query) {
  const repo: IContentRepository = container.get(ContentRepositoryType)

  let q = query
  if (!q) {
    q = {}
  }

  try {
    const contents = await repo.loadCollection(ContentType.Article, { perPage: '10', ...q })

    return [...contents].map(content => {
      return {
        label: content.title,
        value: String(content.id)
      }
    })
  } catch (e) {
    console.debug(e)
    return []
  }
}

export async function loadCategories (container: Container, query?: Query) {
  const repo: INodesRepository = container.get(NodesRepositoryType)

  let q = query
  if (!q) {
    q = {}
  }

  try {
    const nodes = await repo.loadTree({
      'flat-tree': '',
      perPage: '1000',
      ...q
    })

    return [...nodes].map(node => {
      return {
        label: node.title || '',
        value: node.id
      }
    })
  } catch (e) {
    console.debug(e)
    return []
  }
}

export async function loadEditors (container: Container, query?: Query) {
  const repo: IDictionaryRepository = container.get(DictionaryRepositoryType)

  let q = query
  if (!q) {
    q = {}
  }

  try {
    const editors = await repo.loadUsersDictionary({
      perPage: '1000',
      isEditor: '1',
      ...q
    })

    return [...editors].map(editor => {
      return {
        label: editor.fullName,
        value: editor.id
      }
    })
  } catch (e) {
    console.debug(e)
    return []
  }
}

export async function loadLabelsDict (container: Container) {
  const repo: IDictionaryRepository = container.get(DictionaryRepositoryType)

  try {
    const labels = await repo.loadLabelsDictionary()

    return [...labels].map(label => {
      return {
        label: label.name,
        value: label.id || label.slug
      }
    })
  } catch (e) {
    console.debug(e)
    return []
  }
}

export function loadLogsTypesDict () {
  return Object.values(LogType).map(type => {
    return {
      label: `${type}`,
      value: type
    }
  })
}

export async function loadRoles (container: Container, query?: Query) {
  const repo: IDictionaryRepository = container.get(DictionaryRepositoryType)

  let q = query
  if (!q) {
    q = {}
  }

  try {
    const roles = await repo.loadRolesDictionary({ perPage: '1000', ...q })

    return [...roles].map(role => {
      return {
        label: role.name,
        value: role.id
      }
    })
  } catch (e) {
    console.debug(e)
    return []
  }
}

export async function loadTags (container: Container, query?: Query) {
  const repo: IDictionaryRepository = container.get(DictionaryRepositoryType)

  let q = query
  if (!q) {
    q = {}
  }

  try {
    const tags = await repo.loadTagsDictionary({ perPage: '1000', ...q })

    return [...tags].map(tag => {
      return {
        label: tag.name,
        value: tag.id
      }
    })
  } catch (e) {
    console.debug(e)
    return []
  }
}

export async function loadUsers (container: Container, query?: Query) {
  const repo: IDictionaryRepository = container.get(DictionaryRepositoryType)

  let q = query || {}
  if (!q) {
    q = {}
  }

  try {
    const users = await repo.loadUsersDictionary({ perPage: '1000', ...q })

    return [...users].map(user => {
      return {
        label: user.fullName,
        value: user.id
      }
    })
  } catch (e) {
    console.debug(e)
    return []
  }
}

export function loadFileTypesDict () {
  const fileTypes = []

  // Image types
  fileTypes.push(...Object.values(ImageType).map(type => {
    return {
      label: type,
      value: type
    }
  }))

  fileTypes.push(...Object.values(AudioType).map(type => {
    return {
      label: type,
      value: type
    }
  }))

  fileTypes.push(...Object.values(DocumentType).map(type => {
    return {
      label: type,
      value: type
    }
  }))

  fileTypes.push(...Object.values(VideoType).map(type => {
    return {
      label: type,
      value: type
    }
  }))

  return fileTypes
}

export function loadStatusDict () {
  return Object.values(ContentStatus).map(status => {
    return {
      label: `status.${status}`,
      value: status
    }
  })
}

export function loadCommentStatusDict () {
  return Object.values(CommentStatus).map(status => {
    return {
      label: `status.${status}`,
      value: status
    }
  })
}

export function loadTypesDict () {
  return Object.values(ContentType).map(type => {
    return {
      label: `type.${type}`,
      value: type
    }
  })
}

export function loadStrategyDict () {
  return Object.values(SetStrategy).map(strategy => {
    return {
      label: `strategy.${strategy}`,
      value: strategy
    }
  })
}

export function loadFormTypesDict () {
  return Object.values(FormType).map(type => {
    return {
      label: `content.forms.list.type.${type}`,
      value: type
    }
  })
}

export function loadFormStatusDict () {
  return Object.values(FormStatus).map(status => {
    return {
      label: `content.forms.list.status.${status}`,
      value: status
    }
  })
}

export function loadSubType () {
  return Object.values(SubscriptionsTypes).map(status => {
    return {
      label: `subscription.type.${status}`,
      value: status
    }
  })
}

export function loadSubStatus () {
  return Object.values(SubscriptionsStatuses).map(status => {
    return {
      label: `${status}`,
      value: status
    }
  })
}

export function loadSubPeriod () {
  return Object.values(SubscriptionsPeriods).map(status => {
    return {
      label: `subscription.period.${status}`,
      value: status
    }
  })
}
