












import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author  Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'AddonLabel'
})
export class AddonLabel extends Vue {
  @Prop({ type: String, required: true })
  public readonly label!: string

  @Prop({ type: Boolean, required: true })
  public readonly hasErrors!: string
}

export default AddonLabel
