// Copyright © 2021 Move Closer

import { DashmixTheme, DashmixIconName } from '@d24/modules'
import { defineComponent, SetupContext } from '@vue/composition-api'

import { FiltersProps } from './Filters.contracts'
import { FilterBadge } from './partials/FilterBadge'
import { useFilters, useFiltersProps } from './Filters.hook'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */

export const Filters = defineComponent({
  name: 'Filters',
  components: { FilterBadge },
  props: useFiltersProps,
  emits: ['setQuery'],

  setup (props: FiltersProps, ctx: SetupContext) {
    return {
      ...useFilters(props, ctx),
      icons: DashmixIconName,
      buttonTheme: DashmixTheme
    }
  },

  template: `
    <div>
      <template v-if="shouldRender">
        <div class="Filters d-flex align-items-center">
          <div class="Filters__trigger btn-group">
            <D24Dropdown
              :triggerTheme="triggerTheme ? triggerTheme : buttonTheme.Dark" :triggerVariant="triggerVariant" :icon = "icons.FilterSolid"
              :label = "label ? label : (!canRemoveAll ? $t('filters.add') : '')" :items="filtersItems()" >
            </D24Dropdown>

            <template v-if="canRemoveAll">
              <D24Button :theme="buttonTheme.Danger"
                         variant="icon"
                         :icon = "icons.TrashSolid"
                         @click="clearFilters" />
            </template>
          </div>

          <div class="Filters__badges pl-2 d-flex">
            <template v-for="(filter, name) in filters">
              <FilterBadge
                :isNew="isFromQuery(name)"
                :key="name"
                :loading="loading"
                :container="container"
                :config="config.groups[name]"
                :field="name"
                :value="filter"
                :dictionaries="dictionaries"
                @onRemove="onRemove"
                @onEdit="onEdit"
                @loadDict="(q) => loadDict(name, q)"
              />
            </template>
          </div>

        </div>
      </template>
    </div>
  `
})
