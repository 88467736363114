// Copyright © 2022 Move Closer

import {
  ICollection,
  Injectable,
  IResponse,
  MappingConfig,
  Repository,
  ResourceActionFailed,
  ResponseType
} from '@movecloser/front-core'

import { resolveFromStatus } from '@/shared/exceptions/connector-errors'
import { Query } from '@/shared/contracts/query'

import { formAdapterMap } from '@module/forms/models/form.adapter'
import { FormData, FormModel } from '../contracts/models'
import { IFormsRepository } from '../contracts/repositories'
import { Form } from '../models/form'
import { EditFormIntention } from '@module/forms/intensions/EditFormIntention'
import { CreateFormIntention } from '@module/forms/intensions/CreateFormIntention'

@Injectable()
export class FormsRepository extends Repository<FormData, Form> implements IFormsRepository {
  protected map: MappingConfig = formAdapterMap
  protected useAdapter = true

  public async create (intention: CreateFormIntention): Promise<void> {
    const response = await this.connector.call(
      'forms',
      'create',
      {},
      intention.toRequest()
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return response.data?.data
  }

  public async delete (id: FormData['id']): Promise<void> {
    const response: IResponse = await this.connector.call(
      'forms',
      'delete',
      { id: id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async download (id: FormData['id']): Promise<{ filename: string; file: Blob }> {
    const response: IResponse = await this.connector.call(
      'forms',
      'download',
      { id },
      {},
      {},
      ResponseType.Blob
    )
    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
    return {
      filename: response.headers['x-content-filename'],
      file: response.data as Blob
    }
  }

  public async load (id: FormData['id']): Promise<FormModel> {
    const response: IResponse = await this.connector.call(
      'forms',
      'item',
      { id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeModel(response.data.data, Form)
  }

  public async loadCollection (query?: Query): Promise<ICollection<FormModel>> {
    const response: IResponse = await this.connector.call('forms', 'list', {}, { ...query })
    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        response.data.data
      )
    }

    return this.composeCollection(
      response.data.data,
      Form,
      response.data.meta
    )
  }

  public async update (id: FormData['id'], intention: EditFormIntention): Promise<void> {
    const response: IResponse = await this.connector.call(
      'forms',
      'update',
      { id: id },
      intention.toRequest()
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }
}
