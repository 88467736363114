// Copyright © 2021 Move Closer

import { AbstractIntention } from '@movecloser/front-core'
import { EmbedContent, Related, RelatedType } from '@d24/modules'

export interface CreateFeedEntryPayload {
  content: string
  image?: Related<RelatedType.File>
  gallery?: Related<RelatedType.Gallery>
  embed?: EmbedContent
}

export class CreateFeedEntryIntention extends AbstractIntention<CreateFeedEntryPayload> {
  protected map = {
    content: 'content',
    image: 'image',
    gallery: 'gallery',
    embed: 'embed'
  }
}
