// Copyright © 2021 Move Closer

import { Component, Vue } from 'vue-property-decorator'
import { escapeKey, IEscapeEvent } from '@/shared/extensions/events'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component<AbstractModal>({
  destroyed () {
    if (this.useEscape) {
      window.removeEventListener('keyup', this.toggleEscape)
    }
  },
  mounted () {
    if (this.useEscape) {
      window.addEventListener('keyup', this.toggleEscape)
    }
  }
})
export class AbstractModal extends Vue implements IEscapeEvent {
  protected toggleEscape = escapeKey.bind(this)
  protected useEscape: boolean = true

  public close (): void {
    this.$emit('close')
  }

  public escapePressed (): void {
    this.close()
  }
}

export default AbstractModal
