









import { Component } from 'vue-property-decorator'

import { Hint, Typeahead } from '@component/Typeahead'

import { AbstractSelector } from './AbstractSelector'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'AuthorSelector',
  components: { Typeahead }
})
export class AuthorSelector extends AbstractSelector {
  protected applySelection (selected: Hint[]) {
    this.onSelection(selected)
  }

  protected loadDictionary (searchParams?: string) {
    this.loading = true

    this.dictRepository.loadAuthorsDictionary({ q: searchParams || '' }).then(dict => {
      this.dictionary = [...dict].map(model => {
        return {
          value: model.id,
          label: model.fullName
        }
      })
    }).finally(() => {
      this.loading = false
    })
  }
}

export default AuthorSelector
