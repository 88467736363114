// Copyright © 2021 Move Closer

import { AbstractIntention } from '@movecloser/front-core'

import { FileCreatePayload } from '../contracts'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export class CreateFileIntention extends AbstractIntention<FileCreatePayload> {
  protected map = {
    directory: 'directory',
    title: 'title',
    author: 'author',
    alt: 'alt',
    caption: 'caption'
  }
}
