





























import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName, DashmixTheme } from '@d24/modules'
import { DateTimeType, IDateTime } from '@movecloser/front-core'

import { Inject } from '@plugin/inversify'

import { CommentModel } from '../contracts'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'Comment'
})
export class Comment extends Vue {
  @Prop({ type: Object, required: true })
  public data!: CommentModel

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public isAccepted: boolean = false
  public isRejected: boolean = false

  public DashmixIconName = DashmixIconName
  public DashmixTheme = DashmixTheme

  public get contentText (): string {
    return this.data.content
  }

  public onAccept (): void {
    this.isAccepted = true
    setTimeout(() => this.$emit('onAccept', this.data.id), 500)
  }

  public onReject (): void {
    this.isRejected = true
    setTimeout(() => this.$emit('onReject', this.data.id), 500)
  }
}

export default Comment
