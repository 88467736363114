



























import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { DashmixTheme } from '@d24/modules'
import { Fragment } from 'vue-fragment'

import { Inject } from '@plugin/inversify'
import { defaultDateFormat } from '@module/content/helpers/formatting'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'
import { PeriodData } from '../contracts/models'

@Component({
  name: 'PeriodsTableRow',
  components: { Fragment }
})
export class PeriodsTableRow extends Mixins<IUserAware>(UserAware) {
  @Prop({ type: Object, required: true })
  public data!: PeriodData

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public badgeTheme = DashmixTheme
  public dateFormat = defaultDateFormat
}

export default PeriodsTableRow
