// Copyright © 2021 Move Closer

import { ExtendedMDE } from 'simplemde'
import { DataSetVersion, ModuleDriver, SizeMap } from '@d24/modules'

import { Modals } from '@/config/modals'

import { Shortcut } from '../MarkdownEditor.contracts'

import { findTokenAtCurrentLine, replaceTokenAtCurrentLine } from '@component/MarkdownEditor/helpers/line-parser'
import { AnyObject } from '@movecloser/front-core'

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
export const datasetShortcut: Shortcut = {
  action: (editor: ExtendedMDE) => {
    const foundToken: AnyObject | null = findTokenAtCurrentLine(editor, ModuleDriver.DataSet)

    editor.options.modalConnector.open(Modals.AddDataset, {
      initialData: foundToken?.dataSet,
      onClose: () => editor.options.modalConnector.close(Modals.AddDataset),
      onConfirm: (data: {name: string; data: []}) => {
        const lineNumber: number = editor.codemirror.getCursor().line
        const lineLength: number = editor.codemirror.getLine(
          editor.codemirror.getCursor().line
        ).length

        editor.codemirror.replaceRange(
          '',
          { line: lineNumber, ch: 0 },
          { line: lineNumber, ch: lineLength }
        )

        replaceTokenAtCurrentLine(
          editor,
          ModuleDriver.DataSet,
          { dataSet: data },
          DataSetVersion.Table
        )
      }
    }, { size: SizeMap.XLarge })
  },
  className: 'fas fa-table',
  name: 'data_set',
  title: 'Wstaw tabelę'
}
