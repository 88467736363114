// Copyright © 2021 Move Closer

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * Conjugates word by number
 */
export function conjugateWordByNumber (singular: string, plural: string, genitive: string, number: number): string {
  return number === 1
    ? singular
    : (
      (number > 1 && number < 5) ||
      (number % 10 > 1 && number % 10 < 5 && number > 20)
        ? plural
        : genitive
    )
}
