// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'

import { Identifiable, Identifier } from '@/shared/contracts/data'

import {
  AuthorModel as VariantAuthorModel,
  AuthorData as VariantAuthorData,
  IAuthor as IVariantAuthor
} from '@module/content/contracts'
import { Author as VariantAuthor } from '@module/content/models/author'

import { AuthorData, IAuthor } from '../contracts/models'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export class Author extends Model<AuthorData> implements IAuthor, Identifiable {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'avatar',
      'deletedAt',
      'description',
      'email',
      'firstName',
      'fullName',
      'id',
      'isActive',
      'isEditor',
      'isLinkActive',
      'isPageActive',
      'lastName',
      'links',
      'nickname',
      'articlesCount',
      'slug',
      'sort',
      'type',
      'title',
      'updatedAt',
      'user',
      'position'
    ]
  }

  public displayName (): string {
    return this._data.fullName
  }

  public identifier (): Identifier {
    return this._data.id
  }

  public toOwner (): VariantAuthorModel {
    const ownerPayload = { ...this._data }

    if (ownerPayload.avatar && ownerPayload.avatar.url) {
      ownerPayload.avatar = ownerPayload.avatar.url
    }
    return VariantAuthor.hydrate<VariantAuthorData, IVariantAuthor>(ownerPayload)
  }
}
