// Copyright © 2021 Move Closer

import { Model, ModelPayload } from '@movecloser/front-core'

import { AcceptanceData, AcceptanceStatus, IAcceptance } from '../contracts'
import { Author } from './author'
import { Variant } from './variant'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class Acceptance extends Model<AcceptanceData> implements IAcceptance {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'asIs', 'acceptedAt', 'acceptedBy', 'author',
      'content', 'contentStatus', 'contentType', 'editor', 'id',
      'lockedAt', 'publishedAt', 'status', 'title', 'toBe', 'updatedAt'
    ]
  }

  public isAcceptable (): boolean {
    return [AcceptanceStatus.Pending, AcceptanceStatus.Rejected].includes(this._data.status)
  }

  public isRejectable (): boolean {
    return [AcceptanceStatus.Pending, AcceptanceStatus.Accepted].includes(this._data.status)
  }

  protected relatesToAcceptedBy (value: ModelPayload) {
    return this.hasOne(Author, value)
  }

  protected relatesToAsIs (value: ModelPayload) {
    return this.hasOne(Variant, value)
  }

  protected relatesToAuthor (value: ModelPayload) {
    return this.hasOne(Author, value)
  }

  protected relatesToEditor (value: ModelPayload) {
    return this.hasOne(Author, value)
  }

  protected relatesToToBe (value: ModelPayload) {
    return this.hasOne(Variant, value)
  }
}
