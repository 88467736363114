// Copyright © 2021 Move Closer

import {
  ICollection,
  Injectable, IResponse, MappingConfig,
  Repository, ResourceActionFailed
} from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'
import { Query } from '@/shared/contracts/query'
import { resolveFromStatus } from '@/shared/exceptions/connector-errors'

import { IUserRepository } from '../contracts/repositories'
import { UserData, IUser, UserModel, UsersInfoData } from '../contracts/models'
import { userAdapterMap } from '../models/user.adapter'
import { User } from '../models/user'
import { CreateUserIntention } from '../intentions/CreateUserIntention'
import { EditUserIntention } from '../intentions/EditUserIntention'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Injectable()
export class UserRepository extends Repository<UserData, IUser> implements IUserRepository {
  protected useAdapter = true
  protected map: MappingConfig = userAdapterMap

  public async create (intention: CreateUserIntention): Promise<void> {
    const response: IResponse = await this.connector.call(
      'user',
      'create',
      {},
      intention.toRequest()
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async delete (id: Identifier): Promise<void> {
    const response: IResponse = await this.connector.call(
      'user',
      'delete',
      { id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async edit (id: Identifier, intention: EditUserIntention): Promise<void> {
    const response: IResponse = await this.connector.call(
      'user',
      'edit',
      { id },
      intention.toRequest()
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async load (id: Identifier): Promise<UserModel> {
    const response: IResponse = await this.connector.call(
      'user',
      'get',
      { id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeModel(
      response.data.data,
      User
    )
  }

  public async loadCollection (query: Query): Promise<ICollection<UserModel>> {
    const response: IResponse = await this.connector.call(
      'user',
      'list',
      {},
      {
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      User,
      response.data.meta
    )
  }

  public async switchActive (id: Identifier, blocked: boolean, model: UserModel): Promise<UserModel> {
    const response: IResponse = await this.connector.call(
      'user',
      'edit',
      { id },
      { isBlocked: blocked }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    model.switchBlocked(!model.isBlocked)

    return this.composeModel(
      model.toObject(),
      User
    )
  }

  public async switchShadowban (id: Identifier, shadowbanned: boolean, model: UserModel): Promise<UserModel> {
    const response: IResponse = await this.connector.call(
      'user',
      'edit',
      { id },
      { isShadowbanned: shadowbanned }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    model.switchShadowbanned(!model.isShadowbanned)

    return this.composeModel(
      model.toObject(),
      User
    )
  }

  public async reset (id: Identifier): Promise<void> {
    const response: IResponse = await this.connector.call(
      'user',
      'reset',
      { id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async loadUsersInfo (): Promise<UsersInfoData> {
    const response = await this.connector.call(
      'user',
      'stats',
      {}
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
    return response.data.data
  }
}
