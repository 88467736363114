// Copyright © 2021 Move Closer

import { AnyModule } from '@d24/modules'
import { DeMarked } from '@/shared/helpers/demarked'

/**
 * Stores the HTML generated from the last `src` argument that has been
 * successfully processed with `DeMarked` (i.e. parsing didn't throw).
 */
let lastSuccessfulRender: string = ''

/**
 * Resolves the HTML for use inside the _Instant Preview_ feature of the _Vue SimpleMDE_.
 *
 * @param src - The MD string that is to be converted to HTML.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const previewRender = (src: string): string => {
  let modules: AnyModule[]
  try {
    modules = DeMarked.convertMDToObjects(src) as AnyModule[]
  } catch (error) {
    console.warn(`previewRender(): Failed to convert the MD string to modules array.\n${error}\nFalling back to the last successful render.`)
    return lastSuccessfulRender
  }

  let html: string
  try {
    html = DeMarked.convertObjectsToHTML(modules)
  } catch (error) {
    console.warn(`previewRender(): Failed to convert the modules array to HTML!\n${error}\nFalling back to the last successful render.`)
    return lastSuccessfulRender
  }

  const previewHTML: string = `
    <div class="preview-render container">
        <div class="row">
            <div class="preview-render__inner col">
                ${html}
            </div>
        </div>
    </div>
  `

  // Store the rendered HTML for future reference.
  lastSuccessfulRender = previewHTML

  return previewHTML
}
