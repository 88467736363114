// Copyright © 2021 Move Closer

import { AbstractIntention } from '@movecloser/front-core'
import { FeedStatus } from '@module/content/contracts'

export interface UpdateFeedPayload {
  title: string
  description?: string
  lock?: boolean
  lockForce?: boolean
  status?: FeedStatus
}

export class UpdateFeedIntention extends AbstractIntention<UpdateFeedPayload> {
  protected map = {
    title: 'title',
    description: 'description',
    lock: 'lock',
    lockForce: 'lock_force',
    status: 'status'
  }
}
