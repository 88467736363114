// Copyright © 2021 Move Closer
// Made using filepond/locale/pl-pl.js - added typing

import { FilePondOptions } from 'filepond'

export interface FilePondOptionsExtended extends FilePondOptions {
  imageValidateSizeLabelFormatError?: string
  imageValidateSizeLabelImageSizeTooSmall?: string
  imageValidateSizeLabelImageSizeTooBig?: string
  imageValidateSizeLabelExpectedMinSize?: string
  imageValidateSizeLabelExpectedMaxSize?: string
  imageValidateSizeLabelImageResolutionTooLow?: string
  imageValidateSizeLabelImageResolutionTooHigh?: string
  imageValidateSizeLabelExpectedMinResolution?: string
  imageValidateSizeLabelExpectedMaxResolution?: string
}

export const plTranslation: FilePondOptionsExtended = {
  labelIdle: 'Przeciągnij tutaj swój plik lub<br/><span class=\'filepond--label-action\'>przeglądaj zawartość komputera</span>',
  labelInvalidField: 'Nieprawidłowe pliki',
  labelFileWaitingForSize: 'Pobieranie rozmiaru',
  labelFileSizeNotAvailable: 'Nieznany rozmiar',
  labelFileLoading: 'Wczytywanie',
  labelFileLoadError: 'Błąd wczytywania',
  labelFileProcessing: 'Przesyłanie',
  labelFileProcessingComplete: 'Przesłano',
  labelFileProcessingAborted: 'Przerwano',
  labelFileProcessingError: 'Przesyłanie nie powiodło się',
  labelFileProcessingRevertError: 'Coś poszło nie tak',
  labelFileRemoveError: 'Nieudane usunięcie',
  labelTapToCancel: 'Anuluj',
  labelTapToRetry: 'Ponów',
  labelTapToUndo: 'Cofnij',
  labelButtonRemoveItem: 'Usuń',
  labelButtonAbortItemLoad: 'Przerwij',
  labelButtonRetryItemLoad: 'Ponów',
  labelButtonAbortItemProcessing: 'Anuluj',
  labelButtonUndoItemProcessing: 'Cofnij',
  labelButtonRetryItemProcessing: 'Ponów',
  labelButtonProcessItem: 'Prześlij',
  labelMaxFileSizeExceeded: 'Plik jest zbyt duży',
  labelMaxFileSize: 'Dopuszczalna wielkość pliku to {filesize}',
  labelMaxTotalFileSizeExceeded: 'Przekroczono łączny rozmiar plików',
  labelMaxTotalFileSize: 'Łączny rozmiar plików nie może przekroczyć {filesize}',
  labelFileTypeNotAllowed: 'Niedozwolony rodzaj pliku',
  fileValidateTypeLabelExpectedTypes: 'Oczekiwano {allButLastType} lub {lastType}',
  imageValidateSizeLabelFormatError: 'Nieobsługiwany format obrazu',
  imageValidateSizeLabelImageSizeTooSmall: 'Obraz jest zbyt mały',
  imageValidateSizeLabelImageSizeTooBig: 'Obraz jest zbyt duży',
  imageValidateSizeLabelExpectedMinSize: 'Minimalne wymiary obrazu to {minWidth}×{minHeight}',
  imageValidateSizeLabelExpectedMaxSize: 'Maksymalna wymiary obrazu to {maxWidth}×{maxHeight}',
  imageValidateSizeLabelImageResolutionTooLow: 'Rozdzielczość jest zbyt niska',
  imageValidateSizeLabelImageResolutionTooHigh: 'Rozdzielczość jest zbyt wysoka',
  imageValidateSizeLabelExpectedMinResolution: 'Minimalna rozdzielczość to {minResolution}',
  imageValidateSizeLabelExpectedMaxResolution: 'Maksymalna rozdzielczość to {maxResolution}'
}

export default plTranslation
