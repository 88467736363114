// Copyright © 2021 Move Closer

/**
 * Reads the `body.clientWidth` and stores its value in the CSS variable.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const readClientWidth = (): void => {
  const clientWidth: string = document.body.clientWidth + 'px'
  document.documentElement.style.setProperty('--client-width', clientWidth)
}
