// Copyright © 2021 Move Closer

import { LogModel } from '@module/users/contracts/models'
import { Model } from '@movecloser/front-core'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export class Log extends Model<LogModel> {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'browserFingerprint', 'commitedAt', 'id', 'message', 'remoteAddr', 'type', 'user'
    ]
  }
}
