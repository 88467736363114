// Copyright © 2021 Move Closer

import { AbstractIntention } from '@movecloser/front-core'

export interface ChangeItemsOrderIntentionPayload {
  id: number
  position: number
}

export class ChangeItemsOrderIntention extends AbstractIntention<ChangeItemsOrderIntentionPayload> {
  protected map = {
    id: 'id',
    position: 'position'
  }
}
