// Copyright © 2021 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'

export const resources: ResourcesRegistry = {
  navigations: {
    prefix: 'api/management',
    methods: {
      delete: {
        url: 'navigations/{navigationId}',
        params: ['navigationId'],
        method: Methods.Delete,
        auth: true
      },
      get: {
        url: 'navigations/{navigationId}',
        params: ['navigationId'],
        method: Methods.Get,
        auth: true
      },
      list: {
        url: 'navigations',
        method: Methods.Get,
        auth: true
      },
      create: {
        url: 'navigations',
        method: Methods.Post,
        auth: true,
        shorthand: 'editNavigation',
        meta: { toast: true }
      },
      update: {
        url: 'navigations/{navigationId}',
        params: ['navigationId'],
        method: Methods.Put,
        auth: true,
        shorthand: 'editNavigation',
        meta: { toast: true }
      }
    }
  },
  layouts: {
    prefix: 'api/management/layouts',
    methods: {
      delete: {
        url: '{layoutId}',
        params: ['layoutId'],
        method: Methods.Delete,
        auth: true
      },
      get: {
        url: '{layoutId}',
        params: ['layoutId'],
        method: Methods.Get,
        auth: true
      },
      list: {
        url: '',
        method: Methods.Get,
        auth: true
      },
      create: {
        url: '',
        method: Methods.Post,
        auth: true,
        shorthand: 'editLayout',
        meta: { toast: true }
      },
      update: {
        url: '{layoutId}',
        params: ['layoutId'],
        method: Methods.Put,
        auth: true,
        shorthand: 'editLayout',
        meta: { toast: true }
      }
    }
  },
  promotions: {
    prefix: 'api/management/promotions',
    methods: {
      delete: {
        url: '{promotionId}',
        params: ['promotionId'],
        method: Methods.Delete,
        auth: true
      },
      get: {
        url: '{promotionId}',
        params: ['promotionId'],
        method: Methods.Get,
        auth: true
      },
      codes: {
        url: '{promotionId}/codes',
        params: ['promotionId'],
        method: Methods.Get,
        auth: true
      },
      codesDownload: {
        url: '{promotionId}/codes-download',
        params: ['promotionId'],
        method: Methods.Get,
        auth: true
      },
      generate: {
        url: '{promotionId}/generate',
        params: ['promotionId'],
        method: Methods.Post,
        auth: true,
        shorthand: 'editPromotionCoupon'
      },
      list: {
        url: '',
        method: Methods.Get,
        auth: true
      },
      create: {
        url: '',
        method: Methods.Post,
        auth: true,
        shorthand: 'editPromotion',
        meta: { toast: true }
      },
      update: {
        url: '{promotionId}',
        params: ['promotionId'],
        method: Methods.Put,
        auth: true,
        shorthand: 'updatePromotion',
        meta: { toast: true }
      }
    }
  },
  settings: {
    prefix: 'api/management',
    methods: {
      get: {
        url: 'settings/{type}',
        params: ['type'],
        method: Methods.Get,
        auth: true
      },
      list: {
        url: 'settings/',
        method: Methods.Get,
        auth: true
      },
      update: {
        url: 'settings/{type}',
        params: ['type'],
        method: Methods.Post,
        auth: true
      }
    }
  },
  tags: {
    prefix: 'api/management',
    methods: {
      import: {
        url: 'tags/import',
        method: Methods.Post,
        auth: true,
        meta: { toast: true }
      },
      export: {
        url: 'tags/export',
        method: Methods.Post,
        auth: true,
        meta: { toast: true }
      },
      create: {
        url: 'tags',
        method: Methods.Post,
        auth: true,
        meta: { toast: true }
      },
      get: {
        url: 'tags/{id}',
        params: ['id'],
        method: Methods.Get,
        auth: true,
        meta: { toast: true }
      },
      delete: {
        url: 'tags/{id}',
        params: ['id'],
        method: Methods.Delete,
        auth: true,
        meta: { toast: true }
      },
      list: {
        url: '{type}',
        params: ['type'],
        method: Methods.Get,
        auth: true
      },
      update: {
        url: 'tags/{id}',
        params: ['id'],
        method: Methods.Put,
        auth: true,
        meta: { toast: true }
      }
    }
  }
}
