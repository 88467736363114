// Copyright © 2021 Move Closer

import { ICollection } from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'
import { Query } from '@/shared/contracts/query'

import {
  CouponModel,
  LayoutData,
  LayoutModel,
  NavigationData,
  NavigationModel,
  PromotionCouponConfig,
  PromotionData,
  PromotionModel,
  SettingData,
  TagData,
  TagModel,
  TagType
} from './models'

export interface INavigationRepository {
  create (payload: Omit<NavigationData, 'id'>): Promise<any>

  delete (navigationId: Identifier): Promise<void>

  get (navigationId: Identifier): Promise<NavigationModel>

  list (): Promise<ICollection<NavigationModel>>

  update (navigationId: Identifier, payload: Omit<NavigationData, 'id'>): Promise<void>
}

export interface ILayoutRepository {
  create (payload: Omit<LayoutData, 'id'>): Promise<any>

  delete (layoutId: Identifier): Promise<void>

  get (layoutId: Identifier): Promise<LayoutModel>

  list (query?: Query): Promise<ICollection<LayoutModel>>

  update (layoutId: Identifier, payload: Omit<LayoutData, 'id'>): Promise<void>
}

export interface IPromotionRepository {
  create (payload: Omit<PromotionData, 'id'>): Promise<any>

  delete (promotionId: Identifier): Promise<void>

  get (promotionId: Identifier): Promise<PromotionModel>

  list (query?: Query): Promise<ICollection<PromotionModel>>

  update (promotionId: Identifier, payload: Omit<PromotionData, 'id'>): Promise<void>
}

export interface ICouponRepository {
  generate (promotionId: Identifier, payload: PromotionCouponConfig): Promise<void>

  list (promotionId: Identifier, query?: Query): Promise<ICollection<CouponModel>>

  download (promotionId: Identifier): Promise<{ filename: string; file: Blob }>
}

export interface ISettingsRepository {
  loadCollection (query?: Query): Promise<ICollection<SettingData>>

  getSetting<T> (type: string): Promise<T>

  updateSetting<T> (type: string, payload: T): Promise<void>
}

export interface ITagRepository {
  create (newTag: Omit<TagData, 'id'>): Promise<TagModel>

  delete (id: TagData['id']): Promise<void>

  get (id: Identifier): Promise<TagModel>

  import (): Promise<void>

  export (): Promise<void>

  loadCollection (type: TagType, query: Query): Promise<ICollection<TagModel>>

  update (tagId: Identifier, tag: Omit<TagData, 'id'>): Promise<TagModel>
}

export const CouponRepositoryType = Symbol.for('ICouponRepository')

export const LayoutRepositoryType = Symbol.for('ILayoutRepository')

export const NavigationRepositoryType = Symbol.for('INavigationRepository')

export const PromotionRepositoryType = Symbol.for('IPromotionsRepository')

export const SettingRepositoryType = Symbol.for('ISettingsRepository')

export const TagRepositoryType = Symbol.for('ITagRepository')
