// Copyright © 2021 Move Closer

import SimpleMDE from 'simplemde'

import { HTMLToMarkdown } from './html-to-markdown'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export const onCodeMirrorPaste = (cm: SimpleMDE['codemirror'], event: ClipboardEvent): void => {
  if (event.clipboardData) {
    const paste = event.clipboardData?.getData('text/html')
    if (!paste) {
      return
    }
    cm.replaceSelection(HTMLToMarkdown(paste))
    event.preventDefault()
  }
}
