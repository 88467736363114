// Copyright © 2021 Move Closer

import {
  AuthorDriver,
  ContentDriver,
  DeduplicatedSetDriver,
  FileDriver,
  GalleryDriver,
  LabelDriver,
  RelatedType,
  RelatedTypeDriverRegistry,
  SetDriver,
  TagDriver,
  SmartAuthorDriver,
  SmartCategoryDriver,
  SmartGalleryDriver,
  SmartTagDriver
} from '@d24/modules'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const resolveDrivers: () => RelatedTypeDriverRegistry = () => ({
  [RelatedType.Author]: new AuthorDriver(),
  [RelatedType.ExtendedAuthor]: new AuthorDriver(),
  [RelatedType.Content]: new ContentDriver(),
  [RelatedType.DeduplicatedSet]: new DeduplicatedSetDriver(),
  [RelatedType.File]: new FileDriver(),
  [RelatedType.Label]: new LabelDriver(),
  [RelatedType.Gallery]: new GalleryDriver(),
  [RelatedType.Set]: new SetDriver(),
  [RelatedType.SmartAuthor]: new SmartAuthorDriver(),
  [RelatedType.SmartCategory]: new SmartCategoryDriver(),
  [RelatedType.SmartGallery]: new SmartGalleryDriver(),
  [RelatedType.SmartTag]: new SmartTagDriver(),
  [RelatedType.Tag]: new TagDriver()
})
