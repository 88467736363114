


















import { Component, Vue } from 'vue-property-decorator'

import { BreadcrumbsProps } from '@d24/modules'
import { Inject } from '@/shared/plugins/inversify'
import { EditModeLayout } from '@component/EditModeLayout'

import { initBreadcrumbs } from '@module/content/helpers'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'

import { Avatar, Roles, SimpleRole } from '../contracts/models'
import { IUserRepository, UserRepositoryType } from '../contracts/repositories'
import { CreateUserIntention } from '../intentions/CreateUserIntention'
import { UserForm } from '../components/UserForm.vue'
import { UserPayload } from '../contracts/data'
import { RolesSideBar } from '../components/RolesSideBar.vue'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'CreateUser',
  components: { UserForm, EditModeLayout, RolesSideBar }
})
export class CreateArticle extends Vue {
  @Inject(UserRepositoryType)
  protected userRepository!: IUserRepository

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public formName: string = 'createUser'
  public isSaving: boolean = false
  public payload: UserPayload = {
    avatar: null,
    email: '',
    firstName: '',
    lastName: '',
    nickname: '',
    isEditor: false,
    isBlocked: false,
    isShadowbanned: false,
    password: '',
    passwordConfirmation: '',
    roles: {} as Roles
  }

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('users.listTitle')}`,
          target: { name: 'users.list' }
        },
        {
          label: `${this.$t('users.addUser')}`,
          target: { name: 'users.create' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get siteLogos () {
    return [...this.siteResolver.getDictionary()]
      .map(site => {
        return {
          [site.domain]: site.logo.light
        }
      })
      .reduce((o, logo) => ({ ...o, ...logo }), {})
  }

  created () {
    this.payload.roles = this.getRoles()
  }

  public createNewUser (): void {
    this.isSaving = true

    const intention = new CreateUserIntention(this.payload)
    this.userRepository.create(intention)
      .then(() => {
        this.$router.push({ name: 'users.list' })
      })
      .catch(error => console.debug(error))
      .finally(() => {
        this.isSaving = false
      })
  }

  public propagateRoles (selected: SimpleRole[]) {
    if (!this.payload.roles) {
      return
    }
    this.payload.roles = Object.keys(this.payload.roles)
      .reduce((o, key) => ({ ...o, [key]: [...selected] }), {}) as unknown as Roles
  }

  public setAvatar (newAvatar: Avatar) {
    this.payload = {
      ...this.payload,
      avatar: newAvatar
    }
  }

  protected getRoles (): Roles {
    return [...this.siteResolver.getDictionary()]
      .map(site => site.domain)
      .reduce((o, key) => ({ ...o, [key]: [] }), {}) as unknown as Roles
  }
}

export default CreateArticle

