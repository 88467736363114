









































































import { BreadcrumbsProps, TableHead } from '@d24/modules'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { IModal, ModalType } from '@movecloser/front-core'
import { HeaderInterface } from '@component/InteractiveTable/InteractiveTable.contracts'
import { Inject } from '@/shared/plugins/inversify'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { ModelListHeader } from '@component/ModelListHeader/ModelListHeader'
import { Query } from '@/shared/contracts/query'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { searchTesterTableHead } from '../maps/article'
import { SearchTableRowElement } from '../contracts'
import { ISearchRepository, SearchRepositoryType } from '../contracts/repositories'
import { initBreadcrumbs } from '../helpers'
import { articlesFiltersConfig } from '@module/content/models/content.filters'
import SearchTableRow from '@module/content/components/SearchTableRow.vue'
import { debounce } from 'lodash'

@Component({
  name: 'SearchTester',
  components: {
    InteractiveTable,
    ModelListHeader
  }
})
export class SearchTester extends Mixins<IUserAware>(UserAware) {
  @Inject(SearchRepositoryType)
  protected searchRepository!: ISearchRepository

  @Inject(ModalType)
  protected modalConnector!: IModal

  public filtersConfig = articlesFiltersConfig
  public isLoading = false
  public boostTitle = 10
  public boostLead = 6
  public boostContent = 3
  public boostTags = 4
  public boostDays = 14

  public get header (): HeaderInterface {
    const payload: Partial<HeaderInterface> = {
      title: `${this.$t('content.search.listTitle')}`
    }
    return payload as HeaderInterface
  }

  public get modelListHeader () {
    const payload = {
      title: this.$t('content.search.listTitle')
    }

    return payload
  }

  public tableHead: TableHead = searchTesterTableHead
  public rowComponent = SearchTableRow
  public tableData: SearchTableRowElement[] = []

  public totalPages: number = 0
  public itemsTotal: number = 0

  mounted () {
    this.loadList(this.queryParams)
  }

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('content.search.listTitle')}`,
          target: { name: 'content.search.list' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  protected loadList (query: Query): void {
    query.exp = '1'
    query.sort = '-date'
    query.boostTitle = this.boostTitle.toString()
    query.boostLead = this.boostLead.toString()
    query.boostContent = this.boostContent.toString()
    query.boostTags = this.boostTags.toString()
    query.boostDays = this.boostDays.toString()
    this.isLoading = true
    this.searchRepository.load(query).then(collection => {
      this.isLoading = false
      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: this.canEditContents || this.canDeleteContents,
          data: model
        }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.itemsTotal = collection.meta.total
    }).catch(error => {
      console.log(error)
      this.isLoading = false
    })
  }

  public search () {
    this.searchDebounce(this.queryParams)
  }

  public searchDebounce = debounce(this.loadList.bind(this), 500)

  @Watch('queryParams', { deep: true })
  protected onQueryChange (query: Query): void {
    this.loadList(query)
  }
}

export default SearchTester
