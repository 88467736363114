// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'

import { IProcess, ProcessData, ProcessStatus } from '../contracts/models'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class Process extends Model<ProcessData> implements IProcess {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'id',
      'code',
      'errors',
      'feedback',
      'result',
      'status',
      'type'
    ]
  }

  public isFailed (): boolean {
    return this._data.status === ProcessStatus.Failed
  }

  public isPending (): boolean {
    return this._data.status === ProcessStatus.Pending
  }

  public isSuccessful (): boolean {
    return [ProcessStatus.Succeed, ProcessStatus.Finished].includes(this._data.status)
  }
}
