// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const authorAdapterMap: MappingConfig = {
  id: 'id',
  avatar: 'avatar',
  email: 'email',
  firstName: 'firstName',
  fullName: 'fullName',
  lastName: 'lastName',
  nickname: 'nickname',
  deletedAt: 'deletedAt',
  isActive: 'isActive',
  isEditor: 'isEditor',
  user: {
    type: MappingTypes.Function,
    value: item => {
      if (!item.user) {
        return item.user
      } else if (!item.user.avatar) {
        return item.user
      }
      return {
        ...item.user,
        avatar: {
          url: item.user.avatar
        }
      }
    }
  },
  articlesCount: {
    type: MappingTypes.Function,
    value: item => {
      if (!item.articlesCount) {
        return '--'
      }
      return item.articlesCount
    }
  },
  slug: 'slug',
  type: 'type',
  title: 'title',
  description: 'description',
  links: 'links',
  sort: 'sort',
  updatedAt: 'updatedAt'
}
