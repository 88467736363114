





































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@d24/modules'
import { IModal, ModalType } from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@/shared/plugins/inversify'

import { FileCreateModalPayload } from '../contracts/components'
import { FileCreateForm } from './FileCreateForm.vue'
import { FileCreatePayload } from '@module/media/contracts'
import { FilePond, FilePondFile } from 'filepond'
import { fileCreateFormValidatorsMap } from '@module/media/helpers/fileCreate'
import { ValidatorsMap } from '../contracts/validators'
import { validateFormData } from '@module/media/helpers/formValidate'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component({
  name: 'FileCreateModal',
  components: { FileCreateForm }
})
export class FileCreateModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: FileCreateModalPayload

  @Inject(ModalType)
  protected modalConnector!: IModal

  public duplicateDetected: Identifier | null = null
  public files: FilePondFile[] = []
  public fileMode: string | null = null
  public forceCreate: boolean = false
  public forms: FileCreatePayload[] = []
  public icons = DashmixIconName
  public isLoading: boolean = false
  public validationErrors: Record<string, string[]>[] = []

  private validatorsMap: ValidatorsMap<FileCreatePayload> = fileCreateFormValidatorsMap

  public get isFileModePublic (): boolean {
    return this.fileMode === 'public'
  }

  public get fileChoosen (): boolean {
    return this.files.length > 0
  }

  public get filepond (): FilePond {
    return (this.$refs.fileCreateForm as FileCreateForm).filepond as unknown as FilePond
  }

  public get targetDirectoryId (): Identifier {
    return this.payload.parentDirectory.id
  }

  public clickSave (mode: string) {
    this.isLoading = true
    this.validationErrors = validateFormData.bind(this, this.forms[0], this.validatorsMap)()

    if (this.validationErrors.length > 0) {
      this.isLoading = false

      return
    }

    if (!this.isFileUploaded(this.files[0]) && !this.duplicateDetected) {
      this.fileMode = mode
      this.filepond.processFile()
      return
    }
    this.sendForm()
  }

  public close (): void {
    this.$emit('close')
  }

  public getIsSaveDisabled (mode: string): boolean {
    return !this.fileChoosen || this.isLoading ||
      (this.duplicateDetected && !this.forceCreate) ||
      (
        (this.isFileUploaded(this.files[0]) || !!this.duplicateDetected) &&
        (mode === 'private' ? !this.isFileModePublic : this.isFileModePublic)
      )
  }

  public isFileUploaded (file: FilePondFile): boolean {
    return !!file?.serverId
  }

  public sendForm (): void {
    if (!this.duplicateDetected && !this.files[0]?.serverId) {
      this.isLoading = false
      return
    }

    this.payload.createFile(this.duplicateDetected ?? parseInt(this.files[0]?.serverId), this.forms[0],
      this.files[0], !!this.duplicateDetected).then((success) => {
      if (!success || this.files.length === 0) {
        this.isLoading = false
        return
      }
      if (this.duplicateDetected) {
        this.duplicateDetected = null
        this.forceCreate = false
      }

      this.filepond.removeFile()
      if (this.files.length === 1) {
        this.isLoading = false
        this.modalConnector.close()
        return
      }
      this.filepond.processFile(this.files[1])
    })
  }

  public showFile (file: any): void {
    window.open(
      this.$router.resolve({ name: 'media.list', query: { editId: file.id } }).resolved.fullPath
    )
  }
}

export default FileCreateModal
