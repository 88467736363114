// Copyright © 2021 Move Closer

import { DashmixIconName, DashmixTheme, TableHead, TableSearchBarAction } from '@d24/modules'

import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'

import { canDeleteContents, canEditContents, canViewContents } from '../guards'
import { ContentModel } from '../contracts'

export const pagesTableHead: TableHead = [
  {
    column: 'tree-structure',
    label: '',
    sortable: false,
    isSorted: false,
    width: '60px'
  },
  {
    column: 'title',
    label: 'Tytuł',
    sortable: false,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'editors',
    label: 'Edytorzy',
    sortable: false,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'status',
    label: 'Status',
    sortable: false,
    isSorted: false,
    width: '100px'
  },
  {
    column: 'children',
    label: 'Podstrony',
    sortable: false,
    isSorted: false,
    width: '120px'
  },
  {
    column: 'createdDate',
    label: 'Data utworzenia',
    sortable: false,
    isSorted: false
  },
  {
    column: 'modificationDate',
    label: 'Data modyfikacji',
    sortable: false,
    isSorted: false
  }
]

export const searchingPagesTableHead: TableHead = [
  {
    column: 'title',
    label: 'Tytuł',
    sortable: false,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'editors',
    label: 'Edytorzy',
    sortable: false,
    isSorted: false,
    width: '0.5fr'
  },
  {
    column: 'status',
    label: 'Status',
    sortable: false,
    isSorted: false,
    width: '100px'
  },
  {
    column: 'createdDate',
    label: 'Data utworzenia',
    sortable: false,
    isSorted: false
  },
  {
    column: 'modificationDate',
    label: 'Data modyfikacji',
    sortable: false,
    isSorted: false
  }
]

export const pagesTableHeadShortened: TableHead = [
  {
    column: 'title',
    label: 'Tytuł',
    sortable: false,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'editors',
    label: 'Edytorzy',
    sortable: false,
    isSorted: false,
    width: '1.6fr'
  },
  {
    column: 'status',
    label: 'Status',
    sortable: false,
    isSorted: false,
    width: '120px'
  }
]

export enum PagesActions {
  Delete = 'delete',
  Edit = 'edit',
  Preview = 'preview',
  Publish = 'publish',
  Sort = 'sort'
}

export const pagesRowActionsFactory = (domain: Domain, user: UserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canViewContents(domain, user)) {
    actions.push(
      {
        label: 'Podgląd',
        icon: DashmixIconName.Eye,
        isBulkAction: false,
        action: PagesActions.Preview,
        guard: (data: unknown): boolean => {
          const model = data as ContentModel
          return !!model.url
        }
      }
    )
  }

  if (canEditContents(domain, user)) {
    actions.push(
      {
        label: 'Edytuj',
        icon: DashmixIconName.PenSolid,
        isBulkAction: false,
        action: PagesActions.Edit
      }
    )
  }

  if (canEditContents(domain, user)) {
    actions.push(
      {
        label: 'Zmień kolejność',
        icon: DashmixIconName.ArrowsAltVSolid,
        isBulkAction: false,
        action: PagesActions.Sort
      }
    )
  }

  if (canDeleteContents(domain, user)) {
    actions.push(
      {
        label: 'Usuń',
        icon: DashmixIconName.TrashAltSolid,
        needsDoubleClick: true,
        isBulkAction: true,
        action: PagesActions.Delete,
        theme: DashmixTheme.Danger
      }
    )
  }

  return actions
}
