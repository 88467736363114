// Copyright © 2021 Move Closer

import { ComponentObjectPropsOptions } from '@d24/modules'
import { PropType } from '@vue/composition-api'
import { RawLocation } from 'vue-router'

import { ModelListHeaderMode, ModelListHeaderProps } from './ModelListHeader.contracts'

export const modelListHeaderHooks: ComponentObjectPropsOptions<ModelListHeaderProps> = {
  addLabel: {
    type: String,
    required: false,
    default: null
  },
  addTarget: {
    type: [String, Object] as PropType<RawLocation>,
    required: false
  },
  mode: {
    type: String as () => ModelListHeaderMode,
    required: false,
    default: ModelListHeaderMode.Link
  },
  showAdd: {
    type: Boolean,
    required: false,
    default: true
  },
  title: {
    type: String,
    required: true
  },
  withoutIcon: {
    type: Boolean,
    required: false
  }
}
