// Copyright © 2021 Move Closer

import { defineComponent, ref } from '@vue/composition-api'

import { editModeLayoutProps } from './EditModeLayout.hooks'
import { EditModeTabs } from './partials/EditModeTabs'
import Loader from '../Loader'
import { DashmixButtonVariantMap, DashmixIconName, DashmixTheme } from '@d24/modules'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const EditModeLayout = defineComponent({
  name: 'EditModeLayout',
  components: { EditModeTabs, Loader },
  props: editModeLayoutProps,
  setup () {
    const toggled = ref<boolean>(false)

    const toggleSidebar = () => {
      toggled.value = !toggled.value
    }

    return {
      toggled,
      toggleSidebar,
      icons: DashmixIconName,
      variants: DashmixButtonVariantMap,
      theme: DashmixTheme
    }
  },
  template: `
    <div class="edit-mode">
      <div class="edit-mode__content" :class="{'--sidebar' : hasSidebar }">
        <Loader v-if="isLoading" />

        <template v-else>
          <slot name="top" v-if="$slots.top" />
          <!-- TODO: Styling... -->
          <D24Breadcrumbs v-if="breadcrumbs" :items="breadcrumbs.items" :root="breadcrumbs.root" />
          <slot name="content" />
        </template>
      </div>

      <div v-if="hasSidebar" class="edit-mode__sidebar" :class="{'--toggled' : toggled }">
        <div class="edit-mode__sidebar__toggler bg-dark"
             :class="toggled ? 'bg-dark' : 'bg-white'"
             @click="toggleSidebar">
          <D24Button
            :icon="toggled ? icons.ChevronRightSolid : icons.ChevronLeftSolid"
            :variant="variants.Icon"
            :theme="toggled ? theme.Light : theme.Dark" />
        </div>
        <div class="edit-mode__sidebar__inner" :class="{'--toggled' : toggled }">
          <D24Box class="edit-mode__sidebar__box" footerClass="p-0" :noBodyPadding="true">
            <slot name="sidebar" v-if="!isLoading" />
            <template v-slot:footer>
              <EditModeTabs v-if="!isLoading && tabs" class="edit-mode__sidebar__tabs"
                            :items="tabs.items" :initTab="tabs.initTab" />
            </template>
          </D24Box>
        </div>
      </div>
    </div>
  `
})
