// Copyright © 2021 Move Closer

import {
  AdBannerModuleForm,
  EmbedModuleForm, ImageModuleForm,
  ModuleDriver,
  ParagraphModuleForm,
  PartnersModuleForm, RandomImageModuleForm,
  StickyBarModuleForm,
  TileModuleForm
} from '@d24/modules'

import { ModulesRegistry } from '@component/PageBuilder'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const sidebarModules: ModulesRegistry = {
  [ModuleDriver.AdBanner]: {
    form: AdBannerModuleForm,
    image: require('@/assets/images/modules/ad_banner.svg'),
    name: ModuleDriver.AdBanner
  },
  [ModuleDriver.Paragraph]: {
    form: ParagraphModuleForm,
    image: require('@/assets/images/modules/paragraph.svg'),
    name: ModuleDriver.Paragraph
  },
  [ModuleDriver.Tile]: {
    form: TileModuleForm,
    image: require('@/assets/images/modules/tile.svg'),
    name: ModuleDriver.Tile
  },
  [ModuleDriver.Embed]: {
    form: EmbedModuleForm,
    image: require('@/assets/images/modules/embed.svg'),
    name: ModuleDriver.Embed
  },
  [ModuleDriver.Image]: {
    form: ImageModuleForm,
    image: require('@/assets/images/modules/image.svg'),
    name: ModuleDriver.Image
  },
  [ModuleDriver.RandomImage]: {
    form: RandomImageModuleForm,
    image: require('@/assets/images/modules/image.svg'),
    name: ModuleDriver.RandomImage
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const topModules: ModulesRegistry = {
  [ModuleDriver.AdBanner]: {
    form: AdBannerModuleForm,
    image: require('@/assets/images/modules/ad_banner.svg'),
    name: ModuleDriver.AdBanner
  },
  [ModuleDriver.Partners]: {
    form: PartnersModuleForm,
    // TODO: Add correct icon.
    image: require('@/assets/images/modules/news_bar.svg'),
    name: ModuleDriver.Partners
  },
  [ModuleDriver.StickyBar]: {
    form: StickyBarModuleForm,
    image: require('@/assets/images/modules/news_bar.svg'),
    name: ModuleDriver.StickyBar
  },
  [ModuleDriver.Image]: {
    form: ImageModuleForm,
    image: require('@/assets/images/modules/image.svg'),
    name: ModuleDriver.Image
  },
  [ModuleDriver.RandomImage]: {
    form: RandomImageModuleForm,
    image: require('@/assets/images/modules/image.svg'),
    name: ModuleDriver.RandomImage
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const commentsModules: ModulesRegistry = {
  [ModuleDriver.AdBanner]: {
    form: AdBannerModuleForm,
    image: require('@/assets/images/modules/ad_banner.svg'),
    name: ModuleDriver.AdBanner
  },
  [ModuleDriver.Image]: {
    form: ImageModuleForm,
    image: require('@/assets/images/modules/image.svg'),
    name: ModuleDriver.Image
  },
  [ModuleDriver.RandomImage]: {
    form: RandomImageModuleForm,
    image: require('@/assets/images/modules/image.svg'),
    name: ModuleDriver.RandomImage
  }
}
