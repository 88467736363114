// Copyright © 2021 Move Closer

import { DashmixIconName, DashmixTheme, TableHead, TableSearchBarAction } from '@d24/modules'

import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'

import { canDeleteAuthors, canEditAuthors } from '../guards'

export const authorTableHead: TableHead = [
  {
    column: 'avatar',
    label: '',
    sortable: false,
    isSorted: false,
    width: '50px'
  },
  {
    column: 'name',
    label: 'authors.table.name',
    sortable: true,
    isSorted: false,
    width: '1.2fr'
  },
  {
    column: 'user',
    label: 'authors.table.user',
    sortable: false,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'numberOfArticles',
    label: 'authors.table.articles',
    sortable: false,
    isSorted: false,
    width: '100px'
  },
  {
    column: 'updatedAt',
    label: 'authors.table.updatedAt',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  }
]

export enum AuthorListsActions {
  Edit = 'edit',
  Delete = 'delete',
}

export const authorRowActionsFactory = (domain: Domain, user: UserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canEditAuthors(domain, user)) {
    actions.push(
      {
        label: 'Edytuj',
        icon: DashmixIconName.PenSolid,
        isBulkAction: false,
        action: AuthorListsActions.Edit
      }
    )
  }

  if (canDeleteAuthors(domain, user)) {
    actions.push(
      {
        label: 'Usuń',
        icon: DashmixIconName.TrashAltSolid,
        needsDoubleClick: true,
        isBulkAction: true,
        action: AuthorListsActions.Delete,
        theme: DashmixTheme.Danger
      }
    )
  }

  return actions
}
