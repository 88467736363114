// Copyright © 2021 Move Closer

import { MappingConfig } from '@movecloser/front-core'

import { ownerAdapterMap } from '@module/root/models/owner.adapter'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const authorAdapterMap: MappingConfig = ownerAdapterMap
