// Copyright © 2021 Move Closer

import { RawLocation } from 'vue-router'
import { PropType } from '@vue/composition-api'

import { ComponentObjectPropsOptions } from '@d24/modules'

import { EmptyTableProps } from './EmptyTable.contracts'

export const emptyTableProps: ComponentObjectPropsOptions<EmptyTableProps> = {
  actionLinkTarget: {
    type: Object as PropType<RawLocation>,
    required: false
  },
  actionLabel: {
    type: String,
    required: false
  }
}
