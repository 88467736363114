// Copyright © 2021 Move Closer

import { IModel, MagicModel } from '@movecloser/front-core'
import { HasIdentifier, Identifier } from '@/shared/contracts/data'
import { AnyObject, Site } from '@d24/modules'

import { AuthorModel as VariantAuthorModel } from '@module/content/contracts'

import { Permission } from '@module/auth/contracts/permissions'

export interface AuthorData extends PersonData {
  slug: string
  type: AuthorTypes
  description: string
  links: Record<string, string[]>
  sort: string
  updatedAt: string
  user: SimpleUser | null
  articlesCount: number
  position: string
}

export type AuthorModel = MagicModel<AuthorData, IAuthor>

export enum AuthorTypes {
  Author = 'author',
  Blogger = 'blogger'
}

export interface Avatar {
  id: Identifier
  url: string
}

export interface IAuthor extends IModel<AuthorData> {
  toOwner (): VariantAuthorModel
}

export interface LogData extends HasIdentifier {
  browserFingerprint: string
  commitedAt: string
  id: Identifier
  message: string
  remoteAddr: string
  type: string
  user: SimpleUser
}

export enum LogType {
  CmsList = 'cms-list',
  CmsPage = 'cms-page',
  CmsArticle = 'cms-article',
  CmsMedia = 'cms-media',
  CmsUsers = 'cms-users',
  CmsSettings = 'cms-settings',
  FrontUsers = 'front-users',
  FrontComments = 'front-comments',
  FrontSuns = 'front-subs',
  FrontPaywall = 'front-paywall'
}

export type LogModel = MagicModel<LogData>

export interface IUser extends IModel<UserData> {
  fullName (): string

  switchBlocked (blocked: boolean): void

  switchShadowbanned (shadowbanned: boolean): void
}

export interface PeriodData extends HasIdentifier {
  isPaid: boolean
  type: SubscriptionsTypes
  period: SubscriptionsPeriods
  invoice?: string
  invoiceSlug?: string
  startedAt: string
  endedAt: string
}

export interface PersonData extends SimpleUser {
  deletedAt: string | null
  isActive: boolean
  isEditor: boolean
  nickname: string
}

export interface Prices {
  [key: string]: AnyObject
}

export interface RoleData extends HasIdentifier {
  id: Identifier
  isLocked: boolean
  name: string
  permissions: Permission[]
  updatedAt: string
  usersCount: number
}

export type RoleModel = MagicModel<RoleData>

export type Roles = Record<Site, SimpleRole[]>

export interface SimpleRole extends HasIdentifier {
  name: string
}

export interface SimpleUser extends HasIdentifier {
  fullName: string
  avatar: Avatar | null
  lastName?: string
  firstName?: string
}

export interface SubscriptionData extends HasIdentifier {
  user: SimpleUser
  type: SubscriptionsTypes
  status: SubscriptionsStatuses
  period: SubscriptionsPeriods
  price?: number
  isActive: boolean
  startedAt: string
  deactivatedBy: string
  deactivatedAt: string
  history: string
  endedAt: string
  nextRenewalAt: string | null
}

export type SubscriptionModel = MagicModel<SubscriptionData>

export interface UsersInfoData {
  userCount: number
  userSubscriptionCount: number
  latestUserCount: number
}

export enum SubscriptionsPeriods {
  Annual = 'annual',
  Monthly = 'monthly',
  SemiAnnual = 'semi-annual',
  None = 'none'
}

export enum SubscriptionsTypes {
  Premium = 'premium',
  Standard = 'standard'
}

export enum SubscriptionsStatuses {
  Cancelled = 'cancelled',
  NotStarted = 'not_started',
  Expired = 'expired',
  Expiring = 'expiring',
  DaysLeft = 'days_left',
  Paid = 'paid'
}

export interface TransactionData {
  driver: string
  id: string
  total: number
  currency: string
  status: string
  startedAt: string
  finishedAt: string
}

export interface CompanyData {
  vatNumber: string
  organization: string
  city: string
  postalCode: string
  country: string
  address: string
}

export interface UserData extends PersonData {
  companyData?: CompanyData
  email: string
  isBlocked: boolean
  isShadowbanned: boolean
  isSubscriber: boolean
  hasActiveSubscription: boolean
  lastLogin: string | null
  roles: Roles
  registeredAt: string
  subscriptionId: string
}

export type UserModel = MagicModel<UserData, IUser>
