













import { BreadcrumbsProps, TableRowAction } from '@d24/modules'
import { Component, Mixins } from 'vue-property-decorator'
import { IModal, ModalType } from '@movecloser/front-core'
import { Inject } from '@plugin/inversify'

import { ModelListHeader } from '@component/ModelListHeader'
import { Query } from '@/shared/contracts/query'

import { TagListTab } from '../components/TagListTab.vue'
import { ITagRepository, TagRepositoryType, TagType } from '../contracts'
import { promotionsRowActionsFactory } from '@module/settings/maps/promotions'
import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Component({
  name: 'TagsList',
  components: { ModelListHeader, TagListTab }
})
export class TagsList extends Mixins<IUserAware>(UserAware) {
  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(TagRepositoryType)
  protected tagRepository!: ITagRepository

  created () {
    this.validateQuery()
  }

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('tag.tags')}`,
          target: { name: 'settings.tags.list' }
        }
      ],
      root: {
        label: `${this.$t('root.views.root')}`,
        target: { name: 'root.dashboard' }
      }
    }
  }

  public get tabs () {
    return [{
      tab: {
        id: TagType.Tag,
        label: 'Tagi'
      }
    }, {
      tab: {
        id: TagType.Label,
        label: 'Etykiety'
      }
    }]
  }

  public get rowActions (): TableRowAction[] {
    return promotionsRowActionsFactory(this.domain, this.user)
  }

  public get activeTab (): TagType {
    if (this.queryParams.type) {
      return this.queryParams.type as TagType
    }
    return TagType.Tag
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  public switchTab (value: string) {
    this.$router.push({
      name: 'settings.tags.list',
      params: {},
      query: { type: value }
    })
  }

  protected validateQuery (): void {
    if (typeof this.queryParams.type !== 'string') {
      this.$router.push({
        name: 'settings.tags.list',
        params: {},
        query: { status: TagType.Tag }
      })
    }
  }
}

export default TagsList
