// Copyright © 2021 Move Closer

import { FilePondFile } from 'filepond'
import { Coordinates, ImageTransforms } from 'vue-advanced-cropper'
import { ImageRatio, Related, RelatedType, TableRowElement } from '@d24/modules'

import { Identifier } from '@/shared/contracts/data'

import {
  DirectoryCreatePayload, DirectoryEditPayload, FileCreatePayload,
  FileSize, MediaMovePayload
} from '../contracts/data'
import { DirectoryModel, DirectoryParentData, FileData, FileDataActions, FileModel } from '../contracts/models'

export interface CropImageModalPayload {
  aspectRatio: number | null
  id: Identifier
  original: string
  ratio: ImageRatio
}

export interface CropperEvent {
  coordinates: Coordinates
  imageTransforms: ImageTransforms
}

export interface DirectoryCreateModalPayload {
  parentDirectory: DirectoryParentData
  createDirectory: (payload: DirectoryCreatePayload) => Promise<void>
}

export interface DirectoryEditModalPayload {
  directory: DirectoryModel
  updateDirectory: (id: Identifier, payload: DirectoryEditPayload) => Promise<void>
}

export interface DirectoryMoveModalPayload {
  directory: DirectoryModel
  moveDirectory: (id: Identifier, payload: MediaMovePayload) => Promise<void>
}

export interface FileCreateModalPayload {
  parentDirectory: DirectoryParentData
  createFile: (id: Identifier, payload: FileCreatePayload, file: FilePondFile, force?: boolean) => Promise<boolean>
}

export interface FileEditModalPayload {
  abandonedMode?: boolean
  file: Identifier
  onDelete?: (id: Identifier) => void
  onUpdate?: (file: FileModel) => void
}

export interface FilesMoveModalPayload {
  files: FileModel[]
  moveFile: (id: Identifier, payload: MediaMovePayload) => Promise<void>
}

export interface FilePickerModalPayload {
  multiple: boolean
  onSuccess: (file: FileModel | FileModel[]) => void
}

export interface FileVariantLabelDefinition extends FileSize {
  label: string
}

export interface EditFilesDataPayload {
  selected: Related<RelatedType.File, Identifier>[]
  filesData: FileData[]
  onSuccess: (filesData: StoredFileData[]) => void
}

export interface MediaTableRowElement extends TableRowElement {
  data: FileModel | DirectoryModel
}

export interface StoredFileData {
  action: FileDataActions
  author: string | null
  caption: string | null
  image: Related<RelatedType.File, Identifier>
  isAuthorChange?: boolean
  isCaptionChange: boolean
  redirectUrl?: string
  shortDesc: string | null
  redirectExt?: string
}

export interface FileOnClickAction {
  action: OnClickAction
  url?: string
}

export enum OnClickAction {
  Link = 'link',
  None = 'none',
  Preview = 'preview'
}
