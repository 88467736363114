// Copyright © 2021 Move Closer

import { defineComponent } from '@vue/composition-api'
import { actionBadgeProps } from './ActionBadge.hook'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const ActionBadge = defineComponent({
  name: 'ActionBadge',
  props: actionBadgeProps,
  emits: ['clicked'],

  template: `
    <D24Badge class="badge" v-bind="$props">
      <slot>{{label}}</slot>
      <D24Button class="badge__button"
                 variant="icon"
                 :size="buttonSize"
                 :disabled="disabled"
                 @click.native.stop="() => $emit('clicked')">
        <D24Icon class="badge__icon" :icon="icon" />
      </D24Button>
    </D24Badge>
  `
})
