// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'

import { ISite, SiteData } from '../contracts/models'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class Site extends Model<SiteData> implements ISite {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'id',
      'name',
      'address',
      'domain',
      'locale',
      'logo',
      'owner',
      'parent',
      'properties',
      'rootContent',
      'routePrefix'
    ]
  }

  public getProperty<Type = any> (name: string, defaultValue?: Type): Type | undefined {
    return this._data.properties && name in this._data.properties ? this._data.properties[name] as Type : defaultValue
  }

  public hasParent (): boolean {
    return this._data.parent !== null
  }
}
