










import { AnyObject, BreadcrumbsProps, DashmixIconName } from '@d24/modules'
import { Component, Vue } from 'vue-property-decorator'

import { EditModeLayout } from '@component/EditModeLayout'
import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'
import { Inject } from '@plugin/inversify'

import { ISettingsRepository, SettingRepositoryType } from '../contracts'
import { FreeSubscriptionRulesForm } from '../components/FreeSubscriptionRulesForm.vue'
import { InfoBarForm } from '../components/InfoBarForm.vue'
import { GeneralSettingsForm } from '../components/GeneralSettingsForm.vue'
import { SubscriptionPriceForm } from '../components/SubscriptionPriceForm.vue'
import { SubscriptionProductsForm } from '../components/SubscriptionProductsForm.vue'
import { AdsConfigForm } from '../components/AdsConfigForm.vue'

@Component({
  name: 'EditSetting',
  components: {
    EditModeLayout,
    InfoBarForm
  }
})
export class EditSetting extends Vue {
  @Inject(SettingRepositoryType)
  protected settingsRepository!: ISettingsRepository

  public readonly icons = DashmixIconName

  public isLoading = true
  public payload: AnyObject | AnyObject[] = {}

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('settings.all.title')}`,
          target: { name: 'settings.config.list' }
        },
        {
          label: `${this.$t(`${this.type}.title`)}`,
          target: { name: `settings.config.${this.type}` }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get formComponent () {
    switch (this.type) {
      case 'general':
        return GeneralSettingsForm
      case 'infoBar':
        return InfoBarForm
      case 'freeSubscriptionRules':
        return FreeSubscriptionRulesForm
      case 'subscriptionPrices':
        return SubscriptionPriceForm
      case 'subscriptionProducts':
        return SubscriptionProductsForm
      case 'adsConfig':
        return AdsConfigForm
      default:
        return null
    }
  }

  public get type () {
    if (this.$route.params.type) {
      return this.$route.params.type
    }
    if (this.$route.meta && this.$route.meta.type) {
      return this.$route.meta.type
    }
    return null
  }

  mounted () {
    this.isLoading = true

    if (this.formComponent) {
      this.payload = this.formComponent.defaultPayload()
    }

    this.settingsRepository.getSetting<AnyObject>(this.type).then(data => {
      if (Array.isArray(this.payload)) {
        this.payload = [...this.payload, ...(Array.isArray(data) ? data : Object.values(data))]
      } else {
        this.payload = {
          ...this.payload,
          ...data
        }
      }
    }).catch(e => console.warn(e))
      .finally(() => {
        this.isLoading = false
      })
  }

  public onSubmit () {
    this.isLoading = true

    this.settingsRepository.updateSetting<AnyObject>(this.type, this.payload)
      .catch(e => console.warn(e))
      .finally(() => {
        this.isLoading = false
      })
  }
}

export default EditSetting
