


/**
 * Abstract class that every SmartLinkTab Vue component should derive
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
import { Component, Vue, Watch } from 'vue-property-decorator'
import { DashmixSelectItem } from '@d24/modules'

import { Inject } from '@/shared/plugins/inversify'
import { IRelatedService, RelatedServiceType } from '@/shared/services/related'
import { DictionaryRepositoryType, IDictionaryRepository } from '@module/root/contracts/repositories'

import { ContentRepositoryType, IContentRepository } from '../contracts'
import { Hint } from '@component/Typeahead'
import { SingleSmartType } from '../maps/smart'

@Component<AbstractSmartLinkTab>({
  name: 'AbstractSmartLinkTab',

  mounted (): void {
    this.loadDictionary().then(() => {
      this.isLoading = false
    })
  }
})
export class AbstractSmartLinkTab extends Vue {
  @Inject(DictionaryRepositoryType)
  protected dictRepository!: IDictionaryRepository

  @Inject(ContentRepositoryType)
  protected contentRepository!: IContentRepository

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  /**
   * Type of smart link
   */
  public type: SingleSmartType | null = null

  /**
   * SmartLinkType
   */
  public SingleSmartType = SingleSmartType

  /**
   * Search string
   */
  public searchParam: string = ''

  /**
   * Value
   */
  public selected: Hint[] = []

  /**
   * State of loading data
   */
  public isLoading: boolean = false

  public changeType (type: SingleSmartType): void {
    this.type = type
  }

  public get smartOptions (): DashmixSelectItem[] {
    return [
      { label: 'Wybierz', value: null }
    ]
  }

  public onSearch (value: string) {
    this.searchParam = value
  }

  public onClear () {
    this.selected = []
    this.searchParam = ''
  }

  public onDelete () {
    this.selected = []
  }

  protected loadDictionary (search?: string): Promise<boolean> {
    // Override
    return Promise.resolve(true)
  }

  @Watch('searchParam', { deep: false })
  protected onSearchParamsChange (searchParams: string): void {
    this.loadDictionary(searchParams).then(() => {
      this.isLoading = false
    }).catch((e) => {
      console.warn(e)
    }).finally(() => {
      this.isLoading = false
    })
  }

  @Watch('type', { deep: false })
  protected onTypeChanged (): void {
    this.loadDictionary().then(() => {
      this.isLoading = false
    })
  }
}
export default AbstractSmartLinkTab
