// Copyright © 2021 Move Closer

import { moduleDriverFormComponentRegistry as moduleDriverForm, ModuleDriver } from '@d24/modules'

import { AdBannerModuleForm } from '@service/ads'
import RequestAccessModuleForms from '@module/forms/shared/RequestAccess'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const moduleDriverFormComponentRegistry = {
  ...moduleDriverForm,
  [ModuleDriver.AdBanner]: AdBannerModuleForm,
  [ModuleDriver.RequestAccess]: RequestAccessModuleForms
}
