// Copyright © 2021 Move Closer

import { DashmixIconName } from '@d24/modules'
import { defineComponent, SetupContext } from '@vue/composition-api'
import { PageBuilder as D24PageBuilder } from '@d24/page-builder'

import { ModeSwitcher } from './partials/ModeSwitcher'
import { PageBuilderOperationMode, PageBuilderProps } from './PageBuilder.contracts'
import { pageBuilderProps, usePageBuilder } from './PageBuilder.hooks'

import '@/assets/scss/custom/modules/_builder.scss'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const PageBuilder = defineComponent({
  name: 'PageBuilder',
  components: {
    D24PageBuilder,
    ModeSwitcher
  },
  props: pageBuilderProps,

  setup (props: PageBuilderProps, ctx: SetupContext) {
    return {
      ...usePageBuilder(props, ctx),
      DashmixIconName,
      PageBuilderOperationMode
    }
  },

  template: `
    <section>
    <div v-if="showControls" class="d-flex justify-content-between flex-row-reverse page-builder__controls"
         :class="{'shadow-sm': isSticky}">
      <ModeSwitcher :mode.sync="builderMode" :modeList="modeList" :showPreview="showPreview"
                    :relatedService="relatedService"/>
      <div>
        <D24Button v-if="modulesRegistry" variant="alt" :icon="DashmixIconName.PlusSolid"
                   :label="$t('builder.addModule')"
                   @click="addModule"/>
        <D24Button v-if="elements.length" theme="light" variant="alt" :icon="DashmixIconName.CopySolid"
                   :title="$t('builder.action.copyModules')"
                   @click="onModulesCopy"/>
        <D24Button v-if="hasAnythingToPaste" theme="light" variant="alt" :icon="DashmixIconName.PasteSolid"
                   :title="$t('builder.action.pasteModules')"
                   @click="onModulesPaste"/>
      </div>
    </div>

    <D24PageBuilder :id="builderMode === PageBuilderOperationMode.Render ? 'preview' : null"
                    class="page-builder__wrapper" :class="builderClass"
                    v-bind="{ columns, elements, elementProps, elementWrapper, isGridEditable, tag:targetTag }"
                    @update="onUpdate" @removeElement="onModuleDelete">

      <!-- Direction icons -->
      <D24Icon :icon="DashmixIconName.ArrowLeftSolid" slot="move-left"/>
      <D24Icon :icon="DashmixIconName.ArrowRightSolid" slot="move-right"/>

      <!-- Resize buttons / X-axis -->
      <D24Icon :icon="DashmixIconName.MinusSolid" slot="resize-x-minus"/>
      <D24Icon :icon="DashmixIconName.PlusSolid" slot="resize-x-plus"/>

      <!-- Resize buttons / Y-axis -->
      <D24Icon :icon="DashmixIconName.MinusSolid" slot="resize-y-minus"/>
      <D24Icon :icon="DashmixIconName.PlusSolid" slot="resize-y-plus"/>
    </D24PageBuilder>
    </section>
  `
})
