// Copyright © 2021 Move Closer

import {
  AggregatesRelated,
  DriverConfig,
  Identifier,
  Related,
  RelatedRecord,
  RelatedType,
  ResolvesRelatedAsync
} from '@d24/modules'
import { AnyObject } from '@movecloser/front-core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum CalledRelatedServiceMethod {
  Describe = 'describe',
  Resolve = 'resolve'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface IRelatedRepository {
  load (query: RelatedQuery): Promise<RelatedRecord>
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface IRelatedService extends AggregatesRelated, ResolvesRelatedAsync {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  merge (type: RelatedType, key: Identifier, value: any): void
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type RelatedQuery = Partial<Record<RelatedType, Identifier[]>>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const RelatedRepositoryType = Symbol.for('IRelatedRepository')

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const RelatedServiceType = Symbol.for('IRelatedService')

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface RelatedToLoad {
  config?: DriverConfig
  method: CalledRelatedServiceMethod
  reject: (error: Error) => void
  related: Related
  resolve: (resolved: AnyObject) => void
}
