// Copyright © 2021 Move Closer

import { AxiosDriver } from '@movecloser/front-core/lib/services/http/axios-driver'
import { IHttpConnectorConfig } from '@movecloser/front-core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const http: IHttpConnectorConfig = {
  drivers: {
    cdn: () => {
      return new AxiosDriver({
        baseURL: process.env.VUE_APP_CDN_BASEURL
      }, (process.env.VUE_APP_DEBUG === 'true'))
    },
    d24: () => {
      return new AxiosDriver({
        baseURL: process.env.VUE_APP_HTTP_D24_API_BASEURL,
        headers: {
          Accept: 'application/json',
          'Accept-Language': process.env.VUE_APP_HTTP_D24_API_LOCALE || 'en-GB',
          'X-Client': process.env.VUE_APP_HTTP_D24_API_CLIENT
        }
      }, (process.env.VUE_APP_DEBUG === 'true'))
    }
  },
  default: 'd24'
}
