


















































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { DashmixIconName, DashmixSelectItem } from '@d24/modules'
import { IModal, ModalType } from '@movecloser/front-core'

import { FormSelect } from '@component/form'
import { FormInput } from '@component/form/Input/Input'
import { Inject } from '@plugin/inversify'

import { SubscriptionsPeriods, SubscriptionsTypes } from '@module/users/contracts/models'

import { SubscriptionProductsData, SubscriptionProductsItem } from '../contracts/data'

@Component({
  name: 'SubscriptionProductsForm',
  components: { FormInput, FormSelect }
})
export class SubscriptionProductsForm extends Vue {
  @Prop({ type: Boolean, required: false })
  public isLoading!: boolean

  @PropSync('formData', { type: Array, required: true })
  public payload!: SubscriptionProductsData

  @Inject(ModalType)
  protected modalConnector!: IModal

  public readonly icons = DashmixIconName
  public readonly newItem: SubscriptionProductsItem = { type: '', period: '', renewal: false } as unknown as SubscriptionProductsItem

  public form: string = 'editSubscriptionProducts'

  public get typeOptions (): DashmixSelectItem[] {
    return Object.values(SubscriptionsTypes).map(value => {
      return {
        label: this.$t(`subscription.type.${value}`) as string,
        value: value
      }
    })
  }

  public get periodOptions (): DashmixSelectItem[] {
    return Object.values(SubscriptionsPeriods).map(value => {
      return {
        label: this.$t(`subscription.period.${value}`) as string,
        value: value
      }
    })
  }

  public addNewItem (): void {
    this.payload = [...this.payload, { ...JSON.parse(JSON.stringify(this.newItem)) }]
  }

  public deleteItem (index: number): void {
    const itemsCopy = [...this.payload]

    itemsCopy.splice(index, 1)

    this.payload = itemsCopy
  }

  public static defaultPayload () {
    return []
  }
}

export default SubscriptionProductsForm
