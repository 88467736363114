// Copyright © 2021 Move Closer

import { ExtendedMDE } from 'simplemde'
import { ModuleDriver, Related, RelatedType, SizeMap } from '@d24/modules'

import { Identifier } from '@/shared/contracts/data'
import { Modals } from '@/config/modals'

import { Shortcut } from '../MarkdownEditor.contracts'
import { findTokenAtCurrentLine, replaceTokenAtCurrentLine } from '@component/MarkdownEditor/helpers/line-parser'
import { AnyObject } from '@movecloser/front-core'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const readMoreShortcut: Shortcut = {
  action: (editor: ExtendedMDE) => {
    let selected: Related<RelatedType.Content, Identifier>[] | undefined
    const params: AnyObject = {
      showThumbnail: true
    }

    const onSelection = (data: Related<RelatedType.Content, Identifier>[]) => {
      if (!data || data.length === 0) {
        return
      }

      replaceTokenAtCurrentLine(editor, ModuleDriver.ReadMore, { articles: data, showThumbnails: params.showThumbnail })
    }

    const token: AnyObject | null = findTokenAtCurrentLine(editor, ModuleDriver.ReadMore)

    if (token && Object.prototype.hasOwnProperty.call(token, 'articles') && Object.prototype.hasOwnProperty.call(token, 'showThumbnails')) {
      selected = token.articles
      params.showThumbnail = token.showThumbnails
    }

    editor.options.modalConnector.open(Modals.PickArticle, {
      multiple: true,
      onClose: () => editor.options.modalConnector.close(),
      onSelection: onSelection,
      params,
      selected
    }, { size: SizeMap.Large })
  },
  className: 'fas fa-book-open',
  name: 'read_more',
  title: 'Wstaw artykuły powiązane ("Czytaj też")'
}
