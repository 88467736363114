// Copyright © 2021 Move Closer

import { TableHead } from '@d24/modules'

export const settingsTableHead: TableHead = [
  {
    column: 'type',
    label: 'Typ',
    sortable: false,
    isSorted: false,
    width: '1fr'
  }
]
