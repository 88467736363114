


















import { BreadcrumbsProps } from '@d24/modules'
import { Component, Vue } from 'vue-property-decorator'

import { Inject } from '@plugin/inversify'

import { EditModeLayout } from '@component/EditModeLayout'
import { initBreadcrumbs } from '@module/content/helpers'

import { ISubscriptionRepository, IUserRepository, SubscriptionRepositoryType, UserRepositoryType } from '../contracts/repositories'
import { Prices, SubscriptionsPeriods, SubscriptionsTypes, UserModel } from '../contracts/models'
import { SubscriptionForm } from '../components/SubscriptionForm.vue'
import { SubscriptionPayload } from '../contracts/data'
import { UserSiteBar } from '../components/UserSiteBar.vue'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'CreateSubscription',
  components: { EditModeLayout, SubscriptionForm, UserSiteBar }
})
export class CreateSubscription extends Vue {
  @Inject(UserRepositoryType)
  protected userRepository!: IUserRepository

  @Inject(SubscriptionRepositoryType)
  protected subscriptionRepository!: ISubscriptionRepository

  public formName: string = 'createSubscription'
  public isLoading: boolean = false
  public isSaving: boolean = false
  public payload: SubscriptionPayload | null = null
  public prices: Prices | null = null
  public user: UserModel | null = null

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('authors.listTitle')}`,
          target: { name: 'users.authors.list' }
        },
        {
          label: `${this.$t('subscription.listTitle')}`,
          target: { name: 'users.subscriptions.list' }
        },
        {
          label: `${this.$t('subscription.createSubscription')}`,
          target: { name: 'users.subscriptions.create' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get userId () {
    return Number(this.$route.params.id)
  }

  created () {
    this.loadUser()
    this.loadPrices()

    this.payload = {
      userId: this.userId,
      type: '' as SubscriptionsTypes,
      period: '' as SubscriptionsPeriods,
      startedAt: '',
      renewable: false
    }
  }

  public onSubmit () {
    if (!this.payload) {
      return
    }

    this.isSaving = true

    this.subscriptionRepository.create(this.userId, this.payload).then(() => {
      this.$router.push({ name: 'users.subscriptions.list' })
    }).catch((e) => console.debug(e))
      .finally(() => {
        this.isSaving = false
      })
  }

  protected loadPrices () {
    this.isLoading = true

    this.subscriptionRepository.getPrices().then(prices => {
      this.prices = prices
    }).catch(error => console.log(error)
    ).finally(() => { this.isLoading = false })
  }

  protected loadUser () {
    this.isLoading = true

    this.userRepository.load(this.userId).then(model => {
      this.user = model
    }).catch(error => console.log(error)
    ).finally(() => { this.isLoading = false })
  }
}

export default CreateSubscription
