

















import { Component, Vue } from 'vue-property-decorator'
import { BreadcrumbsProps } from '@d24/modules'

import { EditModeLayout } from '@component/EditModeLayout'
import { initBreadcrumbs } from '@module/content/helpers'
import { Inject } from '@plugin/inversify'
import { IMeRepository, MeRepositoryType } from '@module/auth/contracts/repositories'
import { MePayload } from '@module/auth/contracts/data'

import { ChangeAvatarForm } from '@module/auth/components/ChangeAvatarForm.vue'
import { ChangeBasicInformationForm } from '@module/auth/components/ChangeBasicInformationForm.vue'
import { ChangePasswordForm } from '@module/auth/components/ChangePasswordForm.vue'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'MyProfileView',
  components: {
    ChangeAvatarForm,
    ChangeBasicInformationForm,
    ChangePasswordForm,
    EditModeLayout
  }
})
export class MyProfileView extends Vue {
  @Inject(MeRepositoryType)
  protected meRepository!: IMeRepository

  public changePassword: boolean = false
  public editProfileFormName: string = 'myProfileEdit'
  public changePasswordFormName: string = 'changePassword'
  public hasSidebar: boolean = false
  public isLoading: boolean = false
  public isSaving: boolean = false
  public payload: MePayload = {
    avatar: null,
    email: '',
    firstName: '',
    lastName: '',
    nickname: '',
    actual: '',
    password: '',
    passwordConfirmation: ''
  }

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('auth.me.formName')}`,
          target: { name: 'auth.myProfileName' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  created () {
    this.isLoading = true
    this.meRepository.loadMeProfile().then((model) => {
      this.payload = {
        ...this.payload,
        ...model
      }
    }).catch(error => console.warn(error)
    ).finally(() => {
      this.isLoading = false
    })
  }
}

export default MyProfileView
