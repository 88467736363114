// Copyright © 2021 Move Closer

import {
  ICollection,
  Injectable, IResponse, MappingConfig,
  Repository, ResourceActionFailed
} from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'
import { Query } from '@/shared/contracts/query'
import { resolveFromStatus } from '@/shared/exceptions/connector-errors'

import { CreateRoleIntention } from '../intentions/createRole'
import { IRolesRepository } from '../contracts/repositories'
import { Role } from '../models/role'
import { RoleData, RoleModel } from '../contracts/models'
import { roleAdapterMap } from '../models/role.adapter'
import { UpdateRoleIntention } from '../intentions/updateRole'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Injectable()
export class RolesRepository extends Repository<RoleData> implements IRolesRepository {
  protected useAdapter = true
  protected map: MappingConfig = roleAdapterMap

  public async create (intention: CreateRoleIntention): Promise<string> {
    const response = await this.connector.call(
      'roles',
      'create',
      {},
      intention.toRequest()
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return response.data.data.roleId
  }

  public async delete (id: Identifier) {
    await this.connector.call(
      'roles',
      'delete',
      { id }
    )
  }

  public async load (id: Identifier): Promise<RoleModel> {
    const response: IResponse = await this.connector.call(
      'roles',
      'item',
      { id }
    )

    return this.composeModel(
      response.data.data,
      Role
    )
  }

  public async loadCollection (query?: Query): Promise<ICollection<RoleModel>> {
    const response: IResponse = await this.connector.call(
      'roles',
      'list',
      {},
      {
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Role,
      response.data.meta
    )
  }

  public async update (id: Identifier, intention: UpdateRoleIntention) {
    const response = await this.connector.call(
      'roles',
      'update',
      { id },
      intention.toRequest()
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }
}
