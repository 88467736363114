// Copyright © 2021 Move Closer

import { ResourceActionFailed } from '@movecloser/front-core'

export const lockErrorAvatar = (error: ResourceActionFailed) => {
  return error.payload.editor.avatar
}

export const lockErrorDate = (error: ResourceActionFailed) => {
  return error.payload.lockedAt
}

export const lockErrorFullName = (error: ResourceActionFailed) => {
  return error.payload.editor.firstName + ' ' + error.payload.editor.lastName
}

export const lockErrorVariantId = (error: ResourceActionFailed) => {
  return error.payload.id
}
