// Copyright © 2021 Move Closer

import SimpleMDE from 'simplemde'

import { Shortcut } from '../MarkdownEditor.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const headingShortcut: Shortcut = {
  action: SimpleMDE.toggleHeadingSmaller,
  className: 'fas fa-heading',
  name: 'heading',
  title: 'Wstaw nagłówek'
}
