




















import { Component, Vue, Prop } from 'vue-property-decorator'
import { DateTimeType, IDateTime, IModal, ModalType } from '@movecloser/front-core'
import { DashmixIconName } from '@d24/modules'

import { Inject } from '@plugin/inversify'

import { TakeOverModalPayload } from '../contracts/components'

@Component({
  name: 'ContentTakeOverModal'
})
export class ContentTakeOverModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: TakeOverModalPayload

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public icons = DashmixIconName

  public get updatedAt (): string {
    return this.dateTime.parseToFormat(this.payload.newStatus.updatedAt, 'DD:MM:YYYY HH:mm')
  }

  public refuse () {
    this.payload.onRefuse()
  }

  public accept () {
    this.payload.onAccept()
  }
}

export default ContentTakeOverModal
