// Copyright © 2021 Move Closer

import {
  ApiConnectorType,
  AppModule,
  Container,
  IConfiguration,
  IConnector,
  Interfaces,
  Module
} from '@movecloser/front-core'

import {
  CouponRepositoryType,
  ICouponRepository,
  ILayoutRepository,
  INavigationRepository,
  IPromotionRepository,
  ISettingsRepository,
  ITagRepository,
  LayoutRepositoryType,
  NavigationRepositoryType,
  PromotionRepositoryType,
  SettingRepositoryType,
  TagRepositoryType
} from './contracts'
import { LayoutRepository } from './repositories/layouts'
import { NavigationRepository } from './repositories/navigation'
import { resources } from './resources'
import { routesFactory } from './routes'
import { SettingsRepository } from './repositories/settings'
import { TagRepository } from './repositories/tag'
import { CouponRepository } from './repositories/coupons'
import { PromotionRepository } from './repositories/promotions'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@AppModule({
  name: 'settings',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  providers: (config: IConfiguration) => {
    return (bind: Interfaces.Bind) => {
      bind<ICouponRepository>(CouponRepositoryType).to(CouponRepository)
      bind<ILayoutRepository>(LayoutRepositoryType).to(LayoutRepository)
      bind<INavigationRepository>(NavigationRepositoryType).to(NavigationRepository)
      bind<IPromotionRepository>(PromotionRepositoryType).to(PromotionRepository)
      bind<ISettingsRepository>(SettingRepositoryType).to(SettingsRepository)
      bind<ITagRepository>(TagRepositoryType).to(TagRepository)
    }
  },
  boot (container: Container) {
    const connector: IConnector = container.get(ApiConnectorType)
    connector.useResources(resources)
  },
  routes: routesFactory
})
export class SettingsModule extends Module {}
