// Copyright © 2021 Move Closer

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

import { defineComponent, SetupContext } from '@vue/composition-api'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import { setOptions } from 'filepond'
import vueFilePond from 'vue-filepond'

import { allowedTypes } from '@module/media/contracts'

import { FileUploaderProps } from './FileUploader.contracts'
import { fileUploaderProps, useFileUploader } from './FileUploader.hooks'

import plTranslation from '@/assets/langs/pl/filepond'

setOptions(plTranslation)
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
)

export const FileUploader = defineComponent({
  name: 'FileUploader',
  components: { FilePond },
  props: fileUploaderProps,
  emits: ['update:files'],
  setup: (props: FileUploaderProps, ctx: SetupContext) => {
    return {
      ...useFileUploader(props, ctx),
      allowedTypes
    }
  },
  template: `
    <file-pond
      name="file"
      ref="filepond"
      :allow-multiple="multiple"
      :allowImagePreview="showPreview"
      :server="settings.server"
      :maxFileSize="settings.maxFileSize"
      :chunkUploads="settings.chunkUploads"
      :chunkForce="settings.chunkForce"
      :chunkSize="settings.chunkSize"
      :accepted-file-types=allowedTypes[allowedMediaType]
      v-bind:files="model"
      :instantUpload="instantUpload"
      :allowProcess="allowProcess"
      v-on="$listeners"
    />
  `
})
