















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName, SizeMap } from '@d24/modules'

import { Identifier } from '@/shared/contracts/data'

import { FileModel, FileThumbnail } from '../contracts'
import { fileSize } from '../helpers'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({
  name: 'SelectedFilesList',
  components: {}
})
export class SelectedFilesList extends Vue {
  @Prop({ type: Array, required: true })

  public items?: FileModel[]

  public icons = DashmixIconName
  public SizeMap = SizeMap

  public getFile (item: FileModel): FileThumbnail | null {
    const thumbnail = item?.file?.thumbnail ?? item?.file?.file ?? null
    return {
      thumbnail: thumbnail ?? '',
      type: item.mime
    }
  }

  public size (bytes: number): string {
    return bytes ? fileSize(bytes) : ''
  }

  public remove (id: Identifier) {
    this.$emit('removeFile', id)
  }

  public edit (file: FileModel) {
    const routeData = this.$router.resolve({
      name: 'media.showDirectory',
      params: { id: file.directory.id.toString() },
      query: { editId: file.id.toString() }
    })
    window.open(routeData.href, '_blank')
  }
}

export default SelectedFilesList
