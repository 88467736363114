// Copyright © 2021 Move Closer

import { ComponentObjectPropsOptions } from '@d24/modules'
import { computed, PropType } from '@vue/composition-api'

import { Alignment, PropertyProps } from './Property.contracts'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export const propertyProps: ComponentObjectPropsOptions<PropertyProps> = {
  align: {
    type: String as PropType<Alignment>,
    required: false,
    default: Alignment.Left
  },
  columnSizes: {
    type: Array as PropType<string[]>,
    required: false,
    default: () => []
  },
  label: {
    type: String,
    required: false
  },
  value: {
    type: [String, Boolean, Number],
    required: false,
    default: null
  }
}

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export const useProperty = (props: PropertyProps) => {
  let valueToDisplay: string = ''

  switch (typeof props.value) {
    case 'boolean':
      valueToDisplay = props.value ? 'Włączone' : 'Wyłączone'
      break
    case 'object':
      if (!props.value) {
        valueToDisplay = 'Wyłączone'
      }
      break
    case 'number':
    default:
      valueToDisplay = `${props.value}`
  }

  const alignmentClass = computed<string>(() => {
    if (props.align === Alignment.Right) {
      return '--right'
    }
    return '--left'
  })

  const gridStyle = computed(() => {
    return props.columnSizes.reduce((prev: string, current: string) => (`${current} ${prev}`), '')
  })

  return { alignmentClass, gridStyle, valueToDisplay }
}
