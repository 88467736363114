// Copyright © 2022 Move Closer

import { FormType } from '@module/forms/contracts'
import { AbstractIntention } from '@movecloser/front-core'

export interface CreateFormIntentionPayload {
  maEventName: string
  title: string
  type: FormType
}

export class CreateFormIntention extends AbstractIntention<CreateFormIntentionPayload> {
  protected map = {
    maEventName: 'maEventName',
    title: 'title',
    type: 'type'
  }
}
