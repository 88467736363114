





















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Component({
  name: 'PermissionsListItem'
})
class PermissionsListItem extends Vue {
  @Prop({ type: Number, required: true })
  public index!: number

  @Prop({ type: String, required: true })
  public label!: string

  @Prop({ type: String, required: true })
  public description!: string

  @PropSync('checked', { type: Boolean, required: true })
  public isChecked!: boolean

  public check () {
    this.isChecked = !this.isChecked
  }
}

export default PermissionsListItem
