

















import { Component, Vue } from 'vue-property-decorator'

import { BreadcrumbsProps } from '@d24/modules'
import { Inject } from '@/shared/plugins/inversify'
import { EditModeLayout } from '@component/EditModeLayout'

import { initBreadcrumbs } from '@module/content/helpers'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'

import { Avatar } from '../contracts/models'
import { AuthorRepositoryType, IAuthorRepository } from '../contracts/repositories'
import { AuthorForm } from '../components/AuthorForm.vue'
import { AuthorPayload } from '@module/users/contracts/data'
import { PagesSideBar } from '../components/PagesSiteBar.vue'
import { CreateAuthorIntention } from '@module/users/intentions/CreateAuthorIntention'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'CreateAuthor',
  components: { AuthorForm, EditModeLayout, PagesSideBar }
})
export class CreateAuthor extends Vue {
  @Inject(AuthorRepositoryType)
  protected authorRepository!: IAuthorRepository

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public formName: string = 'createAuthor'
  public isSaving: boolean = false
  public payload: AuthorPayload = {
    avatar: null,
    firstName: '',
    lastName: '',
    links: {},
    nickname: '',
    description: '',
    user: null,
    isActive: false,
    position: '',
    title: '',
    type: null,
    isLinkActive: false,
    isPageActive: false
  }

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('authors.listTitle')}`,
          target: { name: 'users.authors.list' }
        },
        {
          label: `${this.$t('authors.addAuthor')}`,
          target: { name: 'users.authors.create' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public createNewAuthor (): void {
    this.isSaving = true

    const intention = new CreateAuthorIntention(this.payload)
    this.authorRepository.create(intention)
      .then(() => {
        this.$router.push({ name: 'users.authors.list' })
      })
      .catch(error => console.debug(error))
      .finally(() => {
        this.isSaving = false
      })
  }

  public setAvatar (newAvatar: Avatar) {
    this.payload = {
      ...this.payload,
      avatar: newAvatar
    }
  }
}

export default CreateAuthor

