// Copyright © 2021 Move Closer

import { AbstractIntention } from '@movecloser/front-core'
import { SetItem } from '@module/content/contracts'

export class AddSetItemIntention extends AbstractIntention<SetItem> {
  protected map = {
    // author: 'author',
    id: 'id',
    position: 'position'
    // publicationDate: 'publicationDate',
    // title: 'title'
  }
}
