// Copyright © 2021 Move Closer

import { SneakPeekModel } from '@module/content/contracts'

export interface ITemplateGenerator {

  /**
   * Minify generated template
   * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  minify (): Promise<string[] | any>
}

/**
 * Enum that defines types of generalizable newsletters
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export enum NewsletterType {
  /**
   * Newsletter without image and additional decoration
   * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
   */
  SimpleList = 'simple_list',

  /**
   * Newsletter that contains image and additional decoration
   * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
   */
  ImageList = 'image_list'
}

/**
 * Generated Newsletter type
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export type GeneratedNewsletter = string

/**
 * Additional filters for NewsletterGenerator
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export interface NewsletterFilters {
  /**
   * Type of Newsletter that should be generated
   * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
   */
  type: NewsletterType
}

/**
 * Template for generating imageList layout table cells
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @param payload
 * @param filters
 * @param isFirst
 * @param isLast
 */
export const generate = (payload: SneakPeekModel, filters: Partial<NewsletterFilters>, isFirst?: boolean, isLast?: boolean): string => {
  switch (filters.type) {
    case NewsletterType.ImageList : {
      return `
        <tr>
            <td style="padding-bottom: 16px;">
                <img src="${payload.properties?.cover?.image?.url}" height="190" width="280" alt="">
            </td>
        </tr>
        <tr>
            <td style="padding-bottom: 12px;font-weight: bold; font-size: 18px;line-height: 1.25;">
                ${payload.title}
            </td>
        </tr>
        <tr>
            <td style="padding-bottom: 16px;font-size: 14px;line-height: 1.5;">
                ${payload.properties.lead}
            </td>
        </tr>
        <tr>
            <td ${isLast ? '' : 'style="padding-bottom:60px"'}>
                <a href="${payload.fullUrl}" target="_blank"
                   style="font-size: 10px; line-height: 1; text-transform: uppercase; color: #ffffff; font-weight: bold; text-decoration: none; border-radius: 3px; background-color: #E61A1A; border-top: 10px solid #E61A1A; border-bottom: 10px solid #E61A1A; border-right: 15px solid #E61A1A; border-left: 15px solid #E61A1A; display: inline-block;">
                    Czytaj więcej
                </a>
            </td>
        </tr>
    `
    }
    case NewsletterType.SimpleList : {
      return `
        <tr style="font-size: 14px;line-height: 1.25;${!isLast && 'border-bottom: 1pt solid #f0f0f0;'}">
          <td valign="top" style="padding-right: 12px;${isFirst ? '' : 'padding-top:16px'}">&bull;</td>
          <td ${isFirst ? '' : 'style="padding-top:16px"'}>
            <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td>
                  <a href="${payload.fullUrl}" target="_blank" style="text-decoration: none;color:#000000;">
                    <strong>${payload.title}</strong>
                  </a>
                </td>
              </tr>
            </table>
          </td>
      </tr>
    `
    }
    default: {
      return `
      <tr>
        <td style="padding-right: 20px;">
          <img src="${payload.properties.cover.image.url}" height="100" width="100" alt="">
        </td>
        <td>
          <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
            <tr>
              <td>
                <a href="${payload.fullUrl}" target="_blank" style="text-decoration: none;color:#000000;">
                    <strong>${payload.title}</strong>
                </a>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    `
    }
  }
}

/**
 * Header
 */
export const header = (): string => {
  return `
<header style="
    background-color: #ffffff;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Arial';
    font-weight: bold;
">
    <div style="
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:1rem;
    ">
        <div style="
        background-color: red;
        width: 12px;
        height: 12px;
        "></div>
        <h3>Newsletter</h3>
    </div>
    <span>${new Date().toLocaleDateString().replace(/\//g, '.')}</span>
</header>
  `
}

/**
 * Base template wrapper for each Newsletter types
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @param payload
 */
export const withHTML = (payload: GeneratedNewsletter[]): string => {
  return `
<table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
    ${payload.map((cell) => {
      return cell
    }).join('\n')}
</table>
  `
}

/**
 * Class to generate newsletters
 *
 * [Warning!] This class is based from functions and contracts from outside of class.
 *
 * If you want to use each functions separately please refer to their description first!
 * @inheritDoc
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export class NewsletterGenerator implements ITemplateGenerator {
  /**
   * Static function that takes array of SneakPeaks to create template out of it
   *
   * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
   *
   * @param take
   * @param filters
   * @returns Promise<string[]>
   */
  public static generateFromSneakPeak (take: SneakPeekModel[], filters: Partial<NewsletterFilters> = {}): Promise<GeneratedNewsletter> {
    const _arr: string[] = []

    return new Promise<GeneratedNewsletter>((resolve) => {
      if (!_arr || _arr.length < 0 || typeof _arr === 'string') {
        throw Error(`Expected first argument to be list, but [${typeof _arr}] given`)
      }
      for (let i = 0; i < take.length; i++) {
        _arr.push(generate(take[i], filters, i === 0, i === take.length - 1))
      }
      resolve(withHTML(_arr))
    }).catch(e => {
      return `[NewsletterGenerator] : error found while generating template with [generateFromSneakPeak] function, stack:  ${e}`
    })
  }

  /**
   * Implement this function to minify generated template if necessary
   * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public minify (): Promise<string[] | any> {
    return new Promise<string>(resolve => {
      // TODO
      resolve()
    })
  }
}
