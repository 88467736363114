// Copyright © 2021 Move Closer

import {
  AdBannerModuleForm as AdBannerModuleFormAbstract,
  AdBannerType,
  AdBannerVersion,
  DashmixSelectItem,
  FormErrors,
  Identifier
} from '@d24/modules'
import { Component } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { AdsRepositoryType, IAdsRepository } from '@module/content/contracts'
import { Inject } from '@plugin/inversify'
import { Query } from '@/shared/contracts/query'
import { Typeahead } from '@component/Typeahead'

import { AdBannerGoogleForm } from './variants/ads-google.form'
import { AdBannerWTGForm } from './variants/ads-wtg.form'
import { AdHint, TargetingOption, TargetingOptionsKeys } from './ads.contracts'
import { TargetingKeys } from './ads.config'

/**
 * Component that contains a complete questionnaire needed to **EDIT** the `AdBanner`'s contents.
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<AdBannerModuleForm>({
  name: 'AdBannerModuleForm',
  components: {
    AdBannerGoogleForm,
    AdBannerWTGForm,
    FormErrors,
    Typeahead
  },

  created () {
    if (typeof this._version === 'undefined') {
      this._version = AdBannerVersion.GoogleAds
    }

    // @ts-expect-error handle legacy data format
    if (this._version === 'sticky') {
      this._version = AdBannerVersion.GoogleAds
      this._content = {
        ...this._content,
        type: AdBannerType.Sticky
      }
    }
    this.initAdUnits()
  },

  mounted () {
    if (Object.prototype.hasOwnProperty.call(this._content, 'mobileSize')) {
      this.hasDifferentMobileSize = true
    }
  },

  template: `
    <div>
    <div class="row mb-2">
      <div class="col-12 col-md-6">
        <label for="size">{{ $t('forms.shared.modulesVariant') }}</label>
        <D24Select :model.sync="_version" name="version"
                   :options="versionOptions" :searchable="false"/>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <label for="size">{{ $t('adBanner.type') }}</label>
        <D24Select :model.sync="_content.type" name="type"
                   :options="typeOptions" :searchable="false"/>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="size">{{ $t('adBanner.variant') }}</label>
          <D24Select :model.sync="_content.size" name="size"
                     :options="sizeOptions" :searchable="false"/>
        </div>

        <div class="form-group mb-3">
          <D24Check class="mb-3" :label="$t('adBanner.mobileSize.check')" name="hasDifferentMobileSize"
                    :model="hasDifferentMobileSize" @update:model="onHasDifferentMobileSizeChange"/>
          <D24Select v-if="hasDifferentMobileSize" :model.sync="_content.mobileSize" name="mobileSize"
                     :options="sizeOptions" :searchable="false"/>

          <D24Check class="mb-3" :label="$t('adBanner.doNotShrinkComponent.check')" name="doNotShrink"
                    :model.sync="_content.doNotShrink"/>

          <D24Check class="mb-3" :label="$t('adBanner.forceShrink.check')" name="forceShrink"
                    :model.sync="_content.forceShrink"/>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="adUnits">{{ $t('adBanner.adUnits') }}</label>
          <Typeahead id="adUnits" name="adUnits" class="w-100"
                     :hints="adUnitHints" :selected="adUnitValue" :loading="loading"
                     @selected="onSelect" @searched="onSearch" @deleted="onDelete" @cleared="onClear"
                     :disabled="disabled" :isMulti="false" :taggable="false" clearable/>
        </div>
      </div>
    </div>

    <component :is="formComponent" :content.sync="_content" :disabled="disabled" :targetingOptions="targetingOptions"
               :getTargeting="getTargeting" :setTargeting="setTargeting"/>

    <!-- Form errors -->
    <FormErrors v-if="errors" v-bind="{ errors }" class="mt-3"/>
    </div>
  `
})
export class AdBannerModuleForm extends AdBannerModuleFormAbstract {
  @Inject(AdsRepositoryType)
  protected readonly adsRepository!: IAdsRepository

  public readonly AdBannerVersion = AdBannerVersion
  public readonly adUnitsPerPage = '20'

  public adUnitHints: AdHint[] = []
  public adUnitValue: AdHint[] = []
  public doNotShrinkComponent: boolean = false
  public hasDifferentMobileSize: boolean = false
  public loading = false
  public targetingOptions: Omit<TargetingOptionsKeys, TargetingKeys.Page> = {
    [TargetingKeys.Section]: [],
    [TargetingKeys.Position]: [],
    [TargetingKeys.Type]: [],
    [TargetingKeys.Order]: []
  }

  public get formComponent (): VueConstructor | undefined {
    // Force default
    if (this._version !== AdBannerVersion.WayToGrow && this._version !== AdBannerVersion.GoogleAds) {
      this._version = AdBannerVersion.GoogleAds
    }
    switch (this._version) {
      case AdBannerVersion.GoogleAds:
        return AdBannerGoogleForm
      case AdBannerVersion.WayToGrow:
        return AdBannerWTGForm
    }
  }

  public get typeOptions (): DashmixSelectItem[] {
    return Object.values(AdBannerType).map(v => ({
      value: v,
      label: this.$t(`forms.adBanner.type.${v}`).toString()
    }))
  }

  public get versionOptions (): DashmixSelectItem[] {
    return Object.values(AdBannerVersion).map(v => ({
      value: v,
      label: this.$t(`forms.adBanner.versions.${v}`).toString()
    }))
  }

  public onHasDifferentMobileSizeChange (value: boolean): void {
    this.hasDifferentMobileSize = value

    if (!value) {
      const contentCopy = { ...this._content }
      delete contentCopy.mobileSize

      this._content = contentCopy
    }
  }

  public onDoNotShrinkComponentChange (value: boolean): void {
    this.doNotShrinkComponent = value

    if (!value) {
      const contentCopy = { ...this._content }
      delete contentCopy.doNotShrinkComponent

      this._content = contentCopy
    }
  }

  public onClear () {
    this.loadAdUnit({ q: '' })
  }

  public onDelete () {
    delete this._content.adUnit
    delete this._content.adUnitId
    delete this._content.adUnitFullPath
    this.adUnitValue = []
  }

  public onSelect (selectedHint: AdHint) {
    this.adUnitValue = [selectedHint]
    this._content.adUnit = selectedHint.label
    this._content.adUnitId = selectedHint.value as unknown as Identifier
    this._content.adUnitFullPath = selectedHint.fullPath
  }

  public onSearch (searchedParams: string) {
    this.loadAdUnit({ q: searchedParams })
  }

  public getTargeting (key: TargetingKeys): string[] {
    if (!this._content.targeting || !this._content.targeting[key]) {
      return []
    }

    const targeting: string | string[] = this._content.targeting[key]
    return Array.isArray(targeting) ? targeting : [targeting]
  }

  public setTargeting (key: TargetingKeys, value: string[]): void {
    const contentCopy = { ...this._content }

    if (value.length === 0) {
      if (contentCopy.targeting && contentCopy.targeting[key]) {
        delete contentCopy.targeting[key]
      }
    } else {
      if (!contentCopy.targeting) {
        contentCopy.targeting = { [key]: value }
      } else {
        contentCopy.targeting[key] = value
      }
    }

    this._content = contentCopy
  }

  protected mapOptionsFromAPI (optionsList: TargetingOption[]): void {
    [...optionsList].forEach(option => {
      if (option.key !== TargetingKeys.Page && this.targetingOptions[option.key] && Array.isArray(this.targetingOptions[option.key])) {
        this.targetingOptions[option.key].push({
          value: option.value,
          label: option.name
        })
      }
    })
  }

  protected initAdUnits () {
    this.loadAdUnit({ q: '' })

    this.adsRepository.loadAdsKeys().then(kv => {
      this.mapOptionsFromAPI(kv)
    })

    if (this._content.adUnitId) {
      this.loading = true
      this.adsRepository.load(this._content.adUnitId).then(adUnit => {
        this.adUnitValue = [{
          value: adUnit.id,
          label: adUnit.name,
          fullPath: adUnit.fullPath,
          sizes: adUnit.sizes
        }]
      }).catch((e) => console.debug(e))
        .finally(() => {
          this.loading = false
        })
      // Protection when AdUnitId is not provided
    } else if (!this._content.adUnitId && this._content.adUnit) {
      this.adUnitValue = [{
        value: this._content.adUnit,
        label: this._content.adUnit,
        sizes: []
      }]
    }
  }

  protected loadAdUnit (query: Query) {
    this.loading = true

    this.adsRepository.loadCollection({ perPage: this.adUnitsPerPage, ...query }).then(collection => {
      this.adUnitHints = [...collection].map(adUnit => {
        return {
          value: adUnit.id,
          label: adUnit.name,
          fullPath: adUnit.fullPath,
          sizes: adUnit.sizes
        }
      })
    }).catch(e => console.debug(e))
      .finally(() => {
        this.loading = false
      })
  }
}
