// Copyright © 2021 Move Closer

import { defineComponent } from '@vue/composition-api'

import { emptyTableProps } from './EmpytTable.hook'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const EmptyTable = defineComponent({
  name: 'EmptyTable',
  props: emptyTableProps,
  template: `
    <div class="mt-6 text-center">
      <h4> Nie ma elementów do wyświetlenia </h4>
      <D24Link v-if="$props.actionLinkTarget" class="mt-3" :target="$props.actionLinkTarget">
        <D24Button :loading="false" theme="light" :label="$props.actionLabel" />
      </D24Link>
    </div>
  `
})
