// Copyright © 2021 Move Closer

import { Domain } from '@module/root/contracts/models'
import { Permission } from '@module/auth/contracts/permissions'
import { UserModel } from '@module/auth/contracts/models'

/**
 * Authors
 */
export const canCreateAuthors = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateAuthors)
}

export const canEditAuthors = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditAuthors)
}

export const canDeleteAuthors = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteAuthors)
}

export const canViewAuthors = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewAuthors)
}

/**
 * Users
 */
export const canCreateUsers = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateUsers)
}

export const canEditUsers = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditUsers)
}

export const canDeleteUsers = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteUsers)
}

export const canViewUsers = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewUsers)
}

/**
 * Roles
 */
export const canCreateRoles = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateRoles)
}

export const canEditRoles = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditRoles)
}

export const canDeleteRoles = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteRoles)
}

export const canViewRoles = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewRoles)
}

/**
 * Subscriptions
 */
export const canViewSubscription = (domain: Domain, user: UserModel | null): boolean => {
  // if (!user) return false
  //
  // return user.canPerform(domain, Permission.ViewSubscription)
  // FIXME: TEMP - delete when ACL ready
  return true
}

export const canEditSubscription = (domain: Domain, user: UserModel | null): boolean => {
  // if (!user) return false
  //
  // return user.canPerform(domain, Permission.EditSubscription)
  // FIXME: TEMP - delete when ACL ready
  return true
}

export const canCreateSubscription = (domain: Domain, user: UserModel | null): boolean => {
  // if (!user) return false
  //
  // return user.canPerform(domain, Permission.CreateSubscription)
  // FIXME: TEMP - delete when ACL ready
  return true
}
