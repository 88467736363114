<!-- Copyright © 2021 Move Closer -->

<template>
  <BaseSection type="inline">
    <template slot="heading">
      {{ $t('components.MarkdownEditorGuideModal.sections.link.heading') }}
    </template>

    <pre>[{{ $t('components.MarkdownEditorGuideModal.sections.link.text-to-display') }}](http://www.example.com)</pre>
  </BaseSection>
</template>

<script>
import BaseSection from './_base'
export default { components: { BaseSection } }
</script>
