// Copyright © 2021 Move Closer

import { Domain } from '@module/root/contracts/models'
import { Permission } from '@module/auth/contracts/permissions'
import { UserModel } from '@module/auth/contracts/models'

export const canViewNavigation = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewNavigation)
}

export const canCreateNavigation = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateNavigation)
}

export const canEditNavigation = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditNavigation)
}

export const canDeleteNavigation = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteNavigation)
}

export const canViewLayout = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewLayout)
}

export const canCreateLayout = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateLayout)
}

export const canEditLayout = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditLayout)
}

export const canDeleteLayout = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteLayout)
}

export const canViewPromotion = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewPromotion)
}

export const canCreatePromotion = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreatePromotion)
}

export const canEditPromotion = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditPromotion)
}

export const canDeletePromotion = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeletePromotion)
}

export const canViewCoupon = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewCoupon)
}

export const canCreateCoupon = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateCoupon)
}

export const canEditCoupon = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditCoupon)
}

export const canDeleteCoupon = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteCoupon)
}

export const canCreateTag = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ManageTags)
}

export const canEditTag = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ManageTags)
}

export const canDeleteTag = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ManageTags)
}
