// Copyright © 2021 Move Closer

import { AnyObject } from '@movecloser/front-core'
import { ExtendedMDE } from 'simplemde'
import { ModuleDriver, RequestAccessContent, SizeMap } from '@d24/modules'

import { Modals } from '@/config/modals'

import { Shortcut } from '../MarkdownEditor.contracts'

import { findTokenAtCurrentLine, replaceTokenAtCurrentLine } from '@component/MarkdownEditor/helpers/line-parser'

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
export const formShortcut: Shortcut = {
  action: (editor: ExtendedMDE) => {
    const foundToken: AnyObject | null = findTokenAtCurrentLine(editor, ModuleDriver.RequestAccess)

    editor.options.modalConnector.open(Modals.AddForm, {
      initialData: foundToken,
      onConfirm: (content: RequestAccessContent) => {
        const lineNumber: number = editor.codemirror.getCursor().line
        const lineLength: number = editor.codemirror.getLine(
          editor.codemirror.getCursor().line
        ).length

        editor.codemirror.replaceRange(
          '',
          { line: lineNumber, ch: 0 },
          { line: lineNumber, ch: lineLength }
        )

        replaceTokenAtCurrentLine(
          editor,
          ModuleDriver.RequestAccess,
          content
        )
      }
    }, { size: SizeMap.XLarge })
  },
  className: 'fas fa-envelope-open',
  name: 'request_access',
  title: 'Wstaw formularz'
}
