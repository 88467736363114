// Copyright © 2021 Move Closer

import { ComponentObjectPropsOptions } from '@d24/modules'
import { computed, PropType, SetupContext } from '@vue/composition-api'

import { IRelatedService } from '@service/related'

import { ModeSwitcherItem, ModeSwitcherProps, PageBuilderOperationMode } from '../PageBuilder.contracts'
import { pageBuilderOperationModeIconRegistry } from '../PageBuilder.config'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const modeSwitcherProps: ComponentObjectPropsOptions<ModeSwitcherProps> = {
  mode: {
    type: String as PropType<PageBuilderOperationMode>,
    required: true
  },
  modeList: {
    type: Array as PropType<PageBuilderOperationMode[]>,
    required: true
  },
  relatedService: {
    type: Object as PropType<IRelatedService>,
    required: true
  }
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export function useModeSwitcher (props: ModeSwitcherProps, ctx: SetupContext) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const availableModes = computed<ModeSwitcherItem[]>(() => {
    return props.modeList.map(mode => ({
      icon: pageBuilderOperationModeIconRegistry[mode],
      label: ctx.root.$i18n.t(`builder.operationMode.${mode}`),
      mode
    }))
  })

  const selected = computed<PageBuilderOperationMode>({
    get () {
      return props.mode
    },
    set (mode: PageBuilderOperationMode) {
      ctx.emit('update:mode', mode)
    }
  })

  function enableMode (mode: PageBuilderOperationMode): void {
    if (mode === PageBuilderOperationMode.Render) {
      props.relatedService.resetDrivers()
    }

    selected.value = mode
  }

  function isModeEnabled (mode: PageBuilderOperationMode): boolean {
    return selected.value === mode
  }

  return { availableModes, enableMode, isModeEnabled, selected }
}
