// Copyright © 2021 Move Closer

import { AbstractIntention } from '@movecloser/front-core'
import { FileModel } from '@module/media/contracts'

export class AddGalleryItemIntention extends AbstractIntention<FileModel> {
  protected map = {
    id: 'id',
    position: 'position'
  }
}
