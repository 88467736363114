// Copyright © 2021 Move Closer

import {
  ICollection,
  Injectable,
  IResponse,
  MappingConfig,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { TagData, TagModel, TagType } from '../contracts/models'
import { ITagRepository } from '../contracts/repositories'
import { resolveFromStatus } from '@/shared/exceptions/connector-errors'
import { Query } from '@/shared/contracts/query'
import { Tag } from '@module/settings/models/tag'
import { tagAdapterMap } from '@module/settings/models/tag.adapter'
import { Identifier } from '@/shared/contracts/data'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */

@Injectable()
export class TagRepository extends Repository<TagData, Tag> implements ITagRepository {
  protected useAdapter: boolean = true
  protected map: MappingConfig = tagAdapterMap

  public async create (newTag: Omit<TagData, 'id'>): Promise<TagModel> {
    if (newTag.type !== TagType.Label) {
      delete newTag.pluralName
    }

    const response: IResponse = await this.connector.call(
      'tags',
      'create',
      {},
      newTag
    )
    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
    return this.composeModel(
      { ...response.data?.data, ...newTag },
      Tag
    )
  }

  public async delete (id: TagData['id']): Promise<void> {
    const response: IResponse = await this.connector.call(
      'tags',
      'delete',
      { id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async import (): Promise<void> {
    const response: IResponse = await this.connector.call(
      'tags',
      'import'
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async export (): Promise<void> {
    const response: IResponse = await this.connector.call(
      'tags',
      'export'
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async get (tagId: Identifier): Promise<TagModel> {
    const response: IResponse = await this.connector.call(
      'tags',
      'get',
      { id: `${tagId}` }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeModel(response.data.data, Tag)
  }

  public async loadCollection (type: TagType, query: Query): Promise<ICollection<TagModel>> {
    const response = await this.connector.call(
      'tags',
      'list',
      { type: `${type}s` },
      {
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Tag,
      response.data.meta
    )
  }

  public async update (tagId: Identifier, newTag: TagData): Promise<TagModel> {
    const response: IResponse = await this.connector.call(
      'tags',
      'update',
      { id: `${tagId}` },
      newTag
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeModel(
      { ...newTag },
      Tag
    )
  }
}
