// Copyright © 2021 Move Closer

import { IModuleConstructor } from '@movecloser/front-core'

import { AuthModule } from '@module/auth/module'
import { RootModule } from '@module/root/module'
import { ContentModule } from '@module/content/module'
import { FormsModule } from '@module/forms/module'
import { MediaModule } from '@module/media/module'
import { SettingsModule } from '@module/settings/module'
import { UsersModule } from '@module/users/module'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const modules: IModuleConstructor[] = [
  // Here you can register new modules of the app.
  // Follow IModuleConstructor type: new() => Module
  AuthModule,
  ContentModule,
  FormsModule,
  MediaModule,
  SettingsModule,
  UsersModule,
  // RootModule needs to be last, as it contains a top-level 'catch-all' route
  RootModule
]
