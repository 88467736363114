// Copyright © 2021 Move Closer

import {
  ApiConnectorType,
  AppModule,
  ConnectorMiddleware,
  IConfiguration,
  IConnector,
  Module
} from '@movecloser/front-core'

import {
  DictionaryRepositoryType,
  IDictionaryRepository,
  ISitesRepository,
  SitesRepositoryType
} from './contracts/repositories'
import { DictRepository } from './repositories/dict'
import { resources } from './resources'
import { routesFactory } from './routes'
import {
  ISiteResolver,
  ISiteService,
  SiteMiddleware,
  SiteMiddlewareType,
  SiteResolver,
  SiteResolverType,
  SiteService,
  SiteServiceType
} from './services/site-resolver'
import { SitesRepository } from './repositories/sites'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@AppModule({
  name: 'root',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  providers: (config: IConfiguration) => {
    return (bind) => {
      bind<ConnectorMiddleware>(SiteMiddlewareType).toDynamicValue(context => {
        return new SiteMiddleware(
          context.container.get(SiteResolverType)
        )
      })
      bind<IDictionaryRepository>(DictionaryRepositoryType).to(DictRepository)
      bind<ISitesRepository>(SitesRepositoryType).to(SitesRepository)
      bind<ISiteResolver>(SiteResolverType).toDynamicValue(() => {
        return new SiteResolver('X-Site', 'defence24.pl')
      }).inSingletonScope()
      bind<ISiteService>(SiteServiceType).toDynamicValue(context => {
        return new SiteService(
          context.container.get(SitesRepositoryType),
          context.container.get(SiteResolverType)
        )
      }).inSingletonScope()
    }
  },
  boot: container => {
    const connector = container.get<IConnector>(ApiConnectorType)
    connector.useResources(
      resources
    )
  },
  routes: routesFactory
})
export class RootModule extends Module {}
