




































import { Component } from 'vue-property-decorator'
import { debounce, DebouncedFunc } from 'lodash'
import { Identifier } from '@d24/modules'

import { CheckItem } from '@/shared/contracts/form'
import { Inject } from '@/shared/plugins/inversify'

import { DictionaryRepositoryType, IDictionaryRepository } from '@module/root/contracts/repositories'

import { BadgesColor } from '../../maps/variant'
import { VariantBadge } from '../../contracts'

import { AbstractAddon } from './AbstractAddon'
import { AddonErrors } from './AddonErrors.vue'

@Component({
  name: 'LabelsAddons',
  components: { AddonErrors }
})
export class LabelsAddons extends AbstractAddon {
  @Inject(DictionaryRepositoryType)
  protected dictRepository!: IDictionaryRepository

  public activeLabels: VariantBadge[] = []
  public isLoading = true
  public labelsDictionary: CheckItem[] = [] as CheckItem[]
  public noStandard: boolean = false
  public noStandardId: null | Identifier = null

  public get colorsDictionary () {
    return Object.values(BadgesColor).map(c => {
      return {
        label: this.$t(`colors.${c}`),
        value: c
      }
    })
  }

  public get noStandardColor (): string | undefined {
    for (const label of this.activeLabels) {
      if (label.id === this.noStandardId) {
        return label.color
      }
    }
  }

  public get noStandardName (): string | undefined {
    for (const label of this.activeLabels) {
      if (label.id === this.noStandardId) {
        return label.name
      }
    }
  }

  mounted () {
    this.getVariantProperty()

    this.isLoading = true

    this.dictRepository.loadLabelsDictionary().then(dict => {
      this.labelsDictionary = [...dict].map(model => {
        return {
          key: model.id,
          label: model.name
        }
      })

      this.$nextTick(() => {
        this.activeLabels.forEach(label => {
          if (label.id) {
            if (!this.labelsDictionary.map(label => label.key).includes(label.id)) {
              this.noStandard = true
              this.noStandardId = label.id
            }
          }
        })
      })
    }).finally(() => {
      this.isLoading = false
    })
  }

  public getValue (itemKey: Identifier) {
    return this.activeLabels.map(label => label.id).includes(itemKey)
  }

  public setNoStandardLabelColor (value: string) {
    this.activeLabels.map(label => {
      if (label.id === this.noStandardId) {
        label.color = value
      }

      return label
    })

    this.onInputValueChange(this.activeLabels)
  }

  public setNoStandardLabelName (value: string) {
    this.activeLabels.map(label => {
      if (label.id === this.noStandardId) {
        label.name = value
      }

      return label
    })

    this.onInputValueChange(this.activeLabels)
  }

  public setNoStandard (value: boolean) {
    if (value) {
      // To ensure there is only one custom label
      if (this.noStandardId) {
        this.activeLabels = this.activeLabels.filter(label => label.id !== this.noStandardId)
        this.noStandardId = null
      }
      this.activeLabels.push({ name: '', color: '', id: null } as unknown as VariantBadge)
      this.onInputValueChange(this.activeLabels)
    }
    this.noStandard = value
  }

  public setLabelValue (event: boolean, itemKey: Identifier) {
    if (event) {
      this.activeLabels.push({ id: itemKey })
    } else {
      this.activeLabels = this.activeLabels.filter(label => itemKey !== label.id)
    }

    this.onInputValueChange(this.activeLabels)
  }

  protected getVariantProperty (): void {
    const labelsFromModel = this.variant.getProperty<VariantBadge[]>('labels')

    if (!labelsFromModel) {
      this.activeLabels = []
      return
    }

    this.activeLabels = labelsFromModel
  }

  protected onInputValueChange: DebouncedFunc<(activeLabels: VariantBadge[]) => void> =
    debounce((activeLabels: VariantBadge[]) => {
      this.onInputValueChange.cancel()
      this.variant.setProperty<VariantBadge[]>('labels', activeLabels)

      this.onChange(this.variant)
    }, 500)
}

export default LabelsAddons
