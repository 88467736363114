// Copyright © 2021 Move Closer

import {
  ApiConnectorType,
  AppModule,
  Container,
  IConfiguration,
  IConnector,
  Interfaces,
  Module
} from '@movecloser/front-core'

import {
  AuthorRepositoryType,
  IAuthorRepository,
  ILogsRepository,
  IUserRepository,
  IRolesRepository,
  LogRepositoryType,
  UserRepositoryType,
  RolesRepositoryType,
  ISubscriptionRepository,
  SubscriptionRepositoryType
} from './contracts/repositories'
import { AuthorRepository } from './repositories/author'
import { RolesRepository } from './repositories/roles'
import { UserRepository } from './repositories/user'
import { SubscriptionRepository } from './repositories/subscription'
import { resources } from './resources'
import { routesFactory } from './routes'
import { LogRepository } from '@module/users/repositories/logs'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@AppModule({
  name: 'users',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  providers: (config: IConfiguration) => {
    return (bind: Interfaces.Bind) => {
      bind<IUserRepository>(UserRepositoryType).to(UserRepository)
      bind<IRolesRepository>(RolesRepositoryType).to(RolesRepository)
      bind<IAuthorRepository>(AuthorRepositoryType).to(AuthorRepository)
      bind<ISubscriptionRepository>(SubscriptionRepositoryType).to(SubscriptionRepository)
      bind<ILogsRepository>(LogRepositoryType).to(LogRepository)
    }
  },
  boot (container: Container) {
    const connector: IConnector = container.get(ApiConnectorType)
    connector.useResources(resources)
  },
  routes: routesFactory
})
export class UsersModule extends Module {}
