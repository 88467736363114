// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'

import { fileAdapterMap } from '../models/file.adapter'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export const directoryAdapterMap: MappingConfig = {
  id: 'id',
  createdAt: 'createdAt',
  directories: 'directories',
  elements: 'elements',
  files: {
    type: MappingTypes.Adapter,
    map: fileAdapterMap,
    value: 'files'
  },
  name: 'name',
  parent: 'parent',
  nodes: 'nodes', // tmp
  updatedAt: 'updatedAt'
}
