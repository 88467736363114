// Copyright © 2021 Move Closer

import { AnyModule, DeMarkedTokenizerError } from '@d24/modules'
import { DeMarked } from '@/shared/helpers/demarked'

import { ValidationResult } from '../MarkdownEditor.contracts'

/**
 * Determines whether the passed-in object is an instance of the `DeMarkedTokenizerError`.
 *
 * @param e - The object to test.
 *
 * @typeGuard
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
const isTokenizerError = (e: unknown): e is DeMarkedTokenizerError => {
  return e instanceof DeMarkedTokenizerError
}

/**
 * Checks if the passed-in input can be converted from MD to HTML.
 *
 * @param input - The Markdown string to validate.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const checkInputValidity = (input: string): ValidationResult => {
  let modules: AnyModule[]
  try {
    modules = DeMarked.convertMDToObjects(input) as AnyModule[]
  } catch (e) {
    if (isTokenizerError(e)) {
      return { isValid: false, error: e }
    } else {
      console.warn(e)
    }
    return { isValid: false }
  }

  try {
    DeMarked.convertObjectsToHTML(modules)
  } catch (e) {
    if (isTokenizerError(e)) {
      return { isValid: false, error: e }
    } else {
      console.warn(e)
    }
    return { isValid: false }
  }

  return { isValid: true }
}
