<!-- Copyright © 2021 Move Closer -->

<template>
  <BaseSection>
    <template slot="heading">
      {{ $t('components.MarkdownEditorGuideModal.sections.heading.heading') }}
    </template>

    <pre>
# {{ $t('components.MarkdownEditorGuideModal.sections.heading.heading-level-1') }}
## {{ $t('components.MarkdownEditorGuideModal.sections.heading.heading-level-2') }}
### {{ $t('components.MarkdownEditorGuideModal.sections.heading.heading-level-3') }}
#### {{ $t('components.MarkdownEditorGuideModal.sections.heading.heading-level-4') }}
##### {{ $t('components.MarkdownEditorGuideModal.sections.heading.heading-level-5') }}
###### {{ $t('components.MarkdownEditorGuideModal.sections.heading.heading-level-6') }}</pre>
  </BaseSection>
</template>

<script>
import BaseSection from './_base'
export default { components: { BaseSection } }
</script>
