// Copyright © 2021 Move Closer

import { Container, RoutesFactory } from '@movecloser/front-core'

import LoginPage from './views/LoginPage.vue'
import ForgotPassword from './views/ForgotPassword.vue'
import MyProfileView from './views/MyProfileView.vue'
import ResetPassword from './views/ResetPassword.vue'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const routesFactory: RoutesFactory = (container: Container) => {
  return [
    {
      path: '/access/login',
      name: 'login',
      component: LoginPage,
      meta: {
        layout: 'FullScreenLayout',
        requiresAuth: false,
        noSiteId: true
      }
    },
    {
      path: '/access/forgot-password',
      name: 'forgotPassword',
      component: ForgotPassword,
      meta: {
        layout: 'FullScreenLayout',
        requiresAuth: false,
        noSiteId: true
      }
    },
    {
      path: '/access/reset-password/:token',
      name: 'resetPassword',
      component: ResetPassword,
      meta: {
        layout: 'FullScreenLayout',
        requiresAuth: false,
        noSiteId: true
      }
    },
    {
      path: '/me',
      name: 'myProfileView',
      component: MyProfileView,
      meta: {
        requiresAuth: false,
        noSiteId: true
      }
    }
  ]
}
