// Copyright © 2021 Move Closer

import { AbstractIntention } from '@movecloser/front-core'

// import { SneakPeekData } from '../contracts'

export interface ChangeItemsOrderIntentionPayload {
  // items: SneakPeekData[]
  id: number
  position: number
}

export class ChangeItemsOrderIntention extends AbstractIntention<ChangeItemsOrderIntentionPayload> {
  protected map = {
    // items: 'items'
    id: 'id',
    position: 'position'
  }
}
