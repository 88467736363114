// Copyright © 2022 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'
import { authorAdapterMap } from '@module/content/models/author.adapter'

export const formAdapterMap: MappingConfig = {
  author: {
    type: MappingTypes.Adapter,
    map: authorAdapterMap,
    value: 'author'
  },
  createdAt: 'createdAt',
  id: 'id',
  maEventName: 'maEventName',
  numberOfEntries: 'numberOfEntries',
  title: 'title',
  type: 'type',
  status: 'status',
  uuid: 'uuid'
}
