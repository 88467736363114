


import { AnyObject } from '@d24/modules'
import { Component, Vue } from 'vue-property-decorator'

@Component({ name: 'ConfigForm' })
export class ConfigForm extends Vue {
  /**
   * Default payload value for settings
   */
  static defaultPayload (): AnyObject {
    return {}
  }
}

export default ConfigForm
