




































































import { Component, Prop, Ref } from 'vue-property-decorator'
import { DashmixIconName } from '@d24/modules'
import { IModal, ModalType } from '@movecloser/front-core'
import jspreadsheet, { JSpreadsheetElement, Options } from 'jspreadsheet-ce'
import 'jspreadsheet-ce/dist/jexcel.css'
import 'jsuites/dist/jsuites.css'

import { AbstractModal } from '@component/Modal'
import { Inject } from '@plugin/inversify'

import { AddDatasetModalPayload } from '../contracts'

@Component<AddDatasetModal>({
  name: 'AddDatasetModal',

  created () {
    const { initialData } = this.payload

    if (initialData) {
      this.name = initialData.name
      this.note = initialData.note
      this.spreadsheetOptions.data = initialData.data
    } else {
      this.spreadsheetOptions.minDimensions = [15, 10]
    }
  },
  mounted () {
    (document.querySelector('.modal-content') as HTMLDivElement).style.overflow = 'visible'

    this.initSpreadsheet()
  }
})
class AddDatasetModal extends AbstractModal {
  @Ref('spreadSheetEl')
  private readonly spreadSheetEl!: HTMLDivElement

  @Ref('spreadSheetScroll')
  private readonly spreadSheetScroll!: HTMLDivElement

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Prop({
    type: Object,
    required: true
  })
  protected payload!: AddDatasetModalPayload

  protected readonly DashmixIconName = DashmixIconName

  protected name: string = ''

  protected note: string = ''

  protected spreadsheet!: JSpreadsheetElement

  public spreadsheetOptions: Options = {
    data: [[]],
    csvFileName: `cms_table_${new Date().getTime()}`,
    text: {
      copy: this.$t('tables.actions.copy'),
      paste: this.$t('tables.actions.paste'),
      saveAs: this.$t('tables.actions.saveAs'),
      insertANewColumnBefore: this.$t('tables.actions.insertANewColumnBefore'),
      insertANewColumnAfter: this.$t('tables.actions.insertANewColumnAfter'),
      deleteSelectedColumns: this.$t('tables.actions.deleteSelectedColumns'),
      renameThisColumn: this.$t('tables.actions.renameThisColumn'),
      insertANewRowBefore: this.$t('tables.actions.insertANewRowBefore'),
      insertANewRowAfter: this.$t('tables.actions.insertANewRowAfter'),
      deleteSelectedRows: this.$t('tables.actions.deleteSelectedRows'),
      orderAscending: this.$t('tables.actions.orderAscending'),
      orderDescending: this.$t('tables.actions.orderDescending')
    }
  }

  public close (): void {
    this.payload.onClose()
    super.close()
  }

  protected initSpreadsheet (): void {
    this.spreadsheet = jspreadsheet(this.spreadSheetEl, this.spreadsheetOptions)
  }

  /**
   * Methods using spreadsheet callbacks
   */
  public undo (): void {
    this.spreadsheet.undo()
  }

  public redo (): void {
    this.spreadsheet.redo()
  }

  public copy (): void {
    this.spreadsheet.copy()
  }

  public download (): void {
    this.spreadsheet.download(false)
  }

  public addRow (): void {
    this.spreadsheet.insertRow()
    this.spreadSheetScroll.scrollBy(0, this.spreadSheetScroll.scrollHeight)
  }

  public addColumn (): void {
    this.spreadsheet.insertColumn(null)
    this.spreadSheetScroll.scrollBy(this.spreadSheetScroll.scrollWidth, 0)
  }

  /**
   * Submit changes
   */
  protected submit (): void {
    this.payload.onConfirm({
      data: this.spreadsheet.getData(),
      name: this.name,
      note: this.note
    })

    this.modalConnector.close()
  }
}

export default AddDatasetModal
