





































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { DashmixIconName } from '@d24/modules'
import { Fragment } from 'vue-fragment'

import { Inject } from '@plugin/inversify'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { SetData } from '../contracts'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'SetTableRow',
  components: { Fragment }
})
export class SetTableRow extends Mixins<IUserAware>(UserAware) {
  @Prop({ type: Object, required: true })
  protected data!: SetData

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public Icons = DashmixIconName
}

export default SetTableRow
