
































































































import { Component, InjectReactive, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  DashmixIcon,
  DashmixIconName,
  DashmixTextArea,
  DashmixTheme,
  EmbedModuleForm, EmbedVersion,
  HasDriver,
  Identifier,
  ModuleDriver
} from '@d24/modules'
import { AnyObject, DateTimeType, IDateTime, IModal, ModalType, ResourceActionFailed } from '@movecloser/front-core'
import { Inject } from '@plugin/inversify'

import { Alert, AlertTheme } from '@component/Alert'
import { Clock } from '@component/Clock/Clock'
import { ConfirmModalPayload } from '@component/ConfirmModal'
import { DeMarked } from '@/shared/helpers/demarked'
import { Figure } from '@component/Figure/Figure'
import { FormSelect } from '@component/form/Select/Select'
import { IRelatedService, RelatedServiceType } from '@service/related'
import { MarkdownEditor } from '@component/MarkdownEditor'
import { MINI_SIMPLEMDE_CONFIG } from '@component/MarkdownEditor/MarkdownEditor.config'
import { Modals } from '@/config/modals'
import { Popover } from '@component/Popovers'

import { defaultDateFormat } from '../helpers/formatting'
import { CreateFeedEntryIntention, CreateFeedEntryPayload } from '../intentions/CreateFeedEntryIntention'
import { FeedEntriesListItem } from '../views/feeds/EditFeed.vue'
import { FeedEntriesRepositoryType, FeedEntryData, IFeedEntriesRepository } from '../contracts'
import { FeedEntryRelatedPicker } from '../components/FeedEntryRelatedPicker.vue'
import { UpdateFeedEntryIntention, UpdateFeedEntryPayload } from '../intentions/UpdateFeedEntryIntention'

export enum FeedEntryFormMode {
  Create = 'create',
  Edit = 'edit',
  View = 'view'
}

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Component({
  name: 'FeedEntry',
  components: {
    Alert,
    Clock,
    DashmixIcon,
    DashmixTextArea,
    EmbedModuleForm,
    FeedEntryRelatedPicker,
    Figure,
    FormSelect,
    MarkdownEditor,
    Popover
  }
})
class FeedEntry extends Vue {
  @Prop({ type: Number, required: false })
  public entryId?: Identifier

  @Prop({ type: Boolean, required: false })
  public hasBeenDeleted!: boolean

  @Prop({ type: Number, required: true })
  public index!: number

  @Prop({ type: Object, required: false, default: null })
  private initValues!: FeedEntriesListItem | null

  @Prop({ type: String, required: true })
  public initMode!: FeedEntryFormMode

  @InjectReactive('feedEnded')
  private feedEnded!: boolean

  @InjectReactive('feedId')
  private feedId!: Identifier

  @InjectReactive('isFeedEditable')
  public isFeedEditable!: boolean

  @Inject(DateTimeType)
  private dateTime!: IDateTime

  @Inject(FeedEntriesRepositoryType)
  private feedEntriesRepository!: IFeedEntriesRepository

  @Inject(ModalType)
  private modalConnector!: IModal

  @Inject(RelatedServiceType)
  private relatedService!: IRelatedService

  /**
   * Helpers
   */
  public alertTheme: AlertTheme = AlertTheme.Danger
  public Icon = DashmixIconName
  public mdeConfig = MINI_SIMPLEMDE_CONFIG

  /**
   * State
   */
  protected errors: string | null = null
  public hasErrors: boolean = false
  public isDeleting: boolean = false
  public isModelValid: boolean = true
  public isSending: boolean = false
  public mode: FeedEntryFormMode | null = null
  public payload: FeedEntryData = { content: '', createdAt: '' } as FeedEntryData

  /**
   * Getters
   */
  public get createdAt (): string {
    return this.initValues?.createdAt
      ? this.dateTime.parseToFormat(this.initValues.createdAt, defaultDateFormat)
      : this.dateTime.nowToFormat(defaultDateFormat)
  }

  public get deletedAt (): string {
    if (this.initValues?.deletedAt) {
      return this.dateTime.parseToFormat(this.initValues.deletedAt, defaultDateFormat)
    } else {
      return this.dateTime.nowToFormat(defaultDateFormat)
    }
  }

  public get hasEmbedContent (): boolean {
    return !!(this.payload.embed && Object.keys(this.payload.embed).length > 0)
  }

  public get isCreateMode (): boolean {
    return this.mode === FeedEntryFormMode.Create
  }

  public get isEditMode (): boolean {
    return this.mode === FeedEntryFormMode.Edit
  }

  public get isFirst (): boolean {
    return this.index === 0
  }

  public get isViewMode (): boolean {
    return this.mode === FeedEntryFormMode.View
  }

  public get parsedMdeContent (): string {
    return DeMarked.convertObjectsToHTML(
        DeMarked.convertMDToObjects(this.payload.content) as HasDriver<ModuleDriver.Paragraph>[]
    )
  }

  public get shouldShowDeleteEntryButton (): boolean {
    return !this.hasBeenDeleted && !this.isFirst && this.isFeedEditable
  }

  public get shouldShowCreatedAt (): boolean {
    return this.isFirst ? this.isFeedEditable : true
  }

  public get shouldHidePicker (): boolean {
    return this.isFirst && !this.isFeedEditable
  }

  public get shouldShowLockedInfo (): boolean {
    return this.isCreateMode && !this.isFeedEditable
  }

  public get shouldShowMarkdownEditor (): boolean {
    return !this.hasBeenDeleted && this.isFeedEditable && !this.isViewMode && !this.feedEnded
  }

  public get shouldShowSeparator (): boolean {
    return this.isFirst
  }

  public get shouldShowSubmitButton (): boolean {
    return !this.hasBeenDeleted && this.isFeedEditable && !this.isViewMode
  }

  public toggleEditMode () {
    switch (this.mode) {
      case FeedEntryFormMode.Edit:
        this.mode = FeedEntryFormMode.View
        break
      case FeedEntryFormMode.View:
        this.mode = FeedEntryFormMode.Edit
        break
    }
  }

  created () {
    if (this.initValues) {
      this.payload = this.initValues as FeedEntryData
    }
    this.mode = this.initMode
  }

  /**
   * CRUD Operations on entries
   */
  private async createEntry () {
    const intention = new CreateFeedEntryIntention(this.getIntention()).toModel()
    const id = await this.feedEntriesRepository
      .create(this.feedId, new CreateFeedEntryIntention(this.getIntention()))
    this.$emit('create:entry', { ...intention, id })
  }

  public async deleteEntry () {
    const modalPayload: ConfirmModalPayload = {
      content: {
        buttonLabel: String(this.$t('atoms.delete')),
        contentText: String(this.$t('content.feeds.items.delete_modal.text')),
        header: String(this.$t('content.feeds.items.delete_modal.header', { date: this.createdAt })),
        theme: DashmixTheme.Danger
      },
      onConfirm: () => this.continueDeletion()
    }

    this.modalConnector.open(Modals.Confirm, modalPayload)
  }

  public async continueDeletion () {
    if (!this.entryId) {
      throw new Error('Missing entry id')
    }
    this.isDeleting = true
    try {
      await this.feedEntriesRepository.delete(this.feedId, this.entryId)
      this.$emit('delete:entry', this.index)
      this.modalConnector.close()
    } catch (e) {
      this.modalConnector.close()
      this.errors = e.payload && e.message ? e.message : ''
      this.hasErrors = true
    } finally {
      this.isDeleting = false
      setTimeout(() => { this.hasErrors = false }, 10000)
    }
  }

  private getIntention (): CreateFeedEntryPayload | UpdateFeedEntryPayload {
    const payload: CreateFeedEntryPayload | UpdateFeedEntryPayload = {
      content: this.payload.content
    }

    if (this.payload.image) {
      payload.image = this.payload.image
    }

    if (this.payload.gallery) {
      payload.gallery = this.payload.gallery
    }

    if (this.hasEmbedContent) {
      payload.embed = this.payload.embed
    }

    return payload
  }

  private async updateEntry () {
    if (!this.entryId) {
      throw new Error('Missing entry id')
    }
    await this.feedEntriesRepository
      .update(this.feedId, this.entryId, new UpdateFeedEntryIntention(this.getIntention()))

    this.toggleEditMode()
  }

  public async save () {
    this.isSending = true
    try {
      switch (this.mode) {
        case FeedEntryFormMode.Create:
          await this.createEntry()
          break
        case FeedEntryFormMode.Edit:
          await this.updateEntry()
          break
      }
    } catch (e) {
      this.errors = e.payload && e.message ? e.message : ''
    } finally {
      this.isSending = false
    }
  }

  /**
   * Markdown editor
   */
  public onEditorValidInput () {
    this.isModelValid = true
  }

  public onEditorInvalidInput () {
    this.isModelValid = false
  }
}

export default FeedEntry
