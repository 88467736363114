// Copyright © 2022 Move Closer

import { Model } from '@movecloser/front-core'

import { FormData } from '../contracts/models'

export class Form extends Model<FormData> {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'author',
      'createdAt',
      'id',
      'maEventName',
      'numberOfEntries',
      'type',
      'title',
      'status',
      'uuid'
    ]
  }
}
