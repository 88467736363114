// Copyright © 2021 Move Closer

import { DashmixIconName, DashmixTheme, TableHead, TableSearchBarAction } from '@d24/modules'

import { PropertyItem } from '@/shared/contracts/content'

import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'

import { canDeleteContents, canEditContents, canViewContents } from '../guards'
import { ContentModel } from '../contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const articleOptions: PropertyItem[] = [
  {
    key: 'paywall',
    translationKey: 'content.article.propertiesList.paywall'
  },
  {
    key: 'sponsored',
    translationKey: 'content.article.propertiesList.sponsored'
  },
  {
    key: 'ads',
    translationKey: 'content.article.propertiesList.ads'
  },
  {
    key: 'adsAdX',
    translationKey: 'content.article.propertiesList.adsAdX'
  },
  {
    key: 'adsAdXAdSense',
    translationKey: 'content.article.propertiesList.adsAdXAdSense'
  },
  {
    key: 'comments',
    translationKey: 'content.article.propertiesList.allowComments'
  },
  {
    key: 'lockCommenting',
    translationKey: 'content.article.propertiesList.lockCommenting'
  },
  {
    key: 'hideAuthor',
    translationKey: 'content.article.propertiesList.hideAuthor'
  },
  {
    key: 'hidePublicationDate',
    translationKey: 'content.article.propertiesList.hidePublicationDate'
  }
]

export const searchTesterTableHead: TableHead = [
  {
    column: 'title',
    label: 'Tytuł',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'explanation',
    label: 'Explanation',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'searchFields',
    label: 'Search Fields',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'publishedDate',
    label: 'Opublikowano',
    sortable: true,
    isSorted: false,
    width: '0.5fr'
  }
]

export const articleTableHead: TableHead = [
  {
    column: 'title',
    label: 'Tytuł',
    sortable: true,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'author',
    label: 'Autor',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'editors',
    label: 'Edytorzy',
    sortable: false,
    isSorted: false,
    width: '0.70fr'
  },
  {
    column: 'category',
    label: 'Kategoria / Etykieta',
    sortable: false,
    isSorted: false,
    width: '0.80fr'
  },
  {
    column: 'status',
    label: 'Status',
    sortable: true,
    isSorted: false,
    width: '110px'
  },
  {
    column: 'createdDate',
    label: 'Utworzono',
    sortable: true,
    isSorted: false,
    width: '0.5fr'
  },
  {
    column: 'publishedDate',
    label: 'Opublikowano',
    sortable: true,
    isSorted: false,
    width: '0.5fr'
  },
  {
    column: 'modificationDate',
    label: 'Modyfikowano',
    sortable: true,
    isSorted: false,
    width: '0.6fr'
  }
]

export const articleTableHeadShortened: TableHead = [
  {
    column: 'title',
    label: 'Tytuł',
    sortable: false,
    isSorted: false,
    width: '3.4fr'
  },
  {
    column: 'status',
    label: 'Status',
    sortable: false,
    isSorted: false,
    width: '120px'
  }
]

export enum ArticleActions {
  Ads = 'advertisement',
  Edit = 'edit',
  Delete = 'delete',
  Paywall = 'paywall',
  Preview = 'preview',
  Publish = 'publish',
  Author = 'author',
  Comments = 'comments'
}

export const articlesRowActionsFactory = (domain: Domain, user: UserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canViewContents(domain, user)) {
    actions.push(
      {
        label: 'Podgląd',
        icon: DashmixIconName.Eye,
        isBulkAction: false,
        action: ArticleActions.Preview,
        guard: (data: unknown): boolean => {
          const model = data as ContentModel
          return !!model.url
        }
      }
    )
  }

  if (canEditContents(domain, user)) {
    actions.push(
      ...[
        {
          label: 'Edytuj',
          icon: DashmixIconName.PenSolid,
          isBulkAction: false,
          action: ArticleActions.Edit
        },
        {
          label: 'Wyłącz reklamy',
          icon: DashmixIconName.BanSolid,
          isBulkAction: true,
          action: ArticleActions.Ads
        },
        {
          label: 'Wyłącz paywall',
          icon: DashmixIconName.DollySolid,
          isBulkAction: true,
          action: ArticleActions.Paywall
        },
        {
          label: 'Ukryj autora',
          icon: DashmixIconName.EyeSlash,
          isBulkAction: true,
          action: ArticleActions.Author
        },
        {
          label: 'Wyłącz komentarze',
          icon: DashmixIconName.CommentSlashSolid,
          isBulkAction: true,
          action: ArticleActions.Comments
        }
      ]
    )
  }

  if (canDeleteContents(domain, user)) {
    actions.push(
      {
        label: 'Usuń',
        icon: DashmixIconName.TrashAltSolid,
        needsDoubleClick: true,
        isBulkAction: true,
        action: ArticleActions.Delete,
        theme: DashmixTheme.Danger
      }
    )
  }

  return actions
}
