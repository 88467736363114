// Copyright © 2021 Move Closer

import { MappingConfig } from '@movecloser/front-core'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const variantStatusAdapterMap: MappingConfig = {
  deletedAt: 'deletedAt',
  editorId: 'editorId',
  editorFullname: 'editorFullname',
  status: 'status',
  updatedAt: 'updatedAt'
}
