


























import { Component, Prop, Vue } from 'vue-property-decorator'
import { ResetListItemsModalPayload } from '../../content/contracts'

@Component<ResetListItemsModal>({ name: 'ResetListItemsModal' })
class ResetListItemsModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: ResetListItemsModalPayload

  public setName: string = ''
  public resetLockedItems: boolean = false

  public close () {
    this.payload.onClose()
  }

  public submit () {
    this.payload.onConfirm(this.resetLockedItems)
  }
}

export default ResetListItemsModal
