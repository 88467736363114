// Copyright © 2021 Move Closer

import { DashmixIconName, TableHead, TableSearchBarAction } from '@d24/modules'
import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'

import { canDeletePromotion, canEditPromotion } from '../guards'

export const promotionsTableHead: TableHead = [
  {
    column: 'name',
    label: 'Nazwa',
    sortable: true,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'status',
    label: 'Status',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  }
]

export enum PromotionsActions {
  Delete = 'delete',
  Edit = 'edit'
}

export const promotionsRowActionsFactory = (domain: Domain, user: UserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canEditPromotion(domain, user)) {
    actions.push(
      {
        label: 'Edytuj',
        icon: DashmixIconName.PenSolid,
        isBulkAction: false,
        action: PromotionsActions.Edit
      }
    )
  }

  if (canDeletePromotion(domain, user)) {
    actions.push(
      {
        label: 'Usuń',
        icon: DashmixIconName.TrashAltSolid,
        isBulkAction: true,
        action: PromotionsActions.Delete
      }
    )
  }

  return actions
}
