
















import { Component, Prop, Vue } from 'vue-property-decorator'
import { HandleDirective } from 'vue-slicksort'
import { ContentData } from '@module/content/contracts'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'SortContentModalItem',
  directives: { handle: HandleDirective }
})
export class SortContentModalItem extends Vue {
  @Prop({ type: Number, required: true })
  public position!: number

  @Prop({ type: Object, required: true })
  public item!: ContentData
}

export default SortContentModalItem
