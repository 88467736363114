







































import { Component, Vue } from 'vue-property-decorator'
import { DateTimeType, IDateTime, IModal, ModalType } from '@movecloser/front-core'
import { DashmixIconName, Identifier } from '@d24/modules'

import { Inject } from '@plugin/inversify'
import { Loader } from '@component/Loader'
import { Modals } from '@/config/modals'

import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'

import {
  ContentModel,
  ContentRepositoryType,
  ContentType,
  IContentRepository
} from '../contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<HomePageWidget>({
  name: 'HomePageWidget',
  components: { Loader },

  created (): void {
    this.loadList()
  }
})
export class HomePageWidget extends Vue {
  @Inject(ContentRepositoryType)
  protected readonly contentRepository!: IContentRepository

  @Inject(DateTimeType)
  protected readonly dateTime!: IDateTime

  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  @Inject(SiteResolverType)
  private readonly siteResolver!: ISiteResolver

  public data: ContentModel | null = null
  public icon = DashmixIconName
  public isLoading: boolean = false

  public routeToEditPage (): void {
    const siteModel = this.siteResolver.getSite()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const parentId = siteModel?.rootContent!

    this.$router.push({ name: 'content.pages.content', params: { id: parentId.toString() } })
  }

  public showSets (): void {
    this.modalConnector.open(Modals.ContentLists, {
      siteId: this.siteResolver.getSite()?.id,
      slug: '/',
      title: this.$t('dashboard.homePageWidget.label')
    }, {
      closableWithOutsideClick: true
    })
  }

  private loadList (): void {
    this.isLoading = true

    let parentId: Identifier = 0
    const siteModel = this.siteResolver.getSite()
    parentId = siteModel?.rootContent!

    this.contentRepository.loadNode([ContentType.Page, ContentType.SmartPage], parentId, {}).then((collection) => {
      this.isLoading = false
      this.data = collection
    })
  }
}

export default HomePageWidget
