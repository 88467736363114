// Copyright © 2021 Move Closer

import { AnyObject, ModalPayload } from '@movecloser/front-core'
import { DashmixTheme, Related, RelatedType, TableRowElement } from '@d24/modules'

import { Identifier } from '@/shared/contracts/data'
import { IRelatedService } from '@service/related'

import {
  AcceptanceModel,
  CommentModel,
  ContentModel,
  ContentStatus,
  ContentType,
  Meta,
  SneakPeakModel,
  VariantModel
} from './models'
import { VariantStatusPayload } from '../services/variantStatus'

export interface AcceptanceTableRowElement extends TableRowElement {
  data: AcceptanceModel
}

export interface AddSetItemModalPayload {
  onClose: () => void
  onConfirm: (id: number) => void
}

export interface AddDatasetModalPayload {
  initialData: {
    name: string
    data: []
    note: string
  }
  onClose: () => void
  onConfirm: (table: object) => void
}

export interface ConfirmAcceptanceModalContent {
  header: string
  contentText: string
  contentTitle?: string
  theme?: DashmixTheme
  buttonLabel: string
}

export enum ConfirmAcceptanceModalMode {
  Accept = 'accept',
  Edit = 'edit',
  Reject = 'reject',
  Return = 'return'
}

export interface ConfirmAcceptanceModalPayload {
  content: ConfirmAcceptanceModalContent
  mode: ConfirmAcceptanceModalMode
  onConfirm: (note?: string) => void
  textArea?: boolean
}

export interface SearchTableRowElement extends TableRowElement {
  data: SneakPeakModel
}

export interface ContentTableRowElement extends TableRowElement {
  data: ContentModel
}

export interface CommentTableRowElement extends TableRowElement {
  data: CommentModel
}

export interface ContentSortingPayload extends ModalPayload {
  collection: ContentModel[]
  parent: ContentModel
}

export interface ContentVariantStatusGroup {
  group: ContentStatus
  includes: ContentStatus[]
}

export interface ContentVariantStatusGroups {
  [key: string]: ContentVariantStatusGroup
}

export interface ContentVariantTabEvent {
  action: ContentVariantTabToggle
  id: Identifier
}

export enum ContentVariantTabToggle {
  Pin = 'pin',
  Unpin = 'unpin'
}

export enum FormMode {
  Article = 'article',
  Page = 'page',
  IndexPage = 'indexPage',
  Picker = 'picker'
}

export interface GeneratedNewsletterModalPayload {
  setId: string
  onClose: () => void
  onConfirm: () => void
}

export interface LockConflictModalPayload {
  avatar: string | null
  contentType: ContentType
  lockedAt: string
  onPreview: () => void
  onClose: () => void
  username: string
}

export interface MetaAddonModalPayload {
  disabled: boolean
  intersectedData?: PickerIntersectedPayload<any, RelatedType.File, Identifier>
  meta: Meta
  onChange: (meta: Meta) => void
  onSubmit: () => void
  relatedService: IRelatedService
}

export interface RemoveConflictModalPayload {
  contentType: ContentType
  lockedAt: string
  onClose: () => void
  fullName: string
}

export interface PageListsModalPayload {
  siteId: Identifier
  slug: string
  title: string
}

export interface PickerIntersectedPayload<T, Type, Value, Params extends AnyObject = AnyObject> {
  params: Params
  selected: (data: Related<Type, Value> | Related<Type, Value>[]) => void
}

export interface PickerPayload<T, Type, Value, Params extends AnyObject = AnyObject> {
  config?: PickerConfig
  multiple?: boolean
  onClose?: () => void
  onSelection: (data: Related<Type, Value> | Related<Type, Value>[]) => void
  params?: Params
  selected?: Related<Type, Value> | Related<Type, Value>[]
}

export interface PickerConfig {
  [key: string]: any
}

export interface ResetListItemsModalPayload {
  list: string
  onClose: () => void
  onConfirm: (resetLockedItems: boolean) => void
}

export type OnDirtyCallback = (isDirty: boolean) => void
export type OnVariantChangeCallback = (changed: VariantModel) => void
export type OnMarkdownCallback = (state: boolean) => void

export interface PreventLoosingDataModalPayload {
  onClose: () => void
  onConfirm: () => void
}

export interface SaveVariantEvent {
  postSave: SaveVariantMode
}

export enum SaveVariantMode {
  Acceptance = 'acceptance',
  None = 'none',
  Publication = 'publication'
}

export interface ScrollHandleConfig {
  threshold: number
  throttleInterval: number
}

export interface TakeOverModalPayload {
  newStatus: VariantStatusPayload
  onAccept: () => void
  onRefuse: () => void
}

export interface VariantPreviewDecisionModalPayload {
  cancel: () => void
  generate: () => void
  saveAndGenerate: () => void
}

export type VariantsServices = Record<Identifier, IRelatedService>
