// Copyright © 2021 Move Closer

import { AbstractIntention } from '@movecloser/front-core'

import { IEditFileIntention } from '../contracts/intentions'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export class EditFileIntention extends AbstractIntention<IEditFileIntention> {
  protected map = {
    name: 'name',
    title: 'title',
    author: 'author',
    alt: 'alt',
    caption: 'caption',
    updatedAt: 'updatedAt'
  }
}
