













































import { Component, Watch } from 'vue-property-decorator'
import { Collection, ICollection } from '@movecloser/front-core'

import { BreadcrumbsProps, DashmixIconName } from '@d24/modules'
import { Inject } from '@plugin/inversify'

import { EditModeLayout } from '@component/EditModeLayout'
import { ScrollHandle } from '@/shared/directives/scroll'

import { defaultScrollConfig } from '@module/content/components/SetDraggableList.vue'
import { ScrollHandleConfig } from '@module/content/contracts'

import AbstractEditRole from '../views/AbstractEditRole.vue'
import { IUserRepository, UserRepositoryType } from '../contracts/repositories'
import { RoleFormMode } from '../contracts/components'
import RoleForm from '../components/RoleForm.vue'
import { UserModel } from '../contracts/models'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Component({
  name: 'EditRole',
  components: { EditModeLayout, RoleForm },
  directives: { ScrollHandle }
})
class EditRole extends AbstractEditRole {
  @Inject(UserRepositoryType)
  userRepository!: IUserRepository

  public icons = DashmixIconName
  public mode = RoleFormMode.Edit
  private page: number = 1
  private readonly perPage: number = 15
  public scrollConfig: ScrollHandleConfig = defaultScrollConfig
  private total: number = 15
  public users: ICollection<UserModel> | null = null

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('roles.list.title')}`,
          target: { name: 'users.roles.list' }
        },
        {
          label: `${this.$t('roles.form.edit.title', { name: this.name })}`,
          target: { name: 'users.roles.edit' }
        }
      ],
      root: {
        label: `${this.$t('root.views.root')}`,
        target: { name: 'root.dashboard' }
      }
    }
  }

  mounted () {
    this.loadRole()
    this.loadUsers()
  }

  public handleScroll () {
    this.page++
  }

  public handleUserClick (index: number) {
    if (!this.users) {
      throw new Error('No users found')
    }
    const user = this.users[index]

    this.$router.push({ name: 'users.roles.edit', params: { id: user.id.toString() } })
  }

  @Watch('page')
  public async loadUsers () {
    if (this.page * this.perPage > this.total) return

    this.isLoading = true
    const users = await this.userRepository.loadCollection(
      {
        page: String(this.page),
        perPage: String(this.perPage),
        scope: 'roles',
        roleId: this.$route.params.id
      }
    )

    if (users.length) {
      this.users = new Collection([...this.users || [], ...users])
      this.total = users.meta.total
    }

    this.isLoading = false
  }
}
export default EditRole
