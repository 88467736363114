// Copyright © 2021 Move Closer

import {
  ICollection,
  Injectable,
  IResponse,
  MappingConfig,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'
import { LogData, LogModel } from '@module/users/contracts/models'
import { ILogsRepository } from '@module/users/contracts/repositories'
import { Query } from '@/shared/contracts/query'
import { logAdapterMap } from '@module/users/models/log.adapter'
import { resolveFromStatus } from '@/shared/exceptions/connector-errors'
import { Log } from '@module/users/models/log'

@Injectable()
export class LogRepository extends Repository<LogData> implements ILogsRepository {
  protected useAdapter = true
  protected map: MappingConfig = logAdapterMap

  public async loadCollection (query: Query): Promise<ICollection<LogModel>> {
    const response: IResponse = await this.connector.call(
      'logs',
      'list',
      {},
      {
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Log,
      response.data.meta
    )
  }
}
