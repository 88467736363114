// Copyright © 2021 Move Closer

import { defineComponent, SetupContext } from '@vue/composition-api'
import { DashmixIconName, DashmixTheme, SizeMap } from '@d24/modules'

import { Typeahead } from '@component/Typeahead'

import {
  useFilterEditPopupDefinition,
  useFilterEditPopupDefinitionProps
} from './FilterEditPopupDefinition.hook'
import { FilterEditPopupDefinitionProps } from '../Filters.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const FilterEditPopupDefinition = defineComponent({
  name: 'FilterEditPopupDefinition',
  components: { Typeahead },
  props: useFilterEditPopupDefinitionProps,
  emits: ['onChange', 'onRemove'],

  setup (props: FilterEditPopupDefinitionProps, ctx: SetupContext) {
    return {
      ...useFilterEditPopupDefinition(props, ctx),
      icons: DashmixIconName,
      btnTheme: DashmixTheme,
      sizes: SizeMap
    }
  },
  template: `
    <div class="filter_badge__row" :class="{ '--enumerable' : isEnumerable || isSearchable }">
        <D24Select v-if="haveSelectOperator" name="Operator" :size="sizes.Small"
                   :options="operatorsOptions" :model.sync="definitionOperator" />

      <template v-if="isSearchable">
        <Typeahead name="Value"
                   :hints="dictionariesOptions()" :selected="selected()" :loading="loading"
                   :size="sizes.Small"
                   @selected="onSelect" @searched="onSearch" @deleted="onDelete" @cleared="onClear"
                   :isMulti="false" :taggable="false" clearable />
      </template>

        <template v-else-if="isEnumerable">
          <D24Select name="Value" :options="dictionariesOptions()" :model.sync="value" :size="sizes.Small" searchable />
        </template>

        <template v-else-if="isDate">
          <D24DateTimePicker class="filter_badge__date-picker"
                             :placeholder="$t('filters.datePlaceholder')" name="filter-value" :size="sizes.Small"
                             :model.sync="value" />
        </template>

      <template v-else>
        <D24Input :placeholder="$t('filters.placeholder')" name="filter-value" :size="sizes.Small" :model.sync="value" />
      </template>
      <D24Button class="btn-sm" :size="sizes.Small" :theme="btnTheme.Danger" variant="icon" :icon="icons.TrashSolid"
                 :disabled="index === 0" @click="onRemove" />
    </div>
  `
})
