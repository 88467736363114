





























import { Component, Prop, Vue } from 'vue-property-decorator'

import { TagModalPayload } from '@module/settings/contracts/components'
import { TagData, TagType } from '@module/settings/contracts'

@Component({
  name: 'TagModal'
})
export class TagModal extends Vue {
  @Prop({ type: Object, required: false })
  protected payload!: TagModalPayload

  public tagType: TagType = TagType.Label
  public newTag: TagData = {
    id: 0,
    name: '',
    pluralName: '',
    slug: '',
    useAsTagPage: {}
  }

  created () {
    if (this.payload) {
      this.newTag = { ...this.payload.tag }
    }
  }

  public isActive: boolean = false
  public isDisabled: boolean = false
  public model: string = ''

  protected close () {
    this.payload.onClose()
  }

  public toggleChangeSlug () {
    this.isActive = !this.isActive
  }

  protected submit () {
    this.isDisabled = true
    this.payload.onConfirm(this.newTag)
  }
}

export default TagModal
