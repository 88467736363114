


























import { Component, PropSync, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@d24/modules'

import { Alert, AlertTheme } from '@component/Alert'
import { FormInput } from '../../../shared/components/form/Input/Input'
import { MePayload } from '@module/auth/contracts/data'
import { IMeRepository, MeRepositoryType } from '@module/auth/contracts/repositories'
import { Inject } from '@plugin/inversify'

@Component({
  name: 'ChangePasswordForm',
  components: { Alert, FormInput }
})
export class ChangePasswordForm extends Vue {
  @PropSync('formPasswordData', { type: Object, required: true })
  public payload!: MePayload

  @Inject(MeRepositoryType)
  protected meRepository!: IMeRepository

  public alertTheme: AlertTheme = AlertTheme.Warning
  public errors: string = ''
  public hasErrors: boolean = false
  public formName: string = 'changePasswordForm'
  public icons = DashmixIconName
  public isSaving: boolean = false

  public changePassword () {
    this.isSaving = true

    this.meRepository.editPassword(this.payload.actual, this.payload.password, this.payload.passwordConfirmation).then(() => {
      this.isSaving = false
    }).catch(error => console.warn(error)
    ).finally(() => {
      this.isSaving = false
    })
  }

  public onPasswordSave () {
    this.changePassword()
  }
}

export default ChangePasswordForm
