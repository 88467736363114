// Copyright © 2021 Move Closer

import { MeModel, TokenModel } from './models'

export const AuthRepositoryType = Symbol.for('AuthRepository')

export interface IAuthRepository {
  forgotPassword (email: string): Promise<void>

  login (email: string, password: string, grantType?: string): Promise<TokenModel>

  logout (): Promise<void>

  refresh (token: string): Promise<TokenModel>

  resetPassword (token: string, email: string, password: string, passwordConfirmation: string): Promise<void>
}

export interface IMeRepository {
  editAvatar (avatar: File): Promise<void>

  editBasicInformation (nickname?: string, firstName?: string, lastName?: string): Promise<void>

  editPassword (actual: string, password: string, passwordConfirmation: string): Promise<void>

  loadMeProfile (): Promise<MeModel>
}

export const MeRepositoryType = Symbol.for('IMeRepository')
