import { MappingConfig, MappingTypes } from '@movecloser/front-core'
import { userAdapterMap } from '@module/auth/models/user.adapter'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export const logAdapterMap: MappingConfig = {
  browserFingerprint: 'browserFingerprint',
  commitedAt: 'commitedAt',
  id: 'id',
  message: 'message',
  remoteAddr: 'remoteAddr',
  type: 'type',
  user: {
    type: MappingTypes.Adapter,
    map: userAdapterMap,
    value: 'user'
  }
}
