



























































































import { Component, Vue } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'
import { cloneDeep, isEqual } from 'lodash'
import { BreadcrumbsProps, DashmixIconName } from '@d24/modules'

import { DashmixSwitch } from '@component/Switch/Switch'
import { FormInput, FormSelect } from '@component/form'
import { Identifier } from '@/shared/contracts/data'
import { Loader } from '@component/Loader'
import { ModelListHeader } from '@component/ModelListHeader/ModelListHeader'

import { NodeSelect } from '@module/content/components/NodeSelect.vue'
import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'

import { INavigation, INavigationRepository, NavigationData, NavigationDriver, NavigationItem, NavigationModel, NavigationRepositoryType } from '../contracts'
import { NavigationAutoForm } from '../components/NavigationAutoForm.vue'
import { NavigationManualForm } from '../components/NavigationManualForm.vue'
import { Navigation } from '../models/navigation'

@Component({
  name: 'NavigationEdit',
  components: {
    DashmixSwitch,
    FormInput,
    FormSelect,
    Loader,
    ModelListHeader,
    NavigationAutoForm,
    NavigationManualForm,
    NodeSelect
  }
})
export class NavigationEdit extends Vue {
  @Inject(NavigationRepositoryType)
  private navigationRepository!: INavigationRepository

  public NavigationDriver = NavigationDriver
  public Icons = DashmixIconName

  public driver: NavigationDriver = NavigationDriver.Manual
  public form = 'editNavigation'
  public from: Identifier | null = null
  public isActive: boolean = true
  public isLoading: boolean = false
  public isLocked: boolean = false
  public isSaving: boolean = false
  public links: NavigationItem[] = []
  public name: string = ''
  public navName: string = ''
  public slot: string = ''

  private navigation: NavigationModel | null = null
  private id: Identifier = Number(this.$route.params.id)
  private isEdit: boolean = !!this.id

  public driverOptions = [
    {
      label: this.$t('settings.navigation.driver.content'),
      value: NavigationDriver.Content
    },
    {
      label: this.$t('settings.navigation.driver.manual'),
      value: NavigationDriver.Manual
    }
  ]

  public slotOptions = [
    {
      label: this.$t('settings.navigation.slot.top'),
      value: 'top'
    },
    {
      label: this.$t('settings.navigation.slot.footerTop'),
      value: 'footerTop'
    },
    {
      label: this.$t('settings.navigation.slot.footerBottom'),
      value: 'footerBottom'
    },
    {
      label: this.$t('settings.navigation.slot.left'),
      value: 'left'
    },
    {
      label: this.$t('settings.navigation.slot.relatedSites'),
      value: 'relatedSites'
    },
    {
      label: this.$t('settings.navigation.slot.social'),
      value: 'social'
    }
  ]

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('settings.navigation.title')}`,
          target: { name: 'settings.navigation' }
        },
        {
          label: this.pageTitle,
          target: { name: 'settings.navigation.edit' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get isModified (): boolean {
    return this.driver !== this.navigation?.driver ||
      this.isActive !== this.navigation?.isActive ||
      this.name !== this.navigation?.name ||
      this.slot !== this.navigation?.slot ||
      this.from !== this.navigation?.from ||
      !isEqual(this.links, this.navigation?.links)
  }

  public get isReady (): boolean {
    return (this.driver === NavigationDriver.Content && !!this.from) || (this.driver === NavigationDriver.Manual && !!this.links)
  }

  public get pageTitle (): string {
    return this.isEdit
      ? `${this.$t('settings.navigation.editTitle', { name: this.navName })}`
      : `${this.$t('settings.navigation.createTitle')}`
  }

  mounted () {
    if (this.isEdit) {
      this.isLoading = true

      this.navigationRepository.get(this.id)
        .then(data => {
          this.navigation = data

          this.driver = data.driver
          this.isActive = data.isActive
          this.name = data.name
          this.slot = data.slot
          this.from = data.from
          this.links = data.links ? cloneDeep(data.links) : []

          this.navName = this.name
        })
        .catch(error => console.error(error))
        .finally(() => {
          this.isLoading = false
        })
    }
  }

  public lock (isLocked: boolean) {
    this.isLocked = isLocked
  }

  public submit () {
    let payload: Omit<NavigationData, 'id'> = {
      driver: this.driver,
      from: null,
      name: this.name,
      slot: this.slot,
      isActive: this.isActive
    }

    if (this.driver === NavigationDriver.Manual) {
      payload = {
        ...payload,
        links: this.links
      }
    } else if (this.from) {
      payload = {
        ...payload,
        from: this.from,
        links: []
      }
    }

    this.isSaving = true

    const request = this.isEdit
      ? this.navigationRepository.update(this.id, payload)
      : this.navigationRepository.create(payload)

    request
      .then(data => {
        this.updateNavigation()

        if (!this.isEdit && data.id) {
          this.isEdit = true
          this.id = data.id

          this.$router.push({
            name: 'settings.navigation.edit',
            params: { id: `${data.id}` }
          })
        }
      })
      .catch(error => console.info(error))
      .finally(() => {
        this.isSaving = false
      })
  }

  public toggleDriver (value: NavigationDriver) {
    this.driver = value
  }

  public updateFrom (from: Identifier, links: NavigationItem[]) {
    this.from = from
    this.updateLinks(links)
  }

  public updateLinks (links: NavigationItem[]) {
    this.links = links
  }

  public updateNavigation () {
    this.navigation = Navigation.hydrate<NavigationData, INavigation>({
      driver: this.driver,
      isActive: this.isActive,
      name: this.name,
      slot: this.slot,
      from: this.from,
      links: this.links
    })

    this.navName = this.name
  }
}

export default NavigationEdit
