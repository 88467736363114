


































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { DashmixTheme, SizeMap, TableHead, TableRowElement, TableSearchBarAction } from '@d24/modules'
import { IModal, ModalType } from '@movecloser/front-core'

import { DropdownActions } from '@/shared/contracts/content'
import { Inject } from '@/shared/plugins/inversify'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { Query } from '@/shared/contracts/query'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { ISubscriptionRepository, SubscriptionRepositoryType } from '../contracts/repositories'
import { Modals } from '@/config/modals'
import { TransactionData } from '../contracts/models'
import { TransactionsModalPayload } from '../contracts/components'
import { TransactionsListsActions, transactionsRowActionsFactory, transactionsTableHead } from '../maps/transactions'
import { TransactionTableRow } from '../components/TransactionTableRow.vue'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'TransactionModal',
  components: { InteractiveTable }
})
export class TransactionModal extends Mixins<IUserAware>(UserAware) {
  @Prop({ type: Object, required: true })
  public payload!: TransactionsModalPayload

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(SubscriptionRepositoryType)
  protected subscriptionRepository!: ISubscriptionRepository

  public actions: DropdownActions = {
    [TransactionsListsActions.GetDetails]: {
      callback: (data: unknown) => {
        this.onGetDetails((data as TransactionData).id)
      }
    }
  }

  public isLoading = false
  public rowComponent = TransactionTableRow
  public rowActions: TableSearchBarAction[] = []
  public showAll = false
  public tableData: TableRowElement[] =[]

  public tableHead: TableHead = transactionsTableHead.map(item => {
    return {
      ...item,
      label: `${this.$t(item.label)}`
    }
  })

  public themes = DashmixTheme

  public totalPages: number = 0
  public itemsTotal: number = 0

  public query: Query = {}

  mounted () {
    // TODO fix - see below
    (document.querySelector('.modal-content') as HTMLDivElement).style.overflow = 'visible'
    if (this.payload.query) {
      this.query = this.payload.query
    }

    if (this.payload.showAll) {
      this.showAll = this.payload.showAll
    }

    this.loadList(this.query)

    this.rowActions = transactionsRowActionsFactory(this.domain, this.user)
  }

  public onShowAll () {
    this.showAll = true

    if (this.query.page) {
      delete this.query.page
    }

    this.loadList(this.query)
  }

  public setQuery (query: Query) {
    if (JSON.stringify(this.query) === JSON.stringify(query)) {
      return
    }
    this.query = query
  }

  protected loadList (q: Query): void {
    this.isLoading = true

    const query = { ...q }

    if (!this.showAll) {
      query.periodId = this.payload.periodId
    }

    this.subscriptionRepository.loadTransactions(this.payload.subscriptionId, query).then(collection => {
      this.isLoading = false

      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: true,
          data: model
        }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.itemsTotal = collection.meta.total
    }).catch(error => {
      console.debug(error)
      this.isLoading = false
    })
  }

  protected onGetDetails (id: TransactionData['id']) {
    this.subscriptionRepository.getTransactionsDetails(id)
      .then(response => {
        this.modalConnector.close()

        this.modalConnector.open(Modals.TransactionDetailsModal, {
          details: response,
          goBackPayload: {
            periodId: this.payload.periodId,
            subscriptionId: this.payload.subscriptionId,
            query: this.query,
            showAll: this.payload.showAll
          }
        }, { size: SizeMap.Large })
      }).catch((e) => console.warn(e))
  }

  @Watch('query', { deep: true })
  protected onQueryChange (query: Query): void {
    this.loadList(query)
  }
}

export default TransactionModal
