// Copyright © 2021 Move Closer

import { Item } from '@component/TreeViewer'

import { ParentData } from '../contracts/models'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const mapNodeToParent = (nodes: Item[]): ParentData | null => {
  let parent: ParentData | null = null
  const nodesLength = nodes.length

  for (let i = 0; i < nodesLength; i++) {
    const prevParent: ParentData | null = parent

    parent = {
      id: Number(nodes[i].value),
      name: nodes[i].label,
      slug: nodes[i].slug, // FIXME: Looks like it has no slug.
      parent: prevParent
    }
  }

  return parent
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const mapOptionsToSelected = (options: any, allowed: string[]) => {
  const selected: string[] = []

  if (!options || !Object.keys(options).length) return selected

  for (const a of allowed) {
    if (a in options && options[a]) {
      selected.push(a)
    }
  }

  return selected
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const mapParentToNode = (parent: ParentData | null): Item[] => {
  const parentsSlugs = []

  let activeParent: ParentData | null = parent
  while (activeParent) {
    parentsSlugs.push({ value: activeParent.id, label: activeParent.name, slug: activeParent.slug })

    activeParent = activeParent.parent
  }
  return parentsSlugs.reverse()
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const mapParentToSlug = (init: ParentData | null): string => {
  const items: string[] = []

  let parent: ParentData | null = init
  while (parent) {
    if (parent.slug) {
      items.unshift(parent.slug)
    }
    parent = parent.parent
  }

  return items.length ? items.filter(i => i !== '' && i !== '/').join('/') : ''
}
