






























import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName, DashmixTheme } from '@d24/modules'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { Fragment } from 'vue-fragment'
import { Inject } from '@plugin/inversify'

import { TagData } from '../contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({
  name: 'TagListTableRow',
  components: { Fragment }
})
export class PromotionsTableRow extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  protected data!: TagData

  public icons = DashmixIconName

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public BadgeTheme = DashmixTheme
}

export default PromotionsTableRow
