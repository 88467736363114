// Copyright © 2021 Move Closer

import { Container, RoutesFactory } from '@movecloser/front-core'

import { Permission } from '@module/auth/contracts/permissions'

import EditSettings from './views/EditSettings.vue'
import FreeSubscriptionRules from './components/FreeSubscriptionRulesForm.vue'
import LayoutEdit from './views/EditLayout.vue'
import LayoutsList from './views/LayoutsList.vue'
import Navigation from './views/Navigation.vue'
import NavigationEdit from './views/NavigationEdit.vue'
import PromotionsList from './views/PromotionsList.vue'
import SettingList from './views/SettingList.vue'
import TagsList from './views/TagsList.vue'
import PromotionEdit from '@module/settings/views/PromotionEdit.vue'
import AdsXEdit from '@module/settings/views/AdsXEdit.vue'
import { TagsEdit } from '@module/settings/views/TagsEdit.vue'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const routesFactory: RoutesFactory = (container: Container) => {
  return [
    {
      path: '/ads/adx',
      name: 'ads.adx',
      component: AdsXEdit,
      meta: {
        permissions: [
          // Permission.EditSettings
        ]
      }
    },
    {
      path: '/settings/freeSubscriptionRules',
      name: 'freeSubscriptionRules',
      component: FreeSubscriptionRules
    },
    {
      path: '/settings/navigation/create',
      name: 'navigation.create',
      component: NavigationEdit,
      meta: {
        permissions: [
          Permission.CreateNavigation
        ]
      }
    },
    {
      path: '/settings/navigation/:id/edit',
      name: 'navigation.edit',
      component: NavigationEdit,
      meta: {
        permissions: [
          Permission.EditNavigation
        ]
      }
    },
    {
      path: '/settings/navigation',
      name: 'navigation',
      component: Navigation,
      meta: {
        permissions: [
          Permission.ViewNavigation
        ]
      }
    },
    {
      path: '/settings/layouts',
      name: 'layouts.list',
      component: LayoutsList,
      meta: {
        permissions: [
          Permission.ViewLayout
        ]
      }
    },
    {
      path: '/settings/layouts/create',
      name: 'layouts.create',
      component: LayoutEdit,
      meta: {
        permissions: [
          Permission.CreateLayout
        ]
      }
    },
    {
      path: '/settings/layouts/:id/edit',
      name: 'layouts.edit',
      component: LayoutEdit,
      meta: {
        permissions: [
          Permission.EditLayout
        ]
      }
    },
    {
      path: '/settings/tags',
      name: 'tags.list',
      component: TagsList,
      meta: {
        permissions: [
          Permission.ManageTags
        ]
      }
    },
    {
      path: '/settings/tags/create',
      name: 'tags.create',
      component: TagsEdit,
      meta: {
        permissions: [
          Permission.ManageTags
        ]
      }
    },
    {
      path: '/settings/tags/:id/edit',
      name: 'tags.edit',
      component: TagsEdit,
      meta: {
        permissions: [
          Permission.ManageTags
        ]
      }
    },
    {
      path: '/settings/infoBar',
      name: 'infoBar',
      component: EditSettings,
      meta: {
        permissions: [
          // Permission.MenageInfoBar
        ],
        type: 'infoBar'
      }
    },
    {
      path: '/settings/promotions',
      name: 'promotions.list',
      component: PromotionsList,
      meta: {
        permissions: [
          Permission.ViewPromotion
        ]
      }
    },
    {
      path: '/settings/promotions/create',
      name: 'promotions.create',
      component: PromotionEdit,
      meta: {
        permissions: [
          Permission.CreatePromotion
        ]
      }
    },
    {
      path: '/settings/promotions/:id/edit',
      name: 'promotions.edit',
      component: PromotionEdit,
      meta: {
        permissions: [
          Permission.EditPromotion
        ]
      }
    },
    {
      path: '/settings/:type',
      name: 'config.edit',
      component: EditSettings,
      meta: {
        permissions: [
          // Permission.EditSettings
        ]
      }
    },
    {
      path: '/settings',
      name: 'config.list',
      component: SettingList,
      meta: {
        permissions: [
          // Permission.ViewSetting
        ]
      }
    }
  ]
}
