
















































import { Component } from 'vue-property-decorator'
import { debounce, DebouncedFunc } from 'lodash'
import { TitleVersion } from '@d24/modules'

import { Addon } from '../../maps/variant'

import { AbstractAddon } from './AbstractAddon'
import { AddonErrors } from './AddonErrors.vue'

/**
 * @author  Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'TitlesAddon',
  components: { AddonErrors }
})
export class TitlesAddon extends AbstractAddon {
  public alternativeTitles: Record<TitleVersion, string> = {
    [TitleVersion.Long]: '',
    [TitleVersion.Short]: '',
    [TitleVersion.Clickbait]: '',
    [TitleVersion.TeaserBox]: ''
  }

  public TitleTypes = TitleVersion

  public setTitle (key: TitleVersion, value: string): void {
    this.alternativeTitles[key] = value
    this.setNewTitles(this.alternativeTitles)
  }

  protected getVariantProperty (): void {
    if (this.variant) {
      const titlesFromModel = this.variant.getProperty<Record<TitleVersion, string>>(Addon.AdditionalTitles)

      if (!titlesFromModel) {
        this.alternativeTitles = {
          [TitleVersion.Long]: '',
          [TitleVersion.Short]: '',
          [TitleVersion.Clickbait]: '',
          [TitleVersion.TeaserBox]: ''
        }
        return
      }

      this.alternativeTitles = {
        ...this.alternativeTitles,
        ...titlesFromModel
      }
    }
  }

  protected setNewTitles: DebouncedFunc<(titles: Record<TitleVersion, string>) => void> =
    debounce((titles: Record<TitleVersion, string>) => {
      this.setNewTitles.cancel()

      this.variant.setProperty<Record<TitleVersion, string>>(Addon.AdditionalTitles, titles)

      this.onChange(this.variant)
    }, 1000)
}

export default TitlesAddon
