















import { Component, Vue } from 'vue-property-decorator'

import { Loader } from '@component/Loader'

import { EventsForm } from '@module/forms/components/EventsForm.vue'
import { BreadcrumbsProps } from '@d24/modules'
import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'
import { CreateFormIntention, CreateFormIntentionPayload } from '@module/forms/intensions/CreateFormIntention'
import { Inject } from '@plugin/inversify'
import { FormsRepositoryType, IFormsRepository } from '@module/forms/contracts'

@Component<CreateFormView>({
  name: 'CreateFormView',
  components: {
    EventsForm,
    Loader
  }
})
export class CreateFormView extends Vue {
  @Inject(FormsRepositoryType)
  protected formRepository!: IFormsRepository

  public isLoading: boolean = false
  public formName: string = 'createForm'
  public payload: CreateFormIntentionPayload = {} as CreateFormIntentionPayload

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('content.forms.list.title')}`,
          target: { name: 'forms.forms.list' }
        },
        {
          label: `${this.$t('content.forms.createForm.title')}`,
          target: { name: 'forms.forms.create' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public createNewForm (): void {
    this.isLoading = true
    this.formRepository.create(new CreateFormIntention(this.payload)).then(() => {
      this.isLoading = false
      this.$router.push({ name: 'forms.forms.list' })
    }).catch(e => console.warn(e))
      .finally(() => {
        this.isLoading = false
      })
  }
}

export default CreateFormView
