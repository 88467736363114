// Copyright © 2021 Move Closer

import { computed, ComputedRef, defineComponent } from '@vue/composition-api'

import { FormFieldAbstract, FormFieldAbstractSetup } from '../Abstract'
import { FormTextProps } from './TextArea.contracts'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export const FormText = defineComponent({
  name: 'FormText',
  extends: FormFieldAbstract,
  template: `
    <div>

      <label :for="name" v-if="displayLabel" :require="required">{{ label }}</label>

      <slot name="beforeInput" />

      <D24TextArea :model.sync="value"
             v-bind="{ name, placeholder, autocomplete, readonly, disabled, rows }"
             v-focus="autofocus"
             :class="classObject">

      </D24TextArea>

      <div class="invalid-feedback animated fadeIn" v-if="hasErrors">{{ errors[0] }}</div>
      <small class="text-muted" v-if="helper">{{ helper }}</small>

    </div>

  `,
  props: {
    /**
     * TextArea autocomplete
     */
    autocomplete: {
      type: String,
      required: false
    },

    /**
     * TextArea autofocus
     */
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * TextArea placeholder
     */
    placeholder: {
      type: String,
      required: false,
      default: ''
    },

    /**
     * TextArea rows count - determines how long component will be
     */
    rows: {
      type: Number,
      required: false,
      default: 5
    }
  },
  emits: ['input'],

  setup (props: FormTextProps, { emit }) {
    const {
      errors,
      displayLabel,
      hasErrors,
      value
    } = FormFieldAbstractSetup(props, emit)

    const classObject: ComputedRef<object> = computed(() => {
      return {
        'is-invalid': hasErrors.value
      }
    })

    return {
      classObject,
      errors,
      displayLabel,
      hasErrors,
      value
    }
  }
})
export default FormText
