



















import { BreadcrumbsProps, DashmixTheme, Identifier } from '@d24/modules'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { EventbusType, IEventbus, IModal, ModalType } from '@movecloser/front-core'

import { EditModeLayout } from '@component/EditModeLayout'
import { initBreadcrumbs } from '@module/content/helpers'
import { Inject } from '@plugin/inversify'
import { Modals } from '@/config/modals'

import { ISubscriptionRepository, IUserRepository, SubscriptionRepositoryType, UserRepositoryType } from '../contracts/repositories'
import { PeriodsTable } from '../components/PeriodsTable.vue'
import { Prices, UserModel } from '../contracts/models'
import { SubscriptionForm } from '../components/SubscriptionForm.vue'
import { SubscriptionPayload } from '../contracts/data'
import { UserSiteBar } from '../components/UserSiteBar.vue'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'EditSubscription',
  components: {
    EditModeLayout,
    SubscriptionForm,
    UserSiteBar,
    PeriodsTable
  }
})
export class EditSubscription extends Vue {
  @Inject(EventbusType)
  private eventBus!: IEventbus

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(UserRepositoryType)
  protected userRepository!: IUserRepository

  @Inject(SubscriptionRepositoryType)
  protected subscriptionRepository!: ISubscriptionRepository

  public formName: string = 'updateSubscription'
  public isLoading: boolean = false
  public isSaving: boolean = false
  public payload: SubscriptionPayload | null = null
  public prices: Prices | null = null
  public user: UserModel | null = null

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('authors.listTitle')}`,
          target: { name: 'users.authors.list' }
        },
        {
          label: `${this.$t('subscription.listTitle')}`,
          target: { name: 'users.subscriptions.list' }
        },
        {
          label: `${this.$t('subscription.editSubscription')}`,
          target: { name: 'users.subscriptions.create' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get subscriptionId () {
    return Number(this.$route.params.id)
  }

  created () {
    this.loadSubscription()
    this.loadPrices()
  }

  public cancel () {
    this.modalConnector.open(Modals.Confirm, {
      onConfirm: () => {
        return this.onCancel()
      },
      content: {
        header: 'subscription.cancel.header',
        contentText: 'subscription.cancel.text',
        contentTitle: this.user?.fullName() || '',
        theme: DashmixTheme.Warning,
        buttonLabel: 'subscription.cancel.confirm'
      }
    })
  }

  public forceCancel () {
    this.modalConnector.open(Modals.Confirm, {
      onConfirm: () => {
        return this.onForceCancel()
      },
      content: {
        header: 'subscription.forceCancel.header',
        contentText: 'subscription.forceCancel.text',
        contentTitle: this.user?.fullName() || '',
        theme: DashmixTheme.Danger,
        buttonLabel: 'subscription.forceCancel.confirm'
      }
    })
  }

  public onSubmit () {
    if (!this.payload) {
      return
    }

    this.isSaving = true

    this.subscriptionRepository.update(this.subscriptionId, this.payload).then(() => {
      this.$router.push({ name: 'users.subscriptions.list' })
    }).catch(e => console.debug(e)).finally(() => {
      this.isSaving = false
    })
  }

  protected loadPrices () {
    this.isLoading = true

    this.subscriptionRepository.getPrices().then(prices => {
      this.prices = prices
    }).catch(error => console.log(error)
    ).finally(() => {
      this.isLoading = false
    })
  }

  protected loadSubscription () {
    this.isLoading = true

    this.subscriptionRepository.load(this.subscriptionId).then(model => {
      this.payload = {
        ...model,
        userId: model.user.id,
        renewable: !model.endedAt && !!model.nextRenewalAt
      }
    }).catch(error => console.log(error)
    ).finally(() => {
      this.isLoading = false
    })
  }

  protected onCancel () {
    this.subscriptionRepository.cancel(this.subscriptionId).then(data => {
      if (this.payload) {
        this.payload.endedAt = data
        this.modalConnector.close()
      }
    }).catch(e => console.debug(e))
  }

  protected onForceCancel () {
    this.subscriptionRepository.forceCancel(this.subscriptionId).then(() => {
      this.loadSubscription()
      this.eventBus.emit('ui:edit-sub.refresh')
      this.modalConnector.close()
    }).catch(e => console.debug(e))
  }

  @Watch('payload.userId', { deep: false })
  protected onUserIdSet (id: Identifier) {
    if (!id) {
      return
    }

    this.isLoading = true
    this.userRepository.load(id).then(model => {
      this.user = model
    }).catch(error => console.log(error)
    ).finally(() => {
      this.isLoading = false
    })
  }
}

export default EditSubscription
