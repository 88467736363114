// Copyright © 2021 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const resources: ResourcesRegistry = {
  user: {
    prefix: 'api/management',
    methods: {
      create: {
        url: 'users',
        method: Methods.Post,
        auth: true
      },
      delete: {
        url: 'users/{id}',
        method: Methods.Delete,
        auth: true,
        params: ['id']
      },
      edit: {
        url: 'users/{id}',
        method: Methods.Put,
        auth: true,
        params: ['id']
      },
      get: {
        url: 'users/{id}',
        method: Methods.Get,
        auth: true,
        params: ['id']
      },
      list: {
        url: 'users',
        method: Methods.Get,
        auth: true
      },
      reset: {
        url: 'users/{id}',
        method: Methods.Get,
        auth: true,
        params: ['id']
      },
      stats: {
        url: 'users/stats',
        method: Methods.Get,
        auth: true
      }
    }
  },
  author: {
    prefix: 'api/management',
    methods: {
      delete: {
        url: 'authors/{id}',
        method: Methods.Delete,
        auth: true,
        params: ['id']
      },
      edit: {
        url: 'authors/{id}',
        method: Methods.Put,
        auth: true,
        params: ['id']
      },
      list: {
        url: 'authors',
        method: Methods.Get,
        auth: true
      },
      get: {
        url: 'authors/{id}',
        method: Methods.Get,
        auth: true,
        params: ['id']
      },
      create: {
        url: 'authors',
        method: Methods.Post,
        auth: true
      }
    }
  },
  roles: {
    prefix: 'api/management',
    methods: {
      create: {
        url: 'roles',
        method: Methods.Post,
        shorthand: 'createRole',
        auth: true
      },
      delete: {
        url: 'roles/{id}',
        method: Methods.Delete,
        params: ['id'],
        auth: true
      },
      item: {
        url: 'roles/{id}',
        params: ['id'],
        method: Methods.Get,
        auth: true
      },
      list: {
        url: 'roles',
        method: Methods.Get,
        auth: true
      },
      update: {
        url: 'roles/{id}',
        method: Methods.Put,
        params: ['id'],
        shorthand: 'editRole',
        auth: true
      }
    }
  },
  subscription: {
    prefix: 'api',
    methods: {
      list: {
        url: 'management/subscriptions',
        method: Methods.Get,
        auth: true
      },
      get: {
        url: 'management/subscriptions/{id}',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      update: {
        url: 'management/subscriptions/{id}',
        method: Methods.Put,
        params: ['id'],
        auth: true
      },
      getPrices: {
        url: 'management/settings/subscriptionPrices',
        method: Methods.Get,
        auth: true
      },
      create: {
        url: 'management/users/{id}/subscriptions',
        method: Methods.Post,
        params: ['id'],
        auth: true
      },
      cancel: {
        url: 'management/subscriptions/{id}',
        method: Methods.Delete,
        params: ['id'],
        auth: true
      },
      forceCancel: {
        url: 'management/subscriptions/{id}/force',
        method: Methods.Delete,
        params: ['id'],
        auth: true
      },
      downloadTransactionDetails: {
        url: 'management/transactions/{id}/details',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      downloadInvoice: {
        url: 'downland/invoice/{slug}',
        method: Methods.Get,
        params: ['slug'],
        auth: true
      },
      periods: {
        url: 'management/subscriptions/{id}/periods',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      transactions: {
        url: 'management/subscriptions/{id}/transactions',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      markAsPaid: {
        url: 'management/subscriptions/{subscriptionId}/periods/{id}',
        method: Methods.Put,
        params: ['subscriptionId', 'id'],
        auth: true
      },
      sendPaymentEmail: {
        url: 'management/subscriptions/{subscriptionId}/periods/{id}/sendPaymentInfo',
        method: Methods.Post,
        params: ['subscriptionId', 'id'],
        auth: true
      }
    }
  },
  logs: {
    prefix: 'api/management',
    methods: {
      list: {
        url: 'logs',
        method: Methods.Get,
        auth: true
      }
    }
  }
}
