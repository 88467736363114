// Copyright © 2021 Move Closer

import {
  AnyObject,
  Collection,
  ICollection,
  Injectable,
  IResponse,
  mapCollection,
  MappingConfig,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'
import { resolveFromStatus } from '@/shared/exceptions/connector-errors'
import { Query } from '@/shared/contracts/query'

import { retrieveResult } from '@module/root/helpers/process'

import { Content, SimpleContent } from '../models/content'
import { contentAdapterMap } from '../models/content.adapter'
import {
  ContentCreatePayload,
  IContentOptionsPayload,
  IPublishContentPayload
} from 'src/modules/content/contracts/data'
import {
  ContentData,
  ContentInfoData,
  ContentModel,
  ContentType,
  Properties,
  RegistryPayload,
  SimpleContentData,
  SimpleContentModel
} from '../contracts/models'
import { IContentRepository } from '../contracts/repositories'
import { IEditContentIntention } from '../contracts/intentions'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Injectable()
export class ContentRepository extends Repository<ContentData, Content> implements IContentRepository {
  protected map: MappingConfig = contentAdapterMap
  protected useAdapter = true

  public async changeOrder (parent: ContentData['id'], children: Identifier[]): Promise<ContentModel> {
    const response: IResponse = await this.connector.call(
      'content',
      'order',
      { parentId: parent },
      { children }
    )
    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
    return this.composeModel(response.data.data, Content)
  }

  public async create (payload: ContentCreatePayload): Promise<Identifier> {
    const response: IResponse = await this.connector.call(
      'content',
      'create',
      {},
      payload
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return retrieveResult(response).id
  }

  public async delete (id: ContentData['id']): Promise<void> {
    const response: IResponse = await this.connector.call(
      'content',
      'delete',
      { id: id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async load (id: ContentData['id']): Promise<ContentModel> {
    const response: IResponse = await this.connector.call(
      'content',
      'get',
      { id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeModel(response.data.data, Content)
  }

  public async loadCollection (types: ContentType | ContentType[], query: Query): Promise<ICollection<ContentModel>> {
    const response: IResponse = await this.connector.call(
      'content',
      'list',
      {},
      {
        type: Array.isArray(types) ? types.join(',or:') : types,
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Content,
      response.data.meta
    )
  }

  public async loadContentInfo (): Promise<ContentInfoData> {
    const response = await this.connector.call(
      'content',
      'stats',
      {}
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
    return response.data.data
  }

  public async loadNode (types: ContentType | ContentType[], id: ContentData['id'], query: Query): Promise<ContentModel> {
    const response: IResponse = await this.connector.call(
      'content',
      'get',
      { id },
      {
        cType: Array.isArray(types) ? types.join(',or:') : types,
        with: 'children',
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeModel(response.data.data, Content)
  }

  public async loadTree (query: Query): Promise<ICollection<SimpleContentModel>> {
    const response: IResponse = await this.connector.call(
      'content',
      'nodes',
      {},
      { ...query }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return new Collection(
      (this.useAdapter ? mapCollection(response.data.data, this.map) : response.data.data)
        .map((item: SimpleContentData) => SimpleContent.hydrate(item)),
      response.data.meta
    )
  }

  public async options (id: ContentData['id'], payload: IContentOptionsPayload): Promise<void> {
    const response: IResponse = await this.connector.call(
      'content',
      'options',
      { id: id },
      { properties: payload }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async properties (id: ContentData['id'], payload: Properties): Promise<void> {
    const response: IResponse = await this.connector.call(
      'content',
      'properties',
      { id: id },
      {
        properties: payload,
        bulk: true
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async publish (id: ContentData['id'], payload: IPublishContentPayload): Promise<void> {
    const response: IResponse = await this.connector.call(
      'content',
      'publish',
      { id: id },
      payload
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async registry (slug: string): Promise<RegistryPayload> {
    const response: IResponse = await this.connector.call(
      'public',
      'registry',
      { slug }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    const related: AnyObject = response.data.meta.dicts
    const meta: AnyObject = response.data.meta
    delete meta.dicts

    return {
      data: response.data.data,
      meta,
      related
    }
  }

  public async update (id: ContentData['id'], payload: IEditContentIntention): Promise<IResponse> {
    const response: IResponse = await this.connector.call(
      'content',
      'update',
      { id },
      payload
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return response
  }

  public async sendPush (id: ContentData['id']): Promise<IResponse> {
    const response: IResponse = await this.connector.call(
      'content',
      'sendPush',
      { id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return response
  }
}
