// Copyright © 2021 Move Closer

import { AnyObject } from '@movecloser/front-core'
import { ExtendedMDE } from 'simplemde'
import { ModuleDriver, SizeMap } from '@d24/modules'

import { findTokenAtCurrentLine, replaceTokenAtCurrentLine } from '@component/MarkdownEditor/helpers/line-parser'
import { Modals } from '@/config/modals'
import { QuoteContent } from '@component/QuoteFormModal/QuoteFormModal.contracts'
import { Shortcut } from '@component/MarkdownEditor'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const quoteShortcut: Shortcut = {
  action: (editor: ExtendedMDE) => {
    const onSelection = (data: QuoteContent) => {
      if (!data.text) {
        return
      }

      replaceTokenAtCurrentLine(editor, ModuleDriver.Blockquote, data)
    }

    const token: AnyObject | null = findTokenAtCurrentLine(editor, ModuleDriver.Blockquote)

    editor.options.modalConnector.open(Modals.QuoteFormModal, {
      selected: token,
      onClose: () => editor.options.modalConnector.close(),
      onSelection: onSelection
    }, { size: SizeMap.Medium })
  },
  className: 'fas fa-quote-right',
  name: 'blockquote',
  title: 'Wstaw cytat'
}
