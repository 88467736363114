// Copyright © 2021 Move Closer

import { RawLocation } from 'vue-router'

export interface HeaderInterface {
  buttonLabel?: string
  title: string
  linkTarget?: RawLocation
}

export enum InteractiveTableMode {
  Emit = 'emit',
  Set = 'set'
}
