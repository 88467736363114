// Copyright © 2021 Move Closer

import { Authentication, AuthServiceType } from '@movecloser/front-core'
import { Vue } from 'vue-property-decorator'

import { Inject } from '@plugin/inversify'

import { UserModel } from '@module/auth/contracts/models'

import { Domain } from '../contracts/models'
import { ISiteResolver, SiteResolverType } from '../services/site-resolver'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export class AbstractWidget extends Vue {
  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public get domain () {
    return this.siteResolver.getSite()?.domain || Domain.Defence24PL
  }

  public get user (): UserModel | null {
    return this.authService.user
  }

  public get isWidgetVisable (): boolean {
    return this.checkPermission(this.domain, this.user)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected checkPermission (domain: Domain, user: UserModel | null): boolean {
    return true
  }
}

export default AbstractWidget
