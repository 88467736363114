// Copyright © 2021 Move Closer

export interface PropertyProps {
  align: Alignment
  columnSizes: string[]
  label: string
  value: string | boolean | number | null | undefined
}

export enum Alignment {
  Left = 'left',
  Right = 'right'
}
