import { ApiConnectorType, AppModule, Container, IConfiguration, IConnector, Interfaces, Module } from '@movecloser/front-core'

import { FormsRepositoryType, IFormsRepository } from '@module/forms/contracts/repositories'

import { resources } from './resources'
import { routesFactory } from './routes'
import { FormsRepository } from '@module/forms/repositories/forms'

@AppModule({
  name: 'forms',

  providers: (config: IConfiguration) => {
    return (bind: Interfaces.Bind) => {
      bind<IFormsRepository>(FormsRepositoryType).to(FormsRepository)
    }
  },
  boot (container: Container) {
    const connector: IConnector = container.get(ApiConnectorType)
    connector.useResources(resources)
  },
  routes: routesFactory
})
export class FormsModule extends Module {}
