// Copyright © 2021 Move Closer

import { ExtendedMDE } from 'simplemde'

import { Shortcut } from '../MarkdownEditor.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const subscriptShortcut: Shortcut = {
  action: (editor: ExtendedMDE) => {
    editor.codemirror.replaceSelection('<sub>' + editor.codemirror.getSelection() + '</sub>')
  },
  className: 'fas fa-subscript',
  name: 'subscript',
  title: 'Wstaw indeks dolny'
}
