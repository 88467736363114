// Copyright © 2021 Move Closer

import { merge } from 'lodash'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

import modules from '@d24/modules/dist/modules/_locales/modules.json'
import UImodules from '@d24/modules/dist/modules/_locales/pl.json'

import atomsPL from '@/assets/langs/pl/atoms.json'
import commonPl from '@/assets/langs/pl/common.json'

Vue.use(VueI18n)

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export const i18n = new VueI18n({
  locale: 'pl',
  fallbackLocale: 'pl',
  silentTranslationWarn: true,
  messages: {
    pl: {
      ...merge(atomsPL, commonPl, modules, UImodules)
    }
  }
})
