



































































import { DashmixIconName, DashmixTheme } from '@d24/modules'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { Inject } from '@/shared/plugins/inversify'

import { CouponRepositoryType, ICouponRepository } from '../contracts/repositories'
import { FormInput } from '@component/form'
import CouponsTable from '@module/settings/components/CouponsTable.vue'
import { PromotionCouponConfig } from '@module/settings/contracts'
import { Identifier } from '@/shared/contracts/data'
import { saveAs } from 'file-saver'

@Component<PromotionCouponsForm>({
  name: 'PromotionCouponsForm',
  components: {
    FormInput,
    CouponsTable
  },
  created () {
    if (this.config) {
      this.payload = this.config
    }
  }
})
export class PromotionCouponsForm extends Vue {
  @Inject(CouponRepositoryType)
  protected couponsRepository!: ICouponRepository

  @Prop({
    type: Object,
    required: false,
    default: null
  })
  public config!: PromotionCouponConfig | null

  @Prop({
    type: Number,
    required: false
  })
  public codesCount?: number

  @Prop({
    type: Number,
    required: false
  })
  public codesUsed?: number

  public readonly Icons = DashmixIconName
  public readonly Theme = DashmixTheme
  public readonly form = 'editPromotionCoupon'

  public isLoading = false
  public isTableVisible = false
  public payload: PromotionCouponConfig = {} as PromotionCouponConfig

  public get disabled (): boolean {
    return !this.promotionId
  }

  protected onDownload () {
    this.couponsRepository.download(this.promotionId).then(response => {
      saveAs(response.file, response.filename)
    })
  }

  public get hasCodes (): boolean {
    return !!this.codesCount
  }

  public get promotionId (): number {
    return Number(this.$route.params.id)
  }

  public show (): void {
    this.isTableVisible = true
  }

  public async generate (): Promise<void> {
    try {
      this.isLoading = true
      await this.couponsRepository.generate(this.promotionId, this.payload)
      this.codesCount = this.payload.quantity
      this.show()
    } catch (e) {
      console.error(e)
    } finally {
      this.isLoading = false
    }
  }
}

export default PromotionCouponsForm
