



































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { DashmixButtonVariantMap, DashmixIconName } from '@d24/modules'
import { IModal, ModalType } from '@movecloser/front-core'

import { Inject } from '@plugin/inversify'

import { FootnoteModalData, FootnoteModalPayload } from './FootnoteModal.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<FootnoteModal>({ name: 'FootnoteModal' })
export class FootnoteModal extends Vue {
  @Prop({ type: Object, required: true })
  public readonly payload!: FootnoteModalPayload

  @Inject(ModalType)
  private readonly modalConnector!: IModal

  @Ref('form')
  private readonly formRef!: HTMLFormElement

  public DashmixButtonVariantMap = DashmixButtonVariantMap
  public DashmixIconName = DashmixIconName

  /**
   * Data entered by the User.
   */
  public formData: FootnoteModalData = {
    identifier: '',
    text: ''
  }

  /**
   * Determines whether the component is currently performing any async actions.
   */
  public isActing: boolean = false

  /**
   * VUE lifetime hook - get formData form payload
   */
  mounted () {
    if (!this.payload.selected) {
      return
    }

    this.formData = this.payload.selected
  }

  /**
   * Handles the `@submit` event on the `#footnote-modal__form`.
   */
  public onSubmit (): void {
    if (!this.isFormValid) {
      return
    }

    this.submitFormData()
    this.closeModal()
  }

  /**
   * Handles the `@close` event on the `<D24ModalInner>`.
   */
  public onClose (): void {
    this.closeModal()
  }

  /**
   * Closes the modal.
   */
  private closeModal (): void {
    this.modalConnector.close()
  }

  /**
   * Determines whether the form contains a valid data.
   */
  private get isFormValid (): boolean {
    return this.formRef.checkValidity()
  }

  /**
   * Submits the data collected by the form.
   */
  private submitFormData (): void {
    if (typeof this.payload !== 'undefined' && typeof this.payload.onSelection === 'function') {
      this.payload.onSelection(this.formData, this.payload.position, this.payload.isEditMode)
    }
  }
}

export default FootnoteModal
