



























import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { DashmixIconName } from '@d24/modules'
import { Fragment } from 'vue-fragment'

import { Inject } from '@/shared/plugins/inversify'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { ContentStatusBadge, ContentType, SneakPeakModel } from '../contracts'
import { EditorsStack } from './EditorsStack.vue'

@Component({
  name: 'SearchTableRow',
  components: {
    EditorsStack,
    Fragment
  }
})
export class SearchTableRow extends Mixins<IUserAware>(UserAware) {
  @Prop({
    type: Object,
    required: true
  })
  public data!: SneakPeakModel

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  private ContentStatusBadge = ContentStatusBadge
  public ContentType = ContentType
  public Icons = DashmixIconName

  public get hasParent (): boolean {
    return !!this.data.parent
  }
}

export default SearchTableRow
