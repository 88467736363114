// Copyright © 2021 Move Closer

import { AnyObject, IModel, MagicModel, ModelPayload } from '@movecloser/front-core'

import { HasIdentifier, Identifier } from '@/shared/contracts/data'

export interface DictData {
  name: string
  slug: string
  [key: string]: string
}

export type DictModel = MagicModel<DictData>

export interface IOwner extends IModel<OwnerData> {
  fullName (): string
  shortName (): string
}

export interface IProcess extends IModel<ProcessData> {
  isFailed (): boolean
  isPending (): boolean
  isSuccessful (): boolean
}

export interface ISite extends IModel<SiteData> {
  getProperty <Type = any>(name: string, defaultValue?: Type): Type | undefined
  hasParent (): boolean
}

export interface OwnerData extends HasIdentifier {
  avatar: string
  firstName: string
  lastName: string
}

export type OwnerModel = MagicModel<OwnerData, IOwner>

export interface ProcessData {
  id: string
  code: number
  errors: string[]
  feedback: string[]
  result: ModelPayload
  status: ProcessStatus
  type: string
}

export type ProcessModel = MagicModel<ProcessData, IProcess>

export enum ProcessStatus {
  Canceled = 'canceled',
  Failed = 'failed',
  Finished = 'finished',
  New = 'new',
  Pending = 'pending',
  Succeed = 'succeed'
}

export enum Domain {
  Defence24PL = 'defence24.pl',
  Energetyka24COM = 'energetyka24.com',
  CyberDefence24PL = 'cyberdefence24.pl',
  Space24PL = 'space24.pl',
  Defence24COM = 'defence24.com',
  InfoSecurity24PL = 'infosecurity24.pl',
  Defence24MSPO = 'mspo.defence24.pl',
  Defence24TV = 'defence24.tv'
}

export enum RoutePrefix {
  Defence24PL = 'd24',
  Energetyka24COM = 'e24',
  CyberDefence24PL = 'cd24',
  Space24PL = 's24',
  Defence24COM = 'd24com',
  InfoSecurity24PL = 'is24',
  Defence24MSPO = 'mspo',
  Defence24TV = 'tv'
}

export interface SiteData extends HasIdentifier {
  name: string
  address: string
  domain: Domain
  locale: string
  logo: Partial<Record<SiteLogoVariant, string>>
  owner: OwnerData
  parent: SiteData|null
  properties: AnyObject
  rootContent: Identifier
  routePrefix: RoutePrefix
}

export enum SiteLogoVariant {
  Dark = 'dark',
  Light = 'light'
}

export type SiteModel = MagicModel<SiteData, ISite>
