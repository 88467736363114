// Copyright © 2021 Move Closer

import { EmbedContent, EmbedVersion } from '@d24/modules'

export function getErrors (embed: EmbedContent, version: EmbedVersion): string[] {
  const errors: string[] = []

  if (!version) {
    errors.push('Formularz musi mieć wybraną wersje')
  }

  if (version === EmbedVersion.Iframe && !embed.iframe) {
    errors.push('Formularz musi posiadać poprawny skrypt IFrame')
  }

  if (version === EmbedVersion.YouTube && !embed.videoId) {
    errors.push('Formularz musi posiadać poprawne id video')
  }

  if (version === EmbedVersion.Video && !embed.video) {
    errors.push('Formularz musi posiadać video')
  }

  if (version === EmbedVersion.VideoSwitch && !embed.video) {
    errors.push('Formularz musi posiadać video')
  }

  return errors
}

export function hasErrors (embed: EmbedContent, version: EmbedVersion): boolean {
  if (version === EmbedVersion.Iframe) {
    return !embed.iframe
  } else if (version === EmbedVersion.PDF) {
    return !embed.pdf
  } else if (version === EmbedVersion.YouTube) {
    return !embed.videoId
  } else if (version === EmbedVersion.Twitter) {
    return !embed.twitter
  } else if (version === EmbedVersion.Video) {
    return !embed.video
  } else if (version === EmbedVersion.VideoSwitch) {
    return !embed.video
  }
  return true
}
