// Copyright © 2021 Move Closer

import {
  ApiConnectorType,
  AppModule,
  ConnectorMiddleware,
  Container,
  IConfiguration,
  IConnector, IModal,
  Interfaces, ModalType,
  Module
} from '@movecloser/front-core'

import { AuthRepository } from './repositories/auth'
import { AuthRepositoryType, IAuthRepository, IMeRepository, MeRepositoryType } from './contracts/repositories'
import { MeRepository } from './repositories/me'
import { resources } from './resources'
import { routesFactory } from './routes'
import { LoginMiddleware, LoginMiddlewareType } from '@module/auth/services/login.middleware'

@AppModule({
  name: 'auth',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  providers: (config: IConfiguration) => {
    return (bind: Interfaces.Bind) => {
      bind<IAuthRepository>(AuthRepositoryType).to(AuthRepository)
      bind<ConnectorMiddleware>(LoginMiddlewareType).toDynamicValue((context: Interfaces.Context) => {
        return new LoginMiddleware(
          context.container.get<IModal>(ModalType)
        )
      })
      bind<IMeRepository>(MeRepositoryType).to(MeRepository)
    }
  },
  boot (container: Container) {
    const connector: IConnector = container.get(ApiConnectorType)
    connector.useResources(resources)
  },
  routes: routesFactory
})
export class AuthModule extends Module {}
