// Copyright © 2021 Move Closer

import { AbstractIntention, MappingTypes } from '@movecloser/front-core'

import { IEditContentIntention } from '../contracts/intentions'

export class EditContentIntention extends AbstractIntention<IEditContentIntention> {
  protected map = {
    parent: {
      type: MappingTypes.Function,
      value: (model: IEditContentIntention) => {
        return model.parent?.id
      },
      target: 'parentId'
    },
    slug: 'slug',
    properties: 'properties'
  }
}
