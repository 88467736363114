// Copyright © 2021 Move Closer

import { defineComponent } from '@vue/composition-api'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const Header = defineComponent({
  name: 'Header',
  template: `
    <header id="page-header">
      <div class="content-header">
        <!-- Left Section -->
        <div><slot name="left"></slot></div>

        <!-- Right Section -->
        <div><slot name="right"></slot></div>
      </div>
    </header>
  `
})
