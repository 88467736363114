
































































































import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import { DashmixIconName, DashmixSelectItem, DashmixTheme, DateTimePickerType } from '@d24/modules'

import { FormDateTimePicker, FormInput } from '@component/form'
import { FormSelect } from '@component/form/Select'

import { Prices, SubscriptionsPeriods, SubscriptionsTypes } from '../contracts/models'
import { SubscriptionPayload } from '../contracts/data'
import { padStart } from 'lodash'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<SubscriptionForm>({
  name: 'SubscriptionForm',
  components: { FormSelect, FormInput, FormDateTimePicker },
  mounted () {
    this.price = this.getPrice()
  }
})
export class SubscriptionForm extends Vue {
  @Prop({ type: String, required: true })
  public form!: string

  @Prop({ type: Boolean, required: false, default: false })
  public isEditMode!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public isSaving!: boolean

  @PropSync('formData', { type: Object, required: true })
  public payload!: SubscriptionPayload

  @Prop({ type: Object, required: false, default: null })
  public prices!: Prices | null

  public canChangePrice: boolean = false
  public defaultEndDate_: string = ''
  public readonly dateTimePickerType = DateTimePickerType
  public readonly Icons = DashmixIconName
  public price: number = 0

  public periods: DashmixSelectItem[] = Object.values(SubscriptionsPeriods).map(period => {
    return {
      value: period,
      label: `${this.$t('subscription.form.period.' + period)}`
    }
  })

  public renewable: DashmixSelectItem[] = [
    { value: true, label: `${this.$t('subscription.form.renewable.auto')}` },
    { value: false, label: `${this.$t('subscription.form.renewable.none')}` }
  ]

  public themes = DashmixTheme
  public types: DashmixSelectItem[] = Object.values(SubscriptionsTypes).map(type => {
    return {
      value: type,
      label: `${this.$t('subscription.form.types.' + type)}`
    }
  })

  public get canBeRenewed (): boolean {
    return this.payload.renewable
  }

  public get canShowPrice (): boolean {
    return !!this.payload.type && !!this.payload.period
  }

  public get defaultEndDate (): string {
    const minEndDate = this.getMinEndDate()
    const month = (minEndDate.getMonth() + 1).toString()

    return `${minEndDate.getFullYear()}-${padStart(month, 2, '0')}-${minEndDate.getDate()}`
  }

  public getPrice (): number {
    if (!this.canShowPrice || !this.prices) {
      return 0
    }

    if (this.payload.price) {
      return this.payload.price
    }

    return this.prices[this.payload.type][this.payload.period] || 0
  }

  public get isCancelled (): boolean {
    return this.payload.period === SubscriptionsPeriods.Monthly && this.payload.nextRenewalAt === null
  }

  public get statusClass (): string {
    return this.isCancelled ? 'bg-warning' : (this.payload.isActive ? 'bg-success' : 'bg-danger')
  }

  public get statusTitle (): string {
    return this.isCancelled ? 'cancelled' : (this.payload.isActive ? 'active' : 'inactive')
  }

  public get statusTheme (): DashmixTheme {
    return this.isCancelled ? DashmixTheme.Warning : (this.payload.isActive ? DashmixTheme.Success : DashmixTheme.Danger)
  }

  public disabledBeforeToday (date: Date) {
    const today = new Date()
    today.setDate(today.getDate() - 1)

    return date < today
  }

  public disabledBeforeStart (date: Date) {
    return date < this.getMinEndDate()
  }

  public getMinEndDate (): Date {
    if (!this.payload.startedAt) {
      return new Date()
    }

    const startDate = new Date(this.payload.startedAt)
    // if (!this.payload.period) {
    //   return startDate
    // }
    // const monthsToAdd = this.payload.period === SubscriptionsPeriods.Monthly ? 1 : 12
    // startDate.setMonth(startDate.getMonth() + monthsToAdd)

    return startDate
  }

  @Watch('price')
  protected onPriceUpdate (price: number) {
    this.price = price
    this.payload.price = price
  }

  @Watch('payload', { deep: true })
  protected onPayloadUpdate () {
    this.price = this.getPrice()
  }

  @Watch('prices', { deep: true })
  protected onPricesUpdate () {
    this.price = this.getPrice()
  }
}

export default SubscriptionForm
