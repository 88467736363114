// Copyright © 2021 Move Closer

import { ComponentObjectPropsOptions, DashmixIconName } from '@d24/modules'
import { computed, PropType, ref, SetupContext } from '@vue/composition-api'

import { AlertProps, AlertTheme } from './Alert.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const alertProps: ComponentObjectPropsOptions<AlertProps> = {
  autoTimeout: {
    type: Number,
    required: false,
    default: null
  },
  dismissible: {
    type: Boolean,
    required: false,
    default: false
  },
  icon: {
    type: String as PropType<DashmixIconName>,
    required: false,
    default: null
  },
  showIcon: {
    type: Boolean,
    required: false,
    default: true
  },
  theme: {
    type: String as PropType<AlertTheme>,
    required: false,
    default: AlertTheme.Info
  },
  useDefaultIcon: {
    type: Boolean,
    required: false,
    default: true
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const useAlert = (props: AlertProps, ctx: SetupContext) => {
  const isOpen = ref<boolean>(true)

  const closeAlert = () => {
    isOpen.value = false
    ctx.emit('close')
  }

  const iconToDisplay = computed<DashmixIconName|null>(() => {
    if (!props.icon && props.useDefaultIcon) {
      switch (props.theme) {
        case AlertTheme.Danger:
          return DashmixIconName.ExclamationSolid
        case AlertTheme.Info:
          return DashmixIconName.InfoSolid
        case AlertTheme.Primary:
          return DashmixIconName.InfoSolid
        case AlertTheme.Success:
          return DashmixIconName.CheckSolid
        case AlertTheme.Warning:
          return DashmixIconName.ExclamationTriangleSolid
      }
    }

    return props.icon
  })

  if (props.autoTimeout) {
    setTimeout(() => closeAlert(), props.autoTimeout)
  }

  return { close: closeAlert, closeIcon: DashmixIconName.TimesSolid, iconToDisplay, isOpen }
}
