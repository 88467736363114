// Copyright © 2021 Move Closer

import { Identifier } from '@d24/modules'
import { Injectable, IResponse, MappingConfig, Repository, ResourceActionFailed } from '@movecloser/front-core'

import { Query } from '@/shared/contracts/query'
import { resolveFromStatus } from '@/shared/exceptions/connector-errors'

import { AdUnitData, AdUnitModel, IAdsRepository } from '@module/content/contracts'
import { AdUnit } from '@module/content/models/ad-unit'
import { adUnitAdapterMap } from '@module/content/models/ad-unit.adapter'
import { TargetingOption } from '@service/ads'
import { TargetingKeys } from '@service/ads/ads.config'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Injectable()
export class AdsRepository extends Repository<AdUnitData, AdUnit> implements IAdsRepository {
  protected map: MappingConfig = adUnitAdapterMap
  protected useAdapter = true

  public async load (id: Identifier): Promise<AdUnitModel> {
    const response: IResponse = await this.connector.call('ads', 'get', { id })

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeModel(response.data.data, AdUnit)
  }

  public async loadCollection (query: Query): Promise<AdUnitModel[]> {
    const response: IResponse = await this.connector.call('ads', 'list', {}, query)

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    if (!Array.isArray(response.data.data)) {
      return []
    }

    return this.composeCollection(response.data.data, AdUnit, response.data.meta)
  }

  public async loadAdsKeys (key?: TargetingKeys): Promise<TargetingOption[]> {
    const query: Query = {
      perPage: '200'
    }

    if (key) {
      query.key = key
    }

    const response: IResponse = await this.connector.call(
      'ads',
      'keys',
      {},
      query)

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return response.data.data as TargetingOption[]
  }
}
