// Copyright © 2021 Move Closer

import { MappingConfig } from '@movecloser/front-core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const userAdapterMap: MappingConfig = {
  avatar: 'avatar',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  id: 'id',
  permissions: 'permissions'
}
