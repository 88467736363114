





































































import {
  AnyObject,
  DashmixIconName,
  DashmixSelectItem,
  DescriptionsRecord,
  NewsBarColor,
  NewsBarItem,
  Picker,
  Related,
  RelatedType,
  SizeMap
} from '@d24/modules'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import { debounce, DebouncedFunc } from 'lodash'
import { IModal, ModalType } from '@movecloser/front-core'

import { FormSelect } from '@component/form'
import { FormInput } from '@component/form/Input/Input'
import { Inject } from '@plugin/inversify'
import { IRelatedService, RelatedServiceType } from '@service/related'

import { ConfigForm } from '../components/_abstract/ConfigForm.vue'
import { InfoBarData } from '../contracts'

@Component({
  name: 'InfoBarForm',
  components: {
    FormInput,
    FormSelect
  }
})
export class InfoBarForm extends ConfigForm {
  @Prop({
    type: Boolean,
    required: false
  })
  public isLoading!: boolean

  @PropSync(
    'formData',
    {
      type: Object,
      required: true
    }
  )
  public payload!: InfoBarData

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  public readonly icons = DashmixIconName
  public readonly newItem: NewsBarItem = {
    label: '',
    related: null,
    externalUrl: ''
  } as unknown as NewsBarItem

  private deboucer!: DebouncedFunc<(index: number, value: string) => void>
  private deboucerExternalUrl!: DebouncedFunc<(index: number, value: string) => void>
  protected descriptions: DescriptionsRecord = {}
  public form: string = 'editInfoBar'

  public get backgroundColor (): NewsBarColor | undefined {
    return this.payload && this.payload.backgroundColor ? this.payload.backgroundColor : '' as NewsBarColor
  }

  public set backgroundColor (value: NewsBarColor | undefined) {
    if (!value) {
      return
    }

    this.payload.backgroundColor = value
    this.$forceUpdate()
  }

  public get background (): string {
    return this.payload && this.payload.background ? this.payload.background : ''
  }

  public set background (value: string) {
    if (!value) {
      return
    }

    this.payload.background = value
  }

  public get isCustomColor (): boolean {
    return this.backgroundColor === NewsBarColor.Custom
  }

  public get colorOptions (): DashmixSelectItem[] {
    // For some reason this enum in Object.values returns array of values and keys (one lower case, second capitalize),
    // That's why this method with set and mapping to lower case.
    return [...new Set(Object.values(NewsBarColor).map(value => `${value}`.toLowerCase()))]
      .map(value => {
        return {
          label: this.$t('forms.newsBar.colors.' + value) as string,
          value: value
        }
      })
  }

  created (): void {
    this.deboucer = debounce((index, value) => {
      this.deboucer.cancel()

      const payload = { ...this.payload }

      payload.items[index].label = value

      this.payload = payload
    }, 1500)

    this.deboucerExternalUrl = debounce((index, value) => {
      this.deboucerExternalUrl.cancel()

      const payload = { ...this.payload }

      payload.items[index].externalUrl = value

      this.payload = payload
    }, 1500)
  }

  mounted () {
    this.getRelated(this.payload.items.map(item => item.related))
  }

  public addNewItem (): void {
    this.payload.items = [...this.payload.items, { ...JSON.parse(JSON.stringify(this.newItem)) }]
  }

  public deleteItem (index: number): void {
    const itemsCopy = [...this.payload.items]

    itemsCopy.splice(index, 1)

    this.payload.items = itemsCopy
  }

  public description (related: Related): string {
    if (!this.descriptions[related.type]) {
      return ''
    }

    return this.descriptions[related.type]?.[related.value] || ''
  }

  public findItemSource (index: number) {
    this.modalConnector.open(Picker.Content, {
      config: {},
      onClose: () => this.modalConnector.close(),
      selected: this.payload.items[index].related ? this.payload.items[index].related : undefined,
      onSelection: async (data: Related<RelatedType.Content>) => {
        this.setItemSource(index, data)
      }
    }, { size: SizeMap.Large })
  }

  public hasItemList (index: number) {
    return !!this.payload.items[index] && !!this.payload.items[index].related &&
        !!this.payload.items[index].related.value
  }

  public updateLabel (index: number, value: string) {
    this.deboucer(index, value)
  }

  public updateExternalUrl (index: number, value: string) {
    this.deboucerExternalUrl(index, value)
  }

  protected describe (related: Related, keyName: string = 'title', fallback: string = 'Nie można znaleźć opisu'): void {
    if (!this.descriptions[related.type]) {
      this.descriptions[related.type] = {}
    }

    this.relatedService.describe(related).then((list: AnyObject) => {
      this.descriptions[related.type]![related.value] = list[keyName] || 'Nie znaleziono klucza'
    }).catch((error: Error) => {
      this.descriptions[related.type]![related.value] = fallback
      console.warn(error)
    }).finally(() => {
      this.$forceUpdate()
    })
  }

  protected getRelated (relatedList: Related<RelatedType.Content>[]) {
    for (const related of relatedList) {
      if (related.value && related.type) {
        this.describe(related)
      }
    }
  }

  protected setItemSource (index: number, source: Related<RelatedType.Content>): void {
    const itemsCopy = [...this.payload.items]

    itemsCopy[index].related = source
    this.describe(source)

    this.payload.items = itemsCopy
  }

  public static defaultPayload () {
    return {
      items: [],
      backgroundColor: NewsBarColor.Brand,
      background: '',
      isActive: false
    }
  }
}

export default InfoBarForm
