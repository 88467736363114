



























import { BreadcrumbsProps, DashmixIconName, DashmixSelectItem, DateTimePickerType } from '@d24/modules'
import { Component, Vue } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import { FormDateTimePicker, FormInput, FormSelect } from '@component/form'
import { Identifier } from '@/shared/contracts/data'
import { IRelatedService, RelatedServiceType } from '@service/related'
import { Loader } from '@component/Loader'
import { EditModeLayout } from '@component/EditModeLayout'
import { ModelListHeader } from '@component/ModelListHeader/ModelListHeader'
import { Property } from '@component/Property'

import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'

import { ITagRepository, TagData, TagModel, TagRepositoryType, TagType } from '../contracts'
import { IModal, ModalType } from '@movecloser/front-core'
import { Tag } from '@module/settings/models/tag'

@Component<TagsEdit>({
  name: 'TagsEdit',
  components: {
    FormDateTimePicker,
    FormSelect,
    EditModeLayout,
    FormInput,
    Loader,
    ModelListHeader,
    Property
  },
  async mounted () {
    if (this.isEdit) {
      this.isLoading = true
      try {
        const data = await this.tagsRepository.get(this.id)
        console.log(data)
        await this.updateTag(data, this.id)
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    }
  }
})
export class TagsEdit extends Vue {
  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(TagRepositoryType)
  private tagsRepository!: ITagRepository

  @Inject(RelatedServiceType)
  public readonly relatedService!: IRelatedService

  public readonly form = 'editTag'
  public readonly Icons = DashmixIconName
  public readonly DateTimePickerType = DateTimePickerType
  public readonly tagType: TagType = TagType.Tag

  public articles: { id: Identifier; name: string }[] = []
  public isLoading: boolean = false
  public isSaving: boolean = false
  public navName: string = ''
  public payload: Omit<TagData, 'id'> = {
    name: '',
    slug: '',
    pluralName: '',
    count: 0,
    useAsTagPage: {},
    type: 'tag',
    deletedAt: null
  }

  private id: Identifier = Number(this.$route.params.id)
  private isEdit: boolean = !!this.id
  private tag: TagModel | null = null

  public get sitesKeys () {
    return Object.keys(this.payload.useAsTagPage).filter(value => value !== 'sites')
  }

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('settings.tags.title')}`,
          target: { name: 'settings.tags.list' }
        },
        {
          label: this.pageTitle,
          target: { name: 'settings.tags.edit' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get pageTitle (): string {
    return this.isEdit ? `${this.$t('settings.tags.form.edit', { name: this.navName })}` : `${this.$t('settings.tags.form.create')}`
  }

  public get typeOptions (): DashmixSelectItem[] {
    return [...new Set(Object.values(TagType))].map(value => {
      return {
        label: this.$t('settings.tags.form.type.options.' + value).toString(),
        value: value
      }
    })
  }

  public get tagTypeOptions (): DashmixSelectItem[] {
    return [...new Set(Object.values(TagType))].map(value => {
      return {
        label: this.$t('settings.tags.form.type.options.' + value).toString(),
        value: value
      }
    })
  }

  public async submit () {
    this.isSaving = true

    const payload = this.buildPayload()

    try {
      const data = this.isEdit ? await this.tagsRepository.update(this.id, payload) : await this.tagsRepository.create(payload)
      if (!this.isEdit && data.id) {
        this.id = data.id

        await this.$router.push({
          name: 'settings.tags.edit',
          params: { id: `${data.id}` }
        })
      }

      await this.updateTag(payload)
    } catch (error) {
      console.info(error)
    } finally {
      this.isSaving = false
    }
  }

  public async updateTag (payload: Omit<TagData, 'id'>, id?: Identifier) {
    this.tag = Tag.hydrate<TagData>({
      ...this.tag,
      ...payload,
      id: id ?? this.id
    })
    this.isEdit = !!this.id

    this.parsePayload(this.tag)
  }

  protected buildPayload (): Omit<TagData, 'id'> {
    const useAsTagPage: Record<string, string> = {}
    this.sitesKeys.forEach((siteId) => {
      useAsTagPage[siteId] = this.payload.useAsTagPage[siteId] ? '1' : '0'
    })

    return {
      ...this.payload,
      useAsTagPage
    }
  }

  protected parsePayload (tag: TagModel): void {
    this.payload = tag.toObject()
    this.navName = tag.name

    this.sitesKeys.forEach((siteId) => {
      this.payload.useAsTagPage[siteId] = this.payload.useAsTagPage[siteId] === '1'
    })
  }
}

export default TagsEdit
