

















import { Component, Vue } from 'vue-property-decorator'

import { Loader } from '@component/Loader'

import { EventsForm } from '@module/forms/components/EventsForm.vue'
import { BreadcrumbsProps } from '@d24/modules'
import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'
import { Inject } from '@plugin/inversify'
import { FormModel, FormsRepositoryType, IFormsRepository } from '@module/forms/contracts'
import { EditFormIntention, EditFormIntentionPayload } from '@module/forms/intensions/EditFormIntention'

@Component<EditFormView>({
  name: 'EditFormView',
  components: {
    EventsForm,
    Loader
  },

  created () {
    this.loadForm()
  }

})
export class EditFormView extends Vue {
  @Inject(FormsRepositoryType)
  protected formRepository!: IFormsRepository

  public form: FormModel | null = null
  public isLoading: boolean = false
  public formName: string = 'editForm'
  public payload: EditFormIntentionPayload = {} as EditFormIntentionPayload

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('content.forms.list.title')}`,
          target: { name: 'forms.forms.list' }
        },
        {
          label: `${this.$t('content.forms.editForm.title')}`,
          target: { name: 'forms.forms.edit' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get formId () {
    return Number(this.$route.params.id)
  }

  public loadForm () {
    this.isLoading = true

    this.formRepository.load(this.formId).then((form: FormModel) => {
      this.isLoading = false
      this.form = form
      const formObject = form.toObject()

      this.payload = {
        ...formObject
      }
    }).catch((e) => {
      console.warn(e)
      this.isLoading = false
    }).finally(() => {
      this.isLoading = false
    })
  }

  public onEdit (): void {
    this.isLoading = true
    this.formRepository.update(this.formId, new EditFormIntention(this.payload)).then(() => {
      this.isLoading = false
      this.$router.push({ name: 'forms.forms.list' })
    }).catch(e => console.warn(e))
      .finally(() => {
        this.isLoading = false
      })
  }
}

export default EditFormView
