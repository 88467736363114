// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'

import { Domain } from '@module/root/contracts/models'

import { IUser, UserData, UserPermissions } from '../contracts/models'
import { Permission } from '../contracts/permissions'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
export class User extends Model<UserData> implements IUser {
  protected boot (): void {
    this.initialValues = {}
    this.modelProperties = [
      'id',
      'email',
      'firstName',
      'lastName',
      'permissions',
      'avatar'
    ]
  }

  public canAccess (domain: Domain): boolean {
    return this.sitesWithAccess().includes(domain)
  }

  public canPerform (domain: Domain, permissions: Permission | Permission[]): boolean {
    const userPermissions = this.getPermissions(domain)

    if (Array.isArray(permissions)) {
      return permissions.every(
        (permission: Permission) => {
          return userPermissions.includes(permission)
        }
      )
    }

    return userPermissions.includes(permissions)
  }

  public getPermissions (domain: Domain): Permission[] {
    if (!this.canAccess(domain)) return []

    return (this._data.permissions[domain] ?? []) as Permission[]
  }

  public fullName (): string {
    return `${this._data.firstName} ${this._data.lastName}`
  }

  public sitesWithAccess (): Domain[] {
    const withAccess: Domain[] = []

    for (const [domain, permissions] of Object.entries<UserPermissions>(this._data.permissions)) {
      if (!(permissions && Array.isArray(permissions) && permissions.length > 0)) {
        continue
      }

      withAccess.push(domain as Domain)
    }

    return withAccess
  }

  public smallAvatar (): string {
    return this._data.avatar
  }
}
