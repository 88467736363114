





























import { Component, Vue, Watch } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'
import { QueryParams } from '@movecloser/front-core'

import { BreadcrumbsProps, TableHead, TableRowElement } from '@d24/modules'

import { Identifier } from '@/shared/contracts/data'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { ModelListHeader } from '@component/ModelListHeader/ModelListHeader'

import { FeedsRepositoryType, IFeedsRepository } from '../../contracts'
import FeedTableRow from '../../components/FeedTableRow.vue'
import { feedsTableHead } from '../../maps/feed'
import { initBreadcrumbs } from '../../helpers'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Component({
  name: 'FeedsList',
  components: { InteractiveTable, ModelListHeader }
})
export class FeedsList extends Vue {
  @Inject(FeedsRepositoryType)
  private feedsRepository!: IFeedsRepository

  public isLoading = false
  public rowComponent = FeedTableRow
  public tableData: TableRowElement[] = []
  public tableHead: TableHead = feedsTableHead

  public totalPages: number = 0
  public itemsTotal: number = 0

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('content.feeds.listTitle')}`,
          target: { name: 'content.feeds.list' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get header () {
    return { title: String(this.$t('content.feeds.listTitle')) }
  }

  public get modelListHeader () {
    return { title: String(this.$t('content.feeds.listTitle')) }
  }

  public get queryParams (): QueryParams {
    return this.$route.query as QueryParams
  }

  mounted () {
    this.loadList(this.queryParams)
  }

  @Watch('queryParams', { deep: true })
  protected loadList (query: QueryParams): void {
    this.isLoading = true
    this.feedsRepository.loadCollection(query).then(collection => {
      this.isLoading = false

      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: false,
          data: model
        }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.itemsTotal = collection.meta.total
    }).catch(error => {
      console.log(error)
      this.isLoading = false
    })
  }
}

export default FeedsList
