










































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@d24/modules'
import { IModal, ModalType } from '@movecloser/front-core'

import { FormInput } from '@component/form'
import { Inject } from '@/shared/plugins/inversify'
import { conjugateWordByNumber } from '@module/root/helpers/conjugateWord'

import { DirectorySelect } from './DirectorySelect.vue'
import { FilesMoveModalPayload, MediaMovePayload, DirectoryParentData } from '../contracts'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component({
  name: 'FilesMoveModal',
  components: { FormInput, DirectorySelect }
})
export class FilesMoveModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: FilesMoveModalPayload

  @Inject(ModalType)
  protected modalConnector!: IModal

  public choosenDirectory: DirectoryParentData | null = null
  public formName: string = 'moveFile'
  public icons = DashmixIconName
  public isLoading: boolean = false

  public get form (): MediaMovePayload {
    return {
      directory: this.choosenDirectory?.id || null
    }
  }

  public get fileLabel (): string {
    return conjugateWordByNumber('plik', 'pliki', 'plików', this.payload.files.length)
  }

  mounted () {
    this.choosenDirectory = this.payload.files[0].directory
  }

  public close (): void {
    this.$emit('close')
  }

  public sendForm (): void {
    this.isLoading = true
    const promises: Promise<void>[] = []

    for (const file of this.payload.files) {
      const result = this.payload.moveFile(file.id, this.form)

      promises.push(result)
    }

    if (promises.length) {
      Promise.all(promises).finally(() => {
        this.isLoading = false
      })
    } else {
      this.isLoading = false
    }
  }
}

export default FilesMoveModal
