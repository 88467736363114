// Copyright © 2021 Move Closer

import { DashmixIconName, DashmixTheme, TableHead, TableSearchBarAction } from '@d24/modules'

import { Domain } from '@module/root/contracts/models'
import { UserModel as AuthUserModel } from '@module/auth/contracts/models'

import { canDeleteUsers, canEditUsers } from '../guards'
import { UserModel } from '../contracts/models'

export const usersTableHead: TableHead = [
  {
    column: 'avatar',
    label: '',
    sortable: false,
    isSorted: false,
    width: '50px'
  },
  {
    column: 'name',
    label: 'users.table.name',
    sortable: true,
    isSorted: false,
    width: '1.2fr'
  },
  {
    column: 'nickname',
    label: 'users.table.nickname',
    sortable: true,
    isSorted: false,
    width: '1.2fr'
  },
  {
    column: 'email',
    label: 'users.table.email',
    sortable: true,
    isSorted: false,
    width: '1.2fr'
  },
  {
    column: 'role',
    label: 'users.table.access',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'createdDate',
    label: 'users.table.createdDate',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'lastLogin',
    label: 'users.table.lastLogin',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  }
]

export const usersTableHeadShortened: TableHead = [
  {
    column: 'avatar',
    label: '',
    sortable: false,
    isSorted: false,
    width: '50px'
  },
  {
    column: 'name',
    label: 'users.table.name',
    sortable: false,
    isSorted: false,
    width: '1.7fr'
  },
  {
    column: 'nickname',
    label: 'users.table.nickname',
    sortable: true,
    isSorted: false,
    width: '1.2fr'
  },
  {
    column: 'email',
    label: 'users.table.email',
    sortable: false,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'role',
    label: 'users.table.access',
    sortable: false,
    isSorted: false,
    width: '1fr'
  }
]

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export const usersLogsTableHead: TableHead = [
  {
    column: 'user',
    label: 'users.logs.table.userName',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'userDetails',
    label: 'users.logs.table.userDetails',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'logType',
    label: 'users.logs.table.typeTitle',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'logTime',
    label: 'users.logs.table.logTime',
    sortable: true,
    isSorted: false,
    width: '0.5fr'
  },
  {
    column: 'logName',
    label: 'users.logs.table.logName',
    sortable: false,
    isSorted: false,
    width: '2fr'
  }
]

export enum UserListsActions {
  Unlock = 'unlock',
  UnlockShadowban = 'unlockShadowban',
  Edit = 'edit',
  Delete = 'delete',
  Block = 'block',
  Shadowban = 'shadowban',
  ResetPassword = 'resetPassword'
}

export const userRowActionsFactory = (domain: Domain, user: AuthUserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canEditUsers(domain, user)) {
    actions.push(
      ...[
        {
          label: 'Edytuj',
          icon: DashmixIconName.PenSolid,
          isBulkAction: false,
          action: UserListsActions.Edit
        },
        {
          label: 'Zablokuj całkowicie',
          icon: DashmixIconName.UserSlashSolid,
          isBulkAction: true,
          action: UserListsActions.Block,
          guard: (data: unknown): boolean => {
            const model = data as UserModel
            return !model.isBlocked && !model.isShadowbanned
          }
        },
        {
          label: 'Zablokuj (shadowban)',
          icon: DashmixIconName.UserTimesSolid,
          isBulkAction: true,
          action: UserListsActions.Shadowban,
          guard: (data: unknown): boolean => {
            const model = data as UserModel
            return !model.isBlocked && !model.isShadowbanned
          }
        },
        {
          label: 'Odblokuj',
          icon: DashmixIconName.UserCheckSolid,
          isBulkAction: true,
          action: UserListsActions.Unlock,
          guard: (data: unknown): boolean => {
            const model = data as UserModel
            return model.isBlocked
          }
        },
        {
          label: 'Odblokuj (shadowban)',
          icon: DashmixIconName.UserCheckSolid,
          isBulkAction: true,
          action: UserListsActions.UnlockShadowban,
          guard: (data: unknown): boolean => {
            const model = data as UserModel
            return model.isShadowbanned
          }
        },
        {
          label: 'Resetuj hasło',
          icon: DashmixIconName.UnlockSolid,
          isBulkAction: true,
          action: UserListsActions.ResetPassword
        }
      ]
    )
  }

  if (canDeleteUsers(domain, user)) {
    actions.push(
      {
        label: 'Usuń',
        icon: DashmixIconName.TrashAltSolid,
        needsDoubleClick: true,
        isBulkAction: true,
        action: UserListsActions.Delete,
        theme: DashmixTheme.Danger
      }
    )
  }

  return actions
}
