

















import { Component, Prop, Vue } from 'vue-property-decorator'

import { VariantPreviewDecisionModalPayload } from '../contracts'

@Component({
  name: 'VariantPreviewDecisionModal'
})
export class VariantPreviewDecisionModal extends Vue {
  @Prop({ type: Object, required: true })
  protected payload!: VariantPreviewDecisionModalPayload

  public closeModal (): void {
    this.$emit('close')
    this.payload.cancel()
  }

  public generate (): void {
    this.$emit('close')
    this.payload.generate()
  }

  public saveAndGenerate (): void {
    this.$emit('close')
    this.payload.saveAndGenerate()
  }
}

export default VariantPreviewDecisionModal
