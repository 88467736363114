







































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { AnyModule, AnyObject, DashmixIconName, DashmixSelectItem, Identifier, Related, RelatedType } from '@d24/modules'
import { Collection, ICollection } from '@movecloser/front-core'
import { debounce, DebouncedFunc } from 'lodash'

import { DeMarked } from '@/shared/helpers/demarked'
import { Inject } from '@plugin/inversify'

import { NewsletterGenerator, NewsletterType } from '@/shared/helpers/newsletterGenerator'
import { IRelatedService, RelatedServiceType } from '@service/related'
import { Loader } from '@component/Loader/Loader'
import { Typeahead } from '@component/Typeahead'

import {
  GeneratedNewsletterModalPayload,
  ISetItemsRepository,
  SetItemsRepositoryType,
  SneakPeekModel
} from '../../content/contracts'

@Component<GeneratedNewsletterModal>({
  name: 'GeneratedNewsletterModal',
  components: {
    Loader,
    Typeahead
  },
  mounted (): void {
    this.loadList(this.numberOfArticles)
  },

  created () {
    this.debouncer = debounce((value: number) => {
      try {
        this.loadList(value)
      } catch (e) {
        console.warn(e)
      }
    }, 500)
  }
})
class GeneratedNewsletterModal extends Vue {
  @Ref('newsletter')
  public readonly newsletterRef!: HTMLElement

  @Prop({
    type: Object,
    required: true
  })
  public payload!: GeneratedNewsletterModalPayload

  @Inject(SetItemsRepositoryType)
  private readonly setItemsRepository!: ISetItemsRepository

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  private debouncer!: DebouncedFunc<(value: number) => void>
  public isLoading: boolean = false
  public isCopied: boolean = false
  public icons = DashmixIconName
  private resolved: Collection<SneakPeekModel> = new Collection<SneakPeekModel>()
  public template: string = ''
  public templateType: NewsletterType | null = NewsletterType.ImageList
  public numberOfArticles: number = 10

  public get hints (): DashmixSelectItem[] {
    return [
      {
        label: 'Wybierz rodzaj newslettera',
        value: null
      },
      ...Object.values(NewsletterType).map((hint) => ({
        label: this.$t(`generatedNewsletterModal.types.${hint}`).toString(),
        value: hint
      }))]
  }

  public copy (): void {
    if (this.templateType === null) {
      return
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.newsletterRef.select()
    document.execCommand('copy')
    this.isCopied = true
  }

  public close () {
    this.payload.onClose()
  }

  public submit () {
    this.payload.onConfirm()
  }

  public onNumberOfArticlesChange (number: number) {
    this.debouncer.cancel()

    if (number > 0) {
      this.debouncer(number)
    }
    this.numberOfArticles = number
  }

  private async loadList (perPage?: number): Promise<void> {
    try {
      this.resolved = new Collection<SneakPeekModel>()
      this.isLoading = true

      const collection: ICollection<SneakPeekModel> = await this.setItemsRepository.loadCollection(this.payload.setId as unknown as Identifier, { perPage: perPage ?? '10' })

      for (let i = 0; i < collection.length; i++) {
        this.resolveSingle({
          type: RelatedType.Content,
          value: collection[i].id
        }).then((article) => {
          if (typeof article!.properties.cover.image !== 'object') {
            return
          }

          collection[i].set('fullUrl', article!.fullUrl)
          if (typeof article!.properties.cover?.image === 'object') {
            collection[i].properties.cover.image = article!.properties.cover.image
          }

          this.resolved.push(collection[i])
          if (collection.length - 1 === i) {
            this.onTemplateChanged()
          }
          this.isLoading = false
        })
      }
    } catch (e) {
      this.isLoading = false
      console.warn(e)
    } finally {
      this.isLoading = false
    }
  }

  private parseNewsletter = (newsletter: string): string => {
    let modules: AnyModule[]
    try {
      modules = DeMarked.convertMDToObjects(newsletter) as AnyModule[]
      return DeMarked.convertObjectsToHTML(modules)
    } catch (e) {
      console.warn(e)
    }
    return ''
  }

  private async resolveSingle (relatedType: Related<RelatedType.Content>): Promise<AnyObject | undefined> {
    try {
      return await this.relatedService.resolve(relatedType)
    } catch (e) {
      console.warn(e)
    }
  }

  @Watch('templateType')
  private onTemplateChanged (): void {
    if (this.templateType === null) {
      this.template = NewsletterType.SimpleList
      return
    }

    this.isCopied = false

    NewsletterGenerator.generateFromSneakPeak(this.resolved, { type: this.templateType }).then(template => {
      this.template = this.parseNewsletter(template)
    }).catch((e) => {
      console.warn(e)
    })
  }
}

export default GeneratedNewsletterModal
