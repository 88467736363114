































import { BreadcrumbsProps, DashmixTheme, TableHead, TableRowAction } from '@d24/modules'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { saveAs } from 'file-saver'

import { DropdownActions } from '@/shared/contracts/content'
import { HeaderInterface } from '@component/InteractiveTable/InteractiveTable.contracts'
import { Identifier } from '@/shared/contracts/data'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { initBreadcrumbs } from '@module/content/helpers'
import { Inject } from '@plugin/inversify'
import { ModelListHeader } from '@component/ModelListHeader'
import { Query } from '@/shared/contracts/query'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { FormData, FormsRepositoryType, IFormsRepository } from '../contracts'
import { formsFiltersConfig } from '../models/form.filters'
import { FormsTableRow } from '../components/FormsTableRow.vue'
import { FormTableRowElement } from '../contracts/components'
import { FormActions, formsRowActionsFactory, formTableHead } from '../maps/forms'

@Component({
  name: 'FormsList',
  components: {
    InteractiveTable,
    ModelListHeader
  }
})
export class FormsList extends Mixins<IUserAware>(UserAware) {
  @Inject(FormsRepositoryType)
  protected formRepository!: IFormsRepository

  public filtersConfig = formsFiltersConfig
  public isLoading: boolean = false
  public itemsTotal: number = 0
  public rowComponent = FormsTableRow
  public tableData: FormTableRowElement[] = []
  public tableHead: TableHead = formTableHead
  public totalPages: number = 0

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('content.forms.list.title')}`,
          target: { name: 'forms.forms.list' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get header (): HeaderInterface {
    const payload: Partial<HeaderInterface> = {
      title: `${this.$t('content.forms.list.title')}`
    }

    payload.buttonLabel = 'content.forms.list.listButton'
    payload.linkTarget = { name: 'forms.forms.create' }

    return payload as HeaderInterface
  }

  public get modelListHeader () {
    const payload = {
      addLabel: this.$t('content.forms.list.listButton'),
      addTarget: { name: 'forms.forms.create' },
      title: this.$t('content.forms.list.title')
    }

    return payload
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  public mounted () {
    this.loadList(this.queryParams)
  }

  public get rowActions (): TableRowAction[] {
    return formsRowActionsFactory(this.domain, this.user)
  }

  public actions: DropdownActions = {
    [FormActions.Delete]: {
      callback: (data: unknown) => {
        const model = data as FormData
        return this.onDelete(model.id)
      },
      confirmation: {
        header: 'actions.delete.header',
        contentText: 'actions.delete.contentText',
        theme: DashmixTheme.Danger,
        buttonLabel: 'atoms.delete'
      }
    },
    [FormActions.Download]: {
      callback: (data: unknown) => {
        const model = data as FormData
        this.onDownload(model.id)
      }
    },
    [FormActions.Edit]: {
      callback: (data: unknown) => {
        const model = data as FormData
        this.$router.push({
          name: 'forms.forms.edit',
          params: { id: String(model.id) }
        })
      }
    }
  }

  protected loadList (query: Query): void {
    this.isLoading = true
    this.formRepository.loadCollection(query).then(collection => {
      this.isLoading = false
      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: this.canEditContents || this.canDeleteContents,
          data: model
        }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.itemsTotal = collection.meta.total
    }).catch(error => {
      console.warn(error)
    }).finally(() => {
      this.isLoading = false
    })
  }

  protected onDelete (id: Identifier) {
    return this.formRepository.delete(id).then(() => {
      this.tableData = this.tableData.filter(rowData => rowData.id !== id.toString())
    })
  }

  protected onDownload (id: Identifier) {
    this.formRepository.download(id).then(response => {
      saveAs(response.file, response.filename)
    })
  }

  @Watch('queryParams', { deep: true })
  protected onQueryChange (query: Query): void {
    this.loadList(query)
  }
}

export default FormsList
