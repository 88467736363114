





















import { Component, Prop, Vue } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { DashmixIconName } from '@d24/modules'
import { Fragment } from 'vue-fragment'

import { Inject } from '@/shared/plugins/inversify'

import { FeedModel, FeedStatusBadge } from '../contracts/models'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Component({
  name: 'FeedTableRow',
  components: { Fragment }
})
export class FeedTableRow extends Vue {
  @Prop({ type: Object, required: true })
  public data!: FeedModel

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public FeedStatusBadge = FeedStatusBadge
  public Icons = DashmixIconName
}

export default FeedTableRow
