















import { DashmixIconName } from '@d24/modules'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { FormInput } from '@component/form/Input/Input'

import { Iterator } from '@component/form/Iterator'
import { AdUnitData } from '@module/content/contracts'

@Component<AdUnitsForm>({
  name: 'AdUnitsForm',
  components: { FormInput, Iterator }
})
export class AdUnitsForm extends Vue {
  @Prop({ type: String, required: true })
  public form!: string

  @Prop({ type: Boolean, required: false })
  public isLoading!: boolean

  @PropSync('items', { type: Array, required: true })
  public list!: AdUnitData[]

  public readonly icons = DashmixIconName

  public addItem (): AdUnitData {
    return {
      id: this.list.length,
      name: '',
      sizes: [{ width: 0, height: 0, fullDisplayString: '' }]
    }
  }

  private updateIndexes () {
    // todo: aktualizowanie indeksow, zeby sie nie duplikowaly
    this.list = this.list.map((item, index) => ({ ...item, id: index }))
  }
}

export default AdUnitsForm
