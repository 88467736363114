// Copyright © 2021 Move Closer

import { ExtendedMDE } from 'simplemde'
import { ModuleDriver, Related, RelatedType, SizeMap } from '@d24/modules'

import { Modals } from '@/config/modals'

import { Shortcut } from '../MarkdownEditor.contracts'
import { findTokenAtCurrentLine, replaceTokenAtCurrentLine } from '@component/MarkdownEditor/helpers/line-parser'
import { AnyObject } from '@movecloser/front-core'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const galleryShortcut: Shortcut = {
  action: (editor: ExtendedMDE) => {
    const onSelection = (data: Related<RelatedType.Gallery, string>) => {
      if (!data.value) {
        return
      }

      replaceTokenAtCurrentLine(editor, ModuleDriver.Gallery, { gallery: data })
    }
    const token: AnyObject | null = findTokenAtCurrentLine(editor, ModuleDriver.Gallery)

    let selected

    if (
      token &&
      Object.prototype.hasOwnProperty.call(token, 'gallery') &&
      Object.prototype.hasOwnProperty.call(token.gallery, 'value') &&
      Object.prototype.hasOwnProperty.call(token.gallery, 'type')
    ) {
      selected = token.gallery
    }

    editor.options.modalConnector.open(Modals.PickGallery, {
      multiple: false,
      selected: selected,
      onClose: () => editor.options.modalConnector.close(),
      onSelection: onSelection
    }, { size: SizeMap.XLarge })
  },
  className: 'fas fa-images',
  name: 'gallery',
  title: 'Wstaw galerię'
}
