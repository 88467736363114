// Copyright © 2021 Move Closer

import { FiltersConfig, FilterType } from '@component/Filters/Filters.contracts'
import { defaultIgnoredQueryParams } from '@component/Filters'
import { loadLogsTypesDict, loadUsers } from '@/shared/helpers/filters'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export const userLogsFiltersConfig: FiltersConfig = {
  groups: {
    commitedAt: { label: 'Data', key: 'commitedAt', type: FilterType.Date },
    remoteAddr: { label: 'IP', key: 'browserFingerprint', type: FilterType.Number },
    type: { label: 'Typ', key: 'type', type: FilterType.Dictionary, dictionary: loadLogsTypesDict() },
    user: { label: 'Użytkownik', key: 'user', type: FilterType.Dictionary, dictionaryLoader: loadUsers }
  },
  ignore: [...defaultIgnoredQueryParams],
  override: { page: '1' },
  leave: ['perPage']
}
