// Copyright © 2021 Move Closer

import { ICollection, Injectable, QueryParams, Repository, ResourceActionFailed } from '@movecloser/front-core'

import { resolveFromStatus } from '@/shared/exceptions/connector-errors'

import { CreateFeedEntryIntention } from '../intentions/CreateFeedEntryIntention'
import { FeedData, FeedEntryData, FeedEntryModel, IFeedEntriesRepository } from '../contracts'
import { FeedEntry } from '../models/feedEntry'
import { UpdateFeedEntryIntention } from '../intentions/UpdateFeedEntryIntention'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Injectable()
export class FeedEntriesRepository extends Repository<FeedEntryData, FeedEntry> implements IFeedEntriesRepository {
  public async create (feedId: FeedData['id'], intention: CreateFeedEntryIntention): Promise<void> {
    const response = await this.connector.call(
      'feedEntries',
      'create',
      { feedId },
      intention.toRequest()
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return response.data.data.id
  }

  public async delete (feedId: FeedData['id'], entryId: FeedEntryData['id']): Promise<void> {
    const response = await this.connector.call(
      'feedEntries',
      'delete',
      { feedId, entryId }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return response.data.data
  }

  public async load (feedId: FeedData['id'], entryId: FeedEntryData['id']): Promise<FeedEntryModel> {
    const response = await this.connector.call(
      'feedEntries',
      'item',
      { feedId, entryId }
    )

    return this.composeModel(
      response.data.data,
      FeedEntry
    )
  }

  public async loadCollection (feedId: FeedData['id'], query?: QueryParams): Promise<ICollection<FeedEntryModel>> {
    const response = await this.connector.call(
      'feedEntries',
      'list',
      { feedId },
      query
    )

    return this.composeCollection(
      response.data.data,
      FeedEntry,
      response.data.meta
    )
  }

  public async update (
    feedId: FeedData['id'],
    entryId: FeedEntryData['id'],
    intention: UpdateFeedEntryIntention
  ): Promise<void> {
    await this.connector.call(
      'feedEntries',
      'update',
      { feedId, entryId },
      intention.toRequest()
    )
  }
}
