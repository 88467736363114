// Copyright © 2021 Move Closer

import {
  ICollection,
  IncorrectValueError,
  Injectable,
  IResponse,
  MappingConfig,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { Query } from '@/shared/contracts/query'
import { resolveFromStatus } from '@/shared/exceptions/connector-errors'

import {
  ISetsRepository,
  SetData, SetItem,
  SetModel, SetType
} from '../contracts'
import { CreateSetIntention } from '../intentions/CreateSetIntention'
import { EditSetIntention } from '../intentions/EditSetIntention'
import { Set } from '../models/set'
import { setAdapterMap } from '../models/set.adapter'
import { Identifier } from '@/shared/contracts/data'
import { AnyObject } from '@d24/modules'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 * @author Michał Rossian <michal.rossian@moveclosermichal.pl>
 */
@Injectable()
export class SetsRepository extends Repository<SetData, Set> implements ISetsRepository {
  protected map: MappingConfig = setAdapterMap
  protected useAdapter = true

  // TODO fix this any type when task manager is ready
  public async create (intention: CreateSetIntention): Promise<any> {
    const response = await this.connector.call(
      'sets',
      'create',
      {},
      intention.toRequest()
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return response.data?.data
  }

  public async delete (id: SetData['id']): Promise<void> {
    const response: IResponse = await this.connector.call(
      'sets',
      'delete',
      { id: id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async load (id: SetData['id'], siteId?: Identifier): Promise<SetModel> {
    let payload

    if (siteId) {
      payload = { siteId: siteId }
    }

    const response: IResponse = await this.connector.call(
      'sets',
      'item',
      { id },
      payload
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    if (response.data.data.type !== SetType.Articles) {
      throw new IncorrectValueError(
        'Object with given ID is not an articles set'
      )
    }

    return this.composeModel(response.data.data, Set)
  }

  public async loadCollection (contentType: SetType, query: Query, setId?: Identifier): Promise<ICollection<SetModel>> {
    const payload: AnyObject = {
      type: 'articles',
      ...query
    }

    if (setId) {
      payload.siteId = setId
    }

    const response: IResponse = await this.connector.call(
      'sets',
      'list',
      {},
      payload
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Set,
      response.data.meta
    )
  }

  public async update (id: SetData['id'], intention: EditSetIntention): Promise<void> {
    const response: IResponse = await this.connector.call(
      'sets',
      'update',
      { id },
      intention.toRequest()
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async updateItem (setId: SetData['id'], itemId: SetItem['id'], meta: { title: string; description: string }): Promise<void> {
    const response: IResponse = await this.connector.call(
      'sets',
      'updateItem',
      { setId, itemId },
      { meta }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async reset (id: SetData['id'], resetLockedItems: boolean): Promise<void> {
    const response: IResponse = await this.connector.call(
      'sets',
      'regenerate',
      { id },
      { resetLockedItems }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async refresh (id: SetData['id']): Promise<void> {
    const response: IResponse = await this.connector.call(
      'sets',
      'refresh',
      { id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }
}
