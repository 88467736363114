// Copyright © 2021 Move Closer

import { ConnectorMiddleware, FoundResource, Headers, Injectable, IResponse, Payload } from '@movecloser/front-core'

import { IToastService } from './toaster.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class ToastMiddleware implements ConnectorMiddleware {
  protected service: IToastService<string, ToastrOptions>
  protected shouldToast: boolean = false
  protected skipToast: boolean = true

  constructor (service: IToastService<string, ToastrOptions>) {
    this.service = service
  }

  public afterCall (response: IResponse): void {
    const message: string = response.data && 'message' in response.data ? response.data.message : ''

    if (response.status < 300) {
      // Success statuses: 2xx - trigger when turned on & there's a message to display.
      if (this.shouldToast && message.length) this.service.success(message)
    } else if (response.status < 400) {
      // Destination statuses: 3xx - trigger only when there's a message to display.
      if (message.length) this.service.info(message)
    } else if (response.status === 422) {
      // Validation: 422 - trigger only when turned on.
      if (this.shouldToast) {
        this.service.warning(
          message.length ? message : 'Invalid data given.'
        )
      }
    } else if (response.status < 500) {
      // Request statuses: 4xx - trigger always.
      if (this.shouldToast) {
        this.service.warning(
          ToastMiddleware.resolveMessage(response.status, message, 'Invalid request.')
        )
      }
    } else if (!this.skipToast) {
      // Server error statuses: 5xx - always.
      this.service.error(
        ToastMiddleware.resolveMessage(response.status, message, 'Server error.')
      )
    }
  }

  public beforeCall (resource: FoundResource, headers: Headers, body: Payload) {
    this.shouldToast = resource.meta.toast || false
    this.skipToast = resource.meta.skipToast || false

    return { headers, body }
  }

  /**
   * Returns correct message connected with given http status.
   */
  protected static resolveMessage (status: number, message: string, fallback: string) {
    const map: { [key: string]: string } = {
      e400: 'Bad request.',
      e401: 'Unauthenticated.',
      e402: 'Payment required.',
      e403: 'Forbidden.',
      e404: 'Not found.',
      e405: 'Method not allowed.',
      e406: 'Not acceptable',
      e408: 'Request timeout',
      e410: 'Gone',
      e413: 'Payload too large.',
      e424: 'Failed dependency.',
      e429: 'Too many requests.'
    }

    if (`e${status}` in map) {
      return map[`e${status}`]
    }

    return message.length ? message : fallback
  }
}
