
































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { BreadcrumbsProps, DashmixTheme, TableHead, TableRowElement, TableSearchBarAction } from '@d24/modules'
import { IModal, ModalType } from '@movecloser/front-core'

import { DropdownActions } from '@/shared/contracts/content'
import { HeaderInterface } from '@component/InteractiveTable/InteractiveTable.contracts'
import { HasIdentifier, Identifier } from '@/shared/contracts/data'
import { Inject } from '@/shared/plugins/inversify'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'
import { ModelListHeader } from '@component/ModelListHeader/ModelListHeader'
import { Query } from '@/shared/contracts/query'

import { AuthorRepositoryType, IAuthorRepository } from '../contracts/repositories'
import { AuthorListsActions, authorRowActionsFactory, authorTableHead } from '../maps/authors'
import { AuthorTableRow } from '../components/AuthorTableRow.vue'
import { AuthorData } from '../contracts/models'
import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'AuthorsList',
  components: { InteractiveTable, ModelListHeader }
})
export class AuthorsList extends Mixins<IUserAware>(UserAware) {
  @Inject(AuthorRepositoryType)
  protected authorsRepository!: IAuthorRepository

  @Inject(ModalType)
  protected modalConnector!: IModal

  public isLoading = false

  public get header (): HeaderInterface {
    const payload: Partial<HeaderInterface> = {
      title: 'authors.listTitle'
    }

    if (this.canCreateAuthors) {
      payload.buttonLabel = 'authors.addAuthor'
      payload.linkTarget = { name: 'users.authors.create' }
    }

    return payload as HeaderInterface
  }

  public tableHead: TableHead = authorTableHead.map(item => {
    return {
      ...item,
      label: `${this.$t(item.label)}`
    }
  })

  public rowComponent = AuthorTableRow
  public tableData: TableRowElement[] =[]

  public readonly defaultNumberOfRows: number = 100
  public itemsTotal: number = 0
  public totalPages: number = 0

  public get rowActions (): TableSearchBarAction[] {
    return authorRowActionsFactory(this.domain, this.user)
  }

  public actions: DropdownActions = {
    [AuthorListsActions.Edit]: {
      callback: (data: unknown) => {
        this.$router.push({ name: 'users.authors.edit', params: { id: (data as AuthorData).id.toString() } })
      }
    },
    [AuthorListsActions.Delete]: {
      callback: (data: unknown) => {
        return this.onDelete((data as HasIdentifier).id)
      },
      confirmation: {
        header: 'authors.delete.header',
        contentText: 'actions.delete.contentText',
        theme: DashmixTheme.Danger,
        buttonLabel: 'atoms.delete'
      }
    }
  }

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('authors.listTitle')}`,
          target: { name: 'users.authors.list' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  mounted () {
    this.loadList(this.queryParams)
  }

  protected loadList (query: Query): void {
    this.isLoading = true
    this.authorsRepository.loadCollection({ perPage: `${this.defaultNumberOfRows}`, ...query }).then(collection => {
      this.isLoading = false

      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: this.canEditAuthors || this.canDeleteAuthors,
          data: model
        }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.itemsTotal = collection.meta.total
    }).catch(error => {
      console.log(error)
      this.isLoading = false
    })
  }

  public onDelete (id: Identifier) {
    this.isLoading = true
    this.authorsRepository.delete(id)
      .then(() => {
        this.tableData = this.tableData.filter(rowElement => `${rowElement.id}` !== `${id}`)
      })
      .catch(error => {
        console.debug(error)
      }).finally(() => {
        this.isLoading = false
      })
  }

  @Watch('queryParams', { deep: true })
  protected onQueryChange (query: Query): void {
    this.loadList(query)
  }
}

export default AuthorsList
