

































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ICollection } from '@movecloser/front-core'
import { Inject } from '@plugin/inversify'

import { Typeahead } from '@component/Typeahead'

import { SelectUserModalPayload, UserHint } from '../contracts/components'
import { IUserRepository, UserRepositoryType } from '@module/users/contracts/repositories'
import { UserModel } from '@module/users/contracts/models'
import { Identifier } from '@d24/modules'

@Component({
  name: 'SelectUserModal',
  components: { Typeahead }
})
class SelectUserModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: SelectUserModalPayload

  @Inject(UserRepositoryType)
  protected userRepository!: IUserRepository

  public error: string = ''
  public hasErrors: boolean = false
  public isLoading: boolean = false
  public searchParam: string = ''
  public selectedOption: UserHint[] = []
  public usersOptions: UserHint[] = []

  public get isSubscriber (): boolean {
    if (!this.selectedOption[0]) {
      return false
    }
    return this.selectedOption[0].isSubscriber
  }

  public get label (): string {
    if (!this.isSubscriber) {
      return this.$t('subscription.userModal.create').toString()
    }
    return this.$t('subscription.userModal.edit').toString()
  }

  mounted () {
    // TODO fix - see below
    (document.querySelector('.modal-content') as HTMLDivElement).style.overflow = 'visible'

    this.loadOptions()
  }

  public close () {
    this.payload.onClose()
  }

  public onClear () {
    this.searchParam = ''
  }

  public onDelete () {
    this.selectedOption = []
  }

  public onSearch (value: string) {
    this.searchParam = value
  }

  public onSelect (selectedHint: UserHint) {
    this.hasErrors = false
    this.selectedOption = [selectedHint]
  }

  protected loadOptions (searchParams: string = '') {
    this.isLoading = true
    this.userRepository.loadCollection({ q: searchParams })
      .then((contents: ICollection<UserModel>) => {
        this.usersOptions = SelectUserModal.contentsToHints(contents)
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  @Watch('searchParam', { deep: false })
  protected onSearchParamsChange (searchParams: string): void {
    this.loadOptions(searchParams)
  }

  protected submit () {
    if (!this.selectedOption[0]) {
      this.hasErrors = true
      this.error = this.$t('subscription.userModal.error').toString()
      return
    }

    this.hasErrors = false

    if (this.selectedOption[0].isSubscriber && this.selectedOption[0].subscriptionId) {
      this.payload.onEdit(this.selectedOption[0].subscriptionId)
      return
    }

    this.payload.onCreate(this.selectedOption[0].value as Identifier)
  }

  public static contentsToHints (contents: ICollection<UserModel>): UserHint[] {
    return [...contents].map(
      (content: UserModel) => {
        return {
          value: content.id,
          label: content.fullName(),
          isSubscriber: content.isSubscriber,
          subscriptionId: content.subscriptionId
        }
      }
    )
  }
}

export default SelectUserModal
