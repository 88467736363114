



















import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixTheme, SizeMap } from '@d24/modules'
import { IModal, ModalType } from '@movecloser/front-core'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { JsonViewer } from 'vue-json-viewer'

import { Inject } from '@/shared/plugins/inversify'
import { Modals } from '@/config/modals'

import { TransactionDetailsModalPayload } from '../contracts/components'

// FIXME - register JsonViewer globally
Vue.component('JsonViewer', JsonViewer)

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'TransactionDetailsModal',
  components: {}
})
export class TransactionDetailsModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: TransactionDetailsModalPayload

  @Inject(ModalType)
  protected modalConnector!: IModal

  public themes = DashmixTheme

  public goBack () {
    this.modalConnector.close()

    this.modalConnector.open(Modals.TransactionModal, this.payload.goBackPayload, { size: SizeMap.XLarge })
  }
}

export default TransactionDetailsModal
