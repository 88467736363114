// Copyright © 2021 Move Closer

import { AbstractIntention } from '@movecloser/front-core'

import { IEditPromotionIntention } from '../contracts/intentions'
import { promotionAdapterMap } from '@module/settings/models/promotion.adapter'

export class EditPromotionIntention extends AbstractIntention<IEditPromotionIntention> {
  protected map = promotionAdapterMap
}
