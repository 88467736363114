// Copyright © 2021 Move Closer

import { Model, ModelPayload } from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'

import { ICoupon, CouponData } from '../contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export class Coupon extends Model<CouponData> implements ICoupon {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'id',
      'code',
      'isUsed',
      'transactionId',
      'userId',
      'promotionId',
      'usedAt'
    ]
  }

  public identifier (): Identifier {
    return this._data.id
  }
}
