// Copyright © 2021 Move Closer

import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'
import { Permission } from '@module/auth/contracts/permissions'

/**
 * Forms
 */
export const canCreateForms = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateForms)
}

export const canDeleteForms = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteForms)
}

export const canDownloadForms = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DownloadForms)
}

export const canEditForms = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditForms)
}

export const canViewForms = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewForms)
}
