

























import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { Fragment } from 'vue-fragment'

import { Inject } from '@plugin/inversify'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { FormData } from '../contracts'

@Component({
  name: 'FormsTableRow',
  components: { Fragment }
})
export class FormsTableRow extends Mixins<IUserAware>(UserAware) {
  @Prop({
    type: Object,
    required: true
  })
  public data!: FormData

  @Inject(DateTimeType)
  protected dateTime!: IDateTime
}

export default FormsTableRow
