





























































































































































import { DashmixIconName } from '@d24/modules'
import { Component, Prop, PropSync } from 'vue-property-decorator'

import { FormInput } from '@component/form/Input/Input'

import { ConfigForm } from '../components/_abstract/ConfigForm.vue'
import { AdsConfigData } from '../contracts'

import { AdUnitsForm } from './AdUnitsForm.vue'
import { VideoSettingsForm } from './VideoSettingsForm.vue'

@Component({
  name: 'AdsConfigForm',
  components: {
    AdUnitsForm,
    FormInput,
    VideoSettingsForm
  }
})
export class AdsConfigForm extends ConfigForm {
  @Prop({
    type: Boolean,
    required: false
  })
  public isLoading!: boolean

  @PropSync('formData', {
    type: Object,
    required: true
  })
  public payload!: AdsConfigData

  public readonly icons = DashmixIconName
  public form: string = 'editAdsConfig'

  public defaultOptions = [
    {
      label: 'on',
      value: true
    },
    {
      label: 'off',
      value: false
    }
  ]

  public overrideOptions = [
    {
      label: 'inherit',
      value: null
    },
    {
      label: 'on',
      value: true
    },
    {
      label: 'off',
      value: false
    }
  ]

  mounted () {
    setTimeout(() => {
      if (!this.payload.default || !this.payload.overrides?.user) {
        this.payload.default = AdsConfigForm.defaultPayload().default
        this.payload.overrides = AdsConfigForm.defaultPayload().overrides
      }
      if (this.payload.default && Object.keys(this.payload.default || {}).length !== Object.keys(AdsConfigForm.defaultPayload().default || {}).length) {
        this.payload.default = { ...AdsConfigForm.defaultPayload().default, ...this.payload.default }
      }
      if (this.payload.overrides && this.payload.overrides.user && Object.keys(this.payload.overrides?.user || {}).length !== Object.keys(AdsConfigForm.defaultPayload().overrides?.user || {}).length) {
        this.payload.overrides.user = { ...AdsConfigForm.defaultPayload().overrides?.user, ...this.payload.overrides?.user }
        this.payload.overrides.none = { ...AdsConfigForm.defaultPayload().overrides?.none, ...this.payload.overrides?.none }
        this.payload.overrides.paid = { ...AdsConfigForm.defaultPayload().overrides?.paid, ...this.payload.overrides?.paid }
      }
    }, 1000)
  }

  public toggleDefault (group: 'user' | 'none' | 'paid', key: 'videoSettings') {
    if (this.payload.overrides && this.payload.overrides.hasOwnProperty(group) && this.payload.overrides[group].hasOwnProperty(key)) {
      if (this.payload.overrides[group][key] !== null) {
        this.payload.overrides[group][key] = null
      } else {
        this.payload.overrides[group][key] = this.payload.default?.videoSettings || {
          youtube: 1,
          hls: 0,
          on: 0
        }
      }
    }
  }

  public static defaultPayload (): AdsConfigData {
    return {
      wtgLink: '',
      wtgDelay: 200,
      taboolaDelay: 200,
      videoAdsTag: '',
      adsFileContent: '',
      useNewWTG: true,
      stickyAd: {
        premium: true,
        sponsored: true,
        restrictedUrls: ''
      },
      default: {
        ads: true,
        wtg: true,
        taboola: true,
        onNetwork: false,
        stickyAd: true,
        stickyAdTeaser: true,
        stickyAdTeaserVideo: true,
        openMarket: true,
        autoHide: false,
        forceD24Ads: false,
        forceD24AdsOnWTG: false,
        forceD24AdsOnSticky: false,
        forceD24AdsOnCover: false,
        forceD24AdsOnOnNetwork: false,
        adReloadAll: false,
        doNotShrinkCount: 3,
        videoSettings: {
          youtube: 0,
          hls: 0,
          on: 0
        }
      },
      overrides: {
        user: {
          ads: null,
          wtg: null,
          taboola: null,
          onNetwork: null,
          stickyAd: null,
          stickyAdTeaser: null,
          stickyAdTeaserVideo: null,
          openMarket: null,
          autoHide: null,
          forceD24Ads: null,
          forceD24AdsOnWTG: null,
          forceD24AdsOnSticky: null,
          forceD24AdsOnCover: null,
          forceD24AdsOnOnNetwork: null,
          adReloadAll: null,
          doNotShrinkCount: null,
          videoSettings: null
        },
        none: {
          ads: null,
          wtg: null,
          taboola: null,
          onNetwork: null,
          stickyAd: null,
          stickyAdTeaser: null,
          stickyAdTeaserVideo: null,
          openMarket: null,
          autoHide: null,
          forceD24Ads: null,
          forceD24AdsOnWTG: null,
          forceD24AdsOnSticky: null,
          forceD24AdsOnCover: null,
          forceD24AdsOnOnNetwork: null,
          adReloadAll: null,
          doNotShrinkCount: null,
          videoSettings: null
        },
        paid: {
          ads: null,
          wtg: null,
          taboola: null,
          onNetwork: null,
          stickyAd: null,
          stickyAdTeaser: null,
          stickyAdTeaserVideo: null,
          openMarket: null,
          autoHide: null,
          forceD24Ads: null,
          forceD24AdsOnWTG: null,
          forceD24AdsOnSticky: null,
          forceD24AdsOnCover: null,
          forceD24AdsOnOnNetwork: null,
          adReloadAll: null,
          doNotShrinkCount: null,
          videoSettings: null
        }
      }
    }
  }
}

export default AdsConfigForm
