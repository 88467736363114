// Copyright © 2021 Move Closer

import { defineComponent, SetupContext } from '@vue/composition-api'

import { checkTileProps, useCheckTile } from './CheckTile.hooks'
import { CheckTileProps } from './CheckTile.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const CheckTile = defineComponent({
  name: 'CheckTile',
  props: checkTileProps,
  emits: ['update:selected'],
  setup: (props: CheckTileProps, ctx: SetupContext) => {
    return useCheckTile(props, ctx)
  },
  template: `
    <div class="custom-control custom-block" :class="'custom-control-'+theme">
      <input type="checkbox" :id="id" :name="name" class="custom-control-input"
             v-model="isChecked" />
      <label class="custom-control-label" :for="id"><slot/></label>
    </div>
  `
})
