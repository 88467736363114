














import { Component, Vue } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component({
  name: 'NotFoundView',
  metaInfo (): MetaInfo {
    return {
      title: 'Nie znaleziono'
    }
  }
})
export class NotFoundView extends Vue {
  /**
   * Returns the User to the homepage.
   */
  public goToHomepage (): void {
    this.$router.push('/')
  }

  /**
   * Returns the User to the previous route (page).
   */
  public goToPrevRoute (): void {
    this.$router.back()
  }
}

export default NotFoundView
