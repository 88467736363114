// Copyright © 2022 Move Closer
import { DashmixIconName, DashmixTheme, TableHead, TableSearchBarAction } from '@d24/modules'

import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'
import { canDeleteForms, canDownloadForms, canEditForms } from '@module/forms/guards'

export const formTableHead: TableHead = [
  {
    column: 'title',
    label: 'Tytuł',
    sortable: true,
    isSorted: false,
    width: '0.5fr'
  },
  {
    column: 'createdBy',
    label: 'Autor',
    sortable: false,
    isSorted: false,
    width: '0.5fr'
  },
  {
    column: 'type',
    label: 'Typ',
    sortable: true,
    isSorted: false,
    width: '0.25fr'
  },
  {
    column: 'status',
    label: 'Status',
    sortable: true,
    isSorted: false,
    width: '0.25fr'
  },
  {
    column: 'numberOfEntries',
    label: 'Liczba zgłoszeń',
    sortable: true,
    isSorted: false,
    width: '0.25fr'
  },
  {
    column: 'createdAt',
    label: 'Utworzono',
    sortable: true,
    isSorted: false,
    width: '0.25fr'
  }
]

export enum FormActions {
  Edit = 'edit',
  Delete = 'delete',
  Download = 'download'
}

export const formsRowActionsFactory = (domain: Domain, user: UserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canEditForms(domain, user)) {
    actions.push(
      {
        label: 'Edytuj',
        icon: DashmixIconName.PenSolid,
        isBulkAction: false,
        action: FormActions.Edit
      }
    )
  }

  if (canDeleteForms(domain, user)) {
    actions.push(
      {
        label: 'Usuń',
        icon: DashmixIconName.TrashAltSolid,
        needsDoubleClick: true,
        isBulkAction: true,
        action: FormActions.Delete,
        theme: DashmixTheme.Danger
      }
    )
  }

  if (canDownloadForms(domain, user)) {
    actions.push(
      {
        label: 'Pobierz listę uczestników',
        icon: DashmixIconName.ListSolid,
        isBulkAction: false,
        action: FormActions.Download
      }
    )
  }

  return actions
}
