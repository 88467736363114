// Copyright © 2021 Move Closer

import { VueConstructor } from 'vue'

let Vue: VueConstructor

/**
 * Install function.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
function __install (_Vue: VueConstructor) {
  if (Vue && _Vue === Vue) {
    if (process.env.NODE_ENV !== 'production') {
      /* eslint no-console: off */
      console.error('[prefetch.hook] already installed.')
    }
  } else {
    _Vue.mixin({
      created (this: Vue) {
        // @ts-expect-error
        if (typeof this.fetchRelated === 'function') {
          // @ts-expect-error
          this.fetchRelated()
        }
      }
    })
  }
}

/**
 * Install prefetch hook plugin.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 *
 * @param vue
 */
export const installFetchRelated = (vue: VueConstructor) => {
  vue.use({
    install: __install
  })
}
