// Copyright © 2021 Move Closer

import {
  loadAuthors,
  loadCategories,
  loadEditors,
  loadStatusDict,
  loadLabelsDict,
  loadTags, loadTypesDict
} from '@/shared/helpers/filters'

import { defaultIgnoredQueryParams, FiltersConfig, FilterType } from '@component/Filters'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const articlesFiltersConfig: FiltersConfig = {
  groups: {
    parent: { label: 'Kategoria', key: 'parent', type: FilterType.Dictionary, dictionaryLoader: loadCategories },
    author: { label: 'Autor', key: 'author', type: FilterType.Typeahead, dictionaryLoader: loadAuthors },
    editor: { label: 'Edytor', key: 'editor', type: FilterType.Typeahead, dictionaryLoader: loadEditors },
    status: { label: 'Status', key: 'status', type: FilterType.Dictionary, dictionary: loadStatusDict() },
    tags: { label: 'Tag', key: 'tags', type: FilterType.Typeahead, dictionaryLoader: loadTags },
    labels: { label: 'Etykieta', key: 'labels', type: FilterType.Dictionary, dictionaryLoader: loadLabelsDict },
    ads: { label: 'Reklamy', key: 'ads', type: FilterType.Boolean },
    adsAdX: { label: 'AdX', key: 'adsAdX', type: FilterType.Boolean },
    adsAdXAdSense: { label: 'AdX AdSense', key: 'adsAdXAdSense', type: FilterType.Boolean },
    createdAt: { label: 'Data utworzenia', key: 'createdAt', type: FilterType.Date },
    updatedAt: { label: 'Data modyfikacji', key: 'updatedAt', type: FilterType.Date }
  },
  ignore: [...defaultIgnoredQueryParams],
  override: { page: '1' },
  leave: ['perPage']
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const pageFiltersConfig: FiltersConfig = {
  groups: {
    title: { label: 'Tytuł', key: 'title', type: FilterType.String },
    editor: { label: 'Edytor', key: 'editor', type: FilterType.Typeahead, dictionaryLoader: loadEditors },
    type: { label: 'Typ', key: 'type', type: FilterType.Dictionary, dictionary: loadTypesDict() },
    status: { label: 'Status', key: 'status', type: FilterType.Dictionary, dictionary: loadStatusDict() },
    cType: { label: 'Child typ', key: 'cType', type: FilterType.Dictionary, dictionary: loadTypesDict() },
    cStatus: { label: 'Child status', key: 'cStatus', type: FilterType.Dictionary, dictionary: loadStatusDict() },
    cTitle: { label: 'Child Tytuł', key: 'cTitle', type: FilterType.String },
    cTypeLimit: { label: 'Child Limit', key: 'cTypeLimit', type: FilterType.String },
    cTypeOffset: { label: 'Child Offset', key: 'cTypeOffset', type: FilterType.String },
    createdAt: { label: 'Data utworzenia', key: 'createdAt', type: FilterType.Date },
    updatedAt: { label: 'Data modyfikacji', key: 'updatedAt', type: FilterType.Date }
  },
  ignore: [...defaultIgnoredQueryParams, 'parent'],
  override: { page: '1' },
  leave: ['parent', 'perPage']
}
