// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'

import { ownerAdapterMap } from './owner.adapter'

/**
 * @author Lukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const siteAdapterMap: MappingConfig = {
  address: 'address',
  domain: 'domain',
  id: 'id',
  locale: 'locale',
  logo: 'logo',
  name: 'name',
  owner: {
    type: MappingTypes.Adapter,
    map: ownerAdapterMap,
    value: 'owner'
  },
  properties: {
    type: MappingTypes.Function,
    value: item => {
      if ('properties' in item && typeof item.properties === 'object') {
        return item.properties
      }

      if (item.id === 9) {
        return { requireAuth: true }
      }

      return {}
    }
  },
  rootContent: {
    type: MappingTypes.Function,
    value: item => {
      return item.rootContent
    }
  }
}
