

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { SetModel } from '../contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({
  name: 'SetPickerItem',
  components: {}
})
export class SetPickerItem extends Vue {
  @Prop({ type: Object, required: true })
  public item!: SetModel

  @Prop({ type: Boolean, required: true })
  public isActive!: boolean

  @Prop({ type: Boolean, required: true })
  public isMultiple!: boolean

  @Prop({ type: Boolean, required: true })
  public isSelected!: boolean

  public selected: boolean = this.isSelected

  @Watch('selected')
  public select () {
    this.$emit('select', this.item)
  }
}

export default SetPickerItem
