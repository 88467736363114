

























import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DashmixIconName } from '@d24/modules'
import { Fragment } from 'vue-fragment'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { NavigationData } from '../contracts'

@Component({
  name: 'NavigationTableRow',
  components: { Fragment }
})
export class NavigationTableRow extends Mixins<IUserAware>(UserAware) {
  @Prop({ type: Object, required: true })
  protected data!: NavigationData

  public Icons = DashmixIconName
}

export default NavigationTableRow
