<!-- Copyright © 2021 Move Closer -->

<template>
  <section class="markdown-editor-guide-section">
    <div class="d-flex justify-content-between">
      <h6 class="markdown-editor-guide-section__heading">
        <slot name="heading" />
      </h6>
      <small><kbd>{{ type }}</kbd></small>
    </div>

    <slot />
  </section>
</template>

<script>
export default {
  props: {
    type: { type: String, required: false, default: 'block' }
  }
}
</script>

<style lang="scss">
.markdown-editor-guide-section {
  &:not(:first-child) {
    margin-top: 1.5em;
  }

  &__heading {
    margin-bottom: 0.75em;
  }
}
</style>
