






























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { ICollection } from '@movecloser/front-core'
import { SlickItem, SlickList } from 'vue-slicksort'
import { TableRowAction } from '@d24/modules'

import { FileModel, GalleryModel } from '../contracts'
import { GalleryDraggableItem } from '../components/GalleryDraggableItem.vue'
import { galleryItemsRowActions } from '../maps/gallery'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component({
  name: 'GalleryDraggableList',
  components: { GalleryDraggableItem, SlickList, SlickItem }
})
export class GalleryDraggableList extends Vue {
  @PropSync('items', { type: Array, required: true, default: () => [] })
  public files!: ICollection<FileModel>

  @Prop({ type: Object, required: true })
  public gallery!: GalleryModel

  @Prop({ type: Boolean, required: false, default: false })
  public incrementalLoading!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public isEditable?: boolean

  public rowActions: TableRowAction[] = galleryItemsRowActions
}

export default GalleryDraggableList
