<!-- Copyright © 2021 Move Closer -->

<template>
  <BaseSection>
    <template slot="heading">
      {{ $t('components.MarkdownEditorGuideModal.sections.embed.heading') }}
    </template>

    <pre>
>[ iframe: &lt;iframe&gt;...&lt;/iframe&gt; ]

<small>{{ $t('components.MarkdownEditorGuideModal.sections.embed.to-embed-yt-video') }}</small>
>yt[ videoId: sn9u29b298 ]

<small>{{ $t('components.MarkdownEditorGuideModal.sections.embed.to-add-custom-thumbnail') }}</small>
>yt[ videoId: sn9u29b298 | <strong>thumbnailId: 123</strong> ]

<small>{{ $t('components.MarkdownEditorGuideModal.sections.embed.to-add-description') }}</small>
>[ iframe: &lt;iframe&gt;...&lt;/iframe&gt; | <strong>description: Nullam quis risus eget urna mollis ornare vel eu leo.</strong> ]
>yt[ videoId: sn9u29b298 | <strong>description: Nullam quis risus eget urna mollis ornare vel eu leo.</strong> ]
    </pre>
  </BaseSection>
</template>

<script>
import BaseSection from './_base'
export default { components: { BaseSection } }
</script>
