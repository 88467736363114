


















































import { DashmixIconName, DashmixTheme } from '@d24/modules'
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'

import { FormInput } from '@component/form'
import { PromotionData, PromotionType } from '@module/settings/contracts'
import { FormText } from '@component/form/TextArea/TextArea'

@Component<PromotionConfigForm>({
  name: 'PromotionConfigForm',
  components: {
    FormText,
    FormInput
  },
  created () {
    this.isDefault = !this.payload?.message
    this.isDefaultSummary = !this.payload?.messageSummary
    this.message = this.payload?.message ?? this.defaultMessage
    this.messageSummary = this.payload?.message_summary ?? this.defaultMessageSummary
  }
})
export class PromotionConfigForm extends Vue {
  @PropSync('config', {
    type: Object,
    required: true
  })
  public payload!: PromotionData['config']

  @Prop({
    type: String,
    required: true
  })
  public type!: PromotionType

  public readonly Icons = DashmixIconName
  public readonly Theme = DashmixTheme
  public readonly PromotionType = PromotionType
  public readonly form = 'editPromotion'

  public message: string = ''
  public messageSummary: string = ''
  public isDefault: boolean = true
  public isDefaultSummary: boolean = true

  public get defaultMessage () {
    return this.$t(`settings.promotions.form.discountMessage.type.${this.type}`).toString()
  }

  public get defaultMessageSummary () {
    return this.$t(`settings.promotions.form.discountMessageSummary.type.${this.type}`).toString()
  }

  @Watch('type')
  public onTypeUpdate () {
    if (this.isDefault) {
      this.message = this.defaultMessage
    }
    if (this.isDefaultSummary) {
      this.messageSummary = this.defaultMessageSummary
    }

    this.payload = {
      message: this.message,
      messageSummary: this.messageSummary
    }
  }

  @Watch('message')
  public onMessageUpdate () {
    this.isDefault = !this.message || this.message === this.defaultMessage

    this.payload = {
      ...this.payload,
      message: this.message
    }
  }

  @Watch('messageSummary')
  public onMessageSummaryUpdate () {
    this.isDefaultSummary = !this.messageSummary || this.messageSummary === this.defaultMessageSummary

    this.payload = {
      ...this.payload,
      messageSummary: this.messageSummary
    }
  }
}

export default PromotionConfigForm
