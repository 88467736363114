










































import { Component, Mixins, ProvideReactive, Watch } from 'vue-property-decorator'

import { BreadcrumbsProps, DashmixIconName, Identifier } from '@d24/modules'

import { Authentication, AuthServiceType, ICollection } from '@movecloser/front-core'
import { Inject } from '@plugin/inversify'

import { EditModeLayout } from '@component/EditModeLayout'
import FormCheckbox from '@component/form/Checkbox/Checkbox'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'
import { UserModel } from '@module/auth/contracts/models'

import { initBreadcrumbs } from '../../helpers'
import FeedEntry, { FeedEntryFormMode } from '../../components/FeedEntry.vue'
import {
  FeedEntriesRepositoryType,
  FeedEntryData,
  FeedEntryModel,
  FeedModel,
  FeedsRepositoryType,
  FeedStatus,
  FeedStatusBadge,
  IFeedEntriesRepository,
  IFeedsRepository
} from '../../contracts'
import { UpdateFeedIntention, UpdateFeedPayload } from '../../intentions/UpdateFeedIntention'

export interface FeedEntriesListItem extends Partial<FeedEntryData> {
  mode: FeedEntryFormMode
  hasBeenDeleted?: boolean
}

export enum SubmitMode {
  Default = 'default',
  End = 'end',
  Live = 'live',
  Lock = 'lock',
  RemoveLock = 'removeLock'
}

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Component({
  name: 'EditFeed',
  components: { EditModeLayout, FormCheckbox, FeedEntry }
})
class EditFeed extends Mixins<IUserAware>(UserAware) {
  @Inject(AuthServiceType)
  private authService!: Authentication<UserModel>

  @Inject(FeedsRepositoryType)
  private feedsRepository!: IFeedsRepository

  @Inject(FeedEntriesRepositoryType)
  private feedEntriesRepository!: IFeedEntriesRepository

  @ProvideReactive('feedEnded')
  private feedEnded: boolean = false

  @ProvideReactive('feedId')
  private feedId: Identifier | null = null

  @ProvideReactive('isFeedEditable')
  private isFeedEditable: boolean = false

  /**
   * Helpers
   */
  public FeedStatusBadge = FeedStatusBadge
  public Icon = DashmixIconName

  /**
   * State
   */
  public feed: FeedModel | null = null
  public feedEntries: ICollection<FeedEntryModel> | null = null
  public feedEntryData: FeedEntriesListItem[] = []
  public feedOptions: string[] = []
  public isLoading: boolean = false
  public isSending: boolean = false
  public status: FeedStatus = FeedStatus.New
  public SubmitMode = SubmitMode
  public title: string = ''

  /**
   * Getters
   */
  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('content.feeds.listTitle')}`,
          target: { name: 'content.feeds.list' }
        },
        {
          label: this.title,
          target: { name: 'content.feeds.edit' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get entriesCount (): number {
    return this.feed?.entriesCount || 0
  }

  public get isDraft (): boolean {
    if (!this.feed) return false

    return this.feed.status === FeedStatus.New
  }

  public get isLive (): boolean {
    if (!this.feed) return false

    return this.feed.status === FeedStatus.Live
  }

  public get isLocked (): boolean {
    if (!this.feed) return false

    return this.feed.lockedBy !== null
  }

  public get checkboxItems () {
    const items = []

    if (this.feed?.isLocked()) {
      items.push(
        {
          key: 'removeLock',
          label: String(this.$t('content.feeds.removeLock'))
        }
      )
    } else {
      items.push(
        {
          key: 'lock',
          label: String(this.$t('content.feeds.lock'))
        }
      )
    }

    return items
  }

  public get hasEnded (): boolean {
    if (!this.feed) return false

    return this.feed.status === FeedStatus.Ended
  }

  mounted () {
    this.feedId = parseInt(this.$route.params.id, 10)
    this.feedEnded = this.getIsEnded()
    this.loadData()
  }

  public getIsEditable (): boolean {
    if (!this.feed) return false

    return this.canEditContents &&
      this.isLocked &&
      this.feed.lockedBy === this.authService.getUserId() &&
      !this.hasEnded
  }

  public getIsEnded (): boolean {
    if (!this.feed) return false

    return this.feed.status === FeedStatus.Ended
  }

  public addEntry (payload: FeedEntriesListItem) {
    const newEntry = payload
    newEntry.mode = FeedEntryFormMode.View
    this.feedEntryData.splice(0, 1, newEntry)
    this.feedEntryData.unshift(EditFeed.getEmptyEntry(FeedEntryFormMode.Create))
  }

  public composeIntention (mode: SubmitMode): UpdateFeedPayload {
    const status = mode === SubmitMode.Live
      ? FeedStatus.Live
      : mode === SubmitMode.End
        ? FeedStatus.Ended
        : this.feed?.status
          ? this.feed?.status
          : FeedStatus.New

    let shouldLock = mode === SubmitMode.Lock
      ? true
      : mode === SubmitMode.RemoveLock
        ? false
        : this.isLocked

    if (this.feedOptions.includes('removeLock')) {
      shouldLock = false
    }

    return {
      title: this.feed?.title || '',
      description: this.feed?.description || '',
      lock: shouldLock,
      lockForce: this.feedOptions.includes('lockForce'),
      status: status || this.feed?.status || FeedStatus.New
    }
  }

  public deleteEntry (index: number) {
    const deleted = this.feedEntryData[index]
    this.feedEntryData.splice(index, 1, { ...deleted, hasBeenDeleted: true })
  }

  private getFeedData (entries: ICollection<FeedEntryModel>): FeedEntriesListItem[] {
    const fetchedEntries = [...entries].map(
      (entry: FeedEntryModel) => {
        return {
          ...entry.toObject(),
          hasBeenDeleted: Boolean(entry.deletedAt),
          mode: FeedEntryFormMode.View
        }
      }
    )

    return [EditFeed.getEmptyEntry(FeedEntryFormMode.Create), ...fetchedEntries]
  }

  private async loadEntries () {
    if (!this.feedId) {
      throw new Error('missing feed id')
    }
    return await this.feedEntriesRepository
      .loadCollection(this.feedId, { sort: '-createdAt' })
  }

  private async loadData () {
    this.isLoading = true
    try {
      await this.loadFeed()
      const entries = await this.loadEntries()
      this.feedEntries = entries
      this.feedEntryData = this.getFeedData(entries)
      this.resetCheckboxOptions()
    } catch (error) {
      console.warn(error)
    } finally {
      this.isLoading = false
    }
  }

  private async loadFeed () {
    if (!this.feedId) {
      throw new Error('missing feed id')
    }
    this.isSending = true
    try {
      const feed = await this.feedsRepository.load(this.feedId)
      this.feed = feed
      this.status = feed.status
      this.title = feed.title
      this.resetCheckboxOptions()
    } catch (error) {
      console.warn(error)
    } finally {
      this.isSending = false
    }
  }

  private resetCheckboxOptions () {
    this.feedOptions = []
  }

  public async submit (mode: SubmitMode) {
    if (!this.feedId) {
      throw new Error('missing feed id')
    }
    const intention = new UpdateFeedIntention(this.composeIntention(mode))
    this.isSending = true
    try {
      await this.feedsRepository.update(this.feedId, intention)
      await this.loadFeed()
    } catch (error) {
    } finally {
      this.isSending = false
    }
  }

  @Watch('feed', { deep: true })
  private updateProviders () {
    this.feedEnded = this.getIsEnded()
    this.feedId = this.feed?.id || null
    this.isFeedEditable = this.getIsEditable()
  }

  private static getEmptyEntry (mode: FeedEntryFormMode): FeedEntriesListItem {
    return {
      id: Math.random(),
      content: '',
      mode
    }
  }
}

export default EditFeed
