// Copyright © 2021 Move Closer

import { Model, ModelPayload } from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'

import { Author } from './author'
import {
  AuthorModel,
  ContentStatus,
  ISimpleVariant,
  IVariant,
  SimpleVariantData,
  SimpleVariantModel,
  VariantData,
  VariantUpdatePayload
} from '../contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class SimpleVariant extends Model<SimpleVariantData> implements ISimpleVariant {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'id',
      'author',
      'editor',
      'name',
      'addons',
      'labels',
      'status',
      'publishedDate',
      'createdAt',
      'updatedAt'
    ]
  }

  public isEditor (user: Identifier|null): boolean {
    return this._data.editor === null || this._data.editor.id === user
  }

  protected relatesToAuthor (value: ModelPayload) {
    return this.hasOne(Author, value)
  }

  protected relatesToEditor (value: ModelPayload) {
    return this.hasOne(Author, value)
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export class Variant extends Model<VariantData> implements IVariant {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'addons',
      'content',
      'createdAt',
      'publishedDate',
      'author',
      'editor',
      'grid',
      'id',
      'lockedAt',
      'modules',
      'name',
      'properties',
      'status',
      'title',
      'updatedAt',
      'url'
    ]
  }

  public changeAuthor (newAuthor: AuthorModel): void {
    this._data.author = newAuthor
  }

  public getProperty<T> (propertyKey: string): T | null {
    if (!this._data.properties || !this._data.properties[propertyKey]) {
      return null
    }
    return this._data.properties[propertyKey]
  }

  public isAuthor (user: Identifier|null): boolean {
    return this._data.author.id === user
  }

  public isDraft (): boolean {
    return this._data.status === ContentStatus.Draft
  }

  public isEditable (user: Identifier|null): boolean {
    return this.isDraft() && this.isEditor(user)
  }

  public isEditor (user: Identifier|null): boolean {
    return this._data.editor === null || this._data.editor.id === user
  }

  public isLive (): boolean {
    return this._data.addons.includes('liveFeed')
  }

  public isRemovable (user: Identifier|null): boolean {
    return [ContentStatus.Draft, ContentStatus.InAcceptance].includes(this._data.status as ContentStatus) &&
      this.isEditor(user)
  }

  public isSmart (): boolean {
    return this._data.addons.includes('smartRelated')
  }

  public toSimpleVariant (): SimpleVariantModel {
    return SimpleVariant.hydrate(this._data)
  }

  public toUpdatePayload (): VariantUpdatePayload {
    return {
      addons: this._data.addons,
      author: this._data.author.id,
      modules: this._data.modules,
      name: this._data.name,
      properties: this._data.properties,
      title: this._data.title
    }
  }

  protected isAuthorOrEditor (user: Identifier|null): boolean {
    return this.isAuthor(user) && this.isEditor(user)
  }

  public setProperty<T> (propertyKey: string, payload: T): void {
    this._data.properties[propertyKey] = payload
  }

  protected relatesToAuthor (value: ModelPayload) {
    return this.hasOne(Author, value)
  }

  protected relatesToEditor (value: ModelPayload) {
    return this.hasOne(Author, value)
  }
}
