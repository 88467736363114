// Copyright © 2021 Move Closer

import { DashmixSelectItem, RelatedType } from '@d24/modules'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum SmartStrategy {
  All = 'all',
  Excluded = 'excluded',
  Included = 'included'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum SmartType {
  Author = 'author',
  Category = 'category',
  Gallery = 'gallery',
  Tag = 'tag'
}

export enum SingleSmartType {
  SmartAuthor = 'smartAuthor',
  SmartCategory = 'smartCategory',
  SmartGallery = 'smartGallery',
  SmartTag = 'smartTag'
}

/**
 * @author Javlon Khalimjonov
 */
export type ContentLinksType = RelatedType.Content | RelatedType.SmartCategory | RelatedType.SmartAuthor | RelatedType.SmartGallery | RelatedType.SmartTag

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const smartMarkers: Record<SmartType, string[]> = {
  [SmartType.Author]: ['FIRST_NAME', 'LAST_NAME', 'NICK', 'DESCRIPTION', 'EMAIL'],
  [SmartType.Category]: ['TITLE'],
  [SmartType.Gallery]: ['TITLE'],
  [SmartType.Tag]: ['NAME']
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const smartRelatedStrategyOptions: DashmixSelectItem[] = [
  { label: 'ze wszystkimi', value: SmartStrategy.All },
  { label: 'z wyłączeniem', value: SmartStrategy.Excluded },
  { label: 'tylko dla', value: SmartStrategy.Included }
]

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const smartRelatedTypeOptions: DashmixSelectItem[] = [
  { label: 'Autor', value: SmartType.Author },
  { label: 'Kategoria', value: SmartType.Category },
  { label: 'Galeria', value: SmartType.Gallery },
  { label: 'Tag', value: SmartType.Tag }
]

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const singleSmartRelatedTypeOptions: DashmixSelectItem[] = [
  { label: 'Autor', value: SingleSmartType.SmartAuthor },
  { label: 'Kategoria', value: SingleSmartType.SmartCategory },
  { label: 'Galeria', value: SingleSmartType.SmartGallery },
  { label: 'Tag', value: SingleSmartType.SmartTag }
]
