





































import { Component, PropSync, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@d24/modules'

import { FormInput } from '../../../shared/components/form/Input/Input'

import { Inject } from '@plugin/inversify'
import { MePayload } from '@module/auth/contracts/data'
import { IMeRepository, MeRepositoryType } from '@module/auth/contracts/repositories'

@Component({
  name: 'ChangeBasicInformationForm',
  components: { FormInput }
})
export class ChangeBasicInformationForm extends Vue {
  @PropSync('formData', { type: Object, required: true })
  public payload!: MePayload

  @Inject(MeRepositoryType)
  protected meRepository!: IMeRepository

  public disabled: boolean = true
  public errors: string = ''
  public formName: string = 'changeBasicInformationsForm'
  public readonly Icons = DashmixIconName
  public isLoading: boolean = true
  public isSaving: boolean = false

  public editBasicInformation () {
    this.isSaving = true

    this.meRepository.editBasicInformation(this.payload.firstName, this.payload.lastName, this.payload.nickname).then(() => {
      this.isSaving = false
    }).catch(error => console.warn(error)
    ).finally(() => {
      this.isSaving = false
    })
  }

  public onSave () {
    this.editBasicInformation()
  }
}

export default ChangeBasicInformationForm
