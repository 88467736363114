import SimpleMDE from 'simplemde'

import { Shortcut } from '../MarkdownEditor.contracts'

/**
 * @author Adrian Gajos <adrian.gajos@movecloser.pl>
 */
export const paywallShortcut: Shortcut = {
  action: ({ codemirror }: SimpleMDE) => {
    codemirror.replaceSelection('\n\n' + '#PAYWALL' + '\n\n')
  },
  className: 'fas fa-wallet',
  name: 'paywall',
  title: 'Wstaw paywall'
}
