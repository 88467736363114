


































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { ICollection, IModal, ModalType } from '@movecloser/front-core'
import { SlickItem, SlickList } from 'vue-slicksort'

import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@plugin/inversify'
import { ScrollHandle } from '@/shared/directives/scroll'

import { ISetItemsRepository, ScrollHandleConfig, SetItemsRepositoryType, SetModel, SneakPeekModel } from '../contracts'
import SetDraggableItem from './SetDraggableItem.vue'

export const defaultScrollConfig: ScrollHandleConfig = {
  throttleInterval: 500,
  threshold: 90
}

@Component({
  name: 'SetDraggableList',
  components: { SetDraggableItem, SlickList, SlickItem },
  directives: { ScrollHandle }
})
class SetDraggableList extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public incrementalLoading!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public isDisabled?: boolean

  @Prop({ type: Object, required: false, default: () => defaultScrollConfig })
  public scrollConfig!: ScrollHandleConfig

  @Prop({ type: Object, required: true })
  public set!: SetModel

  @PropSync('items', { type: Array, required: true, default: () => [] })
  public setItems!: ICollection<SneakPeekModel>

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(SetItemsRepositoryType)
  protected setItemRepository!: ISetItemsRepository

  public hoveredItemId: number | null = null

  public onHover (itemId: Identifier) {
    this.hoveredItemId = itemId
  }

  public handleScroll () {
    this.$emit('scroll-handle')
  }

  protected remove (itemId: Identifier) {
    this.setItemRepository.remove(this.set.id, itemId).then(() => {
      this.$emit('on-remove', itemId)
    }).catch(error => {
      console.log(error)
    })
  }

  public lock (itemId: Identifier) {
    this.$emit('lockItem', itemId)
  }

  public move (args: { newIndex: number; oldIndex: number }) {
    this.$emit('moveItem', args)
  }

  public unlock (itemId: Identifier) {
    this.$emit('unlockItem', itemId)
  }
}

export default SetDraggableList
