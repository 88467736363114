



















































































































import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import { ConjunctionOperator, IModal, ModalType } from '@movecloser/front-core'
import { DashmixIconName, DashmixTheme, DateTimePickerType } from '@d24/modules'

import { Inject } from '@plugin/inversify'
import { Modals } from '@/config/modals'

import { FormCheckbox, FormInput } from '@component/form'
import { Loader } from '@component/Loader/Loader'
import { DashmixSwitch } from '@component/Switch/Switch'

import { CreateSetIntentionPayload } from '../intentions/CreateSetIntention'
import { EditSetIntentionPayload } from '../intentions/EditSetIntention'
import { ResetListItemsModalPayload, SetItemsDate, SetProperties, SetSource } from '../contracts'
import { SetSourceForm } from './SetSourceForm.vue'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<SetForm>({
  name: 'SetForm',
  components: {
    Loader,
    FormCheckbox,
    FormInput,
    DashmixSwitch,
    SetSourceForm
  },

  mounted () {
    if (this.setItemsDate.setItemsFrom || this.setItemsDate.setItemsTo) {
      this.canCreateSetItemsDates = true
    }
  },

  created () {
    this.conjunction = this.payload.properties?.conjunction || ConjunctionOperator.And
    this.sources = this.payload.properties?.src || []
    this.title = this.payload.title || ''
    this.autoDates = this.payload.properties?.autoDates || false
    this.showOlderThanHours = this.payload.properties?.showOlderThanHours || null

    if (this.payload.properties?.showOlderThanHours) {
      this.canShowOlderThanHours = true
    }
  }
})
export class SetForm extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  public editable!: boolean

  @Prop({
    type: String,
    required: true
  })
  public form!: string

  @Prop({
    type: Boolean,
    required: true,
    default: false
  })
  public isEditMode!: boolean

  @Prop({
    type: Boolean,
    required: false
  })
  public isLoading!: boolean

  @Prop({
    type: Boolean,
    required: true
  })
  public isSaving!: boolean

  @Prop({
    type: Object,
    required: true
  })
  public payload!: CreateSetIntentionPayload | EditSetIntentionPayload

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public setName!: string

  @PropSync('itemsDate', {
    type: Object,
    required: true
  })
  public setItemsDate!: SetItemsDate

  @Inject(ModalType)
  private modalConnector!: IModal

  public buttonTheme = DashmixTheme
  public canCreateSetItemsDates: boolean = false
  public autoDates: boolean = false
  public conjunction: ConjunctionOperator = ConjunctionOperator.And
  public dateTimePickerType = DateTimePickerType
  public Icons = DashmixIconName
  public shouldRefresh: boolean = false
  public sources: SetSource[] = [{
    type: '',
    value: ''
  }]

  public title?: string = ''
  public canShowOlderThanHours: boolean = false
  public showOlderThanHours: number | null = null

  public premiumOptions = [
    {
      label: this.$t('sets.create.form.premium.no_limit'),
      value: 0
    },
    {
      label: this.$t('sets.create.form.premium.no_premium'),
      value: 1
    },
    {
      label: this.$t('sets.create.form.premium.premium_only'),
      value: 2
    }
  ]

  public conjunctionOptions = [
    {
      label: this.$t('sets.create.form.conjunction.type.and'),
      value: ConjunctionOperator.And
    },
    {
      label: this.$t('sets.create.form.conjunction.type.or'),
      value: ConjunctionOperator.Or
    }
  ]

  public get formName (): string {
    switch (this.isEditMode) {
      case true:
        return 'editSet'
      case false:
        return 'createSet'
    }
  }

  public get formTitle (): string {
    switch (this.isEditMode) {
      case true:
        return `${this.$t('sets.edit.form.header', { title: this.setName })}`
      case false:
        return `${this.$t('sets.create.form.header')}`
    }
  }

  public get initSource () {
    return {
      type: '',
      value: ''
    }
  }

  public addSource () {
    this.sources.push({ ...this.initSource })
  }

  public onSourceChange (source: SetSource, index: number) {
    const newSources = this.sources
    newSources[index] = source
    this.sources = newSources
  }

  public onSourceDeleted (index: number) {
    const newSources = this.sources
    newSources.splice(index, 1)
    this.sources = newSources
  }

  public toggleCanCreateFromDates () {
    this.canCreateSetItemsDates = !this.canCreateSetItemsDates

    if (!this.canCreateSetItemsDates) {
      this.setItemsDate.setItemsFrom = ''
      this.setItemsDate.setItemsTo = ''
    }
  }

  public toggleCanShowOlderThanHours () {
    this.canShowOlderThanHours = !this.canShowOlderThanHours

    if (!this.canShowOlderThanHours) {
      this.showOlderThanHours = null
    }
  }

  public toggleReset (value: boolean) {
    this.$emit('reset', value)
  }

  public toggleResort (value: boolean) {
    this.$emit('resort', value)
  }

  protected composeProperties (): SetProperties {
    return {
      autoDates: this.autoDates,
      locked: this.payload.properties.locked,
      src: this.sources,
      conjunction: this.conjunction,
      setItemsDate: this.setItemsDate,
      showOlderThanHours: this.showOlderThanHours,
      skipPremium: this.payload.properties?.skipPremium || false,
      generateHTML: this.payload.properties?.generateHTML || false
    }
  }

  protected openResetModal () {
    const payload: ResetListItemsModalPayload = {
      list: this.setName,
      onClose: () => this.modalConnector.close(),
      onConfirm: (resetLockedItems) => {
        this.$emit('reset', {
          reset: true,
          resetLockedItems
        })
        this.modalConnector.close()
      }
    }

    this.modalConnector.open(Modals.ResetListItemsModal, payload, { closableWithOutsideClick: true })
  }

  @Watch('conjunction')
  protected onConjunctionChange (value: ConjunctionOperator) {
    this.payload.properties = {
      ...this.payload.properties,
      conjunction: value
    }
  }

  @Watch('sources', { deep: true })
  protected onSourcesChange () {
    const payload = this.payload
    payload.properties = this.composeProperties()
    this.$emit('sources-updated', payload)
  }

  @Watch('title')
  protected onTitleChange () {
    this.$emit('title-updated', this.title)
  }

  @Watch('showOlderThanHours')
  protected onShowOlderThanHours () {
    this.$emit('show-older-than-hours-updated', this.showOlderThanHours)
  }
}

export default SetForm
