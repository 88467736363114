// Copyright © 2021 Move Closer

import {
  ICollection,
  Injectable,
  IResponse,
  MappingConfig,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'
import { resolveFromStatus } from '@/shared/exceptions/connector-errors'
import { Query } from '@/shared/contracts/query'
import { contentAdapterMap } from '../models/content.adapter'
import { ContentData, RegistryData, RegistryModel } from '../contracts/models'
import { IRegistryRepository } from '../contracts/repositories'
import { Registry } from '@module/content/models/registry'

/**
 * @author Piotr Niewczas <piotr.niewczas@movecloser.pl>
 */
@Injectable()
export class RegistryRepository extends Repository<RegistryData, Registry> implements IRegistryRepository {
  protected map: MappingConfig = contentAdapterMap
  protected useAdapter = true

  public async load (id: ContentData['id']): Promise<RegistryModel> {
    const response: IResponse = await this.connector.call(
      'content',
      'get',
      { id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeModel(response.data.data, Registry)
  }

  public async loadCollection (query: Query): Promise<ICollection<RegistryModel>> {
    const response: IResponse = await this.connector.call(
      'registry',
      'list',
      {},
      {
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
    return this.composeCollection(
      response.data.data,
      Registry,
      response.data.meta
    )
  }
}
