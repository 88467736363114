// Copyright © 2022 Move Closer

import { Component } from 'vue-property-decorator'
import { AnyObject, RequestAccessModuleForm } from '@d24/modules'

import { Inject } from '@plugin/inversify'
import { FormsRepositoryType, IFormsRepository } from '@module/forms/contracts'

@Component<RequestAccessModuleForms>({
  name: 'RequestAccessModuleForms'
})
export class RequestAccessModuleForms extends RequestAccessModuleForm {
  @Inject(FormsRepositoryType)
  protected readonly formRepository!: IFormsRepository

  public eventIndex: AnyObject= {}

  public static getInitialContent = RequestAccessModuleForm.getInitialContent
  public static getInitialConfig = RequestAccessModuleForm.getInitialConfig

  public onEventUpdate (uuid: string): void {
    this._content.uuid = uuid

    if (!uuid || !this.eventIndex[uuid]) {
      return
    }

    this._content.eventName = this.eventIndex[uuid].title
  }

  public async loadEvents (): Promise<void> {
    this.formRepository.loadCollection({}).then(items => {
      items.forEach((item: AnyObject) => {
        this.eventIndex[item.uuid] = { ...item }
      })

      this.events = [...items].map((item: AnyObject) => {
        return {
          label: item.title,
          value: item.uuid
        }
      })
    })
  }
}

export default RequestAccessModuleForms
