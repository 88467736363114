// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'

import { IPromotion, PromotionData } from '../contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export class Promotion extends Model<PromotionData> implements IPromotion {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'codesCount',
      'codesUsed',
      'conditions',
      'config',
      'coupon',
      'couponType',
      'endAt',
      'id',
      'isActive',
      'name',
      'productTarget',
      'productType',
      'startAt',
      'type'
    ]
  }

  public displayName (): string {
    return this._data.name
  }

  public identifier (): Identifier {
    return this._data.id
  }
}
