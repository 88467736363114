// Copyright © 2021 Move Closer

import { Model, ModelPayload, IUser, Token as AuthToken } from '@movecloser/front-core'

import { IToken, TokenData, UserModel } from '../contracts/models'
import { User } from './user'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class Token extends Model<TokenData> implements IToken {
  protected boot (): void {
    this.initialValues = {
      tokenType: 'Bearer',
      expiresIn: null
    }
    this.modelProperties = ['accessToken', 'tokenType', 'expiresAt', 'me']
  }

  public getUser (): UserModel {
    return this._data.me
  }

  public toAuthToken (): AuthToken {
    return {
      accessToken: this._data.accessToken,
      expiresAt: this._data.expiresAt,
      tokenType: this._data.tokenType
    }
  }

  /**
   * Returns relation to User model.
   */
  protected relatesToMe (value: ModelPayload) {
    return this.hasOne<IUser>(User, value)
  }
}
