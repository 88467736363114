// Copyright © 2021 Move Closer

import {
  ConnectorMiddleware,
  FoundResource,
  Headers,
  IModal,
  Injectable,
  IResponse,
  Payload
} from '@movecloser/front-core'
import { Modals } from '@/config/modals'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Injectable()
export class LoginMiddleware implements ConnectorMiddleware {
  protected extendsSession: boolean = true
  protected modalConnector: IModal

  public constructor (service: IModal) {
    this.modalConnector = service
  }

  public afterCall (response: IResponse): void {
    if (response.status === 401 && this.extendsSession) {
      this.modalConnector.open(Modals.Login, {})
    }
  }

  public beforeCall (resource: FoundResource, headers: Headers, body: Payload) {
    this.extendsSession = resource.meta.extendsSession ?? true
    return { headers, body }
  }
}

export const LoginMiddlewareType = Symbol.for('LoginMiddleware')
