// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'

import { OwnerData, IOwner } from '@module/root/contracts/models'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export class Owner extends Model<OwnerData> implements IOwner {
  protected boot (): void {
    this.initialValues = {}
    this.modelProperties = ['id', 'avatar', 'firstName', 'lastName']
  }

  public fullName (): string {
    return `${this._data.firstName} ${this._data.lastName}`
  }

  public shortName (): string {
    return this._data.firstName.split(' ')[0] || ''
  }
}
