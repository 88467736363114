// Copyright © 2021 Move Closer

import { DashmixIconName, DashmixTheme, TableHead, TableSearchBarAction } from '@d24/modules'

import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'

import { CommentModel, CommentStatus } from '../contracts'
import { canManageContentsComments } from '../guards'

export const commentTableHead: TableHead = [
  {
    column: 'title',
    label: 'Tytuł',
    sortable: false,
    isSorted: false,
    width: '1.5fr'
  },
  {
    column: 'content',
    label: 'Treść',
    sortable: false,
    isSorted: false,
    width: '1.5fr'
  },
  {
    column: 'author',
    label: 'Autor / Legacy',
    sortable: false,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'site',
    label: 'Strona',
    sortable: false,
    isSorted: false,
    width: '110px'
  },
  {
    column: 'status',
    label: 'Status',
    sortable: false,
    isSorted: false,
    width: '110px'
  },
  {
    column: 'createdAt',
    label: 'Data utworzenia',
    sortable: false,
    isSorted: false,
    width: '1fr'
  }
]

export enum CommentsActions {
  Accept = 'accept',
  Delete = 'delete'
}

export const commentsRowActionsFactory = (domain: Domain, user: UserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canManageContentsComments(domain, user)) {
    /**
     * This action is available only when status of Comment is `Rejected` or `InAcceptance`
     */
    actions.push(
      {
        label: 'Zakceptuj',
        icon: DashmixIconName.CheckSolid,
        isBulkAction: true,
        action: CommentsActions.Accept,
        theme: DashmixTheme.Success,
        guard: (data: unknown): boolean => {
          const model = data as CommentModel
          return model.status === CommentStatus.Rejected || model.status === CommentStatus.InAcceptance
        }
      }
    )
  }

  if (canManageContentsComments(domain, user)) {
    /**
     * This action is available only when status of Comment is `Accepted` or `InAcceptance`
     */
    actions.push(
      {
        label: 'Odrzuć',
        icon: DashmixIconName.TrashAltSolid,
        needsDoubleClick: true,
        isBulkAction: true,
        action: CommentsActions.Delete,
        theme: DashmixTheme.Danger,
        guard: (data: unknown): boolean => {
          const model = data as CommentModel
          return model.status === CommentStatus.Accepted || model.status === CommentStatus.InAcceptance
        }
      }
    )
  }

  return actions
}
