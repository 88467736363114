





















import { IModal, ModalType } from '@movecloser/front-core'
import { DashmixIconName, TableHead, TableRowElement, TableSearchBarAction } from '@d24/modules'
import { Component, Mixins, Watch } from 'vue-property-decorator'

import { DropdownActions } from '@/shared/contracts/content'
import { Inject } from '@/shared/plugins/inversify'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'
import { Query } from '@/shared/contracts/query'

import { CouponRepositoryType, ICouponRepository } from '../contracts/repositories'
import { CouponsTableRow } from '../components/CouponsTableRow.vue'
import { CouponsActions, couponsRowActionsFactory, couponsTableHead } from '../maps/coupons'

@Component<CouponsTable>({
  name: 'CouponsTable',
  components: { InteractiveTable }
})
export class CouponsTable extends Mixins<IUserAware>(UserAware) {
  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(CouponRepositoryType)
  protected couponsRepository!: ICouponRepository

  public readonly Icons = DashmixIconName

  public isLoading = false

  public rowComponent = CouponsTableRow
  public tableHead: TableHead = couponsTableHead.map(item => {
    return {
      ...item,
      label: `${this.$t(item.label)}`
    }
  })

  public tableData: TableRowElement[] = []

  public totalPages: number = 0
  public itemsTotal: number = 0

  public rowActions: TableSearchBarAction[] = []

  public actions: DropdownActions = {}

  public query: Query = {}

  public get promotionId () {
    return Number(this.$route.params.id)
  }

  mounted () {
    this.loadList(this.query)

    this.rowActions = couponsRowActionsFactory(this.domain, this.user)
  }

  public setQuery (query: Query) {
    if (JSON.stringify(this.query) === JSON.stringify(query)) {
      return
    }
    this.query = query
  }

  protected loadList (query: Query): void {
    this.isLoading = true
    this.couponsRepository.list(this.promotionId, query).then(collection => {
      this.isLoading = false

      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: true,
          data: model
        }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.itemsTotal = collection.meta.total
    }).catch(error => {
      console.debug(error)
      this.isLoading = false
    })
  }

  @Watch('query', { deep: true })
  protected onQueryChange (query: Query): void {
    this.loadList(query)
  }
}

export default CouponsTable
