// Copyright © 2021 Move Closer

export function getImageTypeFromUrl (url: string): string {
  const silted = url.split(/[#?]/)[0]

  if (!silted && !silted.split('.')) {
    return ''
  }

  const type = silted.split('.').pop()

  if (!type) {
    return ''
  }
  return type.trim()
}
