// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'

import { Addon } from '@module/content/maps/variant'
import { authorAdapterMap } from '@module/content/models/author.adapter'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const variantAdapterMap: MappingConfig = {
  addons: {
    type: MappingTypes.Function,
    value: item => item.addons.map((a: string) => {
      if (a === 'additional-titles') {
        return Addon.AdditionalTitles
      }
      return a
    })
  },
  author: {
    type: MappingTypes.Adapter,
    map: authorAdapterMap,
    value: 'author'
  },
  content: 'contentId',
  createdAt: 'createdAt',
  publishedDate: 'publishedDate',
  editor: {
    type: MappingTypes.Adapter,
    map: authorAdapterMap,
    value: 'editor'
  },
  grid: 'grid',
  id: 'id',
  lockedAt: 'lockedAt',
  modules: 'modules',
  name: 'name',
  properties: 'properties',
  status: 'status',
  title: 'title',
  updatedAt: 'updatedAt',
  url: {
    type: MappingTypes.Function,
    value: item => {
      return item.status === 'draft' ? null : item.url
    }
  }
}
