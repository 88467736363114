






























import { Component, Prop, Vue } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { Inject } from '@plugin/inversify'

import { LockConflictModalPayload } from '../contracts'
import { defaultDateFormat } from '@module/content/helpers/formatting'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Component({
  name: 'LockConflictModal'
})
export class LockConflictModal extends Vue {
  @Prop({ type: Object, required: true })
  protected payload!: LockConflictModalPayload

  @Inject(DateTimeType)
  protected readonly dateTime!: IDateTime

  public get lockedAt (): string {
    return this.dateTime.parseToFormat(this.payload.lockedAt, defaultDateFormat)
  }

  public closeModal (): void {
    this.$emit('close')
    this.payload.onClose()
  }

  public preview (): void {
    this.$emit('preview')
    this.payload.onPreview()
  }
}

export default LockConflictModal
