// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'
import { AdUnitData } from '@module/content/contracts'

export class AdUnit extends Model<AdUnitData> {
  protected boot (): void {
    this.modelProperties = [
      'fullPath',
      'id',
      'name',
      'sizes'
    ]
  }
}
