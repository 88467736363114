// Copyright © 2021 Move Closer

import { defineComponent } from '@vue/composition-api'

import { figureProps, useFigure } from './Figure.hook'
import { FigureProps } from './Figure.contracts'
import { DashmixIconName } from '@d24/modules'

/**
 * @author  Olga Milczek <olga.milczek@movecloser.pl>
 */
export const Figure = defineComponent({
  name: 'Figure',
  props: figureProps,

  setup (props: FigureProps) {
    return {
      ...useFigure(props),
      icons: DashmixIconName
    }
  },

  template: `
    <figure class="figure">
      <div class="figure__img-container" >
        <D24File class="figure__img" :style="{ width: width, height: height }"
                 :file="{type: imageType, thumbnail: url }"  />
        <div class="figure__button">
          <slot name="button" ></slot>
        </div>
      </div>
      <template v-if="description">
         <figcaption class="text-center" :title="description">{{ description | trim(85) }}</figcaption>
      </template>
    </figure>`
})
