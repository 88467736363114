// Copyright © 2021 Move Closer

import { Injectable, IResponse, MappingConfig, Repository, ResourceActionFailed } from '@movecloser/front-core'
import { IMeRepository } from '@module/auth/contracts/repositories'
import { MeData, MeModel } from '@module/auth/contracts/models'
import { meAdapterMap } from '@module/auth/models/me.adapter'
import { resolveFromStatus } from '@/shared/exceptions/connector-errors'
import { Me } from '@module/auth/models/me'

@Injectable()
export class MeRepository extends Repository<MeData> implements IMeRepository {
  protected useAdapter = true
  protected map: MappingConfig = meAdapterMap

  public async editAvatar (avatar: File): Promise<void> {
    const formData: FormData = new FormData()
    formData.append('file', avatar)

    const response: IResponse = await this.connector.call(
      'me', 'updateAvatar', {}, formData
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        {}
      )
    }
  }

  public async editBasicInformation (firstName?: string, lastName?: string, nickname?: string): Promise<void> {
    const response: IResponse = await this.connector.call(
      'me',
      'editBasicInformation',
      {},
      { firstName, lastName, nickname }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async editPassword (actual: string, password: string, passwordConfirmation: string): Promise<void> {
    const response: IResponse = await this.connector.call(
      'me',
      'editPassword',
      {},
      { actual, password, passwordConfirmation }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async loadMeProfile (): Promise<MeModel> {
    const response: IResponse = await this.connector.call(
      'me',
      'load'
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeModel(response.data.me, Me)
  }
}
