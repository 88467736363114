










import { Component, Prop, Vue } from 'vue-property-decorator'

import { AlertTheme, Alert } from '@component/Alert'

/**
 * @author  Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'AddonErrors',
  components: { Alert }
})
export class AddonErrors extends Vue {
  @Prop({ type: Array, required: true })
  public readonly errors!: string[]

  public alertTheme: AlertTheme = AlertTheme.Danger
}

export default AddonErrors
