
































































import { AbstractElement, EmbeddableInBlock, ModuleSize } from '@d24/page-builder'
import { AnyModule, ModuleDriver, moduleDriverUiComponentRegistry, Space } from '@d24/modules'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import { EventbusType, IEventbus, IWindow, WindowType } from '@movecloser/front-core'
import { Fragment } from 'vue-fragment'
import { VueConstructor } from 'vue'

import { DeMarked } from '@/shared/helpers/demarked'
import { Inject } from '@plugin/inversify'
import { IRelatedService } from '@service/related'

import { ContentType } from '@module/content/contracts'

import { ModulesRegistryEntry, PageBuilderOperationMode } from '../PageBuilder/PageBuilder.contracts'

import { EmptyModuleForm } from './EmptyForm'
import { FormDrawer } from './FormDrawer'
import { ModuleBlock } from './ModuleWrapper.contracts'
import { moduleDriverFormComponentRegistry } from './ModuleWrapper.config'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'ModuleWrapper',
  components: {
    FormDrawer,
    Fragment
  }
})
export class ModuleWrapper extends AbstractElement<ModuleBlock> {
  @Prop({
    type: String,
    required: true
  })
  public readonly contentType!: ContentType

  @Prop({
    type: String,
    required: true
  })
  private readonly mode!: PageBuilderOperationMode

  @Prop({
    type: Object || null,
    required: false,
    default: null
  })
  private readonly modulesRegistry!: Record<ModuleDriver, ModulesRegistryEntry> | null

  @Prop({
    type: Object,
    required: true
  })
  public readonly relatedService!: IRelatedService

  /**
   * Element's content.
   */
  @PropSync('size', {
    type: Object,
    required: true
  })
  protected _size!: ModuleSize

  @Inject(EventbusType)
  public readonly eventBus!: IEventbus

  @Inject(WindowType)
  public readonly windowService!: IWindow

  /**
   * An instance of the `DeMarked` class.
   */
  public readonly DeMarked = DeMarked

  /**
   * @inheritDoc
   */
  public static readonly blockClassName: string = 'page-builder__block'

  /**
   * Determines whether the `<FormDrawer>` component is currently open.
   */
  public isDrawerOpen: boolean = false

  // TODO: Create mock of service.
  public readonly shareService = {}

  public get moduleSize (): { colSpan: number; rowSpan: number } {
    return this.module.size
  }

  created () {
    if (!this._inner.hasOwnProperty('isMobileVisible')) {
      this._inner.isMobileVisible = true
    }
    if (!this._inner.hasOwnProperty('isDesktopVisible')) {
      this._inner.isDesktopVisible = true
    }
  }

  /**
   * Display title.
   */
  public get displayTitle (): string {
    if (this.hasName && this.module.content.name) {
      return this.$options.filters?.trim(this.module.content.name, 24)
    }

    return this.hasTitle ? this.$options.filters?.trim(this.module.content.header.text, 24) : this.$t(`builder.drivers.${this.module.driver}`)
  }

  /**
   * The `VueConstructor` that will be used to display the element's questionnaire.
   */
  public get formComponent (): VueConstructor {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const component = moduleDriverFormComponentRegistry[this._inner.driver as ModuleDriver]

    if (typeof component !== 'function') {
      console.warn(`<ModuleWrapper>.[get]formComponent: There's no Vue component registered for the [${this._inner.driver}] driver!`)
      return EmptyModuleForm
    }

    return component
  }

  /**
   * Module has name to display
   */
  public get hasName (): boolean {
    return this.module.content.hasOwnProperty('name') && this.module.content.name !== ''
  }

  public get hasTitle (): boolean {
    return this.module.hasOwnProperty('content') && this.module.content.hasOwnProperty('header') &&
        this.module.content.header !== null && this.module.content.header.hasOwnProperty('text') &&
        this.module.content.header.text.length
  }

  public get image (): string {
    if (!this.modulesRegistry) {
      return ''
    }

    return this.modulesRegistry[this.module.driver].image
  }

  public get isVisible (): boolean {
    return this._inner.isVisible
  }

  public get isMobileVisible (): boolean {
    return this._inner.isMobileVisible ?? true
  }

  public get isLazyLoaded (): boolean {
    return this._inner.isLazyLoaded ?? true
  }

  public get isDesktopVisible (): boolean {
    return this._inner.isDesktopVisible ?? true
  }

  public get isRendering (): boolean {
    return this.mode === PageBuilderOperationMode.Render
  }

  public get module (): AnyModule {
    return this._inner as unknown as AnyModule
  }

  public set module (module: AnyModule) {
    this._inner = module as unknown as ModuleBlock
  }

  public get shortId (): string {
    return this.module.id.split('-').reverse()[0]
  }

  public get uiComponent (): VueConstructor {
    const component = moduleDriverUiComponentRegistry[this._inner.driver as ModuleDriver]

    if (typeof component !== 'function') {
      throw new Error(`<ModuleWrapper>.[get]uiComponent: There's no Vue component registered for the [${this._inner.driver}] driver!`)
    }

    return component
  }

  public onDrawerClose (): void {
    this.isDrawerOpen = false
  }

  public onEditBtnClick (): void {
    this.isDrawerOpen = true
  }

  public onDelete (): void {
    this.$emit('remove', this._inner)
  }

  public onSizeChange (newSize: Space): void {
    this._size = {
      ...this._inner.size,
      ...newSize
    }
  }

  public onToggleVisibilityClick (): void {
    this.module = {
      ...this._inner,
      isVisible: !this._inner.isVisible
    } as unknown as AnyModule
  }

  public onToggleLazyLoadedClick (): void {
    this.module = {
      ...this._inner,
      isLazyLoaded: !this._inner.isLazyLoaded
    } as unknown as AnyModule
  }

  public onToggleMobileVisibilityClick (): void {
    this.module = {
      ...this._inner,
      isMobileVisible: !this._inner.isMobileVisible
    } as unknown as AnyModule
  }

  public onToggleDesktopVisibilityClick (): void {
    this.module = {
      ...this._inner,
      isDesktopVisible: !this._inner.isDesktopVisible
    } as unknown as AnyModule
  }

  public static resolveInitialSize (inner: EmbeddableInBlock): ModuleSize {
    return { ...inner.size }
  }
}

export default ModuleWrapper
