// Copyright © 2021 Move Closer

import { DashmixIconName, TableHead, TableSearchBarAction, DashmixTheme } from '@d24/modules'

import { canDeleteSets, canEditSets, canViewSets } from '@module/content/guards'
import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'

export const setsTableHead: TableHead = [
  {
    column: 'name',
    label: 'Nazwa',
    sortable: true,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'author',
    label: 'Autor',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'setsNumber',
    label: 'Ilość artykułów',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'strategy',
    label: 'Strategia',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'createdDate',
    label: 'Data utworzenia',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'updatedAt',
    label: 'Data modyfikacji',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  }
]

export enum SetsListsActions {
  Delete = 'delete',
  Generate = 'generate',
  Preview = 'preview'
}

export const setsRowActionsFactory = (domain: Domain, user: UserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canEditSets(domain, user)) {
    actions.push(
      {
        label: 'Przeglądaj',
        icon: DashmixIconName.EyeSolid,
        isBulkAction: false,
        action: SetsListsActions.Preview
      }
    )
  }

  if (canViewSets(domain, user)) {
    actions.push(
      {
        label: 'Wygeneruj newsletter',
        icon: DashmixIconName.Envelope,
        isBulkAction: true,
        action: SetsListsActions.Generate,
        theme: DashmixTheme.Info
      }
    )
  }

  if (canDeleteSets(domain, user)) {
    actions.push(
      {
        label: 'Usuń',
        icon: DashmixIconName.TrashAltSolid,
        isBulkAction: true,
        action: SetsListsActions.Delete,
        theme: DashmixTheme.Danger
      }
    )
  }

  return actions
}
