// Copyright © 2022 Move Closer

import { ExtendedMDE } from 'simplemde'

import { Modals } from '@/config/modals'

import { isSpellCheckingOn, toggleSpellChecking } from '../MarkdownEditor.preloader'
import { Shortcut } from '../MarkdownEditor.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const spellCheckClassName: string = 'fa fa-heartbeat'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const spellCheckShortcut: Shortcut = {
  action: (editor: ExtendedMDE) => {
    editor.options.modalConnector.open(Modals.Confirm, {
      content: {
        buttonLabel: 'mdEditor.spellCheck.buttonLabel',
        contentText: 'mdEditor.spellCheck.contentText',
        header: 'mdEditor.spellCheck.header'
      },
      onConfirm: () => {
        toggleSpellChecking(
          !isSpellCheckingOn()
        )

        window.location.reload()
      }
    })
  },
  className: 'fa fa-heartbeat',
  name: spellCheckClassName,
  title: 'Sprawdzanie pisowni'
}
