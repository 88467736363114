


















import { Component, Vue } from 'vue-property-decorator'

import { ArticlesToAcceptWidget } from '@module/content/shared/ArticlesToAcceptWidget.vue'
import { ArticlesOverviewWidget } from '@module/content/shared/ArticlesOverviewWidget.vue'
import { HomePageWidget } from '@module/content/shared/HomePageWidget.vue'
import { LatestArticlesWidget } from '@module/content/shared/LatestArticlesWidget.vue'
import { LatestPagesWidget } from '@module/content/shared/LatestPagesWidget.vue'
import { LatestUsersWidget } from '@module/users/shared/LatestUsersWidget.vue'
import { UsersOverviewWidget } from '@module/users/shared/UsersOverviewWidget.vue'
import { SubscriptionsWidget } from '@module/users/shared/SubscriptionsWidget.vue'

/**
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({
  name: 'DashboardView',
  components: {
    LatestArticlesWidget,
    LatestPagesWidget,
    LatestUsersWidget,
    UsersOverviewWidget,
    HomePageWidget,
    ArticlesToAcceptWidget,
    ArticlesOverviewWidget,
    SubscriptionsWidget
  }
})
export class DashboardView extends Vue {}
export default DashboardView
