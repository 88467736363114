// Copyright © 2021 Move Closer

import {
  ICollection,
  Injectable,
  IResponse,
  MappingConfig,
  QueryParams,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'
import { resolveFromStatus } from '@/shared/exceptions/connector-errors'

import { File } from '../models/file'
import { FileCreatePayload, FileCropPayload, FileEditPayload, MediaMovePayload } from '../contracts'
import { FileData, FileModel } from '../contracts/models'
import { IFileRepository } from '../contracts/repositories'
import { fileAdapterMap } from '../models/file.adapter'
import { Query } from '@/shared/contracts/query'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Injectable()
export class FileRepository extends Repository<FileData, File> implements IFileRepository {
  protected map: MappingConfig = fileAdapterMap
  protected useAdapter = false

  protected handleErrors = (response: IResponse) => {
    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        {}
      )
    }
  }

  protected handleErrorsCustom = (response: IResponse) => {
    if (!response.isSuccessful()) {
      const errors: ResourceActionFailed [] = []
      for (const key in response.data.errors) {
        errors.push(new ResourceActionFailed(
          response.data.errors[key][0],
          resolveFromStatus(response),
          { name: key }
        ))
      }
      throw errors
    }
  }

  public async createFile (id: Identifier, payload: FileCreatePayload, force: boolean = false): Promise<IResponse> {
    const response: IResponse = await this.connector.call(
      'file',
      force ? 'forceCreate' : 'create',
      { id: id },
      payload
    )

    this.handleErrorsCustom(response)

    return response
  }

  public async cropFile (id: Identifier, payload: FileCropPayload): Promise<void> {
    const response: IResponse = await this.connector.call('file', 'modify', { id }, {
      action: 'ratio_crop',
      params: payload
    })

    this.handleErrors(response)
  }

  public async deleteFile (id: Identifier): Promise<void> {
    const response: IResponse = await this.connector.call(
      'file',
      'delete',
      { id: id }
    )

    this.handleErrors(response)
  }

  public async downloadFile (id: Identifier): Promise<any> {
    const response: IResponse = await this.connector.call(
      'storage',
      'download',
      { id: id }
    )

    this.handleErrors(response)

    return response
  }

  public async loadAbandonedList (query: Query): Promise<ICollection<FileModel>> {
    const response = await this.connector.call(
      'file',
      'list',
      {},
      {
        ...query,
        scope: 'abandoned'
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      File,
      response.data.meta
    )
  }

  public async loadFile (id: Identifier): Promise<FileModel> {
    const response: IResponse = await this.connector.call(
      'file',
      'get',
      { id: id }
    )

    this.handleErrors(response)

    return this.composeModel(
      response.data.data,
      File
    )
  }

  public async loadGalleryItems (id: Identifier, query?: QueryParams): Promise<ICollection<FileModel>> {
    const response = await this.connector.call(
      'gallery',
      'itemList',
      { id },
      query
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      File,
      response.data.meta
    )
  }

  public async moveFile (id: Identifier, payload: MediaMovePayload): Promise<IResponse> {
    const response: IResponse = await this.connector.call(
      'file',
      'move',
      { id: id },
      payload
    )

    this.handleErrors(response)

    return response
  }

  public async updateFile (id: Identifier, payload: FileEditPayload): Promise<IResponse> {
    const response: IResponse = await this.connector.call(
      'file',
      'update',
      { id: id },
      payload
    )

    this.handleErrors(response)

    return response
  }
}
