// Copyright © 2021 Move Closer

import {
  ICollection,
  Injectable, IResponse, MappingConfig,
  Repository, ResourceActionFailed
} from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'
import { resolveFromStatus } from '@/shared/exceptions/connector-errors'
import { Query } from '@/shared/contracts/query'

import { authorAdapterMap } from '../models/author.adapter'
import { IAuthorRepository } from '../contracts/repositories'
import { AuthorData, AuthorModel, IAuthor } from '../contracts/models'
import { Author } from '../models/author'
import { EditAuthorIntention } from '../intentions/EditAuthorIntention'
import { CreateAuthorIntention } from '../intentions/CreateAuthorIntention'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Injectable()
export class AuthorRepository extends Repository<AuthorData, IAuthor> implements IAuthorRepository {
  protected useAdapter = true
  protected map: MappingConfig = authorAdapterMap

  public async create (intention: CreateAuthorIntention): Promise<void> {
    const response: IResponse = await this.connector.call(
      'author',
      'create',
      {},
      intention.toRequest()
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async delete (id: Identifier): Promise<void> {
    const response: IResponse = await this.connector.call(
      'author',
      'delete',
      { id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async edit (id: Identifier, intention: EditAuthorIntention): Promise<void> {
    const response: IResponse = await this.connector.call(
      'author',
      'edit',
      { id },
      intention.toRequest()
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async load (id: Identifier): Promise<AuthorModel> {
    const response: IResponse = await this.connector.call(
      'author',
      'get',
      { id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeModel(
      response.data.data,
      Author
    )
  }

  public async loadCollection (query: Query): Promise<ICollection<AuthorModel>> {
    const response: IResponse = await this.connector.call(
      'author',
      'list',
      {},
      {
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Author,
      response.data.meta
    )
  }
}
