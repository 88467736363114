


































import { Component, PropSync, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixIconName } from '@d24/modules'

import { FormInput } from '@component/form'

import { FileData, FileDataActions, FileThumbnail } from '../contracts'

@Component<EditFileData>({
  name: 'EditFileData',
  components: { FormInput }
})
export class EditFileData extends Vue {
  @PropSync('model', { type: Object, required: true })
  public _model!: FileData

  @Prop({ type: String, required: true })
  public formName!: boolean

  @Prop({ type: Boolean, required: true })
  public isLoading!: boolean

  public fileActions = FileDataActions
  public icons = DashmixIconName

  public get file (): FileThumbnail | null {
    if ('directory' in this._model) {
      const data = this._model
      const thumbnail = data?.file?.thumbnail ?? data?.file?.file ?? null
      return {
        thumbnail: thumbnail || '',
        type: data.mime
      }
    } else {
      return null
    }
  }

  public get fileAction (): FileDataActions {
    if (!this._model.action) {
      return FileDataActions.None
    }

    return this._model.action
  }

  public set fileAction (value: FileDataActions) {
    this._model = {
      ...this._model,
      action: value
    }
  }

  public get link (): string | undefined {
    return this._model.redirectExt
  }

  public set link (value: string | undefined) {
    if (!value) {
      return
    }

    this._model = {
      ...this._model,
      redirectExt: value
    }
  }
}

export default EditFileData
