// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'

import { Identifiable, Identifier } from '@/shared/contracts/data'
import { IRegistry, RegistryData } from '../contracts/models'

/**
 * @author Piotr Niewczas <piotr.niewczas@movecloser.pl>
 */
export class Registry extends Model<RegistryData> implements IRegistry, Identifiable {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'address',
      'publishable_id',
      'publishable_type',
      'redirection_target',
      'redirection_type',
      'site_id',
      'content'
    ]
  }

  public identifier (): Identifier {
    return this._data.id
  }

  displayName (): string {
    return this._data.content.title ?? ''
  }
}
