


















import { Component, Prop, Vue } from 'vue-property-decorator'

import { PreventLoosingDataModalPayload } from '../contracts/components'

@Component({
  name: 'PreventLoosingData'
})
export class PreventLoosingDataModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: PreventLoosingDataModalPayload
}

export default PreventLoosingDataModal
