import TurndownService, { Rule } from 'turndown'

/**
 * Based on https://github.com/euangoddard/clipboard2markdown
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */

// eslint-disable-next-line @typescript-eslint/no-var-requires
export const turndownPluginGfm = require('turndown-plugin-gfm')

export const tdService = new TurndownService()
tdService.use(turndownPluginGfm.gfm)

export const escape = function (input: string) {
  return input.replace(/[\u2018\u2019\u00b4]/g, "'")
    .replace(/[\u201c\u201d\u2033]/g, '"')
    .replace(/[\u2212\u2022\u00b7\u25aa]/g, '-')
    // .replace(/[\u2013\u2015]/g, '--')
    // .replace(/\u2014/g, '---')
    .replace(/\u2026/g, '...')
    .replace(/[ ]+\n/g, '\n')
    .replace(/\s*\\\n/g, '\\\n')
    .replace(/\s*\\\n\s*\\\n/g, '\n\n')
    .replace(/\s*\\\n\n/g, '\n\n')
    .replace(/\n-\n/g, '\n')
    .replace(/\n\n\s*\\\n/g, '\n\n')
    .replace(/\n\n\n*/g, '\n\n')
    .replace(/[ ]+$/gm, '')
    .replace(/^\s+|[\s\\]+$/g, '')
}

export const pandoc: Rule[] = [
  {
    filter: 'h1',
    replacement: function (content: string, node: Node) {
      const underline = Array(content.length + 1).join('=')
      return '\n\n' + content + '\n' + underline + '\n\n'
    }
  },
  {
    filter: 'h2',
    replacement: function (content: string, node: Node) {
      const underline = Array(content.length + 1).join('-')
      return '\n\n' + content + '\n' + underline + '\n\n'
    }
  },

  {
    filter: 'sup',
    replacement: function (content: string) {
      return '<sup>' + content + '</sup>'
    }
  },

  {
    filter: 'sub',
    replacement: function (content: string) {
      return '<sub>' + content + '</sub>'
    }
  },

  {
    filter: 'br',
    replacement: function () {
      return '\\\n'
    }
  },

  {
    filter: 'hr',
    replacement: function () {
      return '\n\n* * * * *\n\n'
    }
  },

  {
    filter: ['em', 'i', 'cite', 'var'],
    replacement: function (content: string) {
      return '*' + content + '*'
    }
  },

  {
    filter: function (node: Node) {
      const hasSiblings = node.previousSibling || node.nextSibling
      const isCodeBlock = node.parentNode?.nodeName === 'PRE' && !hasSiblings
      const isCodeElem = node.nodeName === 'CODE' ||
        node.nodeName === 'KBD' ||
        node.nodeName === 'SAMP' ||
        node.nodeName === 'TT'

      return isCodeElem && !isCodeBlock
    },
    replacement: function (content: string) {
      return '`' + content + '`'
    }
  },

  {
    filter: function (node: any) {
      return node.nodeName === 'A' && node.getAttribute('href')
    },
    replacement: function (content: string, node: any) {
      const url = node.getAttribute('href')
      const titlePart = node.title ? ' "' + node.title + '"' : ''
      if (content === url) {
        return '<' + url + '>'
      } else if (url === ('mailto:' + content)) {
        return '<' + content + '>'
      } else {
        return '\n[' + content + '](' + url + ')\n'
      }
    }
  },

  {
    filter: 'li',
    replacement: function (content: string, node: Node) {
      content = content.replace(/^\s+/, '').replace(/\n/gm, '\n    ')
      let prefix = '-   '
      const parent = node.parentNode

      if (/ol/i.test(parent?.nodeName || '')) {
        const index = Array.prototype.indexOf.call(parent?.children, node) + 1
        prefix = index + '. '
        while (prefix.length < 4) {
          prefix += ' '
        }
      }

      return prefix + content
    }
  }
]

// http://pandoc.org/README.html#smart-punctuation
export const HTMLToMarkdown = function (input: string) {
  pandoc.forEach((rule: Rule, index) => {
    tdService.addRule('custom_rule_' + index, rule)
  })
  return escape(tdService.remove(['script', 'style', 'title']).turndown(input))
}

export default HTMLToMarkdown
