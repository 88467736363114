// Copyright © 2021 Move Closer

import { ICollection, QueryParams, Repository, ResourceActionFailed } from '@movecloser/front-core'

import { resolveFromStatus } from '@/shared/exceptions/connector-errors'

import { CreateFeedIntention } from '../intentions/CreateFeedIntention'
import { ChangeFeedLiveStatusIntention } from '../intentions/ChangeFeedLiveStatusIntention'
import { Feed } from '../models/feed'
import { FeedData, FeedModel, IFeedsRepository } from '../contracts'
import { LockFeedIntention } from '../intentions/LockFeedIntention'
import { UpdateFeedIntention } from '../intentions/UpdateFeedIntention'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
export class FeedsRepository extends Repository<FeedData, Feed> implements IFeedsRepository {
  public async changeLiveStatus (feedId: FeedData['id'], intention: ChangeFeedLiveStatusIntention): Promise<void> {
    await this.connector.call(
      'feeds',
      'changeLiveStatus',
      { feedId },
      intention.toRequest()
    )
  }

  public async create (intention: CreateFeedIntention): Promise<string> {
    const response = await this.connector.call(
      'feeds',
      'create',
      {},
      intention.toRequest()
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return response.data.data.id
  }

  public async delete (feedId: FeedData['id']): Promise<void> {
    await this.connector.call(
      'feeds',
      'delete',
      { feedId }
    )
  }

  public async load (feedId: FeedData['id']): Promise<FeedModel> {
    const response = await this.connector.call(
      'feeds',
      'item',
      { feedId }
    )

    return this.composeModel(
      response.data.data,
      Feed
    )
  }

  public async loadCollection (query?: QueryParams): Promise<ICollection<FeedModel>> {
    const response = await this.connector.call(
      'feeds',
      'list',
      {},
      query
    )

    return this.composeCollection(
      response.data.data,
      Feed,
      response.data.meta
    )
  }

  public async lock (feedId: FeedData['id'], intention: LockFeedIntention): Promise<void> {
    await this.connector.call(
      'feeds',
      'lock',
      { feedId },
      intention.toRequest()
    )
  }

  public async update (feedId: FeedData['id'], intention: UpdateFeedIntention): Promise<void> {
    await this.connector.call(
      'feeds',
      'update',
      { feedId },
      intention.toRequest()
    )
  }
}
