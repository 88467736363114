

























import { Component } from 'vue-property-decorator'
import { DashmixButtonVariantMap, DashmixIconName, DashmixTheme, Picker, Related, RelatedType, SizeMap } from '@d24/modules'
import { AnyObject, IModal, ModalType } from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@plugin/inversify'

import { Addon } from '../../maps/variant'
import { RelatedAddonData } from '../../contracts'
import { AbstractAddon } from './AbstractAddon'
import { AddonErrors } from './AddonErrors.vue'

/**
 * @author  Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'CustomSetsAddon',
  components: { AddonErrors }
})
export class CustomSetsAddon extends AbstractAddon {
  @Inject(ModalType)
  protected modalConnector!: IModal

  public customSets: RelatedAddonData[] = []

  // Helpers
  public buttonTheme = DashmixTheme
  public buttonVariant = DashmixButtonVariantMap
  public icons = DashmixIconName

  public deleteSet (index: number) {
    const newSets: RelatedAddonData[] = [...this.customSets]

    if (!newSets[index]) {
      return
    }

    newSets.splice(index, 1)
    this.customSets = newSets

    this.variant.setProperty<RelatedAddonData[]>(Addon.CustomSets, newSets)

    this.onChange(this.variant)
  }

  public openPicker () {
    this.modalConnector.open(Picker.Set, {
      multiple: true,
      onSelection: this.setList,
      selected: this.customSets.map(s => {
        return { value: s.id }
      }),
      onClose: () => this.modalConnector.close(),
      config: { type: 'list' }
    }, { size: SizeMap.Large })
  }

  protected async describe (lists: Identifier[]): Promise<void> {
    this.customSets = []
    for (const s of lists) {
      try {
        const list: AnyObject = await this.relatedService.describe({ type: RelatedType.Set, value: s })

        this.customSets.push({
          id: s,
          name: list.title
        })
      } catch (e) {
        console.warn(e)
      }
    }
  }

  protected getVariantProperty (): void {
    if (this.variant) {
      this.customSets = []
      const dataFromModel = this.variant.getProperty<RelatedAddonData[]>(Addon.CustomSets)

      if (!dataFromModel) {
        return
      }

      this.describe(dataFromModel.map(s => s.id))
    }
  }

  protected setList (selected: Related<RelatedType.Set, Identifier>[] | unknown) {
    const toLoad: Identifier[] = Array.isArray(selected)
      ? selected.map(set => set.value)
      : [...this.customSets, { id: (selected as unknown as Related<RelatedType.Set, Identifier>).value, name: '' }].map(set => set.id)

    this.describe(toLoad).then(() => {
      this.variant.setProperty<RelatedAddonData[]>(Addon.CustomSets, this.customSets)

      this.onChange(this.variant)
    })
  }
}

export default CustomSetsAddon
