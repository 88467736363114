






























import { Component } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

import { Alert, AlertTheme } from '@component/Alert'
import { EditModeLayout, EditModeTabsProps } from '@component/EditModeLayout'
import { FormCheckbox } from '@component/form'
import { Identifier } from '@/shared/contracts/data'
import { Loader } from '@component/Loader'

import { AbstractEditBasics } from './abstracts/AbstractEditBasics'
import { articleOptions } from '../maps/article'
import { ContentBasicsForm } from '../components/ContentBasicsForm.vue'
import { ContentBasicsInformations } from '../components/ContentBasicsInformations.vue'
import { ContentType } from '../contracts/models'
import { EditContentIntention } from '../intentions/EditContentIntention'
import { PropertyItem } from '@/shared/contracts/content'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'EditArticleBasics',
  components: { Alert, ContentBasicsForm, ContentBasicsInformations, EditModeLayout, FormCheckbox, Loader },
  metaInfo (this: EditArticleBasics): MetaInfo {
    return {
      title: `${this.$t('content.editTabs.meta')}`
    }
  }
})
export class EditArticleBasics extends AbstractEditBasics {
  public alertTheme = AlertTheme.Warning
  public contentType = ContentType.Article
  public formName: string = 'createUpdate'

  public tabs: EditModeTabsProps = {
    items: [
      {
        label: 'content.editTabs.content',
        route: { name: 'content.articles.content', params: { id: this.$route.params.id } },
        guard: (id: Identifier): boolean => {
          return this.preventLosingData(id)
        }
      },
      {
        label: 'content.editTabs.meta',
        route: { name: 'content.articles.basics', params: { id: this.$route.params.id } }
      }
    ],
    initTab: 1
  }

  public get contentOptions (): PropertyItem[] {
    return articleOptions
  }

  public get optionsDictionary () {
    if (this.contentOptions && this.model) {
      return this.contentOptions.map(option => {
        if (option.key === 'hideAuthor') {
          return {
            label: this.$t(option.translationKey, { author: this.model?.authorName() || '' }).toString(),
            key: option.key
          }
        }

        return {
          label: this.$t(option.translationKey).toString(),
          key: option.key
        }
      })
    }
    return []
  }

  public deleteArticle (): void {
    this.isDeleting = true
    this.contentRepository.delete(this.$route.params.id as unknown as Identifier).then(() => {
      this.$router.push({ name: 'content.articles.list' })
    }).catch(error => {
      this.error = error.message
    }).finally(() => {
      this.isDeleting = false
    })
  }

  public saveArticleChanges (): void {
    this.isSaving = true
    const intention = new EditContentIntention({ ...this.payload })

    this.contentRepository.update(this.$route.params.id as unknown as Identifier, intention.toRequest()).then(() => {
      if (!this.model) return

      this.toggleDirty(false)

      this.model.set('properties', this.payload.properties)
      this.model.set('title', this.payload.title)
      this.model.set('slug', this.payload.slug)
      this.model.set('parent', this.payload.parent)
    }).catch(error => {
      this.error = error.message
    }).finally(() => {
      this.isSaving = false
    })
  }

  protected onLosingAccept (id: Identifier): void {
    this.eventBus.emit('ui:edit-mode.force', id)
    this.modalConnector.close()
  }
}

export default EditArticleBasics
