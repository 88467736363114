// Copyright © 2021 Move Closer

import { ModalPayload } from '@movecloser/front-core'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface FootnoteModalPayload extends ModalPayload {
  onSelection: (data: FootnoteModalData, position: FootnotePosition, idEditMode: boolean) => void
  isEditMode: boolean
  selected?: FootnoteModalData
  position: FootnotePosition
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface FootnoteModalData {
  /**
   * Footnote identifier.
   */
  identifier: string

  /**
   * Footnote content.
   */
  text: string
}

export enum FootnotePosition {
  Top = 'top',
  Bottom = 'bottom'
}
