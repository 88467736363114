













































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixIconName, DashmixTheme, TableHead, TableRowAction, TableRowElement } from '@d24/modules'

import { Inject } from '@plugin/inversify'
import { Loader } from '@component/Loader/Loader'
import { ModelListHeader } from '@component/ModelListHeader'
import { ModelListHeaderMode } from '@component/ModelListHeader/ModelListHeader.contracts'
import { Query } from '@/shared/contracts/query'
import { ITagRepository, TagData, TagRepositoryType, TagType } from '../contracts'
import { HeaderInterface } from '@component/InteractiveTable/InteractiveTable.contracts'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { tagContentTableHead, TagsActions, tagsRowActionsFactory } from '@module/settings/maps/tags'
import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'
import { DropdownActions } from '@/shared/contracts/content'
import { HasIdentifier, Identifier } from '@/shared/contracts/data'
import TagListTableRow from '@module/settings/components/TagListTableRow.vue'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Component({
  name: 'TagListTab',
  components: {
    Loader,
    ModelListHeader,
    InteractiveTable
  }
})
export class TagListTab extends Vue {
  @Prop({
    type: String,
    required: true
  })
  protected type!: TagType

  @Prop({
    type: String,
    required: true
  })
  protected domain!: Domain

  @Prop({
    type: Object,
    required: true
  })
  protected user!: UserModel

  @Inject(TagRepositoryType)
  protected tagRepository!: ITagRepository

  public icons = DashmixIconName

  public isLoading = false
  public itemsTotal: number = 0
  public rowComponent = TagListTableRow
  public tableData: TableRowElement[] = []
  public tableHead: TableHead = tagContentTableHead
  public totalPages: number = 0

  public header: HeaderInterface = {
    title: this.$t('settings.tags.title').toString(),
    buttonLabel: this.$t('settings.tags.add').toString(),
    linkTarget: { name: 'settings.tags.create' }
  }

  public actions: DropdownActions = {
    [TagsActions.Edit]: {
      callback: (data: unknown) => {
        this.$router.push({
          name: 'settings.tags.edit',
          params: { id: (data as TagData).id.toString() }
        })
      }
    },
    [TagsActions.Delete]: {
      callback: (data: unknown) => {
        return this.onDelete((data as HasIdentifier).id)
      },
      confirmation: {
        header: this.$t('settings.tags.delete.confirmation.header').toString(),
        contentText: this.$t('actions.delete.contentText').toString(),
        theme: DashmixTheme.Danger,
        buttonLabel: 'atoms.delete'
      }
    }
  }

  public get rowActions (): TableRowAction[] {
    return tagsRowActionsFactory(this.domain, this.user)
  }

  public get modelListHeader () {
    return {
      addLabel: this.type === TagType.Tag ? 'Dodaj tag' : 'Dodaj etykietę',
      mode: ModelListHeaderMode.Button,
      title: this.type === TagType.Tag ? this.$t('tag.tags') : 'Etykiety'
    }
  }

  public get placeholder (): string {
    return this.type === TagType.Tag ? this.$t('tag.type.tag.search') as string : this.$t('tag.type.labels.search') as string
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  mounted () {
    this.loadList(this.queryParams)
  }

  @Watch('type')
  protected onTypeChange () {
    this.tableData = []
    this.loadList()
  }

  @Watch('queryParams', { deep: true })
  protected onPageChange (query: Query): void {
    this.loadList(query)
  }

  protected startExport () {
    this.tagRepository.export()
  }

  protected startImport () {
    this.tagRepository.import()
  }

  protected loadList (query?: Query): void {
    this.isLoading = true
    this.tagRepository.loadCollection(this.type, { ...query }).then(collection => {
      this.isLoading = false

      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: true,
          selected: false,
          data: model
        }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.itemsTotal = collection.meta.total
    }).catch(error => {
      console.log(error)
      this.isLoading = false
    })
  }

  protected onDelete (id: Identifier) {
    return this.tagRepository.delete(id).then(() => {
      this.tableData = this.tableData.filter(rowData => rowData.id !== id.toString())
    })
  }
}

export default TagListTab
