// Copyright © 2021 Move Closer

import { clamp } from '@d24/modules'
import { VueConstructor } from 'vue'

import striphtmlFilter from './extensions/striphtml'
import trimFilter from './extensions/trim'
import ucfirstFilter from './extensions/ucfirst'
import closableBehaviour from './extensions/closable'
import { focus } from './extensions/focus'

/**
 * Register all Vue extensions.
 *
 * @author Łukasz Sitnicki <lukasz.sitnici@movecloser.pl>
 * @version 1.0.0
 */
export function registerExtensions (Vue: VueConstructor): void {
  const extensions = {
    filters: {
      striphtml: striphtmlFilter,
      trim: trimFilter,
      ucfirst: ucfirstFilter
    },
    directives: {
      clamp,
      closable: closableBehaviour,
      focus
    }
  }

  // Register all custom directives
  for (const [name, handler] of Object.entries(extensions.directives)) {
    Vue.directive(name, handler)
  }
  // Register all custom filters
  for (const [name, filter] of Object.entries(extensions.filters)) {
    Vue.filter(name, filter)
  }
}
