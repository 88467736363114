// Copyright © 2021 Move Closer

import { Container, RoutesFactory } from '@movecloser/front-core'

import DashboardView from './views/DashboardView.vue'
import NotFoundView from './views/NotFoundView.vue'
import InitView from './views/InitView.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const routesFactory: RoutesFactory = (container: Container) => {
  return [
    {
      path: '/',
      name: 'init',
      component: InitView,
      meta: {
        noSiteId: true
      }
    },
    {
      path: '/:siteId',
      name: 'dashboard',
      component: DashboardView,
      meta: {
        noSiteId: true
      }
    },
    {
      path: '*',
      name: 'not-found',
      component: NotFoundView,
      meta: {
        noSiteId: true
      }
    }
  ]
}
