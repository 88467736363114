// Copyright © 2021 Move Closer

import { defaultIgnoredQueryParams, FiltersConfig, FilterType } from '@component/Filters'
import { loadEditors } from '@/shared/helpers/filters'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const galleryFiltersConfig: FiltersConfig = {
  groups: {
    editor: { label: 'Autor', key: 'editor', type: FilterType.Typeahead, dictionaryLoader: loadEditors },
    updatedAt: { label: 'Data modyfikacji', key: 'updatedAt', type: FilterType.Date },
    createdAt: { label: 'Data utworzenia', key: 'createdAt', type: FilterType.Date }
  },
  ignore: [...defaultIgnoredQueryParams],
  override: { page: '1' },
  leave: ['perPage']
}
