// Copyright © 2021 Move Closer

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 *
 * Function to trim text do given length
 *
 * @param text - strong to trim
 * @param maxLength - max length of string without Ellipsis
 * @param breakWords - break words or leave only whole words
 *
 * @return substring
 */
export const trimText = (text: string, maxLength: number, breakWords: boolean) => {
  if (text.length <= maxLength) {
    return text
  }
  let shortened = text.substring(0, maxLength - 1)

  shortened = `${breakWords ? shortened : shortened.substring(0, shortened.lastIndexOf(' '))}`

  if (shortened.search(/[^\w\s]/g) === shortened.length - 1) {
    shortened = shortened.substring(0, shortened.length - 1)
  }

  return `${shortened}...`
}
