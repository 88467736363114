




























import { Authentication, AuthServiceType } from '@movecloser/front-core'
import { Component, Prop } from 'vue-property-decorator'
import { DescriptionOfSet, Identifier } from '@d24/modules'

import { AbstractModal } from '@component/Modal'
import { Inject } from '@plugin/inversify'
import { ListGroup, ListGroupItem } from '@component/ListGroup'
import { Loader } from '@component/Loader'

import { Domain, SiteModel } from '@module/root/contracts/models'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'
import { UserModel } from '@module/auth/contracts/models'

import { canViewSets } from '../guards'
import { ContentRepositoryType, IContentRepository, PageListsModalPayload, RegistryPayload } from '../contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component<PageListsModal>({
  name: 'PageListsModal',
  components: { ListGroup, Loader },
  mounted () {
    this.extractLists()
  }
})
export class PageListsModal extends AbstractModal {
  @Prop({ type: Object, required: true })
  protected payload!: PageListsModalPayload

  @Inject(AuthServiceType)
  private authService!: Authentication<UserModel>

  @Inject(ContentRepositoryType)
  protected readonly contentRepository!: IContentRepository

  @Inject(SiteResolverType)
  private readonly siteResolver!: ISiteResolver

  public isLoading: boolean = true
  protected lists: DescriptionOfSet[] = []

  public get listsToDisplay (): ListGroupItem[] {
    return this.lists.map(l => ({ data: l }))
  }

  protected get sites (): SiteModel[] {
    return this.siteResolver.getDictionary()
  }

  public closeModal (): void {
    this.$emit('close')
  }

  public checkPermission (set: DescriptionOfSet): boolean {
    if (this.resolveSite(set.siteId) === '---') {
      return false
    }
    return canViewSets(this.resolveSite(set.siteId) as Domain, this.authService.user)
  }

  public resolveSite (id: Identifier): string {
    const found = this.sites.find(s => `${s.id}` === `${id}`)
    return found?.domain ?? '---'
  }

  protected async extractLists (): Promise<void> {
    try {
      const content: RegistryPayload = await this.contentRepository.registry(this.payload.slug)

      this.lists = Object.entries(content.related.list ?? {}).map(([k, v]) => {
        if (typeof v !== 'object' || v === null) {
          return { id: k, title: '---' } as unknown as DescriptionOfSet
        }

        return { ...v, id: k } as unknown as DescriptionOfSet
      })
      this.isLoading = false
    } catch (e) {
      console.warn(e)
    }
  }
}

export default PageListsModal
