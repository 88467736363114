












import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DashmixIconName } from '@d24/modules'
import { Fragment } from 'vue-fragment'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { SettingData } from '../contracts'

@Component({
  name: 'SettingTableRow',
  components: { Fragment }
})
export class SettingTableRow extends Mixins<IUserAware>(UserAware) {
  @Prop({ type: Object, required: true })
  protected data!: SettingData

  public Icons = DashmixIconName

  public readonly settingsWithForm = ['freeSubscriptionRules', 'infoBar', 'subscriptionPrices', 'subscriptionProducts', 'general', 'adsConfig']

  public get canEditSetting () {
    return this.settingsWithForm.includes(this.data.type)
  }
}

export default SettingTableRow
