












































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixIconName, TableHead } from '@d24/modules'
import { Fragment } from 'vue-fragment'
import { RawLocation } from 'vue-router'

import {
  ContentRepositoryType,
  ContentTableRowElement,
  ContentStatusBadge,
  ContentType,
  IContentRepository,
  ContentData, VariantsRepositoryType, IVariantsRepository, ContentStatus
} from '@module/content/contracts'
import { Inject } from '@plugin/inversify'
import { Identifier } from '@/shared/contracts/data'

import { tagContentTableHead } from '../maps/tags'
import { TagContentListModalPayload } from '../contracts/components'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<TagContentListModal>({
  name: 'TagContentListModal',
  components: { Fragment },

  created () {
    this.loadArticlesList(this.currentPage, this.perPage)
  }
})
export class TagContentListModal extends Vue {
  @Prop({ type: Object, required: true })
  protected payload!: TagContentListModalPayload

  @Inject(ContentRepositoryType)
  protected contentRepository!: IContentRepository

  @Inject(VariantsRepositoryType)
  protected variantsRepository!: IVariantsRepository

  private ContentStatusBadge = ContentStatusBadge
  private currentPage: number = 1

  public isDisabled: boolean = false
  public isLoading = false
  public icons = DashmixIconName
  public perPage: number = 10
  public tableData: ContentTableRowElement[] = []
  public tableHead: TableHead = tagContentTableHead
  public totalItems: number = 0
  public totalPages: number = 0

  public hasLink (data: ContentData) {
    return !!data.url
  }

  public loadArticlesList (page: number = 1, perPage: number) {
    this.isLoading = true

    this.contentRepository.loadCollection(
      [ContentType.Article, ContentType.LiveArticle],
      {
        [`${this.payload.type}s`]: 'eq:' + this.payload.tag.id,
        page: String(page) || this.payload.page,
        perPage: String(perPage) || String(this.perPage)
      }
    ).then(collection => {
      this.isLoading = false
      this.totalItems = Number(collection.meta.total)
      this.totalPages = Number(collection.meta.last_page)
      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: false,
          data: {
            ...model,
            isLoading: false
          }
        }
      })
    })
  }

  /**
   * Add external property isLoading to ContentData to imitate loading in particular article's button
   * This property is not available in any other places
   *
   * @param data
   */
  public openPreview (data: ContentData & { isLoading: boolean }) {
    if (!this.hasLink(data) || data.url === null || !data.variants) {
      return
    }

    data.isLoading = true

    const activeVariants = data.variants.filter((variant) => variant.status === ContentStatus.Published)

    if (!activeVariants.length || activeVariants.length < 0) {
      return
    }

    this.variantsRepository.preview(
        data.id as unknown as Identifier,
        activeVariants[0].id as unknown as number
    ).then(url => {
      window.open(url, '_blank')
    }).finally(() => {
      data.isLoading = false
    })
  }

  public resolveTarget (target: RawLocation): string {
    return this.$router.resolve(target).href
  }

  public updateCurrentPage (payload: number) {
    this.currentPage = payload
  }

  public updatePerPage (payload: number) {
    this.perPage = payload
  }

  protected close () {
    this.payload.onClose()
  }

  @Watch('currentPage')
  protected onPageUpdated (newPage: number): void {
    this.loadArticlesList(newPage, this.perPage)
  }

  @Watch('perPage')
  protected onPerPageUpdated (newPerPage: number): void {
    this.loadArticlesList(this.currentPage, newPerPage)
  }
}

export default TagContentListModal
