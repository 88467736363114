







import { AnyObject, DeMarkedTokenizerError } from '@d24/modules'
import { Component, InjectReactive as VueInject, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator'
import { debounce, DebouncedFunc } from 'lodash'
import { Editor, StringStream } from 'codemirror'
import { ExtendedMDE } from 'simplemde'
import { IModal, ModalType } from '@movecloser/front-core'
import VueSimpleMDE, { ExtendedVueSimpleMDE } from 'vue-simplemde'

import { Inject } from '@plugin/inversify'
import { PreLoadedResults, preLoadedResultsKey } from '@/shared/contracts/preloaders'

import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'

import { checkInputValidity, highlightCustomActions, onCodeMirrorPaste, toggleActiveClassOnToolbarElement } from './helpers'
import { MDTokens, SIMPLEMDE_CONFIG } from './MarkdownEditor.config'
import { isSpellCheckingOn, SPELLCHECK_KEY } from './MarkdownEditor.preloader'
import { ValidationResult } from './MarkdownEditor.contracts'
import { spellCheckClassName } from '@component/MarkdownEditor/shortcuts'

/**
 * @emits blur - When the editor looses the :focus.
 * @emits dirty - When the editor's content gets changed for the 1st time since initialization.
 * @emits input:invalid - When the parser fails to convert MD to HTML.
 * @emits input:valid - When the parser successfully converts MD to HTML.
 *
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<MarkdownEditor>({
  name: 'MarkdownEditor',
  components: { VueSimpleMDE },

  mounted (): void {
    this.decorateCodemirror()
  }
})
export class MarkdownEditor extends Vue {
  @PropSync('model', { type: String, required: true })
  public _model!: string

  @Prop({ type: Object, required: false })
  protected readonly initConfig!: AnyObject

  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  @Inject(SiteResolverType)
  protected readonly siteResolver!: ISiteResolver

  @Ref('vueSimpleMDERef')
  protected readonly vueSimpleMDERef!: ExtendedVueSimpleMDE

  @VueInject(preLoadedResultsKey)
  protected preloaded!: PreLoadedResults

  protected isDirty: boolean = false
  protected static isSpellCheckingOn: boolean = false
  protected lastInputSucceeded: boolean = true

  protected get checkSpelling (): boolean {
    return typeof this.typo === 'object' && this.typo !== null && MarkdownEditor.isSpellCheckingOn
  }

  public get config () {
    const initConfig = this.initConfig || SIMPLEMDE_CONFIG

    return {
      ...initConfig,
      modalConnector: this.modalConnector,
      status: this.statusConfig
    }
  }

  protected get currentLocale (): string {
    return (this.siteResolver.getSite()?.locale || '').toLocaleLowerCase()
  }

  protected get typo (): unknown | null {
    return this.preloaded[SPELLCHECK_KEY] ?? null
  }

  protected get simpleMDEEditor (): ExtendedMDE {
    return this.vueSimpleMDERef?.simplemde as ExtendedMDE
  }

  protected get statusConfig () {
    // noinspection SpellCheckingInspection,JSUnusedGlobalSymbols
    return [
      'cursor', 'autosave', 'lines', 'words',
      {
        className: 'characters',
        defaultValue: (el: HTMLElement) => {
          el.innerHTML = '0'
        },
        onUpdate: (el: HTMLElement) => {
          el.innerHTML = `${this.charactersCount()}`
        }
      }
    ]
  }

  public charactersCount (): number {
    const simpleMDEditor = this.vueSimpleMDERef.simplemde
    if (!simpleMDEditor) {
      return 0
    }

    let text = simpleMDEditor.markdown(simpleMDEditor.codemirror?.getValue()) as string
    if (!text) {
      return 0
    }

    // Do not count the module seating, modules start with # and end with ].
    text = text.replace(/#.*]/g, ' ')

    // Count content inside tags  except >, *, ~, _.
    text = text.replace(/<[^>*~_]*>/g, ' ')

    // Convert html entities to one char (count entity as one char).
    text = text.replace(/&.*;/g, 'x')

    // Do not count ZWJ ;).
    text = text.replace(/[\u200B-\u200D\uFEFF]/g, ' ')

    // Remove white spaces in string.
    text = text.replace(/\s+/g, ' ')

    // Remove white spaces from beginning and end.
    text = text.trim()

    return text.length
  }

  protected get wordsCount (): number {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    let text = this.simpleMDEEditor?.markdown(this.simpleMDEEditor?.codemirror?.getValue()) as string
    if (!text) {
      return 0
    }

    text = text.replace(/<[^>]*>/g, ' ')
    text = text.replace(/\s+/g, ' ')
    text = text.trim()

    return text.length
  }

  protected decorateCodemirror (): void {
    const mde = this.vueSimpleMDERef?.simplemde
    if (!mde || !mde.codemirror) {
      console.warn('[MarkdownEditor]: Missing MDE instance.')
      return
    }

    mde.codemirror.on('paste', onCodeMirrorPaste)
    mde.codemirror.on('cursorActivity', highlightCustomActions)
    mde.codemirror.options.backdrop = { ...mde.codemirror.options.mode }
    mde.codemirror.options.mode = 'spell-checker'

    this.buildSpellchecker(mde.codemirror)
  }

  protected markAsDirty (): void {
    this.isDirty = true
    this.$emit('dirty')
  }

  /**
   * Handles the invalid input.
   */
  protected onInvalidInput: DebouncedFunc<(e?: DeMarkedTokenizerError) => void> = debounce(
    (e?: DeMarkedTokenizerError) => {
      this.onValidInput.cancel()

      if (this.lastInputSucceeded) {
        this.lastInputSucceeded = false
        this.$emit('input:invalid', e)
      }
    },
    1000,
    { leading: false }
  )

  @Watch('_model')
  protected onModelChange (model: string): void {
    if (!this.isDirty) {
      this.markAsDirty()
    }

    this.validateInput(model)
  }

  /**
   * Handles the valid input.
   */
  protected onValidInput: DebouncedFunc<() => void> = debounce(() => {
    this.onInvalidInput.cancel()

    if (!this.lastInputSucceeded) {
      this.lastInputSucceeded = true
      this.$emit('input:valid')
    }
  }, 1000, { leading: true })

  /**
   * Checks if the passed-in input is valid.
   */
  protected validateInput: DebouncedFunc<(input: string) => void> = debounce((input: string) => {
    const validationResult: ValidationResult = checkInputValidity(input)
    validationResult.isValid ? this.onValidInput() : this.onInvalidInput(validationResult.error)
  }, 100)

  private buildSpellchecker (codeMirror: Editor): void {
    if (this.currentLocale.length < 2) {
      console.warn('[MarkdownEditor]: Unknown locale.')
      return
    }

    MarkdownEditor.isSpellCheckingOn = isSpellCheckingOn()
    toggleActiveClassOnToolbarElement(spellCheckClassName, MarkdownEditor.isSpellCheckingOn)

    const overlay = {
      token: (stream: StringStream) => {
        if (!this.checkSpelling) {
          stream.skipToEnd()
          return null
        }

        for (const rule of MDTokens) {
          const res = stream.match(rule)
          if (res) {
            stream.string = stream.string.replace(rule, '')
            break
          }
        }

        const rxWord: string = '!"„#$%&()*+,-./:;<=>?@[\\]^_`{|}~ '
        let ch = stream.peek()
        let word = ''

        if (rxWord.includes(String(ch)) || ch === '\uE000' || ch === '\uE001') {
          stream.next()
          return null
        }

        while ((ch = stream.peek()) && !rxWord.includes(ch)) {
          word += ch
          stream.next()
        }

        if (!/[a-z]/i.test(word)) return null
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (!this.typo.check(word)) return 'spell-error'
      }
    }

    codeMirror.addOverlay(overlay)
    codeMirror.on('cursorActivity', () => {
      toggleActiveClassOnToolbarElement(spellCheckClassName, MarkdownEditor.isSpellCheckingOn)
    })
  }
}

export default MarkdownEditor
