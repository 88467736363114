// Copyright © 2022 Move Closer

import { MagicModel } from '@movecloser/front-core'

import { AuthorModel } from '@module/content/contracts'
import { HasIdentifier, Identifier } from '@/shared/contracts/data'

export interface FormData extends HasIdentifier {
  id: Identifier
  title: string
  type: FormType
  status: FormStatus
  uuid: string
  author: AuthorModel
  createdAt: string
  fields: object
  numberOfEntries: number
  maEventName: string
}

export type FormModel = MagicModel<FormData>

export enum FormType {
  Form = 'form',
  Event = 'event'
  // Pool = 'pool'
}

export enum FormStatus {
  New = 'new',
  Active = 'active',
  Ended = 'ended'
}
