



























import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { Fragment } from 'vue-fragment'

import { Inject } from '@plugin/inversify'
import { Popover } from '@component/Popovers/Popover'

import { defaultDateFormat } from '@module/content/helpers/formatting'
import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import PermissionsPopover from './PermissionsPopover.vue'
import { RoleModel } from '../contracts/models'

@Component({
  name: 'RolesTableRow',
  components: { Fragment, Popover, PermissionsPopover }
})
export class RolesTableRow extends Mixins<IUserAware>(UserAware) {
  @Prop({ type: Object, required: true })
  public data!: RoleModel

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public dateFormat = defaultDateFormat
}

export default RolesTableRow
