// Copyright © 2021 Move Closer

import { Modals } from '@/config/modals'
import { ExtendedMDE } from 'simplemde'
import { ModuleDriver, Picker, Related, RelatedType, SizeMap } from '@d24/modules'

import { Shortcut } from '../MarkdownEditor.contracts'
import { findTokenAtCurrentLine, replaceTokenAtCurrentLine } from '@component/MarkdownEditor/helpers/line-parser'
import { AnyObject } from '@movecloser/front-core'
import { PickerPayload } from '@module/content/contracts'
import { EditFilesDataPayload, FileData, FileModel, StoredFileData } from '@module/media/contracts'
import { Identifier } from '@/shared/contracts/data'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const imageShortcut: Shortcut = {
  action: (editor: ExtendedMDE) => {
    const onSuccess = (filesData: StoredFileData[]) => {
      for (const img of filesData) {
        const { author, caption, isAuthorChange, isCaptionChange, ...content } = img
        replaceTokenAtCurrentLine(editor, ModuleDriver.Image, {
          ...content,
          ...(isAuthorChange ? { author } : {}),
          ...(isCaptionChange ? { caption } : {})
        })
        editor.codemirror.replaceSelection('\n')
      }

      editor.options.modalConnector.close()
    }

    const onSelection = (data: Related<RelatedType.File, Identifier> | Related<RelatedType.File, Identifier>[]) => {
      if (!data || (Array.isArray(data) && data.length === 0)) {
        return
      }

      const payload: EditFilesDataPayload = {
        selected: Array.isArray(data) ? data : [data],
        filesData: [],
        onSuccess: onSuccess
      }

      setTimeout(() => {
        editor.options.modalConnector.open(Modals.EditFileData, payload, { size: SizeMap.XLarge })
      }, 0)
    }

    const token: AnyObject | null = findTokenAtCurrentLine(editor, ModuleDriver.Image)

    let selected
    let filesData

    if (
      token &&
      Object.prototype.hasOwnProperty.call(token, 'image') &&
      Object.prototype.hasOwnProperty.call(token.image, 'value') &&
      Object.prototype.hasOwnProperty.call(token.image, 'type')
    ) {
      selected = token.image

      filesData = [{
        id: token.image.value,
        ...token
      }] as unknown as FileData[]
    }

    if (!selected || !filesData) {
      const payload: PickerPayload<FileModel, RelatedType.File, Identifier> = {
        onSelection: onSelection,
        multiple: true,
        selected: []
      }
      payload.multiple = true

      editor.options.modalConnector.open(Picker.Media, payload, { size: SizeMap.XLarge })
    } else {
      const payload: EditFilesDataPayload = {
        selected: Array.isArray(selected) ? selected : [selected],
        filesData: filesData,
        onSuccess: onSuccess
      }

      editor.options.modalConnector.open(Modals.EditFileData, payload, { size: SizeMap.XLarge })
    }
  },
  className: 'fas fa-image',
  name: 'image',
  title: 'Wstaw zdjęcie'
}
