// Copyright © 2021 Move Closer

import { Identifier } from '@d24/modules'

import { Permission } from '@module/auth/contracts/permissions'

import { Hint } from '@component/Typeahead'
import { Query } from '@/shared/contracts/query'

export interface PermissionsListItemProps {
  checked: boolean
  label: string
  description: string
  value: Permission
}

export enum RoleFormMode {
  Create = 'create',
  Edit = 'edit'
}

export interface SelectUserModalPayload {
  onClose: () => void
  onCreate: (selected: Identifier) => void
  onEdit: (selected: string) => void
}

export interface TransactionsModalPayload {
  periodId: string
  subscriptionId: Identifier
  query?: Query
  showAll?: boolean
}

export interface TransactionDetailsModalPayload {
  details: object
  goBackPayload: TransactionsModalPayload
}

export interface UserHint extends Hint {
  isSubscriber: boolean
  subscriptionId: string
}
