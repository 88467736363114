// Copyright © 2023 Move Closer

import { defaultIgnoredQueryParams, FiltersConfig, FilterType } from '@component/Filters'
import { loadSubPeriod, loadSubStatus, loadSubType, loadUsers } from '@/shared/helpers/filters'

/**
 * @author Bartłomiej Stocki <bartlomiej.stocki@movecloser.pl>
 */

export const subscriptionsFiltersConfig: FiltersConfig = {
  groups: {
    user: { label: 'Użytkownik', key: 'user', type: FilterType.Dictionary, dictionaryLoader: loadUsers },
    isActive: { label: 'Aktywna', key: 'isActive', type: FilterType.Boolean },
    status: { label: 'Status', key: 'status', type: FilterType.Dictionary, dictionary: loadSubStatus() },
    period: { label: 'Okres rozliczeniowy', key: 'period', type: FilterType.Dictionary, dictionary: loadSubPeriod() },
    renewalAt: { label: 'Odnowienie', key: 'renewalAt', type: FilterType.Date },
    startedAt: { label: 'Rozpoczęcie', key: 'startedAt', type: FilterType.Date },
    endedAt: { label: 'Zakończenie', key: 'endedAt', type: FilterType.Date },
    type: { label: 'Typ', key: 'type', type: FilterType.Dictionary, dictionary: loadSubType() }
  },
  ignore: [...defaultIgnoredQueryParams],
  override: { page: '1' },
  leave: ['perPage']
}
