


































import { Component, Prop, Vue } from 'vue-property-decorator'

import { FileModel, FileVariant, FileVariantParams } from '../contracts'
import { AnyObject } from '@d24/modules'

/**
 * @author Mateusz Twardowski <mateusz.twardowski@movecloser.pl>
 */
@Component({
  name: 'FileVariantsTable'
})
export class FileVariantsTable extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  public file!: FileModel

  public copyLink (url: string) {
    const el = document.createElement('textarea')
    el.value = url
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  public variantsParamsForGroup (ratio: string): FileVariantParams | null {
    return this.file?.file?.variantsParams?.[ratio] || null
  }

  public get variantsGroups (): { [key: string]: FileVariant[] } {
    const map: AnyObject = {}
    const variants = this.file?.file?.variants || []

    for (const element of variants) {
      if (!Object.prototype.hasOwnProperty.call(element, 'url')) {
        continue
      }
      if (!map.hasOwnProperty(element.ratio)) {
        map[element.ratio] = []
      }
      map[element.ratio].push(element)
    }

    Object.keys(map).forEach((key) => {
      map[key].sort((a: FileVariant, b: FileVariant) => {
        if (a.width < b.width) {
          return 1
        }
        if (a.width > b.width) {
          return -1
        }
        return 0
      })
      map[key].filter((element: FileVariant) => element?.url)
    })
    return map
  }
}

export default FileVariantsTable
