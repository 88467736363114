<!-- Copyright © 2021 Move Closer -->

<template>
  <BaseSection>
    <template slot="heading">
      {{ $t('components.MarkdownEditorGuideModal.sections.list.heading') }}
    </template>

    <pre>
* {{ $t('components.MarkdownEditorGuideModal.sections.list.unordered-list-item') }}
* {{ $t('components.MarkdownEditorGuideModal.sections.list.unordered-list-item') }}
* {{ $t('components.MarkdownEditorGuideModal.sections.list.unordered-list-item') }}

1. {{ $t('components.MarkdownEditorGuideModal.sections.list.ordered-list-item') }}
2. {{ $t('components.MarkdownEditorGuideModal.sections.list.ordered-list-item') }}
3. {{ $t('components.MarkdownEditorGuideModal.sections.list.ordered-list-item') }}</pre>
  </BaseSection>
</template>

<script>
import BaseSection from './_base'
export default { components: { BaseSection } }
</script>
