





































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName, Identifier } from '@d24/modules'

import { Inject } from '@plugin/inversify'

import { ISetItemsRepository, SetItemsRepositoryType, SneakPeekModel } from '../contracts'

/**
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<LockedArticlesList>({ name: 'LockedArticlesList' })
export class LockedArticlesList extends Vue {
  @Prop({ type: Array, required: false, default: () => [] })
  private lockedItems!: SneakPeekModel[]

  @Prop({ type: Number, required: false })
  private setId!: Identifier

  @Inject(SetItemsRepositoryType)
  private setItemsRepository!: ISetItemsRepository

  public Icons = DashmixIconName

  public get sortedItems (): SneakPeekModel[] {
    return this.lockedItems.sort((a, b) => a.position - b.position)
  }

  public onUnlock (item: SneakPeekModel): void {
    this.$emit('unlockItem', item.item.id)
  }
}
export default LockedArticlesList
