





















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixIconName, DashmixSelectItem, DashmixTheme, RelatedType } from '@d24/modules'
import { debounce } from 'lodash'
import { ICollection, IModel, Intersected, ResourceActionFailed } from '@movecloser/front-core'

import { Inject } from '@plugin/inversify'
import { IRelatedService, RelatedServiceType } from '@service/related'

import { FormCheckbox } from '@component/form'
import { FormSelect } from '@component/form/Select/Select'
import { FormTypeahead } from '@component/FromTypeahead/FormTypeahead'

import { Hint } from '@component/Typeahead'
import { Loader } from '@component/Loader/Loader'
import { DictData } from '@module/root/contracts/models'
import { DictionaryRepositoryType, IDictionaryRepository } from '@module/root/contracts/repositories'

import { ContentData, ContentRepositoryType, IContent, IContentRepository, ISimpleContent, SetSource, SimpleContentData, SourceType } from '../contracts'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'SetSourceForm',
  components: {
    FormTypeahead,
    Loader,
    FormSelect,
    FormCheckbox
  }
})
export class SetSourceForm extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public disabled!: boolean

  @Prop({ type: String, required: true })
  public formName!: string

  @Prop({ type: Number, required: true })
  public index!: number

  @Prop({ type: String, required: true })
  public mode!: SourceType

  @Prop({ type: Object, required: true })
  public source!: SetSource

  @Inject(ContentRepositoryType)
  private contentRepository!: IContentRepository

  @Inject(DictionaryRepositoryType)
  private dictionaryRepository!: IDictionaryRepository

  public buttonTheme = DashmixTheme
  public Icons = DashmixIconName
  public isLoading: boolean = false

  public categories: DashmixSelectItem[] = []
  public authors: Hint[] = []
  public labels: DashmixSelectItem[] = []
  public tags: Hint[] = []

  // Helpers
  public sourceTypes = SourceType

  public sourceTypeOptions = [
    {
      label: this.$t('sets.source.type.author'),
      value: SourceType.Author
    },
    {
      label: this.$t('sets.source.type.category'),
      value: SourceType.Category
    },
    {
      label: this.$t('sets.source.type.categoryRecurrent'),
      value: SourceType.CategoryRecurrent
    },
    {
      label: this.$t('sets.source.type.tag'),
      value: SourceType.Tag
    },
    {
      label: this.$t('sets.source.type.label'),
      value: SourceType.Label
    }
  ]

  public get options () {
    switch (this.mode) {
      case SourceType.Author:
        return this.authors
      case SourceType.Category:
      case SourceType.CategoryRecurrent:
        return this.categories
      case SourceType.Label:
        return this.labels
      case SourceType.Tag:
        return this.tags
    }
  }

  public get sourceLabels () {
    if (this.mode === SourceType.CategoryRecurrent) {
      return {
        label: this.$t('sets.create.form.category.label'),
        placeholder: this.$t('sets.create.form.category.placeholder')
      }
    } else {
      return {
        label: this.$t(`sets.create.form.${this.mode}.label`),
        placeholder: this.$t(`sets.create.form.${this.mode}.placeholder`)
      }
    }
  }

  created () {
    this.loadOptions()
  }

  public get getTypeaheadOption (): Hint[] {
    if (this.source.value) {
      return [{
        value: this.source.value,
        label: this.source.label || this.source.value
      }]
    }
    return []
  }

  public isCategory (): boolean {
    return this.source.type === SourceType.Category
  }

  public isCategoryRecurrent (): boolean {
    return this.source.type === SourceType.CategoryRecurrent
  }

  public toggleCategoryMode () {
    if (this.isCategory()) {
      this.source.type = SourceType.CategoryRecurrent
    } else {
      this.source.type = SourceType.Category
    }
  }

  public loadDefaultOptions () {
    this.$nextTick(() => {
      this.loadOptions()
    })
  }

  public async loadOptions (searchParams?: string) {
    this.isLoading = true

    const logErrors = (error: ResourceActionFailed) => {
      console.log(error)
      this.isLoading = false
    }
    switch (this.mode) {
      case SourceType.Author:
        await this.loadAuthors(searchParams).catch(logErrors)
        break
      case SourceType.Category:
      case SourceType.CategoryRecurrent:
        if (this.categories.length === 0) {
          await this.loadCategories().catch(logErrors)
        }
        break
      case SourceType.Label:
        if (this.labels.length === 0) {
          await this.loadLabels().catch(logErrors)
        }
        break
      case SourceType.Tag:
        await this.loadTags(searchParams).catch(logErrors)
        break
    }
    this.isLoading = false
  }

  public async loadAuthors (searchParams?: string) {
    this.isLoading = true
    this.authors = SetSourceForm.authorsToSelectOptions(
      await this.dictionaryRepository.loadAuthorsDictionary({ q: searchParams || '' })
    )
    this.isLoading = false
  }

  public async loadCategories () {
    this.isLoading = true
    this.categories = SetSourceForm.contentsSimpleToSelectOptions(
      await this.contentRepository.loadTree({ 'flat-tree': '' }))
    this.isLoading = false
  }

  public async loadTags (searchParams?: string) {
    this.isLoading = true
    this.tags = SetSourceForm.dictionaryToSelectOptions(
      await this.dictionaryRepository.loadTagsDictionary({ q: searchParams || '' }))
    this.isLoading = false
  }

  public async loadLabels () {
    this.isLoading = true
    this.labels = SetSourceForm.dictionaryToSelectOptions(
      await this.dictionaryRepository.loadLabelsDictionary())
    this.isLoading = false
  }

  public onClear () {
    this.loadOptions()
  }

  public onDelete () {
    this.source.value = ''
    this.source.label = ''
  }

  public onSelect (selectedHint: Hint) {
    this.source.value = `${selectedHint.value}`
    this.source.label = selectedHint.label
  }

  public onSearch (searchedParams: string) {
    this.loadOptions(searchedParams)
  }

  protected deleteSource () {
    this.$emit('deleted', this.index)
  }

  @Watch('source', { deep: true })
  protected onSourceChange = debounce(() => {
    this.onSourceChange.cancel()

    this.$emit('changed', this.index, this.source)
  }, 500)

  @Watch('source.type', { deep: false })
  protected onSourceTypeChange (newType: string, oldType: string) {
    if (newType !== oldType) {
      this.source.value = ''
      this.source.label = ''
    }
  }

  private static authorsToSelectOptions (
    collection: ICollection<Intersected<IModel<DictData>, DictData>>
  ): Hint[] {
    return [...collection].map(
      (dictionary: Intersected<IModel<DictData>, DictData>) => {
        return {
          value: dictionary.id,
          label: dictionary.fullName
        }
      }
    )
  }

  private static contentsToSelectOptions (
    collection: ICollection<Intersected<IContent, ContentData>>
  ): DashmixSelectItem[] {
    return [...collection].map(
      (model: Intersected<IContent, ContentData>) => {
        const payload = model.toObject()

        return {
          value: payload.id,
          label: payload.title
        }
      }
    )
  }

  private static contentsSimpleToSelectOptions (
    collection: ICollection<Intersected<ISimpleContent, SimpleContentData>>
  ): DashmixSelectItem[] {
    return [...collection].map(
      (model: Intersected<ISimpleContent, SimpleContentData>) => {
        const payload = model.toObject()

        return {
          value: payload.id,
          label: payload.title
        }
      }
    )
  }

  private static dictionaryToSelectOptions (
    collection: ICollection<Intersected<IModel<DictData>, DictData>>
  ): DashmixSelectItem[] {
    return [...collection].map(
      (model: Intersected<IModel<DictData>, DictData>) => {
        const {
          name,
          id
        } = model.toObject()

        return {
          value: id,
          label: name
        }
      }
    )
  }
}

export default SetSourceForm
