// Copyright © 2021 Move Closer

import { defineComponent, SetupContext } from '@vue/composition-api'

import { AlertProps } from './Alert.contracts'
import { alertProps, useAlert } from './Alert.hooks'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const Alert = defineComponent({
  name: 'Alert',
  props: alertProps,

  setup: (props: AlertProps, ctx: SetupContext) => {
    return useAlert(props, ctx)
  },

  template: `
    <div v-if="isOpen" class="alert alert-dismissible" role="alert"
         :class="['alert-' + theme, { 'alert-dismissible': dismissible }]">
      <!-- "Close" button -->
      <button v-if="dismissible" type="button" class="close"
              @click="close" data-dismiss="alert" aria-label="Close">
        <D24Icon :icon="closeIcon" size="small" />
      </button>

      <div class="d-flex align-items-center mb-0">
        <!-- Additional icon -->
        <div v-if="showIcon && iconToDisplay" class="flex-00-auto mr-1">
          <D24Icon :icon="iconToDisplay" size="small" />
        </div>

        <!-- Main content -->
        <div class="flex-fill">
          <slot />
        </div>
      </div>
    </div>
  `
})
