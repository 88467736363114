



































































import { Component, Prop, Watch } from 'vue-property-decorator'
import { debounce } from 'lodash'

import { AbstractModal } from '@component/Modal'
import { DashmixIconName, DashmixSelectItem, Related, RelatedType } from '@d24/modules'
import { IModal, ModalType } from '@movecloser/front-core'
import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@/shared/plugins/inversify'
import { IRelatedService, RelatedServiceType } from '@service/related'
import { Loader } from '@component/Loader'

import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'

import {
  ISetsRepository,
  PickerPayload,
  SetModel,
  SetsRepositoryType,
  SetType
} from '../contracts'
import { SetPickerItem } from './SetPickerItem.vue'
import { SetSidebar } from './SetSidebar.vue'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<SetPickerModal>({
  name: 'SetPickerModal',
  components: { Loader, SetPickerItem, SetSidebar },

  created () {
    const promises: Promise<void>[] = []

    this.site = this.siteResolver.getSite()?.id
    this.siteOptions = [...this.siteResolver.getDictionary()]
      .map(site => {
        return {
          label: site.domain,
          value: site.id
        }
      })

    if (this.payload.selected) {
      let selectedIds: Identifier[] = []

      if (this.payload.multiple) {
        selectedIds = (this.payload.selected as Related<RelatedType.Set, Identifier>[]).map(s => s.value)
      } else {
        selectedIds = [(this.payload.selected as Related<RelatedType.Set, Identifier>).value]
      }

      selectedIds.forEach(id => promises.push(this.loadSet(id, true)))
    }

    Promise.all(promises).then(() => {
      this.loadSetList()
    })
  }
})
export class SetPickerModal extends AbstractModal {
  @Prop({ type: Object, required: true })
  public payload!: PickerPayload<SetModel, RelatedType.Set, Identifier>

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  @Inject(SetsRepositoryType)
  private setsRepository!: ISetsRepository

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public site: Identifier | undefined
  public siteOptions: DashmixSelectItem[] = []

  protected query: string = ''
  protected setList: SetModel[] = []
  protected selected: SetModel[] = []
  protected activeSet: SetModel | null = null

  private lastPage: number = 1
  private currentPage: number = 1

  private icons = DashmixIconName
  private isLoading: boolean = true
  private isSetLoading: boolean = false
  private setType: SetType = this.payload.config?.setType ?? SetType.Articles

  get isFirstPage (): boolean {
    return this.currentPage === 1
  }

  get isReady (): boolean {
    return !!this.selected && !!this.selected.length
  }

  get isMultiple (): boolean {
    return this.payload.multiple ?? false
  }

  public apply (): void {
    if (this.payload.onSelection && typeof this.payload.onSelection === 'function') {
      const result = this.selected.map(item => {
        return { type: RelatedType.Set, value: item.id } as Related<RelatedType.Set, Identifier>
      })

      this.payload.onSelection(this.isMultiple ? result : result[0])
    }

    this.modalConnector.close()
  }

  public close (): void {
    if (this.payload.onClose && typeof this.payload.onClose === 'function') {
      this.payload.onClose()
    }
    this.modalConnector.close()
  }

  public isSelected (id: Identifier): boolean {
    return !!this.selected && this.selected.filter(item => item.id === id).length > 0
  }

  public loadSet (id: Identifier, isSelected: boolean): Promise<void> {
    this.isSetLoading = true

    return new Promise((resolve, reject) => {
      this.setsRepository.load(id, this.site)
        .then((set: SetModel) => {
          if (isSelected) {
            this.updateSelected(set)
          }
          this.activeSet = set

          resolve()
        })
        .catch(error => {
          console.log(error)

          reject(error)
        })
        .finally(() => {
          this.isSetLoading = false
        })
    })
  }

  public nextPage (): void {
    if (this.currentPage >= this.lastPage) {
      return
    }
    this.currentPage++
  }

  public prevPage (): void {
    if (this.currentPage === 1) {
      return
    }
    this.currentPage--
  }

  public loadSetList (pageIndex: number = 1) {
    this.isLoading = true

    this.setsRepository.loadCollection(this.setType, { q: this.query, page: String(pageIndex), perPage: String(10) }, this.site)
      .then((collection) => {
        this.currentPage = collection.meta.current_page
        this.lastPage = collection.meta.last_page

        this.setList = collection
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  public changeSets () {
    this.activeSet = null
    this.selected = []

    this.$nextTick(() => {
      this.loadSetList()
    })
  }

  public toggleSet (set: SetModel) {
    if (this.isSelected(set.id)) {
      this.selected = this.selected.filter(item => item.id !== set.id)
    } else {
      this.selected.push(set)
    }
  }

  public search () {
    this.searchDebounce()
  }

  public searchDebounce = debounce(this.loadSetList.bind(this), 500)

  public updateSelected (set: SetModel) {
    if (this.isMultiple) {
      this.selected.push(set)
    } else {
      this.selected = [set]
    }
  }

  @Watch('currentPage')
  onPageChanged (nextPage: number): void {
    this.loadSetList(nextPage)
  }
}

export default SetPickerModal
