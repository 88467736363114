// Copyright © 2021 Move Closer

export interface LoaderProps {
  variant: LoaderVariant
}

export enum LoaderVariant {
  Dark = 'dark',
  White = 'white'
}
