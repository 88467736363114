// Copyright © 2021 Move Closer

import { AnyModule, ComponentObjectPropsOptions, ContentType, DashmixTheme, SizeMap } from '@d24/modules'
import { computed, onMounted, onUnmounted, PropType, ref, SetupContext } from '@vue/composition-api'
import { ElementProps } from '@d24/page-builder'
import { IModal, ModalType } from '@movecloser/front-core'
import { IRelatedService } from '@service/related'
import { IToastService, ToastServiceType } from '@service/toaster'
import { Subscription } from 'rxjs'
import { VueConstructor } from 'vue'

import { Modals } from '@/config/modals'
import { resolve } from '@plugin/inversify'

import { ModuleBlock } from '../ModuleWrapper'
import ModuleWrapper from '../ModuleWrapper/ModuleWrapper.vue'
import {
  IPageBuilderService,
  ModulesRegistry,
  PageBuilderEvent,
  PageBuilderEventType,
  PageBuilderOperationMode,
  PageBuilderProps,
  PageBuilderServiceType,
  UsePageBuilderProvides
} from './PageBuilder.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const pageBuilderProps: ComponentObjectPropsOptions<PageBuilderProps> = {
  columns: {
    type: Number,
    required: false
  },
  contentType: {
    type: String as PropType<ContentType>,
    required: true
  },
  elementWrapper: {
    type: Function as PropType<VueConstructor>,
    required: false,
    default: ModuleWrapper
  },
  mode: {
    type: String as PropType<PageBuilderOperationMode>,
    required: false,
    default: () => PageBuilderOperationMode.Render
  },
  modules: {
    type: Array as PropType<AnyModule[]>,
    required: true
  },
  modulesRegistry: {
    type: Object as PropType<ModulesRegistry>,
    required: false,
    default: null
  },
  relatedService: {
    type: Object as PropType<IRelatedService>,
    required: true
  },
  showControls: {
    type: Boolean,
    required: false,
    default: true
  },
  showPreview: {
    type: Boolean,
    required: false,
    default: true
  },
  targetTag: {
    type: String,
    required: false,
    default: 'main'
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function usePageBuilder (props: PageBuilderProps, ctx: SetupContext): UsePageBuilderProvides {
  const modalConnector: IModal = resolve(ModalType)
  const pageBuilderService: IPageBuilderService = resolve(PageBuilderServiceType)
  const toastService: IToastService<string, unknown> = resolve(ToastServiceType)

  const builderClass = computed<string[]>(() => {
    const classList: string[] = []

    if (props.mode === PageBuilderOperationMode.EditModules) {
      classList.push('--editable')
    }
    if (props.mode === PageBuilderOperationMode.EditModules) {
      classList.push('--editable-grid')
      classList.push('--editable-modules')
    }

    return classList
  })

  const builderMode = computed<PageBuilderOperationMode>({
    get () {
      return props.mode
    },
    set (mode: PageBuilderOperationMode) {
      ctx.emit('update:mode', mode)
    }
  })

  const builderWrapperOffset = ref<number>(0)

  const elementProps = computed<ElementProps>(() => {
    return {
      contentType: props.contentType,
      mode: builderMode.value,
      modulesRegistry: props.modulesRegistry,
      relatedService: props.relatedService
    }
  })

  const elements = computed<ModuleBlock[]>({
    get () {
      return [...props.modules]
    },
    set (elements: ModuleBlock[]) {
      ctx.emit('update:modules', elements)
    }
  })

  const eventSubscription = ref<Subscription | null>(null)
  const hasAnythingToPaste = ref<boolean>(pageBuilderService.hasCopiedModules())

  const isGridEditable = computed<boolean>(() => {
    return props.mode === PageBuilderOperationMode.EditModules
  })

  const isSticky = ref<boolean>(false)

  const modeList = props.showPreview
    ? Object.values(PageBuilderOperationMode)
    : Object.values(PageBuilderOperationMode).filter(mode => mode !== PageBuilderOperationMode.Render)

  function addModule () {
    modalConnector.open(Modals.SelectModuleModal, {
      modulesRegistry: props.modulesRegistry,
      onSelection: (module: AnyModule) => {
        ctx.emit('update:modules', [...props.modules, module])
      }
    }, {
      closableWithOutsideClick: true,
      size: SizeMap.Large
    })
  }

  function handleScroll () {
    isSticky.value = window.pageYOffset > builderWrapperOffset.value
  }

  function onUpdate (changed: ModuleBlock[]): void {
    elements.value = changed
  }

  function onModuleDelete (module: ModuleBlock) {
    modalConnector.open(Modals.Confirm, {
      content: {
        header: ctx.root.$t('builder.deleteAction.header'),
        contentText: ctx.root.$t('builder.deleteAction.text'),
        contentTitle: ctx.root.$t(`builder.drivers.${module.driver}`),
        theme: DashmixTheme.Danger,
        buttonLabel: ctx.root.$t('atoms.delete')
      },
      onConfirm: () => {
        const newModules = props.modules.filter(m => `${m.id}` !== `${module.id}`)
        ctx.emit('update:modules', newModules)
        modalConnector.close()
      }
    })
  }

  function onModulesCopy (): void {
    try {
      pageBuilderService.copyToStorage(props.modules)
      toastService.success(`${ctx.root.$t('builder.modulesCopied')}`)
    } catch (e) {
      toastService.error(e.message)
    }
  }

  function onModulesPaste (): void {
    const modules: AnyModule[] = pageBuilderService.pasteFromStorage()

    modalConnector.open(Modals.Confirm, {
      content: {
        buttonLabel: `${ctx.root.$t('builder.action.pasteModules')}`,
        contentText: `${ctx.root.$t('builder.pasteModulesConfirmation', { count: modules.length })}`,
        header: `${ctx.root.$t('builder.action.pasteModules')}`,
        theme: DashmixTheme.Primary
      },
      onConfirm: () => {
        ctx.emit('update:modules', [...props.modules, ...modules])
        modalConnector.close()
      }
    })
  }

  onMounted(() => {
    const element = document.querySelector('.page-builder__wrapper') as HTMLElement
    const controls = document.querySelector('.page-builder__controls') as HTMLElement

    if (element && controls) {
      const initOffset: number = 75
      const offset: number = element.offsetTop - controls.getBoundingClientRect().height - initOffset
      builderWrapperOffset.value = offset > initOffset ? offset : initOffset

      document.addEventListener('scroll', handleScroll)
    }

    eventSubscription.value = pageBuilderService.listen((event: PageBuilderEvent) => {
      if (event.type === PageBuilderEventType.Copied) {
        hasAnythingToPaste.value = pageBuilderService.hasCopiedModules()
      } else if (event.type === PageBuilderEventType.Cleared) {
        hasAnythingToPaste.value = false
      }
    })
  })

  onUnmounted(() => {
    document.removeEventListener('scroll', handleScroll)
    if (eventSubscription.value) {
      eventSubscription.value.unsubscribe()
    }
  })

  return {
    addModule,
    builderClass,
    builderMode,
    elementProps,
    elements,
    hasAnythingToPaste,
    isGridEditable,
    isSticky,
    modeList,
    onModuleDelete,
    onModulesCopy,
    onModulesPaste,
    onUpdate
  }
}
