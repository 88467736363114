










































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName, DropdownItem, ImageRatio, TableRowAction } from '@d24/modules'

import { DirectoryData, FileData, FileThumbnail, FileVariant } from '../contracts'
import { MediaActions } from '../maps/media'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({
  name: 'TilesGridItem'
})
export class TilesGridItem extends Vue {
  @Prop({
    type: Array,
    required: true
  })
  public actions!: TableRowAction[]

  @Prop({ type: Boolean })
  public checked?: boolean

  @Prop({
    type: Object,
    required: true
  })
  public data!: FileData | DirectoryData

  @Prop({ type: Boolean })
  public isEditable?: boolean

  @Prop({ type: Boolean })
  public isMultiple?: boolean

  @Prop({ type: Boolean })
  public isSelectable?: boolean

  @Prop({
    type: Function,
    required: true
  })
  public toggle?: (data: FileData, checked: boolean) => {}

  public icons = DashmixIconName

  public get dropdownItems (): DropdownItem[] {
    return this.actions.filter(action => {
      return typeof action.guard !== 'function' || action.guard(this.data)
    }).map(action => ({
      type: 'item',
      icon: action.icon,
      label: action.label,
      theme: action.theme,
      onClick: () => {
        this.$emit('do', {
          id: this.data.id,
          action: action.action,
          data: this.data
        })
      }
    }))
  }

  public get file (): FileThumbnail | null {
    if ('directory' in this.data) {
      const data = this.data as FileData
      const squareVariant = data?.file?.variants?.filter((variant: FileVariant) => variant.ratio === ImageRatio.Square && variant.width === 500)[0]
      const thumbnail = squareVariant?.url ?? data?.file?.thumbnail ?? data?.file?.file ?? null

      return {
        thumbnail: thumbnail || '',
        type: data.mime
      }
    } else {
      return null
    }
  }

  public get fileData (): FileData {
    if (this.file) {
      const data = this.data as FileData
      return {
        ...data,
        file: { ...data.file, ...this.file }
      }
    }

    return this.data as FileData
  }

  public clickDirectoryDefaultAction (): void {
    this.$emit('do', {
      id: this.data.id,
      action: MediaActions.OpenDirectory,
      data: this.data
    })
  }

  public clickFileDefaultAction (): void {
    this.$emit('do', {
      id: this.data.id,
      action: MediaActions.EditFile,
      data: this.data
    })
  }
}

export default TilesGridItem
