
















import { AuthService, AuthServiceType, ConjunctionOperator } from '@movecloser/front-core'
import { BreadcrumbsProps, DateTimePickerType } from '@d24/modules'
import { Component, Vue } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

import { EditModeLayout } from '@component/EditModeLayout/EditModeLayout'
import { Inject } from '@plugin/inversify'
import { Loader } from '@component/Loader'

import { CreateSetIntention, CreateSetIntentionPayload } from '../intentions/CreateSetIntention'
import { initBreadcrumbs } from '../helpers'
import { ISetsRepository, SetItemsDate, SetsRepositoryType, SetStrategy, SetType } from '../contracts'
import { Set } from '../models/set'
import { SetForm } from '../components/SetForm.vue'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Component({
  name: 'CreateSet',
  components: { EditModeLayout, SetForm, Loader },
  metaInfo (this: CreateSet): MetaInfo {
    return {
      title: `${this.$t('content.sets.create')}`
    }
  }
})
export class CreateSet extends Vue {
  @Inject(AuthServiceType)
  private authService!: AuthService

  @Inject(SetsRepositoryType)
  private setsRepository!: ISetsRepository

  public dateTimePickerType = DateTimePickerType
  public setDates: SetItemsDate = { setItemsFrom: '', setItemsTo: '' }
  public formName: string = 'createSet'
  public isLoading: boolean = false
  public payload!: CreateSetIntentionPayload

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('content.sets.listTitle')}`,
          target: { name: 'content.sets.list' }
        },
        {
          label: 'Dodaj listę',
          target: { name: 'content.sets.create' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  created () {
    this.payload = {
      ...new Set(
        {
          author: this.authService.getUserId(),
          type: SetType.Articles,
          title: '',
          childrenCount: 0,
          properties: {
            src: [],
            conjunction: ConjunctionOperator.And,
            items: []
          },
          strategy: SetStrategy.Automatic
        }
      ).toObject(),
      author: Number(this.authService.user?.id)
    }
  }

  public createSet () {
    this.isLoading = true

    this.setsRepository.create(new CreateSetIntention(this.payload)).then(result => {
      this.$router.push({ name: 'content.sets.edit', params: { id: result.id } })
    }).catch((error) => {
      console.debug(error)
    }).finally(() => {
      this.isLoading = false
    })
  }

  protected handleSourcesUpdate (newPayload: CreateSetIntentionPayload) {
    this.payload = newPayload
  }

  protected handleTitleUpdate (newTitle: string) {
    this.payload.title = newTitle
  }

  protected handleShowOlderThanUpdate (newShowOlderThan: number | null) {
    this.payload.properties.showOlderThanHours = newShowOlderThan
  }
}

export default CreateSet

