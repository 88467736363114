














import { Component, Prop, Vue } from 'vue-property-decorator'

import { ArchiveDescriptions } from '@module/media/contracts'

@Component({
  name: 'FileDescriptions'
})
export class FileDescriptions extends Vue {
  @Prop({ type: Array, required: true })
  public archiveDescriptions!: ArchiveDescriptions[]
}

export default FileDescriptions
