// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'
import { FeedData, FeedStatus, IFeed } from '@module/content/contracts'

export class Feed extends Model<FeedData> implements IFeed {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'id',
      'description',
      'entriesCount',
      'status',
      'title',
      'lockedBy',
      'startedBy',
      'endedAt'
    ]
  }

  public selectable (): boolean {
    return !this.isLocked
  }

  public isEnded (): boolean {
    return this._data.status === FeedStatus.Ended
  }

  public isDraft (): boolean {
    return this._data.status === FeedStatus.New
  }

  public isLive (): boolean {
    return this._data.status === FeedStatus.Live
  }

  public isLocked (): boolean {
    return Boolean(this._data.lockedBy)
  }
}
