// Copyright © 2021 Move Closer

import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { AdBannerContent, adBannerSizeCreativesDimensionsRegistry } from '@d24/modules'

import { Inject } from '@plugin/inversify'
import { Typeahead } from '@component/Typeahead'
import { AdsRepositoryType, IAdsRepository } from '@module/content/contracts'

import { AdHint, TargetingOptionsKeys } from '../ads.contracts'
import { TargetingKeys } from '../ads.config'

/**
 * Component that contains a complete questionnaire needed to **EDIT** the `AdBanner`'s contents.
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<AdBannerGoogleForm>({
  name: 'AdBannerGoogleForm',
  components: {
    Typeahead
  },

  template: `
    <div>
      <label class="mt-3">{{ $t('adBanner.targeting.label') }}</label>
      <div class="form-group">
        <label for="targeting.section">{{ $t('adBanner.targeting.section') }}</label>
        <D24Select :model="getTargeting(targetingKeys.Section)" name="targeting.section"
                   @update:model="(model) => setTargeting(targetingKeys.Section, model)"
                   :options="targetingOptions[targetingKeys.Section]" multiple/>
      </div>

      <div class="form-group">
        <label for="targeting.position">{{ $t('adBanner.targeting.position') }}</label>
        <D24Select :model="getTargeting(targetingKeys.Position)" name="targeting.position"
                   @update:model="(model) => setTargeting(targetingKeys.Position, model)"
                   :options="targetingOptions[targetingKeys.Position]" :searchable="false" multiple/>
      </div>

      <div class="form-group">
        <label for="targeting.type">{{ $t('adBanner.targeting.type') }}</label>
        <D24Select :model="getTargeting(targetingKeys.Type)" name="targeting.type"
                   @update:model="(model) => setTargeting(targetingKeys.Type, model)"
                   :options="targetingOptions[targetingKeys.Type]" :searchable="false" multiple/>
      </div>

      <div class="form-group">
        <label for="targeting.order">{{ $t('adBanner.targeting.order') }}</label>
        <D24Select :model="getTargeting(targetingKeys.Order)" name="targeting.order"
                   @update:model="(model) => setTargeting(targetingKeys.Order, model)"
                   :options="targetingOptions[targetingKeys.Order]" :searchable="true" multiple/>
      </div>

      <template v-if="value.length > 0 && !hasCorrectSize">
        <span class="text-danger">{{ $t('adBanner.incorrectSize') }}&nbsp;<b>{{ supportedSizes }}</b></span>
      </template>
    </div>
  `
})
export class AdBannerGoogleForm extends Vue {
  @Inject(AdsRepositoryType)
  protected readonly adsRepository!: IAdsRepository

  @PropSync('content', { type: Object, required: true })
  public _content!: AdBannerContent

  @Prop({ type: Boolean, required: false, default: false })
  public readonly disabled!: boolean

  @Prop({ type: Object, required: true })
  public readonly targetingOptions!: Partial<TargetingOptionsKeys>

  @Prop({ type: Function, required: true })
  public readonly getTargeting!: (key: TargetingKeys) => string[]

  @Prop({ type: Function, required: true })
  public readonly setTargeting!: (key: TargetingKeys, value: string[]) => void

  public hints: AdHint[] = []
  public loading = false

  public value: AdHint[] = []
  public readonly targetingKeys = TargetingKeys

  public get hasCorrectSize (): boolean {
    const supportedSizes = []
    const selectedSizes = adBannerSizeCreativesDimensionsRegistry[this._content.size]
    let isSupported = false

    for (const size of this.value[0].sizes) {
      supportedSizes.push([size.width, size.height])
    }

    if (!selectedSizes) {
      return isSupported
    }

    for (const supportedSize of supportedSizes) {
      for (const selectedSize of selectedSizes) {
        if (supportedSize[0] === selectedSize[0] && supportedSize[1] === selectedSize[1]) {
          isSupported = true
        }
      }
    }
    return isSupported
  }

  public get supportedSizes (): string {
    const supportedSizes = []

    for (const size of this.value[0].sizes) {
      supportedSizes.push(size.fullDisplayString)
    }
    return `${this.$t('forms.adBanner.supportedSizes')}: ${supportedSizes.join(', ')}.`
  }
}
