















import { Component } from 'vue-property-decorator'
import { TableHead, TableRowElement } from '@d24/modules'

import { Inject } from '@plugin/inversify'

import { ModelListHeader } from '@component/ModelListHeader'

import { AbstractWidget } from '@module/root/shared/AbstractWidget'
import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'

import { canViewUsers } from '../guards'
import { IUserRepository, UserRepositoryType } from '../contracts/repositories'
import { UsersTableRowShortened } from '../components/UsersTableRowShortened.vue'
import { usersTableHeadShortened } from '../maps/users'

/**
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<LatestUsersWidget>({
  name: 'LatestUsersWidget',
  components: {
    ModelListHeader
  },

  created (): void {
    if (this.isWidgetVisable) {
      this.loadList()
    }
  }
})
export class LatestUsersWidget extends AbstractWidget {
  @Inject(UserRepositoryType)
  protected userRepository!: IUserRepository

  public isLoading: boolean = false
  public tableData: TableRowElement[] = []
  public rowComponent = UsersTableRowShortened
  public perPage: number = 5
  public tableHead: TableHead = usersTableHeadShortened.map(item => {
    return {
      ...item,
      label: `${this.$t(item.label)}`
    }
  })

  protected checkPermission (domain: Domain, user: UserModel | null): boolean {
    return canViewUsers(domain, user)
  }

  private loadList (): void {
    this.isLoading = true
    this.userRepository.loadCollection({ perPage: `${this.perPage}` }).then(collection => {
      this.isLoading = false

      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: true,
          data: model
        }
      })
    }).catch(error => {
      console.log(error)
      this.isLoading = false
    })
  }
}
export default LatestUsersWidget
