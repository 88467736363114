// Copyright © 2021 Move Closer

import { MappingConfig } from '@movecloser/front-core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const ownerAdapterMap: MappingConfig = {
  id: 'id',
  avatar: 'avatar',
  firstName: 'firstName',
  lastName: 'lastName'
}
