




























import { Component, Vue, Watch } from 'vue-property-decorator'
import { BreadcrumbsProps, TableHead, TableRowElement, TableSearchBarAction } from '@d24/modules'

import { HeaderInterface } from '@component/InteractiveTable/InteractiveTable.contracts'
import { ILogsRepository, LogRepositoryType } from '@module/users/contracts/repositories'
import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'
import { Inject } from '@plugin/inversify'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { ModelListHeader } from '@component/ModelListHeader/ModelListHeader'
import { Query } from '@/shared/contracts/query'

import { usersLogsTableHead } from '@module/users/maps/users'
import { UsersLogsTableRow } from '@module/users/components/UsersLogsTableRow.vue'
import { userLogsFiltersConfig } from '@module/users/models/userLogs.filters'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'UsersLogsList',
  components: { InteractiveTable, ModelListHeader }
})
export class UsersLogsList extends Vue {
  @Inject(LogRepositoryType)
  protected logRepository!: ILogsRepository

  public readonly filtersConfig = userLogsFiltersConfig
  public isLoading: boolean = false
  public itemsTotal: number = 0
  public rowActions: TableSearchBarAction[] = []
  public rowComponent = UsersLogsTableRow
  public tableData: TableRowElement[] = []
  public totalPages: number = 0

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('users.logs.listTitle')}`,
          target: { name: 'users.logs' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get header (): HeaderInterface {
    const header: Partial<HeaderInterface> = {
      title: 'users.logs.listTitle'
    }
    return header as HeaderInterface
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  public tableHead: TableHead = usersLogsTableHead.map(item => {
    return {
      ...item,
      label: `${this.$t(item.label)}`
    }
  })

  mounted () {
    this.loadList(this.queryParams)
  }

  protected loadList (query: Query): void {
    this.isLoading = true
    this.logRepository.loadCollection(query).then(collection => {
      this.isLoading = false

      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: true,
          data: model
        }
      })
      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.itemsTotal = collection.meta.total
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      this.isLoading = false
    })
  }

  @Watch('queryParams', { deep: true })
  protected onQueryChange (query: Query): void {
    this.loadList(query)
  }
}

export default UsersLogsList
