
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixSelectItem, Related, RelatedType } from '@d24/modules'
import { ICollection } from '@movecloser/front-core'

import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@/shared/plugins/inversify'
import FormTypeahead from '@component/FromTypeahead/FormTypeahead'
import { Hint } from '@component/Typeahead'

import { ContentModel, ContentRepositoryType, ContentStatus, ContentType, IContentRepository, SimpleContentModel } from '../contracts'
import { ContentLinksType } from '../maps/smart'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ContentPickerArticle>({
  name: 'ContentPickerArticle',
  components: { FormTypeahead },

  created (): void {
    this.site = this.siteResolver.getSite()?.id ?? null
    this.sites = [...this.siteResolver.getDictionary()]
      .map(site => {
        return {
          label: site.domain,
          value: site.id
        }
      })
  },

  mounted () {
    this.loadArticles()
  }
})
export class ContentPickerArticle extends Vue {
  @Prop({ type: Object, required: false })
  public selected!: Related | null

  @Prop({ type: Function, required: true })
  public onSelect!: (selected: Identifier, related: ContentLinksType | null) => void

  @Inject(ContentRepositoryType)
  private contentRepository!: IContentRepository

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public articles: DashmixSelectItem[] = []
  public isLoading: boolean = false
  public site: Identifier | null = null
  public sites: DashmixSelectItem[] = []

  private selectedOption: DashmixSelectItem = {
    label: '',
    value: ''
  }

  public clear () {
    this.loadArticles()
  }

  public getTypeaheadOption (): Hint[] {
    if (this.selectedOption.value) {
      return [{
        value: this.selectedOption.value,
        label: this.selectedOption.label || `${this.selectedOption.value}`
      }]
    }
    return []
  }

  public remove () {
    this.selectedOption.value = 0
    this.selectedOption.label = ''
  }

  public select (selectedHint: Hint) {
    this.selectedOption.value = Number(selectedHint.value)
    this.selectedOption.label = selectedHint.label

    this.onSelect(this.selectedOption.value as Identifier, RelatedType.Content)
  }

  public search (searchedParams: string) {
    this.loadArticles(searchedParams)
  }

  protected changeSite (site: Identifier): void {
    this.site = site

    this.loadArticles()
  }

  private async loadArticles (searchParams?: string) {
    this.isLoading = true
    this.articles = this.articleToSelectOptions(
      await this.contentRepository.loadCollection([ContentType.Article, ContentType.LiveArticle], { q: searchParams || '', status: ContentStatus.Published, siteId: `${this.site}` })
    )
    this.isLoading = false
  }

  @Watch('selected')
  private updateSelected (selected: ContentModel) {
    this.selectedOption = {
      value: selected?.id,
      label: selected?.title
    }
  }

  private articleToSelectOptions (collection: ICollection<SimpleContentModel>): DashmixSelectItem[] {
    if (this.selected) {
      const selected = [...collection].filter(model => model.id === this.selected!.value)[0]

      this.selectedOption = {
        label: selected ? selected.title : '',
        value: selected ? selected.id : null
      }
    }

    return [...collection].map(model => {
      const payload = model.toObject()

      return {
        value: payload.id,
        label: payload.title
      }
    })
  }
}

export default ContentPickerArticle
