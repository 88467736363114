// Copyright © 2021 Move Closer

import {
  adBanner,
  dataSet,
  embed,
  embedPdf,
  embedTwitter,
  embedVideo,
  embedYouTube,
  form,
  gallery,
  image,
  readMore,
  paywall
// TODO: Fix exports of modules' RegExp.
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
} from '@d24/demarked/dist/rules/block'

import {
  adBannerShortcut,
  datasetShortcut,
  embedShortcut,
  footnoteShortcut,
  formShortcut,
  galleryShortcut,
  guideShortcut,
  headingShortcut,
  imageShortcut,
  linkShortcut,
  paywallShortcut,
  quoteShortcut,
  readMoreShortcut,
  spellCheckShortcut,
  subscriptShortcut,
  superscriptShortcut
} from './shortcuts'
import { previewRender } from './helpers'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const MDTokens = [
  adBanner, dataSet, embed, embedYouTube, embedPdf, embedTwitter, embedVideo, form, gallery, image, readMore, paywall
]

/**
 * Configuration of the `<VueSimpleMDE>` component.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 *
 * @see https://github.com/F-loat/vue-simplemde/blob/master/doc/configuration_en.md
 */
export const SIMPLEMDE_CONFIG = {
  autoDownloadFontAwesome: false,
  indentWithTabs: false,
  blockStyles: {
    italic: '_'
  },
  previewRender,
  spellChecker: false,
  tabSize: 4,
  toolbar: [
    'bold', 'italic', 'strikethrough',
    '|', headingShortcut, quoteShortcut, 'unordered-list', 'ordered-list', linkShortcut, footnoteShortcut, superscriptShortcut, subscriptShortcut,
    '|', imageShortcut, galleryShortcut, datasetShortcut, readMoreShortcut, embedShortcut, formShortcut, adBannerShortcut, paywallShortcut,
    '|', 'preview', 'side-by-side', 'fullscreen',
    '|', guideShortcut,
    '|', spellCheckShortcut
  ]
}

/**
 * Minimalistic configuration of the `<VueSimpleMDE>` component.
 *
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
export const MINI_SIMPLEMDE_CONFIG = {
  ...SIMPLEMDE_CONFIG,
  toolbar: [
    'bold', 'italic', 'strikethrough',
    '|', headingShortcut, quoteShortcut, 'unordered-list', 'ordered-list', linkShortcut,
    '|', 'preview',
    '|', spellCheckShortcut
  ]
}
