<!-- Copyright © 2021 Move Closer -->

<template>
  <BaseSection>
    <template slot="heading">
      {{ $t('components.MarkdownEditorGuideModal.sections.read-more.heading') }}
    </template>

    <pre>
@[ articles: 123, 456, 789 ]

<small>{{ $t('components.MarkdownEditorGuideModal.sections.read-more.to-hide-thumbnails') }}</small>
@[ articles: 123, 456, 789 | <strong>showThumbnails: false</strong> ]</pre>
  </BaseSection>
</template>

<script>
import BaseSection from './_base'
export default { components: { BaseSection } }
</script>
