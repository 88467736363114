// Copyright © 2021 Move Closer

import { ComponentObjectPropsOptions, ImageType } from '@d24/modules'
import { computed, PropType } from '@vue/composition-api'

import { FigureProps, UseFigure } from './Figure.contracts'

/**
 * @author  Olga Milczek <olga.milczek@movecloser.pl>
 */
export const figureProps: ComponentObjectPropsOptions<FigureProps> = {
  description: {
    type: String,
    required: false
  },
  displayHeight: {
    type: Number,
    required: false
  },
  displayWidth: {
    type: Number,
    required: false
  },
  imgHeight: {
    type: Number,
    required: false
  },
  imgWidth: {
    type: Number,
    required: false
  },
  keepVertical: {
    type: Boolean,
    required: false
  },
  url: {
    type: String,
    required: false
  },
  imageType: {
    type: String as PropType<ImageType>,
    required: false,
    default: ImageType.JPEG
  }
}

/**
 * @author  Olga Milczek <olga.milczek@movecloser.pl>
 */
export function useFigure (props: FigureProps): UseFigure {
  const width = computed(() => {
    if (props.displayWidth) {
      return props.displayWidth + 'px'
    }
    return '100%'
  })

  const height = computed(() => {
    if (props.displayHeight) {
      return props.displayHeight + 'px'
    }
    return 'auto'
  })

  return { width, height }
}
