











import { Authentication, AuthEvent, AuthEventType, AuthServiceType, EventbusType, IEventbus, IModal, ModalType } from '@movecloser/front-core'
import { Component, ProvideReactive, Vue } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'
import { Subscription } from 'rxjs'

import { FullScreenLoader } from '@/shared/layouts'
import { Inject } from '@/shared/plugins/inversify'
import { Modal } from '@component/Modal'
import { Modals } from '@/config/modals'
import { PreLoadedResults, preLoadedResultsKey, PreLoader } from '@/shared/contracts/preloaders'

import { AuthRepositoryType, IAuthRepository } from '@module/auth/contracts/repositories'
import { ISiteResolver, SiteResolverType } from '@module/root/services/site-resolver'
import { Layout } from '@module/root/components/Layout.vue'
import { SiteModel } from '@module/root/contracts/models'
import { TokenModel, UserModel } from '@module/auth/contracts/models'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<App>({
  name: 'App',
  components: { FullScreenLoader, Layout, Modal },
  metaInfo (): MetaInfo {
    return {
      titleTemplate: 'Dashboard | %s'
    }
  },

  created () {
    this.setSite()

    this.setHandlers()
    this.setAuthSubscription()
    this.preLoaders().then(() => {
      this.bootedPreloaders = true
    })
  },

  destroyed () {
    this.subscription.unsubscribe()
  }
})
export class App extends Vue {
  @Inject(AuthRepositoryType)
  protected authRepository!: IAuthRepository

  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(EventbusType)
  protected eventBus!: IEventbus

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  @ProvideReactive(preLoadedResultsKey)
  protected preloaded: PreLoadedResults = {}

  protected booted: boolean = false
  protected bootedPreloaders: boolean = false
  protected contextSubscription!: Subscription

  protected subscription!: Subscription
  public site: SiteModel | null = null

  public get isLoading (): boolean {
    return !this.booted || !this.bootedPreloaders
  }

  protected notify (action: string): void {
    this.eventBus.emit('app:authentication', action)

    if (action !== 'refreshing') {
      this.booted = true
    }
  }

  protected onForceChangeSite (newSite: SiteModel) {
    this.siteResolver.setSite(newSite)
    this.eventBus.emit('ui:context.changed')
  }

  protected async preLoaders (): Promise<void> {
    const preLoaders = (this.$root.$options.configuration?.byFile('preloaders') || []) as PreLoader[]

    for (const loader of preLoaders) {
      await loader(this)
    }
  }

  protected setSite (reload?: boolean) {
    const site = this.siteResolver.getSite()

    if (site) {
      this.site = site
    }

    if (reload) {
      this.$router.push({ name: 'root.dashboard' })
    }
  }

  protected setAuthSubscription (): void {
    this.subscription = this.authService.listen((event: AuthEvent) => {
      switch (event.type) {
        case AuthEventType.Booted:
          // Here we already have an answer to previous question. So we should check
          // if there's an authenticated user & decide where should the redirection goes.
          this.notify('booted')
          break
        case AuthEventType.BootedWithToken:
        case AuthEventType.Refresh:
          // In this case we trigger automatic token refresh.
          this.notify('refreshing')

          this.authRepository.refresh(event.token?.accessToken as string).then((model: TokenModel) => {
            this.authService.setUser(model.getUser())
            this.authService.setToken(model.toAuthToken())

            const userPermissions = model.getUser().sitesWithAccess()
            const activeSite = this.siteResolver.getSite()

            if (!activeSite || !activeSite.domain || !userPermissions.includes(activeSite.domain)) {
              const newSite = userPermissions.length ? this.siteResolver.findByDomain(userPermissions[0]) : null

              if (!newSite) {
                this.authService.deleteToken()
                this.$router.push({ name: 'auth.login' })
                return
              }

              this.modalConnector.open(Modals.ForceChangeSite, {
                onConfirm: () => this.onForceChangeSite(newSite),
                newSite: newSite.domain
              })
            }
          }).catch(() => {
            this.authService.deleteToken()
          }).finally(() => {
            this.notify('refreshed')
          })
          break
        case AuthEventType.Authenticated:
          this.notify('booted')
          this.modalConnector.close(Modals.Login)
          break
        case AuthEventType.Invalidated:
          this.notify('booted')
          this.modalConnector.open(Modals.Login, {})
      }
    })
  }

  protected setHandlers (): void {
    this.contextSubscription = this.eventBus.handle('ui:context.changed', () => {
      this.setSite(true)
    })
  }
}

export default App
