












































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { Fragment } from 'vue-fragment'

import { Inject } from '@plugin/inversify'

import { AcceptanceModel, AcceptanceStatus, ContentStatus } from '../contracts'

@Component({
  name: 'AcceptanceTableRow',
  components: { Fragment }
})
export class AcceptanceTableRow extends Vue {
  @Prop({ type: Object, required: true })
  public data!: AcceptanceModel

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public get acceptanceStatus (): AcceptanceStatus {
    return this.$route.query.status as AcceptanceStatus
  }

  public get status (): string {
    return this.data.status || `${ContentStatus.Unpublished}`
  }
}

export default AcceptanceTableRow
