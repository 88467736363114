// Copyright © 2021 Move Closer

import { defineComponent, SetupContext } from '@vue/composition-api'

import { ItemsListProps } from './ItemsList.contracts'
import { itemsListProps, useItemsList } from './ItemsList.hook'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const ItemsList = defineComponent({
  name: 'ItemsList',

  props: itemsListProps,
  emits: ['select'],

  setup (props: ItemsListProps, ctx: SetupContext) {
    return useItemsList(props, ctx)
  },

  template: `
    <div class="list-group list-group-flush" :class="getCSSClass()">
      <template v-for="(item, itemList) in items">
        <!-- FIXME: to delete when adapter can be recursive -->
        <button class="list-group-item list-group-item-action"
                :class="parseInt(item.value) === parseInt(selectedItem.value) ? 'active ' : ''"
                :key="itemList"
                :id="item.value.toString()"
                @click="click(item)"
        >
          {{ item.label }}
        </button>
      </template>
    </div>
  `
})
