// Copyright © 2021 Move Closer

import { ComponentObjectPropsOptions, BreadcrumbsProps } from '@d24/modules'
import { PropType } from '@vue/composition-api'

import { EditModeLayoutProps, EditModeTabsProps } from './EditModeLayout.contracts'

export const editModeLayoutProps: ComponentObjectPropsOptions<EditModeLayoutProps> = {
  breadcrumbs: {
    type: Object as PropType<BreadcrumbsProps>,
    required: false
  },
  hasSidebar: {
    type: Boolean,
    required: false,
    default: true
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false
  },
  tabs: {
    type: Object as PropType<EditModeTabsProps>,
    required: false
  }
}
