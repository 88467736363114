





























































































import { BreadcrumbsProps, DashmixIconName, LayoutContainer, LayoutContainerType } from '@d24/modules'
import { Component, Vue } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import { FormInput } from '@component/form'
import { Identifier } from '@/shared/contracts/data'
import { IRelatedService, RelatedServiceType } from '@service/related'
import { Loader } from '@component/Loader'
import { EditModeLayout } from '@component/EditModeLayout'
import { ModelListHeader } from '@component/ModelListHeader/ModelListHeader'
import { Property } from '@component/Property'

import { initBreadcrumbs } from '@module/root/helpers/breadcrumbs'

import { Layout } from '../models/layout'
import { LayoutSlotForm } from '../components/LayoutSlotForm.vue'
import { composeContainersMap } from '../helpers/layouts'
import {
  ILayout,
  ILayoutRepository,
  LayoutContainers,
  LayoutData,
  LayoutModel,
  LayoutRepositoryType,
  LayoutSlot,
  LayoutSlotData
} from '../contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({
  name: 'EditLayout',
  components: {
    EditModeLayout,
    FormInput,
    LayoutSlotForm,
    Loader,
    ModelListHeader,
    Property
  }
})
export class EditLayout extends Vue {
  @Inject(LayoutRepositoryType)
  private layoutRepository!: ILayoutRepository

  @Inject(RelatedServiceType)
  public readonly relatedService!: IRelatedService

  public form = 'editLayout'
  public isLoading: boolean = false
  public isSaving: boolean = false
  public navName: string = ''
  public name: string = ''

  public containers: LayoutContainers = composeContainersMap({})

  private id: Identifier = Number(this.$route.params.id)
  private isActive: boolean = true
  private isEdit: boolean = !!this.id
  private layout: LayoutModel | null = null
  private slots: LayoutSlotData[] = []

  public Icons = DashmixIconName
  public LayoutContainer = LayoutContainer
  public LayoutContainerType = LayoutContainerType
  public LayoutSlot = LayoutSlot

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('settings.layouts.title')}`,
          target: { name: 'settings.layouts.list' }
        },
        {
          label: this.pageTitle,
          target: { name: 'settings.layouts.edit' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get pageTitle (): string {
    return this.isEdit
      ? `${this.$t('settings.layouts.editTitle', { name: this.navName })}`
      : `${this.$t('settings.layouts.createTitle')}`
  }

  mounted () {
    if (this.isEdit) {
      this.isLoading = true

      this.layoutRepository.get(this.id)
        .then(data => {
          this.updateLayout(data, this.id)
        })
        .catch(error => console.error(error))
        .finally(() => {
          this.isLoading = false
        })
    }
  }

  public setContainer (slot: LayoutContainer, value: boolean): void {
    this.containers[slot] = value ? LayoutContainerType.FullWidth : LayoutContainerType.Boxed
  }

  public submit () {
    const payload: Omit<LayoutData, 'id'> = {
      containers: this.containers,
      isActive: this.isActive,
      name: this.name,
      slots: this.slots
    }

    this.isSaving = true

    const request = this.isEdit
      ? this.layoutRepository.update(this.id, payload)
      : this.layoutRepository.create(payload)

    request
      .then(data => {
        if (!this.isEdit && data.layoutId) {
          this.id = data.layoutId

          this.$router.push({
            name: 'settings.layouts.edit',
            params: { id: `${data.layoutId}` }
          })
        }

        this.updateLayout(payload)
      })
      .catch(error => console.info(error))
      .finally(() => {
        this.isSaving = false
      })
  }

  public update (data: LayoutSlotData) {
    this.slots = { ...this.slots, ...data }
  }

  public async updateLayout (payload: Omit<LayoutData, 'id'>, id?: Identifier) {
    this.layout = Layout.hydrate<LayoutData, ILayout>({
      ...this.layout,
      ...payload,
      containers: composeContainersMap(payload.containers),
      id: id ?? this.id
    })
    this.isEdit = !!this.id

    this.isActive = this.layout.isActive
    this.containers = this.layout.containers
    this.slots = this.layout.slots
    this.name = this.layout.name
    this.navName = this.layout.name
  }
}

export default EditLayout
