
































import { BreadcrumbsProps, DashmixTheme, TableHead, TableRowAction } from '@d24/modules'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { IModal, ModalType } from '@movecloser/front-core'
import { MetaInfo } from 'vue-meta'

import { DropdownActions } from '@/shared/contracts/content'
import { HeaderInterface } from '@component/InteractiveTable/InteractiveTable.contracts'
import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@/shared/plugins/inversify'
import { InteractiveTable } from '@component/InteractiveTable/InteractiveTable.vue'
import { ModelListHeader } from '@component/ModelListHeader/ModelListHeader'
import { Query } from '@/shared/contracts/query'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { ArticleActions, articlesRowActionsFactory, articleTableHead } from '../maps/article'
import { ArticlesTableRow } from '../components/ArticleTableRow.vue'
import { ContentModel, ContentStatus, ContentType } from '../contracts/models'
import { ContentTableRowElement, IContentOptionsPayload, IPublishContentPayload } from '../contracts'
import { ContentRepositoryType, IContentRepository } from '../contracts/repositories'
import { initBreadcrumbs } from '../helpers'
import { articlesFiltersConfig } from '@module/content/models/content.filters'

@Component({
  name: 'ArticlesList',
  components: { InteractiveTable, ModelListHeader },
  metaInfo (this: ArticlesList): MetaInfo {
    return {
      title: `${this.modelListHeader.title}`
    }
  }
})
export class ArticlesList extends Mixins<IUserAware>(UserAware) {
  @Inject(ContentRepositoryType)
  protected contentRepository!: IContentRepository

  @Inject(ModalType)
  protected modalConnector!: IModal

  public filtersConfig = articlesFiltersConfig
  public isLoading = false

  public get header (): HeaderInterface {
    const payload: Partial<HeaderInterface> = {
      title: `${this.$t('content.article.listTitle')}`
    }

    if (this.canCreateContents) {
      payload.buttonLabel = 'content.addArticle'
      payload.linkTarget = { name: 'content.articles.create' }
    }

    return payload as HeaderInterface
  }

  public get modelListHeader () {
    const payload = {
      addLabel: this.$t('content.article.listButton'),
      addTarget: { name: 'content.articles.create' },
      title: this.$t('content.article.listTitle')
    }

    if (!this.canCreateContents) {
      delete payload.addLabel
      delete payload.addTarget
    }

    return payload
  }

  public tableHead: TableHead = articleTableHead
  public rowComponent = ArticlesTableRow
  public tableData: ContentTableRowElement[] = []

  public totalPages: number = 0
  public itemsTotal: number = 0

  public get rowActions (): TableRowAction[] {
    return articlesRowActionsFactory(this.domain, this.user)
  }

  public actions: DropdownActions = {
    [ArticleActions.Preview]: {
      callback: (data: unknown) => {
        this.onPreview(data as ContentModel)
      }
    },
    [ArticleActions.Edit]: {
      callback: (data: unknown) => {
        const model = data as ContentModel
        this.$router.push({
          name: 'content.articles.content',
          params: { id: model.id.toString() }
        })
      }
    },

    [ArticleActions.Publish]: {
      callback: (data: unknown, payload: IPublishContentPayload) => {
        const model = data as ContentModel
        return this.onPublish(model.id, payload)
      },
      confirmation: {
        header: 'actions.publish.header',
        contentText: 'actions.publish.contentText',
        buttonLabel: 'atoms.publish'
      }
    },

    [ArticleActions.Delete]: {
      callback: (data: unknown) => {
        const model = data as ContentModel
        return this.onDelete(model.id)
      },
      confirmation: {
        header: 'actions.delete.header',
        contentText: 'actions.delete.contentText',
        theme: DashmixTheme.Danger,
        buttonLabel: 'atoms.delete'
      }
    },
    [ArticleActions.Ads]: {
      callback: (data: unknown) => {
        const model = data as ContentModel
        return this.onOptionsChanged(model.id, { ads: false })
      }
    },
    [ArticleActions.Paywall]: {
      callback: (data: unknown) => {
        const model = data as ContentModel
        return this.onOptionsChanged(model.id, { paywall: false })
      }
    },
    [ArticleActions.Author]: {
      callback: (data: unknown) => {
        const model = data as ContentModel
        return this.onOptionsChanged(model.id, { hideAuthor: false })
      }
    },
    [ArticleActions.Comments]: {
      callback: (data: unknown) => {
        const model = data as ContentModel
        return this.onOptionsChanged(model.id, { comments: false })
      }
    }
  }

  public get breadcrumbs (): BreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('content.article.listTitle')}`,
          target: { name: 'content.articles.list' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  mounted () {
    this.loadList(this.queryParams)

    this.setFiltersDict()
  }

  protected loadList (query: Query): void {
    this.isLoading = true
    this.contentRepository.loadCollection([ContentType.Article, ContentType.LiveArticle], query)
      .then(collection => {
        this.isLoading = false

        this.tableData = [...collection].map(model => {
          return {
            id: `${model.id}`,
            selectable: this.canEditContents || this.canDeleteContents,
            data: model
          }
        })

        this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
        this.itemsTotal = collection.meta.total
      }).catch(error => {
        console.log(error)
        this.isLoading = false
      })
  }

  protected onDelete (id: Identifier) {
    return this.contentRepository.delete(id).then(() => {
      this.tableData = this.tableData.filter(rowData => rowData.id !== id.toString())
    })
  }

  protected onPublish (id: Identifier, payload: IPublishContentPayload) {
    return this.contentRepository.publish(id, payload)
  }

  protected onPreview (data: ContentModel) {
    if (Object.prototype.hasOwnProperty.call(data, 'url')) {
      if (!data.url) {
        return
      }
      window.open(data.url)
    }
  }

  protected onOptionsChanged (id: Identifier, option: IContentOptionsPayload) {
    return this.contentRepository.options(id, option)
  }

  @Watch('queryParams', { deep: true })
  protected onQueryChange (query: Query): void {
    this.loadList(query)
  }

  protected setFiltersDict () {
    const filtersCopy = { ...this.filtersConfig }
    if (this.filtersConfig.groups.status) {
      this.filtersConfig.groups.status.dictionary = Object.values(ContentStatus).map(status => {
        return {
          label: this.$t(`status.${status}`) as string,
          value: status
        }
      })
    }

    this.filtersConfig = filtersCopy
  }
}

export default ArticlesList
