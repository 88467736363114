// Copyright © 2021 Move Closer

import { defineComponent } from '@vue/composition-api'

import { NavigationItem } from './partials/NavigationItem'
import { NavigationProps } from './Navigation.contracts'
import { navigationProps, useNavigation } from './Navigation.hooks'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const Navigation = defineComponent({
  name: 'Navigation',
  props: navigationProps,
  components: { NavigationItem },
  setup (props: NavigationProps) {
    return useNavigation(props)
  },
  template: `
    <ul class="nav-main">
    <li class="nav-main-item custom-sidebar open mb-2">
      <div class="nav-main-link" @click="toggleAllOpen()">
        <i class="nav-main-link-icon fa" :class="{ 'fa-compress-alt':isAllOpen, 'fa-expand-alt':!isAllOpen }"></i>
        <span class="nav-main-link-name">{{ $t('_.' + (isAllOpen ? 'collapseAll' : 'expandAll')) }}</span>
      </div>
    </li>
    <NavigationItem v-for="(item, index) in availableItems" :key="'nav-item-'+index"
                    :item="item" :isOpened="isOpened(index)" :allOpen="isAllOpen"
                    @click="clickItem(index)" />
    </ul>
  `
})
