// Copyright © 2021 Move Closer

import { defineComponent, SetupContext } from '@vue/composition-api'

import { ItemsList } from './partials/ItemsList'
import { TreeViewProps } from './TreeViewer.contracts'
import { treeViewProps, useTreeView } from './TreeView.hook'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const TreeViewer = defineComponent({
  name: 'TreeViewer',
  components: { ItemsList },
  props: treeViewProps,
  emits: ['selectItems'],

  setup (props: TreeViewProps, ctx: SetupContext) {
    return useTreeView(props, ctx)
  },

  template: `
    <div>
      <label class = "label" :class="isRequired ? 'required' : ''"> {{ label }} </label>
      <div class = "tree-container rounded border border-1"
           :class = "nodeChildren.length > 3 ? 'scrollable' : ''"
           :id = "treeContainerHTMLId" >
        <div class = "tree-viewer" :id = "treeViewerHTMLId" >
          <ItemsList
            :items = "items"
            :treeLevel = "0"
            :selectedItem = "skippedRoot ? selectedItemsList[1] : selectedItemsList[0]"
            :isLast = "nodeChildren.length === 0"
            @select = "selectItem"
          />

          <template v-for="(Item, index) in nodeChildren">
            <ItemsList
              :key = "index"
              :treeLevel = "index + 1"
              :items = "Item"
              :selectedItem = "skippedRoot ? selectedItemsList[index + 2] : selectedItemsList[index + 1]"
              :isLast = "index >= nodeChildren.length - 1"
              @select = "selectItem"
            />
          </template>
        </div>
      </div>
    </div>
  `
})
