// Copyright © 2021 Move Closer

import { DashmixIconName, DashmixTheme, TableHead, TableSearchBarAction } from '@d24/modules'

import { canDeleteNavigation, canEditNavigation } from '@module/settings/guards'
import { Domain } from '@module/root/contracts/models'
import { UserModel } from '@module/auth/contracts/models'

export const navigationTableHead: TableHead = [
  {
    column: 'name',
    label: 'Nazwa',
    sortable: true,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'slot',
    label: 'Rodzaj',
    sortable: true,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'active',
    label: 'Status',
    sortable: false,
    isSorted: false,
    width: '1fr'
  }
]

export enum NavigationActions {
  Delete = 'delete',
  Edit = 'edit'
}

export const navigationRowActionsFactory = (domain: Domain, user: UserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canEditNavigation(domain, user)) {
    actions.push(
      {
        label: 'Edytuj',
        icon: DashmixIconName.PenSolid,
        isBulkAction: false,
        action: NavigationActions.Edit
      }
    )
  }

  if (canDeleteNavigation(domain, user)) {
    actions.push(
      {
        label: 'Usuń',
        icon: DashmixIconName.TrashAltSolid,
        isBulkAction: true,
        action: NavigationActions.Delete,
        theme: DashmixTheme.Danger
      }
    )
  }

  return actions
}
