



























import { Component, Prop } from 'vue-property-decorator'
import { DashmixIconName, EmbedModuleForm, EmbedVersion, Picker, PickerSelectionCallback, Related, SizeMap } from '@d24/modules'
import { IModal, ModalType } from '@movecloser/front-core'

import { AbstractModal } from '@component/Modal'
import { Identifier } from '@/shared/contracts/data'
import { Inject } from '@plugin/inversify'
import { IRelatedService, RelatedServiceType } from '@service/related'
import { Modals } from '@/config/modals'
import { mediaTypes } from '@module/media/contracts'

import { EmbedModalPayload } from './EmbedModalForm.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'EmbedFormModal',
  components: { EmbedModuleForm }
})
export class EmbedFormModal extends AbstractModal {
  @Prop({ type: Object, required: true })
  public payload!: EmbedModalPayload

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  public readonly icons = DashmixIconName

  public apply () {
    this.modalConnector.close()

    if (this.payload.content.iframe) {
      this.payload.content.iframe = this.payload.content.iframe
        .replace(/  +/g, ' ')
        .replace(/ {2}|\r\n|\n|\r/gm, '')
    }

    this.payload.onSelection(this.payload.content, this.payload.version)
  }

  public close () {
    this.modalConnector.close()
  }

  public pickRelated (picker: Picker, pick: PickerSelectionCallback): void {
    this.modalConnector.close()

    const onClose = (modalConnector: IModal) => {
      setTimeout(() => {
        modalConnector.open(Modals.EmbedModal, this.payload, { size: SizeMap.XLarge })
      }, 0)
    }

    let allowedMediaType

    switch (this.payload.version) {
      case EmbedVersion.PDF:
        allowedMediaType = mediaTypes.Document
        break
      case EmbedVersion.Video:
        /* FIXME - this is hack to add proper allowedMediaType based on what related will be set.
        It will be better to get it some how from config, but it need more changes in markdown */
        if (pick.name.includes('Video')) {
          allowedMediaType = mediaTypes.Video
        } else {
          allowedMediaType = mediaTypes.File
        }
        break
      default:
        allowedMediaType = mediaTypes.File
    }

    this.modalConnector.open(picker, {
      config: {
        allowedMediaType: allowedMediaType
      },
      onClose: () => {
        onClose(this.modalConnector)
      },
      onSelection: (data: Related<Picker, Identifier>) => {
        this.$nextTick(() => {
          this.modalConnector.open(Modals.EmbedModal, this.payload, { size: SizeMap.XLarge })

          pick({
            type: data.type,
            value: data.value
          } as never)
        })
      }
    }, { size: SizeMap.XLarge })
  }
}

export default EmbedFormModal
