





































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@d24/modules'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { Fragment } from 'vue-fragment'

import { Inject } from '@plugin/inversify'

import { CommentModel, CommentStatus, ContentType } from '../contracts'
import { EditorsStack } from './EditorsStack.vue'

@Component({
  name: 'CommentsTableRow',
  components: {
    EditorsStack,
    Fragment
  }
})
export class CommentsTableRow extends Vue {
  @Prop({ type: Object, required: true })
  public data!: CommentModel

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public ContentType = ContentType
  public Icons = DashmixIconName

  public get creator (): string {
    if (typeof this.data.creator !== 'string') {
      return this.data.creator.firstName + ' ' + this.data.creator.lastName
    } else {
      return this.data.creator
    }
  }

  public get isRejected (): boolean {
    return this.data.status === CommentStatus.Rejected ||
      this.data.status === CommentStatus.Spam
  }

  public get isInAcceptance (): boolean {
    return this.data.status === CommentStatus.InAcceptance
  }
}

export default CommentsTableRow
