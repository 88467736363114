



























































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DashmixIconName } from '@d24/modules'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { Fragment } from 'vue-fragment'

import { Inject } from '@/shared/plugins/inversify'
import { MediaActions } from '@module/media/maps/media'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { FileModel, FileThumbnail } from '../contracts'
import { fileSize } from '../helpers'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component({
  name: 'MediaTableRow',
  components: { Fragment }
})
export class MediaTableRow extends Mixins<IUserAware>(UserAware) {
  @Prop({ type: Object, required: true })
  public data!: FileModel

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public icons = DashmixIconName

  public get file (): FileThumbnail | null {
    if (this.data?.directory) {
      const thumbnail = this.data?.file?.thumbnail ?? this.data?.file?.file ?? null
      return {
        thumbnail: thumbnail || '',
        type: this.data.mime
      }
    } else {
      return null
    }
  }

  public clickDirectoryDefaultAction (): void {
    this.$emit('do', { id: this.data.id, action: MediaActions.OpenDirectory, data: this.data })
  }

  public clickFileDefaultAction (): void {
    this.$emit('do', { id: this.data.id, action: MediaActions.EditFile, data: this.data })
  }

  public getRowFileSize (size: number | null): string {
    return size ? fileSize(size) : ''
  }
}
export default MediaTableRow
