























































































































import { DashmixIconName } from '@d24/modules'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import { IModal, ModalType } from '@movecloser/front-core'

import { FormInput } from '@component/form/Input/Input'
import { Inject } from '@plugin/inversify'

import { Prices } from '../contracts'
import { ConfigForm } from '../components/_abstract/ConfigForm.vue'

@Component({
  name: 'SubscriptionPriceForm',
  components: { FormInput }
})
export class SubscriptionPriceForm extends ConfigForm {
  @Prop({ type: Boolean, required: false })
  public isLoading!: boolean

  @PropSync('formData', { type: Object, required: true })
  public payload!: Prices

  @Inject(ModalType)
  protected modalConnector!: IModal

  public readonly icons = DashmixIconName

  public form: string = 'editSubscriptionPrices'

  static defaultPayload () {
    return {
      standard: {
        annual: 0,
        'semi-annual': 0,
        monthly: 0,
        savesAnnual: 0,
        savesSemiAnnual: 0,
        savesMonthly: 0,
        fullAnnual: 0,
        fullSemiAnnual: 0,
        fullMonthly: 0
      },
      premium: {
        annual: 0,
        'semi-annual': 0,
        monthly: 0,
        savesAnnual: 0,
        savesSemiAnnual: 0,
        savesMonthly: 0,
        fullAnnual: 0,
        fullSemiAnnual: 0,
        fullMonthly: 0
      }
    }
  }
}

export default SubscriptionPriceForm
