










import { Component, PropSync, Vue } from 'vue-property-decorator'
import { DashmixSelectItem } from '@d24/modules'

import { RobotsValues } from '../../contracts'

/**
 * @author  Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<MetaRobots>({
  name: 'MetaRobots',
  mounted () {
    if (!this.value) {
      this.value = RobotsValues.NoLimitations
    }
  }
})
export class MetaRobots extends Vue {
  @PropSync('model', { type: String, required: false })
  public value?: RobotsValues

  public options: DashmixSelectItem[] = Object.entries(RobotsValues).map(([key, value]: [string, string]) => {
    return {
      label: `${this.$t(`content.addons.meta.robots.${key}`)}`,
      value: value
    }
  })
}

export default MetaRobots
