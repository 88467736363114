// Copyright © 2021 Move Closer

import { AbstractIntention, MappingTypes } from '@movecloser/front-core'
import { AuthorPayload } from '@module/users/contracts/data'

export class EditAuthorIntention extends AbstractIntention<AuthorPayload> {
  protected map = {
    avatar: {
      type: MappingTypes.Function,
      value: (model: AuthorPayload) => {
        if (!model.avatar) {
          return
        }
        return model.avatar.id
      },
      target: 'avatar'
    },
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    nickname: 'nickname',
    title: 'title',
    position: 'position',
    type: 'type',
    description: 'description',
    user: {
      type: MappingTypes.Function,
      value: (model: AuthorPayload) => {
        if (!model.user) {
          return
        }
        return model.user.id
      },
      target: 'user'
    },
    links: 'links',
    slug: 'slug',
    isLinkActive: 'isLinkActive',
    isPageActive: 'isPageActive'
  }
}
