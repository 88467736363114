// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'
import { authorAdapterMap } from '@module/content/models/author.adapter'

const ownerAdapterMap: MappingConfig = {
  id: 'id',
  title: 'title'
}

export const commentAdapterMap: MappingConfig = {
  content: 'content',
  createdAt: 'createdAt',
  creator: {
    type: MappingTypes.Function,
    map: authorAdapterMap,
    value: (comment) => {
      if (!comment.creator) {
        return comment.legacyName
      } else {
        return comment.creator
      }
    }
  },
  id: 'id',
  owner: {
    type: MappingTypes.Adapter,
    map: ownerAdapterMap,
    value: 'owner'
  },
  parentId: 'parentId',
  status: 'status'
}
