// Copyright © 2021 Move Closer

import VueRouter, { RouteConfig } from 'vue-router'
import { Bootstrapper, Container, IConfiguration } from '@movecloser/front-core'

import { config } from '@/config'
import { createRouter } from '@/bootstrap/router'
import { ISiteService, SiteServiceType } from '@module/root/services/site-resolver'

export interface BootedApp {
  configuration: IConfiguration
  container: Container
  router: VueRouter
}

/**
 * Creates app instance.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export async function createApp (): Promise<BootedApp> {
  const bootstrapper = new Bootstrapper(config)
  await bootstrapper.boot()

  const configuration: IConfiguration = bootstrapper.getConfiguration()
  const container: Container = bootstrapper.getContainer()

  const siteService: ISiteService = container.get(SiteServiceType)
  await siteService.boot()

  const router = createRouter(
    bootstrapper.getRoutesStack() as RouteConfig[],
    configuration,
    container
  )

  return { configuration, container, router }
}
