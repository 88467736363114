














import { Component, Prop } from 'vue-property-decorator'
import { DashmixSelectItem } from '@d24/modules'

import { Hint, Typeahead } from '@component/Typeahead'
import { Identifier } from '@/shared/contracts/data'

import { AbstractSmartLinkTab } from '../components/AbstractSmartLinkTab.vue'
import { ContentType } from '../contracts'
import { singleSmartRelatedTypeOptions, SingleSmartType } from '../maps/smart'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<ContentPickerSmartLink>({
  name: 'ContentPickerSmartLink',
  components: {
    Typeahead
  }
})
export class ContentPickerSmartLink extends AbstractSmartLinkTab {
  //
  @Prop({ type: Function, required: true })
  public onSelect!: (selected: Identifier|string, related: SingleSmartType|null) => void

  public typeaheadOptions: Hint[] = []
  public options = singleSmartRelatedTypeOptions

  public get smartOptions (): DashmixSelectItem[] {
    return [
      { label: 'Wybierz', value: null },
      ...singleSmartRelatedTypeOptions
    ]
  }

  public onSelection (value: Hint): void {
    if (!value) {
      this.selected = []
    } else {
      this.onSelect(Number(value.value), this.type!)
      this.selected = [value]
    }
  }

  protected async loadOptions (
    base: 'dict' | 'collection',
    type: 'author' | 'tag' | 'gallery' | 'content',
    params: string
  ): Promise<any> {
    this.isLoading = true
    if (base === 'dict' && type === 'author') {
      return await this.dictRepository.loadAuthorsDictionary({ q: params || '' })
    }
    if (base === 'dict' && type === 'tag') {
      return await this.dictRepository.loadTagsDictionary({ q: params || '' })
    }
    if (base === 'dict' && type === 'gallery') {
      return await this.dictRepository.loadGalleryDictionary({ q: params || '' })
    }
    if (base === 'collection' && type === 'content') {
      return await this.contentRepository.loadCollection(ContentType.Page, { q: params || '' })
    }
  }

  protected toOptions (from: any[], type: 'author' | 'content' | 'name'): void {
    this.typeaheadOptions = [...from].map(model => {
      return {
        value: model.id,
        label: type === 'author' ? model.fullName : (type === 'content' ? model.title : model.name)
      }
    })
  }

  protected loadDictionary (searchParams?: string): Promise<boolean> {
    return new Promise(resolve => {
      switch (this.type) {
        case SingleSmartType.SmartAuthor:
          this.loadOptions('dict', 'author', searchParams || '').then(model => {
            this.toOptions(model, 'author')
          }).finally(() => resolve())
          break
        case SingleSmartType.SmartCategory:
          this.loadOptions('collection', 'content', searchParams || '').then(model => {
            this.toOptions(model, 'content')
          }).finally(() => resolve())
          break
        case SingleSmartType.SmartGallery:
          this.loadOptions('dict', 'gallery', searchParams || '').then(model => {
            this.toOptions(model, 'name')
          }).finally(() => resolve())
          break
        case SingleSmartType.SmartTag:
          this.loadOptions('dict', 'tag', searchParams || '').then(model => {
            this.toOptions(model, 'name')
          }).finally(() => resolve())
          break
      }
    })
  }
}

export default ContentPickerSmartLink
