

















import { Component } from 'vue-property-decorator'
import { debounce, DebouncedFunc } from 'lodash'

import { Addon } from '../../maps/variant'

import { AbstractAddon } from './AbstractAddon'
import { AddonErrors } from './AddonErrors.vue'

/**
 * @author  Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'LeadAddon',
  components: { AddonErrors }
})
export class LeadAddon extends AbstractAddon {
  public lead: string = ''

  public setLead (lead: string) {
    this.lead = lead
    this.setNewLead(this.lead)
  }

  protected getVariantProperty (): void {
    if (this.variant) {
      const leadFromModel = this.variant.getProperty<{ lead: string }>(Addon.Lead)

      if (!leadFromModel) {
        this.lead = ''
        return
      }

      this.lead = leadFromModel.lead
    }
  }

  protected setNewLead: DebouncedFunc<(lead: string) => void> =
    debounce((lead: string) => {
      this.setNewLead.cancel()

      this.variant.setProperty<{ lead: string }>(Addon.Lead, { lead: lead })

      this.onChange(this.variant)
    }, 1000)
}

export default LeadAddon
