

























import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { Fragment } from 'vue-fragment'
import { RawLocation } from 'vue-router'

import { Inject } from '@/shared/plugins/inversify'
import { Query } from '@/shared/contracts/query'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { ContentModel, ContentStatus, ContentType } from '../contracts'
import { ContentStatusBadge } from '../contracts/models'
import { EditorsStack } from './EditorsStack.vue'

@Component({
  name: 'PagesTableRowShortened',
  components: { EditorsStack, Fragment }
})
export class PagesTableRowShortened extends Mixins<IUserAware>(UserAware) {
  @Prop({ type: Object, required: true })
  public data!: ContentModel

  public ContentStatus = ContentStatus
  public ContentStatusBadge = ContentStatusBadge
  public ContentType = ContentType
}

export default PagesTableRowShortened
