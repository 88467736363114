























import { Component, Prop, Vue } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { Fragment } from 'vue-fragment'

import { Inject } from '@plugin/inversify'

import { LogModel, LogType } from '../contracts/models'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'UsersLogsTableRow',
  components: { Fragment }
})
export class UsersLogsTableRow extends Vue {
  @Prop({ type: Object, required: true })
  public data!: LogModel

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public logType = LogType
}

export default UsersLogsTableRow
