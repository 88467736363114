// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'

import { siteAdapterMap } from '@module/root/models/site.adapter'

import { authorAdapterMap } from './author.adapter'

export const contentAdapterMap: MappingConfig = {
  author: {
    type: MappingTypes.Adapter,
    map: authorAdapterMap,
    value: 'author'
  },
  childrenCount: 'childrenCount',
  children: {
    type: MappingTypes.Function,
    value: item => item.children || []
  },
  publishedDate: 'publishedDate',
  createdAt: 'createdAt',
  editors: {
    type: MappingTypes.Adapter,
    map: authorAdapterMap,
    value: 'editors'
  },
  id: 'id',
  parent: 'parent',
  properties: 'properties',
  site: {
    type: MappingTypes.Adapter,
    map: siteAdapterMap,
    value: 'site'
  },
  slug: 'slug',
  status: 'status',
  title: 'title',
  type: 'type',
  variantsCount: 'variantsCount',
  variants: {
    type: MappingTypes.Function,
    value: item => item.variants || []
  },
  updatedAt: 'updatedAt',
  url: {
    type: MappingTypes.Function,
    value: item => item.url || null
  }
}
